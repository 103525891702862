import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import helper from './cementTableHelper'
import {
  ISimpleTableSettings,
  ITargetEvent,
  SimpleTableRow,
} from '../../../Common/Logic/Types'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'

const useCementTable = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  tableSettings: ISimpleTableSettings,
  setTableSettings: Dispatch<SetStateAction<ISimpleTableSettings>>,
  zipCodeCoordinates?: number[]
) => {
  const [cementRows, setCementRows] = useState<SimpleTableRow[]>([])
  const [cementCount, setCementCount] = useState<number>(0)
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([])
  const echoSettings = useRecoilValue(atomEchoSettings)

  // Extract coordinates from state to limit re-renders while getting table data
  const zipCodeCoords1 = zipCodeCoordinates?.[0]
  const zipCodeCoords2 = zipCodeCoordinates?.[1]

  const getTableData = useCallback(
    async abortController => {
      try {
        setIsLoading(true)
        const { rows, count } = await helper.getCementTableData(
          echoSettings,
          tableSettings,
          abortController,
          [zipCodeCoords1 as number, zipCodeCoords2 as number]
        )
        setCementRows(rows)
        setCementCount(count)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, echoSettings, tableSettings, zipCodeCoords1, zipCodeCoords2]
  )

  const handleExpanderClick = useCallback(
    (id: number) => {
      setExpandedRowIds(helper.updateExpandedRowIds(expandedRowIds, id))
    },
    [expandedRowIds]
  )

  const handleRequestSort = useCallback(
    (_event: ITargetEvent, property: string) => {
      setTableSettings(helper.updateTableSettingsSort(tableSettings, property))
    },
    [tableSettings, setTableSettings]
  )

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      setTableSettings(
        helper.updateTableSettingsRowsPerPage(tableSettings, event.target.value)
      )
    },
    [tableSettings, setTableSettings]
  )

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setTableSettings(helper.updateTableSettingsPage(tableSettings, newPage))
    },
    [tableSettings, setTableSettings]
  )

  useEffect(() => {
    const abortController = new AbortController()
    getTableData(abortController)

    return () => {
      abortController.abort()
    }
  }, [getTableData])

  // Memoize the specific properties of echoSettings that should trigger cementTable page reset
  const cementTableFilterSettings = useMemo(
    () => ({
      countries: echoSettings.countries,
      states: echoSettings.states,
      cities: echoSettings.cities,
      zipCodes: echoSettings.zipCodes,
      cementTypes: echoSettings.cementTypes,
      cementSuppliers: echoSettings.cementSuppliers,
      labResults: echoSettings.labResults,
    }),
    [echoSettings]
  )

  // Effect to reset the page to 0 when cementTableFilterSettings change
  useEffect(() => {
    if (tableSettings.page !== 0) {
      setTableSettings(currentSettings => ({
        ...currentSettings,
        page: 0,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cementTableFilterSettings])

  return {
    cementRows,
    cementCount,
    expandedRowIds,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleExpanderClick,
  }
}

const hook = { useCementTable }

export default hook
