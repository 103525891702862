import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  SelectInput: {
    backgroundColor: 'white',
  },
  SecondsInput: {
    width: '40px',
    height: '40px',
    backgroundColor: 'white',
  },
})

function writeBytes(byte_values) {
  const buf = new ArrayBuffer(byte_values.length)
  const view = new DataView(buf)

  let offset = 0
  for (const v of byte_values) {
    view.setUint8(offset, v)
    offset += 1
  }

  return buf
}

/**
 *
 * @param {number} localRemoteId The local remote id of the remote unit you'd like to send this command to
 * @param {number} valveId The valve ID of the valve you'd like to open. 1 is gas, 2 is liquid
 * @param {number} seconds The number of seconds you'd like to keep the valve open for
 */
function getOpenValvePayload(localRemoteId, valveId, seconds) {
  const code = 1

  return writeBytes([code, localRemoteId, valveId, seconds])
}

function getFixedSensorValuePayload(localRemoteId, sensorId) {
  const code = 2

  return writeBytes([code, localRemoteId, sensorId])
}

function getRebootPayload() {
  const code = 3

  return writeBytes([code])
}

function getDisableSensorOverridePayload(localRemoteId, sensorId) {
  const code = 4

  return writeBytes([code, localRemoteId, sensorId])
}

const commands = [
  {
    value: 0,
    label: 'Select a command',
  },
  {
    value: 2,
    label: 'Open Valve',
  },
  {
    value: 3,
    label: 'Reboot',
  },
  {
    value: 4,
    label: 'Fix Sensor Value',
  },
  {
    value: 5,
    label: 'Disable Fix Sensor Value',
  },
]

const valves = [
  {
    value: 1,
    label: 'Gas Valve',
  },
  {
    value: 2,
    label: 'Liquid Valve',
  },
]

const sensors = [
  {
    value: 1,
    label: 'Inlet Liquid Pressure',
  },
  {
    value: 2,
    label: 'Inlet Gas Pressure',
  },
  {
    value: 3,
    label: 'Downstream Pressure 1',
  },
  {
    value: 4,
    label: 'Downstream Pressure 2',
  },
]

WebSocketCommandController.propTypes = {
  webSocket: PropTypes.object.isRequired,
  remoteUnitLocalId: PropTypes.number.isRequired,
}

function WebSocketCommandController(props) {
  const { webSocket, remoteUnitLocalId } = props
  const classes = useStyles()
  const [command, setCommand] = useState(0)
  const [valveType, setValveType] = useState(1)
  const [sensor, setSensor] = useState(1)
  const [seconds, setSeconds] = useState(0)
  const selectProps = { native: true }

  const handleCommandSelectChange = event => {
    let selectedCommand = Number(event.target.value)
    setCommand(selectedCommand)
  }

  const handleValveTypeChange = event => {
    setValveType(event.target.value)
  }

  const handleSensorChange = event => {
    const sensorVal = Number(event.target.value)
    setSensor(sensorVal)
  }

  const handleSecondsChange = event => {
    let secondsInput = Number(event.target.value)
    if (isNaN(secondsInput)) {
      setSeconds(0)
    } else {
      secondsInput = Math.floor(secondsInput)
      setSeconds(secondsInput)
    }
  }

  const sendOpenValveCommand = () => {
    let payload = getOpenValvePayload(remoteUnitLocalId, valveType, seconds)
    webSocket.send(payload)
  }

  const sendRebootCommand = () => {
    let payload = getRebootPayload()
    webSocket.send(payload)
  }

  const sendFixSensorValueCommand = () => {
    let payload = getFixedSensorValuePayload(remoteUnitLocalId, sensor)
    webSocket.send(payload)
  }

  const sendDisableFixSensorValueCommand = () => {
    let payload = getDisableSensorOverridePayload(remoteUnitLocalId, sensor)
    webSocket.send(payload)
  }

  const openValveFieldAndButton = (
    <>
      <Grid item>
        <TextField
          variant="outlined"
          label="Valve Type"
          value={valveType}
          SelectProps={selectProps}
          onChange={handleValveTypeChange}
          select
          inputProps={{
            className: classes.SelectInput,
          }}
        >
          {valves.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <Typography>For</Typography>
      </Grid>
      <Grid item>
        <TextField
          variant="filled"
          value={seconds}
          onChange={handleSecondsChange}
          size="small"
          className={classes.SecondsInput}
          InputProps={{
            className: classes.SecondsInput,
          }}
        ></TextField>
      </Grid>
      <Grid item>
        <Button variant="text" color="primary" onClick={sendOpenValveCommand}>
          <Typography>Send</Typography>
        </Button>
      </Grid>
    </>
  )

  const sendRebootButton = (
    <Grid item>
      <Button variant="text" color="primary" onClick={sendRebootCommand}>
        <Typography>Reboot</Typography>
      </Button>
    </Grid>
  )

  const fixedSensorSelect = (
    <Grid item>
      <TextField
        variant="outlined"
        label="Valve Type"
        value={sensor}
        SelectProps={selectProps}
        onChange={handleSensorChange}
        select
        inputProps={{
          className: classes.SelectInput,
        }}
      >
        {sensors.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Grid>
  )

  const fixedSensorFieldAndButton = (
    <>
      {fixedSensorSelect}
      <Grid item>
        <Button
          variant="text"
          color="primary"
          onClick={sendFixSensorValueCommand}
        >
          <Typography>Send</Typography>
        </Button>
      </Grid>
    </>
  )
  const disableFixSensorFieldAndButton = (
    <>
      <Grid item>{fixedSensorSelect}</Grid>
      <Grid item>
        <Button
          variant="text"
          color="primary"
          onClick={sendDisableFixSensorValueCommand}
        >
          <Typography>Send</Typography>
        </Button>
      </Grid>
    </>
  )

  const commandToFields = {
    0: <></>,
    2: openValveFieldAndButton,
    3: sendRebootButton,
    4: fixedSensorFieldAndButton,
    5: disableFixSensorFieldAndButton,
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          variant="outlined"
          id="select-live-command"
          label="Commands"
          value={command}
          SelectProps={selectProps}
          onChange={handleCommandSelectChange}
          select
          inputProps={{
            className: classes.SelectInput,
          }}
        >
          {commands.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justify="space-evenly"
        spacing={1}
      >
        {commandToFields[command]}
      </Grid>
    </Grid>
  )
}

export default WebSocketCommandController
