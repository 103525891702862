import { TextField } from '@material-ui/core'
import React, { useState, useEffect, useCallback } from 'react'

function SearchMixCodeFieldForParams(props) {
  const {
    mixCodeInternal,
    setMixCodeInternal,
    addDataSettings,
    setAddDataSettings,
  } = props

  const [isFirstRender, setIsFirstRender] = useState(true)

  /*
   Only triggers changing the mix code after half a second of not typing, 
   otherwise prevents the mixCode from being set.

   This is to prevent unnecessary number of requests before a user finishes typing   
  */

  const handleFilterValueChange = useCallback(() => {
    setAddDataSettings(prevSettings => {
      if (isFirstRender) {
        setIsFirstRender(false)
        const currentMixCode = prevSettings.mixCode?.[0]
        if (currentMixCode) setMixCodeInternal(currentMixCode)
        return { ...prevSettings }
      }
      return {
        ...prevSettings,
        mixCode: mixCodeInternal ? [mixCodeInternal] : [],
      }
    })
  }, [isFirstRender, mixCodeInternal, setAddDataSettings, setMixCodeInternal])

  useEffect(() => {
    const timeOut = setTimeout(() => handleFilterValueChange(), 500)
    return () => clearTimeout(timeOut)
  }, [mixCodeInternal, setAddDataSettings, handleFilterValueChange])

  return (
    <TextField
      variant="outlined"
      onChange={event => setMixCodeInternal(event.target.value)}
      name="mixCode"
      placeholder="Search by Mix Code"
      size="small"
      value={mixCodeInternal}
      label="Mix Code"
      type="search"
      disabled={!addDataSettings.divisionId.length}
      InputLabelProps={{
        shrink: !!mixCodeInternal,
      }}
    />
  )
}
export default SearchMixCodeFieldForParams
