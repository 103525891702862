import React, { ChangeEvent } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import CompanyNameSelectPresentational from './CompanyNameSelectPresentational'
import { IConcreteCorporation } from '../../Interfaces/EchoInterfaces'
import { EMapLayers } from '../../Enums/EchoEnums'
import { comparedSort } from '../../../Common/Helpers/GeneralHelpers'

interface IComponentProps {
  filteredCorpData: Array<IConcreteCorporation>
}

function CompanyNameSelectLogical(props: IComponentProps) {
  const { filteredCorpData } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  /** All company names listed in Echo data */
  const divisionNames = new Set<string>()
  filteredCorpData.forEach(company =>
    company.divisions.map(division => divisionNames.add(division.divisionName))
  )
  const companyList = Array.from(divisionNames).sort(comparedSort)

  /** Handle input change. Pass selected filter value to useEffect in EchoHome. */
  const handleChange = (_event: ChangeEvent<{}>, value: string | null) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.companies = value ? [value] : []
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.companies = value ? [value] : []
      newEchoSettings.isGreenfield = false
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <CompanyNameSelectPresentational
      companyList={companyList}
      handleChange={handleChange}
    />
  )
}

export default CompanyNameSelectLogical
