import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Box,
} from '@material-ui/core'
import EchoTableHead from '../EchoTableHead/EchoTableHead'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import PropTypes from 'prop-types'
import RoomIcon from '@material-ui/icons/Room'
import { projectColors } from '../../Constants/Constants'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    height: '10px',
  },
  wideCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    width: '200px',
    overflow: 'hidden',
  },
}))

export const UpcomingProjectsTablePresentational = props => {
  UpcomingProjectsTablePresentational.propTypes = {
    /** Array of selected table rows */
    selectedRows: PropTypes.array.isRequired,
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** Function to sort the table rows */
    handleRequestSort: PropTypes.func.isRequired,
    /** Function to handle selecting table rows */
    handleClick: PropTypes.func.isRequired,
    /** Function to switch to a different table page */
    handleChangePage: PropTypes.func.isRequired,
    /** Change the number of table rows displayed per page */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** upcoming project data that has been returned after being filtered */
    filteredUpcomingProjects: PropTypes.array.isRequired,
  }

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleRequestSort,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    selectedRows,
    filteredUpcomingProjects,
  } = props

  const classes = useStyles()

  /** Keep track of which table rows are selected */
  const isSelected = name => selectedRows.indexOf(name) !== -1

  /** Table column headers */
  const headCells = [
    {
      id: 'organization',
      numeric: false,
      label: 'Org.',
    },
    {
      id: 'description',
      numeric: false,
      label: 'Desc.',
    },
    {
      id: 'formattedLocation',
      numeric: false,
      label: 'Loc.',
    },
    {
      id: 'formattedStartDate',
      numeric: false,
      label: 'Start',
    },
    {
      id: 'formattedSize',
      numeric: true,
      label: 'Size (m²)',
    },
    {
      id: 'formattedValue',
      numeric: true,
      label: 'Value',
    },
    {
      id: 'formattedStage',
      numeric: false,
      label: 'Stage',
    },
    {
      id: 'closestPlants',
      numeric: false,
      label: 'Closest Plants',
    },
  ]

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="upcoming projects"
            size={'small'}
          >
            <EchoTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              includeCheckbox={true}
            />
            <TableBody>
              {/* Map through the filtered data and display it as sortable rows */}
              {stableSort(
                filteredUpcomingProjects,
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  /** Keep track of selected rows */
                  const isItemSelected = isSelected(row)
                  const labelId = `enhanced-table-checkbox-${row.constructionProjectId}`

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.constructionProjectId}
                      selected={isItemSelected}
                    >
                      {/* Row checkbox */}
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          id={`${row.constructionProjectId}`}
                        />
                      </TableCell>
                      {/* Organization */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className={classes.wideCell}
                      >
                        <RoomIcon
                          style={{
                            color: row.isCcSpecified
                              ? projectColors.specified.hex
                              : projectColors.unspecified.hex,
                            fontSize: '20px',
                            verticalAlign: 'middle',
                            marginRight: '0.5em',
                          }}
                        />
                        {row.organization}
                      </TableCell>
                      {/* Description */}
                      <TableCell className={classes.wideCell}>
                        {row.description}
                      </TableCell>
                      {/* Location */}
                      <TableCell className={classes.wideCell}>
                        {row.formattedLocation}
                      </TableCell>
                      {/* Project start date */}
                      <TableCell>{row.formattedStartDate}</TableCell>
                      {/* Project size */}
                      <TableCell>{row.formattedSize}</TableCell>
                      {/* Project value */}
                      <TableCell className={classes.wideCell}>
                        {row.formattedValue}
                      </TableCell>
                      {/* Project stage */}
                      <TableCell className={classes.wideCell}>
                        {row.formattedStage}
                      </TableCell>
                      {/* Closest plants */}
                      <TableCell className={classes.wideCell}>
                        {row.closestPlants}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredUpcomingProjects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
