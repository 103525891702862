import { Grid, Typography, makeStyles } from '@material-ui/core'
import { FiberManualRecord, Remove } from '@material-ui/icons'
import React from 'react'
import { baseColors } from '../../../theme/colors'
import typography from '../../../theme/typography'

const useStyles = makeStyles(theme => ({
  legend: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  legendLabel: {
    fontSize: typography.highcharts.fontSize,
    fontFamily: typography.highcharts.fontFamily,
    fontWeight: typography.highcharts.fontWeight,
  },
  baseline: {
    backgroundColor: baseColors.secondary.main,
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginBottom: 4,
  },
  carbonCure: {
    backgroundColor: baseColors.primary.main,
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginBottom: 4,
  },
}))

export interface ITestResultsLegendProps {
  showDesignStrength?: boolean
  isPrinting: boolean
}

function TestResultsLegend(props: Readonly<ITestResultsLegendProps>) {
  const { showDesignStrength, isPrinting } = props
  const classes = useStyles()

  return (
    <Grid container direction="row" justify="center" spacing={2}>
      <Grid item className={classes.legend}>
        {isPrinting ? (
          <div className={classes.baseline} />
        ) : (
          <FiberManualRecord
            style={{ color: baseColors.secondary.main, fontSize: '12px' }}
          />
        )}
        <Typography className={classes.legendLabel}>Baseline</Typography>
      </Grid>
      <Grid item className={classes.legend}>
        {isPrinting ? (
          <div className={classes.carbonCure} />
        ) : (
          <FiberManualRecord
            style={{ color: baseColors.primary.main, fontSize: '12px' }}
          />
        )}
        <Typography className={classes.legendLabel}>CarbonCure</Typography>
      </Grid>
      {showDesignStrength && (
        <Grid item className={classes.legend}>
          <Remove
            style={{ color: baseColors.text.primary, fontSize: '12px' }}
          />
          <Typography className={classes.legendLabel}>
            Design Strength
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default TestResultsLegend
