import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import xor from 'lodash.xor'
import clonedeep from 'lodash.clonedeep'
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core'
import { useRecoilState } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import {
  isProjectSearchActive,
  isCO2DepotsSelected,
} from '../../Logic/FilterLogicHelpers'

const useStyles = makeStyles({
  formControlLabel: {
    fontSize: '14px',
  },
})

function OwnershipFilter() {
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)

  const classes = useStyles()

  /** Pass checkbox values to state */
  const handleChange = e => {
    const newSettings = clonedeep(echoSettings)
    if (e.target.name === 'greenfield') {
      newSettings.isGreenfield = !newSettings.isGreenfield
      setEchoSettings(newSettings)
      return
    }
    if (e.target.name === 'co2Connect') {
      newSettings.co2ConnectedToCustomers = !newSettings.co2ConnectedToCustomers
      setEchoSettings(newSettings)
      return
    }
    newSettings.ownership = xor(newSettings.ownership, [e.target.name])
    setEchoSettings(newSettings)
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            id="customer"
            name="customer"
            onChange={handleChange}
            checked={echoSettings.ownership.includes('customer')}
            disabled={isProjectSearchActive(echoSettings)}
            color="primary"
          />
        }
        label={
          <Typography className={classes.formControlLabel}>Customer</Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            id="privatelyOwned"
            name="privatelyOwned"
            onChange={handleChange}
            checked={echoSettings.ownership.includes('privatelyOwned')}
            disabled={isProjectSearchActive(echoSettings)}
            color="primary"
          />
        }
        label={
          <Typography className={classes.formControlLabel}>
            Privately owned
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            id="verticallyIntegrated"
            name="verticallyIntegrated"
            onChange={handleChange}
            checked={echoSettings.ownership.includes('verticallyIntegrated')}
            disabled={isProjectSearchActive(echoSettings)}
            color="primary"
          />
        }
        label={
          <Typography className={classes.formControlLabel}>
            Vertically integrated
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="greenfield"
            value={echoSettings.isGreenfield}
            checked={echoSettings.isGreenfield}
            onChange={handleChange}
            color="primary"
            id="greenfieldToggle"
            disabled={
              echoSettings.projects.length > 0 ||
              echoSettings.groups.length > 0 ||
              echoSettings.companies.length > 0 ||
              isProjectSearchActive(echoSettings)
            }
          />
        }
        label={
          <Typography className={classes.formControlLabel}>
            Greenfield
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            name="co2Connect"
            value={echoSettings.co2ConnectedToCustomers}
            checked={echoSettings.co2ConnectedToCustomers}
            onChange={handleChange}
            color="primary"
            id="co2Connect"
            disabled={!isCO2DepotsSelected(echoSettings)}
          />
        }
        label={
          <Typography className={classes.formControlLabel}>
            Connect CO2 to Customers
          </Typography>
        }
      />
    </FormGroup>
  )
}

export default OwnershipFilter
