import { GraphFilter } from './GraphFilterClass'
import { FreshPropertyNumberKeys, MixGroupVariation } from './Types'
import { testCategoryFilter } from './TSSLogic'
import { comparedSort } from '../../Common/Helpers/GeneralHelpers'

export default class GraphHelper {
  /**
   * Returns max and min values for a specified batch property.
   * @param {Object[]} selectedMixVariations array of mix variations.
   * @param {FreshPropertyNumberKeys} propertyName name of property to retrieve range values for.
   * @returns {{min: Number, max: Number}} object containing min and max values for the property.
   */
  static getRanges = (
    selectedMixVariations: MixGroupVariation[],
    propertyName: FreshPropertyNumberKeys
  ): { min: number; max: number } => {
    //is number | string | null because a freshProperty can be a date-timestamp, number, or null
    let min: string | number | null = null
    let max: string | number | null = null
    selectedMixVariations.forEach(mix => {
      mix.freshProperties.forEach(property => {
        const propValue = property[propertyName]
        if (propValue == null) return

        if (min == null || propValue < min) min = propValue
        if (max == null || propValue > max) max = propValue
      })
    })

    // Only defualt to 0 if all batches are missing values for the property.
    return { min: min ? Math.floor(min) : 0, max: max ? Math.ceil(max) : 0 }
  }

  /**
   * Calculates max/min values for each property in the selected mix variations.
   * @param {MixGroupVariation[]} selectedMixVariations array of mix variations.
   * @returns object containing max and min values for each property.
   */
  static minMaxOfFreshProperties = (
    selectedMixVariations: MixGroupVariation[]
  ) => {
    return {
      air: this.getRanges(selectedMixVariations, 'air'),
      slump: this.getRanges(selectedMixVariations, 'slump'),
      ambientTemperature: this.getRanges(
        selectedMixVariations,
        'ambientTemperature'
      ),
      batchCementContent: this.getRanges(
        selectedMixVariations,
        'batchCementContent'
      ),
      batchStrength: this.getRanges(selectedMixVariations, 'batchStrength'),
      batchWaterCementRatio: this.getRanges(
        selectedMixVariations,
        'batchWaterCementRatio'
      ),
      co2DosageLabel: this.getRanges(selectedMixVariations, 'co2DosageLabel'),
      concreteTemperature: this.getRanges(
        selectedMixVariations,
        'concreteTemperature'
      ),
    }
  }

  /**
   * Calculates max dataset sizes (number of strength readings) across all selected mix variations.
   * @param {Object[]} selectedMixVariations array of mix variations.
   * @returns {{min: Number, max: Number}} object containing min and max values for the dataset sizes.
   */
  static computeMinMaxDataSetSizes = (
    selectedMixVariations: MixGroupVariation[]
  ): { min: number; max: number } => {
    const dataSetSizes = selectedMixVariations.map(
      mix => mix.freshProperties.length
    )
    return { min: Math.min(...dataSetSizes), max: Math.max(...dataSetSizes) }
  }

  /**
   * Calculates max/min values for each property in a selected mix design.
   * @param {MixGroupVariation[]} selectedMixVariations array of mix variations.
   * @returns object containing max/min values for each property, or default values (0).
   */
  static computeMinMaxForSelectedMixes = (
    selectedMixVariations: MixGroupVariation[]
  ) => {
    //check if one or two mixes have been selected
    if (selectedMixVariations.length) {
      return {
        ...this.minMaxOfFreshProperties(selectedMixVariations),
        dataSetSize: this.computeMinMaxDataSetSizes(selectedMixVariations),
      }
    } else {
      return {
        air: { min: 0, max: 0 },
        ambientTemperature: { min: 0, max: 0 },
        batchCementContent: { min: 0, max: 0 },
        batchStrength: { min: 0, max: 0 },
        batchWaterCementRatio: { min: 0, max: 0 },
        co2DosageLabel: { min: 0, max: 0 },
        concreteTemperature: { min: 0, max: 0 },
        slump: { min: 0, max: 0 },
        dataSetSize: { min: 0, max: 0 },
      }
    }
  }

  /**
   * Fetches all unique production dates from a list of selected mix variations.
   * @param {MixGroupVariation[]} selectedMixVariations currently selected variations with batches displayed in graph.
   * @returns {String[]} all unique production dates used to filter data by.
   */
  static collectProductionDates = (
    selectedMixVariations: MixGroupVariation[]
  ): string[] => {
    const uniqueProductionDates = new Set<string>()
    selectedMixVariations.forEach(design => {
      design.productionDates.forEach(date => uniqueProductionDates.add(date))
    })
    return Array.from(uniqueProductionDates).sort(comparedSort)
  }

  /**
   * Fetches all batchIntervals  from a list of selected mix variations.
   * @param {MixGroupVariation[]} selectedMixVariations currently selected variations with batches displayed in graph.
   * @returns {Number[]} all unique batchIntervals used to filter data by.
   */
  static collectBatchIntervals = (
    selectedMixVariations: MixGroupVariation[]
  ): number[] => {
    const uniqueBatchIntervals = new Set<number>()
    selectedMixVariations.forEach(design => {
      design.freshProperties.forEach(freshProperty => {
        if (freshProperty.batchInterval !== null)
          uniqueBatchIntervals.add(freshProperty.batchInterval)
      })
    })
    return Array.from(uniqueBatchIntervals).sort((a, b) => a - b)
  }

  /**
   * Filters data displayed in a graph using the selected filters and their min/max values.
   * @param {MixGroupVariation[]} selectedMixVariations currently selected variations with batches displayed in graph.
   * @param {GraphFilter[]} selectedFilters currently active filters.
   * @returns {MixGroupVariation[]} subset of selectedMixVariations array with the filtered data.
   */
  static filterGraphData = (
    selectedMixVariations: MixGroupVariation[],
    selectedFilters: GraphFilter[]
  ): MixGroupVariation[] => {
    let filteredData = [...selectedMixVariations]
    selectedFilters.forEach(filter => {
      if (filter.property == null || filter.isDisabled) return
      filteredData = this.filterGraphSingleProperty(filteredData, filter)
    })

    return filteredData
  }

  /**
   * Filters graph data using values from a single filter.
   * @param {Object[]} data mix variations with batch data to be filtered.
   * @param {Object} graphFilter filter currently being applied.
   * @returns {Object[]} subset of the data passed as an argument.
   */
  static filterGraphSingleProperty = (
    data: MixGroupVariation[],
    graphFilter: GraphFilter
  ) => {
    if (graphFilter.property === 'dataSetSize') {
      return this.filterDesignsByNumSamples(data, graphFilter)
    } else {
      return this.filterBatchesByProperty(data, graphFilter)
    }
  }

  /**
   * Removes mix variations from the dataset based on their strength reading count. Used with dataSetSize filter property only.
   * @param {MixGroupVariation[]} data mix variations with batch data to be filtered.
   * @param {GraphFilter} graphFilter filter currently being applied.
   * @returns {MixGroupVariation[]} subset of the data passed as an argument.
   */
  static filterDesignsByNumSamples = (
    data: MixGroupVariation[],
    graphFilter: GraphFilter
  ): MixGroupVariation[] => {
    return data.filter(design => {
      const strengthReadingsCount = design.freshProperties.length
      return (
        //The selectedMin/Max values are never null if this
        strengthReadingsCount >=
          (graphFilter.selectedMinValue as string | number) &&
        strengthReadingsCount <=
          (graphFilter.selectedMaxValue as string | number)
      )
    })
  }

  /**
   * Filters graph data with any property outside of dataSetSize.
   * @param {MixGroupVariation[]} data mix variations with batch data to be filtered.
   * @param {GraphFilter} graphFilter filter currently being applied.
   * @param {Boolean} includeNull bool indicating whether null values should be included in the filtered dataset
   * @returns {MixGroupVariation[]} subset of the data passed as an argument.
   */
  static filterBatchesByProperty = (
    data: MixGroupVariation[],
    graphFilter: GraphFilter,
    includeNull: boolean = false
  ): MixGroupVariation[] => {
    if (graphFilter.property === null) {
      return data
    }
    return data.map(design => {
      const filteredSamples = design.freshProperties.filter(batch => {
        //we check above if the graphFilter.propery is null
        const batchProperty =
          batch[graphFilter.property as FreshPropertyNumberKeys]

        // Include batches if values for filter property are null.
        if (includeNull && batchProperty === null) {
          return true
        }

        //added since typescript likes things to be explicit, if a batchProperty is null it should be excluded
        if (batchProperty === null) {
          return false
        }

        if (graphFilter.property === 'batchInterval') {
          return graphFilter.selectedOptions.includes(batchProperty)
        }

        if (graphFilter.property === 'testCategory') {
          return testCategoryFilter(graphFilter, batchProperty)
        }

        return (
          // The selectedMax and selectedMin are never null once it's reached to this function
          batchProperty >= (graphFilter.selectedMinValue as string | number) &&
          batchProperty <= (graphFilter.selectedMaxValue as string | number)
        )
      })
      return { ...design, freshProperties: filteredSamples }
    })
  }
}
