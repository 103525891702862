import NotificationsIcon from '@material-ui/icons/Notifications'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'

const alertClasses = makeStyles({
  yellowAlert: {
    color: '#F4D334',
  },
})

AlarmIcon.propTypes = {
  alertLevel: PropTypes.number.isRequired,
}

AlarmIcon.defaultProps = {
  alertLevel: 0,
}

function AlarmIcon({ alertLevel }) {
  const classes = alertClasses()
  const HighAlertNotification = (
    <Tooltip title="Immediate Attention Required">
      <NotificationsIcon color="error" />
    </Tooltip>
  )
  const MediumAlertNotification = (
    <Tooltip title="A problem has occured, unit is running suboptimally">
      <NotificationsIcon color="primary" />
    </Tooltip>
  )

  const LowAlertNotification = (
    <Tooltip title="A problem has occured, unit still operating">
      <NotificationsIcon
        color="action"
        classes={{ colorAction: classes.yellowAlert }}
      />
    </Tooltip>
  )

  const alertNotifications = {
    0: <></>,
    1: LowAlertNotification,
    2: MediumAlertNotification,
    3: HighAlertNotification,
  }

  return alertNotifications[alertLevel]
}

export default AlarmIcon
