import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import HistoryIcon from '@material-ui/icons/History'
import EditableCellBaseLogical from '../EditableCell/EditableCellBaseLogical'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { atomPlantsFiltersOptions } from '../../../Common/tssAtoms'
import { atomPlantTemporal } from '../../../Common/tssNewAtoms'
import { PlantsEditableCellDropdown } from '../EditableCell/EditableCell.types'
import PlantsTableRow from './PlantsTableRow'
import cloneDeep from 'lodash.clonedeep'

interface PlantsEditableTableRowProps {
  /** Data presented in current row, corresponds with one plant. */
  rowData: PlantsTableRow
  /** Updates single row from table. Each row corresponds with one plant. */
  updateTableRow: (...args: any[]) => any
}

function PlantsEditableTableRow(props: PlantsEditableTableRowProps) {
  const { rowData, updateTableRow } = props

  const [internalRowData, setInternalRowData] = useState(rowData)
  const plantsFiltersOptions = useRecoilValue(atomPlantsFiltersOptions)
  const setPlantTemporal = useSetRecoilState(atomPlantTemporal)
  const tseList = plantsFiltersOptions.tse

  const setInternalValue = (
    property: keyof PlantsTableRow,
    newValue: string | number | PlantsEditableCellDropdown
  ) => {
    setInternalRowData(prevValues => {
      const updatedRow = cloneDeep(prevValues)
      updatedRow.edited[property] = newValue
      return updatedRow
    })
  }

  useEffect(() => {
    setInternalRowData(rowData)
  }, [rowData, rowData.edited.isMetric])

  useEffect(() => {
    setInternalRowData(rowData)
  }, [rowData])

  return (
    <TableRow hover>
      <TableCell padding="checkbox" style={{ paddingLeft: '1.5em' }}>
        <Tooltip arrow title="View historical data">
          <IconButton
            color="primary"
            onClick={() => {
              setPlantTemporal(rowData)
            }}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography>{rowData.original.divisionName}</Typography>
      </TableCell>
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="name"
        minWidthEm={12}
        isNoted
      />
      <TableCell align="left">
        <Typography>{rowData.original.state}</Typography>
      </TableCell>
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="cO2DosePercent"
        associatedProperty="cO2DosePercentTimestamp"
        adornment="percentage"
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="cO2DosePercentTimestamp"
        associatedProperty="cO2DosePercent"
        isDate
        minWidthEm={16}
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="averageCementCut"
        associatedProperty="averageCementCutTimestamp"
        adornment="percentage"
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="averageCementCutTimestamp"
        associatedProperty="averageCementCut"
        isDate
        minWidthEm={16}
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="averageCementLoading"
        associatedProperty="averageCementLoadingTimestamp"
        adornment="density"
        minWidthEm={12}
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="averageCementLoadingTimestamp"
        associatedProperty="averageCementLoading"
        isDate
        minWidthEm={16}
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="yardsPerTruck"
        adornment="loadSize"
        isNoted
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="tankRentalFee"
        adornment="dollars"
        isNoted
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="residentialPercent"
        adornment="percentage"
        isNoted
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="commercialPercent"
        adornment="percentage"
        isNoted
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="dotPercent"
        adornment="percentage"
        isNoted
      />
      <TableCell align="left" style={{ minWidth: '6em' }}>
        <Typography>{rowData.original.installDate}</Typography>
      </TableCell>
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="tse"
        dropdownOptions={tseList}
        minWidthEm={18}
        focused={false}
        disabled={true}
      />
      <EditableCellBaseLogical
        rowData={rowData}
        updateTableRow={updateTableRow}
        internalRowData={internalRowData}
        setInternalValue={setInternalValue}
        property="tssNote"
        minWidthEm={50}
      />
    </TableRow>
  )
}

export default PlantsEditableTableRow
