import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Paper,
  Box,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import HistoricalTableRow from './HistoricalTableRow'
import GeneralizedEnhancedTableHead from '../../../Common/Components/GeneralizedEnhancedTableHead'
import { HistoricalTableHeadCells } from './HistoricalTableHeadCells'
import { useRecoilState } from 'recoil'
import { atomPlantTemporal } from '../../../Common/tssNewAtoms'
import {
  PlantTemporal,
  PlantsTableRowObject,
} from '../PlantsEditableTable/PlantsEditableTable.types'
import { roundUpToDecimal } from '../../../Common/Helpers/GeneralHelpers'
import { getFormattedDate } from '../../Logic/TSSLogic'
import { baseColors } from '../../../theme/colors'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    color: baseColors.error.main,
  },
}))

function HistoricalTablePresentational() {
  const [
    plantTemporal,
    setPlantTemporal,
  ] = useRecoilState<PlantsTableRowObject | null>(atomPlantTemporal)

  const classes = useStyles()

  if (plantTemporal !== null) {
    return (
      <Dialog
        id="historicalTable"
        open={plantTemporal !== null}
        onClose={() => {
          setPlantTemporal(null)
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justify="space-between">
            <Typography variant="h2">
              Historical Data: {plantTemporal.original.divisionName} -{' '}
              {plantTemporal.original.displayName}
            </Typography>
            <IconButton
              className={classes.closeIcon}
              onClick={() => {
                setPlantTemporal(null)
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box mt={2} mb={3}>
            <Paper variant="outlined">
              <TableContainer>
                <Table size="small">
                  <GeneralizedEnhancedTableHead
                    headCells={HistoricalTableHeadCells}
                    onRequestSort={() => ({})}
                    order={'asc'}
                    orderBy={''}
                  />

                  <TableBody>
                    {plantTemporal.original.plantTemporals.map(
                      (rowData: PlantTemporal) => (
                        <HistoricalTableRow
                          key={rowData.timestamp}
                          rowData={[
                            getFormattedDate(
                              rowData.timestamp ? rowData.timestamp : '',
                              'YYYYMMDD',
                              '-',
                              true
                            ),
                            rowData.cementPlantName,
                            rowData.cO2DosePercent + ' %',
                            rowData.averageCementCut + ' %',
                            rowData.averageCementLoadingKgPerM3 == null
                              ? ''
                              : roundUpToDecimal(
                                  rowData.averageCementLoadingKgPerM3
                                ).toString(),
                          ]}
                        />
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
  return null
}

export default HistoricalTablePresentational
