import React from 'react'
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

function OnsiteService(props) {
  const { inputClassName, disabled } = props

  const { register, errors, watch, setValue } = useFormContext()

  return (
    <Grid item container justify="space-between" lg={12}>
      <Grid item lg={5}>
        <TextField
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          className={inputClassName}
          name="criticalRepairPlan"
          label="Critical Repair Plan"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors?.criticalRepairPlan}
          helperText={!!errors?.criticalRepairPlan?.message}
        />

        <TextField
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          className={inputClassName}
          name="actionsTakenOnsite"
          label="Action Taken Onsite"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors?.actionsTakenOnsite}
          helperText={!!errors?.actionsTakenOnsite?.message}
        />
        <TextField
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          className={inputClassName}
          name="replacedComponents"
          label="Replaced Components"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors?.replacedComponents}
          helperText={!!errors?.replacedComponents?.message}
        />
      </Grid>
      <Grid item lg={5}>
        <TextField
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          className={inputClassName}
          name="onsiteTechnician"
          label="CarbonCure Onsite Technician"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors?.onsiteTechnician}
          helperText={!!errors?.onsiteTechnician?.message}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="anticipatedRepairDate"
          label="Anticipated Repair Date"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          size="small"
          inputRef={register}
          error={!!errors?.anticipatedRepairDate}
          helperText={!!errors?.anticipatedRepairDate?.message}
        />
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox color="primary" />}
          name="arrivedOnsite"
          label="Arrived Onsite"
          onChange={e => setValue('arrivedOnsite', e.target.checked)}
          checked={watch('arrivedOnsite')}
        />
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox color="primary" />}
          name="solvedOnsite"
          label="Solved Onsite"
          onChange={e => setValue('solvedOnsite', e.target.checked)}
          checked={watch('solvedOnsite')}
        />
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox color="primary" />}
          name="onsiteServiceRequired"
          label="Onsite Service Required"
          onChange={e => setValue('onsiteServiceRequired', e.target.checked)}
          checked={watch('onsiteServiceRequired')}
        />
        <FormControlLabel
          disabled={disabled}
          className={inputClassName}
          name="repairPlanApproved"
          label="Repair Plan Approved"
          control={<Checkbox color="primary" />}
          onChange={e => setValue('repairPlanApproved', e.target.checked)}
          checked={watch('repairPlanApproved')}
        />
      </Grid>
    </Grid>
  )
}

OnsiteService.propTypes = {
  inputClassName: PropTypes.string,
}

OnsiteService.defaultProps = {
  inputClassName: null,
}

export default OnsiteService
