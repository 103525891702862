import React, { useEffect, useState } from 'react'
import MergeMaterialModalPresentational from './MergeMaterialModalPresentational'
import {
  IDigestedMaterial,
  MaterialManagerComponent,
  MergeMaterialType,
} from '../../Logic/Types'
import {
  getMaterialsByDivisionId,
  putMergeMaterial,
} from '../../Data/TSSDataHelpers'
import { digestMaterials, getMergeableMaterials } from '../../Logic/TSSLogic'
import { isUnclassifiedMaterial } from '../../Helpers/MaterialManagerHelpers'

export interface IMergeMaterialModalLogicalProps {
  /** Determines if modal is open */
  modalOpen: boolean
  /** Controls the opening of the modal */
  setModalOpen: (arg0: boolean) => void
  /** Controls the opening of the filter panal */
  setIsFilterPanelOpen: (arg0: boolean) => void
  /** Contains the current material data */
  currentMaterial: IDigestedMaterial
  /** Handles the material editing functionality */
  handleEditMaterial: (id: number) => void
  /** Function to set the current view in the Material Manager Component */
  setCurrentView: (arg0: MaterialManagerComponent) => void
}

function MergeMaterialModalLogical(props: IMergeMaterialModalLogicalProps) {
  const {
    modalOpen,
    setModalOpen,
    setIsFilterPanelOpen,
    currentMaterial,
    handleEditMaterial,
    setCurrentView,
  } = props

  const [modalType, setModalType] = useState<MergeMaterialType>()
  const [mergeableMaterials, setMergeableMaterials] = useState<
    Array<IDigestedMaterial>
  >([])
  const [selectedMaterial, setSelectedMaterial] = useState<IDigestedMaterial>()
  const [isLoading, setIsLoading] = useState(false)

  /** Get mergeable materials of the same parent */
  useEffect(() => {
    if (!currentMaterial) return

    setIsLoading(true)
    getMaterialsByDivisionId(Number(currentMaterial.divisionId))
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const digestedMaterials = digestMaterials(data)
            const mergeableMaterials = getMergeableMaterials(
              digestedMaterials,
              currentMaterial,
              isUnclassifiedMaterial(currentMaterial)
            )
            const modalType = mergeableMaterials.length
              ? MergeMaterialType.MERGE
              : MergeMaterialType.EDIT
            setMergeableMaterials(mergeableMaterials)
            setModalType(modalType)
            setIsLoading(false)
          })
      })
      .catch(e => console.log(e))
  }, [currentMaterial, setMergeableMaterials])

  const handleCancel = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setModalOpen(false)
  }

  const handleMerge = () => {
    setIsLoading(true)
    if (currentMaterial && selectedMaterial) {
      const source = currentMaterial.materialMappingId
      const target = selectedMaterial.materialMappingId

      putMergeMaterial(source, target)
        .then(res => {
          if (res.ok)
            res.json().then(() => {
              setIsLoading(false)
              setModalType(MergeMaterialType.SUCCESS)
              setTimeout(() => {
                window.location.reload()
                setModalOpen(false)
              }, 3500)
            })
        })
        .catch(e => console.log(e))
    }
  }

  const handleEdit = (id: number) => {
    handleEditMaterial(id)
    setIsFilterPanelOpen(false)
    setCurrentView(MaterialManagerComponent.EditMaterial)
  }

  return (
    <MergeMaterialModalPresentational
      modalType={modalType}
      currentMaterial={currentMaterial}
      modalOpen={modalOpen}
      isLoading={isLoading}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
      handleMerge={handleMerge}
      mergeableMaterials={mergeableMaterials}
      selectedMaterial={selectedMaterial}
      setSelectedMaterial={setSelectedMaterial}
    />
  )
}

export default MergeMaterialModalLogical
