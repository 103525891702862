import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SimpleTableTypes } from '../../../Common/Logic/Types'
import { baseColors } from '../../../theme/colors'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import MixGroupVariationTable from '../MixGroupVariationTable/MixGroupVariationTable'
import FilterButton from '../../../Common/Components/FilterPanel/FilterButton'
import FilterPanelChips from '../../../Common/Components/FilterPanel/FilterPanelChips'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '2em',
  },
  tableHeadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
}))

export default function MixDesignTablePresentational(props) {
  MixDesignTablePresentational.propTypes = {
    /** Mix design data processed from digestMixDesigns in GeneralHelpers */
    rows: PropTypes.array.isRequired,
    /** Producer list acquired from digesting the mixdesigns */
    producerList: PropTypes.array.isRequired,
    /** Supplier list acquired from digesting the mixdesigns */
    supplierList: PropTypes.array.isRequired,
    /** Number of rows to be shown for a page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Number of current page to calculate offset */
    page: PropTypes.number.isRequired,
    /** column selected for sorting */
    orderBy: PropTypes.string.isRequired,
    /** ascending or descending order style */
    order: PropTypes.string.isRequired,
    /** The number of rows returned by the endpoint */
    rowCount: PropTypes.number.isRequired,
    /* Function to set the producer and supplier based on current chips selected */
    setParameters: PropTypes.func.isRequired,
    /* Function to set producer drop down list values to the state variables */
    setProducerValues: PropTypes.func.isRequired,
    /* Function to set supplier drop down list values to the state variables */
    setSupplierValues: PropTypes.func.isRequired,
    /* Function to sort the table */
    handleRequestSort: PropTypes.func.isRequired,
    /* Function to select/unselect a table row */
    handleClick: PropTypes.func.isRequired,
    /* Function to change the page of the table */
    handleChangePage: PropTypes.func.isRequired,
    /* Function to change the number of table rows to display */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** List of selected supplier for filter  */
    selectedSupplier: PropTypes.array.isRequired,
    /** List of selected producer for filter */
    selectedProducer: PropTypes.array.isRequired,
    /** The selected rows of mix design table */
    selectedMixDesigns: PropTypes.array.isRequired,
    /** The selected variation rows of mix design table */
    selectedMixVariations: PropTypes.array.isRequired,
    /** The type of table */
    tableType: PropTypes.string.isRequired,
    /** Classes defined in parent */
    parentClasses: PropTypes.object.isRequired,
    /** Mix group table data */
    tableData: PropTypes.array.isRequired,
    /** Function to set altered mix group table data globally */
    setTableData: PropTypes.func.isRequired,
    /** Settings for mix group table */
    tableSettings: PropTypes.object.isRequired,
    /** Function to set altered mix group table settings */
    setTableSettings: PropTypes.func.isRequired,
    /** Filter settings for mix table results */
    analysisFilterSettings: PropTypes.object.isRequired,
    /** Function to set filter settings for mix table results */
    setAnalysisFilterSettings: PropTypes.func.isRequired,
    /** Boolean that indicates whether the filter panel is open or not */
    isFilterPanelOpen: PropTypes.bool.isRequired,
    /** Function that opens and closes the filter panel */
    onFilterBtnClickHandler: PropTypes.func.isRequired,
    /** Boolean indicating whether or not the measurement system is metric */
    isMetric: PropTypes.bool.isRequired,
  }
  const {
    rows,
    rowCount,

    parentClasses,
    tableData,
    setTableData,
    tableSettings,
    setTableSettings,

    isFilterPanelOpen,
    onFilterBtnClickHandler,
    filterCount,
    activeFilters,
    deleteChipHandler,
    deleteAllChipsHandler,
    isMetric,
  } = props
  const classes = useStyles()
  return (
    <>
      <Grid
        className={classes.tableHeadContainer}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        item
        xs={12}
      >
        <Grid item lg={4}>
          <FilterButton
            open={isFilterPanelOpen}
            parentClasses={parentClasses}
            filterCount={filterCount}
            onClickHandler={onFilterBtnClickHandler}
          />
        </Grid>
        <Grid
          item
          xs={8}
          wrap="nowrap"
          container
          direction="row"
          justify="flex-end"
          spacing={4}
        >
          <Grid
            item
            style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <FiberManualRecordIcon
              style={{ color: baseColors.variations.baseline }}
            />{' '}
            <Typography>Baseline Variation</Typography>
          </Grid>
          <Grid
            item
            style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <FiberManualRecordIcon
              style={{ color: baseColors.variations.optimized }}
            />{' '}
            <Typography>Optimized Variation</Typography>
          </Grid>
        </Grid>
      </Grid>
      <FilterPanelChips
        activeFilters={activeFilters}
        chipClickHandler={deleteChipHandler}
        buttonClickHandler={deleteAllChipsHandler}
      />
      <Paper className={classes.paper} variant="outlined">
        <MixGroupVariationTable
          isAssociationView={false}
          setTableData={setTableData}
          tableData={tableData}
          setTableSettings={setTableSettings}
          tableSettings={tableSettings}
          parentClasses={parentClasses}
          roles={[]}
          mixCodeToFilter={''}
          rowCount={rowCount}
          tableType={SimpleTableTypes.ViewDesignMixGroup}
          rows={rows}
          isMetric={isMetric}
        />
      </Paper>
    </>
  )
}
