import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import GraphHelper from '../Logic/GraphHelper'
import GraphFiltersContainerPresentational from './GraphFiltersContainerPresentational'
import { GraphFilter } from '../Logic/GraphFilterClass'
import cloneDeep from 'lodash.clonedeep'
import { TestCategories } from '../Logic/Types'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { atomGraphSelectedFilters } from '../../Common/tssAtoms'
import {
  additionalFilters,
  freshPropertyLabelsWithImperialUnit,
} from '../Constants/AnalysisConstants'

export default function GraphFiltersContainerLogical(props) {
  GraphFiltersContainerLogical.propTypes = {
    /** Function used to filter the graph data, depends on currently selected filters. */
    setFilteredData: PropTypes.func.isRequired,
    /** Array of selected mix variations (including batch data) currently displayed in the graph. */
    selectedMixVariations: PropTypes.array.isRequired,
    /** Displayed graph. */
    graphType: PropTypes.string.isRequired,
    /** Function that updates the selected interval for the graphs */
    updateSelectedAge: PropTypes.func.isRequired,
    /* Whether or not the measurement system is metric */
    isMetric: PropTypes.bool.isRequired,
  }

  const {
    setFilteredData,
    selectedMixVariations,
    graphType,
    updateSelectedAge,
    isMetric,
  } = props

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    atomGraphSelectedFilters
  )

  const memoizedMinMaxForAllProperties = useMemo(
    () => GraphHelper.computeMinMaxForSelectedMixes(selectedMixVariations),
    [selectedMixVariations]
  )

  const memoizedBatchIntervals = useMemo(
    () => GraphHelper.collectBatchIntervals(selectedMixVariations),
    [selectedMixVariations]
  )

  const memoizedProductionDates = useMemo(
    () => GraphHelper.collectProductionDates(selectedMixVariations),
    [selectedMixVariations]
  )
  const resetSelectedGraphFilters = useResetRecoilState(
    atomGraphSelectedFilters
  )

  useEffect(() => {
    resetSelectedGraphFilters()
  }, [selectedMixVariations, resetSelectedGraphFilters])

  /** Use effect sets current filter range when the min/max values are updated. */
  useEffect(() => {
    setSelectedFilters(prevFilters => {
      const prevFiltersCopy = cloneDeep(prevFilters)
      prevFiltersCopy.forEach(filter => {
        if (filter.property == null) {
          filter.updateFilterRange()
        } else {
          if (filter.property !== 'batchInterval') {
            const { min, max } = memoizedMinMaxForAllProperties[filter.property]
            filter.updateFilterRange(min, max)
          }
          if (filter.property === 'batchInterval') {
            const age = filter.updateOptions(memoizedBatchIntervals)
            updateSelectedAge(age[0])
          }
        }
      })
      return [...prevFiltersCopy]
    })
  }, [
    memoizedMinMaxForAllProperties,
    memoizedBatchIntervals,
    updateSelectedAge,
    setSelectedFilters,
  ])

  /** Use effect updates graph data when filter values are altered. */
  useEffect(() => {
    const filteredData = GraphHelper.filterGraphData(
      selectedMixVariations,
      selectedFilters
    )
    setFilteredData(filteredData)
  }, [selectedMixVariations, selectedFilters, setFilteredData])

  /**
   * Updates a filters selected range, indirectly filtering the data displayed in the graph.
   * @param {GraphFilter} graphFilter the filter being updated.
   * @param {Number | String} minValue the new minimum selected value.
   * @param {Number | String} maxValue the new max selected value.
   */
  const updateFilterSelectedRange = (graphFilter, minValue, maxValue) => {
    setSelectedFilters(prevFilters => {
      const filterToUpdateIdx = prevFilters.findIndex(
        filter => filter === graphFilter
      )
      const filterToUpdate = prevFilters[filterToUpdateIdx]
      const filterToUpdateCopy = cloneDeep(filterToUpdate)
      filterToUpdateCopy.updateSelectedRange(minValue, maxValue)
      const prevFiltersCopy = cloneDeep(prevFilters)
      prevFiltersCopy[filterToUpdateIdx] = filterToUpdateCopy
      return [...prevFiltersCopy]
    })
  }

  /** Disable age filter on strenght chart*/
  useEffect(() => {
    const newFilters = cloneDeep(selectedFilters)
    const ageFilter = newFilters.find(x => x.property === 'batchInterval')
    if (ageFilter) {
      if (
        (graphType === 'Strength Development' || graphType === 'Bar') &&
        !ageFilter.isDisabled
      ) {
        ageFilter.updateDisable(true)
        setSelectedFilters(newFilters)
      }
      if (
        graphType !== 'Strength Development' &&
        graphType !== 'Bar' &&
        ageFilter.isDisabled
      ) {
        ageFilter.updateDisable(false)
        setSelectedFilters(newFilters)
      }
    }
  }, [graphType, selectedFilters, setSelectedFilters, setFilteredData])

  const updateFilterSelectedOptions = (graphFilter, selectedOptions) => {
    setSelectedFilters(prevFilters => {
      const prevFiltersCopy = cloneDeep(prevFilters)
      const filterToUpdateIdx = prevFilters.findIndex(
        filter => filter === graphFilter
      )
      const filterToUpdate = prevFilters[filterToUpdateIdx]
      const filterToUpdateCopy = cloneDeep(filterToUpdate)
      filterToUpdateCopy.updateSelectedOptions(selectedOptions)
      prevFiltersCopy[filterToUpdateIdx] = filterToUpdateCopy
      return [...prevFiltersCopy]
    })
  }

  /**
   * Updates the property used to filter data by.
   * @param {GraphFilter} graphFilter the filter being updated.
   * @param {String} property the new property to use with the filter.
   */
  const updateFilterProperty = (graphFilter, property, label) => {
    setSelectedFilters(prevFilters => {
      const filterToUpdateIdx = prevFilters.findIndex(
        filter => filter === graphFilter
      )
      const filterToUpdate = prevFilters[filterToUpdateIdx]
      const filterToUpdateCopy = cloneDeep(filterToUpdate)
      if (property == null) {
        filterToUpdateCopy.updateProperty()
      } else if (property === 'productionDate') {
        const dateRangeStart = memoizedProductionDates[0]
        const dateRangeEnd =
          memoizedProductionDates[memoizedProductionDates.length - 1]

        filterToUpdateCopy.updateProperty(
          property,
          dateRangeStart,
          dateRangeEnd,
          memoizedProductionDates
        )
        filterToUpdateCopy.updateLabelProperty(label)
      } else if (property === 'testCategory') {
        filterToUpdateCopy.updateProperty(
          property,
          null,
          null,
          Object.keys(TestCategories)
        )
        filterToUpdateCopy.updateLabelProperty(label)
      } else {
        const { min, max } = memoizedMinMaxForAllProperties[property]
        filterToUpdateCopy.updateProperty(property, min, max)
        filterToUpdateCopy.updateLabelProperty(label)
      }
      const prevFiltersCopy = cloneDeep(prevFilters)
      prevFiltersCopy[filterToUpdateIdx] = filterToUpdateCopy
      return [...prevFiltersCopy]
    })
  }

  const updateFilterExpandedProperty = graphFilter => {
    setSelectedFilters(prevFilters => {
      const filterToUpdateIdx = prevFilters.findIndex(
        filter => filter === graphFilter
      )
      const filterToUpdate = prevFilters[filterToUpdateIdx]
      const currentExpanded = filterToUpdate.expanded
      const filterToUpdateCopy = cloneDeep(filterToUpdate)
      const prevFiltersCopy = cloneDeep(prevFilters)
      filterToUpdateCopy.updateExpandedProperty(!currentExpanded)
      prevFiltersCopy[filterToUpdateIdx] = filterToUpdateCopy
      return [...prevFiltersCopy]
    })
  }

  /**
   * Adds a blank filter to the sidebar.
   */
  const addFilter = () => {
    setSelectedFilters(currentFilters => {
      const currentFiltersCopy = cloneDeep(currentFilters)
      currentFiltersCopy.push(new GraphFilter())
      return [...currentFiltersCopy]
    })
  }

  // don't allow users to add more filters than the total amount of filter options available
  const disableAddFilterBtn =
    freshPropertyLabelsWithImperialUnit.concat(additionalFilters).length ===
    selectedFilters.length
  return (
    <GraphFiltersContainerPresentational
      memoizedMinMaxForAllProperties={memoizedMinMaxForAllProperties}
      updateFilterSelectedRange={updateFilterSelectedRange}
      updateFilterSelectedOptions={updateFilterSelectedOptions}
      updateFilterProperty={updateFilterProperty}
      selectedFilters={selectedFilters}
      addFilter={addFilter}
      updateSelectedAge={updateSelectedAge}
      updateFilterExpandedProperty={updateFilterExpandedProperty}
      disableAddFilterBtn={disableAddFilterBtn}
      isMetric={isMetric}
    />
  )
}
