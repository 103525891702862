const defaultHead = {
  numeric: false,
  disablePadding: false,
  sortable: false,
}
export const HistoricalTableHeadCells = [
  {
    ...defaultHead,
    id: 'date',
    label: 'Date',
  },
  {
    ...defaultHead,
    id: 'cementSupply',
    label: 'Cement Supply',
  },
  {
    ...defaultHead,
    id: 'cO2DosePercent',
    label: 'CO\u2082 Dosage',
  },
  {
    ...defaultHead,
    id: 'averageCementCut',
    label: 'Cement Reduction',
  },
  {
    ...defaultHead,
    id: 'averageCementLoading',
    label: 'Avg Cement Loading',
  },
]
