import { ScatterplotLayer } from '@deck.gl/layers'
import { projectColors } from '../../../Constants/Constants'

export function getCarbonCureProjectsLayer(filteredOngoingProjects, ongoing) {
  return [
    new ScatterplotLayer({
      radiusUnits: 'pixels',
      id: ongoing ? 'ongoingProjects' : 'finishedProjects',
      data: filteredOngoingProjects.map(project => ({
        ...project,
        layerType: 'carbonCureProjects',
      })),
      pickable: true,
      opacity: 0.2,
      stroked: false,
      filled: true,
      getRadius: project => {
        if (project.concreteDelivered > 3000) {
          return 55
        } else if (project.concreteDelivered > 1000) {
          return 45
        } else if (project.concreteDelivered > 500) {
          return 35
        } else {
          return 25
        }
      },
      getPosition: project => [project.longitude, project.latitude],
      getFillColor: ongoing
        ? projectColors.ongoing.rgb
        : projectColors.finished.rgb,
    }),
  ]
}
