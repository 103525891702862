import React, { useEffect, useRef } from 'react'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { fetchQuicksightEmbedURL } from './QuicksightDashboard'

export interface IDashboardConfig {
  title: string
  baseUrl: string
  dashboardId: string
  dashboardRegion: string
  dashboardName: string
}

export interface IQuicksightDashboardExperimentalProps {
  dashboard: IDashboardConfig
  height: string
  width: string
  toolbarOptionExport: boolean
  toolbarOptionUndoRedo: boolean
}

const QuickSightDashboardExperimental = ({
  dashboard,
  height = '1500px',
  width = '100%',
  toolbarOptionExport,
  toolbarOptionUndoRedo,
}: IQuicksightDashboardExperimentalProps) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const invokeUrl = dashboard.baseUrl
    const urlParams = {
      dashboardId: dashboard.dashboardId,
      dashboardName: dashboard.dashboardName,
      dashboardRegion: dashboard.dashboardRegion,
    }
    const embedDashboard = async () => {
      const embedUrl = await fetchQuicksightEmbedURL(invokeUrl, urlParams)
      const embeddingContext = await createEmbeddingContext()

      if (containerRef.current) {
        const frameOptions = {
          url: embedUrl,
          container: containerRef.current,
          height,
          width,
        }

        const contentOptions = {
          toolbarOptions: {
            export: toolbarOptionExport,
            undoRedo: toolbarOptionUndoRedo,
            reset: false,
          },
          sheetOptions: {
            singleSheet: false,
            emitSizeChangedEventOnSheetChange: false,
          },
          attributionOptions: {
            overlayContent: false,
          },
        }
        await embeddingContext.embedDashboard(frameOptions, contentOptions)
      }
    }

    embedDashboard()
  }, [dashboard, height, width, toolbarOptionExport, toolbarOptionUndoRedo])

  return <div ref={containerRef} style={{ height, width }} />
}

export default QuickSightDashboardExperimental
