import { getCO2DosageUnitLabel } from './TSSLogic'
import { DigestedBatchActual, DigestedMixDesign } from './Types'

export const getBatchUrl = (
  selectedBatchActuals: Array<DigestedBatchActual>,
  url: string,
  divistionId: number | string
) => {
  const ids = selectedBatchActuals.map(
    batch => 'batchTestSampleIds=' + batch.batchTestSampleId
  )
  return `${url}/AddBatchData?divisionId=${divistionId}&${ids.join('&')}`
}

export const getBaleenVariationIdsUrl = (
  selectedMixDesign: DigestedMixDesign
) => {
  const ids = selectedMixDesign.variations
    .slice(0, 6)
    .map(
      variation =>
        `variationIds=${selectedMixDesign.mixDesignId}-${
          variation.cementReductionPercent
        }-${variation.cO2Dosage}${getCO2DosageUnitLabel(
          variation.cO2DosageUnit,
          selectedMixDesign.isMetric
        )}`
    )
  return selectedMixDesign.variations.length > 6
    ? `${ids.join('&')}`
    : `variationIds=all`
}
