import React from 'react'
import PropTypes from 'prop-types'
import InletTablePresentational from './InletTablePresentational.js'

function InletTableLogical(props) {
  InletTableLogical.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Data from the inlet data endpoint */
    inletData: PropTypes.array.isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Number of rows returned by the endpoint */
    count: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
  }
  const {
    handleTableSort,
    inletData,
    order,
    orderBy,
    page,
    count,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  /** Table column headers */
  const headCells = [
    {
      id: 'dateTime',
      numeric: false,
      label: 'Date & Time',
    },
    {
      id: 'inlet_Liquid_psi',
      numeric: true,
      label: 'Inlet Liquid Pressure (psi)',
    },
    {
      id: 'inlet_Gas_psi',
      numeric: true,
      label: 'Inlet Gas Pressure (psi)',
    },
    {
      id: 'temp_c',
      numeric: true,
      label: 'Temperature (C)',
    },
  ]

  return (
    <InletTablePresentational
      inletData={inletData}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleTableSort={handleTableSort}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      order={order}
      orderBy={orderBy}
      headCells={headCells}
    />
  )
}

export default InletTableLogical
