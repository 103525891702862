import React, { useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import ProductionDashboards from './ProductionDashboards'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { dashboardNameList } from '../Constants/UrlMapping'
import { convertCamelCaseToPascalCase } from '../../Common/Helpers/GeneralHelpers'

function QuickSightHome(props) {
  const [currentDashboard, setCurrentDashboard] = useState(dashboardNameList[3])
  let { path } = useRouteMatch()
  const { isExperimental } = props
  const pageTitle = isExperimental ? 'QuickSight (Experimental)' : 'QuickSight'

  return (
    <Grid container>
      <Switch>
        {dashboardNameList.map(dashboard => {
          return (
            <Route
              key={dashboard.id}
              exact
              path={`${path}/${convertCamelCaseToPascalCase(dashboard)}`}
            >
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
              <ProductionDashboards
                currentDashboard={dashboard}
                setCurrentDashboard={setCurrentDashboard}
                isExperimental={isExperimental}
              />
            </Route>
          )
        })}
        <Redirect
          to={`/${
            isExperimental ? 'QuickSightExperimental' : 'QuickSight'
          }/${convertCamelCaseToPascalCase(currentDashboard)}`}
        />
      </Switch>
    </Grid>
  )
}

QuickSightHome.propTypes = {
  isExperimental: PropTypes.bool,
}
export default QuickSightHome
