import { TestCategoriesKey } from '../../TSS/Logic/Types'

export type CementTestSampleResultKey = 'NotTested' | 'Positive' | 'Negative'

export enum CementTestSampleResult {
  NotTested = 'Not Tested',
  Positive = 'Positive',
  Negative = 'Negative',
}

export interface IKelownaCementTestSample {
  batchTestSampleId: number
  cementReductionPercent: number | null
  cO2DosageLitresPerM3: number | null
  designStrengthMpa: number | null
  cementLoadingKgPerM3: number | null
  slumpMm: number | null
  waterCementRatio: number | null
  scmTypes: string[] | null
  scmPercentage: number | null
  testDate: string | null
  result: CementTestSampleResultKey
  testCategory: TestCategoriesKey
}

export interface IKelownaCement {
  cementPlantId: number
  cementPlantName: string
  supplier: string
  city: string
  state: string
  country: string
  groupName: string
  cementType: string
  cementId: number
  averageCementReductionPercent: number | null
  averageCO2DosageLitresPerM3: number | null
  dateTested: string | null
  testSamples: IKelownaCementTestSample[]
}

export interface ICementTestSample {
  batchTestSampleId: number
  cementReduction: number | null
  cO2Dosage: number | null
  designStrength: number | null
  cementLoading: number | null
  slump: number | null
  waterCementRatio: number | null
  scmTypes: string[] | null
  scmPercent: number | null
  productionDate: string | null
  result: CementTestSampleResult
}

export interface ICement {
  cementId: number
  state: string
  groupName: string
  supplier: string
  cementPlantName: string
  cementType: string | null
  avgCementReduction: number | null
  avgCO2Dosage: number | null
  dateTestedTimestamp: string | null
  testSamples: ICementTestSample[]
  result: CementTestSampleResult
  isMetric: boolean
}

export interface ICementForDownload {
  cementId: number
  state: string
  groupName: string
  supplier: string
  cementPlantName: string
  cementType: string | null
  avgCementReduction: number | null
  avgCO2Dosage: number | null
  dateTestedTimestamp: string | null
  testSamples: ICementTestSample[]
  result: CementTestSampleResult
}

export interface IGetCementsConstraints {
  Limit: number
  Offset: number
  SortColumn: string
  SortOrder: 'Ascending' | 'Descending'
  countries?: string
  states?: string
  cities?: string
  zipCodes?: string
  cementTypes?: string[]
  cementSuppliers?: string
  labResults?: string[]
  [key: string]: number | string | string[] | undefined | null
}

export interface ICementTableDownloadRow {
  state: string
  groupName: string
  supplier: string
  plant: string
  cementType: string | null
  avgCementAdjustment: number | null
  avgCO2Dose: number | null
  lastDateTested: string | null
  result: CementTestSampleResult
  cementAdjustment: number | null
  cO2Dosage: number | null
  designStrength: number | null
  cementLoading: number | null
  slump: number | null
  waterCementRatio: number | null
  scmType: string | null
  scmPercent: number | null
  productionDate: string | null
  sampleTestResult: CementTestSampleResult | null
}
