import React, { useState, useEffect } from 'react'
import {
  getAssociatedMixesByMixDesignId,
  getMixGroupDetails,
} from '../../Data/TSSDataHelpers'
import CopyMixVariationPresentational from './CopyMixVariationPresentational'
import { digestMixDesigns, findMixDesignById } from '../../Logic/TSSLogic'
import { MixGroupVariation } from '../../Logic/Types'

export interface ICopyMixVariationLogical {
  /** Boolean indicating whether or not the View & Select Mix Variation interface is visible */
  isCopyMixVariationMenuOpen: boolean
  /** Function to determine whether or not the View & Select Mix Variation interface is visible */
  setIsCopyMixVariationMenuOpen: (arg1: boolean) => void
  /** Associated mix object for fetching variations*/
  associatedMixDesign: {
    mixDesignId: string
    mixCode: string
    baseLineCO2: string
    baseLineCementReduction: string
  }
  /** Function to fetch and update data for autofilling */
  updateMixDetailsAndMaterials: (
    data: {},
    selectedVariation: MixGroupVariation
  ) => void
  /** Boolean indicating whether or not the page is in Add Mix Mode */
  addMixMode: boolean
}

const CopyMixVariationLogical = (props: ICopyMixVariationLogical) => {
  const {
    isCopyMixVariationMenuOpen,
    setIsCopyMixVariationMenuOpen,
    associatedMixDesign,
    updateMixDetailsAndMaterials,
    addMixMode,
  } = props

  const [associatedMixVariations, setAssociatedMixVariations] = useState<
    Array<MixGroupVariation>
  >([])
  const [selectedVariation, setSelectedVariation] = useState<
    MixGroupVariation | null | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  // Fetch selected mix design details/materials by ID.
  const fetchAndCopyMixVariation = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    setIsLoading(true)
    if (selectedVariation)
      getMixGroupDetails(selectedVariation.mixDesignId)
        .then(res => {
          if (res.ok) {
            res.json().then(data => {
              setTimeout(() => {
                updateMixDetailsAndMaterials(data, selectedVariation)
                setIsCopyMixVariationMenuOpen(false)
                setSelectedVariation(null)
                setIsLoading(false)
              }, 500)
            })
          }
        })
        .catch(e => console.error(e))
  }

  /** Gets the Mix Details of the Selected Associated Mix */
  useEffect(() => {
    if (!associatedMixDesign) return
    let allassociatedMixes
    allassociatedMixes = {
      mixDesignId: associatedMixDesign.mixDesignId,
    }
    // Get Associated Mixes
    getAssociatedMixesByMixDesignId(allassociatedMixes)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const { results } = data
            const { mixDesignData } = digestMixDesigns(results)
            const currentAssociatedMix = findMixDesignById(
              mixDesignData,
              Number(associatedMixDesign.mixDesignId)
            )
            if (
              currentAssociatedMix &&
              currentAssociatedMix.variations.length
            ) {
              setAssociatedMixVariations(currentAssociatedMix.variations)
            } else setAssociatedMixVariations([currentAssociatedMix]) //If there is no variation, the baseline will be copied
          })
      })
      .catch(e => console.log(e))
  }, [associatedMixDesign])

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MixGroupVariation
  ) => {
    if (selectedVariation === row) {
      setSelectedVariation(null)
    } else {
      setSelectedVariation(row)
    }
  }

  return (
    <CopyMixVariationPresentational
      isCopyMixVariationMenuOpen={isCopyMixVariationMenuOpen}
      setIsCopyMixVariationMenuOpen={setIsCopyMixVariationMenuOpen}
      mixCode={associatedMixDesign?.mixCode}
      fetchAndCopyMixVariation={fetchAndCopyMixVariation}
      associatedMixVariations={associatedMixVariations}
      handleClick={handleClick}
      selectedVariation={selectedVariation}
      addMixMode={addMixMode}
      isLoading={isLoading}
    />
  )
}

export default CopyMixVariationLogical
