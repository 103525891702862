import { atom } from 'recoil'
import AlarmCodeMapper from '../Eng/AlarmsManagement/Logic/AlarmCodeMapper'

/**
 * Atom for Engineering Dashboard selected remote, stores the selected Remote as well as the display string
 */
const atomCurrentRemote = atom({
  key: 'remote',
  default: {
    remoteId: null,
    displayString: null,
  },
})

/**
 * Atom to hold date range.
 *
 * start time and end time are ISO Strings representing the date entered at 00:00UTC
 */
const atomDateRange = atom({
  key: 'dateRange',
  default: {
    startTime: null,
    endTime: null,
  },
})

const atomRegion = atom({
  key: 'region',
  default: {
    international: true,
    canadaUS: true,
  },
})

const atomCodeMapper = atom({
  key: 'codeMapper',
  default: new AlarmCodeMapper(),
})

const atomSelectedTab = atom({
  key: 'selectedTab',
  default: 'Alarms',
})
export {
  atomDateRange,
  atomCurrentRemote,
  atomRegion,
  atomCodeMapper,
  atomSelectedTab,
}
