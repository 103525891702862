/** This file contains constants used in CustomerPageView */
export const customerPriorities = {
  1: {
    color: '#D74F39',
    value: 'Very High',
    tip: 'New Customer, No Design Data Available',
  },
  2: { color: '#E38132', value: 'High', tip: 'New Customer' },
  3: { color: '#FFC144', value: 'Medium', tip: 'Current Customer' },
  4: { color: '#939BA0', value: 'Low', tip: 'No Design Data Available' },
}

export const commissioningWarnings = {
  lowSamples: {
    subtitle: 'Low Sample Count',
    message: 'has less than 2 mix designs with over 15 samples',
    backgroundColor: '#FFF0D0',
    textColor: '#653C02',
    iconColor: '#FFA12D',
    errorIcon: true,
  },
  sufficientSamples: {
    subtitle: 'Sufficient Samples Recorded',
    message: '',
    backgroundColor: '#EFF7EE',
    textColor: '#274524',
    iconColor: '#74B369',
    errorIcon: false,
  },
}
