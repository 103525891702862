import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableContainer, Paper } from '@material-ui/core'
import EngTableHead from './EngTableHead'
import PropTypes from 'prop-types'
import AlarmForTable from '../Logic/AlarmForTable'
import AlarmsTableRow from './AlarmsTableRow'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
}))

/**
 *
 * @param {Object} props Collection of props
 * @param {AlarmForTable []} props.alarms
 * @returns
 */
function AlarmsTablePresentational(props) {
  AlarmsTablePresentational.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Data from the alarms endpoint */
    alarms: PropTypes.arrayOf(PropTypes.instanceOf(AlarmForTable)).isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Total number of results from the alarms endpoint  */
    count: PropTypes.number.isRequired,
    /** Keep track of which alarm has been selected */
    selectedAlarm: PropTypes.object.isRequired,
    /** Function to handle selecting table rows */
    handleClick: PropTypes.func.isRequired,
    /** Function to go to another page of the table */
    handleChangePage: PropTypes.func.isRequired,
    /** Function to update the number of table rows displayed */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Table Column headers */
    headCells: PropTypes.array.isRequired,
  }
  const {
    handleTableSort,
    alarms,
    order,
    orderBy,
    page,
    rowsPerPage,
    count,
    selectedAlarm,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    headCells,
  } = props
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer>
        <Table aria-label="alarms" size={'small'}>
          <EngTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleTableSort}
            headCells={headCells}
            includeCheckbox={true}
          />
          <TableBody>
            {/* Map through the alarm data and display it as sortable rows */}
            {alarms.map(row => {
              /** Keep track of selected row */
              const isItemSelected = selectedAlarm.alarmId === row.alarmId
              const labelId = `alarm-${row.alarmId}`
              return (
                <AlarmsTableRow
                  handleClick={handleClick}
                  isItemSelected={isItemSelected}
                  row={row}
                  labelId={labelId}
                  key={row.alarmId}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleTablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AlarmsTablePresentational
