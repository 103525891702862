import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import MasterUnitBootsTable from '../Components/MasterUnitBootsTable'
import MasterUnitCommandsTable from '../Components/MasterUnitCommandsTable'
import AlarmsTable from '../Components/AlarmsTable'
import RemoteStatusTable from '../Components/RemoteStatusTable'
import RemoteUnitInjectionsTable from '../Components/RemoteUnitInjectionsTable'
import ConfirmOpenSessionModal from '../Components/ConfirmOpenSessionModal'
import {
  getCurrentTunnels,
  getMasterUnit,
  getMasterUnitSession,
  getRemoteStatuses,
  postMasterCommand,
} from '../Data/PCBDataHelpers'
import TabPanel from '../../../Common/Components/TabPanel'
import LiveStatusChartView from './LiveStatusChartView'
import AlarmIcon from '../Components/AlarmIcon'
import { getHighestAlarmLevel } from '../Logic/PCBLogic'
import useFeedback from '../Hooks/useFeedback'
import EditRemoteModal from '../Components/EditRemoteModal'

const useStyles = makeStyles({
  tabContainer: {
    backgroundColor: '#F5F5F5',
    overflow: 'scroll',
    maxHeight: '750px',
    minHeight: '250px',
    width: '100%',
  },
  buttonTextOverride: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
  masterButtonOverride: {
    display: 'inline',
  },
  commandArea: {
    paddingLeft: '2%',
  },
  remoteUnitListButtonSelected: {
    '&.Mui-selected': {
      fontWeight: 'bold',
      backgroundColor: 'transparent',
    },
  },
  inheritFontWeight: {
    fontWeight: 'inherit',
  },
})

MasterUnitView.propTypes = {
  masterData: PropTypes.object.isRequired,
}

function MasterUnitView(props) {
  const classes = useStyles()
  const { masterData } = props
  const [masterCommandFeedbackIcon, setMasterCommandFeedbackIcon] = useFeedback(
    'nothing',
    'command_feedback'
  )
  const [masterUnit, setMasterUnit] = useState({})
  const [remoteUnits, setRemoteUnits] = useState([])
  const [activeMasterTab, setActiveMasterTab] = useState('boots')
  const [activeRemoteTab, setActiveRemoteTab] = useState('status')
  const [remoteView, setRemoteView] = useState(false)
  //defaults the first remoteUnit as selected, should probably do this better
  const [remoteSelectedIndex, setRemoteSelectedIndex] = useState(0)
  //states for master unit related data
  const [boots, setBoots] = useState([])
  const [commands, setCommands] = useState([])
  const [bootsLoaded, setBootsLoaded] = useState(false)
  const [commandsLoaded, setCommandsLoaded] = useState(false)
  //states for remote unit related data
  const [statuses, setStatuses] = useState([])
  const [statusesLoaded, setStatusesLoaded] = useState(false)
  const [injections, setInjections] = useState([])
  const [injectionsLoaded, setInjectionsLoaded] = useState(false)
  const [remoteAlarms, setRemoteAlarms] = useState([])
  //Websocket stuff
  const [openSession, setOpenSession] = useState(false)
  const [webSocket, setWebSocket] = useState(() => null)
  //ModalState
  const [open, setOpen] = useState(false)

  console.log(injections)

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOpenSessionModal = () => {
    //openModal
    setOpen(true)
  }

  const handleCloseWebSocket = event => {
    //close the websocket
    setOpenSession(false)
  }

  const handleCloseWebSocketButton = () => {
    setOpenSession(false)
    webSocket.close()
  }

  const handleSelectMasterUnit = () => {
    setRemoteView(false)
  }

  const handleSelectRemoteUnit = function(selectedRemoteIndex) {
    setRemoteView(true)
    setRemoteSelectedIndex(selectedRemoteIndex)
  }

  const handleSendReboot = () => {
    setMasterCommandFeedbackIcon('loading')
    const commandData = {
      MasterUnitId: masterUnit.masterUnitId,
      Code: 1,
      Args: {},
    }

    postMasterCommand(commandData).then(
      response => {
        if (response.ok) {
          setMasterCommandFeedbackIcon('success')
        } else {
          setMasterCommandFeedbackIcon('error')
        }
      },
      () => {
        setMasterCommandFeedbackIcon('error')
      }
    )
  }

  const handleOpenSession = async function() {
    let response
    try {
      response = await getCurrentTunnels()
    } catch (e) {
      console.error(e)
      return
    }
    if (response.ok) {
      const tunnels = await response.json()

      for (let tunnel of tunnels) {
        if (tunnel.masterUnitId === Number(masterData.masterUnitId)) {
          try {
            const webSocketHostName = `${tunnel.hostname}`
            const webSocketPort = tunnel.port
            const websocketAddressAndPort = `wss://${webSocketHostName}:${webSocketPort}`
            let ws = new WebSocket(websocketAddressAndPort)
            ws.onerror = event => {
              console.error('A websocket error has occured', event)
            }
            ws.binaryType = 'arraybuffer'
            setOpenSession(true)
            setActiveMasterTab('liveSession')
            setRemoteView(false)
            handleCloseModal()
            setWebSocket(ws)
            return
          } catch (e) {
            console.error('Sad face :(', e)
          }
          break
        }
      }
    }
    response = await getMasterUnitSession(masterData.masterUnitId)

    if (response.ok) {
      const sessionData = await response.json()
      const webSocketHostname = `${sessionData.hostname}`
      const webSocketPort = sessionData.port
      const websocketAddressAndPort = `wss://${webSocketHostname}:${webSocketPort}`
      let ws
      try {
        ws = new WebSocket(websocketAddressAndPort)
        ws.onerror = event => console.error(event)
      } catch (e) {
        console.error(e)
      }
      ws.binaryType = 'arraybuffer'
      setOpenSession(true)
      setActiveMasterTab('liveSession')
      setRemoteView(false)
      handleCloseModal()
      setWebSocket(ws)
    }
  }

  //useEffect for fetching data on load
  useEffect(() => {
    const internalGetMasterUnit = async () => {
      const response = await getMasterUnit(masterData.masterUnitId)

      if (response.ok) {
        const result = await response.json()
        setMasterUnit(result)
        setRemoteUnits(result.remoteUnits)
        setBoots(result.boots)
        setCommands(result.commands)
        setBootsLoaded(true)
        setCommandsLoaded(true)
      }
    }

    setMasterCommandFeedbackIcon('nothing')
    setRemoteSelectedIndex(0)
    if (masterData.masterUnitId) {
      internalGetMasterUnit()
    }
  }, [masterData, setMasterCommandFeedbackIcon])
  /*
    When a new remote is selected, fetch the associated statuses,
    injections, and alarms
    */
  useEffect(() => {
    setRemoteSelectedIndex(0)

    function getAllStatuses(remotes) {
      const statuses = []
      remotes.forEach(async remote => {
        const response = await getRemoteStatuses(remote.remoteUnitId)
        const status = await response.json()
        statuses.push(status)
      })
      return statuses
    }

    function getAllInjections(remotes) {
      const injections = []
      remotes.forEach(remote => {
        injections.push(remote.injections)
      })
      return injections
    }
    const alarms = remoteUnits.map(remoteUnit => [
      ...remoteUnit.activeAlarms,
      ...remoteUnit.recentAlarms,
    ])
    const statuses = getAllStatuses(remoteUnits)
    const injections = getAllInjections(remoteUnits)

    setStatuses(statuses)
    setInjections(injections)
    setStatusesLoaded(true)
    setInjectionsLoaded(true)
    setRemoteAlarms(alarms)
    //fetch data for all statuses
  }, [remoteUnits])

  const handleCloseEditConfigModal = () => {
    setActiveRemoteTab('status')
  }

  const remoteTabs =
    statusesLoaded && remoteUnits.length ? (
      <>
        <Tabs
          value={activeRemoteTab}
          onChange={(event, newValue) => setActiveRemoteTab(newValue)}
          indicatorColor="primary"
        >
          <Tab label="Status" value="status" disabled={!statusesLoaded} />
          <Tab
            label="Injections"
            value="injections"
            disabled={!injectionsLoaded}
          />
          <Tab label="Alarms" value="alarms" />
          <Tab label="Configuration" value="config" />
        </Tabs>
        <TabPanel
          value={activeRemoteTab}
          className={classes.tabContainer}
          index="status"
        >
          <RemoteStatusTable statuses={statuses[remoteSelectedIndex]} />
        </TabPanel>
        <TabPanel
          value={activeRemoteTab}
          className={classes.tabContainer}
          index="injections"
        >
          <RemoteUnitInjectionsTable
            injections={remoteUnits[remoteSelectedIndex].injections}
          />
        </TabPanel>
        <TabPanel
          value={activeRemoteTab}
          className={classes.tabContainer}
          index="alarms"
        >
          <AlarmsTable alarms={remoteAlarms[remoteSelectedIndex]} />
        </TabPanel>
        <TabPanel
          value={activeRemoteTab}
          className={classes.tabContainer}
          index="config"
        >
          <EditRemoteModal
            isOpen={activeRemoteTab === 'config'}
            remoteUnit={remoteUnits[remoteSelectedIndex]}
            handleClose={handleCloseEditConfigModal}
          />
        </TabPanel>
      </>
    ) : (
      <></>
    )

  const masterTabs = (
    <>
      <Tabs
        value={activeMasterTab}
        onChange={(event, newValue) => setActiveMasterTab(newValue)}
        indicatorColor="primary"
      >
        <Tab label="Boots" value="boots" disabled={!bootsLoaded} />
        <Tab label="Commands" value="commands" disabled={!commandsLoaded} />
        <Tab label="Alarms" value="alarms" />
        <Tab label="Live Session" value="liveSession" />
      </Tabs>
      <TabPanel
        value={activeMasterTab}
        className={classes.tabContainer}
        index="boots"
      >
        <MasterUnitBootsTable boots={boots} />
      </TabPanel>
      <TabPanel
        value={activeMasterTab}
        className={classes.tabContainer}
        index="commands"
      >
        <MasterUnitCommandsTable commands={commands} />
      </TabPanel>
      <TabPanel
        value={activeMasterTab}
        className={classes.tabContainer}
        index="alarms"
      >
        <AlarmsTable alarms={remoteAlarms.flat()} />
      </TabPanel>
      <TabPanel
        value={activeMasterTab}
        className={classes.tabContainer}
        index="liveSession"
      >
        <LiveStatusChartView
          remoteUnits={remoteUnits}
          webSocket={webSocket}
          handleWebSocketOnClose={handleCloseWebSocket}
        />
      </TabPanel>
    </>
  )

  const currentViewText = `${masterData.corporationName ||
    'No Corporation'} - ${masterData.plantName || 'No Plant'} - Master Unit ${
    masterUnit.hardwareId
  }`
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justify="space-between"
      spacing={2}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="baseline"
        justify="space-between"
      >
        <Typography variant="h5">
          <b>Current View -</b> {currentViewText}
        </Typography>
      </Grid>
      <Grid item>
        <Paper>
          <Grid container item spacing={1}>
            <Grid
              container
              item
              direction="column"
              justify="space-between"
              xs={3}
            >
              <Grid item>
                <Button
                  className={classes.masterButtonOverride}
                  size="small"
                  onClick={handleSelectMasterUnit}
                >
                  <Typography
                    className={classes.buttonTextOverride}
                    variant="h5"
                  >
                    Master Unit {masterUnit.hardwareId}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                {/* Remote Units (probably in list) */}
                <List>
                  {remoteUnits &&
                    remoteUnits.map((remoteUnit, index) => {
                      return (
                        <ListItem
                          button
                          selected={remoteSelectedIndex === index && remoteView}
                          onClick={() => handleSelectRemoteUnit(index)}
                          key={remoteUnit.localId}
                          classes={{
                            selected: classes.remoteUnitListButtonSelected,
                          }}
                        >
                          <Typography className={classes.inheritFontWeight}>
                            Remote Unit: {remoteUnit.localId}
                          </Typography>
                          <AlarmIcon
                            alertLevel={getHighestAlarmLevel(
                              remoteUnit.activeAlarms.map(alarm => alarm.code)
                            )}
                          />
                        </ListItem>
                      )
                    })}
                </List>
              </Grid>
              <Grid container item className={classes.commandArea} spacing={2}>
                {masterCommandFeedbackIcon}
                <Typography>Master Commands</Typography>
                <Grid container item spacing={1} direction="row">
                  {/* //TODO make this portion stateful, dependent on remoteView or masterView */}
                  <Grid item>
                    {/* Put different conditional buttons here */}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={
                        openSession
                          ? handleCloseWebSocketButton
                          : handleOpenSessionModal
                      }
                      disabled={masterUnit.isLegacy}
                    >
                      <Typography>
                        {openSession ? 'Close Session' : 'Open Session'}
                      </Typography>
                    </Button>
                    <ConfirmOpenSessionModal
                      isOpen={open}
                      handleClose={handleCloseModal}
                      handleConnect={handleOpenSession}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSendReboot}
                    >
                      <Typography>Reboot</Typography>
                    </Button>
                  </Grid>
                </Grid>
                {/* <Grid container item alignItems="center" spacing={1}>
                  <Grid item>
                    <DeploymentPhaseSelect
                      deploymentPhase={deploymentPhase}
                      setNewDeploymentPhase={setDeploymentPhase}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleSetDeploymentPhase}
                    >
                      Set Phase
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid container item xs={9} alignItems="stretch">
              {remoteView ? remoteTabs : masterTabs}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MasterUnitView
