import { InputAdornment, TextField, makeStyles } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import React, { useEffect, useState } from 'react'
import { baseColors } from '../../theme/colors'

export interface IBaleenOutlierReasonTextField {
  batchId: number
  variationId: string
  outlierReasonTextField: string | null
  updateOutlierReasonTextFieldExternal: (
    arg1: number,
    arg2: string,
    arg3: string | null
  ) => void
  disabled: boolean
  setIsSaveBtnDisabled: (arg1: boolean) => void
  error: boolean
}

const useStyles = makeStyles(() => ({
  input: {
    '&::placeholder': {
      opacity: 1,
    },
  },
}))

function BaleenOutlierReasonTextField(props: IBaleenOutlierReasonTextField) {
  const {
    batchId,
    variationId,
    outlierReasonTextField,
    updateOutlierReasonTextFieldExternal,
    disabled,
    setIsSaveBtnDisabled,
    error,
  } = props

  const classes = useStyles()
  const [
    outlierReasonTextFieldInternal,
    setOutlierReasonTextFieldInternal,
  ] = useState(outlierReasonTextField)

  /*
   Only triggers changing the outlier 'other' reason after half a second of not typing, 
   otherwise prevents the reason from being set.

   This is to prevent unnecessary number of baleen view state updates before a user finishes typing.
   Without it, the updates were laggy in the textfield.
  */

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        updateOutlierReasonTextFieldExternal(
          batchId,
          variationId,
          outlierReasonTextFieldInternal
        ),
      500
    )
    return () => clearTimeout(timeOut)
  }, [
    outlierReasonTextFieldInternal,
    updateOutlierReasonTextFieldExternal,
    batchId,
    variationId,
    outlierReasonTextField,
    setIsSaveBtnDisabled,
  ])

  /**
   * This is to sync the local outlier reason state with the outlier reason prop when 'undo' is clicked.
   */
  useEffect(() => {
    setOutlierReasonTextFieldInternal(outlierReasonTextField)
  }, [outlierReasonTextField])

  const getColor = () => {
    if (disabled) return baseColors.text.disabled
    if (error && !outlierReasonTextFieldInternal?.trim())
      return baseColors.error.light
    return 'black'
  }
  return (
    <TextField
      placeholder="Please provide details"
      autoComplete="off"
      InputProps={{
        style: { color: getColor(), backgroundColor: 'white' },
        classes: {
          input:
            error && !outlierReasonTextFieldInternal?.trim()
              ? classes.input
              : '',
        },
        endAdornment: error && !outlierReasonTextFieldInternal?.trim() && (
          <InputAdornment position="start">
            <ReportProblemOutlinedIcon
              style={{ color: baseColors.error.main }}
            />
          </InputAdornment>
        ),
      }}
      focused={!disabled && !outlierReasonTextFieldInternal?.trim()}
      variant="outlined"
      onChange={event => {
        setOutlierReasonTextFieldInternal(event.target.value)
        if (event.target.value === '' || event.target.value?.trim() === '') {
          setIsSaveBtnDisabled(true)
        }
      }}
      name="baleenOutlierReasonTextField"
      size="small"
      value={outlierReasonTextFieldInternal}
      disabled={disabled}
      error={error && !outlierReasonTextFieldInternal?.trim()}
    />
  )
}
export default BaleenOutlierReasonTextField
