import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ICommissionReportSettings, IMixSelection } from '../../Logic/Types'
import { baseColors } from '../../../theme/colors'

export interface ICommissionReportSelectAnalysisProps {
  readonly mixSelections: IMixSelection[]
  readonly reportSettings: ICommissionReportSettings | undefined
  tabValue: number
  handleAnalysisChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

const useStyles = makeStyles(theme => ({
  checkbox: {
    '&.Mui-disabled': {
      color: `${baseColors.action.required} !important`,
    },
  },
}))

type CheckboxDisabled = {
  frequencyGraph: boolean
  avgStrengthGraph: boolean
}

function CommissionReportSelectAnalysis(
  props: ICommissionReportSelectAnalysisProps
) {
  const {
    mixSelections,
    reportSettings,
    tabValue,
    handleAnalysisChange,
  } = props
  const classes = useStyles()

  const [disableCheckbox, setDisableCheckbox] = useState<CheckboxDisabled>({
    frequencyGraph: false,
    avgStrengthGraph: false,
  })

  let avgStrengthGraphSelected = true
  let frequencyGraphSelected = true
  if (mixSelections) {
    const currentMixDesignId = mixSelections[tabValue]?.mixDesignId
    const mixDesignSettings = reportSettings?.mixDesignSettings.find(
      settings => settings.mixDesignId === currentMixDesignId
    )
    avgStrengthGraphSelected = mixDesignSettings
      ? mixDesignSettings.avgStrengthGraph.isSelected
      : false
    frequencyGraphSelected = mixDesignSettings
      ? mixDesignSettings.frequencyGraph.isSelected
      : false
  }

  //Disable checkbox when only one graph is selected, ie disable frequencyGraph if avgStrengthGraph is deselected and vice versa
  useEffect(() => {
    if (!mixSelections.length) return
    setDisableCheckbox(prevState => ({
      ...prevState,
      frequencyGraph: !avgStrengthGraphSelected,
      avgStrengthGraph: !frequencyGraphSelected,
    }))
  }, [frequencyGraphSelected, avgStrengthGraphSelected, mixSelections])

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      alignItems="center"
      justify="flex-start"
    >
      <Grid item>
        <Typography>Select Analysis:</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            disableCheckbox.frequencyGraph
              ? 'At least one analysis type is required'
              : ''
          }
          placement="bottom-start"
          arrow
          style={{ padding: '0px' }}
        >
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  id="frequencyGraph"
                  name="frequencyGraph"
                  color="primary"
                  checked={frequencyGraphSelected || false}
                  onChange={handleAnalysisChange}
                  disabled={disableCheckbox.frequencyGraph}
                  className={classes.checkbox}
                  data-testid="checkbox-frequencyGraph"
                />
              }
              label={
                <Typography style={{ color: baseColors.text.primary }}>
                  Frequency
                </Typography>
              }
            />
          </span>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip
          title={
            disableCheckbox.avgStrengthGraph
              ? 'At least one analysis type is required'
              : ''
          }
          placement="bottom-start"
          arrow
          style={{ padding: '0px' }}
        >
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  id="avgStrengthGraph"
                  name="avgStrengthGraph"
                  color="primary"
                  checked={avgStrengthGraphSelected || false}
                  onChange={handleAnalysisChange}
                  disabled={disableCheckbox.avgStrengthGraph}
                  className={classes.checkbox}
                  data-testid="checkbox-avgStrengthGraph"
                />
              }
              label={
                <Typography style={{ color: baseColors.text.primary }}>
                  Average Strength
                </Typography>
              }
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default CommissionReportSelectAnalysis
