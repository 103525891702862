import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

ProjectDropdownPresentational.propTypes = {
  /** Options for the project dropdown */
  options: PropTypes.array.isRequired,
  /** Function called when the dropdown value changes */
  handleChange: PropTypes.func.isRequired,
}

function ProjectDropdownPresentational(props) {
  const { options, handleChange } = props

  return (
    <Grid item>
      <Autocomplete
        id="projectDropdown"
        multiple
        filterSelectedOptions
        onChange={handleChange}
        options={options}
        getOptionLabel={option => option}
        getOptionSelected={(option, value) => option === value}
        renderInput={params => (
          <TextField
            {...params}
            label="Project Type"
            variant="outlined"
            size="small"
            placeholder="Select a project type..."
            required
          />
        )}
      />
    </Grid>
  )
}

export default ProjectDropdownPresentational
