import { useCallback, useEffect, useState } from 'react'
//@ts-ignore (For future resolution)
import { useLocation, useHistory } from 'react-router-dom'
import {
  Filter,
  FilterComponentTypes,
  FilterOption,
  IActiveFilter,
  ICustomerFilterOption,
  ICustomerFilterOptions,
  ISonarFilter,
  ISonarFrontendFilter,
} from '../../../Common/Logic/Types'
import { DefaultFilterSectionExpanded } from '../../../TSS/Logic/Types'
import {
  defaultCustomerFilterOptions,
  technologyTypes,
  timeDownOptions,
} from '../Constants/SonarConstants'
import SonarViewHelper from './SonarViewHelper'
import { useRecoilState } from 'recoil'
import { atomSonarFilter, atomSonarFrontendFilter } from '../../../Common/atoms'
import { getNewSettings } from '../../../TSS/Data/TSSDataHelpers'
import { getLocalTimezoneOffsetFromUTC } from '../../../Common/Helpers/GeneralHelpers'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IUseSonarViewProps {}

const useSonarView = () => {
  const location = useLocation()
  const history = useHistory()
  // State and Setters
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isFirstRender, setIsFirstRender] = useState(true)

  /** Handles displayed filter count */
  const [filterCount, setFilterCount] = useState<number>(0)

  /** Handles opening and closing of Filter Drawer */
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  /** Handles active filters */
  const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>([])

  /** Handles expanded filters */
  const [expandFilters, setExpandFilters] = useState<
    DefaultFilterSectionExpanded
  >({
    technology: false,
    customer: false,
    downSystems: false,
  })

  /** Sonar Filters */
  const [sonarFilter, setSonarFilter] = useRecoilState<ISonarFilter>(
    atomSonarFilter
  )

  const [sonarFrontendFilter, setSonarFrontendFilter] = useRecoilState<
    ISonarFrontendFilter
  >(atomSonarFrontendFilter)

  /** Handles custom date range settings */
  const [rangeSelected, setRangeSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenDateRange') ?? null
  )

  const [startDateSelected, setStartDateSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenStartDate') ?? null
  )

  const [endDateSelected, setEndDateSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenEndDate') ?? null
  )

  const [clearRangeSelected, setClearRangeSelected] = useState<boolean>(false)

  const [addDataSettings, setAddDataSettings] = useState([])
  const [customerFilterOptions, setCustomerFilterOptions] = useState<
    ICustomerFilterOptions
  >(defaultCustomerFilterOptions)

  const { search } = location

  /** Defines the filter helper variables */
  const filterPanelHelperVariables = {
    isChipActive: SonarViewHelper.isChipActive,
  }

  // CALLBACKS
  /** Updates the URL with tab and filter parameters */
  const updateUrlParams = useCallback(() => {
    const currentQueryParams = new URLSearchParams(search)

    // Retrieve the current tab parameter
    const tabParam = currentQueryParams.get('tab')

    // Start fresh for updating
    const updatedQueryParams = new URLSearchParams()

    // Retain 'tab' in updated query params if it exists
    if (tabParam) {
      updatedQueryParams.set('tab', tabParam)
    }

    // Add filter parameters from sonarFilter state
    Object.keys(sonarFilter).forEach(key => {
      const filterValues = sonarFilter[key as keyof ISonarFilter]
      if (filterValues && filterValues.length > 0) {
        filterValues.forEach((value: string) => {
          updatedQueryParams.append(key, value)
        })
      }
    })

    // Add filter parameters from sonarFilter state
    Object.keys(sonarFrontendFilter).forEach(key => {
      const filterValues =
        sonarFrontendFilter[key as keyof ISonarFrontendFilter]
      if (filterValues && filterValues.length > 0) {
        filterValues.forEach((value: string) => {
          updatedQueryParams.append(key, value)
        })
      }
    })
    // Replace the URL with updated query parameters
    history.replace({
      pathname: location.pathname,
      search: `?${updatedQueryParams.toString()}`,
    })
  }, [search, history, location.pathname, sonarFilter, sonarFrontendFilter])

  //Effects
  useEffect(() => {
    if (!isFirstRender) {
      updateUrlParams()
    } else {
      const params = new URLSearchParams(search)
      const newSettings: any = getNewSettings(sonarFilter, params)
      const newFrontendSettings: any = getNewSettings(
        sonarFrontendFilter,
        params
      )
      setSonarFilter(newSettings)
      setSonarFrontendFilter(newFrontendSettings)
      setIsFirstRender(false)
    }
  }, [
    isFirstRender,
    sonarFilter,
    search,
    setSonarFilter,
    updateUrlParams,
    sonarFrontendFilter,
    setSonarFrontendFilter,
  ])

  // Callbacks
  const filterButtonClickHandler = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen, setIsFilterOpen])

  /** Click handler for the individual filter item chips */
  const filterPanelChipsChipClickHandler = useCallback(
    (activeFilter: IActiveFilter) => {
      switch (activeFilter.id) {
        case 'technology-all': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = { ...prevSonarFilter }
            tempSonarFilter.technology = []
            return tempSonarFilter
          })
          break
        }

        case 'date-range': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = { ...prevSonarFilter }
            setRangeSelected(null)
            tempSonarFilter.lastSeenDateRange = []
            tempSonarFilter.lastSeenStartDate = []
            tempSonarFilter.lastSeenEndDate = []
            return tempSonarFilter
          })
          break
        }

        case 'time-down': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = { ...prevSonarFilter }
            tempSonarFilter.timeDown = []
            tempSonarFilter.lastSeenStartDate = []
            tempSonarFilter.lastSeenEndDate = []
            return tempSonarFilter
          })
          break
        }

        case 'snoozed-alarm': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = { ...prevSonarFilter }
            const currentSnoozedAlarm = tempSonarFilter.shouldShowSnoozed?.[0]
            if (currentSnoozedAlarm === 'true') {
              tempSonarFilter.shouldShowSnoozed = ['false']
            } else {
              tempSonarFilter.shouldShowSnoozed = ['true']
            }

            return tempSonarFilter
          })
          break
        }

        default: {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = { ...prevSonarFilter }

            // For technology related filter chips
            tempSonarFilter.technology = tempSonarFilter.technology.filter(
              tech => tech !== activeFilter.id.split('-')[1]
            )

            // For customer related filter chips
            tempSonarFilter[activeFilter.id.split('-')[1]] = null
            return tempSonarFilter
          })
        }
      }

      setActiveFilters(prev =>
        prev.filter(filter => filter.id !== activeFilter.id)
      )
    },
    [setActiveFilters, setSonarFilter]
  )

  /** Handler for the Clear All Filters button click */
  const filterPanelChipsButtonClickHandler = useCallback(() => {
    setRangeSelected(null)
    setStartDateSelected(null)
    setEndDateSelected(null)

    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter: ISonarFilter = {
        ...prevSonarFilter,
        technology: [],
        divisionId: [],
        plantId: [],
        systemId: [],
        region: [],
        country: [],
        state: [],
        city: [],
        lastSeenDateRange: [],
        lastSeenStartDate: [],
        lastSeenEndDate: [],
        shouldShowSnoozed: ['false'],
        timeDown: [],
      }

      return tempSonarFilter
    })
    setActiveFilters([])
    setRangeSelected(null)
  }, [setActiveFilters, setSonarFilter])

  /** Handler for the Technology Types chips selection in the Filter Panel */
  const technologyTypesChipClickHandler = useCallback(
    (_: Filter, option: FilterOption) => {
      setSonarFilter(prevSonarFilter => {
        const tempSonarFilter = { ...prevSonarFilter }

        // If the option.id is already in the tempSonarFilter.technology, remove it, otherwise, add it
        if (tempSonarFilter.technology.includes(option.id)) {
          tempSonarFilter.technology = tempSonarFilter.technology.filter(
            tech => tech !== option.id
          )
        } else {
          tempSonarFilter.technology = [
            ...tempSonarFilter.technology,
            option.id,
          ]
        }

        return tempSonarFilter
      })
    },
    [setSonarFilter]
  )

  const filterChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => {
      return (_: Filter, property: { id: number | string } | null) => {
        setSonarFilter((prevSettings: ISonarFilter) => {
          const newSettings = {
            ...prevSettings,
            [propertyName]: property ? [property.id] : [],
          }

          return newSettings
        })
      }
    },
    [setSonarFilter]
  )

  const snoozedAlarmsChangeHandler = useCallback(() => {
    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter = { ...prevSonarFilter }
      const currentSnoozedAlarm = tempSonarFilter.shouldShowSnoozed?.[0]

      if (currentSnoozedAlarm === 'true') {
        tempSonarFilter.shouldShowSnoozed = ['false']
      } else if (currentSnoozedAlarm === undefined) {
        tempSonarFilter.shouldShowSnoozed = ['true']
      } else {
        tempSonarFilter.shouldShowSnoozed = [
          currentSnoozedAlarm ? 'true' : 'false',
        ]
      }

      return tempSonarFilter
    })
  }, [setSonarFilter])

  const dateRangeChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => {
      return (_: Filter, property: { id: number | string } | null) => {
        setSonarFilter((prevSettings: ISonarFilter) => {
          let dateRangeSettings = {}
          if (!property) {
            dateRangeSettings = {
              lastSeenStartDate: [],
              lastSeenEndDate: [],
            }
          }
          const newSettings = {
            ...prevSettings,
            ...dateRangeSettings,
            [propertyName]: property ? [property.id] : [],
          }
          return newSettings
        })
      }
    },
    [setSonarFilter]
  )

  const timeDownChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => dateRangeChangeHandler(propertyName),
    [dateRangeChangeHandler]
  )

  const lastSeenChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => dateRangeChangeHandler(propertyName),
    [dateRangeChangeHandler]
  )

  const timezoneChangeHandler = useCallback(() => {
    setSonarFrontendFilter(prevSonarFrontendFilter => {
      return SonarViewHelper.timezoneChangeHandlerHelper(
        prevSonarFrontendFilter
      )
    })
  }, [setSonarFrontendFilter])

  const processTechnologyFilters = useCallback(() => {
    const technologyFilters = SonarViewHelper.processSelectedTechnologyFilter(
      sonarFilter.technology
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all technology items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'technology'
      )

      // Merge the arrays
      return [...technologyFilters, ...tempActiveFilters]
    })
  }, [sonarFilter.technology])

  const processCustomerFilters = useCallback(() => {
    if (!customerFilterOptions) return
    const customerFilters = SonarViewHelper.processSelectedCustomerFilter(
      sonarFilter,
      customerFilterOptions
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all customer items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'customer'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...customerFilters]
    })
  }, [sonarFilter, customerFilterOptions])

  const processDateRangeFilters = useCallback(() => {
    const dateRangeFilters = SonarViewHelper.processSelectedDateRangeFilter(
      sonarFilter,
      rangeSelected,
      startDateSelected,
      endDateSelected
    )

    setClearRangeSelected(dateRangeFilters.length === 0)

    setActiveFilters(prevActiveFilters => {
      // Filter out all date range items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'dateRange'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...dateRangeFilters]
    })
  }, [rangeSelected, sonarFilter, startDateSelected, endDateSelected])

  const processDateRangeForAtomFilter = useCallback(() => {
    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter = { ...prevSonarFilter }
      if (rangeSelected) {
        tempSonarFilter.lastSeenDateRange = rangeSelected ? [rangeSelected] : []
        tempSonarFilter.lastSeenStartDate = startDateSelected
          ? [startDateSelected]
          : []
        tempSonarFilter.lastSeenEndDate = endDateSelected
          ? [endDateSelected]
          : []

        // Store the date ranges in session
        sessionStorage.setItem('sonar-lastSeenDateRange', rangeSelected ?? '')
        sessionStorage.setItem(
          'sonar-lastSeenStartDate',
          startDateSelected ?? ''
        )
        sessionStorage.setItem('sonar-lastSeenEndDate', endDateSelected ?? '')
      } else {
        tempSonarFilter.lastSeenDateRange = []
        tempSonarFilter.lastSeenStartDate = []
        tempSonarFilter.lastSeenEndDate = []

        sessionStorage.removeItem('sonar-lastSeenDateRange')
        sessionStorage.removeItem('sonar-lastSeenStartDate')
        sessionStorage.removeItem('sonar-lastSeenEndDate')
      }
      return tempSonarFilter
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateSelected, rangeSelected, setSonarFilter, startDateSelected])

  const processTimeDownFilters = useCallback(() => {
    const timeDownFilters = SonarViewHelper.processSelectedTimeDownFilter(
      sonarFilter.timeDown?.[0] ?? ''
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all time down items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'timeDown'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...timeDownFilters]
    })
  }, [sonarFilter.timeDown])

  const processTimeDownForAtomFilter = useCallback(() => {
    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter = { ...prevSonarFilter }
      // When using the Time Down filter, ORCA send Kelowna a start date and end date with no time.
      // Kelowna decides the range is from start date @ midnight (00:00) to end date midnight @ (00:00)
      // When the Time Down selection is < 10 days (nine days ago to today), ORCA wants the current day included.
      // Therefore ORCA gets the current date in UTC and adds 1.
      // ORCA manipulates the date in UTC instead of local time to avoid any weirdness with Kelowna since
      // they handle dates in UTC
      const cutoff = new Date()
      cutoff.setUTCDate(cutoff.getUTCDate() + 1)
      cutoff.setUTCHours(0, 0, 0, 0)
      if (sonarFilter?.timeDown?.length) {
        const { startDate, endDate } = SonarViewHelper.getTimeDownDateRanges(
          sonarFilter?.timeDown,
          cutoff
        )
        tempSonarFilter.lastSeenStartDate = startDate ? [startDate] : []
        tempSonarFilter.lastSeenEndDate = endDate ? [endDate] : []
      } else {
        tempSonarFilter.lastSeenStartDate = startDateSelected
          ? [startDateSelected]
          : []
        tempSonarFilter.lastSeenEndDate = endDateSelected
          ? [endDateSelected]
          : []
      }
      return tempSonarFilter
    })
  }, [
    setSonarFilter,
    sonarFilter?.timeDown,
    startDateSelected,
    endDateSelected,
  ])

  const processSnoozedAlarmFilters = useCallback(() => {
    const snoozedAlarmFilters = SonarViewHelper.processSelectedSnoozedAlarmFilter(
      sonarFilter.shouldShowSnoozed?.[0] ?? 'false'
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all snoozed alarms items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'shouldShowSnoozed'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...snoozedAlarmFilters]
    })
  }, [sonarFilter.shouldShowSnoozed])

  const getFilterValue = (filter: ISonarFilter) => {
    const options = filter.options
    const filterValue = sonarFilter[filter.property]

    if (options?.length && filterValue?.length) {
      const matchedOption = options.find(
        (option: ICustomerFilterOption) =>
          option?.id.toString() === filterValue[0].toString()
      )

      // Since the options for the checkbox (visibility) is based on the property value,
      // it needs to be handled differently.
      if (filter.property === 'shouldShowSnoozed' && matchedOption) {
        return matchedOption.value === 'true'
      }
      return matchedOption || null
    }

    if (filter.property === 'isUTC') {
      if (options?.length && sonarFrontendFilter.isUTC) {
        const matchedOption = options.find(
          (option: ICustomerFilterOption) =>
            option?.id === sonarFrontendFilter.isUTC?.[0]
        )
        return matchedOption.id
      }
    }
    return null
  }

  const getCustomerFilterOptions = useCallback(async () => {
    const tempSonarFilter = { ...sonarFilter }
    const queryParams = SonarViewHelper.generateQueryParams(tempSonarFilter)
    const customerFilterOptions = await SonarViewHelper.getSonarCustomerFilterOptions(
      queryParams,
      setIsLoading
    )
    setCustomerFilterOptions(customerFilterOptions)
  }, [sonarFilter])

  //Effects
  useEffect(() => {
    if (!isFirstRender) {
      updateUrlParams()
    } else {
      const params = new URLSearchParams(search)

      // Check if the params contains filter values for lastSeenDateRange, lastSeenStartDate, and lastSeenEndDate
      const lastSeenDateRange = params.get('lastSeenDateRange')
      const lastSeenStartDate = params.get('lastSeenStartDate')
      const lastSeenEndDate = params.get('lastSeenEndDate')

      // If values are found for lastSeenDateRange, lastSeenStartDate, and lastSeenEndDate, we set the values in the state
      if (lastSeenDateRange && lastSeenStartDate && lastSeenEndDate) {
        setRangeSelected(lastSeenDateRange)
        setStartDateSelected(lastSeenStartDate)
        setEndDateSelected(lastSeenEndDate)
      }
      const newSettings: any = getNewSettings(sonarFilter, params)
      setSonarFilter(newSettings)
      setIsFirstRender(false)
    }
  }, [isFirstRender, sonarFilter, search, setSonarFilter, updateUrlParams])

  useEffect(() => {
    processCustomerFilters()
    processTechnologyFilters()
    processSnoozedAlarmFilters()
    processDateRangeFilters()
    processTimeDownFilters()
  }, [
    processCustomerFilters,
    processDateRangeFilters,
    processSnoozedAlarmFilters,
    processTechnologyFilters,
    processTimeDownFilters,
  ])

  useEffect(() => {
    setFilterCount(activeFilters.length)
  }, [activeFilters])

  useEffect(() => {
    getCustomerFilterOptions()
  }, [getCustomerFilterOptions])

  useEffect(() => {
    processDateRangeForAtomFilter()
  }, [processDateRangeForAtomFilter])

  useEffect(() => {
    processTimeDownForAtomFilter()
  }, [processTimeDownForAtomFilter])

  useEffect(() => {
    // Clear sessionStorage when the component unmounts
    return () => {
      sessionStorage.removeItem('sonar-lastSeenDateRange')
      sessionStorage.removeItem('sonar-lastSeenStartDate')
      sessionStorage.removeItem('sonar-lastSeenEndDate')
    }
  }, [])

  /* Defines the filter items that will appear on the filter panel.
   * Add the other filter items here as progressing with the other
   * filter items.
   */
  const filterPanelItems = [
    {
      category: 'technology',
      filters: [
        {
          componentType: FilterComponentTypes.ChipsMulti,
          disabled: () => false,
          heading: '',
          name: '',
          onClickHandler: technologyTypesChipClickHandler,
          options: technologyTypes,
          property: 'technologyTypes',
        },
      ],
      name: 'TECHNOLOGY',
    },
    {
      category: 'customer',
      filters: [
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'Producer',
          onChangeHandler: filterChangeHandler('divisionId'),
          options: customerFilterOptions.divisionId,
          property: 'divisionId',
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'Plant',
          onChangeHandler: filterChangeHandler('plantId'),
          options: customerFilterOptions.plantId,
          property: 'plantId',
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'System ID',
          onChangeHandler: filterChangeHandler('systemId'),
          options: customerFilterOptions.systemId,
          property: 'systemId',
        },
        {
          componentType: FilterComponentTypes.Accordion,
          property: 'location',
          name: '',
          nestedFilter: [
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: 'Location',
              name: 'Region',
              onChangeHandler: filterChangeHandler('region'),
              options: customerFilterOptions.region,
              property: 'region',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'Country',
              onChangeHandler: filterChangeHandler('country'),
              options: customerFilterOptions.country,
              property: 'country',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'State',
              onChangeHandler: filterChangeHandler('state'),
              options: customerFilterOptions.state,
              property: 'state',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'City',
              onChangeHandler: filterChangeHandler('city'),
              options: customerFilterOptions.city,
              property: 'city',
            },
          ],
        },
      ],
      name: 'CUSTOMER',
    },
    {
      category: 'downSystems',
      name: 'DOWN SYSTEMS',
      filters: [
        {
          componentType: FilterComponentTypes.DateRangeSelect,
          disabled: () => sonarFilter?.timeDown?.length,
          heading: '',
          name: 'Last Seen Date Range',
          onChangeHandler: lastSeenChangeHandler('lastSeen'),
          property: 'lastSeen',
          options: null,
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => !!rangeSelected,
          heading: '',
          name: 'Time Down',
          onChangeHandler: timeDownChangeHandler('timeDown'),
          options: timeDownOptions,
          property: 'timeDown',
        },
        {
          checkboxIconType: 'visibility',
          componentType: FilterComponentTypes.Checkbox,
          label: 'Snoozed Alarms',
          name: 'Snoozed Alarms',
          onChangeHandler: snoozedAlarmsChangeHandler,
          options: [
            { id: 'true', value: 'true' },
            { id: 'false', value: 'false' },
          ],
          property: 'shouldShowSnoozed',
        },
      ],
    },
    {
      category: 'timezone',
      name: 'TIME ZONE',
      filters: [
        {
          property: 'isUTC',
          name: 'Timezone',
          componentType: FilterComponentTypes.RadioSingleSelect,
          onChangeHandler: timezoneChangeHandler,
          options: [
            {
              id: 'false',
              name: `Local (UTC${getLocalTimezoneOffsetFromUTC()})`,
            },
            {
              id: 'true',
              name: 'UTC (UTC+00:00)',
            },
          ],
          isHorizontal: false,
          color: 'secondary',
        },
      ],
    },
  ]

  return {
    activeFilters,
    addDataSettings,
    clearRangeSelected,
    customerFilterOptions,
    endDateSelected,
    expandFilters,
    filterButtonClickHandler,
    filterChangeHandler, // Returned for testing purposes
    filterCount,
    filterPanelChipsButtonClickHandler,
    filterPanelChipsChipClickHandler,
    filterPanelHelperVariables,
    filterPanelItems,
    getFilterValue,
    isFilterOpen,
    isLoading,
    rangeSelected,
    setAddDataSettings,
    setEndDateSelected,
    setExpandFilters,
    setIsFilterOpen,
    setRangeSelected,
    setStartDateSelected,
    setIsLoading,
    snoozedAlarmsChangeHandler, // Returned for testing purposes
    dateRangeChangeHandler, // Returned for testing purposes
    timeDownChangeHandler, // Returned for testing purposes
    lastSeenChangeHandler, // Returned for testing purposes
    sonarFilter,
    startDateSelected,
    technologyTypesChipClickHandler, // Returned for testing purposes
  }
}

const hooks = {
  useSonarView,
}

export default hooks
