import React, { useEffect } from 'react'
import {
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
  makeStyles,
  Button,
} from '@material-ui/core'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  commissioning: {
    width: '50px',
  },
}))

function CustomerTableRow(props) {
  CustomerTableRow.propTypes = {
    /** Function to toggle row select */
    handleSelectRow: PropTypes.func.isRequired,
    /** Function to open the modal */
    openModal: PropTypes.func.isRequired,
    /** Whether or not the table row is selected */
    isItemSelected: PropTypes.bool.isRequired,
    /** The table row label ID */
    labelId: PropTypes.string.isRequired,
    /** The table row */
    row: PropTypes.object.isRequired,
    /** The priority level for the row/customer */
    priority: PropTypes.object.isRequired,
    /** The table row that has had its Commissioning Complete checkbox clicked */
    commissioningRow: PropTypes.object.isRequired,
    /** Boolean that indicates whether a customer has just been marked Commissioning Complete */
    isCommissioningComplete: PropTypes.bool.isRequired,
    /** State setter that updates boolean determining whether a customer just been marked Commissioning Complete */
    setIsCommissioningComplete: PropTypes.func.isRequired,
  }

  const {
    handleSelectRow,
    openModal,
    isItemSelected,
    labelId,
    row,
    priority,
    isCommissioningComplete,
    setIsCommissioningComplete,
    commissioningRow,
  } = props
  const classes = useStyles()

  /** When a customer is marked Commissioning Complete, update the checkbox for that table row */
  useEffect(() => {
    if (isCommissioningComplete && row === commissioningRow) {
      row.inCommissioning = false
      setIsCommissioningComplete(false)
    }
  }, [
    commissioningRow,
    isCommissioningComplete,
    row,
    setIsCommissioningComplete,
  ])

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell id={labelId} scope="row">
        <Checkbox
          icon={<CircleUnchecked />}
          checkedIcon={<RadioButtonChecked />}
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          color="primary"
          onClick={event => handleSelectRow(event, row)}
        />
      </TableCell>
      <TableCell>{row.divisionName}</TableCell>
      <TableCell align="center" className={classes.commissioning}>
        {row.inCommissioning ? (
          <Button
            disableRipple
            onClick={() => openModal(row)}
            name="commissioningButton"
          >
            <CheckBoxOutlineBlank color="secondary" />
          </Button>
        ) : (
          <Button disabled name="commissioningButton">
            <CheckBox />
          </Button>
        )}
      </TableCell>
      <TableCell align="left">{row.numMixDesigns}</TableCell>
      <TableCell align="left">{row.numControlDesigns}</TableCell>
      <TableCell align="left">{row.numCO2Designs}</TableCell>
      <TableCell align="left">{row.tssRep}</TableCell>
      {priority && (
        <Tooltip title={priority.tip}>
          <TableCell
            style={{
              color: priority.color,
            }}
          >
            {priority.value}
          </TableCell>
        </Tooltip>
      )}
    </TableRow>
  )
}

export default CustomerTableRow
