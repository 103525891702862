import React from 'react'
import PropTypes from 'prop-types'
import MixerManifoldTablePresentational from './MixerManifoldTablePresentational.js'
import Status from '../Logic/Status.js'

function MixerManifoldTableLogical(props) {
  MixerManifoldTableLogical.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Data from the mixer manifold data endpoint */
    mixerManifoldData: PropTypes.arrayOf(Status).isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of rows for the mixer manifold data */
    count: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
  }
  const {
    handleTableSort,
    mixerManifoldData,
    order,
    orderBy,
    page,
    count,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  /** Table column headers */
  const headCells = [
    {
      id: 'dateTime',
      numeric: false,
      label: 'Date & Time',
    },
    {
      id: 'inj_sec',
      numeric: true,
      label: '',
    },
    {
      id: 'flowRateKgPerSec',
      numeric: true,
      label: 'CO2 Flow Rate (kg/s)',
    },
    {
      id: 'temp_c',
      numeric: true,
      label: 'Temperature (C)',
    },
    {
      id: 'us_psi',
      numeric: true,
      label: 'Upstream Pressure (psi)',
    },
    {
      id: 'ds_psi',
      numeric: true,
      label: 'Downstream Pressure (psi)',
    },
    {
      id: 'in_Liq_psi',
      numeric: true,
      label: 'Inlet Liquid Pressure (psi)',
    },
    {
      id: 'in_Gas',
      numeric: true,
      label: 'Inlet Gas Pressure (psi)',
    },
    {
      id: 'co2_kg',
      numeric: true,
      label: '',
    },
  ]

  return (
    <MixerManifoldTablePresentational
      mixerManifoldData={mixerManifoldData}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleTableSort={handleTableSort}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      order={order}
      orderBy={orderBy}
      headCells={headCells}
    />
  )
}

export default MixerManifoldTableLogical
