import React from 'react'
import PropTypes from 'prop-types'
import { InputBase, makeStyles, Grid } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

function filterIt(arr, searchKey) {
  return arr.filter(obj =>
    ['plantName', 'corporationName'].some(key =>
      obj[key].toUpperCase().includes(searchKey.toUpperCase())
    )
  )
}

const useSearchStyles = makeStyles({
  search: {
    background: '#E6E6E6 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '19px',
    width: '292px',
    height: '38px',
  },
})

ArrayOfObjectSearch.propTypes = {
  arraySearchOn: PropTypes.array.isRequired,
  setResultState: PropTypes.func.isRequired,
}

function ArrayOfObjectSearch(props) {
  const { arraySearchOn, setResultState } = props
  const classes = useSearchStyles()
  const handleOnChange = event => {
    const text = event.target.value

    if (text.length === 0) {
      setResultState(arraySearchOn)
    } else {
      setResultState(filterIt(arraySearchOn, text))
    }
  }

  return (
    <Grid
      className={classes.search}
      container
      spacing={1}
      alignItems="flex-end"
    >
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid item>
        <InputBase placeholder="Search" onChange={handleOnChange} />
      </Grid>
    </Grid>
  )
}

export default ArrayOfObjectSearch
