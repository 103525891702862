import React, { useState } from 'react'
import {
  List,
  ListItem,
  makeStyles,
  Button,
  Popover,
  Backdrop,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'
import { ICSVParam } from '../../Interfaces/EchoInterfaces'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles(() => ({
  optionButton: {
    width: '170px',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1200,
  },
}))

interface IComponentProps {
  CSVParams: Array<ICSVParam>
  isLoading: boolean
}

function DownloadDataPresentational(props: IComponentProps) {
  const { CSVParams, isLoading } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'download-menu' : undefined

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Tooltip
        arrow
        title={
          CSVParams.length > 0
            ? ''
            : 'You must select a map layer to be able to download data.'
        }
      >
        <span>
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetAppIcon />}
            onClick={event => handleClick(event)}
            disabled={CSVParams.length === 0}
            id="downloadEchoData"
          >
            Download data
          </Button>
        </span>
      </Tooltip>
      <Popover
        PaperProps={{
          style: { width: '200px' },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {CSVParams.map(dataSet => {
            return (
              <ListItem key={dataSet.label}>
                <CSVLink
                  data={dataSet.CSVData}
                  headers={dataSet.headers}
                  filename={dataSet.filename}
                >
                  <Button
                    id={dataSet.id}
                    color="primary"
                    variant="outlined"
                    className={classes.optionButton}
                    onClick={handleClose}
                  >
                    {dataSet.label}
                  </Button>
                </CSVLink>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </>
  )
}
export default DownloadDataPresentational
