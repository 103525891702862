import React from 'react'
import { formatCorpDataForDownload } from '../../Logic/DataFormatters'
import {
  upcomingProjectHeaders,
  carbonCureProjectHeaders,
  corpDataHeaders,
  co2VendorHeaders,
} from '../../Constants/Constants'
import DownloadDataPresentational from './DownloadDataPresentational'
import { useRecoilValue } from 'recoil'
import {
  atomEchoSettings,
  atomFilteredOngoingProjects,
  atomFilteredFinishedProjects,
} from '../../../Common/echoAtoms'
import { CO2DepotTableFeature } from '../../../Common/assets/FeatureFlags'
import {
  IFormattedUpcomingProject,
  IConcreteCorporation,
  IFormattedCO2Vendor,
} from '../../Interfaces/EchoInterfaces'
import { EProjectTypes, EMapLayers } from '../../Enums/EchoEnums'
import { getDate } from '../../../Common/Helpers/GeneralHelpers'
import cementTableHelper from '../CementTable/cementTableHelper'
import { ICementTableDownloadRow } from '../../Logic/Types'

interface IComponentProps {
  filteredUpcomingProjects: Array<IFormattedUpcomingProject>
  readyMixTableData: Array<IConcreteCorporation>
  precastTableData: Array<IConcreteCorporation>
  isLoading: boolean
  formattedCO2VendorData: Array<IFormattedCO2Vendor>
  cementTableDataForDownload: ICementTableDownloadRow[]
}

function DownloadDataLogical(props: IComponentProps) {
  const echoSettings = useRecoilValue(atomEchoSettings)
  const salesPerson =
    echoSettings.salesPeople.length > 0 ? echoSettings.salesPeople[0] : ''
  const filterByState =
    echoSettings.states.length > 0 ? echoSettings.states : []
  const filterByCity =
    echoSettings.cities.length > 0 ? echoSettings.cities[0] : ''
  const filterByCompany =
    echoSettings.companies.length > 0 ? echoSettings.companies[0] : ''
  const {
    filteredUpcomingProjects,
    readyMixTableData,
    precastTableData,
    isLoading,
    formattedCO2VendorData,
    cementTableDataForDownload,
  } = props

  /** Atom array of filtered ongoing CarbonCure construction projects */
  const filteredOngoingProjects = useRecoilValue(atomFilteredOngoingProjects)
  /** Atom array of filtered completed CarbonCure construction projects */
  const filteredFinishedProjects = useRecoilValue(atomFilteredFinishedProjects)

  /** Format the filtered ready mix data for the CSV file to be downloaded */
  const formattedReadyMixData = formatCorpDataForDownload(
    readyMixTableData,
    'Ready Mix',
    filterByState,
    filterByCity,
    salesPerson,
    filterByCompany
  )

  /** Format the filtered precast data for the CSV file to be downloaded */
  const formattedPrecastData = formatCorpDataForDownload(
    precastTableData,
    'Precast',
    filterByState,
    filterByCity,
    salesPerson,
    filterByCompany
  )

  /** Format the filtered upcoming project data for the CSV file to be downloaded */
  const formattedUpcomingProjectData = filteredUpcomingProjects.map(project => {
    return {
      project_id: project.constructionProjectId,
      organization: project.organization,
      description: project.description,
      location: project.formattedLocation,
      country: project.country,
      start_date: project.targetStartDate,
      size_m2: project.formattedSize,
      monetaryValue: project.monetaryValue
        ? `${project.monetaryValue.toLocaleString()} ${project.currencyCode}`
        : '',
      stage: project.formattedStage,
      closest_plants: project.closestPlants,
    }
  })

  /** Time stamp of the current date in YYYY-MM-DD format */
  const currentTimestamp = getDate(Date.now())

  /** Parameters for CSVLink */
  const CSVParams = []

  if (
    echoSettings.layers.includes(EMapLayers.rmPlants) ||
    echoSettings.layers.includes(EMapLayers.rmCompanies)
  ) {
    CSVParams.push({
      label: EMapLayers.rmPlants,
      id: 'readyMix',
      CSVData: formattedReadyMixData,
      headers: corpDataHeaders,
      filename: `filtered_ready_mix_plants_${currentTimestamp}.csv`,
    })
  }
  if (
    echoSettings.layers.includes(EMapLayers.precastPlants) ||
    echoSettings.layers.includes(EMapLayers.precastCompanies)
  ) {
    CSVParams.push({
      label: EMapLayers.precastPlants,
      id: 'precast',
      CSVData: formattedPrecastData,
      headers: corpDataHeaders,
      filename: `filtered_precast_plants_${currentTimestamp}.csv`,
    })
  }
  if (echoSettings.layers.includes(EMapLayers.cementPlants)) {
    CSVParams.push({
      label: 'Cements',
      id: 'cement',
      CSVData: cementTableDataForDownload,
      headers: cementTableHelper.cementTableDownloadHeadCells,
      filename: `cements_${currentTimestamp}.csv`,
    })
  }
  if (echoSettings.projects.includes(EProjectTypes.upcoming)) {
    CSVParams.push({
      label: EProjectTypes.upcoming,
      id: 'upcomingProjects',
      CSVData: formattedUpcomingProjectData,
      headers: upcomingProjectHeaders,
      filename: `filtered_upcoming_projects_${currentTimestamp}.csv`,
    })
  }
  if (echoSettings.projects.includes(EProjectTypes.ongoing)) {
    CSVParams.push({
      label: EProjectTypes.ongoing,
      id: 'ongoingProjects',
      CSVData: filteredOngoingProjects,
      headers: carbonCureProjectHeaders,
      filename: `filtered_ongoing_projects_${currentTimestamp}.csv`,
    })
  }
  if (echoSettings.projects.includes(EProjectTypes.finished)) {
    CSVParams.push({
      label: EProjectTypes.finished,
      id: 'finishedProjects',
      CSVData: filteredFinishedProjects,
      headers: carbonCureProjectHeaders,
      filename: `filtered_finished_projects_${currentTimestamp}.csv`,
    })
  }
  if (
    CO2DepotTableFeature &&
    echoSettings.layers.includes(EMapLayers.co2Depots)
  ) {
    CSVParams.push({
      label: 'CO₂ Vendors',
      id: 'co2Vendors',
      CSVData: formattedCO2VendorData,
      headers: co2VendorHeaders,
      filename: `filtered_CO2_vendors_${currentTimestamp}.csv`,
    })
  }

  return (
    <DownloadDataPresentational CSVParams={CSVParams} isLoading={isLoading} />
  )
}

export default DownloadDataLogical
