import PropTypes from 'prop-types'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

function NavSubMenuItem(props) {
  const { title, path, onClick } = props

  return (
    <Box marginLeft={2}>
      <MenuItem component={Link} to={path} onClick={onClick}>
        <Typography variant="subtitle2">{title}</Typography>
      </MenuItem>
    </Box>
  )
}

NavSubMenuItem.propTypes = {
  /** Title of the page within this project */
  title: PropTypes.string.isRequired,
  /** Path to the page within this project */
  path: PropTypes.string.isRequired,
  /** The onClick function that will be passed to the menu item */
  onClick: PropTypes.func.isRequired,
}

export default NavSubMenuItem
