import { CellBehavior, SimpleTableTypes } from '../../../Common/Logic/Types'
import {
  addDataTooltips,
  headCellsAddDataView,
  headCellsMixDesigns,
} from '../../../TSS/Constants/AddDataConstants'
import { headCellsMaterialTable } from '../../../TSS/Constants/MaterialManagerConstants'
import { getBaleenSampleHeaders } from '../../../TSS/Helpers/BaleenHelpers'
import {
  AddDataHeadCell,
  DigestedMixDesign,
  MaterialTableTooltips,
} from '../../../TSS/Logic/Types'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { baseColors } from '../../../theme/colors'
import { reportLibraryHeadCells } from '../../../TSS/Constants/CommissionReportConstants'
import cementTableHelper from '../../../Echo/Components/CementTable/cementTableHelper'

/**
 * SimpleTable
 *
 */
export default class SimpleTable {
  rows: Array<any>
  headCells: Array<any>

  constructor(digestedMixDesigns: DigestedMixDesign[] = [], headCells = []) {
    this.rows = digestedMixDesigns.map(e => {
      const cells = [
        e.producer,
        e.locationName,
        e.mixCode,
        '*** assoc groups',
        '*** status label',
        {
          behavior: CellBehavior.Chips,
          content: [
            {
              text: e.condition,
              color: 'Primary',
            },
          ],
        },
        '*** cmt red',
        '*** co2 dos',
        e.totalBatches,
        e.totalCylinders,
        e.designStrength,
        e.interval,
        e.waterCementRatio,
        '*** cCM load',
        e.totalYield,
      ]

      return {
        id: e.mixDesignId,
        cells: cells,
      }
    })
    this.headCells = headCells
  }
}

/**
 * SimpleTable headcell widths and styling
 *
 */
export const simpleTableStyles = (
  tableType: SimpleTableTypes,
  theme: Theme,
  selected: Array<number | string>,
  areTablesDisabled: boolean = false,
  isFilterPanelOpen?: boolean
) => {
  let styles: Record<string, any> = {} //need to be any type here
  switch (tableType) {
    case SimpleTableTypes.AddDataMixGroup:
      styles.selectorCell = {
        width: '78px',
        [theme.breakpoints.up(2000)]: {
          width: '5.87%',
        },
      }
      headCellsAddDataView.forEach((cell: AddDataHeadCell) => {
        if (selected.some(Number) && cell.id === 'editAssociation') {
          styles[cell.id] = {
            width: cell.width,
            [theme.breakpoints.up(2000)]: {
              width: cell.xxlWidth,
            },
            [theme.breakpoints.down(2000)]: {
              position: 'sticky',
              right: 0,
              backgroundColor: '#f9f9f9',
            },
          }
        } else {
          styles[cell.id] = {
            width: cell.width,
            [theme.breakpoints.up(2000)]: {
              width: cell.xxlWidth,
            },
          }
        }
        if (cell.wordBreak === false) {
          styles[cell.id] = {
            ...styles[cell.id],
            wordBreak: 'normal',
          }
        }
      })
      break
    case SimpleTableTypes.ViewDesignMixGroup:
      styles.selectorCell = {
        width: '78px',
        [theme.breakpoints.up(2200)]: {
          width: '5.88%',
        },
      }
      headCellsMixDesigns.forEach((cell: AddDataHeadCell) => {
        styles[cell.id] = {
          width: cell.width,
          [theme.breakpoints.up(2200)]: {
            width: cell.xxlWidth ? cell.xxlWidth : cell.width,
          },
        }
        if (cell.wordBreak === false) {
          styles[cell.id] = {
            ...styles[cell.id],
            wordBreak: 'normal',
          }
        }
      })
      break
    case SimpleTableTypes.MaterialManager:
      headCellsMaterialTable.forEach(cell => {
        if (cell.id === 'mergeMaterial') {
          styles[cell.id] = {
            width: cell.width,
            ...(!isFilterPanelOpen
              ? {}
              : {
                  position: 'sticky',
                  right: 0,
                  backgroundColor: '#f9f9f9',
                }),
            [theme.breakpoints.up(2000)]: {
              width: cell.xxlWidth,
            },
            [theme.breakpoints.down(1500)]: {
              position: 'sticky',
              right: 0,
              backgroundColor: '#f9f9f9',
            },
          }
        } else {
          styles[cell.id] = {
            width: cell.width,
            [theme.breakpoints.up(2000)]: {
              width: cell.xxlWidth,
            },
          }
        }
      })
      break
    case SimpleTableTypes.BaleenSamplesTable:
      const headCellsBaleenSampleTable = getBaleenSampleHeaders(
        SimpleTableTypes.BaleenSamplesTable,
        false
      )
      headCellsBaleenSampleTable.forEach(cell => {
        styles[cell.id] = {
          width: cell.width,
        }
      })
      break
    case SimpleTableTypes.BaleenOutliersTable:
      const headCellsBaleenOutliersTable = getBaleenSampleHeaders(
        SimpleTableTypes.BaleenOutliersTable,
        false
      )
      headCellsBaleenOutliersTable.forEach(cell => {
        styles[cell.id] = {
          width: cell.width,
          color: areTablesDisabled
            ? baseColors.action.disabled
            : baseColors.text.primary,
        }
      })
      styles['baleenBodyRow'] = {
        backgroundColor: baseColors.primary.eightOpacity,
      }
      break
    case SimpleTableTypes.ReportLibraryTable:
      reportLibraryHeadCells.forEach(cell => {
        if (cell.id === 'view') {
          styles[cell.id] = {
            width: cell.width,
            [theme.breakpoints.down(1120)]: {
              position: 'sticky',
              right: 0,
              backgroundColor: '#f9f9f9',
            },
          }
        } else {
          styles[cell.id] = {
            width: cell.width,
          }
        }
      })
      break
    case SimpleTableTypes.EchoCementTable:
      cementTableHelper.cementTableHeadCells.forEach(cell => {
        styles[cell.id] = {
          width: cell.width,
          padding: '6px 16px',
        }
      })
      break
    default:
      return null
  }
  return styles
}

export const getTooltip = (
  tableType: SimpleTableTypes,
  isIngested: boolean | undefined
) => {
  if (tableType === SimpleTableTypes.MaterialManager) {
    return isIngested
      ? [MaterialTableTooltips.MergeMaterial]
      : [MaterialTableTooltips.LinkedMixDesigns]
  }
  return [
    addDataTooltips.viewAssociation,
    addDataTooltips.viewMix,
    addDataTooltips.baleen,
  ]
}
