import {
  ConvertMPAToPSIRounded,
  getKeyByValue,
  roundUpToSigFigs,
} from '../../Common/Helpers/GeneralHelpers'
import {
  GallonPerYd3ToLitrePerM3,
  InToMm,
  LbPerYd3ToKgPerM3,
  OzPerYd3ToLitrePerM3,
} from '../../Common/Helpers/UnitConversions'
import { admixTypes, weightUnits } from '../Constants/AddDataConstants'
import { MixGroupConditions } from '../Logic/Types'
import { getMaterialName } from './TSSLogic'

/**
 * Parses an interval string in the format "X.00:00:00" to extract the number of days.
 *
 * @param {string} interval - The interval string to be parsed.
 * @returns {number|null} The number of days if the interval is valid, otherwise null.
 */
export const parseInterval = interval =>
  interval &&
  !interval.startsWith('-') &&
  !isNaN(parseInt(interval.split('.')[0], 10))
    ? parseInt(interval.split('.')[0], 10)
    : null

/** Extract mix details to autopopulate form fields on AddMixDesign. */
export const extractMixDetails = (mix, isImperial, materials) => {
  const slumpConversionFactor = isImperial ? InToMm : 1

  if (!materials.length) return // exit if materials from kelowna is yet available

  // Extract materials and cementType.
  const mixMaterials = extractMixMaterials(
    mix.ingredients,
    isImperial,
    materials
  )
  // Parse the interval format "X.00:00:00" to get the number of days
  const intervalDays = parseInterval(mix.compressiveStrengthInterval)
  return {
    properties: {
      cementType: materials.cementType,
      description: mix.description || '',
      interval: intervalDays || '28', // Use the parsed interval value
      exposureClass: mix.exposureClass || null,
      wcRatio: mix.waterCementRatio || '',
      designStrength: extractMixDesignStrength(
        mix.compressiveStrengthMPa,
        isImperial
      ),
      airContentRange:
        mix.airContentPctMin != null && mix.airContentPctMax != null
          ? `${mix.airContentPctMin} - ${mix.airContentPctMax}`
          : '',
      slumpRange:
        mix.slumpMmMin && mix.slumpMmMax
          ? `${roundUpToSigFigs(
              mix.slumpMmMin / slumpConversionFactor
            )} - ${roundUpToSigFigs(mix.slumpMmMax / slumpConversionFactor)}`
          : '',
      strippingStrength: extractMixDesignStrength(
        mix.strippingStrengthMPa,
        isImperial
      ),
      strippingInterval: mix.strippingStrengthMPa
        ? mix.strippingStrengthInterval.hours
        : '15',
    },
    mixMaterials: mixMaterials,
  }
}

/** Extract quantity and convert value + units from Kelowna to match UI. */
export const extractQuantity = (value, unit, isImperial, materialType) => {
  let frontEndValue
  let frontEndUnit
  const admixDisplayNames = Object.values(admixTypes)
  // Convert value and unit to default imperial values if required.
  if (isImperial) {
    frontEndValue = value
    frontEndUnit = weightUnits[unit]
    if (unit === 'KgPerM3') {
      frontEndValue = value / LbPerYd3ToKgPerM3
      frontEndUnit = weightUnits['LbPerYd3']
    }
    if (unit === 'LitrePerM3') {
      // If it is an admixture it should be converted to oz/yd3.
      if (admixDisplayNames.includes(materialType)) {
        frontEndValue = value / OzPerYd3ToLitrePerM3
        frontEndUnit = weightUnits['OzPerYd3']
      } else {
        frontEndValue = value / GallonPerYd3ToLitrePerM3
        frontEndUnit = weightUnits['GallonPerYd3']
      }
    }
  } else {
    frontEndValue = value
    frontEndUnit = weightUnits[unit]
    if (unit === 'LitrePerM3') {
      // If the value is an admixture it should be converted to mL.
      if (admixDisplayNames.includes(materialType)) {
        frontEndValue = value * 1000
        frontEndUnit = weightUnits['MlPerM3']
      }
    }
  }
  return { value: roundUpToSigFigs(frontEndValue), unit: frontEndUnit }
}

/** Extract all mix materials and the cement type. */
const extractMixMaterials = (mixIngredients, isImperial, materials) => {
  const extractedMaterials = mixIngredients.map(ingredient => {
    const matched = materials.find(
      mat => mat.materialId === ingredient.materialId
    )

    // Get material quantity and unit.
    const quantity = extractQuantity(
      ingredient.amount,
      ingredient.unit,
      isImperial,
      ingredient.materialType
    )
    if (matched) {
      return {
        ...matched,
        quantity: quantity.value,
        unit: quantity.unit,
      }
    } else {
      //error handling when no match - there is missing material at kelowna endpoint
      console.log(
        `Error: Material: ${ingredient.materialName} (materialId:${ingredient.materialId}) has no match!`
      )
      return null
    }
  })
  return extractedMaterials
}

/** Extract mix design strength and round to integer if converting to imperial. */
const extractMixDesignStrength = (compressiveStrengthMPa, isImperial) => {
  if (compressiveStrengthMPa == null) return ''
  else if (isImperial) return ConvertMPAToPSIRounded(compressiveStrengthMPa)
  else return compressiveStrengthMPa
}

/** Extract max and min values from string with range. */
const getRangeValues = range =>
  range ? range.split('-').map(val => parseFloat(val)) : [null, null]

/** Create mix design object for post method. */
export const createMixDesignObject = (mixDetails, materials, customer) => {
  const airContentRange = getRangeValues(mixDetails.airContentRange)
  const slumpRange = getRangeValues(mixDetails.slumpRange)
  const isCO2Design = mixDetails.condition === MixGroupConditions.CO2
  return {
    plantId: customer.plant.plantId,
    mixCode: mixDetails.mixCode,
    isCO2Design,
    preCCMixDesignId: isCO2Design ? mixDetails.variationMix?.mixDesignId : null,
    description: mixDetails.description,
    waterCementRatio: parseFloat(mixDetails.wcRatio),
    compressiveStrength: parseFloat(mixDetails.designStrength),
    compressiveStrengthHours: parseInt(mixDetails.interval) * 24,
    strippingStrength: customer.division.isPrecast
      ? parseFloat(mixDetails.strippingStrength)
      : null,
    strippingStrengthHours: customer.division.isPrecast
      ? parseInt(mixDetails.strippingInterval)
      : null,
    strengthUnit: mixDetails.strengthUnit,
    exposureClass: mixDetails.exposureClass,
    baseLineCO2: mixDetails.baseLineCO2
      ? parseFloat(mixDetails.baseLineCO2)
      : null,
    baseLineCementReduction: mixDetails.baseLineCementReduction
      ? parseFloat(mixDetails.baseLineCementReduction)
      : null,
    airContentPctMin: airContentRange[0],
    airContentPctMax: airContentRange[1],
    slumpMin: slumpRange[0],
    slumpMax: slumpRange[1],
    slumpUnit: mixDetails.slumpUnit,
    ingredients: materials.map(mat => ({
      amount: parseFloat(mat.quantity),
      unit: getKeyByValue(weightUnits, mat.units),
      materialName: getMaterialName(mat),
      ...(mat.materialId
        ? { materialId: mat.materialId }
        : { materialType: mat.type }),
    })),
  }
}

export const getMaterialDefaultUnits = unitsSystem => {
  return unitsSystem === 'Imperial'
    ? ['lb / yd³', 'oz / yd³']
    : ['kg / m³', 'mL / m³']
}
