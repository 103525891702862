import React, { Dispatch, SetStateAction } from 'react'
import {
  Collapse,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { Autocomplete } from '@material-ui/lab'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { makeStyles } from '@material-ui/core/styles'
import {
  EditMixAssociationOptions,
  MixGroupStatus,
  MixGroupConditions,
} from '../../Logic/Types'
import MixGroupOptionWithBaselineConditions from '../MixGroupOptionWithBaselineConditions'

export interface IEditMixAssociationPresentational {
  /** Boolean indicating whether or not the Edit Mix Association interface is visible */
  isEditMixAssociationMenuOpen: boolean
  /** Boolean indicating whether or not the Accept & Close button is disabled */
  isAcceptButtonDisabled: boolean
  /** Boolean indicating whether or not the Delete button is disabled */
  isDeleteButtonDisabled: boolean
  /** String indicating whether the condition is CO2 or NONCO2 */
  conditionTag: string
  /** String indicating whether the condition is Active or Archived */
  statusTag: string
  /** Object of the selected mix code */
  associatedMixDesign: EditMixAssociationOptions
  /** Function setting the status of the associated mix */
  setStatusTag: Dispatch<SetStateAction<string>>
  /** Function setting the condition of the associated mix */
  setConditionTag: Dispatch<SetStateAction<string>>
  /** Function setting the selected associated mix code */
  setAssociatedMixDesign: Dispatch<
    SetStateAction<EditMixAssociationOptions | null>
  >
  /** Function to accept the changes and close the Edit Mix Association interface */
  acceptAndClose: () => void
  /** Function to reset all values of the inputs in the Edit Mix Association interface */
  clearAll: () => void
  /** associated mix options in dropdown */
  options: Array<EditMixAssociationOptions>
  /** Number of plant options */
  plantOptionsLength: number
  /** Boolean indicating a plant has been selected or not */
  isPlantSelected: boolean
  /** Mix Associations of Mix Design being edited */
  mixAssociationsResults: Array<any>
  /** The Parent Mix Association of the Mix Design being edited  */
  parentMixAssociation: any
}

const useStyles = makeStyles(theme => ({
  cardHeader: {
    paddingBottom: '4px',
  },
  cardContent: {
    paddingTop: '0px',
  },
  closeIcon: {
    color: 'rgb(215, 79, 57)',
  },
  disabledCloseIcon: {
    color: 'gray',
  },
  inputsContainer: {
    paddingBottom: '8px',
  },
  customTextField: {
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  associatedMixInput: {
    padding: '8px',
    paddingLeft: 0,
  },
  gridItems: {
    padding: '8px',
    paddingLeft: 0,
  },
  gridButton: {
    padding: '12px',
    paddingLeft: 0,
  },
}))

const EditMixAssociationPresentational = (
  props: IEditMixAssociationPresentational
) => {
  const {
    isEditMixAssociationMenuOpen,
    isAcceptButtonDisabled,
    isDeleteButtonDisabled,
    conditionTag,
    statusTag,
    associatedMixDesign,
    setStatusTag,
    setConditionTag,
    setAssociatedMixDesign,
    acceptAndClose,
    clearAll,
    options,
    isPlantSelected,
    plantOptionsLength,
    parentMixAssociation,
  } = props
  const classes = useStyles()

  return (
    <>
      <Collapse
        in={
          isEditMixAssociationMenuOpen &&
          (isPlantSelected || plantOptionsLength === 0)
        }
        timeout="auto"
        unmountOnExit
      >
        <Card elevation={3}>
          <CardHeader
            className={classes.cardHeader}
            title="Edit Mix Association"
            titleTypographyProps={{
              variant: 'h5',
            }}
            subheader="To filter by condition or status tag, use those dropdowns before
            selecting an Associated Mix."
          />
          <CardContent className={classes.cardContent}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.inputsContainer}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                className={classes.associatedMixInput}
              >
                <Autocomplete
                  options={options}
                  value={
                    associatedMixDesign
                      ? associatedMixDesign
                      : parentMixAssociation
                  }
                  onChange={(_, newValue) => {
                    setAssociatedMixDesign(newValue)
                  }}
                  id="associated-mix-code"
                  data-testid="associated-mix-code"
                  getOptionSelected={(option, value) =>
                    option.mixCode || '' === value.mixCode
                  }
                  getOptionLabel={option => option.mixCode || ''}
                  renderOption={(option: EditMixAssociationOptions) => (
                    <MixGroupOptionWithBaselineConditions option={option} />
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select an Associated Mix"
                      variant="outlined"
                      size="small"
                      name="associatedMixDesign"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} className={classes.gridItems}>
                <TextField
                  variant="outlined"
                  label="Condition Tag"
                  size="small"
                  select
                  name="condition"
                  placeholder="Condition"
                  value={conditionTag}
                  onChange={e => setConditionTag(e.target.value)}
                  data-testid="condition"
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value={MixGroupConditions.CO2}>
                    {MixGroupConditions.CO2}
                  </MenuItem>
                  <MenuItem value={MixGroupConditions.NOCO2}>
                    {MixGroupConditions.NOCO2}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={3} className={classes.gridItems}>
                <TextField
                  variant="outlined"
                  label="Status Tag"
                  size="small"
                  select
                  name="status"
                  placeholder="Status"
                  value={statusTag}
                  onChange={e => {
                    setStatusTag(e.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value={MixGroupStatus.ACTIVE}>Active</MenuItem>
                  <MenuItem value={MixGroupStatus.ARCHIVED}>Archived</MenuItem>
                </TextField>
              </Grid>
              <Grid item sm={4} md={1} className={classes.gridButton}>
                <Tooltip
                  title={
                    isDeleteButtonDisabled
                      ? 'Cannot disassociate active mix or its predecessor.'
                      : 'Delete Mix Association'
                  }
                >
                  {/* Span tag needed to allow tooltip to display while button is disabled */}
                  <span>
                    <IconButton
                      onClick={clearAll}
                      data-testid="clear-all"
                      style={{ paddingLeft: 0 }}
                      disabled={isDeleteButtonDisabled}
                    >
                      <CloseOutlinedIcon
                        className={
                          isDeleteButtonDisabled
                            ? classes.disabledCloseIcon
                            : classes.closeIcon
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid>
                {isAcceptButtonDisabled && (
                  <Typography variant="caption" color="error">
                    Active mixes and their predecessors can only be reassociated
                    to other mixes within the same group of associated mixes.
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              size="medium"
              variant={'contained'}
              color="primary"
              type="button"
              onClick={acceptAndClose}
              data-testid="accept-and-close"
              disabled={isAcceptButtonDisabled}
            >
              Accept & Close
            </Button>
          </CardContent>
        </Card>
      </Collapse>
    </>
  )
}

export default EditMixAssociationPresentational
