import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Grid,
  makeStyles,
} from '@material-ui/core'
import CustomerTableHead from './CustomerTableHead'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { atomCurrentCustomer } from '../../../Common/atoms'
import { customerPriorities } from '../../Constants/CustomersPageConstants'
import CommissioningDialogLogical from '../CommissioningDialog/CommissioningDialogLogical'
import CustomerTableRow from './CustomerTableRow'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: '2em',
  },
  tableHeadContainer: {
    marginBottom: '1.5em',
  },
}))

function CustomerTablePresentational(props) {
  CustomerTablePresentational.propTypes = {
    /** Table rows */
    rows: PropTypes.array.isRequired,
    /** Filtered table rows */
    filteredRows: PropTypes.array.isRequired,
    /** Function to update the search query */
    setSearched: PropTypes.func.isRequired,
    /** The sort order of the table: ascending or descending */
    order: PropTypes.string.isRequired,
    /** The name of the table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Function to sort the table */
    handleRequestSort: PropTypes.func.isRequired,
    /** The page number of the table page that is being viewed */
    page: PropTypes.number.isRequired,
    /** The number of table rows to view per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Function to toggle row select */
    handleSelectRow: PropTypes.func.isRequired,
    /** Function to change to a different table page */
    handleChangePage: PropTypes.func.isRequired,
    /** Function to change the number of table rows to display per page */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Boolean to determine whether the modal is open */
    open: PropTypes.bool.isRequired,
    /** State setter to open/close the modal */
    setOpen: PropTypes.func.isRequired,
    /** Function to open the modal */
    openModal: PropTypes.func.isRequired,
    /** The table row that has had its Commissioning Complete checkbox clicked */
    commissioningRow: PropTypes.object.isRequired,
    /** Boolean that indicates whether a customer has just been marked Commissioning Complete */
    isCommissioningComplete: PropTypes.bool.isRequired,
    /** State setter that updates boolean determining whether a customer just been marked Commissioning Complete */
    setIsCommissioningComplete: PropTypes.func.isRequired,
  }

  const {
    filteredRows,
    order,
    orderBy,
    handleRequestSort,
    page,
    rowsPerPage,
    handleSelectRow,
    handleChangePage,
    handleChangeRowsPerPage,
    open,
    setOpen,
    openModal,
    commissioningRow,
    setIsCommissioningComplete,
    isCommissioningComplete,
  } = props
  const classes = useStyles()

  const selectedCustomer = useRecoilValue(atomCurrentCustomer)

  const isSelected = row =>
    selectedCustomer.division?.divisionId === row.divisionId

  return (
    <>
      <CommissioningDialogLogical
        open={open}
        setOpen={setOpen}
        commissioningRow={commissioningRow}
        setIsCommissioningComplete={setIsCommissioningComplete}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.tableHeadContainer}
      >
        <Grid
          item
          container
          xs={12}
          md={7}
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={2}
        ></Grid>
      </Grid>
      <Paper className={classes.paper} variant="outlined">
        <TableContainer>
          <Table aria-label="customers" size="small">
            <CustomerTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* Map through the filtered rows and display them */}
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isItemSelected = isSelected(row)
                  const labelId = `customer-${row.divisionId}`
                  const priority = customerPriorities[row.priority]
                  return (
                    <CustomerTableRow
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      key={labelId}
                      row={row}
                      priority={priority}
                      handleSelectRow={handleSelectRow}
                      openModal={openModal}
                      isCommissioningComplete={isCommissioningComplete}
                      setIsCommissioningComplete={setIsCommissioningComplete}
                      commissioningRow={commissioningRow}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[20, 35]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default CustomerTablePresentational
