/**
 * Convert a unix timestamp into a YYYY/MM/DD HH:MM:SS time
 * @param {num} unixTimeStamp A unix timestamp, will convert it to milliseconds by multiplying by 1000
 */
export function getPrettyDate(unixTimeStamp) {
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  let date = new Date(unixTimeStamp * 1000)
  // Hours part from the timestamp
  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear()

  let hours = date.getHours()
  // Minutes part from the timestamp
  let minutes = date.getMinutes()
  // Seconds part from the timestamp
  let seconds = date.getSeconds()

  // Will display time in 10:30:23 format
  let formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  return `${year}/${month}/${day} ${formattedTime}`
}

/**
 * Returns a random number from 0->max
 * @param {number} max The maximum number of your random int
 */
export function getRandomInt(max) {
  const randomBuffer = new Uint32Array(1)
  const randomValue = window.crypto.getRandomValues(randomBuffer)[0]
  let randomNumber = randomValue / (0xffffffff + 1)

  const min = 0
  max = Math.floor(max)
  return Math.floor(randomNumber * (max - min + 1)) + min
}

const alarmCodeToAlertLevelMapping = {
  0: 1,
  1: 3,
  2: 3,
  3: 3,
  4: 2,
  5: 2,
  6: 2,
  7: 2,
  8: 3,
  9: 1,
  10: 3,
  11: 3,
  12: 1,
  13: 3,
  14: 3,
  15: 2,
  16: 2,
  17: 3,
  18: 3,
  19: 3,
  20: 3,
  21: 1,
  22: 2,
  23: 3,
  24: 2,
}

const alarmCodeToDescription = {
  0: 'Low Priority: Eeprom Self Test Failed',
  1: 'High Priority: Uninitialized Site Variables',
  2: 'High Priority: Valve Enclosure Power Off',
  3: 'High Priority: Lost Sensor Connections',
  4: 'Medium Priority: Nonsense Sensor Readings',
  5: 'Medium Priority: Sensor Malfunction',
  6: 'Medium Priority: High Tank Pressure Prv Malfunction',
  7: 'Medium Priority: High Tank Pressure Economizer Malfunction',
  8: 'High Priority: Sensor Malfunction or Ball Valve Closed',
  9: 'Low Priority: Low CO2 Tank Pressure',
  10: 'High Priority: Extremely Low CO2 Tank Pressure',
  11: 'High Priority: High Pressure During Injection',
  12: 'Low Priority: Low Pressure During Injection',
  13: 'High Priority: Liquid Valve Malfunction',
  14: 'High Priority: Gas Valve Malfunction',
  15: 'Medium Priority: High Delta-P Across Liquid Valve',
  16: 'Medium Priority: High Pressure After Injection',
  17: 'High Priority: Nozzle Clogged',
  18: 'High Priority: Obstruction Before Orifice',
  19: 'High Priority: Solenoid Malfunction',
  20: 'High Priority: High Pressure When Valves Closed',
  21: 'Low Priority: High Ambient Temperature',
  22: 'High Priority: Very High CO2 Concentration',
  23: 'High Priority: Very High CO2 Concentration',
  24: 'High Priority: High Downstream Pressure',
  25: 'Mod Bus Communication Timeout',
}

/**
 * Get the alert level from an alarm code
 *
 * @param {Number} alarmCode The number representing the alarm code
 * @returns {Number} 1 for low, 2 for medium, 3 for high, or 0 if it is out of bounds
 */
export function getAlarmAlertLevel(alarmCode) {
  if (alarmCode > 24) {
    return 0
  } else {
    return alarmCodeToAlertLevelMapping[alarmCode]
  }
}

/**
 * Takes an array of alarm codes and returns the highest alert level from the list
 *
 * @param {Array<number>} alarms
 */
export function getHighestAlarmLevel(alarms) {
  let returnAlarmLevel = 0
  alarms.forEach(alarm => {
    const alarmLevel = getAlarmAlertLevel(alarm)
    returnAlarmLevel =
      returnAlarmLevel > alarmLevel ? returnAlarmLevel : alarmLevel
  })

  return returnAlarmLevel
}

/**
 * Function that takes in an alarm code and spits out a string that describes the alarm
 *
 * @param {Number} alarmCode
 * @returns {String} Human-readable description of the alarm code
 */
export function getAlarmDescription(alarmCode = 0) {
  // Shifting the alarms from the new alarm code format to the new one
  alarmCode = alarmCode >= 100 ? alarmCode - 100 : alarmCode
  if (alarmCode > 25 || alarmCode < 0) {
    return 'Unidentified Alarm'
  } else {
    return alarmCodeToDescription[alarmCode]
  }
}

/**
 * A function just to get a nicer-looking date from a timestamp
 *
 * @param {String} dateString the timestamp string as returned by Kelowna
 */
export function getLocalDateTimeFromISODateString(dateString = '') {
  if (dateString === '') return ''

  const date = new Date(dateString)
  return date.toLocaleString()
}
