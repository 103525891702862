import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useFusionAuth } from '@carboncure/fusion-auth-component'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'
import jwt_decode from 'jwt-decode'
import { atomJWT } from '../atoms'

ProtectedRoute.propTypes = {
  /** The path you'd like to protect ("*" for all paths other than ones defined) */
  path: PropTypes.string.isRequired,
  /**  When true, will only match if the path matches the pathname exactly. */
  exact: PropTypes.bool,
  /**  When true, will match if the path is case sensitive. */
  sensitive: PropTypes.bool,
  /** When true, a path that has a trailing slash will only match a location.pathname with a trailing slash. */
  strict: PropTypes.bool,
}

function ProtectedRoute({ children, ...rest }) {
  const refresh = useFusionAuth()[1]
  const [loggedIn, setLoggedIn] = useState('loading')
  /** State setter to get the user's JSON Web Token (JWT) from localStorage */
  const setJWT = useSetRecoilState(atomJWT)

  /** Get the user's JSON Web Token (JWT) from localStorage */
  useEffect(() => {
    const encodedJWT = window.localStorage.getItem('JWT')
    if (encodedJWT !== null) {
      try {
        setJWT(jwt_decode(encodedJWT))
      } catch (exception) {
        console.error('Error during Decoding')
      }
    }
  }, [setJWT])

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      refresh(refreshToken, () => setLoggedIn('false')).then(() => {
        setLoggedIn('true')
      })
    } else {
      setLoggedIn('false')
    }
  }, [refresh])

  const whatToReturn = {
    true: children,
    false: <Redirect to="/login" />,
    loading: 'Loading...',
  }

  return <Route {...rest}>{whatToReturn[loggedIn]}</Route>
}

export default ProtectedRoute
