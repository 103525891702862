import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ZipCodeSearchPresentational from './ZipCodeSearchPresentational'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import { EMapLayers } from '../../Enums/EchoEnums'

ZipCodeSearchLogical.propTypes = {
  /** Boolean to display or not display error for zip code search filter */
  zipCodeError: PropTypes.bool.isRequired,
  /** State setter to display or not display error for zip code search filter */
  setZipCodeError: PropTypes.func.isRequired,
}

function ZipCodeSearchLogical(props) {
  const { zipCodeError, setZipCodeError } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )
  const [inputValue, setInputValue] = useState('')

  const handleChange = e => {
    if (
      echoSettings.layers.includes(EMapLayers.projectSearch) &&
      e.target.value
    ) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.zipCodes = [e.target.value]
      setProjectSearchValues(newSearchValues)
    } else if (
      !echoSettings.layers.includes(EMapLayers.projectSearch) &&
      e.target.value
    ) {
      setInputValue(e.target.value)
    } else {
      setInputValue('')
      setZipCodeError(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.zipCodes = inputValue.length ? [inputValue] : []
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <ZipCodeSearchPresentational
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      zipCodeError={zipCodeError}
    />
  )
}

export default ZipCodeSearchLogical
