import { makeStyles, Typography, Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import { atomCurrentCustomer } from '../../Common/atoms'
import { useRecoilValue } from 'recoil'

const useStyles = makeStyles(theme => ({
  card: {
    padding: '8px',
    marginTop: '16px',
  },
  cardHeading: {
    display: 'inline',
    marginRight: '8px',
  },
  cardText: {
    display: 'inline',
  },
  mixCodeTag: {
    display: 'inline-block',
    borderRadius: '4px',
    padding: '3px 16px',
    margin: '2px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
  },
}))

AddBatchSummaryCard.propTypes = {
  /** Array containing all materials entered in the table. */
  batchData: PropTypes.array.isRequired,
  /** List of materials to show in summary card. */
  materials: PropTypes.array.isRequired,
}

/**
 * Component displays summary of entered batch data.
 * @param {Array} batchData - array containing all materials entered in the table.
 * @param {Array} materials - list of materials to show in summary card.
 */
function AddBatchSummaryCard(props) {
  const classes = useStyles()
  const { batchData, materials } = props

  // Get current customer.
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)

  // Get unique mix codes.
  const mixCodes = [...new Set(batchData.map(row => row.mixCode))]

  // Get list of batches that have materials added.
  const batchesWithMaterials = batchData.filter(row => {
    const isMatPresent = materials.length > 0
    return isMatPresent
  })

  return (
    <>
      <Card variant="outlined" className={classes.card}>
        <div>
          <Typography variant="h5" className={classes.cardHeading}>
            Producer:
          </Typography>
          <Typography variant="body1" style={{ display: 'inline' }}>
            {selectedCustomer.division.name}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" className={classes.cardHeading}>
            Number of Batches:
          </Typography>
          <Typography variant="body1" className={classes.cardText}>
            {batchData.length}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" className={classes.cardHeading}>
            Batches with Materials:
          </Typography>
          <Typography variant="body1" className={classes.cardText}>
            {batchesWithMaterials
              .map(mat => `${mat.ticketId}-${mat.mixCode}`)
              .join(', ')}
          </Typography>
        </div>
      </Card>
      <Card variant="outlined" className={classes.card}>
        <Typography variant="h5">Mix Codes in Dataset</Typography>
        {mixCodes.map(code => (
          <Typography variant="body2" key={code} className={classes.mixCodeTag}>
            {code}
          </Typography>
        ))}
      </Card>
    </>
  )
}

export default AddBatchSummaryCard
