import React from 'react'
import PropTypes from 'prop-types'
import { EMapLayers } from '../../../Enums/EchoEnums'
import { FlagOutlined } from '@material-ui/icons'
import { cementPlantsColors } from '../../../../theme/colors'
import BaseLegend from '../BaseLegend'

function CementPlantLegend(props) {
  CementPlantLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props

  if (!echoSettings.layers.includes(EMapLayers.cementPlants)) return null

  const items = [
    {
      label: 'Positive',
      color: cementPlantsColors.positive.hex,
      Icon: FlagOutlined,
    },
    {
      label: 'Negative',
      color: cementPlantsColors.negative.hex,
      Icon: FlagOutlined,
    },
    {
      label: 'Not Tested',
      color: cementPlantsColors.notTested.hex,
      Icon: FlagOutlined,
    },
  ]

  return <BaseLegend title="Cement Lab Results:" items={items} />
}

export default CementPlantLegend
