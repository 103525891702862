import { Grid } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import PlantsEditableTableLogical from '../Components/PlantsEditableTable/PlantsEditableTableLogical'
import PlantsTableRow from '../Components/PlantsEditableTable/PlantsTableRow'

/**
 *
 * @param {Object} props
 * @param {PlantsTableRow[]} props.plantData data represented in editable table
 * @param {'asc' | 'desc'} props.order The order of the column, either ascending or dsecending
 * @param {Function} props.setOrder state setter for the order
 * @param {String} props.orderBy The column to order by, should be the id of a headcell
 * @param {Function} props.setOrderBy state setter for order by
 * @param {Number} props.count the number of results returned by the endpoint, used for pagination
 * @param {Number} props.page the current page
 * @param {Function} props.setPage state setter for the page
 * @param {Number} props.rowsPerPage the number of rows to display per page
 * @param {Function} props.setRowsPerPage the state setter for the number of rows per page
 */

function PlantTableViewPresentational(props) {
  const {
    plantData,
    updateTableRow,
    count,
    order,
    orderBy,
    page,
    rowsPerPage,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
  } = props

  return (
    <Grid container direction="column" spacing={4} justify="space-around">
      <Grid item xs>
        <PlantsEditableTableLogical
          count={count}
          order={order}
          updateTableRow={updateTableRow}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rows={plantData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Grid>
    </Grid>
  )
}

PlantTableViewPresentational.propTypes = {
  /** Data for editable table. */
  plantData: PropTypes.arrayOf(PropTypes.shape(PlantsTableRow)).isRequired,
  /** Current page */
  page: PropTypes.number.isRequired,
  /** State setter for the page  */
  setPage: PropTypes.func.isRequired,
  /** the number of rows to display per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** the state setter for the number of rows per page */
  setRowsPerPage: PropTypes.func.isRequired,
  /** the number of results returned by the endpoint, used for pagination */
  count: PropTypes.number.isRequired,
  /** Order of the column either ascending or descending */
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  /** State setter for the order */
  setOrder: PropTypes.func.isRequired,
  /** The column to order by, should be the id of a headcell */
  orderBy: PropTypes.string.isRequired,
  /** state setter for order by */
  setOrderBy: PropTypes.func.isRequired,
}

PlantTableViewPresentational.defaultProps = {
  corporationNames: [],
}
export default PlantTableViewPresentational
