import React from 'react'
import { Grid, TextField } from '@material-ui/core'

interface IComponentProps {
  handleChange: (event: { target: { value: string } }) => void
}

function KeywordSearchPresentational(props: IComponentProps) {
  const { handleChange } = props
  return (
    <Grid item>
      <TextField
        size="small"
        label="Project Key Words"
        onChange={handleChange}
        variant="outlined"
      />
    </Grid>
  )
}

export default KeywordSearchPresentational
