import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import AlarmsPresentational from './AlarmsPresentational'
import { useRecoilValue } from 'recoil'
import {
  atomCodeMapper,
  atomCurrentRemote,
  atomDateRange,
  atomRegion,
} from '../../../Common/engAtoms'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import AlarmForTable from '../Logic/AlarmForTable'
import { useInterval } from '../../../Common/Hooks/hooks'

function AlarmsLogical(props) {
  const {
    selectedAlarm,
    setSelectedAlarm,
    selectedTab,
    setOpen,
    remoteUnitsWithPlants,
    showInactiveAlarms,
  } = props

  /** Data from the alarms endpoint */
  const [alarms, setAlarms] = useState([])
  const regionDisplay = useRecoilValue(atomRegion)
  const [tableCount, setTableCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('timestamp')
  /** Timestamp for when the Alarms table was last refreshed */
  const [refreshedAt, setRefreshedAt] = useState('')
  /** Boolean to determine whether the Alarms table is refreshing */
  const [refreshing, setRefreshing] = useState(false)

  const currentRemote = useRecoilValue(atomCurrentRemote)
  const dateRange = useRecoilValue(atomDateRange)

  const alarmCodeMapper = useRecoilValue(atomCodeMapper)

  const handleTableSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Get alarm data from Kelowna */
  const getAndSetAlarms = useCallback(async () => {
    setRefreshing(true)
    const response = await AlarmsManagementDataHelpers.getAlarmsTable({
      Limit: rowsPerPage,
      Offset: rowsPerPage * page,
      SortColumn: orderBy || '',
      SortOrder: order === 'asc' ? 'Ascending' : 'Descending',
      remoteUnitId: currentRemote?.remoteId || '',
      endTime: dateRange.endTime || '',
      startTime: dateRange.startTime || '',
      displayCanadaUs: regionDisplay.canadaUS,
      displayInternational: regionDisplay.international,
      onlyUnresolved: !showInactiveAlarms,
    })
    if (response.ok) {
      const { count, results } = await response.json()
      const alarmClassObjects = results.map(alarm =>
        AlarmForTable.fromKelownaObject({ ...alarm, alarmCodeMapper })
      )
      setAlarms(alarmClassObjects)
      setTableCount(count)
      setRefreshedAt(new Date().toLocaleTimeString())
      setRefreshing(false)
    }
  }, [
    currentRemote?.remoteId,
    dateRange.endTime,
    dateRange.startTime,
    order,
    orderBy,
    page,
    regionDisplay.canadaUS,
    regionDisplay.international,
    rowsPerPage,
    showInactiveAlarms,
    alarmCodeMapper,
  ])

  /** Reload alarm data from Kelowna every time one of the above useCallback dependencies changes */
  useEffect(() => {
    getAndSetAlarms()
  }, [getAndSetAlarms])

  /** Reload alarm data from Kelowna every 5 minutes */
  useInterval(() => {
    getAndSetAlarms()
  }, 300000)

  /** Open the service case modal */
  const handleOpen = () => setOpen(true)

  return (
    <AlarmsPresentational
      handleTableSort={handleTableSort}
      order={order}
      orderBy={orderBy}
      page={page}
      rowsPerPage={rowsPerPage}
      selectedAlarm={selectedAlarm}
      setSelectedAlarm={setSelectedAlarm}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      selectedTab={selectedTab}
      showInactiveAlarms={showInactiveAlarms}
      handleOpen={handleOpen}
      alarms={alarms}
      tableCount={tableCount}
      refreshedAt={refreshedAt}
      refreshing={refreshing}
      remoteUnitsWithPlants={remoteUnitsWithPlants}
    />
  )
}

AlarmsLogical.propTypes = {
  /** Keep track of which alarm has been selected */
  selectedAlarm: PropTypes.object.isRequired,
  /** State setter function to keep track of which alarm has been selected */
  setSelectedAlarm: PropTypes.func.isRequired,
  /** State setter to open and close the service case modal */
  setOpen: PropTypes.func.isRequired,
  /** The name of the selected tab */
  selectedTab: PropTypes.string.isRequired,
  /** List of remote units with plants */
  remoteUnitsWithPlants: PropTypes.array.isRequired,
  /** Whether to show inactive alarms or not */
  showInactiveAlarms: PropTypes.bool.isRequired,
}
export default AlarmsLogical
