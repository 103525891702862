import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import { InputAdornment } from '@material-ui/core'
import { baseColors } from '../../../theme/colors'

function Search(props) {
  Search.propTypes = {
    setSearched: PropTypes.func,
  }

  const { setSearched } = props

  const handleSearch = e => {
    if (e.target.value.length > 0) {
      setSearched(e.target.value)
    } else {
      setSearched('')
    }
  }

  return (
    <form noValidate autoComplete="off">
      <TextField
        onChange={handleSearch}
        id="searchField"
        label="Search"
        variant="outlined"
        size="small"
        type="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: baseColors.text.disabled }} />
            </InputAdornment>
          ),
        }}
      />
    </form>
  )
}

export default Search
