import React, { Dispatch, SetStateAction } from 'react'
import SimpleTablePresentational from '../../Common/Components/SimpleTable/SimpleTablePresentational'
import { Paper } from '@material-ui/core'
import {
  ISimpleTableSettings,
  ITargetEvent,
  SimpleTableRow,
  SimpleTableSelection,
  SimpleTableTypes,
} from '../../Common/Logic/Types'
import { BaleenOutlierStatus, BaleenSample } from '../Logic/Types'
import cloneDeep from 'lodash.clonedeep'
import { IBaleenFrontendFilterSettings } from '../Views/BaleenView'
import { sortSamples } from '../Helpers/BaleenHelpers'

interface IBaleenSamplesTableLogical {
  frontendFilterSettings: IBaleenFrontendFilterSettings
  setSamples: Dispatch<SetStateAction<BaleenSample[]>>
  tableType:
    | SimpleTableTypes.BaleenOutliersTable
    | SimpleTableTypes.BaleenSamplesTable
  defaultRowsPerPage: number
  rowsPerPageOptions: number[]
  rows: SimpleTableRow[]
  headCells: any
  tableSettings: ISimpleTableSettings
  setTableSettings: Dispatch<ISimpleTableSettings>
  handleSampleCheckboxChange?: (arg1: number) => void
  removePotentialOutlierFromOutliersTable?: (arg1: number) => void
  handleTextFieldSelectChange?: (arg1: any, id: number) => void
  handleTextFieldChange?: (arg1: any, id: number) => void
  handleEditOrUndoClick?: (arg1: number, arg2: BaleenOutlierStatus) => void
  setIsSaveBtnDisabled?: (arg1: boolean) => void
  areTableRowsDisabled: boolean
}

const BaleenSamplesTableLogical = (props: IBaleenSamplesTableLogical) => {
  const {
    frontendFilterSettings,
    setSamples,
    tableType,
    rowsPerPageOptions,
    rows,
    headCells,
    tableSettings,
    setTableSettings,
    handleSampleCheckboxChange,
    removePotentialOutlierFromOutliersTable,
    handleTextFieldSelectChange,
    handleTextFieldChange,
    handleEditOrUndoClick,
    setIsSaveBtnDisabled,
    areTableRowsDisabled = false,
  } = props

  const handleRequestSort = (event: ITargetEvent, property: string) => {
    const { orderBy, order } = tableSettings
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        order: newOrder,
        orderBy: property,
      }
    })
    setSamples((prevSamples: BaleenSample[]) => {
      const prevSamplesCopy = cloneDeep(prevSamples)
      sortSamples(
        prevSamplesCopy,
        property,
        newOrder,
        frontendFilterSettings.interval[0]
      )
      return prevSamplesCopy
    })
  }

  const handleChangeRowsPerPage = (event: ITargetEvent) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const handleChangePage = (event: ITargetEvent, newPage: number) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: newPage,
      }
    })
  }

  return (
    <Paper>
      <SimpleTablePresentational
        headCells={headCells}
        rows={rows.slice(
          tableSettings.page * tableSettings.rowsPerPage,
          tableSettings.page * tableSettings.rowsPerPage +
            tableSettings.rowsPerPage
        )}
        tableType={tableType}
        selectionType={SimpleTableSelection.None}
        onSort={handleRequestSort}
        order={tableSettings.order}
        orderBy={tableSettings.orderBy}
        rowCount={rows.length}
        page={tableSettings.page}
        rowsPerPage={tableSettings.rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onToggle={handleSampleCheckboxChange}
        removePotentialOutlierFromOutliersTable={
          removePotentialOutlierFromOutliersTable
        }
        handleTextFieldSelectChange={handleTextFieldSelectChange}
        handleTextFieldChange={handleTextFieldChange}
        enableRowHover={tableType !== SimpleTableTypes.BaleenOutliersTable}
        enablePagination={
          !(
            tableType === SimpleTableTypes.BaleenOutliersTable &&
            rows.length === 0
          )
        }
        handleEditOrUndoClick={handleEditOrUndoClick}
        setIsSaveBtnDisabled={setIsSaveBtnDisabled}
        areTableRowsDisabled={areTableRowsDisabled}
      />
    </Paper>
  )
}

export default BaleenSamplesTableLogical
