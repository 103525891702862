import {
  Checkbox,
  Radio,
  TableCell,
  TableRow,
  Tooltip,
  Collapse,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  ConvertKgPerM3ToLbPerFt3,
  getFormattedInteger,
  roundUpToSigFigs,
} from '../../../Common/Helpers/GeneralHelpers'
import { getFormattedDate } from '../../Logic/TSSLogic'
import typography from '../../../theme/typography'

const useStyles = makeStyles({
  collapseCol: {
    paddingLeft: '10px',
  },
  checkboxCol: {
    paddingLeft: 0,
    paddingRight: '10px',
  },
  collapsibleHeader: {
    fontSize: typography.fontSize.S,
    fontWeight: typography.fontWeight.bold,
    display: 'inline',
  },
  collapsibleText: {
    fontWeight: typography.fontWeight.medium,
    fontSize: typography.fontSize.S,
    marginBottom: '2em',
    marginLeft: '3rem',
  },
  collapsibleCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
})

AddDataBatchTableRowPresentational.propTypes = {
  /** The batch data displayed in the row */
  batchDetails: PropTypes.object.isRequired,

  /** The onClickHandler for this row */
  onClickHandler: PropTypes.func.isRequired,

  /** Has this row been selected */
  selected: PropTypes.bool.isRequired,

  /** Whether this row should be single selectable radio button or checkbox */
  radioVariant: PropTypes.bool,

  /** Function to render collapse arrow, based on whether notes are visible. */
  renderCollapseArrow: PropTypes.func.isRequired,

  /** Whether this row should be single selectable radio button or checkbox */
  notesOpen: PropTypes.bool.isRequired,
}

AddDataBatchTableRowPresentational.defaultProps = {
  radioVariant: false,
}

export default function AddDataBatchTableRowPresentational(props) {
  const {
    batchDetails,
    onClickHandler,
    selected,
    radioVariant,
    renderCollapseArrow,
    notesOpen,
  } = props
  const classes = useStyles()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="none" className={classes.collapseCol}>
          <div style={{ display: 'inline-block', width: '24px' }}>
            {batchDetails.notes && renderCollapseArrow(notesOpen)}
          </div>
        </TableCell>
        <TableCell align="left" className={classes.checkboxCol}>
          {/* Some radio button here for using selected */}
          {radioVariant ? (
            <Radio
              onClick={() => onClickHandler(batchDetails)}
              color="primary"
              checked={selected}
              size="medium"
            />
          ) : (
            <Checkbox
              onClick={() => onClickHandler(batchDetails)}
              checked={selected}
              size="medium"
              color="primary"
            />
          )}
        </TableCell>
        <TableCell align="left">{batchDetails.producer}</TableCell>
        <TableCell align="left">{batchDetails.mixCode}</TableCell>
        <TableCell align="left">{batchDetails.ticketId}</TableCell>
        <Tooltip title="MM/DD/YYYY">
          <TableCell align="left">
            {getFormattedDate(
              batchDetails.productionDate,
              'MMDDYYYY',
              '/',
              true
            )}
          </TableCell>
        </Tooltip>
        <TableCell align="left">{batchDetails.totalCylinders}</TableCell>
        <Tooltip title="Inch">
          <TableCell align="left">{batchDetails.slump}</TableCell>
        </Tooltip>
        <Tooltip title="Percentage">
          <TableCell align="left">{batchDetails.air}</TableCell>
        </Tooltip>
        <Tooltip title="Fahrenheit">
          <TableCell align="left">{batchDetails.concreteTemperature}</TableCell>
        </Tooltip>
        <Tooltip title="Lb">
          <TableCell align="left">
            {roundUpToSigFigs(batchDetails.cementContent)}
          </TableCell>
        </Tooltip>
        <Tooltip title="PSI">
          <TableCell align="left">
            {getFormattedInteger(batchDetails.strengthAvg7Days)}
          </TableCell>
        </Tooltip>
        <Tooltip title="PSI">
          <TableCell align="left">
            {getFormattedInteger(batchDetails.strengthAvg28Days)}
          </TableCell>
        </Tooltip>
        <Tooltip title="Lb/Ft3">
          <TableCell align="left">
            {ConvertKgPerM3ToLbPerFt3(batchDetails.densityKgPerM3)}
          </TableCell>
        </Tooltip>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={Object.keys(batchDetails).length}
          className={classes.collapsibleCell}
        >
          <Collapse in={notesOpen} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid item xs={3} sm={5} md={7} lg={8} xl={11}>
                <Typography className={classes.collapsibleText}>
                  <Typography className={classes.collapsibleHeader}>
                    Batch Notes:
                  </Typography>
                  {' ' + batchDetails.notes}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
