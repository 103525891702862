import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Collapse,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  getPlantMixDesigns,
  getMixGroupByPlant,
  getMixGroupDetails,
  getMaterialsByDivisionId,
} from '../Data/TSSDataHelpers'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import { weightUnits, allMaterialTypes } from '../Constants/AddDataConstants'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import AddMixDetailsForms from '../Components/AddMixDetailsForms'
import AddMixSummaryCard from '../Components/AddMixSummaryCard'
import EditableTable, { createTableData } from '../Components/EditableTable'
import AddMixConfirmDialog from '../Components/AddMixConfirmDialog'
import AddDataProgressLostAlertLogical from '../Components/AddDataProgressLostAlert/AddDataProgressLostAlertLogical'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { atomCustomerOptions } from '../../Common/tssAtomsB'
import { useRecoilValue, useRecoilState } from 'recoil'
import CustomerAndLocationSelect from '../Components/CustomerAndLocationSelect'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import EditModeAlert from '../Components/EditModeAlert'
import { Helmet } from 'react-helmet'
import {
  createMixDesignObject,
  extractMixDetails,
  getMaterialDefaultUnits,
  parseInterval,
} from '../Logic/AddMixDesignViewLogic'
import cloneDeep from 'lodash.clonedeep'
import {
  extractMixCodeFromVariationName,
  materialsToAddMixMaterialRows,
  setHeadersText,
  digestMaterials,
  tssCanWrite,
} from '../Logic/TSSLogic'
import AddMixCopyDesignLogical from '../Components/AddMixCopyDesign/AddMixCopyDesignLogical'
import EditMixAssociationLogical from '../Components/EditMixAssociation/EditMixAssociationLogical'
import { ReactComponent as EditMixAssociationIconOrange } from '../Assets/MixGroupsFilled-Orange.svg'
import { ReactComponent as EditMixAssociationIconDisabled } from '../Assets/MixGroupsFilled_Disabled.svg'

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { baseColors } from '../../theme/colors'
import {
  MaterialSelectType,
  MixGroupConditions,
  AddDefaultMaterialsType,
} from '../Logic/Types'
import CopyMixVariationLogical from '../Components/CopyMixVariation/CopyMixVariationLogical'
import MaterialSelect from '../Components/MaterialSelect'
import { useAddDefaultMaterials } from '../Logic/Hooks'

/* Custom styling for page. */
const useStyles = makeStyles(theme => ({
  mainContainer: {
    textAlign: 'left',
    paddingLeft: '0.5em',
  },
  materialsContainer: {
    marginTop: '1em',
  },
  collapseContainer: {
    width: '100%',
  },
  copyDesignBtn: {
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px',
    },
  },
  editAssociationBtn: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px',
    },
  },
  editAndCopyIcons: {
    marginRight: '6px',
    height: '20px',
    width: '20px',
  },
  copyButtonActiveHover: {
    marginRight: '24px',
    backgroundColor: baseColors.primary.hover,
    color: '#e37f1c',
  },
  editButtonActiveHover: {
    backgroundColor: baseColors.primary.hover,
    '&:hover': {
      backgroundColor: baseColors.primary.hover,
      color: baseColors.primary.contrast,
    },
  },
}))

/** Declare material table columns*/
const columns = [
  'type',
  'subtype',
  'supplier',
  'plant',
  'alias',
  'quantity',
  'units',
]

/**
 * Visit this link: https://regex101.com/r/uiSwfa/5 to see the explanation of this regular expression
 *
 * Similar to the Air content range regex, but doesn't limit to 2 integers
 *
 * Intended to check either a integer or float on either side of a `-` character
 */
const slumpRangeRegularExpression = /^\d+(\.\d+)?\s*-\s*\d+(\.\d+)?$/

/**
 * This regex matches up to 2 digits before and after an optional decimal, and requires a "-" to separate two values.
 */
const airContentRangeRegularExpression = /^\d\d?(\.\d\d?)? *- *\d\d?(\.\d\d?)?$/

/* Define form schema. */
const addMixSchemaWithAssociation = yup.object().shape({
  mixCode: yup.string().required('Mix Code is required'),
  condition: yup.string().required('Condition is required'),
  interval: yup
    .number()
    .required('Please set the strength interval in days')
    .integer('Interval must be a whole number')
    .positive('Interval must have a positive value')
    .typeError('Please set the strength interval in days'),
  designStrength: yup
    .number()
    .required('Design Strength is required ')
    .positive('Design strength must have a positive value')
    .typeError('Design Strength is required '),
  strippingStrength: yup
    .number()
    .positive('Stripping strength must have a positive value')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  strippingInterval: yup
    .number()
    .integer('Interval must be a whole number')
    .positive('Interval must have a positive value'),
  airContentRange: yup
    .string()
    .nullable()
    .matches(airContentRangeRegularExpression, 'Please enter a range. E.g. 4-6')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  slumpRange: yup
    .string()
    .nullable()
    .matches(slumpRangeRegularExpression, 'Please enter a range. E.g. 22-28')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  wcRatio: yup
    .number()
    .max(1, 'The water / cement ratio cannot exceed 1')
    .min(0, 'The water / cement ratio cannot be less than 0')
    .typeError('Please enter a value between 0 and 1')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  baseLineCO2: yup
    .number()
    .max(100, 'The CO2 Dosage cannot exceed 100%')
    .min(0, 'The CO2 Dosage cannot be less than 0%')
    .typeError('Please enter a value between 0 and 100')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  baseLineCementReduction: yup
    .number()
    .max(100, 'The Cement Reduction cannot exceed 100%')
    .min(0, 'The Cement Reduction cannot be less than 0%')
    .typeError('Please enter a value between 0 and 100')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
})

/* Define form schema. */
const addMixSchemaObj = yup.object().shape({
  customer: yup.string().required('Customer Name is required'),
  location: yup.string().required('Location is required'),
})

const addMixSchema = addMixSchemaObj.concat(addMixSchemaWithAssociation)

/** Used primarily for Edit Association menu. Check for active mix in mix associations and current mix */
export const hasActiveDescendent = (currentMix, associatedMixes) => {
  if (!currentMix || !associatedMixes) return
  if (currentMix.isActiveMixGroup) return true

  const children = associatedMixes.filter(
    c => c.parentMixDesignId === currentMix.mixDesignId
  )
  if (children.length < 1) {
    return currentMix.isActiveMixGroup
  }

  return children.some(am => hasActiveDescendent(am, associatedMixes))
}

/**
 * Page for adding new mix designs.
 */
function AddMixDesignView() {
  const classes = useStyles()

  /** Get recoil values. */
  const currentCustomer = useRecoilValue(atomCurrentCustomer)
  const [addDataSettings, setAddDataSettings] = useState({
    divisionId: currentCustomer.division?.divisionId
      ? [currentCustomer.division.divisionId]
      : [],
    plantId: currentCustomer.plant?.plantId
      ? [currentCustomer.plant?.plantId]
      : [],
    mixCode: [],
    mixDesignId: [],
    showArchived: [],
  })
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const { search } = useLocation()
  /* State variable declarations. */
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [mixDesigns, setMixDesigns] = useState([])
  const [mixDetails, setMixDetails] = useState({
    mixCode: '',
    condition: '',
    description: '',
    interval: '28',
    cementType: null,
    baseLineCementReduction: '',
    baseLineCO2: '',
    co2DosageUnit: '%',
    designStrength: '',
    unitsSystem: '',
    strengthUnit: '',
    strippingStrength: '',
    strippingInterval: '15',
    airContentRange: '',
    slumpRange: '',
    slumpUnit: '',
    exposureClass: null,
    wcRatio: '',
    associatedMix: null,
  })
  const JWT = useRecoilValue(atomJWT)

  const [materials, setMaterials] = useState([])
  const [selectedMaterials, setSelectedMaterials] = useState([])
  const [materialRows, setMaterialRows] = useState([])
  const [tableRefreshCounter, setTableRefreshCounter] = useState(0)
  const [detailsForEdit, setDetailsForEdit] = useState({
    plantId: null,
    divisionId: null,
  })
  const [isCopyMenuOpen, setIsCopyMenuOpen] = useState(false)
  const [
    isEditMixAssociationMenuOpen,
    setIsEditMixAssociationMenuOpen,
  ] = React.useState(false)
  const [
    isCopyMixVariationMenuOpen,
    setIsCopyMixVariationMenuOpen,
  ] = React.useState(false)
  const [plantOptions, setPlantOptions] = useState([])
  const [mixGroupDetails, setMixGroupDetails] = useState([])
  const [parentMixAssociation, setParentMixAssociation] = useState()
  /** The value of isActiveMixCode property of edited */
  const [isActiveMixGroupValue, setIsActiveMixGroupValue] = useState(null)
  const [editedMixGroupDetails, setEditedMixGroupDetails] = useState({})
  /** Boolean of whether the mix is part of association */
  const [inAssociation, setInAssociation] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const handleCopyMenuCollapse = () => {
    setIsCopyMenuOpen(prevValue => !prevValue)
  }

  const handleEditMixMenuCollapse = () => {
    setIsEditMixAssociationMenuOpen(prevValue => !prevValue)
  }

  const setAssociatedMixGroupDetails = group => {
    // Adds a property to the Mix Details containing info for the Selected Associated Mix Group to display in Mix Summary
    setMixDetails(prevDetails => {
      return {
        ...prevDetails,
        associatedMix: group,
      }
    })
  }

  /** Update material rows when selecting from dropdown*/
  useEffect(() => {
    const [defaultUnit, admixUnit] = getMaterialDefaultUnits(
      mixDetails.unitsSystem
    )

    const rows = createTableData(
      columns,
      materialsToAddMixMaterialRows(selectedMaterials)
    )
    const rowsWithUnits = rows.map(row => {
      if (['Admixture', 'CO2'].includes(row.type)) row.units = admixUnit
      else row.units = defaultUnit
      return row
    })

    setMaterialRows(prevItems => {
      //keep user input in existing rows, only update new rows or unitSystem changes
      const updatedItems = rowsWithUnits.map(row => {
        const match = prevItems.find(
          materialRow => materialRow.materialId === row.materialId
        )
        return match ? match : row
      })
      return updatedItems
    })
  }, [selectedMaterials, mixDetails.unitsSystem])

  /** Check if any mixes were passed for editing. */
  const mixDesignId =
    addDataSettings.mixDesignId.length > 0
      ? addDataSettings.mixDesignId[0]
      : null
  const headersText = setHeadersText(!!mixDesignId, JWT.roles, isEditMode)

  // init customer options dropdown
  useEffect(() => {
    if (customerOptions.length === 0) {
      getDivisionNames(true)
        .then(res => {
          if (res.ok)
            res.json().then(data => {
              setCustomerOptions(data)
            })
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  useEffect(() => {
    if (isFirstRender) {
      // FIRST RENDER
      const params = new URLSearchParams(search)
      const newSettings = cloneDeep(addDataSettings)

      for (const setting in addDataSettings) {
        if (params.getAll(setting).length > 0) {
          newSettings[setting] = params.getAll(setting)
        }
      }
      if (newSettings.mixDesignId.length === 0 && !tssCanWrite(JWT.roles)) {
        setRedirect(true)
      }
      setAddDataSettings(newSettings)
      setIsFirstRender(false)
    }
  }, [addDataSettings, setAddDataSettings, isFirstRender, search, JWT.roles])

  /* Null out variation mix selection when customer changes. */
  useEffect(() => {
    if (!currentCustomer.division) return
    // Set mix codes based on country.
    const newSystem = currentCustomer.division.isImperial
      ? 'Imperial'
      : 'Metric'
    setUnits(newSystem)
  }, [currentCustomer.division])

  /* API call to set mix codes and units on plant location change. */
  useEffect(() => {
    if (!currentCustomer.plant) return
    // Set mix codes based on plant location as well.
    getPlantMixDesigns(currentCustomer.plant.plantId)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setMixDesigns(data)
          })
      })
      .catch(e => console.log(e))
  }, [currentCustomer.plant])

  /** Get materials of the selected customer */
  useEffect(() => {
    if (!currentCustomer.division) return

    getMaterialsByDivisionId(Number(currentCustomer.division.divisionId))
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const digestedMaterials = digestMaterials(data)
            setMaterials(digestedMaterials)
          })
      })
      .catch(e => console.log(e))
  }, [currentCustomer.division])

  /** Api call to get Mix Groups on plant location change */
  useEffect(() => {
    if (!currentCustomer.plant) return
    getMixGroupByPlant(currentCustomer.plant.plantId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            setMixGroupDetails(data)
          })
        }
      })
      .catch(e => console.error(e))
  }, [currentCustomer.plant])

  /** Get the isActiveMixGroup property, and the Parent Associated Mix of the edited mix.  **/
  useEffect(() => {
    if (!mixDesignId) return
    getMixGroupDetails(mixDesignId)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const { parentMixGroup, isActiveMixGroup } = data
            // Get Parent Associated Mix (Prepopulates Edit Mix Association Selection)
            setParentMixAssociation(parentMixGroup)
            setAssociatedMixGroupDetails(parentMixGroup)
            // Get isActiveMixGroup of mix that's being edited
            setIsActiveMixGroupValue(isActiveMixGroup)
            data.mixDesignId = parseInt(mixDesignId)
            setEditedMixGroupDetails(data)
          })
      })
      .catch(e => console.log(e))
  }, [mixDesignId])

  /** Method to update UI from API values. Shared by both copy mix design & copy associated mix variation */
  const updateMixDetailsAndMaterials = useCallback(
    (data, selectedVariation) => {
      // Extract properties and materials, then set them in the UI.
      try {
        let properties, mixMaterials
        if (materials.length) {
          ;({ properties, mixMaterials } = extractMixDetails(
            data,
            data.isImperial,
            materials
          ))
        }
        const mixDesignCondition = data.isCO2Design
          ? MixGroupConditions.CO2
          : MixGroupConditions.NOCO2
        // Parse the interval of format "X.00:00:00" to get the number of days
        const intervalDays = parseInterval(data.compressiveStrengthInterval)
        setMixDetails(prevValues => ({
          ...prevValues,
          mixCode: mixDesignId ? data.mixCode : null, //mixCode field is blank when autofilling a new mix
          condition: mixDesignId ? mixDesignCondition : null, //condition field is blank when autofilling a new mix
          unitsSystem: data.isImperial ? 'Imperial' : 'Metric',
          baseLineCO2: selectedVariation
            ? selectedVariation.cO2Dosage
            : data.baseLineCO2,
          baseLineCementReduction: selectedVariation
            ? selectedVariation.cementReductionPercent
            : data.baseLineCementReduction,
          interval: intervalDays, // Use the parsed interval value
          ...properties,
        }))
        if (mixMaterials?.length) {
          setMaterialRows(
            createTableData(
              columns,
              materialsToAddMixMaterialRows(mixMaterials),
              true
            )
          )
          setSelectedMaterials(mixMaterials)
        }
        setTableRefreshCounter(prevValue => prevValue + 1)
      } catch (error) {
        console.error('Error in updateMixDetailsAndMaterials:', error)
      }
    },
    [mixDesignId, materials]
  )

  /** Get batches if IDs were passed from another page. */
  useEffect(() => {
    if (!mixDesignId) return
    getMixGroupDetails(mixDesignId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            updateMixDetailsAndMaterials(data)
            // Save mix code being edited so its not flagged as a duplicate.
            setDetailsForEdit({
              plantId: data.plantId,
              divisionId: data.divisionId,
              mixCode: data.mixCode,
            })
          })
        }
      })
      .catch(e => console.error(e))
  }, [mixDesignId, updateMixDetailsAndMaterials])

  /** Get data for mixVariationForOptimization selected at analysis page. */
  useEffect(() => {
    //Retrieve mixVariation from sessionStorage since recoil can't carry over to new tab
    const jsonMixVariationForOptimization = sessionStorage.getItem(
      'mixVariationForOptimization'
    )
    const mixVariationForOptimization = JSON.parse(
      jsonMixVariationForOptimization
    )

    //Clear sessionStorage to avoid conflict with other data
    sessionStorage.removeItem('mixVariationForOptimization')

    if (!mixVariationForOptimization?.mixDesignId) return

    getMixGroupDetails(mixVariationForOptimization.mixDesignId)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Error retrieving mix group details.')
        }
      })
      .then(data => {
        const associatedMixDesign = {
          baseLineCO2: mixVariationForOptimization.cO2Dosage,
          baseLineCementReduction:
            mixVariationForOptimization.cementReductionPercent,
          mixCode: extractMixCodeFromVariationName(
            mixVariationForOptimization.mixCode
          ),
          mixDesignId: mixVariationForOptimization.mixDesignId,
        }
        setParentMixAssociation(associatedMixDesign)
        setAssociatedMixGroupDetails(associatedMixDesign)
        updateMixDetailsAndMaterials(data, mixVariationForOptimization)
        setAddDataSettings(prevDetails => {
          return {
            ...prevDetails,
            divisionId: [data.divisionId],
            plantId: [data.plantId],
          }
        })
      })
      .catch(e => console.error(e))
  }, [updateMixDetailsAndMaterials, setAddDataSettings])

  /* Function to set all units. */
  const setUnits = newSystem => {
    const [strengthUnit, slumpUnit] =
      newSystem === 'Metric' ? ['MPa', 'mm'] : ['PSI', 'In']
    const co2DosageUnit = '%'

    setMixDetails(prevItems => ({
      ...prevItems,
      unitsSystem: newSystem,
      strengthUnit,
      slumpUnit,
      co2DosageUnit,
    }))
  }

  /** Custom hook to check if there is default cement, if not, add to materials */
  useAddDefaultMaterials(
    materials,
    currentCustomer,
    setMaterials,
    AddDefaultMaterialsType.AddMix
  )

  /* Instantiate form validation. */
  const editModeSchema = cloneDeep(addMixSchema)
  editModeSchema.fields.customer = editModeSchema.fields.customer.notRequired()

  const getValidationSchema = (isEditMode, inAssociation) => {
    if (isEditMode && !inAssociation) {
      return editModeSchema
    } else if (inAssociation) {
      return addMixSchemaWithAssociation
    } else {
      return addMixSchema
    }
  }
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(getValidationSchema(isEditMode, inAssociation)),
  })

  /* Create dialog with summary card once data is submitted. */
  const [mixDesignPostObject, setMixDesignPostObject] = useState(false)
  const [hasUserInput, setHasUserInput] = useState(false)

  const onSubmit = () =>
    setMixDesignPostObject(
      createMixDesignObject(mixDetails, materialRows, currentCustomer)
    )

  /* Specify which columns will use the drop downs and any special input types. */
  const dropCols = {
    units: Object.values(weightUnits),
    type: Object.values(allMaterialTypes),
  }
  const titleType = isEditMode ? 'Edit' : 'View'

  /* Change Header Texts when switching mode */
  useEffect(() => {
    setHeadersText(!!mixDesignId, JWT.roles, isEditMode)
  }, [mixDesignId, isEditMode, JWT.roles])

  // Boolean to disable input fields when viewing a mix
  const isViewOnly = !isEditMode && !!mixDesignId

  /** Clear material dropdown & selected materials when clearing customer or customer is changed. */
  useEffect(() => {
    if (!currentCustomer.division?.divisionId) {
      setMaterialRows([])
    }
    //only reset selected materials when adding new batch
    if (!isEditMode && !isViewOnly) setSelectedMaterials([])
  }, [currentCustomer.division?.divisionId, isEditMode, isViewOnly])

  if (redirect) return <Redirect to="/Concrete/AddData" />
  return (
    <Container
      className={classes.mainContainer}
      maxWidth="xl"
      style={{ padding: '0em 0.5em' }}
    >
      {mixDesignId && (
        <EditModeAlert
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          customer={currentCustomer}
          mixCode={detailsForEdit?.mixCode}
          roles={JWT.roles}
        />
      )}
      <Helmet>
        {!!mixDesignId ? (
          <title>{titleType} Mix Design</title>
        ) : (
          <title>Add Mix Design</title>
        )}
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h2">{headersText.h1}</Typography>
          </Grid>
          <Grid
            container
            xs={7}
            sm={7}
            lg={7}
            md={7}
            xl={7}
            spacing={2}
            style={{ paddingLeft: '16px', paddingRight: '20px' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomerAndLocationSelect
                register={register}
                errors={errors}
                showPlants
                inputsAlwaysEnabled={false}
                inAssociation={inAssociation}
                infoIconVisible={currentCustomer.division}
                setPlantsInParent={setPlantOptions}
                sizes={{ xs: 12, sm: 6, md: 6, lg: 6, spacing: 2 }}
                addDataSettings={addDataSettings}
                setAddDataSettings={setAddDataSettings}
                isViewOnly={isViewOnly}
                isEditMode={isEditMode}
                isLocationRequired={true}
              />
            </Grid>
          </Grid>
          <CopyMixVariationLogical
            isCopyMixVariationMenuOpen={isCopyMixVariationMenuOpen}
            setIsCopyMixVariationMenuOpen={setIsCopyMixVariationMenuOpen}
            updateMixDetailsAndMaterials={updateMixDetailsAndMaterials}
            associatedMixDesign={mixDetails.associatedMix}
            addMixMode={!mixDesignId}
          />
          <Grid item>
            <Typography variant="h3">{headersText.h2}</Typography>
          </Grid>

          {/* Container to hold forms on left and summary card on right. */}
          <Grid item container spacing={5} justify="space-between">
            <Grid
              item
              xs={12}
              sm={7}
              container
              alignItems="center"
              spacing={2}
              style={{ marginBottom: '0px', paddingBottom: '0px' }}
            >
              <Collapse
                in={currentCustomer.division}
                className={classes.collapseContainer}
                unmountOnExit
              >
                <Grid container lg={12} md={12} style={{ padding: '8px' }}>
                  <Button
                    color="primary"
                    size="large"
                    component="span"
                    variant="outlined"
                    onClick={handleCopyMenuCollapse}
                    disabled={isViewOnly}
                    data-testid="copy-mix-design"
                    className={isCopyMenuOpen && classes.copyButtonActiveHover}
                    style={{ marginRight: '24px' }}
                  >
                    <FileCopyOutlinedIcon
                      className={classes.editAndCopyIcons}
                    />
                    Copy Mix Design
                  </Button>
                  <Collapse in={!!currentCustomer.plant} unmountOnExit>
                    <Button
                      color="primary"
                      size="large"
                      component="span"
                      variant="outlined"
                      onClick={handleEditMixMenuCollapse}
                      disabled={isViewOnly}
                      data-testid="edit-mix-association"
                      className={
                        isEditMixAssociationMenuOpen &&
                        classes.editButtonActiveHover
                      }
                    >
                      {isViewOnly ? (
                        <EditMixAssociationIconDisabled
                          className={classes.editAndCopyIcons}
                        />
                      ) : (
                        <EditMixAssociationIconOrange
                          className={classes.editAndCopyIcons}
                        />
                      )}
                      Edit Mix Association
                    </Button>
                  </Collapse>
                </Grid>
                <Grid
                  item
                  md={12}
                  lg={12}
                  style={{ padding: isCopyMenuOpen ? '8px' : '0px' }}
                >
                  <AddMixCopyDesignLogical
                    roles={JWT.roles}
                    updateMixDetailsAndMaterials={updateMixDetailsAndMaterials}
                    isCopyMenuOpen={isCopyMenuOpen}
                    setIsCopyMenuOpen={setIsCopyMenuOpen}
                    isCustomerSelected={!!currentCustomer.divisionId}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  lg={12}
                  style={{
                    padding: isEditMixAssociationMenuOpen ? '8px' : '0px',
                  }}
                >
                  <EditMixAssociationLogical
                    isEditMixAssociationMenuOpen={isEditMixAssociationMenuOpen}
                    setIsEditMixAssociationMenuOpen={
                      setIsEditMixAssociationMenuOpen
                    }
                    setIsCopyMixVariationMenuOpen={
                      setIsCopyMixVariationMenuOpen
                    }
                    plantOptionsLength={plantOptions.length}
                    isPlantSelected={!!currentCustomer.plant}
                    isCustomerSelected={!!currentCustomer.division}
                    mixGroupDetails={mixGroupDetails}
                    parentMixAssociation={parentMixAssociation}
                    setParentMixAssociation={setParentMixAssociation}
                    setAssociatedMixGroupDetails={setAssociatedMixGroupDetails}
                    editMixId={mixDesignId}
                    editedMixGroupDetails={editedMixGroupDetails}
                    setInAssociation={setInAssociation}
                  />
                </Grid>
              </Collapse>
              <AddMixDetailsForms
                mixDetails={mixDetails}
                setMixDetails={setMixDetails}
                setUnits={setUnits}
                register={register}
                errors={errors}
                setValue={setValue}
                mixDesigns={mixDesigns}
                editMixCode={detailsForEdit?.mixCode}
                hasUserInput={hasUserInput}
                setHasUserInput={setHasUserInput}
                roles={JWT.roles}
                isActiveMix={editedMixGroupDetails?.isActiveMixGroup}
                isViewOnly={isViewOnly}
              />
              <Grid item xs={12} className={classes.materialsContainer}>
                <Typography variant="h3">{headersText.h3}</Typography>
                <Typography>
                  Please make sure that the quantities are entered per cubic
                  yard or meter.
                </Typography>
                <MaterialSelect
                  type={MaterialSelectType.AddMix}
                  materials={materials}
                  selected={selectedMaterials}
                  currentCustomer={currentCustomer}
                  handleChange={setSelectedMaterials}
                  isViewOnly={isViewOnly}
                />
              </Grid>
            </Grid>
            {/* Card is displayed after forms and table on small screens. */}
            <Grid item xs={12} sm={5} style={{ marginBottom: '1em' }}>
              <AddMixSummaryCard
                mixDetails={mixDetails}
                materials={materialRows}
                roles={JWT.roles}
                search={search}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EditableTable
              data={materialRows}
              setData={setMaterialRows}
              columns={columns}
              dropCols={dropCols}
              key={tableRefreshCounter}
              setHasUserInput={setHasUserInput}
              roles={JWT.roles}
              isViewOnly={isViewOnly}
            />
          </Grid>
        </Grid>
      </form>
      <AddMixConfirmDialog
        postObject={mixDesignPostObject}
        mixDetails={mixDetails}
        mixGroupDetails={mixGroupDetails}
        parentMixAssociation={parentMixAssociation}
        materials={materialRows}
        editMixId={mixDesignId}
        open={!!mixDesignPostObject}
        setOpen={setMixDesignPostObject}
        setHasUserInput={setHasUserInput}
        isActiveMixGroupValue={isActiveMixGroupValue}
      />
      <AddDataProgressLostAlertLogical hasUserInput={hasUserInput} />
    </Container>
  )
}

export default AddMixDesignView
