import { TablePagination, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { baseColors } from '../../../theme/colors'
import typography from '../../../theme/typography'

interface ISimpleTablePagination {
  rowsPerPage: number
  count: number
  page: number
  rowsPerPageOptions?: number[]
  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  hidePagination?: boolean
}

const useStyles = makeStyles(theme => ({
  paginationActions: {
    color: baseColors.action.active,
  },
  select: {
    paddingTop: '10px',
    fontWeight: 400,
    fontSize: '12px',
  },
}))

const SimpleTablePagination = (props: ISimpleTablePagination) => {
  const {
    rowsPerPage,
    rowsPerPageOptions,
    onChangePage,
    onChangeRowsPerPage,
    count,
    page,
    hidePagination,
  } = props
  const classes = useStyles()

  const labelRowsPerPage = (
    <Typography
      variant="caption"
      style={{
        color: baseColors.text.secondary,
        fontWeight: typography.fontWeight.medium,
      }}
    >
      Rows per page:
    </Typography>
  )
  const totalPages = Math.ceil(count / rowsPerPage)
  const pageInformation = () => {
    return (
      <Typography variant="caption" style={{ fontWeight: 500 }}>
        {`Page ${page + 1} of ${totalPages === 0 ? 1 : totalPages}`}
      </Typography>
    )
  }

  return hidePagination ? null : (
    <TablePagination
      classes={{ actions: classes.paginationActions, select: classes.select }}
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={labelRowsPerPage}
      component="div"
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelDisplayedRows={pageInformation}
    />
  )
}

export default SimpleTablePagination
