import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { atomCurrentCustomer } from '../../../Common/atoms'
import PropTypes from 'prop-types'
import AddMixCopyDesignPresentational from './AddMixCopyDesignPresentational'
import {
  getDivisionMixCodesAndIds,
  getMixGroupDetails,
} from '../../Data/TSSDataHelpers'

/**
 * Component is responsible for "copying a mix design", i.e. all of the input values
 * on AddMixDesignView are autopopulated with selected mix's values (mix must belong to same division).
 *
 * @param {Function} updateMixDetailsAndMaterials - updates all mix details on AddMixDesignView using the API response as an argument.
 */
export default function AddMixCopyDesignLogical(props) {
  AddMixCopyDesignLogical.propTypes = {
    /** Function updates all mix details on AddMixDesignView using the API response as an argument. */
    updateMixDetailsAndMaterials: PropTypes.func.isRequired,
    /** Bool indiciating whether the component should diplay the copy interface. */
    isCopyMenuOpen: PropTypes.bool.isRequired,
    /** Function to determine whether or not the Copy Mix Design interface is visible */
    setIsCopyMenuOpen: PropTypes.func.isRequired,
    /** An array of strings representing the roles of the logged in user */
    roles: PropTypes.array.isRequired,
  }

  const {
    updateMixDetailsAndMaterials,
    isCopyMenuOpen,
    setIsCopyMenuOpen,
  } = props
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)
  const [divisionMixCodes, setDivisionMixCodes] = useState([])
  const [selectedMixCode, setSelectedMixCode] = useState(null)
  const [isConditionCO2, setIsConditionCO2] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Fetch list of mix codes for a division, updates as division changes.
  useEffect(() => {
    // Prevent unnecessary API requests if menu is not open.
    if (!selectedCustomer.division || !isCopyMenuOpen) return

    getDivisionMixCodesAndIds(selectedCustomer.division.divisionId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            setDivisionMixCodes(data)
          })
        }
      })
      .catch(e => console.error(e))
  }, [selectedCustomer, isCopyMenuOpen])

  useEffect(() => {
    if (!selectedCustomer.division) {
      setIsCopyMenuOpen(false)
    }
  }, [selectedCustomer, setIsCopyMenuOpen])

  // Change condition for filtering mix design options.
  const handleSetCondition = e => {
    setSelectedMixCode(null)
    setIsConditionCO2(e.target.value)
  }

  // Fetch selected mix design details/materials by ID.
  const fetchAndCopyMixDesign = event => {
    event.preventDefault()
    setIsLoading(true)
    getMixGroupDetails(selectedMixCode.mixDesignId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            setTimeout(() => {
              updateMixDetailsAndMaterials(data)
              setIsCopyMenuOpen(false)
              setSelectedMixCode(null)
              setIsLoading(false)
            }, 500)
          })
        }
      })
      .catch(e => console.error(e))
  }

  return (
    <AddMixCopyDesignPresentational
      divisionMixCodes={divisionMixCodes}
      selectedMixCode={selectedMixCode}
      isConditionCO2={isConditionCO2}
      isLoading={isLoading}
      handleSetCondition={handleSetCondition}
      setSelectedMixCode={setSelectedMixCode}
      handleConfirm={fetchAndCopyMixDesign}
      isCopyMenuOpen={isCopyMenuOpen}
      roles={props.roles}
    />
  )
}
