import {
  EchoTooltipBackground,
  getLabResultColors,
} from '../../Logic/MapLogicHelpers'
import {
  translatedCementTypes,
  ownershipColors,
  radiuses,
  co2MarkerSizes,
  projectColors,
  opportunitiesColors,
} from '../../Constants/Constants'
import cloneDeep from 'lodash.clonedeep'
import { FlyToInterpolator } from 'react-map-gl'
import circle from '@turf/circle'
import typography from '../../../theme/typography'

/** location param is a plant or a company */
export function getLocationRadius(location, selectedIds) {
  return selectedIds.includes(location.corporationId)
    ? radiuses.selected
    : radiuses.notSelected
}

/** location param is a CO2 depot */
export function getCO2LocationSize(location, selectedRows) {
  const selectedStates = selectedRows.map(row => row.state)
  const selectedVendors = selectedRows.map(row => row.companyName)
  return selectedStates.includes(location.state) &&
    selectedVendors.includes(location.companyName)
    ? co2MarkerSizes.selected
    : co2MarkerSizes.noSelected
}

export function getUnfilteredCompanyFillColor(company, type) {
  switch (type) {
    case 'rmCompaniesNoCustomers':
    case 'precastCompaniesNoCustomers':
      if (!company.isVerticallyIntegrated) {
        return ownershipColors.privatelyOwned.rgb
      }
      if (company.isVerticallyIntegrated) {
        return ownershipColors.verticallyIntegrated.rgb
      }
      break
    case 'rmCompaniesCustomers':
    case 'precastCompaniesCustomers':
      if (company.someInstalled) {
        return ownershipColors.customers.rgb
      }
      break
    default:
  }
}

export function getNoCustomersPlantFill(plant, greenfield) {
  if (!greenfield) {
    return plant.isVerticallyIntegrated
      ? ownershipColors.verticallyIntegrated.rgb
      : ownershipColors.privatelyOwned.rgb
  }
  if (plant.someInstalled) {
    return ownershipColors.customers.rgb
  }
  if (!plant.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (plant.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

export function getCustomersPlantFill(plant, greenfield) {
  if (!greenfield) {
    return ownershipColors.customers.rgb
  }
  if (plant.someInstalled) {
    return ownershipColors.customers.rgb
  }
  if (!plant.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (plant.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

export function getUnfilteredPlantFillColor(plant, type, greenfield) {
  switch (type) {
    case 'rmPlantsNoCustomers':
    case 'precastPlantsNoCustomers':
      return getNoCustomersPlantFill(plant, greenfield)
    case 'rmPlantsCustomers':
    case 'precastPlantsCustomers':
      return getCustomersPlantFill(plant, greenfield)
    default:
  }
}

export function getPlantFill(plant, filterBySelected, type, greenfield) {
  return filterBySelected
    ? plant.uniqueColor.rgb
    : getUnfilteredPlantFillColor(plant, type, greenfield)
}

export function getCompanyFill(company, filterBySelected, type) {
  return filterBySelected
    ? company.uniqueColor.rgb
    : getUnfilteredCompanyFillColor(company, type)
}

/** Function that returns a string of cement info to display in a map marker tooltip
 * @param {Object} cement - A cement
 * @param {Boolean} concretePlant - Whether or not the cement info is going to be displayed on a concrete plant map marker
 * @returns {String} Returns a string of cement info to display in a map marker tooltip
 */
export const getCementInfo = (cement, concretePlant) => {
  const translateLabResult = labResult => {
    if (labResult === 'Positive') return 'Positive'
    if (labResult === 'Negative') return 'Negative'
    return 'No Result'
  }
  const labResult = translateLabResult(cement.labResult)

  // show kelowna's name if there's no translated cement type
  const cementTypeOrName =
    translatedCementTypes[cement.cementType] ||
    cement.cementType ||
    cement.cementName ||
    cement.name
  return `${cementTypeOrName} - ${labResult}\n`
}

/** Function that returns a string indicating whether a concrete plant is Precast or Ready Mix (RM) */
export const getConcreteType = plant => {
  if (plant.isPrecast) return 'Precast'
  else return 'RM'
}

export function getMapLayerTooltip(object, echoSettings, producerData) {
  const type = object?.layerType ?? '-'
  const tooltipConfig = getTooltipConfig(
    type,
    object,
    echoSettings,
    producerData
  )

  if (!tooltipConfig) return null

  const { header, content, borderColor } = tooltipConfig

  const tooltipStyle = {
    backgroundColor: '#ffffff',
    color: '#000000',
    fontFamily: 'Inter, sans-serif',
    fontSize: typography.fontSize.S,
    padding: '16px',
    border: `2px solid`,
    borderColor: `${borderColor}`,
    borderRadius: '8px',
    boxShadow:
      '0px 1px 8px 0px #0000001F, 0px 3px 4px 0px #00000024, 0px 3px 3px -2px #00000033',
  }

  return {
    html: getTooltipContent(header, content),
    style: tooltipStyle,
  }
}

export function getTooltipConfig(type, object, echoSettings, producerData) {
  const configs = {
    carbonCureProjects: () => ({
      header: 'CarbonCure Project',
      content: `
        <div>Location: ${object.city}</div>
        <div>Customer: ${object.customer}</div>
        <div>Concrete Delivered: ${object.concreteDelivered.toLocaleString()} yd³</div>
        <div>CO₂ Savings: ${object.co2Savings} tons</div>
        <div>Start date: ${object.startDate}</div>
        <div>Status: ${object.projectStatus}</div>
      `,
      borderColor:
        object.projectStatus === 'Completed'
          ? projectColors.finished.hex
          : projectColors.ongoing.hex,
    }),
    upcomingProject: () => ({
      header: object.organization,
      content: `
        <div>Location: ${object.formattedLocation}</div>
        <div>Start date: ${object.formattedStartDate}</div>
        <div>Description: ${object.description}</div>
        <div>Size (m²): ${object.formattedSize}</div>
        <div>Value ($): ${object.formattedValue}</div>
      `,
      borderColor: object.isCcSpecified
        ? projectColors.specified.hex
        : projectColors.unspecified.hex,
    }),
    company: () => ({
      header: `${getConcreteType(object)} Company`,
      content: `
        <div>${object.divisionName}</div>
        <div>State: ${object.state}</div>
        <div>Plants: ${object.numPlants}</div>
      `,
      borderColor: EchoTooltipBackground(object.layerType, object),
    }),
    plant: () => {
      const locationInfo = `
        <div>${object.divisionName}</div>
        <div>Location: ${object.city}${
        object.state ? ', ' + object.state : ''
      }</div>
      `
      const cementInfo = object.cements.length
        ? `
          <div style="font-weight: 700; margin-top: 10px">Cements</div>
          <div>${object.cements
            .map(cement => `<div>${getCementInfo(cement, false)}</div>`)
            .join('')}</div>
        `
        : ''
      return {
        header: `${getConcreteType(object)} Plant`,
        content: locationInfo + cementInfo,
        borderColor: EchoTooltipBackground(
          object.layerType,
          object,
          echoSettings.isGreenfield
        ),
      }
    },
    cementPlant: () => ({
      header: 'Cement Supplier',
      content: `
        <div>${object.groupName}</div>
        <div>${object.companyName}</div>
        <div>${object.plantName}</div>
        <div style="font-weight: 700; margin-top: 10px">Cements</div>
        <div>${
          object.cements.length
            ? object.cements
                .map(cement => `<div>${getCementInfo(cement, false)}</div>`)
                .join('')
            : '-'
        }</div>
        ${
          producerData.isClicked
            ? `<div style="font-weight: 700;margin-top: 10px">Producers in range: ${producerData.readyMixValue}</div>`
            : `<div style="font-size: 12px;font-weight: 400;margin-top: 10px; font-style: italic; opacity: 0.6">${producerData.defaultString}</div>`
        }
      `,
      borderColor: getLabResultColors(object).hex,
    }),
    co2Depot: () => ({
      header: 'CO₂ Depot',
      content: `
        <div>${object.companyName}</div>
        <div>Location: ${object.city}${
        object.state ? ', ' + object.state : ''
      }</div>
      `,
      borderColor: '#0C1921',
    }),
    opportunity: () => ({
      header: 'Sales Opportunity',
      content: `
        <div>Company: ${object.companyName ?? '-'}</div>
        <div>Stage: ${object.pipelineStage ?? '-'}</div>
        <div>Plants: ${object.plantCount ?? '-'}</div>
        <div>Sales Person: ${object.carbonCureSalesPerson ?? '-'}</div>
        <div>Location: ${object.city ?? '-'}${
        object.state ? ', ' + object.state : ''
      }</div>
      `,
      borderColor: opportunitiesColors.opportunities.hex,
    }),
  }

  return configs[type] ? configs[type]() : null
}

export function getTooltipContent(header, content) {
  return `
    <div style="font-weight: 700">${header}</div>
    ${content}
  `
}

/** All opportunity markers are red, but the opacity (alpha channel) is adjusted based on the sales pipeline stage. */
export function getOpportunityColourAndOpacity(opportunity) {
  switch (opportunity.pipelineStage) {
    case 'Presentation':
      return [200, 88, 66, 25]
    case 'Qualification':
      return [200, 88, 66, 51]
    case 'Proposal':
      return [200, 88, 66, 76]
    case 'Negotiation':
      return [200, 88, 66, 89]
    case 'Agreement':
      return [200, 88, 66, 102]
    case 'Goal':
      return [200, 88, 66, 114]
    case 'Decision Maker':
      return [200, 88, 66, 127]
    case 'In Discussion':
      return [200, 88, 66, 140]
    case 'Evaluation':
      return [200, 88, 66, 153]
    case 'Business Terms':
      return [200, 88, 66, 178]
    case 'Verbal':
      return [200, 88, 66, 216]
    default:
      return opportunitiesColors.opportunities.rgb
  }
}

/** Helper for generating circle on cement plant click */
export function getCircleCoordinates(longitude, latitude, radiusInKilometers) {
  const center = [longitude, latitude]
  const options = { steps: 360, units: 'kilometers' }
  const circleFeature = circle(center, radiusInKilometers, options)
  const coordinates = circleFeature.geometry.coordinates[0] // Extract the coordinates from the circle feature
  return coordinates
}

export const getMapMarkerZoom = rmProducerRange => {
  if (!rmProducerRange) return 6
  const radiusFilter = parseInt(rmProducerRange[0])
  if (radiusFilter < 40) return 9
  if (radiusFilter < 80) return 7
  return 6
}

export const getMarker = (
  markerLocation,
  setMarkerLocation,
  echoSettings,
  setEchoSettings,
  setViewport
) => coordinate => {
  if (markerLocation) {
    setMarkerLocation(null)
    setEchoSettings(prevSettings => ({
      ...prevSettings,
      markerLocation: [],
    }))
  } else {
    setMarkerLocation(coordinate)
    const newEchoSettings = cloneDeep(echoSettings)
    newEchoSettings.markerLocation = coordinate
    setEchoSettings(newEchoSettings)
    setViewport({
      latitude: coordinate[1],
      longitude: coordinate[0],
      zoom: getMapMarkerZoom(newEchoSettings.rmProducerRange),
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }
}

/** Returns an object with data   */
export function getProducerData(markerLocation, readyMixData) {
  let producerData = {
    isClicked: false,
    readyMixValue: null,
    defaultString: '',
  }
  if (markerLocation?.length) {
    return {
      ...producerData,
      isClicked: true,
      readyMixValue: readyMixData?.length,
    }
  } else {
    return {
      ...producerData,
      isClicked: false,
      defaultString: 'Click to see producers in range',
    }
  }
}
