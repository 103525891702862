import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ICommissionReportSettings } from '../../Logic/Types'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { baseColors } from '../../../theme/colors'
import { UndoOutlined } from '@material-ui/icons'
import { generateConclusion } from '../../Helpers/CommissionReportHelpers'
import cloneDeep from 'lodash.clonedeep'
import { ICommissionReportCustomer } from '../../Views/CommissionReportGeneratorView'
import { atomJWT } from '../../../Common/atoms'
import { useRecoilValue } from 'recoil'

export interface ICommissionReportConclusion {
  reportSettings: ICommissionReportSettings | undefined
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  isLoadingMixData: boolean
  selectedCustomer: ICommissionReportCustomer
  isValidReportId: boolean
}

const CommissionReportConclusion = (props: ICommissionReportConclusion) => {
  const {
    reportSettings,
    setReportSettings,
    isLoadingMixData,
    selectedCustomer,
    isValidReportId,
  } = props

  const JWT = useRecoilValue(atomJWT)
  const corporationName = selectedCustomer?.corporationName
  const tssSpecialist =
    (reportSettings?.createdBy as string) || `${JWT.firstName} ${JWT.lastName}`

  const DEFAULT_CONCLUSION = generateConclusion(corporationName, tssSpecialist)
  const currentConclusion = reportSettings?.conclusionParagraph

  const MAX_CHARACTER_LIMIT = 1000

  const [showResetButton, setShowResetButton] = useState(
    currentConclusion !== DEFAULT_CONCLUSION
  )
  const [conclusionSummary, setConclusionSummary] = useState<string>(
    reportSettings?.conclusionParagraph ?? DEFAULT_CONCLUSION
  )

  const characterCount = conclusionSummary.length
  const remainingCharacters = MAX_CHARACTER_LIMIT - characterCount
  const helperText = `Maximum ${MAX_CHARACTER_LIMIT} characters (${remainingCharacters} remaining)`

  const isFirstRender = useRef(true)
  const debounce = useRef<NodeJS.Timeout | null>(null)

  /** Set initial conclusion text on first render (but not until mix data is loaded, prevents too many re-renders) */
  useEffect(() => {
    if (isLoadingMixData) return
    if (isFirstRender.current) {
      if (!currentConclusion && !isValidReportId) {
        setConclusionSummary(DEFAULT_CONCLUSION)
        setReportSettings(prevSettings => {
          const newSettings = cloneDeep(prevSettings)
          newSettings.conclusionParagraph = DEFAULT_CONCLUSION
          return {
            ...newSettings,
          }
        })
      } else {
        setConclusionSummary(currentConclusion as string)
      }
      isFirstRender.current = false
    }
    setShowResetButton(conclusionSummary !== DEFAULT_CONCLUSION)
  }, [
    DEFAULT_CONCLUSION,
    conclusionSummary,
    currentConclusion,
    isLoadingMixData,
    isValidReportId,
    setReportSettings,
  ])

  /** Reset to Default button */
  const handleReset = () => {
    setConclusionSummary(DEFAULT_CONCLUSION)
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      newSettings.conclusionParagraph = DEFAULT_CONCLUSION
      return {
        ...newSettings,
      }
    })
  }

  /** Updates testing summary and report settings with debouncing */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value
    setConclusionSummary(newText)
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings)
        newSettings.conclusionParagraph = newText
        return {
          ...newSettings,
        }
      })
    }, 500)
  }

  return (
    <Grid container direction="row">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: 4 }}>
          <Typography variant="h3">Conclusion & Next Steps</Typography>
        </Grid>
        <Grid item>
          {showResetButton && (
            <Button
              id="reset-button"
              data-testid="reset-button"
              onClick={handleReset}
              startIcon={<UndoOutlined />}
              variant="outlined"
              color="primary"
            >
              Reset To Default
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="conclusion-text-input"
          data-testid="conclusion-text-input"
          multiline
          fullWidth
          rows={8}
          value={conclusionSummary}
          variant="outlined"
          inputProps={{
            maxLength: MAX_CHARACTER_LIMIT,
          }}
          onChange={handleChange}
          helperText={helperText}
          FormHelperTextProps={{ style: { color: baseColors.text.secondary } }}
        />
      </Grid>
    </Grid>
  )
}

export default CommissionReportConclusion
