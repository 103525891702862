//Material Table Head Cells
const dropdownCol = {
  id: 'dropdownCol',
  width: '20px',
  xxlWidth: '5%',
}

const divisionName = {
  id: 'divisionName',
  numeric: false,
  name: 'Company Name',
  sortable: true,
  width: '120px',
  xxlWidth: '10%',
}

const primaryMaterialType = {
  id: 'primaryMaterialType',
  numeric: false,
  name: 'Material Type',
  sortable: true,
  width: '120px',
  xxlWidth: '10%',
}

const materialType = {
  id: 'materialType',
  numeric: false,
  name: 'Material Subtype',
  sortable: true,
  width: '120px',
  xxlWidth: '10%',
}

const supplierCompany = {
  id: 'supplierCompany',
  numeric: false,
  name: 'Supplier Company',
  sortable: true,
  width: '120px',
  xxlWidth: '10%',
}

const supplierPlant = {
  id: 'supplierPlant',
  numeric: false,
  name: 'Supplier Plant',
  sortable: true,
  width: '120px',
  xxlWidth: '10%',
}

const aliases = {
  id: 'aliases',
  name: 'Alias',
  sortable: true,
  width: '250px',
  xxlWidth: '20%',
}

const mergeMaterial = {
  id: 'mergeMaterial',
  name: '',
  width: '50px',
  xxlWidth: '5%',
}

export const headCellsMaterialTable = [
  { ...dropdownCol },
  { ...divisionName },
  { ...primaryMaterialType },
  { ...materialType },
  { ...supplierCompany },
  { ...supplierPlant },
  { ...aliases },
  { ...mergeMaterial },
]

export const defaultMaterialDetails = {
  divisionId: 0,
  materialMappingId: null,
  divisionName: null,
  primaryMaterialType: null,
  materialType: null,
  specificGravity: null,
  supplierCompany: null,
  supplierPlant: null,
  admixtureSolidsContentPercent: null,
  aggregateManufacturing: null,
  aggregatePitId: null,
  aggregateRockType: null,
  aggregateSize: null,
  fiberManufacturing: null,
  fiberSize: null,
  fiberDiameterMm: null,
  fiberLengthMm: null,
  flyAshLossOfIgnitionPercent: null,
  silicaFumeGrade: null,
  slagGrade: null,
  slagProcessing: null,
  waterTemperature: null,
  modeOfTransportation: null,
  cementSupplier: {},
  aliases: [''],
  overrideSimilarCheck: false,
}

export const spreadsheetHeaders = [
  { label: 'Ingested', key: 'isIngested' },
  { label: 'Company Name', key: 'divisionName' },
  { label: 'Material Type', key: 'primaryMaterialType' },
  { label: 'Material Subtype', key: 'materialType' },
  { label: 'Specific Gravity', key: 'specificGravity' },
  { label: 'Supplier Company', key: 'supplierCompany' },
  { label: 'Supplier Plant', key: 'supplierPlant' },
  { label: 'Alias', key: 'aliases' },
]

export const mockCustomerOptions = [
  {
    corporationId: 11,
    isImperial: true,
    divisionId: 1,
    name: 'Concrete Company 1',
  },
  {
    corporationId: 10,
    isImperial: true,
    divisionId: 2,
    name: 'Concrete Company 2',
  },
]
