import React, { ChangeEvent, useEffect, useState } from 'react'
import PlantCompositionCardPresentational from './PlantCompositionCardPresentational'
import { getPlantCompositionsByDivisionId } from '../../Data/TSSDataHelpers'
import { digestPlantComposition } from '../../Logic/TSSLogic'
import { GridSize } from '@material-ui/core'
import {
  CompositionChangeType,
  CurrentPlantCompositions,
  PlantComposition,
} from '../../Logic/Types'
import {
  convertPlantCompositionData,
  getPlantNames,
  initializeCementComponent,
  nullifyCurrentCementID,
  updatePlants,
} from '../../Helpers/MaterialManagerHelpers'

export interface IPlantCompositionCardLogicalProps {
  roles?: Array<string>
  isEditMode: boolean
  divisionId?: number | string
  currentMaterialId: number | string
  selectedPlants: string[]
  currentPlantName: string
  currentMaterialSubtype: string
  currentAliases: string[]
  setCementCompositionData: (arg0: CurrentPlantCompositions[]) => void
  setSelectedPlants: (arg0: string[]) => void
}

const PlantCompositionCardLogical = (
  props: IPlantCompositionCardLogicalProps
) => {
  const {
    roles,
    divisionId,
    currentMaterialId,
    isEditMode,
    selectedPlants,
    setSelectedPlants,
    currentPlantName,
    currentMaterialSubtype,
    currentAliases,
    setCementCompositionData,
  } = props

  /** Contains all of the Division's Plant Composition Data */
  const [plantData, setPlantData] = useState<PlantComposition[]>([])
  /** Determines which Cement Composition cards are shown and contains the respective data */
  const [plants, setPlants] = useState<PlantComposition[]>([])

  /** Uses the "roles" variable to help determine the current view. Roles are only defined in Add/Edit Material View */
  const isAddEditView: boolean = typeof roles !== 'undefined'

  /** For reducing Cog. Complexity. Uses a returned boolean to determine grid size. */
  const getGridSize = (
    cond: boolean | undefined,
    firstValue: number,
    secondValue: number
  ) => {
    if (cond) {
      return firstValue as GridSize
    } else {
      return secondValue as GridSize
    }
  }

  /** Handles changes to the Cement Composition Card Data */
  const handleCementCompositionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    plantId: number | string,
    materialId: number | string | null,
    changeType?: CompositionChangeType | undefined
  ) => {
    const newValue = event.target.value
    switch (changeType) {
      case CompositionChangeType.NewCement:
        setPlants(prevPlants => {
          return prevPlants.map(plant => {
            // Change the composition data based on the matching plantId
            if (
              plant.plantId === plantId &&
              plant.cementCompositions.length > 0
            ) {
              // Get the composition with the latest effective date
              const mostRecentComposition = plant.cementCompositions[0]
              if (mostRecentComposition.cementComponents.length === 0) {
                // Create new components if none currently exist
                mostRecentComposition.cementComponents = [
                  {
                    ...mostRecentComposition.cementComponents,
                    materialId: currentMaterialId,
                    cementName: null,
                    cementType: currentMaterialSubtype,
                    cementPlantName: currentPlantName,
                    cementPercentage: parseInt(newValue, 10),
                  },
                ]
              }
              // Change the cementPercentage based on the matching materialId
              const updatedCompositions = mostRecentComposition.cementComponents.map(
                component => {
                  if (component.materialId === currentMaterialId) {
                    return {
                      ...component,
                      cementPercentage: parseInt(newValue, 10),
                    }
                  }
                  return component
                }
              )

              // Create updated cementCompositions
              return {
                ...plant,
                cementCompositions: [
                  {
                    ...mostRecentComposition,
                    cementComponents: updatedCompositions,
                  },
                  // Replace the previous cementComposition
                  ...plant.cementCompositions.slice(1),
                ],
              }
            }
            return plant
          })
        })
        break
      case CompositionChangeType.Date:
        setPlants(prevPlants => {
          return prevPlants.map(plant => {
            // Change the composition data based on the matching plantId
            if (
              plant.plantId === plantId &&
              plant.cementCompositions.length > 0
            ) {
              // Get the composition with the latest effective date
              const mostRecentComposition = plant.cementCompositions[0]
              mostRecentComposition.effectiveDate = newValue
            }
            return plant
          })
        })
        break
      default:
        // Do nothing if number isn't in range of 0-100
        if (Number(newValue) < 0 || Number(newValue) > 100) return
        // Changes existing Component Data
        setPlants(prevPlants => {
          return prevPlants.map(plant => {
            // Change the composition data based on the matching plantId
            if (
              plant.plantId === plantId &&
              plant.cementCompositions.length > 0
            ) {
              // Get the composition with the latest effective date
              const mostRecentComposition = plant.cementCompositions[0]

              // Change the cementPercentage based on the matching materialId
              const updatedCompositions = mostRecentComposition.cementComponents.map(
                component => {
                  if (component.materialId === materialId) {
                    return {
                      ...component,
                      cementPercentage: Number(newValue),
                    }
                  }
                  return component
                }
              )

              // Create updated cementCompositions
              return {
                ...plant,
                cementCompositions: [
                  {
                    ...mostRecentComposition,
                    cementComponents: updatedCompositions,
                  },
                  // Replace the previous cementComposition
                  ...plant.cementCompositions.slice(1),
                ],
              }
            }
            return plant
          })
        })
        break
    }
  }

  /** Get materials of the selected customer */
  useEffect(() => {
    if (!divisionId) return

    const options = {
      divisionId: divisionId,
    }
    getPlantCompositionsByDivisionId(options)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setPlantData(data)
          })
      })
      .catch(e => console.log(e))
  }, [divisionId])

  useEffect(() => {
    const plantsWithCurrentCement = digestPlantComposition(
      plantData,
      currentMaterialId
    )
    // Set selected plants if there are current cement compositions
    if (setSelectedPlants && plantsWithCurrentCement?.length > 0) {
      setSelectedPlants(getPlantNames(plantsWithCurrentCement))
    }
  }, [currentMaterialId, plantData, setSelectedPlants])

  // Handle which Cement Composition Cards are shown. This changes based on Material Manager View type and selected plants
  useEffect(() => {
    const plantsWithCurrentCement = digestPlantComposition(
      plantData,
      currentMaterialId
    )
    if (!isAddEditView) {
      // For Material Table View. Renders card(s) based on the plant's current cement compositions
      setPlants(plantsWithCurrentCement)
    } else {
      if (!selectedPlants) return
      // Gets data for selected plants
      const filteredPlants = plantData?.filter(plant =>
        selectedPlants.includes(plant.plantName)
      )
      // Adds necessary data to plants for rendering cement composition card
      const initializedPlants = initializeCementComponent(
        filteredPlants,
        currentMaterialId as number,
        currentPlantName,
        currentMaterialSubtype,
        isEditMode
      )
      setPlants(prevState => {
        return updatePlants(prevState, initializedPlants, isEditMode)
      })
    }
  }, [
    currentMaterialId,
    currentMaterialSubtype,
    currentPlantName,
    isAddEditView,
    plantData,
    selectedPlants,
    isEditMode,
  ])

  useEffect(() => {
    if (!selectedPlants) return
    const convertedInitializedPlants = convertPlantCompositionData(plants)
    // NOTE: Kelowna Rule - The current cement's materialId must have a null value in order to be submitted.
    // It will be rejected otherwise.
    const plantsWithNulledCurrentMaterial = nullifyCurrentCementID(
      convertedInitializedPlants,
      currentMaterialId as number
    )
    setCementCompositionData(plantsWithNulledCurrentMaterial)
  }, [currentMaterialId, plants, selectedPlants, setCementCompositionData])

  return (
    <PlantCompositionCardPresentational
      roles={roles}
      getGridSize={getGridSize}
      plants={plants}
      currentMaterialId={currentMaterialId}
      currentAliases={currentAliases}
      currentPlantName={currentPlantName}
      currentMaterialSubtype={currentMaterialSubtype}
      isEditMode={isEditMode}
      isAddEditView={isAddEditView}
      handleCementCompositionChange={handleCementCompositionChange}
    />
  )
}

export default PlantCompositionCardLogical
