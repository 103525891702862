import { ScatterplotLayer } from '@deck.gl/layers'
import { getCompanyLineColor } from '../../../Logic/MapLogicHelpers'
import { getLocationRadius, getCompanyFill } from '../MapComponentHelpers'

export function getItems(companyMarkers, echoSettings, someInstalled) {
  let result = companyMarkers.filter(
    company => !company.isPrecast && company.someInstalled === someInstalled
  )
  // states
  if (echoSettings.states.length) {
    result = result.filter(company =>
      echoSettings.states.includes(company.state)
    )
  }
  // companies
  if (echoSettings.companies.length) {
    result = result.filter(
      company => company.divisionName === echoSettings.companies[0]
    )
  }
  return result.map(company => ({ ...company, layerType: 'company' }))
}

/**
 * @param {Object []} data - Objects returned from getItems
 * @param {Number[]} selectedIds
 * @param {Boolean} filterBySelected
 * @param {'rmCompaniesNoCustomers' | 'rmCompaniesCustomers'} type
 * @returns
 */
export function getLayer(data, selectedIds, filterBySelected, type) {
  return new ScatterplotLayer({
    radiusUnits: 'pixels',
    id: type,
    data: data,
    pickable: true,
    opacity: 0.6,
    stroked: filterBySelected,
    filled: true,
    getRadius: company => getLocationRadius(company, selectedIds),
    getPosition: company => [company.stateLong, company.stateLat],
    getFillColor: company => getCompanyFill(company, filterBySelected, type),
    ...(filterBySelected && { getLineColor: getCompanyLineColor() }),
    ...(filterBySelected && { getLineWidth: 4 }),
    lineWidthUnits: 'pixels',
  })
}

export function getReadyMixCompaniesLayer(
  echoSettings,
  companyMarkers,
  selectedIds,
  filterBySelected
) {
  const showNoCustomers =
    echoSettings.ownership.includes('privatelyOwned') ||
    echoSettings.ownership.includes('verticallyIntegrated')
  const showCustomers = echoSettings.ownership.includes('customer')

  return [
    ...(showNoCustomers
      ? [
          getLayer(
            getItems(companyMarkers, echoSettings, false),
            selectedIds,
            filterBySelected,
            'rmCompaniesNoCustomers'
          ),
        ]
      : []),
    ...(showCustomers
      ? [
          getLayer(
            getItems(companyMarkers, echoSettings, true),
            selectedIds,
            filterBySelected,
            'rmCompaniesCustomers'
          ),
        ]
      : []),
  ]
}
