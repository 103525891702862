import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  atomAddressOptions,
  atomEchoSettings,
  atomFilterGroup,
  atomAddressSearch,
  atomAddressCoordinates,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import { EMapLayers } from '../../Enums/EchoEnums'

const useStyles = makeStyles({
  formControl: {
    marginTop: 0,
    paddingTop: 0,
  },
})

function FilterBy() {
  /** Echo settings Atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Choose to filter by country, state, city, group, company, zip code, or address */
  const [filterGroup, setFilterGroup] = useRecoilState(atomFilterGroup)
  /** Update options for the address search dropdown */
  const setAddressOptions = useSetRecoilState(atomAddressOptions)
  /** Update address search query */
  const setAddressSearch = useSetRecoilState(atomAddressSearch)
  /** Update coordinates of address that has been searched in address filter */
  const setAddressCoordinates = useSetRecoilState(atomAddressCoordinates)
  /** Project search values atom */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  const classes = useStyles()

  /** Make input available to filter by country, state, city, group, company, zip code, or address according to whichever radio button is selected */
  const handleChange = event => {
    const newValue = event.target.value
    setFilterGroup(newValue)
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newProjectSearchValues = cloneDeep(projectSearchValues)
      newProjectSearchValues.countries = []
      newProjectSearchValues.states = []
      newProjectSearchValues.cities = []
      newProjectSearchValues.groups = []
      newProjectSearchValues.companies = []
      newProjectSearchValues.zipCodes = []
      setProjectSearchValues(newProjectSearchValues)
      const newSettings = cloneDeep(echoSettings)
      newSettings.countries = []
      newSettings.states = []
      newSettings.cities = []
      newSettings.groups = []
      newSettings.companies = []
      newSettings.zipCodes = []
      newSettings.addresses = []
      setEchoSettings(newSettings)
    } else {
      const newSettings = cloneDeep(echoSettings)
      newSettings.countries = []
      newSettings.states = []
      newSettings.cities = []
      newSettings.groups = []
      newSettings.companies = []
      newSettings.zipCodes = []
      newSettings.addresses = []
      setEchoSettings(newSettings)
      setAddressOptions([])
      setAddressSearch('')
      setAddressCoordinates([])
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup row value={filterGroup} onChange={handleChange}>
        <FormControlLabel
          value="country"
          control={<Radio color="primary" id="countryRadio" />}
          label="Country"
        />
        <FormControlLabel
          value="state"
          control={<Radio color="primary" id="stateRadio" />}
          label="State"
        />
        <FormControlLabel
          value="city"
          control={<Radio color="primary" id="cityRadio" />}
          label="City"
        />
        <FormControlLabel
          value="group"
          control={<Radio color="primary" id="groupRadio" />}
          label="Group"
        />
        <FormControlLabel
          value="company"
          control={<Radio color="primary" id="companyRadio" />}
          label="Company"
        />
        <FormControlLabel
          value="zipCode"
          control={<Radio color="primary" id="zipCodeRadio" />}
          label="Zip code"
        />
        {echoSettings.layers.includes(EMapLayers.co2Depots) && (
          <FormControlLabel
            value="address"
            control={<Radio color="primary" id="addressRadio" />}
            label="Address"
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default FilterBy
