import { baseColors } from '../../theme/colors'

export enum CommissioningFilterOptions {
  InCommissioning = 'In Commissioning',
  CommissioningComplete = 'Commissioning Complete',
}

export const activeTag = {
  backgroundColor: baseColors.primary.main,
  color: 'white',
  outline: `2px solid ${baseColors.primary.twentySixMainOpacity}`,
  fontWeight: 700,
}

export const archivedTag = {
  backgroundColor: baseColors.noCO2Tag.main,
  color: 'white',
  outline: `2px solid ${baseColors.noCO2Tag.twentySixMainOpacity}`,
  fontWeight: 700,
}

export const co2Tag = {
  ...activeTag,
}

export const noCO2Tag = {
  ...archivedTag,
}

export const draftTag = {
  backgroundColor: '#495358',
  color: 'white',
  outline: `2px solid ${baseColors.noCO2Tag.twentySixMainOpacity}`,
  fontWeight: 700,
}

export const pendingReviewTag = {
  backgroundColor: 'rgba(32, 59, 91, 0.8)',
  color: 'white',
  outline: `2px solid rgba(32, 59, 91, 0.26)`,
  fontWeight: 700,
}

export const reviewedTag = {
  backgroundColor: 'rgba(59, 141, 114, 1)',
  color: 'white',
  outline: `2px solid rgba(59, 141, 114, 0.26)`,
  fontWeight: 700,
}
