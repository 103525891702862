import React from 'react'
import MaterialFieldsLogical from './MaterialFieldsLogical'
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import {
  CustomerDivision,
  CustomerOptions,
  IMaterial,
  IMetaData,
  MaterialManagerComponent,
} from '../../Logic/Types'

interface IAddMaterialPresentational {
  /** The metadata returned from Kelowna */
  metaData: IMetaData | undefined
  /** Determines when loading spinner is shown */
  isLoading: boolean
  /** The customers that can be selected */
  customerOptions: CustomerOptions[]
  /** Determines if fields can be edited or just viewed */
  isEditMode: boolean
  /** Changes the current view type for material Manaer */
  setCurrentView: (view: MaterialManagerComponent) => void
  /** The selected Material and the relevant details needed for editing/viewing */
  selectedMaterial: IMaterial | null | undefined
  /** Manages Unsaved Changes Modal */
  setHasUserInput: (arg0: boolean) => void
  /** Determines if fields can be edited or just viewed */
  isDefaultMaterial: boolean | undefined
  /** Determines if the selected material is unclassified */
  isUnclassifiedMaterial?: boolean
  /** Set the material to be viewed in Add/Edit view */
  setSelectedMaterial: (arg0: IMaterial) => void
  /** Values from the material manager filters */
  customerFilterSelection?: CustomerDivision | null
}

function AddMaterialPresentational(props: IAddMaterialPresentational) {
  const {
    metaData,
    isLoading,
    customerOptions,
    setCurrentView,
    selectedMaterial,
    isEditMode,
    setHasUserInput,
    isDefaultMaterial,
    setSelectedMaterial,
    customerFilterSelection,
    isUnclassifiedMaterial,
  } = props

  return (
    <>
      {/* Typescript doesn't consider zIndex prop to be valid when used with a string. Forcing error ignore */}
      {/* @ts-ignore */}
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h3" align="left">
                {isEditMode ? 'Edit Material' : 'Add Material'}
              </Typography>
            </Grid>
            <Grid item>
              <MaterialFieldsLogical
                customerOptions={customerOptions}
                metaData={metaData}
                setCurrentView={setCurrentView}
                selectedMaterial={selectedMaterial}
                isEditMode={isEditMode}
                setHasUserInput={setHasUserInput}
                isDefaultMaterial={isDefaultMaterial}
                isUnclassifiedMaterial={isUnclassifiedMaterial}
                setSelectedMaterial={setSelectedMaterial}
                customerFilterSelection={customerFilterSelection}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default AddMaterialPresentational
