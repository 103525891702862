import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import DownloadDataLogical from '../../Components/DownloadData/DownloadDataLogical'
import RenderTableLogical from '../../Components/RenderTable/RenderTableLogical'

const useStyles = makeStyles({
  tableContainer: {
    position: 'relative',
  },
  downloadContainer: {
    position: 'relative',
    textAlign: 'right',
  },
})

function TableViewPresentational(props) {
  TableViewPresentational.propTypes = {
    /** Data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Data table page number */
    page: PropTypes.number,
    /** State setter to update data table page */
    setPage: PropTypes.func,
    /** Ascending or descending sort order of data table column */
    order: PropTypes.string,
    /** State setter to update ascending or descending data table sort order */
    setOrder: PropTypes.func,
    /** Name of data table column to sort by */
    orderBy: PropTypes.string,
    /** State setter to update data table column to sort by */
    setOrderBy: PropTypes.func,
    /** Number of data table rows to display */
    rowsPerPage: PropTypes.number,
    /** State setter to update number of data table rows to display */
    setRowsPerPage: PropTypes.func,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
    /** Array of data to display on the precast table. */
    precastTableData: PropTypes.array.isRequired,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Filtered and formatted array of CO2 vendors */
    formattedCO2VendorData: PropTypes.array.isRequired,
    /** Cement table's settings */
    cementTableSettings: PropTypes.object.isRequired,
    /** Setter for cement table's settings */
    setCementTableSettings: PropTypes.func.isRequired,
    /** Download data for cement table */
    cementTableDataForDownload: PropTypes.array.isRequired,
    /** Array of coordinates for zip codes */
    zipCodeCoordinates: PropTypes.array,
  }
  const {
    selectedRows,
    setSelectedRows,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    readyMixTableData,
    precastTableData,
    isLoading,
    formattedCO2VendorData,
    cementTableSettings,
    setCementTableSettings,
    cementTableDataForDownload,
    zipCodeCoordinates,
  } = props

  const classes = useStyles()

  return (
    <>
      <Grid item className={classes.tableContainer}>
        <RenderTableLogical
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          filteredUpcomingProjects={filteredUpcomingProjects}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          readyMixTableData={readyMixTableData}
          precastTableData={precastTableData}
          formattedCO2VendorData={formattedCO2VendorData}
          cementTableSettings={cementTableSettings}
          setCementTableSettings={setCementTableSettings}
          zipCodeCoordinates={zipCodeCoordinates}
        />
      </Grid>
      <Grid item className={classes.downloadContainer}>
        <DownloadDataLogical
          filteredUpcomingProjects={filteredUpcomingProjects}
          readyMixTableData={readyMixTableData}
          precastTableData={precastTableData}
          isLoading={isLoading}
          formattedCO2VendorData={formattedCO2VendorData}
          cementTableDataForDownload={cementTableDataForDownload}
        />
      </Grid>
    </>
  )
}

export default TableViewPresentational
