import { DateRange, SonarDateRange } from '../../../TSS/Logic/Types'
import {
  addDays,
  formatISO,
  startOfToday,
  startOfYesterday,
  subDays,
} from 'date-fns'

export interface HandleDateRangeResult {
  startDate: string | null
  endDate: string | null
  selectedRange: string | null
}

const formatDate = (date: Date | null): string | null => {
  return date ? formatISO(date).substring(0, 10) : null
}

export const handleDateRange = (
  value: SonarDateRange | null,
  currentDateStart: DateRange,
  currentDateEnd: DateRange,
  currentDate: Date = new Date()
): HandleDateRangeResult => {
  let startDate: Date | null = null
  let endDate: Date | null = null
  let selectedRange: string | null = value

  switch (value) {
    case SonarDateRange.Today:
      startDate = startOfToday()
      endDate = currentDate
      break
    case SonarDateRange.Yesterday:
      startDate = startOfYesterday()
      endDate = currentDate
      break
    case SonarDateRange.Last7Days:
      startDate = subDays(currentDate, 7)
      endDate = currentDate
      break
    case SonarDateRange.Last30Days:
      startDate = subDays(currentDate, 30)
      endDate = currentDate
      break
    case SonarDateRange.Last90Days:
      startDate = subDays(currentDate, 90)
      endDate = currentDate
      break
    case SonarDateRange.Last180Days:
      startDate = subDays(currentDate, 180)
      endDate = currentDate
      break
    case SonarDateRange.OneDay:
      startDate = currentDate
      endDate = addDays(currentDate, 1)
      break
    case SonarDateRange.SevenDays:
      startDate = currentDate
      endDate = addDays(currentDate, 7)
      break
    case SonarDateRange.ThirtyDays:
      startDate = currentDate
      endDate = addDays(currentDate, 30)
      break
    case SonarDateRange.NinetyDays:
      startDate = currentDate
      endDate = addDays(currentDate, 90)
      break
    case SonarDateRange.CustomRange:
      startDate = new Date(currentDate)
      endDate = new Date(currentDate)
      break
    default:
      selectedRange = null
  }

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    selectedRange,
  }
}

const sonarDateRangeSelectHelper = {
  handleDateRange,
}

export default sonarDateRangeSelectHelper
