import { Button, Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  getPlantsNoMasterUnits,
  getUnassignedMasterUnits,
} from '../Data/PCBDataHelpers'
import AddMasterUnitModal from '../Components/AddMasterUnitModal'
import AssignMasterUnitModal from '../Components/AssignMasterUnitModal'
import MasterUnitInventoryTable from '../Components/MasterUnitInventoryTable'
import MasterUnitView from './MasterUnitView'

async function fetchAssignMasterUnitModalProps() {
  const corporationsResponse = await getPlantsNoMasterUnits()

  let corporations = []

  if (corporationsResponse.ok) {
    corporations = await corporationsResponse.json()
  }

  const plants = {}
  corporations.forEach(corporation => {
    plants[corporation.corporationId] = corporation.plants
  })

  return [corporations, plants]
}

function InventoryView() {
  const [selectedMasterUnit, setSelectedMasterUnit] = useState(() => null)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [masterUnits, setMasterUnits] = useState([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [corporations, setCorporations] = useState([])
  const [plantsMappedToCorporations, setPlantsMappedToCorporations] = useState(
    {}
  )
  const [refresh, setRefresh] = useState(false)

  const toggleRefresh = () => {
    setRefresh(toggle => !toggle)
  }

  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true)
  }

  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false)
  }

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true)
  }

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false)
  }

  useEffect(() => {
    fetchAssignMasterUnitModalProps().then(result => {
      const [corporations, plants] = result

      setPlantsMappedToCorporations(plants)
      setCorporations(corporations)
    })
    const fetchUnassignedMasterUnits = async () => {
      const response = await getUnassignedMasterUnits()
      if (response && response.ok) {
        const masterUnitData = await response.json()
        setMasterUnits(masterUnitData)
      }
    }
    fetchUnassignedMasterUnits()
  }, [refresh])

  return (
    <Grid direction="column" spacing={3} container>
      <Grid item>
        {selectedMasterUnit ? (
          <MasterUnitView masterData={selectedMasterUnit} />
        ) : (
          <></>
        )}
      </Grid>
      <Grid container item direction="row" justify="space-between">
        <Grid item>
          <Button
            variant="text"
            color="primary"
            onClick={handleOpenAssignModal}
          >
            Assign Master Unit
          </Button>
          <AssignMasterUnitModal
            handleClose={handleCloseAssignModal}
            isOpen={isAssignModalOpen}
            corporations={corporations}
            plants={plantsMappedToCorporations}
            masterUnits={masterUnits}
            refresh={toggleRefresh}
          />
        </Grid>
        <Grid item>
          <Button variant="text" color="primary" onClick={handleOpenAddModal}>
            + Add Master Unit
          </Button>
          <AddMasterUnitModal
            isOpen={isAddModalOpen}
            handleClose={handleCloseAddModal}
            refresh={toggleRefresh}
          />
        </Grid>
      </Grid>
      <Grid item>
        <MasterUnitInventoryTable
          setSelectedMasterUnit={setSelectedMasterUnit}
          data={masterUnits}
        />
      </Grid>
    </Grid>
  )
}

export default InventoryView
