import { InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { metricSwitch } from '../../../Common/atoms'
import { PlantsEditableCellAdornment } from '../PlantsEditableTable/PlantsEditableTable.types'

interface EditableCellTextProps {
  /** Text value used in editable cell. */
  value: string | number
  /** Change handler for text input. */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Adornment to be shown for numeric inputs. */
  adornment?: PlantsEditableCellAdornment
  /** Whether field is currently focused. Used to highlight associated fields. */
  isFocused: boolean
  /** Whether field value is valid */
  isValid: boolean
}

function EditableCellText(props: EditableCellTextProps) {
  const { value, onChange, adornment, isFocused } = props

  // Set adornments
  const isMetric = useRecoilValue(metricSwitch)
  const loadSizeUnit = isMetric ? 'm³' : 'yd³'
  const densityUnit = isMetric ? 'kg/m³' : 'lb/yd³'
  const adornmentMapping = {
    percentage: '%',
    dollars: '$',
    loadSize: loadSizeUnit,
    density: densityUnit,
  }

  const inputProps = adornment
    ? {
        endAdornment: (
          <InputAdornment position="end">
            {adornmentMapping[adornment]}
          </InputAdornment>
        ),
      }
    : undefined

  return (
    <TextField
      value={value}
      focused={isFocused}
      size="small"
      variant="outlined"
      onChange={onChange}
      InputProps={inputProps}
      error={!props.isValid}
      helperText=""
    />
  )
}

export default EditableCellText
