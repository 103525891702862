import React, { useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ArrowDownward,
  Close,
  InfoOutlined,
  CreateOutlined,
  Visibility,
  HighlightOff,
} from '@material-ui/icons'
import { baseColors } from '../../../theme/colors'
import { tssCanWrite } from '../../../TSS/Logic/TSSLogic'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

export interface IExpandablePromptProps {
  /** Function to determine whether or not the prompt is visible */
  setIsMenuOpen?: (arg1: boolean) => void
  /** String to be displayed at the prompt header */
  headerText: string
  /** String to be displayed at the prompt body */
  contentText: string | React.ReactNode
  /** String to be displayed at the expand button */
  expandButtonText: string
  /** Component to be displayed after the prompt is expanded */
  expandedContent?: React.ReactNode
  /** Type of prompt */
  promptType: string
  /** TSS roles */
  roles?: Array<string>
  /** Button onClick Handler */
  onClickHandler?: () => void
  /** Style classes defined in the parent */
  parentClasses: ClassNameMap<string>
  /** JSX content that appears beneath the contentText. Optional */
  bodyJSX?: React.ReactNode
}

const ExpandablePrompt = (props: IExpandablePromptProps) => {
  const {
    setIsMenuOpen,
    headerText,
    contentText,
    expandButtonText,
    promptType,
    expandedContent,
    roles,
    onClickHandler,
    parentClasses,
    bodyJSX,
  } = props

  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => {
    setExpanded(prevState => !prevState)
  }

  interface HeaderIcons {
    [key: string]: { name: React.ElementType; color: string }
  }

  interface ExpandIcons {
    [key: string]: {
      name: React.ElementType
      color: string
      position: 'start' | 'end'
    }
  }

  const headerIcons: HeaderIcons = {
    info: { color: baseColors.text.primary, name: InfoOutlined },
    addDataViewMode: { color: baseColors.text.primary, name: InfoOutlined },
    addDataEditMode: { color: baseColors.text.primary, name: InfoOutlined },
    commissionReportViewMode: {
      color: baseColors.info.headerText,
      name: InfoOutlined,
    },
    commissionReportEditMode: {
      color: baseColors.info.headerText,
      name: InfoOutlined,
    },
    commissionReportInsufficientSamples: {
      color: baseColors.text.secondary,
      name: HighlightOff,
    },
  }

  const expandIcons: ExpandIcons = {
    info: { color: baseColors.info.main, name: ArrowDownward, position: 'end' },
    addDataViewMode: {
      color: baseColors.info.main,
      name: CreateOutlined,
      position: 'start',
    },
    addDataEditMode: {
      color: baseColors.info.main,
      name: Visibility,
      position: 'start',
    },
    commissionReportViewMode: {
      color: baseColors.info.dark,
      name: Visibility,
      position: 'start',
    },
    commissionReportEditMode: {
      color: baseColors.info.dark,
      name: CreateOutlined,
      position: 'start',
    },
  }

  const getHeaderIcon = () => {
    if (!headerIcons[promptType]) return
    const Icon = headerIcons[promptType].name
    return <Icon fontSize="small" className={classes.icon} />
  }

  const getExpandButton = () => {
    const Icon = expandIcons[promptType]?.name
    const position = expandIcons[promptType]?.position
    const createButton = (
      handler: React.MouseEventHandler<HTMLButtonElement> | undefined
    ) => (
      <Button
        onClick={handler}
        variant="outlined"
        size="medium"
        className={parentClasses.expandButton}
        startIcon={position === 'start' ? <Icon /> : undefined}
        endIcon={position === 'end' ? <Icon /> : undefined}
      >
        {expandButtonText}
      </Button>
    )

    // Check the permission and prompt type to determine if the button should be rendered
    const isPermissionGranted =
      promptType === 'addDataViewMode' ||
      promptType === 'commissionReportEditMode'
        ? tssCanWrite(roles)
        : true

    // Assign the correct event handler based on the type
    const getClickHandler = () => {
      switch (promptType) {
        case 'addDataViewMode':
        case 'commissionReportEditMode':
        case 'commissionReportViewMode':
        case 'commissionReportInsufficientSamples':
          return onClickHandler
        default:
          return handleExpand
      }
    }

    // logic for rendering based on type and permissions
    switch (promptType) {
      case 'addDataViewMode':
      case 'commissionReportEditMode':
      case 'commissionReportViewMode':
      case 'info':
        return isPermissionGranted ? createButton(getClickHandler()) : null
      case 'commissionReportInsufficientSamples':
        return createButton(getClickHandler())
      default:
        return null
    }
  }

  const useStyles = makeStyles({
    icon: {
      color: headerIcons[promptType].color,
    },
  })

  const classes = useStyles()

  return (
    <>
      <Grid container item direction="column" xs={12}>
        <Paper elevation={1} className={parentClasses.container}>
          <Box className={parentClasses.headingContainer}>
            <Grid container>
              <Grid
                item
                xs={11}
                container
                direction="row"
                alignContent="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Box ml={1} mr={1} pt={0.5}>
                  {getHeaderIcon()}
                </Box>
                <Typography variant="h5">{headerText}</Typography>
              </Grid>
              <Grid item xs={1} container justify="flex-end">
                <Box display="flex" justifyContent="flex-end" mr={0.5}>
                  {setIsMenuOpen && (
                    <IconButton
                      onClick={() => setIsMenuOpen(false)}
                      color="secondary"
                      data-testid="close-button"
                    >
                      <Close />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              justify="space-between"
              style={{ marginTop: '4px' }}
            >
              <Grid
                item
                sm={8}
                md={9}
                container
                direction="row"
                alignContent="center"
                alignItems="center"
                style={{ margin: '0 20px 0 20px' }}
              >
                {/* if contentText prop is an array, array[1] will be bold */}
                {Array.isArray(contentText) ? (
                  <Typography variant="body2">
                    {contentText[0]}
                    <strong>{contentText[1]}</strong>
                  </Typography>
                ) : (
                  <Typography variant="body2">{contentText}</Typography>
                )}
                {bodyJSX}
              </Grid>
              <Grid
                item
                sm={4}
                md={2}
                lg={1}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Box style={{ margin: '10px 20px', height: '40px' }}>
                  {getExpandButton()}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Collapse in={expanded} style={{ padding: '0 10px' }}>
            <Box>{expandedContent}</Box>
          </Collapse>
        </Paper>
      </Grid>
    </>
  )
}

export default ExpandablePrompt
