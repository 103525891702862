import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { IMixGroupOptionWithConditions } from '../../Logic/Types'

interface ICommissionReportMixGroupSelectProps {
  readonly index: number
  readonly mixGroupOptions: IMixGroupOptionWithConditions[]
  readonly mixDesignId: number | null
  readonly handleMixGroupChange: (
    index: number,
    value: IMixGroupOptionWithConditions | null
  ) => void
  readonly getIsMixGroupOptionDisabled: (
    option: IMixGroupOptionWithConditions,
    index: number
  ) => boolean
  readonly renderMixGroupOption: (
    option: IMixGroupOptionWithConditions
  ) => React.ReactNode
}

function CommissionReportMixGroupSelect(
  props: ICommissionReportMixGroupSelectProps
) {
  const {
    index,
    mixGroupOptions,
    mixDesignId,
    handleMixGroupChange,
    getIsMixGroupOptionDisabled,
    renderMixGroupOption,
  } = props

  return (
    <Autocomplete
      disableClearable
      size="small"
      id={`autocomplete-${index}`}
      data-testid={`autocomplete-${index}`}
      options={mixGroupOptions}
      value={
        mixGroupOptions.find(option => option.id === mixDesignId) ?? undefined
      }
      onChange={(_event, value) => handleMixGroupChange(index, value)}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} placeholder="Mix Code" variant="outlined" />
      )}
      getOptionDisabled={option => getIsMixGroupOptionDisabled(option, index)}
      renderOption={renderMixGroupOption}
    />
  )
}

export default CommissionReportMixGroupSelect
