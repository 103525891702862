import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import typography from '../../../theme/typography'
import BaseLegendItem from './BaseLegendItem'

const useStyles = makeStyles({
  legendTypeLabel: {
    fontWeight: typography.fontWeight.bold,
  },
})

export interface ILegendItem {
  label: string
  Icon: SvgIconComponent //as React component
  color: string
}

interface IBaseLegendProps {
  title: string
  items: ILegendItem[]
}

const BaseLegend = (props: IBaseLegendProps) => {
  const { title, items } = props
  const classes = useStyles()

  return (
    <Grid container item xs={12}>
      <Grid item container xs={3} direction="column" justify="center">
        <Typography className={classes.legendTypeLabel}>{title}</Typography>
      </Grid>
      {items.map((item, index) => (
        <Grid item container xs={3} key={`${index}-${item.label}`}>
          <BaseLegendItem item={item} />
        </Grid>
      ))}
    </Grid>
  )
}

export default BaseLegend
