import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import MapComponentLogical from '../../Components/MapComponent/MapComponentLogical'
import PropTypes from 'prop-types'
import RenderMapLegend from '../../Components/RenderMapLegend/RenderMapLegend'

const useStyles = makeStyles({
  mapContainer: {
    minHeight: '500px',
    maxHeight: '500px',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '8px',
  },
  legendContainer: {
    marginBottom: '1em',
  },
})

function MapViewPresentational(props) {
  MapViewPresentational.propTypes = {
    /** Data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** Flat array of companies, used for the map markers */
    companyMarkers: PropTypes.array.isRequired,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Filtered array of cement plants */
    filteredCementPlants: PropTypes.array,
    /** Coordinates of zip code that was selected in "FilterBy" */
    zipCodeCoordinates: PropTypes.array,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Boolean to determine whether or not to display the map legend */
    showMapLegend: PropTypes.bool.isRequired,
    /** Function to show/hide the map legend */
    toggleMapLegend: PropTypes.func.isRequired,
    /** Filtered array of CO2 depots */
    filteredCO2Depots: PropTypes.array,
    /** Array of CO2 depots */
    co2Depots: PropTypes.array,
    /** Filtered array of sales opportunities */
    filteredSalesOpportunities: PropTypes.array,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
  }
  const {
    selectedRows,
    filteredCorpData,
    companyMarkers,
    filteredUpcomingProjects,
    filteredCementPlants,
    zipCodeCoordinates,
    isLoading,
    showMapLegend,
    toggleMapLegend,
    filteredCO2Depots,
    co2Depots,
    filteredSalesOpportunities,
    readyMixTableData,
  } = props

  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} className={classes.mapContainer} id="echoMap">
        <MapComponentLogical
          selectedRows={selectedRows}
          companyMarkers={companyMarkers}
          filteredUpcomingProjects={filteredUpcomingProjects}
          filteredCementPlants={filteredCementPlants}
          zipCodeCoordinates={zipCodeCoordinates}
          isLoading={isLoading}
          filteredCO2Depots={filteredCO2Depots}
          co2Depots={co2Depots}
          filteredSalesOpportunities={filteredSalesOpportunities}
          filteredCorpData={filteredCorpData}
          readyMixTableData={readyMixTableData}
        />
      </Grid>
      <Grid item container className={classes.legendContainer}>
        <RenderMapLegend
          filteredCorpData={filteredCorpData}
          showMapLegend={showMapLegend}
          toggleMapLegend={toggleMapLegend}
        />
      </Grid>
    </>
  )
}

export default MapViewPresentational
