import React from 'react'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { EMapLayers } from '../../../Enums/EchoEnums'
import { echoColors } from '../../../../theme/colors'
import BaseLegend from '../BaseLegend'

/** Function to return sales opportunities icon for the map legend */
const OpportunitiesLegend = ({ echoSettings }) => {
  OpportunitiesLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  if (!echoSettings.layers.includes(EMapLayers.opportunities)) return null

  const items = [
    {
      label: 'Opportunities',
      color: echoColors.opportunities.hex,
      Icon: FiberManualRecordIcon,
    },
  ]

  return <BaseLegend title="Opportunities:" items={items} />
}

export default OpportunitiesLegend
