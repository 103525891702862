import React from 'react'
import {
  Tabs,
  Tab,
  Box,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import TabPanel from '../../../Common/Components/TabPanel'
import { iconMap } from './SonarTabHelper'
import { baseColors } from '../../../theme/colors'
import theme from '../../../theme/muiTheme'
import hooks from './UseSonarTabs'

interface StyleClasses {
  tabIcon: string
}

const useStyles = makeStyles(() => ({
  tabLabel: {
    ...theme.typography.body1,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: '-2px',
    whiteSpace: 'nowrap',
    marginLeft: '112px',
    marginRight: '80px',
  },
  selectedTab: {
    color: baseColors.primary.main,
  },
  indicator: {
    marginLeft: '32px',
    maxWidth: 260,
  },
  viewTab: {
    borderRadius: '8px',
    flexGrow: 1,
    marginBottom: '16px',
    padding: '24px',
  },
  tabIcon: {
    marginRight: '4px',
    marginBottom: '-6px',
    width: '24px',
    height: '24px',
  },
  customTab: {
    minWidth: 120,
    maxWidth: 300,
  },
}))

function getTabIcon(
  label: string,
  isSelected: boolean,
  classes: StyleClasses
): JSX.Element | null {
  const iconData = iconMap[label]
  if (!iconData) return null

  if (label === 'Overview') {
    const iconSrc = isSelected ? iconData.selectedIcon : iconData.unselectedIcon
    return <img src={iconSrc} className={classes.tabIcon} alt="Overview Icon" />
  }

  const Icon = iconData.icon
  if (!Icon) return null
  return <Icon className={classes.tabIcon} />
}

function SonarTabs() {
  const { tabValue, setTabValue, enabledTabs } = hooks.useSonarTabs()
  const classes = useStyles()

  return (
    <>
      <Box>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          classes={{ indicator: classes.indicator }}
        >
          {enabledTabs.map((tab, index) => (
            <Tab
              key={tab.label}
              className={classes.customTab}
              label={
                <span
                  className={`${classes.tabLabel} ${
                    tabValue === index ? classes.selectedTab : ''
                  }`}
                >
                  {getTabIcon(tab.label, tabValue === index, classes)}
                  {tab.label}
                </span>
              }
            />
          ))}
        </Tabs>
      </Box>
      {enabledTabs.map((tab, index) => (
        <TabPanel key={tab.label} tabValue={tabValue} index={index}>
          <Paper variant="outlined" className={classes.viewTab}>
            {tab.component ? (
              <tab.component />
            ) : (
              <Typography variant="h3" align="left">
                {tab.content}
              </Typography>
            )}
          </Paper>
        </TabPanel>
      ))}
    </>
  )
}

export default SonarTabs
