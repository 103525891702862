import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { CementTableData } from '../../Logic/Types'

interface MaterialHistoricalTableRowProps {
  rowData: CementTableData[]
}

function MaterialHistoricalTableRow(props: MaterialHistoricalTableRowProps) {
  const { rowData } = props

  return (
    <TableRow hover>
      {rowData.map((cell: CementTableData) => (
        <TableCell key={cell.id} style={{ minWidth: 150 }}>
          {typeof cell.cementData === 'number'
            ? parseInt(cell.cementData)
            : cell.cementData}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default MaterialHistoricalTableRow
