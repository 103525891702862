export const serviceCaseOverviewLabels = {
  leftColumn: [
    {
      label: 'Case',
      name: 'case',
    },
    {
      label: 'Location',
      name: 'location',
    },
    {
      label: 'System',
      name: 'system',
    },
    {
      label: 'Case Opened',
      name: 'caseOpened',
    },
    {
      label: 'Case Closed',
      name: 'caseClosed',
    },
    {
      label: 'Days To Resolve',
      name: 'daysToResolve',
    },
  ],
  rightColumn: [
    {
      label: 'Alarm HMI ID (if any)',
      name: 'hmiID',
    },
    {
      label: 'System Downtime',
      name: 'systemDowntime',
    },
    {
      label: 'Solved Remotely',
      name: 'solvedRemotely',
    },
    {
      label: 'Unique Issue',
      name: 'uniqueIssue',
    },
    {
      label: 'Replacement Parts Needed Date',
      name: 'replacementPartsNeededDate',
    },
    {
      label: 'Parts Shipped Date',
      name: 'partsShippedDate',
    },
    {
      label: 'Parts Received Date',
      name: 'partsReceivedDate',
    },
  ],
}

export const remoteTroubleshootingLabels = {
  leftColumn: [
    {
      label: 'CarbonCure Remote Technician',
      name: 'remoteTechnician',
    },
    {
      label: 'Initial Indication',
      name: 'initialIndication',
    },
    {
      label: 'Responsible for the issue',
      name: 'responsibleForIssue',
    },
    {
      label: 'Error Message',
      name: 'errorMessage',
    },
    {
      label: 'Faulty System Component',
      name: 'faultySystemComponent',
    },
    {
      label: 'Plant POC',
      name: 'plantPOC',
    },
    {
      label: 'Description of Problem by Customer',
      name: 'problemDescriptionByCustomer',
    },
    {
      label: 'Actions Taken Remotely',
      name: 'actionsTakenRemotely',
    },
  ],
  rightColumn: [
    {
      label: 'Graph or Data',
      name: 'graphOrData',
    },
    {
      label: 'Remote Update',
      name: 'remoteUpdate',
    },
    {
      label: 'Follow Up Required',
      name: 'followUpRequired',
    },
    {
      label: 'Follow Up Summary',
      name: 'followUpSummary',
    },
    {
      label: 'Status',
      name: 'status',
    },
    {
      label: 'Market Dev. Rep Email',
      name: 'marketDevRepEmail',
    },
    {
      label: 'Sales Owner Email',
      name: 'salesOwnerEmail',
    },
    {
      label: 'TSS Owner Email',
      name: 'tssOwnerEmail',
    },
  ],
}

export const onsiteServiceLabels = {
  leftColumn: [
    {
      label: 'Onsite Service Required',
      name: 'onsiteServiceRequired',
    },
    {
      label: 'Critical Repair Plan',
      name: 'criticalRepairPlan',
    },
    {
      label: 'Repair Plan Approved',
      name: 'repairPlanApproved',
    },
    {
      label: 'Actions Taken Onsite',
      name: 'actionsTakenOnsite',
    },
    {
      label: 'Replaced Components',
      name: 'replacedComponents',
    },
  ],
  rightColumn: [
    {
      label: 'CarbonCure Onsite Technician',
      name: 'onsiteTechnician',
    },
    {
      label: 'Anticipated Repair Date',
      name: 'anticipatedRepairDate',
    },
    {
      label: 'Arrived Onsite',
      name: 'arrivedOnsite',
    },
    {
      label: 'Solved Onsite',
      name: 'solvedOnsite',
    },
  ],
}

export const blankServiceCase = {
  overview: {
    case: '',
    location: '',
    system: '',
    caseOpened: '',
    caseClosed: '',
    daysToResolve: '',
    hmiID: '',
    systemDowntime: '',
    solvedRemotely: '',
    uniqueIssue: '',
    replacementPartsNeededDate: '',
    partsShippedDate: '',
    partsReceivedDate: '',
  },
  remoteTroubleshooting: {
    remoteTechnician: '',
    initialIndication: '',
    responsibleForIssue: '',
    errorMessage: '',
    faultySystemComponent: '',
    plantPOC: '',
    problemDescriptionByCustomer: '',
    actionsTakenRemotely: '',
    graphOrData: '',
    remoteUpdate: '',
    followUpRequired: '',
    followUpSummary: '',
    status: '',
    marketDevRepEmail: '',
    salesOwnerEmail: '',
    tssOwnerEmail: '',
  },
  onsiteService: {
    onsiteServiceRequired: '',
    criticalRepairPlan: '',
    repairPlanApproved: '',
    actionsTakenOnsite: '',
    replacedComponents: '',
    onsiteTechnician: '',
    anticipatedRepairDate: '',
    arrivedOnsite: '',
    solvedOnsite: '',
  },
  notes: [''],
}

export const ServiceCaseStatuses = [
  { value: 'Open', name: 'Open' },
  { value: 'UnderInvestigation', name: 'Under Investigation' },
  {
    value: 'AwaitingRepairPlanApproval',
    name: 'Awaiting Repair Plan Approval',
  },
  { value: 'AwaitingRepair', name: 'Awaiting Repair' },
  { value: 'Solved', name: 'Solved' },
  { value: 'Closed', name: 'Closed' },
]

export const ServiceCaseStatusMapping = {
  Open: 'Open',
  UnderInvestigation: 'Under Investigation',
  AwaitingRepairPlanApproval: 'Awaiting Repair Plan Approval',
  AwaitingRepair: 'Awaiting Repair',
  Solved: 'Solved',
  Closed: 'Closed',
}

export const injectionGraphOptions = [
  { id: 'cO2GraphVisible', name: 'Injected CO2' },
  { id: 'temperatureGraphVisible', name: 'Temp' },
  { id: 'gasGraphVisible', name: 'Gas' },
  { id: 'liquidGraphVisible', name: 'Liquid' },
  { id: 'upstreamPressureGraphVisible', name: 'US Press' },
  { id: 'downstreamPressure0GraphVisible', name: 'DS Press' },
  { id: 'downstreamPressure1GraphVisible', name: 'DS Press 2' },
]
