import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid } from '@material-ui/core'
import hm from 'highcharts/modules/heatmap.js'
import PropTypes from 'prop-types'
import {
  freshPropertyLabelsWithImperialUnit,
  freshPropertyLabelsWithMetricUnit,
} from '../../Constants/AnalysisConstants'
import FreshPropertyDropdown from '../FreshPropertyDropdown'

hm(Highcharts)

export default function SandBoxPresentational(props) {
  SandBoxPresentational.propTypes = {
    /** Config settings for Highchart, includes array with graph data. */
    graphOptions: PropTypes.object.isRequired,
    /** Currently selected Y variable */
    yVariable: PropTypes.string.isRequired,
    /** Function sets the variable for graph's Y axis. */
    setYVariable: PropTypes.func.isRequired,
    /** Currently selected X variable */
    xVariable: PropTypes.string.isRequired,
    /** Function sets the variable for graph's X axis. */
    setXVariable: PropTypes.func.isRequired,
    /** Currently selected color variable */
    colorVariable: PropTypes.string.isRequired,
    /** Function sets the variable for the graph point colors. */
    setColorVariable: PropTypes.func.isRequired,
    /** A function to set the ref */
    setRef: PropTypes.func.isRequired,
  }
  const {
    graphOptions,
    yVariable,
    setYVariable,
    xVariable,
    setXVariable,
    colorVariable,
    setColorVariable,
    setRef,
    isMetric,
  } = props

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOptions}
            ref={setRef}
          />
        </Grid>
        <Grid item style={{ margin: '0px 20px 15px 20px', width: '100%' }}>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justify="flex-start"
          >
            <Grid item md={4} align="center">
              <FreshPropertyDropdown
                propertyOptions={
                  isMetric
                    ? freshPropertyLabelsWithMetricUnit
                    : freshPropertyLabelsWithImperialUnit
                }
                setSelectedProperty={setYVariable}
                labelName={'Select Y Variable'}
                selectedProperty={yVariable}
              />
            </Grid>
            <Grid item md={4} lg={4} align="center">
              <FreshPropertyDropdown
                propertyOptions={
                  isMetric
                    ? freshPropertyLabelsWithMetricUnit
                    : freshPropertyLabelsWithImperialUnit
                }
                setSelectedProperty={setXVariable}
                labelName={'Select X Variable'}
                selectedProperty={xVariable}
              />
            </Grid>
            <Grid item md={4} lg={4} align="center">
              <FreshPropertyDropdown
                propertyOptions={
                  isMetric
                    ? freshPropertyLabelsWithMetricUnit
                    : freshPropertyLabelsWithImperialUnit
                }
                setSelectedProperty={setColorVariable}
                labelName={'Select Property'}
                selectedProperty={colorVariable}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
