import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core'
import {
  CopyVariationHeadCell,
  CopyVariationTableType,
  MixGroupVariation,
} from '../../Logic/Types'
import GeneralizedEnhancedTableHead from '../../../Common/Components/GeneralizedEnhancedTableHead'
import CopyMixVariationTableRow from './CopyMixVariationTableRow'

export interface ICopyMixVariationTableProps {
  /** Function to fetch data and copy variation when confirm autofill */
  fetchAndCopyMixVariation?: (event: React.FormEvent<HTMLFormElement>) => void
  /** Function to store copied variation and open Add Mix page when confirm create new mix group */
  CopyMixVariationFromAnalysis?: (mixDesignId: number) => void
  /** Array of mix group variations for populating the table */
  associatedMixVariations: Array<MixGroupVariation>
  /** Function to handle selecting variation when the row is clicked */
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MixGroupVariation
  ) => void
  /** Selected Variation to be copied and autofilled */
  selectedVariation: MixGroupVariation | null | undefined
  /** Boolean indicating whether to show the process icon and to disable buttons */
  isLoading?: boolean
  /** Enum to determine the UI/behaviour of the table */
  tableType: CopyVariationTableType
  /** Function to close the current menu by id */
  setIsMenuOpen?: (prevState: { [mixDesignId: string]: boolean }) => void
  /** Current Mix Design Id, optional */
  mixDesignId?: number
  /** Whether the unit system is metric or not */
}

const CopyMixVariationTable = (props: ICopyMixVariationTableProps) => {
  const {
    fetchAndCopyMixVariation,
    CopyMixVariationFromAnalysis,
    handleClick,
    associatedMixVariations,
    selectedVariation,
    isLoading,
    tableType,
    mixDesignId,
    isMetric,
  } = props

  //Headcell values in arrays to avoid duplication at SonarCloud
  const headCellValues: Array<[string, boolean, boolean, string, string]> = [
    ['mixCode', false, false, 'Variation #', '20%'],
    ['cementReduction', true, false, 'Cement Red.', '10%'],
    ['co2Dosage', true, false, 'CO₂ Dose', '10%'],
    ['totalBatches', true, false, '# Batches', '10%'],
    ['designStrength', true, false, 'Des. Str.', '10%'],
    ['requiredStrength', true, false, 'Req. Str.', '10%'],
    ['averageStrength', true, false, 'Ave. Str.', '10%'],
    ['OD', true, false, 'OD', '15%'],
  ]

  //Mapping the values into headCells
  const headCells: CopyVariationHeadCell[] = headCellValues.map(
    ([id, numeric, disablePadding, label, width]) => ({
      id,
      numeric,
      disablePadding,
      label,
      width,
    })
  )

  const getButtonText = () => {
    switch (tableType) {
      case CopyVariationTableType.AddData:
        return 'Autofill New Mix Group'
      case CopyVariationTableType.Analysis:
        return 'Create New Mix Group'
      default:
        return ''
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    switch (tableType) {
      case CopyVariationTableType.AddData:
        if (fetchAndCopyMixVariation) fetchAndCopyMixVariation(event)
        break

      case CopyVariationTableType.Analysis:
        if (mixDesignId && CopyMixVariationFromAnalysis)
          CopyMixVariationFromAnalysis(mixDesignId)
        break
      default:
        return null
    }
  }

  //button only enable/disable in current table when multiple prompts are open
  const currentSelected =
    tableType === CopyVariationTableType.Analysis
      ? mixDesignId === selectedVariation?.mixDesignId
      : !!selectedVariation

  return (
    <>
      <Box margin={1}>
        <TableContainer style={{ maxHeight: 300 }}>
          <Table>
            <GeneralizedEnhancedTableHead
              headCells={headCells}
              checkboxColumn={true}
            />
            <TableBody>
              {associatedMixVariations?.map(row => {
                const isSelected = selectedVariation?.variationId
                  ? selectedVariation.variationId === row.variationId
                  : selectedVariation?.mixDesignId === row.mixDesignId
                const labelId = `variation-${row.mixCode}`

                return (
                  <CopyMixVariationTableRow
                    key={row.variationId}
                    row={row}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    labelId={labelId}
                    isMetric={isMetric}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justify="flex-end" style={{ margin: '16px 0' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading || !currentSelected}
            onClick={event => handleButtonClick(event)}
            size="medium"
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="2em" />
            ) : (
              <>{getButtonText()}</>
            )}
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default CopyMixVariationTable
