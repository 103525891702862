import React, { useCallback, useEffect, useState } from 'react'
import InjectionGraphPresentational from './InjectionGraphPresentational'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import Status from '../Logic/Status'
import Injection from '../Logic/Injection'
import { useRecoilValue } from 'recoil'
import { atomCurrentRemote, atomDateRange } from '../../../Common/engAtoms'

function InjectionGraphLogical(props) {
  const { graphVisibility } = props

  //will eventually have the plant in a recoil value
  const plant = useRecoilValue(atomCurrentRemote)

  const dateRange = useRecoilValue(atomDateRange)

  const [injections, setInjections] = useState([])
  const [, setInjectionCount] = useState(0)
  const [status, setStatus] = useState([])
  const [, setStatusCount] = useState(0)

  const loadStatuses = useCallback(
    async (limit = 1000, offset = 0) => {
      const response = await AlarmsManagementDataHelpers.getStatuses(
        plant?.remoteId,
        {
          limit,
          offset,
          startTime: dateRange.startTime,
          endTime: dateRange.endTime,
        }
      )
      if (response.ok) {
        const { results, count } = await response.json()
        setStatus(
          results.map(statusObject => Status.fromKelownaObject(statusObject))
        )
        setStatusCount(count)
        return results.map(statusObject =>
          Status.fromKelownaObject(statusObject)
        )
      }
    },
    [plant, dateRange.endTime, dateRange.startTime]
  )

  const loadInjections = useCallback(
    async (limit = 1000, offset = 0) => {
      const response = await AlarmsManagementDataHelpers.getInjections(
        plant?.remoteId,
        {
          limit,
          offset,
          startTime: dateRange.startTime,
          endTime: dateRange.endTime,
        }
      )
      if (response.ok) {
        const { results, count } = await response.json()
        const injectionsAsObjects = results.map(injection =>
          Injection.fromKelownaObject(injection)
        )
        setInjections(() => injectionsAsObjects)
        setInjectionCount(count)
        return injectionsAsObjects
      }
    },
    [plant, dateRange.endTime, dateRange.startTime]
  )

  useEffect(() => {
    if (plant?.remoteId) {
      loadInjections()
      loadStatuses()
    } else {
      setInjections([])
      setStatus([])
    }
  }, [plant, loadStatuses, loadInjections])

  return (
    <InjectionGraphPresentational
      statusArray={status}
      injectionArray={injections}
      graphVisibility={graphVisibility}
    />
  )
}

export default InjectionGraphLogical
