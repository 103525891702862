import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { getLocalDateTimeFromISODateString } from '../Logic/PCBLogic'

MasterUnitBootsTable.propTypes = {
  /** An array of boots that will be displayed in this table */
  boots: PropTypes.array.isRequired,
}

function MasterUnitBootsTable(props) {
  const { boots } = props

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Timestamp</Typography>
              </TableCell>
              <TableCell>
                <Typography>Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boots.map(boot => {
              return (
                <TableRow key={boot.bootId}>
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(boot.timestamp)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Firmware Version: {boot.masterUnitFirmwareVersion}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default MasterUnitBootsTable
