import React from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  CardHeader,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MixGroupConditions } from '../../Logic/Types'
import MixGroupOptionWithBaselineConditions from '../MixGroupOptionWithBaselineConditions'

export default function AddMixCopyDesignPresentational(props) {
  AddMixCopyDesignPresentational.propTypes = {
    /** List of a divisions mix codes, including mixDesignId and isCO2Design properties. */
    divisionMixCodes: PropTypes.arrayOf(
      PropTypes.shape({
        mixDesignId: PropTypes.number,
        mixCode: PropTypes.string,
        isCO2Design: PropTypes.bool,
        plantName: PropTypes.string,
      })
    ).isRequired,

    /** Currently selected mix code object. */
    selectedMixCode: PropTypes.PropTypes.shape({
      mixDesignId: PropTypes.number,
      mixCode: PropTypes.string,
      isCO2Design: PropTypes.bool,
      plantName: PropTypes.string,
    }),

    /** Function to set the current mix. */
    setSelectedMixCode: PropTypes.func.isRequired,

    /** Function to fetch selected mix detials and populate the view. */
    handleConfirm: PropTypes.func.isRequired,

    /** Bool indicating if component is in a loading state. */
    isLoading: PropTypes.bool.isRequired,

    /** Bool indicating whether control or CO2 mixes should be displayed. */
    isConditionCO2: PropTypes.bool.isRequired,

    /** Function to toggle between CO2 and control mixes. */
    handleSetCondition: PropTypes.func.isRequired,

    /** Bool indiciating whether the component should diplay the copy interface. */
    isCopyMenuOpen: PropTypes.bool.isRequired,

    /** Function to hide the component when the copy menu is closed. */
    handleCollapse: PropTypes.func.isRequired,
  }

  const {
    divisionMixCodes,
    selectedMixCode,
    setSelectedMixCode,
    handleConfirm,
    isLoading,
    isConditionCO2,
    handleSetCondition,
    isCopyMenuOpen,
  } = props

  return (
    <>
      <Collapse in={isCopyMenuOpen} timeout="auto">
        <Card elevation={3}>
          <CardHeader
            title="Copy Mix Design"
            titleTypographyProps={{
              variant: 'h5',
            }}
            style={{ paddingBottom: '0px' }}
          />
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '8px' }}>
                <Autocomplete
                  options={divisionMixCodes.filter(option =>
                    isConditionCO2 ? option.isCO2Design : !option.isCO2Design
                  )}
                  getOptionSelected={(option, value) =>
                    option.MixDesignId === value.MixDesignId
                  }
                  getOptionLabel={option =>
                    option.plantName
                      ? `${option.mixCode} - ${option.plantName}`
                      : option.mixCode
                  }
                  disabled={!divisionMixCodes?.length}
                  value={selectedMixCode}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Mix Code"
                      variant="outlined"
                      size="small"
                      name="copyMixDesign"
                      placeholder="Select a Mix Design to Copy"
                    />
                  )}
                  renderOption={option => (
                    <MixGroupOptionWithBaselineConditions
                      option={option}
                      forceBaselineData={true}
                    />
                  )}
                  onChange={(_, newValue) => {
                    setSelectedMixCode(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '8px' }}>
                <TextField
                  variant="outlined"
                  label="Condition"
                  size="small"
                  select
                  name="condition"
                  placeholder="Select A Condition"
                  value={isConditionCO2}
                  onChange={handleSetCondition}
                >
                  <MenuItem value={false}>{MixGroupConditions.NOCO2}</MenuItem>
                  <MenuItem value={true}>{MixGroupConditions.CO2}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" style={{ paddingRight: '8px' }}>
              <Grid item>
                <Button
                  size="medium"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isLoading || !selectedMixCode}
                  onClick={e => handleConfirm(e)}
                >
                  {isLoading ? (
                    <CircularProgress color="primary" size="2em" />
                  ) : (
                    <>Confirm Copy</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Collapse>
    </>
  )
}
