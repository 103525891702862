import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  wideHeader: {
    width: '100px',
  },
}))

function EchoTableHead(props) {
  EchoTableHead.defaultProps = { includeCheckbox: false }
  EchoTableHead.propTypes = {
    /** Order of the data table rows, ascending or descending */
    order: PropTypes.string.isRequired,
    /** Data table column by which to set the order of the rows */
    orderBy: PropTypes.string.isRequired,
    /** State setter function to set the order of the rows based on a particular column */
    onRequestSort: PropTypes.func.isRequired,
    /** Table Column headers */
    headCells: PropTypes.array.isRequired,
  }

  const { order, orderBy, onRequestSort, headCells, includeCheckbox } = props
  const headerClasses = useStyles()

  /** Function to sort the table rows */
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {includeCheckbox ? <TableCell padding="checkbox" /> : null}
        {/* Table head cells with sort functionality */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headerClasses.cell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={
                headCell.id === 'co2Savings' ||
                headCell.id === 'concreteDelivered'
                  ? headerClasses.wideHeader
                  : null
              }
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EchoTableHead
