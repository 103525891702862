import React from 'react'
import PropTypes from 'prop-types'
import { CloudOutlined } from '@material-ui/icons'
import { EMapLayers } from '../../../Enums/EchoEnums'
import { echoColors } from '../../../../theme/colors'
import BaseLegend from '../BaseLegend'

const CO2DepotsLegend = ({ echoSettings }) => {
  CO2DepotsLegend.propTypes = {
    echoSettings: PropTypes.object.isRequired,
  }

  if (!echoSettings.layers.includes(EMapLayers.co2Depots)) return null

  const items = [
    {
      label: 'CO₂ Depots',
      color: echoColors.co2Deport.hex,
      Icon: CloudOutlined,
    },
  ]

  return <BaseLegend title="CO₂ Depots:" items={items} />
}

export default CO2DepotsLegend
