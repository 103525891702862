import { ScatterplotLayer } from '@deck.gl/layers'
import { getPlantLineColor } from '../../../Logic/MapLogicHelpers'
import { checkIsWithinRadius } from '../../../Logic/FilterLogicHelpers'
import { getLocationRadius, getPlantFill } from '../MapComponentHelpers'

export function getItems(plantMarkers, echoSettings, hasSystem) {
  let result = plantMarkers.filter(
    plant => plant.isPrecast && plant.hasSystem === hasSystem
  )

  // salesPeople
  if (echoSettings.salesPeople.length) {
    result = result.filter(
      plant => plant.salesPerson === echoSettings.salesPeople[0]
    )
  }
  // countries
  if (echoSettings.countries.length) {
    result = result.filter(plant => plant.country === echoSettings.countries[0])
  }
  // states
  if (echoSettings.states.length) {
    result = result.filter(plant => echoSettings.states.includes(plant.state))
  }
  // cities
  if (echoSettings.cities.length) {
    result = result.filter(plant =>
      checkIsWithinRadius(plant.longLat, echoSettings.cities[0].longLat, 200)
    )
  }

  result = result.map(plant => ({ ...plant, layerType: 'plant' }))
  return result
}

/**
 * @param {Object []} data - Objects returned from getItems
 * @param {Number[]} selectedIds
 * @param {Boolean} greenfield
 * @param {Boolean} filterBySelected
 * @param {'precastPlantsNoCustomers' | 'precastPlantsCustomers'} type
 * @returns
 */
export function getLayer(
  data,
  selectedIds,
  greenfield,
  filterBySelected,
  type
) {
  return new ScatterplotLayer({
    radiusUnits: 'pixels',
    id: type,
    data: data,
    pickable: true,
    opacity: 0.6,
    stroked: filterBySelected,
    filled: true,
    getPosition: plant => plant.longLat,
    getRadius: plant => getLocationRadius(plant, selectedIds),
    getFillColor: plant =>
      getPlantFill(plant, filterBySelected, type, greenfield),
    ...(filterBySelected && { getLineColor: getPlantLineColor(greenfield) }),
    ...(filterBySelected && { getLineWidth: 4 }),
    lineWidthUnits: 'pixels',
  })
}

export function getPrecastPlantsLayer(
  echoSettings,
  plantMarkers,
  selectedIds,
  filterBySelected
) {
  const showNoCustomers =
    echoSettings.ownership.includes('privatelyOwned') ||
    echoSettings.ownership.includes('verticallyIntegrated')
  const showCustomers = echoSettings.ownership.includes('customer')

  return [
    ...(showNoCustomers
      ? [
          getLayer(
            getItems(plantMarkers, echoSettings, false),
            selectedIds,
            echoSettings.isGreenfield,
            filterBySelected,
            'precastPlantsNoCustomers'
          ),
        ]
      : []),
    ...(showCustomers
      ? [
          getLayer(
            getItems(plantMarkers, echoSettings, true),
            selectedIds,
            echoSettings.isGreenfield,
            filterBySelected,
            'precastPlantsCustomers'
          ),
        ]
      : []),
  ]
}
