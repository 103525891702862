import React, { useState, useEffect, useRef, useCallback } from 'react'
import Graphs from '../Components/Graphs'
import MixDesignTableLogical from '../Components/MixDesignTable/MixDesignTableLogical'
import BatchDataTable from '../Components/BatchDataTable'
import {
  Container,
  Grid,
  Typography,
  Paper,
  makeStyles,
  Backdrop,
  CircularProgress,
  Button,
  Tooltip,
  Box,
} from '@material-ui/core'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import {
  getMixGroupByBatch,
  getNewSettings,
  getSupplierNames,
  getUrlFromFilters,
} from '../Data/TSSDataHelpers'
import {
  GetAlerts,
  convertAnalysisBatchDataUnit,
  convertAnalysisMixDataUnit,
  digestMixDesigns,
  getAnalysisIdentifyOutliersUrl,
  getMixDesignsForOptimization,
  mixVariationsToMixVariationsRows,
  tssCanWrite,
} from '../Logic/TSSLogic'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { addStyles, headCellsMixDesigns } from '../Constants/AddDataConstants'
import SimpleTablePresentational from '../../Common/Components/SimpleTable/SimpleTablePresentational'
import {
  FilterComponentTypes,
  SimpleTableSelection,
  SimpleTableTypes,
  VariabilityGraphPage,
} from '../../Common/Logic/Types'
import {
  deepCopy,
  getNewCurrentPage,
  isAbortedFetchError,
} from '../../Common/Helpers/GeneralHelpers'
import { baseColors } from '../../theme/colors'
import xor from 'lodash.xor'
import { useLocation, Link } from 'react-router-dom'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import { CommissioningFilterOptions } from '../Constants/CommonConstants'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import { atomGraphSelectedFilters } from '../../Common/tssAtoms'
import { mean } from 'mathjs'
import theme from '../../theme/muiTheme'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import { cloneDeep } from 'lodash'
import SelectOptimizationPrompt from '../Components/SelectOptimizationPrompt/SelectOptimizationPrompt'
import AnalyticsOutlined_Enabled from '../Assets/AnalyticsOutlined_Enabled.svg'
import AnalyticsOutlined_Disabled from '../Assets/AnalyticsOutlined_Disabled.svg'
import { Alert, AlertTitle } from '@material-ui/lab'
import breakpoints from '../../theme/breakpoints'

const useStyles = makeStyles({
  ...addStyles(SimpleTableTypes.ViewDesignMixGroup, theme, []),
  ...theme.customClasses.filterPanel,
  filterPanelContainer: {
    position: 'relative',
    [theme.breakpoints.down(breakpoints.sm)]: {
      position: 'static',
    },
  },
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '226px',
    zIndex: 50,
    [theme.breakpoints.up(1378)]: {
      top: '208px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    zIndex: -50,
    top: '226px',
    [theme.breakpoints.up(1378)]: {
      top: '208px',
    },
  },
  btnContainer: {
    display: 'inline-block',
    paddingLeft: 0,
  },
  baleenButton: {
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid transparent',
    },
  },
})

const useStyles2 = makeStyles({
  ...addStyles(SimpleTableTypes.ViewDesignMixGroup, theme, []),

  graphFilterPanelContainer: {
    position: 'relative',
    [theme.breakpoints.down(breakpoints.sm)]: {
      position: 'static',
    },
  },
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    zIndex: 100,
    top: '12px',
    [theme.breakpoints.down(breakpoints.sm)]: {
      top: '50px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    display: 'inline-block',
    zIndex: -50,
    top: '18px',
  },
})

const useFiltersStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: 'auto',
  },
  checkboxFormControlLabel: {
    marginLeft: 'auto',
    width: '100%',
  },
}))

const defaultMixTableData = {
  digestedMixDesigns: [],
  selectedMixDesigns: [],
  selectedMixVariations: [],
  batchActuals: [],
  compareAndAnalyzeMixDesigns: {}, // used to know which mix designs have at least 1 variation in the compare & analyze section
  selectedBatchActuals: [],
  dataToExport: [],
}

const defaultTableSettings = {
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'divisionName',
}

const defaultCompareAndAnalyzeTableSettings = {
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: '',
}

const graphTypeOptions = [
  {
    id: 'Histogram',
    name: 'Histogram',
  },
  {
    id: 'Strength Development',
    name: 'Strength Development',
  },
  {
    id: 'Variability',
    name: 'Variability',
  },
  {
    id: 'Sandbox',
    name: 'Sandbox',
  },
  {
    id: 'Bar',
    name: 'Bar',
  },
]

function ViewDesignPage() {
  const JWT = useRecoilValue(atomJWT)
  const currentCustomer = useRecoilValue(atomCurrentCustomer)
  const [batchData, setBatchData] = useState([{ value: [] }])
  const [producerList, setProducerList] = useState([])
  const [selectedProducer, setSelectedProducer] = useState(null)
  const [supplierList, setSupplierList] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState([])
  const [mixDesignCount, setMixDesignCount] = useState(0)
  const [scroll, setScroll] = useState(false)
  const [tableData, setTableData] = useState(defaultMixTableData)
  const [mixDesignTableSettings, setMixDesignTableSettings] = useState(
    defaultTableSettings
  )
  const [
    compareAndAnalyzeTableSettings,
    setCompareAndAnalyzeTableSettings,
  ] = useState(defaultCompareAndAnalyzeTableSettings)
  const classes2 = useStyles2()
  const filtersClasses = useFiltersStyles()
  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)
  const [isOptimizationMenuOpen, setIsOptimizationMenuOpen] = useState(true)
  const [mixDesignsForOptimization, setMixDesignsForOptimization] = useState([])
  const batchRef = useRef(null)
  const compareAndAnalyzeHeadingRef = useRef()
  const [collapsedMixDesigns, setCollapsedMixDesigns] = useState(() => [])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const { search } = useLocation()

  const [analysisFilterSettings, setAnalysisFilterSettings] = useState({
    mixCode: [],
    producer: [],
    supplier: [],
    commissioning: [],
    showArchived: ['false'],
  })
  const [
    analysisFrontendFilterSettings,
    setAnalysisFrontendFilterSettings,
  ] = useState({ isMetric: false })
  const [isMetricFlag, setIsMetricFlag] = useState(false)

  const [
    isViewDesignsFilterPanelOpen,
    setIsViewDesignsFilterPanelOpen,
  ] = useState(false)
  const [isGraphFilterPanelOpen, setIsGraphFilterPanelOpen] = useState(false)

  const [expandFilters, setExpandFilters] = useState({
    customer: false,
    mixDetails: false,
  })
  const [expandGraphFilters, setExpandGraphFilters] = useState({
    graphs: false,
  })
  const [activeGraphFilters, setActiveGraphFilters] = useState([])
  const [filteredData, setFilteredData] = useState(
    tableData.selectedMixVariations
  )
  const [graphType, setGraphType] = useState('Histogram')
  const [selectedAge, setSelectedAge] = useState(0)

  const [filterCount, setFilterCount] = useState(0)
  const [graphFilterCount, setGraphFilterCount] = useState(0)

  const [isNormalizedButtonDisabled, setIsNormalizedButtonDisabled] = useState(
    false
  )
  const [normalizedData, setNormalizedData] = useState([])

  const resetSelectedGraphFilters = useResetRecoilState(
    atomGraphSelectedFilters
  )
  const [selectedGraphFilters, setSelectedGraphFilters] = useRecoilState(
    atomGraphSelectedFilters
  )
  const [alerts, setAlerts] = useState([])

  const updateSelectedAge = useCallback(age => {
    setSelectedAge(age)
  }, [])

  const selectGraph = e => {
    setGraphType(e.target.value)
  }

  const helperVariables = {
    analysisFilterSettings: analysisFilterSettings,
    setAnalysisFilterSettings: setAnalysisFilterSettings,
    rows: tableData.digestedMixDesigns,
    selectedMixVariations: tableData.selectedMixVariations,
  }

  const graphHelperVariables = {
    selectedMixVariations: tableData.selectedMixVariations,
    filteredData: filteredData,
    setFilteredData: setFilteredData,
    graphType: graphType,
    selectGraph: selectGraph,
    updateSelectedAge: updateSelectedAge,
    analysisFrontendFilterSettings: analysisFrontendFilterSettings,
  }

  const classes = useStyles()
  /** Options for the dropdown */
  const commissioningOptions = [
    CommissioningFilterOptions.InCommissioning,
    CommissioningFilterOptions.CommissioningComplete,
  ]

  const measurementOptions = [
    {
      name: 'Metric',
      id: true,
    },
    {
      name: 'Imperial',
      id: false,
    },
  ]

  const setSelectedCustomer = useSetRecoilState(atomCurrentCustomer)
  /* Function to set producer drop down list values to the state variables */
  const setProducerValues = (filter, division) => {
    const divisionId = division ? division.divisionId : null
    setAnalysisFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        producer: divisionId ? [divisionId] : [],
      }
    })
    setSelectedCustomer(currVal => ({
      ...currVal,
      division: division,
      plant: null,
    }))
  }

  /* Function to set supplier drop down list values to the state variables */
  const setSupplierValues = (filter, itemList) => {
    let supplierIds = itemList.map(item => item.cementPlantId)
    setAnalysisFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        supplier: supplierIds.length ? supplierIds : [],
      }
    })
    return supplierIds
  }

  /** A function which takes a filter as an argument and returns an array of objects values or a string value. Determines the value of a filter. */
  const getValue = filter => {
    if (filter.property === 'producer') {
      if (analysisFilterSettings.producer.length > 0) {
        let idx = producerList.findIndex(
          x => x.divisionId === Number(analysisFilterSettings.producer[0])
        )
        if (idx > -1) {
          setSelectedProducer(producerList[idx])
          return producerList[idx]
        }
        setSelectedProducer(null)
        return null
      }
      setSelectedProducer(null)
      return null
    } else if (filter.property === 'supplier') {
      return supplierList.filter(option =>
        analysisFilterSettings['supplier'].includes(option['cementPlantId'])
      )
    } else if (filter.property === 'showArchived') {
      return analysisFilterSettings.showArchived[0] === 'true'
    } else if (filter.property === 'mixCode') {
      return analysisFilterSettings.mixCode[0]
    } else if (filter.property === 'graphType') {
      return graphType
    } else if (filter.property === 'isMetric') {
      return analysisFrontendFilterSettings.isMetric
    } else {
      const validValue = analysisFilterSettings['commissioning'].length > 0
      return validValue ? analysisFilterSettings.commissioning[0] : null
    }
  }

  const showArchivedChangeHandler = () => {
    setAnalysisFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        showArchived:
          prevSettings['showArchived'][0] === 'true' ? ['false'] : ['true'],
      }
    })
  }

  const onCommissioningChangeHandler = (_, val) => {
    setAnalysisFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        commissioning: val ? [val] : [],
      }
    })
  }

  const isCustomerDisabled = () => {
    return !producerList.length > 0
  }

  const calculateBatchStrength = (slumpDiff, airDiff, batchStrength) => {
    if (slumpDiff > 0) {
      batchStrength = batchStrength + 150 * slumpDiff
    } else {
      batchStrength = batchStrength - 150 * slumpDiff
    }

    if (airDiff > 0) {
      batchStrength = batchStrength + 0.05 * batchStrength * airDiff
    } else {
      batchStrength = batchStrength - 0.05 * batchStrength * airDiff
    }
    return batchStrength
  }

  /** Normalize selected data according to following formula
  For slump, add/subtract 150 psi for every 1" of slump change
  For air content, add/subtract 5% of strength for every 1% change in air content */
  const normalize = () => {
    let slumpValues = []
    let airValues = []
    let avgSlump, avgAir, slumpDiff, airDiff
    let dataToNormalize = deepCopy(filteredData)

    dataToNormalize.forEach(selected => {
      selected.freshProperties.forEach(freshProperty => {
        if (freshProperty.slump) slumpValues.push(freshProperty.slump)
        if (freshProperty.air) airValues.push(freshProperty.air)
      })
    })
    avgSlump = slumpValues.length !== 0 && mean(slumpValues)
    avgAir = airValues.length !== 0 && mean(airValues)

    dataToNormalize.forEach(selected => {
      selected.freshProperties.forEach(freshProperty => {
        slumpDiff = freshProperty.slump - avgSlump
        airDiff = freshProperty.air - avgAir
        const batchStrength = calculateBatchStrength(
          slumpDiff,
          airDiff,
          freshProperty.batchStrength
        )
        freshProperty.batchStrength = batchStrength.toFixed(0)
        freshProperty.slump = Number(avgSlump).toFixed(2)
        freshProperty.air = Number(avgAir).toFixed(2)
      })
    })
    setTableData(prevTableData => {
      return {
        ...prevTableData,
        selectedMixVariations: dataToNormalize,
      }
    })
    setFilteredData(dataToNormalize)
    setNormalizedData(dataToNormalize)
    setIsNormalizedButtonDisabled(true)
  }

  const radioSinglSelectChangeHandler = (filter, value) => {
    const newVal = value !== 'false'
    setAnalysisFrontendFilterSettings(prev => {
      return {
        ...prev,
        isMetric: newVal,
      }
    })
  }

  const viewDesignsFilterPanel = [
    {
      name: 'CUSTOMER',
      category: 'customer',
      filters: [
        {
          property: 'producer',
          name: 'Producer',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: producerList,
          onChangeHandler: setProducerValues,
          disabled: isCustomerDisabled,
        },
        {
          property: 'supplier',
          name: 'Supplier',
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: supplierList,
          onChangeHandler: setSupplierValues,
          placeholder: 'Select Cement Supplier',
        },
        {
          property: 'commissioning',
          name: 'Commissioning',
          componentType: FilterComponentTypes.CommissioningFilter,
          options: commissioningOptions,
          onChangeHandler: onCommissioningChangeHandler,
        },
      ],
    },
    {
      name: 'MIX DETAILS',
      category: 'mixDetails',
      filters: [
        {
          property: 'mixCode',
          name: 'Mix Code',
          componentType: FilterComponentTypes.SearchMixCodeField,
        },
        {
          property: 'showArchived',
          name: 'Show Archived',
          componentType: FilterComponentTypes.Checkbox,
          onChangeHandler: showArchivedChangeHandler,
          label: 'Archived Groups',
          checkboxIconType: 'visibility',
        },
      ],
    },
    {
      name: 'MEASUREMENT',
      category: 'isMetric',
      filters: [
        {
          property: 'isMetric',
          name: 'Measurements',
          componentType: FilterComponentTypes.RadioSingleSelect,
          options: measurementOptions,
          onChangeHandler: radioSinglSelectChangeHandler,
          isHorizontal: true,
          color: 'secondary',
        },
      ],
    },
  ]

  const getIsNormalizedButtonDisabled = () => {
    return isNormalizedButtonDisabled
  }

  const graphFilterPanel = [
    {
      name: 'GRAPH',
      category: 'graphs',
      filters: [
        {
          property: 'graphType',
          name: 'Graph Type',
          componentType: FilterComponentTypes.TextFieldSelect,
          options: graphTypeOptions,
          onChangeHandler: selectGraph,
          label: 'Graph Type',
        },
        {
          property: 'normalize',
          name: 'Normalize',
          componentType: FilterComponentTypes.Button,
          onClickHandler: normalize,
          label: 'Normalize',
          disabled: getIsNormalizedButtonDisabled,
        },
        {
          property: 'graphs',
          name: 'graphs',
          componentType: FilterComponentTypes.GraphFilters,
        },
      ],
    },
  ]

  const handleViewDesignsFilterButton = () => {
    setIsViewDesignsFilterPanelOpen(prev => !prev)
    setIsGraphFilterPanelOpen(false)
  }

  const handleCompareAndAnalyzeFilterButton = () => {
    setIsGraphFilterPanelOpen(prev => !prev)
    setIsViewDesignsFilterPanelOpen(false)
    const windowWidth = window.innerWidth
    if (windowWidth < 600) {
      compareAndAnalyzeHeadingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const handleCompareAndAnalyzeTablePageChange = (event, newPage) => {
    setCompareAndAnalyzeTableSettings(prev => {
      return {
        ...prev,
        page: newPage,
      }
    })
  }

  const handleCompareAndAnalyzeTableRowsPerPageChange = event => {
    setCompareAndAnalyzeTableSettings(prev => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const deleteAlerts = alert => {
    let remainingAlerts
    remainingAlerts = alerts.filter(
      a => a.mixDesignId !== alert.mixDesignId || a.name !== alert.name
    )
    setAlerts(remainingAlerts)
  }

  /* Generate a list of Alerts component */
  const renderAlerts = () => {
    let alertsArray = []
    alerts.forEach(alert => {
      alertsArray.push(
        <Grid
          key={`${alert.mixDesignId}-${alert.mixCode}-${alert.name}`}
          item
          align="left"
          style={{ width: '100%' }}
        >
          <Alert
            onClose={() => deleteAlerts(alert)}
            severity={alert.name.includes('Commissioning') ? 'info' : 'warning'}
            className={classes.alerts}
          >
            <AlertTitle>
              {' '}
              <b>{alert.name}</b>
            </AlertTitle>
            {!alert.name.includes('Commissioning') ? (
              <Typography>
                {' '}
                {alert.description} {alert.producer}
                {' - '}
                {' ' + alert.mixCode}
              </Typography>
            ) : null}
          </Alert>
        </Grid>
      )
    })

    return alertsArray
  }

  const getMixGroupFilterCount = useCallback(() => {
    let count = 0
    for (const setting in analysisFilterSettings) {
      if (setting !== 'showArchived') {
        count += analysisFilterSettings[setting].length
        continue
      }
      if (analysisFilterSettings['showArchived'][0] === 'true') count += 1
    }
    return count
  }, [analysisFilterSettings])

  /* set the producer filter if customer is selected on any other page*/
  useEffect(() => {
    if (currentCustomer.division) {
      setSelectedProducer(currentCustomer.division.divisionId)
    }
  }, [currentCustomer, setSelectedProducer])

  /* Scroll to the batch data table when a point in graph is selected*/
  useEffect(() => {
    if (scroll && batchRef.current) batchRef.current.scrollIntoView()
  }, [scroll])

  useEffect(() => {
    if (!isFirstRender) {
      const settings = {
        ...analysisFilterSettings,
        ...analysisFrontendFilterSettings,
      }
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(settings, 'Designs')
      )
      const count = getMixGroupFilterCount()
      setFilterCount(count)
    } else {
      // FIRST RENDER
      const params = new URLSearchParams(search)
      const settings = {
        ...analysisFilterSettings,
        ...analysisFrontendFilterSettings,
      }
      const newSettings = getNewSettings(settings, params)
      const newSettingsCopy = cloneDeep(newSettings)
      delete newSettings.isMetric
      if (newSettings['producer'][0])
        newSettings['producer'][0] = Number(newSettings['producer'][0])
      if (currentCustomer.division)
        newSettings['producer'][0] = currentCustomer.division.divisionId
      for (let i = 0; i < newSettings['supplier'].length; i++) {
        newSettings['supplier'][i] = Number(newSettings['supplier'][i])
      }
      setAnalysisFrontendFilterSettings(prevSettings => {
        return {
          ...prevSettings,
          isMetric: newSettingsCopy.isMetric,
        }
      })
      setAnalysisFilterSettings(newSettings)
      setIsFirstRender(false)
    }
  }, [
    analysisFilterSettings,
    analysisFrontendFilterSettings,
    isFirstRender,
    search,
    setAnalysisFilterSettings,
    currentCustomer,
    mixDesignTableSettings.showArchived,
    getMixGroupFilterCount,
  ])

  const getUnit = useCallback(
    property => {
      const isMetric = analysisFrontendFilterSettings.isMetric
      if (property === 'slump') {
        return isMetric ? 'mm' : 'in'
      } else if (property === 'strength') {
        return isMetric ? 'MPa' : 'psi'
      } else if (property === 'temperature') {
        return isMetric ? 'C' : 'F'
      } else if (property === 'cementContent') {
        return isMetric ? 'kg' : 'lb'
      } else {
        return ''
      }
    },
    [analysisFrontendFilterSettings.isMetric]
  )

  useEffect(() => {
    const abortController = new AbortController()
    const { page, rowsPerPage, orderBy, order } = mixDesignTableSettings
    const showArchived = analysisFilterSettings['showArchived'][0] === 'true'
    let archiveFilter
    if (showArchived) {
      archiveFilter = 'IncludeArchived'
    } else {
      archiveFilter = 'OnlyActive'
    }
    let constraints = {
      Limit: rowsPerPage,
      Offset: page * rowsPerPage,
      SortOrder: order === 'asc' ? 'Ascending' : 'Descending',
      SortColumn: orderBy,
      includeDesignsWithoutSamples: false,
      mixCode: analysisFilterSettings['mixCode'][0]
        ? analysisFilterSettings['mixCode'][0]
        : '',
      onlyInCommissioning:
        analysisFilterSettings['commissioning'][0] ===
        CommissioningFilterOptions.InCommissioning,
      archiveFilter: archiveFilter,
    }

    let selectedProducerList = analysisFilterSettings['producer'][0]
      ? analysisFilterSettings['producer']
      : []

    let arrayParams = {
      divisionIds: selectedProducerList,
      cementPlantIds: analysisFilterSettings['supplier'],
    }

    /* Fetching data from Kelowna backend */
    setIsLoading(true)
    getMixGroupByBatch(constraints, arrayParams, abortController)
      .then(result => {
        if (result.ok)
          result.json().then(({ count, results }) => {
            if (results) {
              const { mixDesignData } = digestMixDesigns(results)
              setTableData(prevTableData => {
                return {
                  ...prevTableData,
                  digestedMixDesigns: mixDesignData,
                }
              })
              setMixDesignCount(count)
            }
            setIsLoading(false)
            setIsMetricFlag(prev => !prev)
          })
      })
      .catch(e => {
        //aborted fetch requests are fine, we can ignore them since we purposefully abort
        if (!isAbortedFetchError(e)) {
          console.error(e)
        }
      })

    setIsLoading(true)
    getSupplierNames()
      .then(result => {
        if (result.ok)
          result.json().then(data => {
            setSupplierList(data)
            setIsLoading(false)
          })
      })
      .catch(e => console.log(e))

    setIsLoading(true)
    getDivisionNames(true)
      .then(result => {
        if (result.ok)
          result.json().then(data => {
            setProducerList(data)
            setIsLoading(false)
          })
      })
      .catch(e => console.log(e))

    return () => {
      abortController.abort()
    }
  }, [
    currentCustomer,
    selectedSupplier,
    mixDesignTableSettings,
    analysisFilterSettings,
  ])

  useEffect(() => {
    const isMetric = analysisFrontendFilterSettings.isMetric
    setTableData(prevTableData => {
      const {
        digestedMixDesigns,
        selectedMixVariations,
        selectedMixDesigns,
        compareAndAnalyzeMixDesigns,
      } = prevTableData
      const {
        convertedMixDesigns,
        convertedSelectedVariations,
        convertedSelectedMixDesigns,
        convertedCompareAndAnalyzeMixDesigns,
      } = convertAnalysisMixDataUnit(
        isMetric,
        digestedMixDesigns,
        selectedMixVariations,
        selectedMixDesigns,
        compareAndAnalyzeMixDesigns
      )

      return {
        ...prevTableData,
        digestedMixDesigns: convertedMixDesigns,
        selectedMixVariations: convertedSelectedVariations,
        selectedMixDesigns: convertedSelectedMixDesigns,
        compareAndAnalyzeMixDesigns: convertedCompareAndAnalyzeMixDesigns,
      }
    })

    setBatchData(prevBatchData => {
      const convertedBatchData = convertAnalysisBatchDataUnit(
        isMetric,
        prevBatchData
      )
      const batchDataArr = [{ value: convertedBatchData }]
      return batchDataArr
    })
  }, [isMetricFlag, analysisFrontendFilterSettings])

  const intervalChipValid = useCallback(() => {
    return graphType !== 'Strength Development' && graphType !== 'Bar'
  }, [graphType])

  /** Enable the normalize button on different mix design selection */
  useEffect(() => {
    tableData.selectedMixVariations.forEach(data => {
      if (normalizedData.indexOf(data) === -1)
        setIsNormalizedButtonDisabled(false)
    })
  }, [tableData.selectedMixVariations, normalizedData])

  const removeIntervalFilter = filters => {
    const batchIntervalFilter = filters.find(
      filter => filter.property === 'batchInterval'
    )
    batchIntervalFilter.selectedOptions = []
  }

  const deleteAllChipsHandler = () => {
    setSelectedGraphFilters(prevFilters => {
      const filtersCopy = cloneDeep(prevFilters)
      const newFilters = filtersCopy.filter(filter => filter.defaultFilter)
      if (intervalChipValid()) removeIntervalFilter(newFilters)
      return newFilters
    })
  }

  const deleteChipHandler = filterToDelete => {
    setSelectedGraphFilters(prevFilters => {
      const filtersCopy = cloneDeep(prevFilters)
      let newFilters
      if (filterToDelete.property !== 'batchInterval')
        newFilters = filtersCopy.filter(
          filter => filter.property !== filterToDelete.property
        )
      else {
        removeIntervalFilter(filtersCopy)
        newFilters = filtersCopy
      }
      return newFilters
    })
    updateSelectedAge('')
  }

  useEffect(() => {
    const filterChips = []
    for (const filter of selectedGraphFilters) {
      switch (filter.property) {
        case 'batchInterval':
          if (filter.selectedOptions[0] && intervalChipValid())
            filterChips.push({
              property: filter.property,
              label: `Interval: ${filter.selectedOptions[0]}`,
            })
          break
        case 'slump':
          filterChips.push({
            property: filter.property,
            label: `Slump (${getUnit('slump')}): ${filter.selectedMinValue}-${
              filter.selectedMaxValue
            }`,
          })
          break
        case 'air':
          filterChips.push({
            property: filter.property,
            label: `Air Cont. (%): ${filter.selectedMinValue}-${filter.selectedMaxValue}`,
          })
          break
        case 'ambientTemperature':
          filterChips.push({
            property: filter.property,
            label: `Ambient Temp. (${getUnit('temperature')}): ${
              filter.selectedMinValue
            }-${filter.selectedMaxValue}`,
          })
          break
        case 'concreteTemperature':
          filterChips.push({
            property: filter.property,
            label: `Concrete Temp. (${getUnit('temperature')}): ${
              filter.selectedMinValue
            }-${filter.selectedMaxValue}`,
          })
          break
        case 'batchWaterCementRatio':
          filterChips.push({
            property: filter.property,
            label: `W/CM: ${filter.selectedMinValue}-${filter.selectedMaxValue}`,
          })
          break
        case 'batchCementContent':
          filterChips.push({
            property: filter.property,
            label: `Cement Cont. (${getUnit('cementContent')}): ${
              filter.selectedMinValue
            }-${filter.selectedMaxValue}`,
          })
          break
        case 'co2DosageLabel':
          filterChips.push({
            property: filter.property,
            label: `CO₂ Dos.: ${filter.selectedMinValue}-${filter.selectedMaxValue}`,
          })
          break
        case 'batchStrength':
          filterChips.push({
            property: filter.property,
            label: `Batch Str. (${getUnit('strength')}): ${
              filter.selectedMinValue
            }-${filter.selectedMaxValue}`,
          })
          break
        case 'dataSetSize':
          filterChips.push({
            property: filter.property,
            label: `Data Set Size: ${filter.selectedMinValue}-${filter.selectedMaxValue}`,
          })
          break
        case 'productionDate':
          filterChips.push({
            property: filter.property,
            label: `Production Date: ${filter.selectedMinValue} To ${filter.selectedMaxValue}`,
          })
          break
        case 'testCategory':
          filterChips.push({
            property: filter.property,
            label: filter.selectedOptions[0].replace(
              /([a-z0-9])([A-Z])/g,
              '$1 $2'
            ),
          })
          break
        default:
          break
      }
    }
    setActiveGraphFilters(filterChips)
  }, [
    selectedGraphFilters,
    graphType,
    intervalChipValid,
    analysisFrontendFilterSettings.isMetric,
    getUnit,
  ])

  useEffect(() => {
    let count
    const nonDefaultFilters = selectedGraphFilters.filter(
      filter => !filter.defaultFilter && filter.property
    )
    const batchIntervalFilter = selectedGraphFilters.find(
      filter => filter.property === 'batchInterval'
    )
    const isIntervalActive = batchIntervalFilter?.selectedOptions?.length > 0
    if (isIntervalActive && intervalChipValid()) {
      count = nonDefaultFilters.length + 1
    } else {
      count = nonDefaultFilters.length
    }
    setGraphFilterCount(count)
  }, [graphType, selectedGraphFilters, intervalChipValid])

  // Reset atom to default when switching pages
  useEffect(() => {
    return () => {
      resetSelectedGraphFilters()
    }
  }, [resetSelectedGraphFilters])

  const getCommonVariationIds = variations => {
    let variationIds = []
    let selectedVariationIds = []
    for (const variation of variations) {
      variationIds.push(variation.variationId)
    }
    for (const selectedMixVariation of tableData.selectedMixVariations) {
      selectedVariationIds.push(selectedMixVariation.variationId)
    }
    return variationIds.filter(e => selectedVariationIds.indexOf(e) > -1)
  }

  const deselectVariation = (mixDesign, id, commonIds) => {
    let count = 0
    for (const variation of tableData.selectedMixVariations) {
      if (mixDesign.mixDesignId === variation.mixDesignId) count++
    }
    if (count === 1) {
      const newCompareAndAnalyzeMixDesigns = {
        ...tableData.compareAndAnalyzeMixDesigns,
      }
      delete newCompareAndAnalyzeMixDesigns[mixDesign.mixDesignId]
      setTableData(prevTableData => {
        return {
          ...prevTableData,
          compareAndAnalyzeMixDesigns: newCompareAndAnalyzeMixDesigns,
        }
      })
    }
    // if the clicked mix variation row was already selected, deselect it.
    // if not all of the mix groups variations will be selected, deselect the mix group row automatically.
    if (commonIds.length === mixDesign.variations.length) {
      setTableData(prevTableData => {
        return {
          ...prevTableData,
          selectedMixDesigns: prevTableData.selectedMixDesigns.filter(
            design => design.mixDesignId !== mixDesign.mixDesignId
          ),
        }
      })
    }
    setTableData(prevTableData => {
      return {
        ...prevTableData,
        selectedMixVariations: prevTableData.selectedMixVariations.filter(
          variation => variation.variationId !== id
        ),
      }
    })
  }

  const handleVariationClick = id => {
    // find the variation and its parent mix group
    let variation = tableData.selectedMixVariations.find(
      variation => variation.variationId === id
    )
    const mixDesign =
      tableData.compareAndAnalyzeMixDesigns[variation.mixDesignId]
    const commonIds = getCommonVariationIds(mixDesign.variations)
    deselectVariation(mixDesign, id, commonIds)
  }

  /* Function to sort table rows */
  const handleRequestSort = (event, property) => {
    const { orderBy, order } = mixDesignTableSettings
    const isAsc = orderBy === property && order === 'asc'
    setMixDesignTableSettings(prevSettings => {
      return {
        ...prevSettings,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      }
    })
  }

  function onCollapseToggle(mixDesignId) {
    setCollapsedMixDesigns(xor(collapsedMixDesigns, [mixDesignId]))
  }

  useEffect(() => {
    const mixDesignsForOptimization = getMixDesignsForOptimization(
      tableData.compareAndAnalyzeMixDesigns
    )
    setMixDesignsForOptimization(mixDesignsForOptimization)
    setIsOptimizationMenuOpen(mixDesignsForOptimization.length > 0)
  }, [tableData.compareAndAnalyzeMixDesigns, setIsOptimizationMenuOpen])

  /** Fetch the alerts array on mix design change */
  useEffect(() => {
    let alertArray = GetAlerts(tableData.selectedMixVariations)
    setAlerts(alertArray)
  }, [setAlerts, tableData.selectedMixVariations])

  useEffect(() => {
    setCompareAndAnalyzeTableSettings(prevTableSettings => {
      const newPage = getNewCurrentPage(
        tableData.selectedMixVariations.length,
        prevTableSettings.rowsPerPage,
        prevTableSettings.page
      )
      return {
        ...prevTableSettings,
        page: newPage,
      }
    })
  }, [tableData.selectedMixVariations])

  const compareAndAnalyzeMixDesignsKeys = Object.keys(
    tableData.compareAndAnalyzeMixDesigns
  )
  let path
  if (compareAndAnalyzeMixDesignsKeys.length === 1) {
    path = getAnalysisIdentifyOutliersUrl(
      tableData,
      compareAndAnalyzeMixDesignsKeys,
      selectedAge
    )
  }
  return (
    <React.Fragment>
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em', position: 'relative' }}
      >
        <div className={classes.filterPanelContainer}>
          <FilterPanelLogical
            open={isViewDesignsFilterPanelOpen}
            setOpen={setIsViewDesignsFilterPanelOpen}
            expandFilters={expandFilters}
            setExpandFilters={setExpandFilters}
            filterPanel={viewDesignsFilterPanel}
            parentClasses={classes}
            getValue={getValue}
            helperVariables={helperVariables}
            filtersClasses={filtersClasses}
          />
          <div
            className={
              isViewDesignsFilterPanelOpen || isGraphFilterPanelOpen
                ? classes.wrapperShifted
                : classes.wrapperUnshifted
            }
          >
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="h2" align="left">
                  Analysis
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" align="left">
                  Mix Designs
                </Typography>
                <Typography align="left" style={{ marginBottom: '0%' }}>
                  Select a mix design to start analysis. Select two mixes to
                  start comparing.
                </Typography>
                <Box mb={-3}>
                  <Typography
                    align="left"
                    style={{
                      marginTop: '0px',
                      color: baseColors.text.secondary,
                    }}
                  >
                    The calculations (Required Strength, Over Design, etc.) in
                    the following table are based on 28 day data
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Box mt={2}>
                  <MixDesignTableLogical
                    rows={tableData.digestedMixDesigns}
                    selectedMixDesigns={tableData.selectedMixDesigns}
                    selectedMixVariations={tableData.selectedMixVariations}
                    supplierList={supplierList}
                    producerList={producerList}
                    selectedProducer={selectedProducer}
                    setSelectedProducer={setSelectedProducer}
                    selectedSupplier={selectedSupplier}
                    setSelectedSupplier={setSelectedSupplier}
                    rowCount={mixDesignCount}
                    onCollapseToggle={onCollapseToggle}
                    expanded={collapsedMixDesigns}
                    handleRequestSort={handleRequestSort}
                    tableType={SimpleTableTypes.ViewDesignMixGroup}
                    parentClasses={classes}
                    tableData={tableData}
                    setTableData={setTableData}
                    tableSettings={mixDesignTableSettings}
                    setTableSettings={setMixDesignTableSettings}
                    analysisFilterSettings={analysisFilterSettings}
                    setAnalysisFilterSettings={setAnalysisFilterSettings}
                    isFilterPanelOpen={isViewDesignsFilterPanelOpen}
                    onFilterBtnClickHandler={handleViewDesignsFilterButton}
                    filterCount={filterCount}
                    isMetric={analysisFrontendFilterSettings.isMetric}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className={
              isGraphFilterPanelOpen || isViewDesignsFilterPanelOpen
                ? classes.wrapperShifted
                : classes.wrapperUnshifted
            }
          >
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Box mt={-1} mb={1}>
                  <Typography
                    variant="h2"
                    align="left"
                    ref={compareAndAnalyzeHeadingRef}
                  >
                    Compare & Analyze
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Box mb={-1}>
                  <Paper variant="outlined">
                    <SimpleTablePresentational
                      onSort={handleRequestSort}
                      onToggle={event => {
                        handleVariationClick(event)
                      }}
                      selected={tableData.selectedMixVariations.map(e => {
                        return e.variationId
                      })}
                      selectionType={SimpleTableSelection.Multiple}
                      headCells={headCellsMixDesigns}
                      rows={mixVariationsToMixVariationsRows(
                        tableData.selectedMixVariations,
                        analysisFrontendFilterSettings.isMetric,
                        compareAndAnalyzeTableSettings
                      )}
                      parentClasses={classes}
                      tableType={SimpleTableTypes.ViewDesignMixGroup}
                      rowCount={tableData.selectedMixVariations.length}
                      rowsPerPage={compareAndAnalyzeTableSettings.rowsPerPage}
                      rowsPerPageOptions={[10, 20, 35]}
                      page={compareAndAnalyzeTableSettings.page}
                      onChangePage={handleCompareAndAnalyzeTablePageChange}
                      onChangeRowsPerPage={
                        handleCompareAndAnalyzeTableRowsPerPageChange
                      }
                      enablePagination={true}
                    />
                  </Paper>
                </Box>
              </Grid>
              {alerts.length > 0 ? (
                <Grid xs={12} item>
                  <Box mb={4} mt={1}>
                    <Typography
                      style={{ marginBottom: '2%', fontWeight: 'bold' }}
                      variant="h3"
                      align="left"
                    >
                      Alerts
                    </Typography>
                  </Box>
                  {renderAlerts()}
                </Grid>
              ) : null}
              <Grid item xs>
                {isOptimizationMenuOpen && tssCanWrite(JWT.roles) && (
                  <>
                    <Box mb={3} mt={1}>
                      <Typography variant="h3" align="left">
                        Optimization
                      </Typography>
                      <SelectOptimizationPrompt
                        isOptimizationMenuOpen={isOptimizationMenuOpen}
                        setIsOptimizationMenuOpen={setIsOptimizationMenuOpen}
                        mixDesignsForOptimization={mixDesignsForOptimization}
                        isMetric={analysisFrontendFilterSettings.isMetric}
                      />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div className={classes2.graphFilterPanelContainer}>
            <FilterPanelLogical
              open={isGraphFilterPanelOpen}
              setOpen={setIsGraphFilterPanelOpen}
              expandFilters={expandGraphFilters}
              setExpandFilters={setExpandGraphFilters}
              filterPanel={graphFilterPanel}
              parentClasses={classes2}
              getValue={getValue}
              helperVariables={graphHelperVariables}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <div
              className={
                isGraphFilterPanelOpen || isViewDesignsFilterPanelOpen
                  ? classes.wrapperShifted
                  : classes.wrapperUnshifted
              }
            >
              <Grid container direction="column" spacing={4}>
                <Grid item container justify="space-between">
                  <FilterButton
                    open={isGraphFilterPanelOpen}
                    parentClasses={classes}
                    filterCount={graphFilterCount}
                    onClickHandler={handleCompareAndAnalyzeFilterButton}
                  />
                  {compareAndAnalyzeMixDesignsKeys.length > 0 && (
                    <>
                      <Tooltip
                        title={
                          compareAndAnalyzeMixDesignsKeys.length > 1
                            ? 'Select a single mix group to analyze using Baleen'
                            : ''
                        }
                      >
                        <span>
                          <Button
                            className={classes.baleenButton}
                            color="primary"
                            size="medium"
                            component={Link}
                            variant="outlined"
                            disabled={
                              compareAndAnalyzeMixDesignsKeys.length > 1
                            }
                            target="_blank"
                            to={`/Concrete/Baleen?${path}`}
                          >
                            <img
                              src={
                                compareAndAnalyzeMixDesignsKeys.length > 1
                                  ? AnalyticsOutlined_Disabled
                                  : AnalyticsOutlined_Enabled
                              }
                              alt="Analytics Icon"
                              style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '10px',
                              }}
                            />
                            Identify Outliers With Baleen
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  <FilterPanelChips
                    activeFilters={activeGraphFilters}
                    chipClickHandler={deleteChipHandler}
                    buttonClickHandler={deleteAllChipsHandler}
                  />
                </Grid>
                <Grid item xs>
                  <Box mt={-5}>
                    <Graphs
                      selectedMixVariations={tableData.selectedMixVariations}
                      setSelectedMixVariations={
                        tableData.setSelectedMixVariations
                      }
                      setTableData={setTableData}
                      batchData={batchData}
                      setBatchData={setBatchData}
                      rows={tableData.digestedMixDesigns}
                      setScroll={setScroll}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      graphType={graphType}
                      setGraphType={setGraphType}
                      selectedAge={selectedAge}
                      updateSelectedAge={updateSelectedAge}
                      isFilterPanelOpen={
                        isViewDesignsFilterPanelOpen || isGraphFilterPanelOpen
                      }
                      isMetric={analysisFrontendFilterSettings.isMetric}
                      page={VariabilityGraphPage.ANALYSIS}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={-1} mb={1}>
                    <Typography variant="h3" align="left">
                      <b>Selected Batch Data</b>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs>
                  <div ref={batchRef}>
                    <BatchDataTable
                      batchData={batchData}
                      setBatchData={setBatchData}
                      isMetric={analysisFrontendFilterSettings.isMetric}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default ViewDesignPage
