import React from 'react'
import PropTypes from 'prop-types'
import CountrySelectPresentational from './CountrySelectPresentational'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'
import { EMapLayers } from '../../Enums/EchoEnums'
import { comparedSort } from '../../../Common/Helpers/GeneralHelpers'

CountrySelectLogical.propTypes = {
  /** Filtered Echo data */
  filteredCorpData: PropTypes.array.isRequired,
}

function CountrySelectLogical(props) {
  const { filteredCorpData } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  let uniqueCountries = new Set()
  filteredCorpData.forEach(corp => {
    corp.divisions.forEach(division => {
      division.plants.forEach(plant => {
        if (plant.country != null) uniqueCountries.add(plant.country)
      })
      division.countries.forEach(country => {
        if (country.country != null) uniqueCountries.add(country.country)
      })
    })
  })

  /** Transform set to sorted array */
  uniqueCountries = [...uniqueCountries].sort(comparedSort)

  /** Handle input change. Pass selected filter value to useEffect in EchoHome. */
  const handleChange = (event, value) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.countries = value ? [value] : []
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.countries = value ? [value] : []
      setEchoSettings(newEchoSettings)
    }
  }

  let dropdownValue = null
  if (
    echoSettings.layers.includes(EMapLayers.projectSearch) &&
    projectSearchValues.countries.length
  ) {
    dropdownValue = projectSearchValues.countries[0]
  } else if (
    !echoSettings.layers.includes(EMapLayers.projectSearch) &&
    echoSettings.countries.length
  ) {
    dropdownValue = echoSettings.countries[0]
  }

  return (
    <CountrySelectPresentational
      uniqueCountries={uniqueCountries}
      handleChange={handleChange}
      dropdownValue={dropdownValue}
    />
  )
}

export default CountrySelectLogical
