import React, { useState, useEffect, useMemo } from 'react'
// @ts-ignore
import { Prompt, useHistory, useLocation, Location } from 'react-router-dom'
import AddDataProgressLostAlertPresentational from './AddDataProgressLostAlertPresentational'
import { IManualPrompt } from '../../Logic/Types'

export interface IAddDataProgressLostAlertLogicalProps {
  hasUserInput: boolean
  /** Optional. Allows for manual triggering of prompt instead of only URL change  */
  manualPrompt?: IManualPrompt | null
  setManualPrompt?: (arg0: any) => void
}

function AddDataProgressLostAlertLogical(
  props: IAddDataProgressLostAlertLogicalProps
) {
  const { hasUserInput, manualPrompt, setManualPrompt } = props

  const history = useHistory()
  const location = useLocation()

  const [showAlert, setShowAlert] = useState(false)
  const [lastLocation, setLastLocation] = useState(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmLeavePage, setConfirmLeavePage] = useState(false)

  // Pathnames that render different pages without changing URLs. useMemo stops this from changing every render
  const staticPages = useMemo(() => {
    return ['/TSS/MaterialManager']
  }, [])

  const showModal = (nextLocation: Location) => {
    setShowAlert(true)
    setLastLocation(nextLocation)
  }

  const closeModal = () => {
    setShowAlert(false)
    setShouldUnload(false)
    // call function only if optional prop is passed in
    if (typeof setManualPrompt === 'function') {
      setManualPrompt({ show: false, view: '' })
    }
  }

  const handleBlockedRoute = (nextLocation: Location) => {
    if (hasUserInput && !confirmLeavePage) {
      showModal(nextLocation)
      return false
    }

    return true
  }

  const handleLeavePage = () => {
    closeModal()
    setConfirmLeavePage(true)
    // Checks if page is static and changes views like Material Manager. Triggers reload.
    if (
      staticPages.includes(location.pathname) &&
      location.pathname === lastLocation.pathname
    ) {
      window.location.reload()
    }
  }

  // Block navigation unless user confirm leaving page
  useEffect(() => {
    if (confirmLeavePage && lastLocation) {
      setShouldUnload(true)
      history.push(lastLocation.pathname)
    }
  }, [confirmLeavePage, lastLocation, history])

  // Block refresh or closing browser
  useEffect(() => {
    const cancelEventListener = () => {
      // Override Event Listeners during navigation for static pages like Material Manager
      if (showAlert && staticPages.includes(location.pathname)) return true
      return false
    }
    if (!cancelEventListener()) {
      const unload = (event: BeforeUnloadEvent) => {
        if (hasUserInput && !shouldUnload) {
          event.returnValue =
            'By leaving this page, any progress you have made will be lost.'
        }
        if (shouldUnload) {
          event.returnValue = ''
        }
      }
      window.addEventListener('beforeunload', unload)

      return () => window.removeEventListener('beforeunload', unload)
    }
  }, [
    hasUserInput,
    lastLocation.pathname,
    location.pathname,
    shouldUnload,
    showAlert,
    staticPages,
  ])

  // Show alert manually for Material Manager
  useEffect(() => {
    const materialPath = staticPages[0]
    if (location.pathname !== materialPath) return
    if (hasUserInput && manualPrompt?.show) {
      setShowAlert(true)
      lastLocation.pathname = materialPath
    }
  }, [hasUserInput, manualPrompt, location, lastLocation, staticPages])

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <AddDataProgressLostAlertPresentational
        showAlert={showAlert}
        closeModal={closeModal}
        handleLeavePage={handleLeavePage}
      />
    </>
  )
}

export default AddDataProgressLostAlertLogical
