import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import React from 'react'
import {
  SimpleTableComplexCell,
  SimpleTableRow,
} from '../../../Common/Logic/Types'
import helper from './cementTableCollapsibleRowHelper'

export interface ICementTableCollapsibleRow {
  row: SimpleTableRow
  displayCell: (
    rowCell:
      | string
      | number
      | SimpleTableComplexCell
      | null
      | undefined
      | Array<string>,
    id: string | number,
    key: string
  ) => string | number | JSX.Element | JSX.Element[] | null | undefined
}

const useStyles = makeStyles(() => ({
  tableHeadRow: {
    backgroundColor: 'rgba(112, 112, 112, 0.04)',
  },
  tableBodyRow: {
    backgroundColor: 'rgba(112, 112, 112, 0.08)',
  },
  ...helper.getTableCellStyles(),
}))

const CementTableCollapsibleRow = (props: ICementTableCollapsibleRow) => {
  const { row, displayCell } = props
  const classes = useStyles()
  const headCells = helper.getCollapsibleHeadCells(row.isMetric)
  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableHeadRow}>
          {headCells.map(cell => (
            <Tooltip key={cell.id} title={cell.tooltip ? cell.tooltip : ''}>
              <TableCell
                key={cell.id}
                className={
                  classes[
                    `${cell.id}-headCell` as 'tableHeadRow' | 'tableBodyRow'
                  ]
                }
              >
                {cell.name}
              </TableCell>
            </Tooltip>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBodyRow}>
        {row.collapsibleRows?.map(collapsibleRow => (
          <TableRow key={collapsibleRow.id}>
            {headCells.map((headCell, index) => (
              <TableCell
                key={headCell.id}
                className={
                  classes[
                    `${headCell.id}-bodyCell` as 'tableHeadRow' | 'tableBodyRow'
                  ]
                }
              >
                {displayCell(
                  collapsibleRow.cells[index],
                  collapsibleRow.id,
                  ''
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CementTableCollapsibleRow
