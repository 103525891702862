import React, { useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Filter, FilterOption } from '../../Common/Logic/Types'
import CustomerAndLocationSelect from './CustomerAndLocationSelect'
import MixGroupOptionWithBaselineConditions, {
  IBaselineMixGroups,
} from './MixGroupOptionWithBaselineConditions'
import { ICreateReportSettings } from '../Logic/Types'
import { useRecoilState } from 'recoil'
import { atomCustomerOptions } from '../../Common/tssAtomsB'
import { useCustomerOptions, useMixGroupOptions } from '../Logic/Hooks'
import breakpoints from '../../theme/breakpoints'

const useStyles = makeStyles(theme => ({
  modal: {
    padding: '16px 32px 32px',
  },
  selectContainer: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  mixGroupSelect: {
    [theme.breakpoints.down(Number(breakpoints.sm))]: {
      minWidth: '100%',
      marginLeft: 0,
      marginTop: '16px',
    },
    [theme.breakpoints.up(Number(breakpoints.sm))]: {
      minWidth: '32%',
      marginLeft: '16px',
    },
  },
}))
export interface ICreateReportModalProps {
  readonly modalOpen: boolean
  readonly setModalOpen: (arg0: boolean) => void
  currentCustomer: any
  setCurrentCustomer: (arg0: any) => void
  currentMixGroup: any
  setCurrentMixGroup: (arg0: any) => void
}

function CreateReportModal(props: ICreateReportModalProps) {
  const {
    modalOpen,
    setModalOpen,
    currentCustomer,
    setCurrentCustomer,
    currentMixGroup,
    setCurrentMixGroup,
  } = props
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const [mixGroupOptions, setMixGroupOptions] = useState<FilterOption[]>([])

  const [createReportSettings, setCreateReportSettings] = useState<
    ICreateReportSettings
  >({
    divisionId: currentCustomer.division?.divisionId
      ? [currentCustomer.division?.divisionId]
      : [],
    plantId: currentCustomer.plant?.plantId
      ? [currentCustomer.plant?.plantId]
      : [],
    mixGroup: currentMixGroup,
  })

  let { path } = useRouteMatch()
  const history = useHistory()

  /** Custom hook to initialize customer options dropdown */
  useCustomerOptions(customerOptions, setCustomerOptions, setIsLoading)

  /** Custom hook to get Mix Group options for autocomplete */
  useMixGroupOptions(currentCustomer, setMixGroupOptions, setIsLoading)

  // Function to setState when a mix is selected
  const handleMixGroupChange = (_: Filter, mixGroup: FilterOption) => {
    const selectedMixGroup = mixGroup ? [mixGroup.id] : []
    setCreateReportSettings(prevSettings => ({
      ...prevSettings,
      mixGroup: selectedMixGroup,
    }))
    setCurrentMixGroup(prevSettings => ({
      ...prevSettings,
      mixGroup: selectedMixGroup,
    }))
  }

  // Function to close the modal and clear settings
  const handleCancel = () => {
    setModalOpen(false)
    setCreateReportSettings({
      divisionId: [],
      plantId: [],
      mixGroup: [],
    })
    setCurrentCustomer({
      divisionId: [],
      plantId: [],
    })
    setCurrentMixGroup({
      mixGroup: [],
    })
  }

  // Function to navigate to new report page
  const handleCreateBtnClick = () => {
    history.push(`${path}/New`)
  }

  // Function to get the value for selected mix group option
  const getValue = () => {
    const selectedOption = mixGroupOptions.find(
      option => option.id === createReportSettings.mixGroup[0]
    )
    return selectedOption ?? null
  }

  // Boolean to determine whether or not the mix group select is disabled
  const isMixGroupSelectDisabled = !(
    createReportSettings.divisionId.length &&
    createReportSettings.plantId.length
  )
  // Boolean to determine whether or not the create report button is disabled
  const isCreateBtnDisabled = !(
    createReportSettings.divisionId.length &&
    createReportSettings.plantId.length &&
    createReportSettings.mixGroup.length
  )

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCancel}
      fullWidth
      disableBackdropClick
      maxWidth="md"
    >
      <Backdrop open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Paper className={classes.modal} variant="outlined">
        <Typography variant="h3">Create Commissioning Report</Typography>
        <Typography variant="body1" style={{ margin: '24px 0px' }}>
          Select report information to continue.
        </Typography>
        <Grid container className={classes.selectContainer}>
          <CustomerAndLocationSelect
            showPlants
            inputsAlwaysEnabled={true}
            isAssociationView={false}
            sizes={{ xs: 12, sm: 6, md: 6, lg: 6, spacing: 2 }}
            addDataSettings={createReportSettings}
            setAddDataSettings={setCreateReportSettings}
            isLocationRequired={true}
          />
          <Autocomplete
            id={'mixGroupSelect'}
            size="small"
            options={mixGroupOptions}
            value={getValue()}
            onChange={(_event, value) => {
              handleMixGroupChange(_, value)
            }}
            getOptionLabel={option => option.name}
            className={classes.mixGroupSelect}
            renderInput={params => (
              <TextField
                {...params}
                label={'Mix Group *'}
                placeholder="Select A Mix Group"
                variant="outlined"
              />
            )}
            disabled={isMixGroupSelectDisabled}
            data-testid={`mixGroupSelect`}
            renderOption={(option: IBaselineMixGroups) => (
              <MixGroupOptionWithBaselineConditions
                option={option}
                forceBaselineData={true}
              />
            )}
          />
        </Grid>
        <Grid
          container
          justify="flex-end"
          spacing={2}
          style={{ marginTop: '24px' }}
        >
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreateBtnClick}
              disabled={isCreateBtnDisabled}
            >
              Create Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default CreateReportModal
