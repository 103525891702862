import { MixGroupVariation } from '../../Logic/Types'

export const generateErrorMessages = (
  selectedMixVariations: MixGroupVariation[],
  errorThreshold: number,
  warningThreshold: number
): Array<string> => {
  return selectedMixVariations
    .map(mixVariation => {
      if (mixVariation.sampleCount < errorThreshold) {
        return `${mixVariation.locationName} - ${mixVariation.variationIdLabel} only has ${mixVariation.sampleCount} samples available`
      } else if (mixVariation.sampleCount < warningThreshold) {
        return `${mixVariation.locationName ?? 'No Plant Name'} - ${
          mixVariation.mixCode
        } only has ${mixVariation.sampleCount} samples available`
      }
      return ''
    })
    .filter(errorMessage => errorMessage !== '')
}
