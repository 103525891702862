import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { Tooltip, Typography } from '@material-ui/core'
import {
  BaleenIntervals,
  BaleenMixVariation,
  VariationTypes,
} from '../Logic/Types'
import { FilterOption } from '../../Common/Logic/Types'
import { IBaleenFrontendFilterSettings } from '../Views/BaleenView'
import { roundUpToDecimal } from '../../Common/Helpers/GeneralHelpers'
import { baseColors } from '../../theme/colors'
import {
  getCO2DosagePrecision,
  getIntervalHeader,
  getPropertyPrecision,
  getPropertyUnit,
} from '../Helpers/BaleenHelpers'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 800,
    tableLayout: 'fixed',
  },
  tableCell: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  variationLabel: {
    display: 'flex',
  },
  variationDotContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '8px',
  },
  variationDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
}))

export interface IBaleenVariationTable {
  selectedDataColumns: FilterOption[]
  selectedMixVariations: BaleenMixVariation[]
  frontendFilterSettings: IBaleenFrontendFilterSettings
  mixVariations: BaleenMixVariation[]
}

const getTitle = (
  dataColumnOption: { name: string; id: string },
  interval: BaleenIntervals
) => {
  if (dataColumnOption.id === 'strengths') {
    if (!interval) return 'Strength'
    return `${getIntervalHeader(interval)} (% to Baseline)`
  } else if (dataColumnOption.id === 'cementContent') {
    return 'Avg. Cement Load. (% to Baseline)'
  } else if (dataColumnOption.id === 'cementEfficiency') {
    return 'Cement Efficiency'
  } else if (dataColumnOption.id === 'concreteTemperature') {
    return 'Temperature'
  }
  return dataColumnOption.name
}

const BaleenVariationTablePresentational = (props: IBaleenVariationTable) => {
  const {
    selectedDataColumns,
    selectedMixVariations,
    frontendFilterSettings,
    mixVariations,
  } = props
  const classes = useStyles()
  const cellWidth = `${100 / (selectedDataColumns.length + 1)}%`

  const baselineVariation = mixVariations.find(
    variation => variation.variationType === VariationTypes.BASELINE
  )
  const hasSelectedVariations = selectedMixVariations.length > 0

  const getVariationStrengthWithBaseline = (
    variation: BaleenMixVariation,
    interval: BaleenIntervals,
    colId: keyof BaleenMixVariation,
    isMetric: boolean,
    unit: string | undefined
  ) => {
    const hasBaselineVariationStrength =
      typeof baselineVariation?.strengths[interval] !== 'undefined' &&
      baselineVariation.strengths[interval] !== null
    const hasVariationStrength =
      typeof variation.strengths[interval] !== 'undefined' &&
      variation.strengths[interval] !== null
    if (!hasBaselineVariationStrength && !hasVariationStrength) {
      return '-'
    } else if (hasBaselineVariationStrength && !hasVariationStrength) {
      return '-'
    } else if (!hasBaselineVariationStrength && hasVariationStrength) {
      return `${variation.strengths[interval]?.toFixed(
        getPropertyPrecision(colId, isMetric)
      )} ${unit} (-)`
    } else {
      if (variation.variationId === baselineVariation?.variationId) {
        return `${variation.strengths[interval]?.toFixed(
          getPropertyPrecision(colId, isMetric)
        )} ${unit} (-)`
      } else if (baselineVariation?.strengths[interval] === 0)
        return `${variation.strengths[interval]?.toFixed(
          getPropertyPrecision(colId, isMetric)
        )} ${unit} (-)` // don't want to divide by 0
      return `${variation.strengths[interval]?.toFixed(
        getPropertyPrecision(colId, isMetric)
      )} ${unit} (${roundUpToDecimal(
        (variation.strengths[interval] /
          baselineVariation.strengths[interval]) *
          100,
        0
      )}%)`
    }
  }

  const getVariationCementContentWithBaseline = (
    variation: BaleenMixVariation,
    colId: keyof BaleenMixVariation,
    isMetric: boolean,
    unit: string | undefined
  ) => {
    const hasBaselineVariationCementContent =
      typeof baselineVariation.cementContent !== 'undefined' &&
      baselineVariation.cementContent !== null
    const hasVariationCementContent =
      typeof variation.cementContent !== 'undefined' &&
      variation.cementContent !== null
    if (!hasBaselineVariationCementContent && !hasVariationCementContent) {
      return '-'
    } else if (
      hasBaselineVariationCementContent &&
      !hasVariationCementContent
    ) {
      return '-'
    } else if (
      !hasBaselineVariationCementContent &&
      hasVariationCementContent
    ) {
      return `${variation.cementContent?.toFixed(
        getPropertyPrecision(colId, isMetric)
      )} ${unit} (-)`
    } else {
      if (variation.variationId === baselineVariation.variationId) {
        return `${variation.cementContent?.toFixed(
          getPropertyPrecision(colId, isMetric)
        )} ${unit} (-)`
      } else if (baselineVariation?.cementContent === 0)
        return `${variation.cementContent?.toFixed(
          getPropertyPrecision(colId, isMetric)
        )} ${unit} (-)` // don't want to divide by 0
      return `${variation.cementContent?.toFixed(
        getPropertyPrecision(colId, isMetric)
      )} ${unit} (${roundUpToDecimal(
        (variation.cementContent / baselineVariation.cementContent) * 100,
        0
      )}%)`
    }
  }

  const getStrengthValue = (
    variation: BaleenMixVariation,
    colId: keyof BaleenMixVariation,
    isMetric: boolean
  ) => {
    const interval = frontendFilterSettings.interval[0]
    const unit = getPropertyUnit(colId, isMetric, variation)
    if (!baselineVariation) {
      // no baseline variation
      if (
        typeof variation.strengths[interval] === 'undefined' ||
        variation.strengths[interval] === null
      ) {
        return '-'
      } else {
        return (
          variation.strengths[interval]?.toFixed(
            getPropertyPrecision(colId, isMetric)
          ) +
          ' ' +
          unit
        )
      }
    } else {
      // there is a baseline variation
      return getVariationStrengthWithBaseline(
        variation,
        interval,
        colId,
        isMetric,
        unit
      )
    }
  }

  const getCementContentValue = (
    variation: BaleenMixVariation,
    colId: keyof BaleenMixVariation,
    isMetric: boolean
  ) => {
    const unit = getPropertyUnit(colId, isMetric, variation)
    if (!baselineVariation) {
      // no baseline variation
      if (
        typeof variation.cementContent === 'undefined' ||
        variation.cementContent === null
      ) {
        return '-'
      } else {
        return (
          variation.cementContent?.toFixed(
            getPropertyPrecision(colId, isMetric)
          ) +
          ' ' +
          unit
        )
      }
    } else {
      // there is a baseline variation
      return getVariationCementContentWithBaseline(
        variation,
        colId,
        isMetric,
        unit
      )
    }
  }

  const getCementEfficiencyValue = (
    variation: BaleenMixVariation,
    interval: BaleenIntervals,
    isMetric: boolean
  ) => {
    const unit = getPropertyUnit('cementEfficiency', isMetric, variation)
    if (
      variation['cementEfficiency'][interval] !== undefined &&
      variation['cementEfficiency'][interval] !== null
    ) {
      return variation['cementEfficiency'][interval].toFixed(2) + ' ' + unit
    } else return '-'
  }

  const getCO2DosageValue = (
    variation: BaleenMixVariation,
    isMetric: boolean
  ) => {
    const precision = getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
    const unit = getPropertyUnit('cO2DosageLabel', isMetric, variation)
    if (variation['cO2DosageLabel'] !== null)
      return variation['cO2DosageLabel'].toFixed(precision) + ' ' + unit
    else return '-'
  }

  const getValue = (
    variation: BaleenMixVariation,
    colId: keyof BaleenMixVariation
  ) => {
    const interval = frontendFilterSettings.interval[0]
    const isMetric = frontendFilterSettings.isMetric
    if (colId === 'strengths') {
      return getStrengthValue(variation, colId, isMetric)
    } else if (colId === 'cementContent') {
      return getCementContentValue(variation, colId, isMetric)
    } else if (colId === 'cO2DosageLabel') {
      return getCO2DosageValue(variation, isMetric)
    } else if (colId === 'cementEfficiency') {
      return getCementEfficiencyValue(variation, interval, isMetric)
    } else {
      if (variation[colId] !== null) {
        const precision = getPropertyPrecision(
          colId,
          frontendFilterSettings.isMetric
        )
        if (precision || precision === 0) {
          return (
            Number(variation[colId]).toFixed(precision) +
            ' ' +
            getPropertyUnit(colId, frontendFilterSettings.isMetric, variation)
          )
        } else {
          return variation[colId]
        }
      } else {
        return '-'
      }
    }
  }

  const getBorderRightStyle = (colIndex: number) => {
    return colIndex !== selectedDataColumns.length - 1
      ? '1px solid #BDBDBD'
      : 'none'
  }

  const getTooltip = (colId: keyof BaleenMixVariation) => {
    if (colId === 'strengths' || colId === 'cementContent') {
      if (!baselineVariation) {
        return 'No baseline variation for this mix group'
      }
    }
    return ''
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="baleen variation table">
        <TableRow>
          <TableCell
            className={classes.tableCell}
            style={{ borderBottom: 'none', borderRight: '1px solid #BDBDBD' }}
            width={cellWidth}
          >
            <Typography
              variant="h5"
              style={{
                color: hasSelectedVariations
                  ? baseColors.text.primary
                  : baseColors.text.disabled,
              }}
            >
              Variation ID
            </Typography>
          </TableCell>
          {selectedDataColumns.map((col: FilterOption, colIndex) => (
            <Tooltip
              title={getTooltip(col.id as keyof BaleenMixVariation)}
              key={col.id + 'tooltip header'}
            >
              <TableCell
                key={col.id + 'header'}
                className={classes.tableCell}
                style={{
                  borderBottom: 'none',
                  borderRight: getBorderRightStyle(colIndex),
                }}
                width={cellWidth}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: hasSelectedVariations
                      ? baseColors.text.primary
                      : baseColors.text.disabled,
                  }}
                >
                  {getTitle(col, frontendFilterSettings.interval[0])}
                </Typography>
              </TableCell>
            </Tooltip>
          ))}
        </TableRow>
        <TableBody>
          {!hasSelectedVariations ? (
            <TableRow>
              <TableCell
                className={classes.tableCell}
                style={{ borderRight: '1px solid #BDBDBD' }}
              >
                -
              </TableCell>
              {selectedDataColumns.map((_, colIndex) => (
                <TableCell
                  className={classes.tableCell}
                  style={{ borderRight: getBorderRightStyle(colIndex) }}
                >
                  -
                </TableCell>
              ))}
            </TableRow>
          ) : (
            <>
              {selectedMixVariations.map((variation, variationIndex) => (
                <TableRow key={variation.variationId}>
                  <TableCell
                    className={classes.tableCell}
                    width={cellWidth}
                    style={{ borderRight: '1px solid #BDBDBD' }}
                  >
                    <div className={classes.variationLabel}>
                      <div className={classes.variationDotContainer}>
                        <div
                          className={classes.variationDot}
                          style={{ backgroundColor: variation.fillColor }}
                        />
                      </div>
                      <Typography variant="body1">
                        {variation.variationIdLabel}
                      </Typography>
                    </div>
                  </TableCell>
                  {selectedDataColumns.map((col, colIndex) => (
                    <React.Fragment
                      key={variation.variationId + col.id + 'cell'}
                    >
                      <TableCell
                        className={classes.tableCell}
                        width={cellWidth}
                        style={{ borderRight: getBorderRightStyle(colIndex) }}
                      >
                        <Typography variant="body1">
                          {getValue(
                            variation,
                            col.id as keyof BaleenMixVariation
                          )}
                        </Typography>
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BaleenVariationTablePresentational
