import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slider, TextField, Typography, Grid } from '@material-ui/core'
import cloneDeep from 'lodash.clonedeep'
import { AddDataSettings } from '../Logic/Types'
import {
  isPositiveInteger,
  keyPressNumericStringsOnly,
} from '../../Common/Helpers/GeneralHelpers'

export interface IRangeSlider {
  addDataSettings: AddDataSettings
  setAddDataSettings: Dispatch<SetStateAction<AddDataSettings>>
  sliderValues: Array<any>
  setSliderValues: (arg1: Array<string>) => void
  isAssociationView: boolean
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  sliderContainer: {
    maxWidth: 'calc(100% - 40px)',
    margin: '0 auto',
  },
})

const RangeSlider = (props: IRangeSlider) => {
  const {
    addDataSettings,
    setAddDataSettings,
    sliderValues,
    setSliderValues,
    isAssociationView,
  } = props
  const classes = useStyles()

  /** Used for running useEffect only on First Render */
  const [isFirstRender, setIsFirstRender] = useState(true)

  /** Determines the values of the slider */
  const handleSliderChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    newValue: string[]
  ) => {
    setSliderValues(newValue)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isPositiveInteger(e.target.value)) return
    if (e.target.name === 'min')
      setSliderValues([e.target.value, sliderValues[1]])
    if (e.target.name === 'max')
      setSliderValues([sliderValues[0], e.target.value])
  }

  /** Checks for the existence of Min/Max Sample URL param and updates inputs on first render */
  useEffect(() => {
    if (isFirstRender) {
      const minSamples =
        addDataSettings?.minNumSamples[0] !== undefined && !sliderValues[0]
          ? addDataSettings?.minNumSamples[0]
          : sliderValues[0] || ''
      const maxSamples =
        addDataSettings?.maxNumSamples[0] !== undefined && !sliderValues[1]
          ? addDataSettings?.maxNumSamples[0]
          : sliderValues[1] || ''
      // Check if individual samples match individual values in slider array
      const minSamplesMatch = minSamples === sliderValues[0]
      const maxSamplesMatch = maxSamples === sliderValues[1]
      // Checks if all samples match, preventing an unnecessary update
      if (minSamplesMatch && maxSamplesMatch) return
      // Update slider values and their respective inputs
      if (!minSamplesMatch && !maxSamplesMatch) {
        setSliderValues([minSamples, maxSamples])
      } else if (!minSamplesMatch && maxSamplesMatch) {
        setSliderValues([minSamples, sliderValues[1]])
      } else {
        setSliderValues([sliderValues[0], maxSamples])
      }
      setIsFirstRender(false)
    }
  }, [addDataSettings, sliderValues, isFirstRender, setSliderValues])

  /** Handles URL Param updates after inputting */
  useEffect(() => {
    // Delays processing while user is typing to prevent rapid calls to Kelowna
    const timeOut = setTimeout(() => {
      // Get samples data from addDataSettings
      const minSamples =
        addDataSettings?.minNumSamples[0] !== undefined
          ? addDataSettings?.minNumSamples[0]
          : ''
      const maxSamples =
        addDataSettings?.maxNumSamples[0] !== undefined
          ? addDataSettings?.maxNumSamples[0]
          : ''
      // Check if sample data matches values in slider array
      const minSamplesMatch = minSamples === sliderValues[0]
      const maxSamplesMatch = maxSamples === sliderValues[1]
      // Check if all sample data matches all slider array values
      if (minSamplesMatch && maxSamplesMatch) return
      // Set up new values to update addDataSettings
      const newMinSampleArray = sliderValues[0] !== '' ? [sliderValues[0]] : []
      const newMaxSampleArray = sliderValues[1] !== '' ? [sliderValues[1]] : []
      const newSettings = cloneDeep(addDataSettings)
      newSettings.minNumSamples = newMinSampleArray
      newSettings.maxNumSamples = newMaxSampleArray
      setAddDataSettings(newSettings)
    }, 500)
    return () => clearTimeout(timeOut)
  }, [addDataSettings, sliderValues, setAddDataSettings])

  /** Resets Sliders and Params when in Association View Mode */
  useEffect(() => {
    if (!isAssociationView) return
    if (
      addDataSettings?.minNumSamples.length === 0 &&
      addDataSettings?.maxNumSamples.length === 0
    )
      return
    const newSettings = cloneDeep(addDataSettings)
    newSettings.minNumSamples.length = 0
    newSettings.maxNumSamples.length = 0
    setAddDataSettings(newSettings)
    setSliderValues(['', ''])
  }, [addDataSettings, setAddDataSettings, isAssociationView, setSliderValues])

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={5}>
          <TextField
            onChange={handleInputChange}
            value={sliderValues[0]}
            name="min"
            variant="outlined"
            size="small"
            placeholder="Min."
            label="Min."
            type="number"
            inputProps={{ min: '0' }}
            onKeyPress={keyPressNumericStringsOnly}
            disabled={isAssociationView}
          />
        </Grid>
        <Grid item xs={2} container justify="center">
          <Typography>-</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            onChange={handleInputChange}
            value={sliderValues[1]}
            name="max"
            variant="outlined"
            size="small"
            placeholder="Max."
            label="Max."
            type="number"
            inputProps={{ min: '0' }}
            onKeyPress={keyPressNumericStringsOnly}
            disabled={isAssociationView}
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            value={sliderValues}
            onChange={handleSliderChange}
            min={0}
            max={300}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            disabled={isAssociationView}
            color="secondary"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default RangeSlider
