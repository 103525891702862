import { Button, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React from 'react'
// Not importing as ReactComponent to avoid rendering issue in Jest
import FilterIcon from '../../assets/FilterComponent_56px_030823.svg'

export interface IFilterButton {
  open: boolean
  parentClasses: ClassNameMap<string>
  filterCount: number
  onClickHandler: () => void
}

const useStyles = makeStyles(theme => ({
  filterCountContainer: {
    backgroundColor: '#1A4373',
    color: 'white',
    padding: '0px 8px',
    marginRight: '10px',
    fontSize: '12px',
    borderRadius: '4px',
  },
}))
const FilterButton = (props: IFilterButton) => {
  const { open, parentClasses, onClickHandler, filterCount } = props
  const classes = useStyles()
  return (
    <div id="filter-btn__container" className={parentClasses.btnContainer}>
      <Button
        data-testid="filter-button"
        className={
          open
            ? parentClasses.filterButtonActive
            : parentClasses.filterButtonInactive
        }
        onClick={onClickHandler}
        variant="outlined"
      >
        {filterCount ? (
          <span className={classes.filterCountContainer}>{filterCount}</span>
        ) : (
          <img
            src={FilterIcon}
            alt="Filter Icon"
            style={{ width: '24px', height: '24px', marginRight: '10px' }}
          />
        )}
        Filters
      </Button>
    </div>
  )
}

export default FilterButton
