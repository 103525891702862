import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Status from '../Logic/Status'
import Injection from '../Logic/Injection'
import CO2InjectedGraphLogical from '../Components/CO2InjectedGraphLogical'
import GasChartLogical from '../Components/GasChartLogical'
import LiquidChartLogical from '../Components/LiquidChartLogical'
import DownstreamPressureChartLogical from '../Components/DownstreamPressureChartLogical'
import TemperatureChartLogical from '../Components/TemperatureChartLogical'
import UpstreamPressureLogical from '../Components/UpstreamPressureLogical'

const useStyles = makeStyles(() => ({
  chartArea: {
    maxHeight: '70vh',
    minHeight: '20vh',
    overflow: 'scroll',
    marginTop: '2vh',
    marginRight: '2vh',
  },
  whiteChip: {
    backgroundColor: '#FFF !important',
  },
}))

/**
 *
 * @param {Object} props
 * @param {Status[]} props.statusArray
 * @param {Injection[]} props.injectionArray
 * @returns
 */
function InjectionGraphPresentational(props) {
  const { graphVisibility } = props

  const classes = useStyles()
  return (
    <>
      <Grid container direction="row" justify="flex-start">
        <Grid container item xs={12} className={classes.chartArea} spacing={2}>
          {graphVisibility.cO2GraphVisible && (
            <>
              <Grid item xs={12}>
                <CO2InjectedGraphLogical
                  title="Injected CO2"
                  injectionArray={props.injectionArray}
                  metric
                />
              </Grid>
            </>
          )}
          {graphVisibility.temperatureGraphVisible && (
            <>
              <Grid item xs={12}>
                <TemperatureChartLogical
                  statusArray={props.statusArray}
                  metric
                />
              </Grid>
            </>
          )}
          {graphVisibility.gasGraphVisible && (
            <>
              <Grid item xs={12}>
                <GasChartLogical statusArray={props.statusArray} />
              </Grid>
            </>
          )}
          {graphVisibility.liquidGraphVisible && (
            <>
              <Grid item xs={12}>
                <LiquidChartLogical statusArray={props.statusArray} />
              </Grid>
            </>
          )}
          {graphVisibility.upstreamPressureGraphVisible && (
            <>
              <Grid item xs={12}>
                <UpstreamPressureLogical statusArray={props.statusArray} />
              </Grid>
            </>
          )}
          {graphVisibility.downstreamPressure0GraphVisible && (
            <>
              <Grid item xs={12}>
                <DownstreamPressureChartLogical
                  statusArray={props.statusArray}
                />
              </Grid>
            </>
          )}
          {graphVisibility.downstreamPressure1GraphVisible && (
            <>
              <Grid item xs={12}>
                <DownstreamPressureChartLogical
                  statusArray={props.statusArray}
                  downstreamTwo
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

InjectionGraphPresentational.propTypes = {
  /** Object containing visibility states for each graph */
  graphVisibility: PropTypes.shape({
    cO2GraphVisible: PropTypes.bool.isRequired,
    upstreamPressureGraphVisible: PropTypes.bool.isRequired,
    temperatureGraphVisible: PropTypes.bool.isRequired,
    downstreamPressure0GraphVisible: PropTypes.bool.isRequired,
    downstreamPressure1GraphVisible: PropTypes.bool.isRequired,
    liquidGraphVisible: PropTypes.bool.isRequired,
    gasGraphVisible: PropTypes.bool.isRequired,
  }).isRequired,
  /** Array of status instances associated with an injection */
  statusArray: PropTypes.arrayOf(PropTypes.instanceOf(Status)),
  /** Array of injection instances */
  injectionArray: PropTypes.arrayOf(PropTypes.instanceOf(Injection)),
}

InjectionGraphPresentational.defaultProps = {
  graphVisibility: {
    cO2GraphVisible: true,
    upstreamPressureGraphVisible: true,
    temperatureGraphVisible: true,
    downstreamPressure0GraphVisible: true,
    downstreamPressure1GraphVisible: true,
    liquidGraphVisible: true,
    gasGraphVisible: true,
  },
  statusArray: [],
  injectionArray: [],
}

export default InjectionGraphPresentational
