import {
  fetchDataHelper,
  postDataHelper,
  putDataHelper,
} from '../../../Common/Helpers/DataHelpers'

/**
 *
 * @param {Number} remoteUnitId The remote unit
 * @param {{}} options The options object passed on for pagination, etc
 * @returns {Promise<Response>}
 */
export async function getInjectionReadings(remoteUnitId, options) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${remoteUnitId}/InjectionReadings`
  return fetchDataHelper(endpoint, options)
}

/**
 *
 * @param {Number} remoteUnitId The remote unit
 * @param {{}} options The options object passed on for pagination, etc
 * @returns {Promise<Response>}
 */
function getStatuses(remoteUnitId, options) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${remoteUnitId}/StatusesTableResults`
  return fetchDataHelper(endpoint, options)
}

/**
 *
 * @param {Number} remoteUnitId The remote unit
 * @param {{}} limit The number of injection objects
 * @returns {Promise<Response>}
 */
function getInjections(remoteUnitId, options) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${remoteUnitId}/InjectionsTableResults`
  return fetchDataHelper(endpoint, options)
}

/**
 * Function to get all remote units
 * @returns  {Promise<Response>}
 */
function getRemoteUnits() {
  const endpoint = `/Hardware/Mixer/RemoteUnits`
  return fetchDataHelper(endpoint)
}

/**
 *
 * @param {Object} params
 * @param {Number} params.Limit
 * @param {Number} params.Offset
 * @param {String} params.SortColumn
 * @param {'Ascending' | 'Descending'} params.SortOrder
 * @param {Boolean} params.onlyUnresolved
 * @param {Boolean} params.onlyResolved
 * @param {Boolean} params.displayInternational
 * @param {Boolean} params.displayCanadaUs
 * @param {Number} params.remoteUnitId
 * @param {String} params.startTime
 * @param {String} params.endTime
 * @returns {Promise<Response>}
 */
function getAlarmsTable(params) {
  const endpoint = `/Hardware/Mixer/Alarms`
  return fetchDataHelper(endpoint, params)
}

/**
 * Resolve an alarm by its ID
 * @param {Number} alarmId
 */
function postResolveAlarm(alarmId) {
  const endpoint = `/Hardware/Mixer/Alarms/${alarmId}/Resolve`
  return postDataHelper(endpoint, {})
}

/**
 *
 * @param {Object} params Parameters for sorting
 *  * @param {Number} params.Limit
 * @param {Number} params.Offset
 * @param {String} params.SortColumn
 * @param {'Ascending' | 'Descending'} params.SortOrder
 * @param {Boolean} params.displayInternational
 * @param {Boolean} params.displayCanadaUs
 * @param {Number} params.remoteUnitId
 * @param {String} params.startTime
 * @param {String} params.endTime
 * @return {Promise<Response>}
 */
function getServiceCasesTable(params) {
  const endpoint = `/Hardware/Mixer/ServiceCase`
  return fetchDataHelper(endpoint, params)
}

/**
 *
 * @param {Number} id id of the service case
 * @return {Promise<Response>}
 */
function getServiceCase(id) {
  const endpoint = `/Hardware/Mixer/ServiceCase/${id}`
  return fetchDataHelper(endpoint)
}

/**
 * Endpoint for alarm code details, ensures that alarm codes are kept up to date from the backend
 * @returns {Promise<Response>}
 */
function getAlarmCodeDetails() {
  const endpoint = `/Hardware/Mixer/Alarms/CodeDetails`
  return fetchDataHelper(endpoint)
}

function postServiceCase(params) {
  const endpoint = `/Hardware/Mixer/ServiceCase`
  return postDataHelper(endpoint, params)
}

function putServiceCase(params) {
  const endpoint = `/Hardware/Mixer/ServiceCase/Update`
  return putDataHelper(endpoint, params)
}

function postServiceCaseNote(id, note) {
  const endpoint = `/Hardware/Mixer/ServiceCase/${id}/AddNote`
  return postDataHelper(endpoint, { note }, true)
}
const AlarmsManagementDataHelpers = {
  getInjectionReadings,
  getStatuses,
  getInjections,
  getRemoteUnits,
  getAlarmCodeDetails,
  getAlarmsTable,
  getServiceCasesTable,
  getServiceCase,
  postResolveAlarm,
  postServiceCaseNote,
  postServiceCase,
  putServiceCase,
}

export default AlarmsManagementDataHelpers
