import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Box, Tooltip } from '@material-ui/core'
import jspreadsheet from 'jspreadsheet-ce'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import UploadData from './UploadBatchData'
import XLSX from 'xlsx'
import { disableSubmitButton } from '../Logic/TSSLogic'
import { isColumnReadOnly } from '../Logic/AddBatchViewLogic'

const useStyles = makeStyles({
  templateButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1em',
  },
  comfirmButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  spreadsheet: {
    width: '100%',
    padding: 0,
  },
})

/** Define default spreadsheet options common to every re-render. */
const defaultTableOptions = {
  tableOverflow: true,
  columnDrag: true,
  search: false,
  minSpareRows: 5,
  wordwrap: true,
  minDimensions: [9, 10],
  allowInsertColumn: false,
  allowRenameColumn: false,
  allowDeleteColumn: false,
  includeHeadersOnDownload: true,
  defaultColWidth: 125,
  csvFileName: 'BatchDataTemplate',
  tableHeight: '540px',
  tableWidth: '100%',
}

AddBatchSpreadsheet.propTypes = {
  /** Columns selected in AddBatchView to appear in spreadsheet. */
  selectedCols: PropTypes.array.isRequired,
  /** Used to set columns in parent component. Only used when a file is uploaded. */
  setSelectedCols: PropTypes.func.isRequired,
  /** List of all possible spreadsheet columns. Used to match column in file upload. */
  possibleCols: PropTypes.object.isRequired,
  /** This function validates the entered data before submission, here it is used to extract data from the spreadsheet. */
  saveData: PropTypes.func.isRequired,
  /** Function to update reading and material options. */
  setStrengthAndMaterialCols: PropTypes.func.isRequired,
  /** Data to update spreadsheet with. Data comes from Kelowna when AddBatchView is in edit mode, otherwise null. */
  updateData: PropTypes.array,
  /** Function to null out updateData and prevent updates on every render. */
  setUpdateData: PropTypes.func,
  /** List of cement plants as fetched from Kelowna. */
  cementPlants: PropTypes.array.isRequired,
  /** Function to trigger progress lost alert modal. */
  setHasUserInput: PropTypes.func,
  /** Boolean flag that indicates whether the component is in edit mode. Optional.*/
  isEditMode: PropTypes.bool,
  /** Boolean flag that determines whether input field is disabled. Optional. */
  isViewOnly: PropTypes.bool,
}

/**
 * Spreadsheet component to enter batch actual data.
 * The spreadsheet is created using the passed down columns prop, and data can be added directly or via uploading the template.
 * Downloading the template downloads a CSV version of the current copy of the spreadsheet, allowing TSS to customize the template prior to uploading it.
 * @param {Array} selectedCols - columns selected in AddBatchView to appear in spreadsheet.
 * @param {Function} setSelectedCols - used to set columns in parent component. Only used when a file is uploaded.
 * @param {Object} possibleCols - list of all possible spreadsheet columns. Used to match column in file upload.
 * @param {Function} saveData - this function validates the entered data before submission, here it is used to extract data from the spreadsheet.
 * @param {Function} setStrengthAndMaterialCols - function to update reading and material options.
 * @param {Array} updateData - data to update spreadsheet with. Data comes from Kelowna when AddBatchView is in edit mode, otherwise null.
 * @param {Function} setUpdateData - function to null out updateData and prevent updates on every render.
 * @param {Array} cementPlants - list of cement plants as fetched from Kelowna.
 */
function AddBatchSpreadsheet(props) {
  const {
    setHasUserInput,
    selectedCols,
    setSelectedCols,
    possibleCols,
    saveData,
    setStrengthAndMaterialCols,
    updateData,
    setUpdateData,
    cementPlants,
    search,
    roles,
    isEditMode,
    isViewOnly,
  } = props
  const classes = useStyles()
  const [spreadSheet, setSpreadSheet] = useState({})
  /** Wrapper for spreadsheet. */
  const wrapper = useRef()

  /** Update table whenever new columns are passed to component.
   * Also used to instantiate table on first render. */
  useEffect(() => {
    /** Function to update table with new options (typically data and columns) */
    setSpreadSheet(prevSheet => {
      const cols = selectedCols.map(col => {
        return { ...col, readOnly: isColumnReadOnly(isViewOnly, col.name) }
      })
      const options = { ...defaultTableOptions, columns: cols }
      if (wrapper.current.innerHTML) {
        wrapper.current.innerHTML = ''

        // Perserve entered data if update is not via file upload.
        options.data = prevSheet
          .getJson()
          .map(row => {
            const newRow = {}
            selectedCols.forEach(col => {
              newRow[col.name] = row[col.name]
            })
            return newRow
          })
          .filter(row => Object.keys(row).length > 0) // Remove empty rows.

        options.onbeforechange = (element, cell, x, y, value) => {
          if (typeof value === 'string') {
            const upperCaseValue = value.toUpperCase()
            if (upperCaseValue === 'TRUE' || upperCaseValue === 'FALSE') {
              return upperCaseValue
            }
          }

          return value
        }
        // onChange to trigger progess lost alert
        options.onchange = (element, cell, x, y, value) => {
          setHasUserInput(true)
        }

        // Enable lazy loading if there are more than 400 rows to improve performance.
        if (options.data.length > 400) {
          options.lazyLoading = true
          options.loadingSpin = true
        }
      }

      return jspreadsheet(wrapper.current, options)
    })
  }, [selectedCols, roles, setHasUserInput, isViewOnly])

  /** Used to update table data after spreadsheet columns are updated. */
  useEffect(() => {
    if (!updateData || !Object.keys(spreadSheet)?.length > 0) return
    spreadSheet.setData(updateData)
    setUpdateData(null)
  }, [updateData, spreadSheet, setUpdateData])

  /** Event handler to download XLSX with current table contents. */
  const downloadTemplate = () => {
    // Extract header titles.
    const templateHeaders = []
    const headerNames = []
    for (let i = 0; i < selectedCols.length; i++) {
      templateHeaders.push(spreadSheet.getHeader(i))
      headerNames.push(selectedCols[i].name)
    }

    // Instantiate sheet with template data.
    const templateSheet = XLSX.utils.json_to_sheet(spreadSheet.getJson(), {
      header: headerNames, // Use data derived from selectedCols to determine the order of columns in template
    })

    // Replace internal column names with extracted headers.
    XLSX.utils.sheet_add_aoa(templateSheet, [templateHeaders])

    // Instantiate workbook, add sheet, and create client-side file.
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, templateSheet)
    XLSX.writeFile(wb, 'BatchDataTemplate.xlsx')
  }

  /** Extract data from table and validate in parent function. */
  const onClick = () => {
    saveData(() => {
      return spreadSheet
        .getJson()
        .map(row => {
          const newRow = {}
          selectedCols.forEach(col => {
            if (typeof row[col.name] === 'string') {
              newRow[col.name] = row[col.name].trim()
            } else newRow[col.name] = row[col.name]
          })
          return newRow
        })
        .filter(row => Object.keys(row).length > 0) // Remove empty rows
    })
  }

  return (
    <>
      <Box className={classes.templateButtons}>
        <Button
          onClick={downloadTemplate}
          color="primary"
          variant="outlined"
          size="medium"
        >
          Download Template
        </Button>
        <UploadData
          setHasUserInput={setHasUserInput}
          setUpdateData={setUpdateData}
          setSelectedCols={setSelectedCols}
          possibleCols={possibleCols}
          setStrengthAndMaterialCols={setStrengthAndMaterialCols}
          cementPlants={cementPlants}
          roles={roles}
          isViewOnly={isViewOnly}
        />
      </Box>
      <Tooltip
        title="Rearrange columns by hovering over a header and dragging the column to the left or right."
        placement="top-start"
        style={{ marginTop: '0.5em' }}
      >
        <Box ref={wrapper} className={classes.spreadsheet} />
      </Tooltip>
      <Box className={classes.comfirmButton}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          type="submit"
          onClick={onClick}
          disabled={disableSubmitButton(isEditMode, 'batchActuals', search)}
        >
          Confirm & Submit
        </Button>
      </Box>
    </>
  )
}

export default AddBatchSpreadsheet
