import React, { Dispatch, SetStateAction } from 'react'
import DialogModal from '../../../../Common/Components/DialogModal/DialogModal'
import {
  DialogModalSize,
  DialogModalType,
  SonarFilterType,
} from '../../../../TSS/Logic/Types'
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import SonarDateRangeSelect from '../SonarDateRangeSelect'
import {
  SnoozeDownSystemReason,
  SnoozeDownSystemReasonString,
} from '../../../Logic/Types'
import DownSystemSnoozeReasonDetails from './DownSystemSnoozeReasonDetails'
import hook from './useSnoozeDownSystemsModal'
import { addDays, formatISO, format, parseISO, startOfDay } from 'date-fns'
import snoozeDownSystemsModalHelper from './snoozeDownSystemsModalHelper'
import { ISonarDownSystem } from '../../../../Common/Logic/Types'
import { useRecoilValue } from 'recoil'
import { atomJWT } from '../../../../Common/atoms'
import { baseColors } from '../../../../theme/colors'

const useStyles = makeStyles(theme => ({
  textField: {
    margin: '2px 0px',
  },
  card: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  cardContent: {
    padding: '8px 16px !important',
  },
  fullWidth: {
    width: '100%',
  },
  upperBackdrop: {
    zIndex: Math.max(...Object.values(theme.zIndex)) + 1,
  },
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses?.warningModal,
}))

const useStyles2 = makeStyles(theme => ({
  // @ts-ignore
  ...theme.customClasses?.successModal,
}))

export interface ISnoozeDownSystemModal {
  showSnoozeModal: boolean
  clickedDownSystem: ISonarDownSystem | null
  setClickedDownSystem: Dispatch<SetStateAction<ISonarDownSystem | null>>
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  setSnoozeSavedFlag: Dispatch<SetStateAction<boolean>>
  showSnoozeSuccessModal: boolean
  setShowSnoozeSuccessModal: Dispatch<SetStateAction<boolean>>
}

const SnoozeDownSystemsModal = (props: ISnoozeDownSystemModal) => {
  const {
    clickedDownSystem,
    setClickedDownSystem,
    showSnoozeModal,
    setShowSnoozeModal,
    setSnoozeSavedFlag,
    showSnoozeSuccessModal,
    setShowSnoozeSuccessModal,
  } = props

  const classes: ClassNameMap = useStyles()
  const successClasses: ClassNameMap = useStyles2()
  const snoozeMin = formatISO(addDays(new Date(), 1)).substring(0, 10)
  const snoozeMax = formatISO(addDays(new Date(), 90)).substring(0, 10)
  const today = formatISO(startOfDay(new Date())).substring(0, 10)
  const userInfo = useRecoilValue(atomJWT)
  const {
    reason,
    reasonDetails,
    rangeSelected,
    snoozeEnd,
    isSnoozed,
    snoozeEditDate,
    setRangeSelected,
    setSnoozeEnd,
    handleSnoozeModalClose,
    handleReasonChange,
    handleReasonDetailsChange,
    handleConfirmSnooze,
    handleDateChange,
    handleUnsnooze,
    handleSnoozeSuccessModalClose,
    isSaving,
    isSavingModal,
    errorMessage,
  } = hook.useSnoozeDownSystemsModal({
    clickedDownSystem: clickedDownSystem,
    setClickedDownSystem: setClickedDownSystem,
    setShowSnoozeModal,
    setSnoozeSavedFlag,
    setShowSnoozeSuccessModal,
    showSnoozeSuccessModal,
    showSnoozeModal,
  })

  const SNOOZE_TEXT = (
    <Typography variant="body2">
      You are about to snooze the following down system. Please provide the
      reason and date you wish to unsnooze or reengage the alarm to save and
      continue.
    </Typography>
  )
  const EDIT_SNOOZE_TEXT = (
    <Typography variant="body2">
      Edit the following snoozed down system. Please provide expected reason to
      save and continue.
    </Typography>
  )

  const renderDownSystemInfoCard = () => {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2">
            {clickedDownSystem?.divisionName}
          </Typography>
          <Typography variant="body2">{clickedDownSystem?.location}</Typography>
          <Typography variant="body2">{clickedDownSystem?.systemId}</Typography>
          {isSnoozed && !showSnoozeSuccessModal && (
            <Typography variant="body2">
              Last Updated By: {clickedDownSystem?.snoozeBy}
            </Typography>
          )}
          {showSnoozeSuccessModal && (
            <>
              <Typography variant="body2">
                Reason for Snooze:{' '}
                {
                  SnoozeDownSystemReasonString[
                    clickedDownSystem?.snoozeReason as SnoozeDownSystemReason
                  ]
                }
              </Typography>
              <Typography variant="body2">
                Snoozed By: {clickedDownSystem?.snoozeBy}
              </Typography>
              <Typography variant="body2">
                Date Snoozed:{' '}
                {clickedDownSystem?.snoozeDate &&
                  format(parseISO(clickedDownSystem?.snoozeDate), 'yyyy-MM-dd')}
              </Typography>
              <Typography variant="body2">
                Unsnoozed By: {userInfo?.firstName} {userInfo?.lastName}
              </Typography>
              <Typography variant="body2">
                Date Unsnoozed: {format(new Date(), 'yyyy-MM-dd')}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    )
  }

  const renderModalBody = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            size="small"
            variant="outlined"
            label="Reason*"
            onChange={handleReasonChange}
            className={classes.textField}
            value={reason}
          >
            <MenuItem value={SnoozeDownSystemReason.Seasonal}>
              Seasonal
            </MenuItem>
            <MenuItem value={SnoozeDownSystemReason.ProducerMaintenance}>
              Maintenance (Producer)
            </MenuItem>
            <MenuItem value={SnoozeDownSystemReason.CarbonCureMaintenance}>
              Maintenance (CarbonCure)
            </MenuItem>
            <MenuItem value={SnoozeDownSystemReason.CO2TankMaintenance}>
              Maintenance (CO2 Tank)
            </MenuItem>
            <MenuItem value={SnoozeDownSystemReason.Churned}>Churned</MenuItem>
            <MenuItem value={SnoozeDownSystemReason.Other}>Other</MenuItem>
          </TextField>
        </Grid>
        <Collapse
          in={reason === SnoozeDownSystemReason.Other}
          timeout="auto"
          unmountOnExit
          className={classes.fullWidth}
        >
          <Grid item xs={12}>
            <DownSystemSnoozeReasonDetails
              reasonDetails={reasonDetails}
              handleReasonDetailsChange={handleReasonDetailsChange}
              parentClasses={classes}
            />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          {isSnoozed ? (
            <TextField
              name="date"
              label="Snooze Until*"
              value={snoozeDownSystemsModalHelper.formatUTCISODate(
                snoozeEditDate as string
              )}
              size="small"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDateChange}
              type="date"
              InputProps={{
                inputProps: { min: today, max: snoozeMax },
              }}
            />
          ) : (
            <SonarDateRangeSelect
              dateRangeStart={null}
              dateRangeEnd={snoozeEnd}
              rangeSelected={rangeSelected}
              setRangeSelected={setRangeSelected}
              showOnlyEndDate={true}
              filterType={SonarFilterType.DownSnoozedModal}
              parentClasses={classes}
              setEndDateSelected={setSnoozeEnd}
              endDateMin={snoozeMin}
              endDateMax={snoozeMax}
              disableCustomRangeEndDate={true}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ color: baseColors.error.main }}
            variant="caption"
          >
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderSnoozeDownSystemModal = () => {
    return (
      <>
        {/* @ts-ignore */}
        <Backdrop open={isSavingModal} className={classes.upperBackdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
        <DialogModal
          modalOpen={showSnoozeModal}
          modalType={DialogModalType.warning}
          headerText={
            isSnoozed ? 'Edit Snoozed Down System' : 'Snooze Down System'
          }
          contentText={isSnoozed ? EDIT_SNOOZE_TEXT : SNOOZE_TEXT}
          parentClasses={classes}
          modalSize={DialogModalSize.Small}
          handleCancel={handleSnoozeModalClose}
          handleConfirm={handleConfirmSnooze}
          cancelButtonText="Cancel"
          confirmButtonText={isSaving ? 'Saving' : 'Save'}
          hasAction={true}
          hasCard={true}
          cardContent={renderDownSystemInfoCard()}
          hasBody={true}
          bodyContent={renderModalBody()}
          hasDeleteAction={isSnoozed as boolean}
          deleteButtonText={isSnoozed ? 'Unsnooze Down System' : ''}
          handleDelete={handleUnsnooze}
          shouldDisable={
            isSnoozed
              ? snoozeDownSystemsModalHelper.isEditConfirmDisabled(
                  clickedDownSystem?.snoozeId,
                  reason,
                  reasonDetails,
                  snoozeEnd,
                  snoozeEditDate,
                  snoozeMin,
                  snoozeMax
                )
              : snoozeDownSystemsModalHelper.isConfirmDisabled(
                  clickedDownSystem?.remoteUnitId,
                  clickedDownSystem?.reclaimedWaterUnitId,
                  reason,
                  reasonDetails,
                  snoozeEnd,
                  rangeSelected
                )
          }
          isLoading={isSaving}
        />
      </>
    )
  }

  const renderSnoozeDownSystemSuccessModal = () => {
    return (
      <DialogModal
        modalOpen={showSnoozeSuccessModal}
        modalType={DialogModalType.success}
        headerText={'Successfully Unsnoozed System'}
        contentText={`You've unsnoozed the following system:`}
        parentClasses={successClasses}
        modalSize={DialogModalSize.Small}
        hasCard={true}
        cardContent={renderDownSystemInfoCard()}
        handleClose={handleSnoozeSuccessModalClose}
      />
    )
  }

  if (showSnoozeSuccessModal) {
    return renderSnoozeDownSystemSuccessModal()
  }

  if (showSnoozeModal) {
    return renderSnoozeDownSystemModal()
  }

  return null
}

export default SnoozeDownSystemsModal
