import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useState } from 'react'
import CollapsableRemoteInfo from './CollapsableRemotInfo'

const headCells = [
  {
    id: 'CreationTime',
    align: 'center',
    disablePadding: true,
    label: 'Created On',
  },
  {
    id: 'HardwareId',
    align: 'center',
    disablePadding: false,
    label: 'Serial Number',
  },
  {
    id: 'RemoteUnitCount',
    align: 'center',
    disablePadding: false,
    label: 'Number of Remote Units',
  },
  {
    id: 'DeploymentPhase',
    align: 'center',
    disablePadding: false,
    label: 'Deployment Phase',
  },
]

const tableStyles = makeStyles({
  container: {
    maxHeight: '1000px',
  },
  revealedRow: {
    backgroundColor: '#E1E1E1',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  row: {
    cursor: 'pointer',
  },
  highLightedRow: {
    backgroundColor: 'rgba(288,132,37,0.1)',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  selectMasterUnitButton: {
    paddingTop: '10px',
  },
})

MasterUnitInventoryTable.propTypes = {
  /** The array of Master Units returned from the /MasterUnits/NoPlantUnits endpoint */
  data: PropTypes.array.isRequired,
  /** A state setter to set the selected master unit */
  setSelectedMasterUnit: PropTypes.func.isRequired,
}

function MasterUnitInventoryTable(props) {
  const { data, setSelectedMasterUnit } = props
  const [selectedRow, setSelectedRow] = useState(-1)
  const classes = tableStyles()

  const createRowOnClickHandler = masterUnit => {
    return () => {
      if (selectedRow === masterUnit.masterUnitId) {
        setSelectedRow(-1)
      } else {
        setSelectedRow(masterUnit.masterUnitId)
      }
    }
  }

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map(cell => (
              <TableCell key={cell.id} align={cell.align}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(masterUnit => (
            <React.Fragment key={masterUnit.masterUnitId}>
              <TableRow onClick={createRowOnClickHandler(masterUnit)}>
                <TableCell align="center">
                  {masterUnit.creationTimestamp}
                </TableCell>
                <TableCell align="center">{masterUnit.hardwareId}</TableCell>
                <TableCell align="center">
                  {masterUnit.remoteUnits.length}
                </TableCell>
                <TableCell align="center">
                  {masterUnit.deploymentPhase}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.revealedRow} colSpan={4}>
                  <CollapsableRemoteInfo
                    expanded={selectedRow === masterUnit.masterUnitId}
                    remotes={masterUnit.remoteUnits}
                    setSelectedMasterUnit={() => {
                      setSelectedMasterUnit(masterUnit)
                    }}
                    variant="inventory"
                    deploymentPhase={masterUnit.deploymentPhase}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MasterUnitInventoryTable
