import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  ICommissionReportFrequencyGraphSettings,
  ICommissionReportSettings,
  IMixSelection,
  NumericIntervals,
} from '../../Logic/Types'
import { getIntervalString } from '../../Helpers/BaleenHelpers'
import { baseColors } from '../../../theme/colors'
import { Autocomplete } from '@material-ui/lab'

export interface ITestResultsHistogramSettingsProps {
  reportSettings: ICommissionReportSettings
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  mixSelections: IMixSelection[]
  tabValue: number
}

const useStyles = makeStyles(theme => ({
  checkboxText: {
    color: baseColors.text.primary,
  },
  checkbox: {
    '&.Mui-disabled': {
      color: `${baseColors.action.required} !important`,
    },
  },
}))

export interface ReportIntervalOption {
  id: NumericIntervals
  label: string
}
interface CheckboxDisabled {
  failureZone: boolean
  bellCurve: boolean
  histogram: boolean
}

function TestResultsHistogramSettings(
  props: Readonly<ITestResultsHistogramSettingsProps>
) {
  const { reportSettings, setReportSettings, mixSelections, tabValue } = props
  const classes = useStyles()

  const [disableCheckbox, setDisableCheckbox] = useState<CheckboxDisabled>({
    failureZone: false,
    bellCurve: false,
    histogram: false,
  })

  const graphSettings =
    reportSettings?.mixDesignSettings[tabValue]?.frequencyGraph

  const handleClick = (key: keyof ICommissionReportFrequencyGraphSettings) => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
      const mixDesignSettings = newSettings.mixDesignSettings[tabValue]
      const updatedHistogramSettings = {
        ...mixDesignSettings.frequencyGraph,
        [key]: !mixDesignSettings.frequencyGraph[key],
      }

      //showFailureZone will be false if showBellCurve is unchecked
      if (
        !disableCheckbox.failureZone &&
        key === 'showBellCurve' &&
        !updatedHistogramSettings.showBellCurve
      ) {
        updatedHistogramSettings.showFailureZone = false
      }
      //showBellCurve will be true if showFailureZone is checked
      if (
        key === 'showFailureZone' &&
        updatedHistogramSettings.showFailureZone
      ) {
        updatedHistogramSettings.showBellCurve = true
      }
      mixDesignSettings.frequencyGraph = updatedHistogramSettings
      return newSettings
    })
  }

  const handleSelectedInterval = (interval: ReportIntervalOption[]) => {
    if (interval.length === 0) return
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
      const mixDesignSettings = newSettings.mixDesignSettings[tabValue]
      const updatedHistogramSettings = {
        ...mixDesignSettings.frequencyGraph,
        selectedInterval:
          intervalOptions.length === 0
            ? Number(interval[0].id)
            : Number(interval[interval.length - 1].id),
      }
      mixDesignSettings.frequencyGraph = updatedHistogramSettings
      return newSettings
    })
  }

  const renderTags = (value: ReportIntervalOption[]) => {
    return value.map((option: ReportIntervalOption) => (
      <Chip
        key={option.id}
        label={option.label}
        size="small"
        style={{ margin: '1px' }}
      />
    ))
  }

  const getValue = () => {
    const selectedInterval = intervalOptions.find(
      option => option.id === graphSettings.selectedInterval
    )
    if (selectedInterval) return [selectedInterval]
    else if (intervalOptions.length) return [intervalOptions[0]]
    else return []
  }

  const getOptionDisabled = (option: ReportIntervalOption) => {
    return option.id === graphSettings.selectedInterval
  }
  //Disable failure zone checkbox if selected interval is not the same as design strength interval
  useEffect(() => {
    const strengthIntervalHours = mixSelections[tabValue]?.strengthIntervalHours
    const selectedAge = graphSettings?.selectedInterval
    const validFailureZone = strengthIntervalHours === selectedAge
    setDisableCheckbox(prevState => ({
      ...prevState,
      failureZone: !validFailureZone,
    }))

    if (!validFailureZone) {
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings)
        const mixDesignSettings = newSettings?.mixDesignSettings?.[tabValue]
        const updatedHistogramSettings = {
          ...mixDesignSettings.frequencyGraph,
          showFailureZone: false,
        }
        mixDesignSettings.frequencyGraph = updatedHistogramSettings

        return newSettings
      })
    }
  }, [
    mixSelections,
    tabValue,
    setReportSettings,
    setDisableCheckbox,
    graphSettings?.selectedInterval,
  ])

  //Disable checkbox when only one graph is selected, ie disable bellCurve if histogram is deselected and vice versa
  useEffect(() => {
    if (!graphSettings) return

    setDisableCheckbox(prevState => ({
      ...prevState,
      bellCurve: !graphSettings.showHistogram,
      histogram: !graphSettings.showBellCurve,
    }))
  }, [graphSettings])

  const intervalOptions =
    mixSelections[tabValue]?.intervalOptions?.map(option => {
      return {
        id: option,
        label: getIntervalString(option),
      }
    }) ?? []

  return (
    <>
      {graphSettings && (
        <Grid container justify="center" style={{ marginTop: '16px' }}>
          <Grid item xs={5} style={{ marginLeft: '-38px' }}>
            <Autocomplete
              multiple
              disableClearable
              select
              size="small"
              variant="outlined"
              label="Maturity Age"
              data-testid="select-maturityAge"
              onChange={(_, event) => handleSelectedInterval(event)}
              value={getValue()}
              style={{ padding: '0px' }}
              options={intervalOptions}
              getOptionLabel={option => {
                return option.label
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Maturity Age'}
                  variant="outlined"
                />
              )}
              renderTags={renderTags}
              getOptionDisabled={option => getOptionDisabled(option)}
            />
          </Grid>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item>
              <Tooltip
                title={
                  mixSelections[tabValue]?.strengthIntervalHours &&
                  disableCheckbox.failureZone
                    ? `To include failure zone please select ${getIntervalString(
                        mixSelections[tabValue]?.strengthIntervalHours as number
                      )} maturity age`
                    : ''
                }
                placement="top-start"
                arrow
                style={{ padding: '0px' }}
              >
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="failureZone"
                        name="failureZone"
                        color="primary"
                        disabled={disableCheckbox.failureZone}
                        checked={graphSettings.showFailureZone}
                        onChange={() => handleClick('showFailureZone')}
                        data-testid="checkbox-failureZone"
                      />
                    }
                    label={<Typography>Failure Zone</Typography>}
                  />
                </span>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip
                title={
                  disableCheckbox.bellCurve
                    ? 'At least one graph type is required'
                    : ''
                }
                placement="top-start"
                arrow
                style={{ padding: '0px' }}
              >
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="bellCurve"
                        name="bellCurve"
                        color="primary"
                        disabled={disableCheckbox.bellCurve}
                        checked={graphSettings.showBellCurve}
                        onChange={() => handleClick('showBellCurve')}
                        className={classes.checkbox}
                        data-testid="checkbox-bellCurve"
                      />
                    }
                    label={
                      <Typography className={classes.checkboxText}>
                        Bell Curve
                      </Typography>
                    }
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  disableCheckbox.histogram
                    ? 'At least one graph type is required'
                    : ''
                }
                placement="top"
                arrow
              >
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="histogram"
                        name="histogram"
                        color="primary"
                        disabled={disableCheckbox.histogram}
                        checked={graphSettings.showHistogram}
                        onChange={() => handleClick('showHistogram')}
                        className={classes.checkbox}
                        data-testid="checkbox-histogram"
                      />
                    }
                    label={
                      <Typography className={classes.checkboxText}>
                        Histogram
                      </Typography>
                    }
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TestResultsHistogramSettings
