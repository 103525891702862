import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CommissioningDialogPresentational from './CommissioningDialogPresentational'
import { putUpdateCommissioning } from '../../Data/TSSDataHelpers'
import { commissioningWarnings } from '../../Constants/CustomersPageConstants'

function CommissioningDialogLogical(props) {
  CommissioningDialogLogical.propTypes = {
    /** Boolean to determine whether the modal is open */
    open: PropTypes.bool.isRequired,
    /** State setter to open and close the modal */
    setOpen: PropTypes.func.isRequired,
    /** The table row that has had its Commissioning Complete checkbox clicked */
    commissioningRow: PropTypes.object.isRequired,
    /** State setter that updates boolean determining whether a customer just been marked Commissioning Complete */
    setIsCommissioningComplete: PropTypes.func.isRequired,
  }
  const { open, setOpen, commissioningRow, setIsCommissioningComplete } = props

  /** The content to display in the modal */
  const [modalContent, setModalContent] = useState({
    customer: '',
    warnings: {},
    lowSamples: true,
  })

  /** Update the commissioning modal content and styling according to whether the selected customer has enough samples */
  useEffect(() => {
    if (commissioningRow.designsmediumReactivitySamples < 2) {
      setModalContent({
        customer: commissioningRow.divisionName,
        warnings: commissioningWarnings.lowSamples,
        lowSamples: true,
      })
    } else {
      setModalContent({
        customer: commissioningRow.divisionName,
        warnings: commissioningWarnings.sufficientSamples,
        lowSamples: false,
      })
    }
  }, [
    commissioningRow.designsmediumReactivitySamples,
    commissioningRow.divisionName,
  ])

  /** Function to close the modal */
  const handleClose = () => {
    setOpen(false)
  }

  /** Upate the selected division to Commissioning Complete status */
  const updateCommissioningStatus = () => {
    return putUpdateCommissioning(commissioningRow.divisionId)
      .then(setIsCommissioningComplete(true))
      .then(handleClose)
  }

  return (
    <CommissioningDialogPresentational
      open={open}
      handleClose={handleClose}
      modalContent={modalContent}
      updateCommissioningStatus={updateCommissioningStatus}
    />
  )
}

export default CommissioningDialogLogical
