import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Button,
  Dialog,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import ServiceCaseOverview from '../Components/ServiceCaseOverview'
import TabPanel from '../../../Common/Components/TabPanel'
import RemoteTroubleshooting from '../Components/RemoteTroubleshooting'
import OnsiteService from '../Components/OnsiteService'
import ServiceCaseNotesLogical from '../Components/ServiceCaseNotesLogical'
import EditButton from '../Components/EditButton'

const useStyles = makeStyles(theme => ({
  modal: {
    padding: '2em 4em',
  },
  modalHeader: {
    marginBottom: '2em',
  },
  modalTabs: {
    width: '100%',
    marginTop: '2em',
  },
  tabLabel: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'capitalize',
  },
  innerTabPanel: {
    padding: '3em',
  },
  notes: {
    marginTop: '2em',
  },
  submitButton: {
    marginTop: '2em',
  },
  input: {
    '& input': {
      background: '#FFFFFF',
    },
  },
  indicator: {
    marginLeft: '10px',
    maxWidth: 200,
  },
}))

function ServiceCaseModalPresentational(props) {
  const {
    open,
    selectedTab,
    serviceCaseSource,
    tabValue,
    editModeActive,
    handleChange,
    handleClose,
    setEditModeActive,
    handleSubmit,
    setServiceCaseDraft,
    serviceCaseDraft,
    systemDownDateObj,
    systemUpDateObj,
    handleChangeSystemUp,
    handleChangeSystemDown,
    hmiId,
  } = props

  const classes = useStyles()

  const disabled = selectedTab === 'Service Cases' && !editModeActive

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <Paper className={classes.modal} variant="outlined">
        <Grid container>
          <Grid
            item
            container
            justify="space-between"
            className={classes.modalHeader}
          >
            <Grid>
              <Typography variant="h2">
                {selectedTab === 'Service Cases'
                  ? 'View/Edit Service Case'
                  : 'Create Service Case'}
              </Typography>
            </Grid>
            <Grid>
              {selectedTab === 'Service Cases' ? (
                <EditButton
                  editModeActive={editModeActive}
                  setEditModeActive={setEditModeActive}
                />
              ) : null}
              <Button color="primary" onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
          <ServiceCaseOverview
            serviceCaseSource={serviceCaseSource}
            disabled={disabled}
            inputClassName={classes.input}
            systemDownDateObj={systemDownDateObj}
            systemUpDateObj={systemUpDateObj}
            handleChangeSystemUp={handleChangeSystemUp}
            handleChangeSystemDown={handleChangeSystemDown}
            hmiId={hmiId}
            caseId={serviceCaseDraft?.serviceCaseId}
          />
          <Grid className={classes.modalTabs}>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab
                  label={
                    <span className={classes.tabLabel}>
                      Remote Troubleshooting
                    </span>
                  }
                />
                <Tab
                  label={
                    <span className={classes.tabLabel}>Onsite Service</span>
                  }
                />
              </Tabs>
            </Box>
            <TabPanel tabValue={tabValue} index={0}>
              <Paper variant="outlined" className={classes.innerTabPanel}>
                <RemoteTroubleshooting
                  serviceCaseSource={serviceCaseSource}
                  disabled={disabled}
                  selectedTab={selectedTab}
                  inputClassName={classes.input}
                  setServiceCaseDraft={setServiceCaseDraft}
                />
              </Paper>
            </TabPanel>
            <TabPanel tabValue={tabValue} index={1}>
              <Paper variant="outlined" className={classes.innerTabPanel}>
                <OnsiteService
                  inputClassName={classes.input}
                  disabled={disabled}
                  setServiceCaseDraft={setServiceCaseDraft}
                />
              </Paper>
            </TabPanel>
          </Grid>
          <Grid container item className={classes.notes}>
            <ServiceCaseNotesLogical
              selectedTab={selectedTab}
              setServiceCaseDraft={setServiceCaseDraft}
              serviceCaseDraft={serviceCaseDraft}
              disabled={disabled}
            />
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            className={classes.submitButton}
          >
            <Button
              disabled={!editModeActive && selectedTab === 'Service Cases'}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Save & submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

ServiceCaseModalPresentational.propTypes = {
  /** Boolean to determine whether the service case modal is open */
  open: PropTypes.bool.isRequired,
  /** The name of the selected tab */
  selectedTab: PropTypes.string.isRequired,
  /** The service case displayed when the modal opens: existing or new */
  serviceCaseSource: PropTypes.object,
  /** The value of the currently selected tab */
  tabValue: PropTypes.number.isRequired,
  /** Boolean to determine whether a service case is being edited */
  editModeActive: PropTypes.bool.isRequired,
  /** Function to switch to a different tab */
  handleChange: PropTypes.func.isRequired,
  /** Function to close the service case modal */
  handleClose: PropTypes.func.isRequired,
  /** State setter to toggle edit mode for the service case modal */
  setEditModeActive: PropTypes.func.isRequired,
  /** Function to submit/save the service case changes */
  handleSubmit: PropTypes.func.isRequired,
  /** State setter to save draft of service case changes */
  setServiceCaseDraft: PropTypes.func.isRequired,
  /** Draft of the service case changes */
  serviceCaseDraft: PropTypes.object.isRequired,

  systemDownDateObj: PropTypes.instanceOf(Date),

  handleChangeSystemDown: PropTypes.func.isRequired,

  systemUpDateObj: PropTypes.instanceOf(Date),

  handleChangeSystemUp: PropTypes.func.isRequired,
}

ServiceCaseModalPresentational.defaultProps = {
  systemDownDateObj: null,
  systemUpDateObj: null,
}

export default ServiceCaseModalPresentational
