import React from 'react'
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function GraphFilterDateSelector(props) {
  GraphFilterDateSelector.propTypes = {
    /** Function updates the currently selected range of a filter. */
    updateFilterSelectedRange: PropTypes.func.isRequired,
    /** GraphFilter object containing the property, total range, and currently selected range.*/
    graphFilter: PropTypes.object.isRequired,
  }

  const { graphFilter, updateFilterSelectedRange } = props

  /**
   * Updates the earliest date in a selected range, passed to TextField onChange.
   * @param {object} event onChange event containing the new value.
   */
  const updateDateRangeMin = event => {
    const dateMin = event.target.value
    updateFilterSelectedRange(
      graphFilter,
      dateMin,
      graphFilter.selectedMaxValue
    )
  }

  /**
   * Updates the most recent date in a selected range, passed to TextField onChange.
   * @param {object} event onChange event containing the new value.
   */
  const updateDateRangeMax = event => {
    const dateMax = event.target.value
    updateFilterSelectedRange(
      graphFilter,
      graphFilter.selectedMinValue,
      dateMax
    )
  }

  /**
   * Returns a component for selecting a date.
   * @param {String} value the selected date to display in the range selector.
   * @param {Function} updateDateRange function responsible for updating the range, passed to onChange.
   * @returns {TextField} dropdown component to render.
   */
  const renderDateDropdown = (value, updateDateRange) => (
    <TextField
      select
      size="small"
      variant="outlined"
      onChange={updateDateRange}
      value={value}
    >
      {graphFilter.options.map(date => (
        <MenuItem key={date} value={date}>
          {date}
        </MenuItem>
      ))}
    </TextField>
  )

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={8} lg={5}>
        {renderDateDropdown(graphFilter.selectedMinValue, updateDateRangeMin)}
      </Grid>
      <Grid container justify="center" item xs={12} lg={2}>
        <Typography>To</Typography>
      </Grid>
      <Grid item xs={8} lg={5}>
        {renderDateDropdown(graphFilter.selectedMaxValue, updateDateRangeMax)}
      </Grid>
    </Grid>
  )
}
