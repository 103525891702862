import { useFusionAuth } from '@carboncure/fusion-auth-component'

/**
 * The component is required to be within the auth provider context.
 * @returns null, but replaces the window URL with the loginURL initialized by the verifier and PKCE
 */
function RedirectToFusionAuthLogin() {
  const [, , , loginURL] = useFusionAuth()
  window.location.replace(loginURL)
  return null
}

export default RedirectToFusionAuthLogin
