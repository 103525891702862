import React from 'react'
import PropTypes from 'prop-types'
import { onlyUnique } from '../../../Common/Helpers/GeneralHelpers'
import SalesPersonSelectPresentational from './SalesPersonSelectPresentational'
import { useRecoilState } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'

SalesPersonSelectLogical.propTypes = {
  /** Filtered Echo data */
  filteredCorpData: PropTypes.array.isRequired,
}

function SalesPersonSelectLogical(props) {
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  const { filteredCorpData } = props

  /** Return all sales people on file with the Echo data */
  const salesPeople = filteredCorpData
    .map(company =>
      company.divisions.map(division =>
        division.plants
          .filter(plant => plant.salesperson != null)
          .map(plant => plant.salesperson)
      )
    )
    .flat()
    .flat()
    .filter(onlyUnique)
    .sort()

  /** Handle input change to filter Echo data by sales person */
  const handleChange = (event, value) => {
    const newSettings = cloneDeep(echoSettings)
    newSettings.salesPeople = value ? [value] : []
    setEchoSettings(newSettings)
  }

  return (
    <SalesPersonSelectPresentational
      handleChange={handleChange}
      salesPeople={salesPeople}
    />
  )
}

export default SalesPersonSelectLogical
