import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TextField,
  Card,
  makeStyles,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { addMixMaterialTooltips } from '../Constants/AddDataConstants'

const useStyles = makeStyles({
  selectCell: {
    maxWidth: '10em',
    minWidth: '6em',
    height: '42px',
  },
  inputCell: {
    maxWidth: '10em',
    minWidth: '7em',
    height: '42px',
  },
  addRowButton: {
    margin: '2em 0em',
  },
  tableHeader: {
    backgroundColor: '#7070700A',
  },
})

/** Convert array into table ready data. */
export const createTableData = (columns, data) => {
  return data.map(row => {
    const materialId = row.materialId
    const tableRow = { materialId }

    /* Create tablerow objects by matching data rows to columns. */
    columns.forEach((colName, i) => {
      if (row.materialcells?.[i] !== undefined)
        tableRow[colName] = row.materialcells[i]
    })

    return tableRow
  })
}

EditableTable.propTypes = {
  /** Data from parent component. Array of objects, can be empty or created with createTableData. */
  data: PropTypes.array.isRequired,
  /** Sets data in parent component when saved in table. */
  setData: PropTypes.func.isRequired,
  /** List of all column names in table. */
  columns: PropTypes.array.isRequired,
  /** List of column names which do not require values. */
  optionalColumns: PropTypes.array,
  /** Object with col names as key and dropdown values as the value. Optional. */
  dropCols: PropTypes.object,
  /** Object with col names as key and HTML input type as val. Optional. */
  colTypes: PropTypes.object,
  /** Function that sets a flag in the parent component indicating whether the user has input data. Optional. */
  setHasUserInput: PropTypes.func,
  /** Boolean flag that indicates whether the table is read-only. Optional. */
  isViewOnly: PropTypes.bool,
}

/**
 * Component displays data in MUI table and allows editing of data.
 * @param {Array} data - data from parent component. Array of objects, can be empty or created with createTableData.
 * @param {Function} setData - sets data in parent component when saved in table.
 * @param {String[]} columns - list of all column names in table.
 * @param {String[]} optionalColumns - list of column names which do not require values.
 * @param {Object} dropCols - object with col names as key and dropdown values as the value. Optional.
 * @param {Object} colTypes - object with col names as key and HTML input type as val. Optional.
 */
function EditableTable(props) {
  const classes = useStyles()
  const {
    data,
    setData,
    columns,
    dropCols,
    setHasUserInput,
    isViewOnly,
  } = props

  /* A local dataset is used so that changes to data are only updated in the parent in save event. */
  const [rows, setRows] = useState(data)
  const [errors, setErrors] = useState(new Set())

  useEffect(() => {
    setRows(data)
  }, [data])

  /* Handle changes within an editable row. Does not save changes to original data. */
  const changeRowData = (selectedRow, selectedCol, newVal) => {
    const newRows = rows.map(row => {
      if (
        row.materialId === selectedRow.materialId &&
        row.subtype === selectedRow.subtype
      ) {
        return { ...row, [selectedCol]: newVal }
      }
      return row
    })
    setRows(newRows)
  }

  /* Save changed row to props data after some validation. */
  const saveRowData = selectedRow => () => {
    /* Track whether a new error appears on current row, and append to all errors. */
    let errorInRow = false

    /* Set to list of errors so that error appears on input fields on render. */
    setErrors(prevErrors => {
      const errorSet = new Set(prevErrors)
      const requiredCols = ['quantity', 'units']
      requiredCols.forEach(col => {
        const errorId = col + selectedRow.materialId
        if (selectedRow[col]) {
          errorSet.delete(errorId)
        } else {
          errorSet.add(errorId)
          errorInRow = true
        }
      })
      return errorSet
    })

    /* Prevent saving the row if there are unfilled values and exit function. */
    if (errorInRow) return

    /* Update prop table with saved data. */
    setData(prevItems =>
      prevItems.map(dataRow => {
        if (
          dataRow.materialId === selectedRow.materialId &&
          dataRow.subtype === selectedRow.subtype
        ) {
          return selectedRow
        }
        return dataRow
      })
    )
  }

  /* Render a selection entry field. */
  const renderEditSelect = (selectOptions, row, col) => (
    <Autocomplete
      options={selectOptions}
      value={row[col]}
      id="editable-table-dropdown"
      disabled={!selectOptions || isViewOnly}
      renderInput={params => (
        <TextField
          {...params}
          label={col}
          variant="outlined"
          size="small"
          name="materialsTableAutocomplete"
          placeholder="Select Type"
          error={errors.has(col + row.id)}
        />
      )}
      onChange={(_, newValue) => {
        changeRowData(row, col, newValue)
        // Make unit changes persist if you change them before entering quantities
        if (col === 'units') {
          setData(prevItems =>
            prevItems.map(dataRow => {
              if (dataRow.materialId === row.materialId) {
                dataRow.units = newValue
              }
              return dataRow
            })
          )
        }
        setHasUserInput(true)
      }}
      onBlur={saveRowData(row)}
    />
  )

  /* Render an input entry field, checking for errors and setting any types. */
  const renderEditInput = (row, col) => (
    <TextField
      value={row[col]}
      name={col}
      size="small"
      variant="outlined"
      className={classes.inputCell}
      data-testid={`input-${col}-${row[col]}`}
      error={errors.has(col + row.id)}
      type={'number'}
      inputProps={{
        min: 0,
        step: 0.0000000001, // Allow decimals with a precision of 10
      }}
      onChange={e => {
        const newVal = parseFloat(e.target.value)
        changeRowData(row, col, newVal)
        setHasUserInput(true)
      }}
      onBlur={saveRowData(row)}
      disabled={isViewOnly}
    />
  )

  /* Render select edit if dropdown column prop exits, otherwise render the input edit. */
  const editableCell = (row, col) =>
    dropCols?.[col]
      ? renderEditSelect(dropCols[col], row, col)
      : renderEditInput(row, col)

  /* Convert camelcase column names to headers. */
  const colHeaders = columns.map(colName => {
    const headerName = colName.replace(/([A-Z])/g, ' $1')
    return headerName.charAt(0).toUpperCase() + headerName.slice(1)
  })

  return (
    <>
      <TableContainer
        component={Card}
        variant="outlined"
        style={{
          marginBottom: '2em',
          paddingBottom: rows.length ? '1em' : '5em',
        }}
      >
        <Box padding="1em">
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {colHeaders.map(col =>
                  addMixMaterialTooltips[col] ? (
                    <Tooltip
                      key={col}
                      title={addMixMaterialTooltips[col]}
                      placement="top-start"
                    >
                      <TableCell align="left">
                        <Typography style={{ fontWeight: 500 }}>
                          {col}
                        </Typography>
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell key={col} align="left">
                      <Typography style={{ fontWeight: 500 }}>{col}</Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  {Object.keys(row).map(
                    col =>
                      col !== 'materialId' && ( // Skip rendering meta data.
                        <TableCell
                          align="left"
                          key={col + row.id}
                          style={{
                            width: ['alias', 'units'].includes(col)
                              ? '15%'
                              : '13%',
                          }}
                        >
                          {['quantity', 'units'].includes(col) ? (
                            editableCell(row, col)
                          ) : (
                            <Typography>
                              {Array.isArray(row[col])
                                ? row[col].map(element => element).join(', ')
                                : row[col]}
                            </Typography> //display array with spacing
                          )}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  )
}

export default EditableTable
