import { useState, useEffect } from 'react'
import downSystemsHelper from './DownSystemsHelper'
import { REFRESH_INTERVAL_ALARMS } from '../../../Hardware/Sonar/Constants/SonarConstants'

export const useAlarms = () => {
  const [lastRefreshed, setLastRefreshed] = useState<string>(
    downSystemsHelper.formatTime(new Date()) // Initial state set with formatted time
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshed(downSystemsHelper.formatTime(new Date()))
    }, REFRESH_INTERVAL_ALARMS)

    return () => clearInterval(interval)
  }, [])

  return {
    lastRefreshed,
  }
}

const hooks = {
  useAlarms,
}

export default hooks
