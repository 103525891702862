import {
  CommissionReportOutlierRow,
  ICommissionReportSettings,
  IMixSelection,
  VariationTypes,
} from '../../Logic/Types'
import {
  getIntervalString,
  outlierReasonOptions,
} from '../../Helpers/BaleenHelpers'

export const MixSelectionMockData = [
  { value: 'testId', label: 'Test Name' },
  { value: 'testId2', label: 'Test Name 2' },
]

export const getOutlierRows = (
  mixSelection: IMixSelection,
  variationType: VariationTypes
) => {
  const variation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === variationType
  )
  if (!variation) return []

  const outliers = variation.samples.filter(sample => sample.isOutlier)
  const outliersRows: CommissionReportOutlierRow[] = outliers.map(outlier => ({
    id: outlier.ticketId,
    description:
      variationType === VariationTypes.BASELINE
        ? VariationTypes.BASELINE
        : VariationTypes.OPTIMIZEDALT,
    reason:
      outlierReasonOptions[outlier.outlierReason] || outlier.outlierReason,
  }))
  //sort the rows by ticket id
  outliersRows.sort((a, b) => a.id.localeCompare(b.id))
  return outliersRows
}

export const getCommissionReportBaleenUrl = (
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings | undefined
) => {
  const baselineVariation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === VariationTypes.BASELINE
  )
  const carbonCureVariation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
  )
  const path = `divisionId=${reportSettings?.producerId}&plantId=${reportSettings?.plantId}&mixGroup=${mixSelection?.mixDesignId}&variationIds=${baselineVariation?.variationId}&variationIds=${carbonCureVariation?.variationId}`
  return path
}

export const getMaturityIntervalOptions = (mixSelection: IMixSelection) => {
  const optionLabels =
    mixSelection?.intervalOptions?.map(option => ({
      value: option,
      label: getIntervalString(option),
    })) ?? []
  return optionLabels
}

export const sortGraphDataAndLabels = (
  optimizedData: number[],
  baselineData: number[],
  intervalLabels: number[]
) => {
  const indices = intervalLabels?.map((_, index) => index)
  indices.sort((a, b) => intervalLabels[a] - intervalLabels[b])

  const sortedOptimizedData = indices?.map(index => optimizedData[index])
  const sortedBaselineData = indices?.map(index => baselineData[index])
  const sortedIntervalLabels = indices?.map(index =>
    getIntervalString(intervalLabels[index])
  )

  return { sortedOptimizedData, sortedBaselineData, sortedIntervalLabels }
}
