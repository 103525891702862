import { ServiceCaseStatusMapping } from '../Constants/Constants'

class ServiceCaseTableObject {
  serviceCaseId
  remoteUnitId
  alarmId
  status
  faultyComponent
  uniqueIssue
  customerLocation
  hmiId
  caseOpenedTimestamp
  caseClosedTimestamp

  /**
   *
   * @param {Object} kelownaObject
   * @param {Number} kelownaObject.serviceCaseId
   * @param {Number} kelownaObject.remoteUnitId
   * @param {Number} kelownaObject.alarmId
   * @param {String} kelownaObject.status
   * @param {String} kelownaObject.faultyComponent
   * @param {Boolean} kelownaObject.uniqueIssue
   * @param {String} kelownaObject.customerLocation
   * @param {Number} kelownaObject.hmiId
   * @param {String} kelownaObject.caseClosedTimestamp
   * @param {String} kelownaObject.caseOpenedTimestamp
   */
  constructor(kelownaObject) {
    this.serviceCaseId = kelownaObject.serviceCaseId
    this.remoteUnitId = kelownaObject.remoteUnitId
    this.alarmId = kelownaObject.alarmId
    this.status = kelownaObject.status
    this.faultyComponent = kelownaObject.faultyComponent
    this.uniqueIssue = kelownaObject.uniqueIssue
    if (kelownaObject.customerLocation === ', ') {
      this.customerLocation = 'Location Not Available'
    } else {
      this.customerLocation = kelownaObject.customerLocation
    }
    this.hmiId = kelownaObject.hmiId
    this.caseOpenedTimestamp = new Date(kelownaObject.caseOpenedTimestamp)
    if (kelownaObject.caseClosedTimestamp != null) {
      this.caseClosedTimestamp = new Date(kelownaObject.caseClosedTimestamp)
    }
    this.caseClosedTimestamp = null
  }

  get caseOpenedTimeString() {
    return this.caseOpenedTimestamp.toLocaleDateString()
  }

  get caseClosedTimeString() {
    if (this.caseClosedTimestamp != null) {
      return this.caseClosedTimestamp.toLocaleString()
    }
    return null
  }

  get uniqueIssueString() {
    if (this.uniqueIssue) {
      return 'Unique'
    }
    return 'Common'
  }

  get statusString() {
    return ServiceCaseStatusMapping[this.status]
  }
}

export default ServiceCaseTableObject
