import { CircularProgress } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { useState, useEffect } from 'react'

/**
 * Displays a feedback icon, loader, or nothing as specified.
 * @param {string} feedback Takes the string "loading", "success", "error", and "nothing"
 * @param {string} key the key to assign the progress
 */
function useFeedback(feedback = 'nothing', key = '') {
  const [feedbackSelected, setFeedbackSelected] = useState(feedback)
  const [feedbackIcon, setFeedBackIcon] = useState()

  useEffect(() => {
    const icons = {
      loading: <CircularProgress key={`loading_${key}`} />,
      success: <CheckCircleIcon color="primary" key={`success_${key}`} />,
      error: <ErrorIcon color="primary" key={`error_${key}`} />,
      nothing: <></>,
    }
    setFeedBackIcon(icons[feedbackSelected])
  }, [feedbackSelected, key])

  return [feedbackIcon, setFeedbackSelected]
}

export default useFeedback
