import React from 'react'
import PropTypes from 'prop-types'
import ServiceCasesTablePresentational from './ServiceCasesTablePresentational'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'

function ServiceCasesTableLogical(props) {
  const {
    handleTableSort,
    serviceCases,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    selectedServiceCase,
    setSelectedServiceCase,
    count,
  } = props

  /** Function to handle selecting table rows */
  const handleClick = (event, row) => {
    if (selectedServiceCase === row) {
      setSelectedServiceCase(null)
    } else {
      setSelectedServiceCase(row)
    }
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(Number(event.target.value, 10))
  }

  /** Table column headers */
  const headCells = [
    {
      id: 'caseOpenedTimestamp',
      numeric: false,
      label: 'Case Opened',
      sortable: true,
    },
    {
      id: 'serviceCaseId',
      numeric: false,
      label: 'Case ID',
      sortable: true,
    },
    {
      id: 'hmiID',
      numeric: false,
      label: 'HMI ID',
      sortable: true,
    },
    {
      id: 'customerLocation',
      numeric: false,
      label: 'Customer Location',
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      sortable: true,
    },
    {
      id: 'faultyComponent',
      numeric: false,
      label: 'Faulty Component',
      sortable: true,
    },
    {
      id: 'uniqueIssue',
      numeric: false,
      label: 'Unique Issue',
      sortable: true,
    },
  ]

  return (
    <ServiceCasesTablePresentational
      serviceCases={serviceCases}
      handleClick={handleClick}
      selectedServiceCase={selectedServiceCase}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleTableSort={handleTableSort}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      order={order}
      orderBy={orderBy}
      headCells={headCells}
    />
  )
}

ServiceCasesTableLogical.propTypes = {
  /** Function to sort the data table rows */
  handleTableSort: PropTypes.func.isRequired,
  /** Order of the sorted table column: ascending or descending */
  order: PropTypes.string.isRequired,
  /** Name of the table column to sort by */
  orderBy: PropTypes.string.isRequired,
  /** Data from the service cases endpoint */
  serviceCases: PropTypes.array.isRequired,
  /** Current page number of the table */
  page: PropTypes.number.isRequired,
  /** State setter to update table page */
  setPage: PropTypes.func.isRequired,
  /** Number of table rows to display per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** State setter to update number of table rows per page */
  setRowsPerPage: PropTypes.func.isRequired,
  /** State setter function to keep track of which service case has been selected */
  setSelectedServiceCase: PropTypes.func.isRequired,
  /** Keep track of which service case has been selected */
  selectedServiceCase: PropTypes.instanceOf(ServiceCaseTableObject),
  /** Count given by the table endpoint */
  count: PropTypes.number.isRequired,
}
export default ServiceCasesTableLogical
