import React from 'react'
import { ICommissionReportSettings } from '../../Logic/Types'
import { Grid, Typography } from '@material-ui/core'

interface IViewCommissionReportConclusion {
  reportSettings: ICommissionReportSettings
}

const ViewCommissionReportConclusion = (
  props: IViewCommissionReportConclusion
) => {
  const { reportSettings } = props
  const conclusionText = reportSettings?.conclusionParagraph

  // Convert newline characters to HTML line breaks
  const formattedConclusion = conclusionText?.split('\n').map(line => (
    <React.Fragment key={line}>
      {line}
      <br />
    </React.Fragment>
  ))

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h3">Conclusion & Next Steps</Typography>
        <Typography variant="body1">{formattedConclusion}</Typography>
      </Grid>
    </Grid>
  )
}

export default ViewCommissionReportConclusion
