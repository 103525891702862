//File that will host any methods that can be used in many places that have to do with fetching,putting, or posting data
//TODO change to include get, post, and put requests for endpoints instead of them living in the components

/**
 * A helper method to fetch data from Kelowna, retrieves the address of Kelowna from an environment variable
 * defiend in the .env file
 *
 * @param {String} endpoint The endpoint you're trying to reach, with the ID and importantly a leading slash
 * @param {Object} params the search parameters you'd like to search on
 */
export async function fetchDataHelper(endpoint, params = {}) {
  const token = localStorage.getItem('JWT')
  const url = new URL([process.env.REACT_APP_KELOWNA, endpoint].join(''))
  const urlSearchParams = new URLSearchParams(params)
  url.search = urlSearchParams
  return fetch(url, {
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    credentials: 'omit',
  }).then(
    res => {
      return res
    },
    //according to the docs you don't want to catch errors, just handle them with a callback
    error => {
      console.error(error)
      return error
    }
  )
}

export function getHost() {
  return process.env.REACT_APP_KELOWNA
}

export async function putDataHelper(endpoint, data = {}) {
  const token = localStorage.getItem('JWT')
  return fetch([process.env.REACT_APP_KELOWNA, endpoint].join(''), {
    method: 'PUT',
    mode: 'cors',

    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
    credentials: 'omit',
  })
}

export async function postDataHelper(endpoint, data = {}) {
  const token = localStorage.getItem('JWT')
  return fetch([process.env.REACT_APP_KELOWNA, endpoint].join(''), {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
    credentials: 'omit',
  })
}

export async function putMasterUnit(id, masterUnitData) {
  const endpoint = `/Hardware/Mixer/MasterUnits/${id}`
  return putDataHelper(endpoint, masterUnitData)
}

export async function postMasterUnit(masterUnitData) {
  const endpoint = '/Hardware/Mixer/MasterUnits'

  return postDataHelper(endpoint, masterUnitData)
}

export async function postRemoteUnit(remoteUnitData) {
  const endpoint = '/Hardware/Mixer/RemoteUnits'
  return postDataHelper(endpoint, remoteUnitData)
}

export async function postMasterCommand(commandData) {
  const endpoint = '/Hardware/Mixer/Commands'
  return postDataHelper(endpoint, commandData)
}

export async function putRemoteUnit(id, remoteUnitData) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${id}`
  return putDataHelper(endpoint, remoteUnitData)
}

export async function putRemoteUnitConfiguration(id, remoteUnitConfiguration) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${id}/Configuration`
  return postDataHelper(endpoint, remoteUnitConfiguration)
}
export async function getAllCorporations() {
  const endpoint = '/Customers/Management/Corporations'
  return fetchDataHelper(endpoint)
}

export async function getAllMasterUnits() {
  const endpoint = '/Hardware/Mixer/MasterUnits'
  return fetchDataHelper(endpoint)
}

export async function getMasterUnit(id) {
  const endpoint = `/Hardware/Mixer/MasterUnits/${id}`
  return fetchDataHelper(endpoint)
}

export async function getPlant(id) {
  const endpoint = `/Customers/Management/Plants/${id}`
  return fetchDataHelper(endpoint)
}

export async function getDivision(id) {
  const endpoint = `/Customers/Management/Divisions/${id}`
  return fetchDataHelper(endpoint)
}

export async function getCorporation(id) {
  const endpoint = `/Customers/Management/Corporations/${id}`
  return fetchDataHelper(endpoint)
}

export async function getRemoteAlarms(id) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${id}/Alarms`
  return fetchDataHelper(endpoint)
}

export async function getRemoteStatuses(id, limit = 10) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${id}/Statuses?limit=${limit}`
  return fetchDataHelper(endpoint)
}

export async function getRemoteInjections(id, limit = 10) {
  const endpoint = `/Hardware/Mixer/RemoteUnits/${id}/Injections?limit=${limit}`
  return fetchDataHelper(endpoint)
}

export async function getMasterUnitCommands(id) {
  const endpoint = `/Hardware/Mixer/MasterUnits/${id}/Commands`
  return fetchDataHelper(endpoint)
}

export async function getMasterUnitBoots(id) {
  const endpoint = `/Hardware/Mixer/MasterUnits/${id}/Boots`
  return fetchDataHelper(endpoint)
}

export async function getPlantsNoMasterUnits() {
  const endpoint = '/Customers/Management/Corporations/WithoutMixerUnits'
  return fetchDataHelper(endpoint)
}

export async function getOverviewTableRows(
  plantsWithAlarms = false,
  sortOrder = '',
  limit = 15,
  offset = 0,
  includeLegacy = true,
  sortColumn = ''
) {
  const endpoint = '/Customers/Management/Plants/Overview'
  const parameters = {
    sortColumn,
    sortOrder,
    limit,
    offset,
    includeLegacy,
    plantsWithAlarms,
  }
  if (sortOrder === '') {
    delete parameters.sortOrder
  }
  if (sortColumn === '') {
    delete parameters.sortColumn
  }
  if (limit === -1) {
    delete parameters.limit
    delete parameters.offset
  }
  return fetchDataHelper(endpoint, parameters)
}

export async function getMasterUnitSession(id) {
  const endpoint = `/Hardware/Mixer/MasterUnits/${id}/Tunnel`

  return postDataHelper(endpoint)
}

export async function getUnassignedMasterUnits() {
  const endpoint = `/Hardware/Mixer/MasterUnits/UnassignedUnits`

  return fetchDataHelper(endpoint)
}

export async function getCurrentTunnels() {
  const endpoint = `/Hardware/Mixer/MasterUnits/Tunnel`

  return fetchDataHelper(endpoint)
}
