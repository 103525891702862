import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'

function EngTableHead(props) {
  EngTableHead.defaultProps = { includeCheckbox: false }
  EngTableHead.propTypes = {
    /** Order of the data table rows, ascending or descending */
    order: PropTypes.string.isRequired,
    /** Data table column by which to set the order of the rows */
    orderBy: PropTypes.string,
    /** State setter function to set the order of the rows based on a particular column */
    onRequestSort: PropTypes.func.isRequired,
    /** Table Column headers */
    headCells: PropTypes.array.isRequired,
  }

  const { order, orderBy, onRequestSort, headCells, includeCheckbox } = props

  /** Function to sort the table rows */
  const createSortHandler = headCell => event => {
    if (headCell.sortable) {
      onRequestSort(event, headCell.id)
    }
  }

  const getOrderDirection = headCellId => {
    return orderBy === headCellId ? order : 'asc'
  }

  return (
    <TableHead>
      <TableRow>
        {includeCheckbox ? <TableCell padding="checkbox" /> : null}
        {/* Table head cells with sort functionality */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={getOrderDirection(headCell.id)}
                onClick={createSortHandler(headCell)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EngTableHead
