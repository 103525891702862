import React from 'react'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid } from '@material-ui/core'

require('highcharts/modules/annotations')(Highcharts)

interface CommissioningBarGraphPresentationalProps {
  chartOptions: Options
  setRef: (...args: any) => any
}

function CommissioningBarGraphPresentational(
  props: CommissioningBarGraphPresentationalProps
) {
  return (
    <Grid item xs={12} style={{ alignContent: 'center', width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={props.chartOptions}
        ref={props.setRef}
      />
    </Grid>
  )
}

export default CommissioningBarGraphPresentational
