import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

CountrySelectPresentational.propTypes = {
  /** Options (countries) for the dropdown */
  uniqueCountries: PropTypes.array.isRequired,
  /** Function called when country is selected */
  handleChange: PropTypes.func.isRequired,
  /** Value of the dropdown */
  dropdownValue: PropTypes.string,
}

function CountrySelectPresentational(props) {
  const { handleChange, uniqueCountries, dropdownValue } = props

  return (
    <Autocomplete
      id="countrySelect"
      value={dropdownValue}
      onChange={handleChange}
      options={uniqueCountries}
      getOptionLabel={option => option}
      getOptionSelected={(option, value) => option === value}
      renderInput={params => (
        <TextField
          {...params}
          label="Countries"
          variant="outlined"
          size="small"
          placeholder="Select a country..."
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default CountrySelectPresentational
