import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

ZipCodeSearchPresentational.propTypes = {
  /** Function called when zip code search field value changes */
  handleChange: PropTypes.func.isRequired,
  /** Function called when zip code search is submitted */
  handleSubmit: PropTypes.func.isRequired,
  /** Boolean to display or not display error for zip code search filter */
  zipCodeError: PropTypes.bool.isRequired,
}

function ZipCodeSearchPresentational(props) {
  const { handleChange, handleSubmit, zipCodeError } = props

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        onChange={handleChange}
        label="Search"
        variant="outlined"
        size="small"
        placeholder="Search"
        id="zipCodeSearch"
        error={zipCodeError}
        helperText={zipCodeError ? 'Zip code not found' : ''}
      />
    </form>
  )
}

export default ZipCodeSearchPresentational
