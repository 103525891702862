import { ScatterplotLayer } from '@deck.gl/layers'
import { getOpportunityColourAndOpacity } from '../MapComponentHelpers'
export function getOpportunitiesLayer(filteredSalesOpportunities) {
  return [
    new ScatterplotLayer({
      radiusUnits: 'pixels',
      id: 'opportunities',
      data: filteredSalesOpportunities.map(opportunity => ({
        ...opportunity,
        layerType: 'opportunity',
      })),
      pickable: true,
      stroked: false,
      filled: true,
      getRadius: opportunity => {
        if (opportunity.plantCount > 20) {
          return 40
        } else if (opportunity.plantCount > 10) {
          return 30
        } else if (opportunity.plantCount > 5) {
          return 20
        } else {
          return 10
        }
      },
      getPosition: opportunity => [opportunity.longitude, opportunity.latitude],
      getFillColor: opportunity => {
        return getOpportunityColourAndOpacity(opportunity)
      },
    }),
  ]
}
