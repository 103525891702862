import React, { useState, useEffect } from 'react'
import { EMapLayers } from '../../Enums/EchoEnums'
import {
  onlyUnique,
  removeActiveFilter,
} from '../../../Common/Helpers/GeneralHelpers'
import {
  getUpcomingProjects,
  getCarbonCureProjects,
  getCorporationsSalesOverview,
  getCementPlants,
  getMapboxGeocode,
  getCO2Depots,
  getCO2Vendors,
  getOpportunities,
} from '../../Data/EchoDataHelpers'
import { flatPlants, flatCompanies } from '../../Logic/MapLogicHelpers'
import {
  echoUpdater,
  isAnyFilterActive,
  whatToUpdate,
  isProjectSearchActive,
  getPrecastTableData,
  getReadyMixTableData,
  handleChipLabel,
} from '../../Logic/FilterLogicHelpers'
import {
  formatCarbonCureProjectData,
  formatUpcomingProjectData,
} from '../../Logic/DataFormatters'
import {
  mapboxApiAccessToken,
  domesticSalesRegions,
  defaultEchoSettings,
  defaultMapViewport,
  defaultProjectSearchValues,
} from '../../Constants/Constants'
import EchoHomePresentational from './EchoHomePresentational'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import {
  atomEchoSettings,
  atomAddressOptions,
  atomAddressSearch,
  atomAddressCoordinates,
  atomLoadingAddresses,
  atomFilterBySelected,
  atomPlantMarkers,
  atomProjectSearchValues,
  atomFilterGroup,
  atomMapViewportSettings,
  atomFilteredOngoingProjects,
  atomFilteredFinishedProjects,
} from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'
// Imports for filter group
import GroupNameSelectLogical from '../../Components/GroupNameSelect/GroupNameSelectLogical'
import CountrySelectLogical from '../../Components/CountrySelect/CountrySelectLogical'
import StateSelectLogical from '../../Components/StateSelect/StateSelectLogical'
import CitySelectLogical from '../../Components/CitySelect/CitySelectLogical'
import CompanyNameSelectLogical from '../../Components/CompanyNameSelect/CompanyNameSelectLogical'
import ZipCodeSearchLogical from '../../Components/ZipCodeSearch/ZipCodeSearchLogical'
import AddressFilterLogical from '../../Components/AddressFilter/AddressFilterLogical'
import { useCementFilter } from '../../Logic/Hooks'

function EchoHomeLogical() {
  /** Atom with Echo Settings **/
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Project search values atom */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )
  /** Unfiltered Echo data */
  const [corpData, setCorpData] = useState([])
  /** Rows that have been selected on the Echo data table */
  const [selectedRows, setSelectedRows] = useState([])
  /** Filtered Echo data */
  const [filteredCorpData, setFilteredCorpData] = useState([])
  /** Coordinates of zip code that has been selected in "filter by" */
  const [zipCodeCoordinates, setZipCodeCoordinates] = useState([])
  /** Boolean to display or not display an error for the zip code search filter */
  const [zipCodeError, setZipCodeError] = useState(false)
  /** Coordinates of address that has been searched in address filter */
  const [addressCoordinates, setAddressCoordinates] = useRecoilState(
    atomAddressCoordinates
  )
  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)
  /** State setter to upodate boolean to determine whether address options are loading in Autocomplete */
  const setLoadingAddresses = useSetRecoilState(atomLoadingAddresses)
  /** State setter to update a flat array of plants */
  const setPlantMarkers = useSetRecoilState(atomPlantMarkers)
  /** A flat array of companies */
  const [companyMarkers, setCompanyMarkers] = useState([])
  /** Data table pagination */
  const [page, setPage] = useState(0)
  /** Sort data table rows by ascending or descending values */
  const [order, setOrder] = useState('desc')
  /** Indicate data table column by which to sort table rows */
  const [orderBy, setOrderBy] = useState('plantCount')
  /** Set number of data table rows to display per page */
  const [rowsPerPage, setRowsPerPage] = useState(10)
  /** Upcoming upcoming projects */
  const [upcomingProjects, setUpcomingProjects] = useState([])
  /** Filtered upcoming project data */
  const [filteredUpcomingProjects, setFilteredUpcomingProjects] = useState([])
  /** Ongoing CarbonCure construction projects */
  const [ongoingProjects, setOngoingProjects] = useState([])
  /** Filtered ongoing CarbonCure project data */
  const setFilteredOngoingProjects = useSetRecoilState(
    atomFilteredOngoingProjects
  )
  /** Finished CarbonCure construction projects */
  const [finishedProjects, setFinishedProjects] = useState([])
  /** Filtered finished CarbonCure project data */
  const setFilteredFinishedProjects = useSetRecoilState(
    atomFilteredFinishedProjects
  )
  /** Array of cement plants */
  const [cementPlants, setCementPlants] = useState([])
  /** Filtered array of cement plants */
  const [filteredCementPlants, setFilteredCementPlants] = useState([])
  /** Whether or not a "filter by" filter has been selected */
  const setFilterBySelected = useSetRecoilState(atomFilterBySelected)
  /** Array of CO2 depots */
  const [co2Depots, setCO2Depots] = useState([])
  /** Filtered array of CO2 depots */
  const [filteredCO2Depots, setFilteredCO2Depots] = useState([])
  /** Array of CO2 vendors */
  const [co2Vendors, setCO2Vendors] = useState([])
  /** Filtered array of CO2 vendors */
  const [filteredCO2Vendors, setFilteredCO2Vendors] = useState([])
  /** Array of sales opportunities */
  const [salesOpportunities, setSalesOpportunities] = useState([])
  /** Filtered array of sales opportunities */
  const [filteredSalesOpportunities, setFilteredSalesOpportunities] = useState(
    []
  )
  /** State setter to update array of options for the address search autocomplete */
  const setAddressOptions = useSetRecoilState(atomAddressOptions)
  /** Address search query */
  const addressSearch = useRecoilValue(atomAddressSearch)
  /** Atom to update which location filter is being used */
  const setFilterGroup = useSetRecoilState(atomFilterGroup)
  /** Choose to filter by country, state, city, group, company, zip code, or address */
  const filterGroup = useRecoilValue(atomFilterGroup)
  /** Atom to update the map viewport */
  const setViewport = useSetRecoilState(atomMapViewportSettings)

  /** Handles the opening and closing of the filter drawer */
  const [open, setOpen] = useState(false)
  /** Tracks the total amount of active filters */
  const [filterCount, setFilterCount] = useState(0)
  /** Tracks the currently active filters for Filter Chip display */
  const [activeFilters, setActiveFilters] = useState([])
  /** Array of selected map layer options. Passed down to MapLayerSelectLogical */
  const [selectedOptions, setSelectedOptions] = useState(['RM Plants'])
  /** Array of data to display on the ready mix table. */
  const [readyMixTableData, setReadyMixTableData] = useState([])
  /** Array of data to display on the precast table. */
  const [precastTableData, setPrecastTableData] = useState([])

  const [expandFilters, setExpandFilters] = useState({
    filterBy: false,
    sales: false,
    mapLayers: false,
    producers: false,
    projectSearch: false,
  })

  /** Handles the removal of chips and their respective filters when delete button is clicked */
  const filterChipsHandler = filter => {
    // An array to help target "Filter By" filters
    const filterByFilters = [
      'cities',
      'companies',
      'countries',
      'groups',
      'states',
      'zipCodes',
    ]
    // For handling selected Map Layer Filter Panel Chips
    const newSelectedOptions = [...selectedOptions]
    // Reference echoSettings to update filters
    let newSettings = cloneDeep(echoSettings)
    const echoProperty = filter.property
    const echoId = filter.id
    // Handle Plant Limit Slider Values. Clears both chips filters if either one is removed
    if (echoProperty === 'plantsLimits-0' || echoProperty === 'plantsLimits-1')
      newSettings['plantsLimits'] = []
    // Reset all relevant Project Search filters when Project Search chip is removed
    if (echoId === 'Project Search') {
      newSettings[echoProperty] = newSettings[echoProperty].filter(
        echoProp => echoProp !== echoId
      )
      newSettings.projects = []
      newSettings.countries = []
      newSettings.states = []
      newSettings.cities = []
      newSettings.groups = []
      newSettings.companies = []
      newSettings.zipCodes = []
      newSettings.startDate = ''
      newSettings.endDate = ''
      newSettings.keywords = ''
      setActiveFilters(prev => removeActiveFilter(prev, filter))
      setSelectedOptions(
        newSelectedOptions.filter(option => option !== 'Project Search')
      )
      setEchoSettings(newSettings)
      return
    }
    // Handle filter type to determine how to remove it from old echoSettings
    switch (typeof newSettings[echoProperty]) {
      case 'boolean':
        newSettings[echoProperty] = false
        break
      case 'object':
        newSettings[echoProperty] = newSettings[echoProperty].filter(
          echoProp => echoProp !== echoId
        )
        // Handle "Filter By" filter to reset when any filter is cleared due to bug with inputs not updating
        if (filterByFilters.includes(echoProperty)) {
          newSettings[echoProperty] = []
          setFilterGroup('country')
        }
        break
      default:
        break
    }
    setActiveFilters(prev => removeActiveFilter(prev, filter))
    setSelectedOptions(newSelectedOptions.filter(option => option !== echoId))
    setEchoSettings(newSettings)
  }

  /** If the "Connect CO2 to Customers" toggle is turned on, the RM Plants layer must be turned on. */
  useEffect(() => {
    if (
      echoSettings.co2ConnectedToCustomers &&
      !echoSettings.layers.includes(EMapLayers.rmPlants)
    ) {
      const newSelectedOptions = [...selectedOptions]
      newSelectedOptions.push('RM Plants')
      setSelectedOptions(
        newSelectedOptions
          .filter(option => option !== 'RM Companies')
          .filter(onlyUnique)
      )
    }
  }, [
    echoSettings.co2ConnectedToCustomers,
    echoSettings.layers,
    selectedOptions,
  ])

  useEffect(() => {
    // Internal function to check for existing id in Active Filters
    const noId = echoProp => {
      return !activeFilters.some(obj => obj['id'] === echoProp)
    }
    // Internal function that checks for array, string, and boolean values. Helps prevent errors when referencing values
    const checkProps = echoProps => {
      return echoProps.length === 0 || !echoProps
    }

    const activeFilters = []
    // Filters with arrays that contain multiple elements
    const multiFilters = [
      'layers',
      'salesPeople',
      'ownership',
      'states',
      'cementTypes',
    ]
    // Filters with arrays that will only ever contain one element
    const soloArrayFilters = [
      'addresses',
      'cities',
      'companies',
      'countries',
      'groups',
      'zipCodes',
      'cementSuppliers',
      'labResults',
      'rmProducerRange',
    ]
    const booleanFilters = ['isGreenfield', 'co2ConnectedToCustomers']
    const sliderFilters = ['plantsLimits']
    let count = 0
    for (const setting in echoSettings) {
      let echoProps = echoSettings[setting]
      // Handle Boolean Filters
      if (booleanFilters.includes(setting) && echoProps) {
        count += echoProps
        activeFilters.push(handleChipLabel(setting, echoProps.toString()))
      }
      // Handle Multi Filters
      if (multiFilters.includes(setting)) {
        count += echoProps.length
        if (!checkProps(echoProps)) {
          for (const props in echoProps) {
            if (noId(echoProps[props])) {
              activeFilters.push(handleChipLabel(setting, echoProps[props]))
            }
          }
        }
      }
      // Handle Slider Filters
      if (sliderFilters.includes(setting)) {
        count += echoProps.length
        if (!checkProps(echoProps)) {
          for (const props in echoProps) {
            if (noId(echoProps[props])) {
              activeFilters.push(
                handleChipLabel(`${setting}-${props}`, echoProps[props])
              )
            }
          }
        }
      }
      // Handle Solo Array Filters
      if (soloArrayFilters.includes(setting) && echoProps.length > 0) {
        count++
        if (noId(echoProps[0])) {
          activeFilters.push(handleChipLabel(setting, echoProps[0]))
        }
      }
    }
    setFilterCount(count)
    setActiveFilters(activeFilters)
  }, [isLoading, echoSettings])

  /** On page load */
  useEffect(() => {
    /** Load Echo data from Kelowna */
    getCorporationsSalesOverview(true)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setCorpData(data)
          })
      })
      .catch(e => console.error(e))
    /** Load upcoming project data from Kelowna */
    getUpcomingProjects(true)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setUpcomingProjects(data)
          })
      })
      .catch(e => console.error(e))
    /** Load CarbonCure project data from Kelowna */
    getCarbonCureProjects()
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setOngoingProjects(data.filter(project => project.isOngoing))
            setFinishedProjects(data.filter(project => !project.isOngoing))
          })
      })
      .catch(e => console.error(e))
    /** Load cement plants from Kelowna */
    getCementPlants()
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setCementPlants(data)
          })
      })
      .catch(e => console.error(e))
    /** Load CO2 depots from Kelowna */
    getCO2Depots()
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setCO2Depots(data)
          })
      })
      .catch(e => console.error(e))
    /** Load CO2 vendors from Kelowna */
    getCO2Vendors()
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setCO2Vendors(data.results)
          })
      })
      .catch(e => console.error(e))
    /** Load sales opportunities from Kelowna */
    getOpportunities()
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setSalesOpportunities(data)
          })
      })
      .catch(e => console.error(e))
  }, [])

  /** When a zip code is searched, return the geocode search results */
  useEffect(() => {
    if (echoSettings.zipCodes.length) {
      const displayZipcode = async response => {
        if (response.ok) {
          const data = await response.json()
          setZipCodeCoordinates(
            data.features.length ? data.features[0].center : []
          )
          // Simplify setting the `zipCodeError` state.
          // Instead of using a ternary operator to explicitly return true or false based on the condition,
          // directly use the condition itself. This makes the code cleaner and more readable.
          // Previous code: setZipCodeError(data.features.length > 0 ? false : true);
          setZipCodeError(data.features.length === 0)
          setIsLoading(false)
        }
      }
      const timeoutPointer = setTimeout(() => {
        setIsLoading(true)
        const getCoordinatesFromZipcode = async () => {
          const response = await getMapboxGeocode(echoSettings.zipCodes[0], {
            access_token: mapboxApiAccessToken,
            limit: 1,
            country: domesticSalesRegions,
            types: 'postcode',
          })
          displayZipcode(response)
        }
        getCoordinatesFromZipcode()
        return () => clearTimeout(timeoutPointer)
      }, 500)
    } else {
      setZipCodeCoordinates([])
    }
  }, [echoSettings.zipCodes])

  /** When the address search field is updated, return autocomplete suggestions */
  useEffect(() => {
    if (addressSearch.length && addressSearch.length >= 5) {
      const displayOptions = async response => {
        if (response.ok) {
          const data = await response.json()
          setAddressOptions(
            data.features.length
              ? data.features.map(feature => feature.place_name)
              : []
          )
          setLoadingAddresses(false)
        }
      }
      const timeoutPointer = setTimeout(() => {
        setLoadingAddresses(true)
        const getOptionsFromSearch = async () => {
          const response = await getMapboxGeocode(addressSearch, {
            access_token: mapboxApiAccessToken,
            country: domesticSalesRegions,
            types: 'address',
            autocomplete: true,
            limit: 4,
          })
          displayOptions(response)
        }
        getOptionsFromSearch()
        return () => clearTimeout(timeoutPointer)
      }, 100)
    } else {
      setAddressOptions([])
    }
  }, [addressSearch, setAddressOptions, setLoadingAddresses])

  /** When an address search is submitted, return the geocode search results */
  useEffect(() => {
    if (echoSettings.addresses.length) {
      const displayAddress = async response => {
        if (response.ok) {
          const data = await response.json()
          setAddressCoordinates(
            data.features.length ? data.features[0].center : []
          )
          setIsLoading(false)
        }
      }
      const timeoutPointer = setTimeout(() => {
        setIsLoading(true)
        const getCoordinatesFromAddress = async () => {
          const response = await getMapboxGeocode(echoSettings.addresses[0], {
            access_token: mapboxApiAccessToken,
            limit: 1,
            country: domesticSalesRegions,
            types: 'address',
          })
          displayAddress(response)
        }
        getCoordinatesFromAddress()
        return () => clearTimeout(timeoutPointer)
      }, 500)
    } else {
      setAddressCoordinates([])
    }
  }, [echoSettings.addresses, setAddressCoordinates])

  /** When the filteredCorpData is loaded, create flat arrays of plants and companies to be used for the map markers. */
  useEffect(() => {
    setPlantMarkers(flatPlants(filteredCorpData))
    setCompanyMarkers(flatCompanies(filteredCorpData))
  }, [filteredCorpData, setPlantMarkers])

  /** When a filter is selected in the "filter by" section, change filterBySelected to true. */
  useEffect(() => {
    setFilterBySelected(isAnyFilterActive(echoSettings, zipCodeCoordinates))
  }, [echoSettings, setFilterBySelected, zipCodeCoordinates])

  /** Handle filters */
  useEffect(() => {
    /** Check which data needs updating based on which filters have been used */
    const dataToUpdate = whatToUpdate(
      echoSettings,
      zipCodeCoordinates,
      addressCoordinates
    )
    /** State setters for the Echo data */
    const stateSetters = {
      setFilteredCorpData,
      setFilteredSalesOpportunities,
      setFilteredUpcomingProjects,
      setFilteredCementPlants,
      setFilteredCO2Depots,
      setFilteredOngoingProjects,
      setFilteredFinishedProjects,
      setFilteredCO2Vendors,
    }
    /** Turn on loading spinner until filtering is complete */
    setIsLoading(true)
    setTimeout(() => {
      setFilteredCorpData(corpData)
      setFilteredUpcomingProjects(
        formatUpcomingProjectData(upcomingProjects, corpData)
      )
      setFilteredOngoingProjects(formatCarbonCureProjectData(ongoingProjects))
      setFilteredFinishedProjects(formatCarbonCureProjectData(finishedProjects))
      setFilteredCementPlants(cementPlants)
      setFilteredCO2Depots(co2Depots)
      setFilteredCO2Vendors(co2Vendors)
      setFilteredSalesOpportunities(salesOpportunities)
      /** Update Echo data and settings according to which filters have been used */
      echoUpdater(
        dataToUpdate,
        stateSetters,
        echoSettings,
        zipCodeCoordinates,
        addressCoordinates
      )
      /** Return to page 1 of the table whenever the table is sorted, the number of rows is changed, or a filter is changed. */
      setPage(0)
      /** Turn off loading spinner when filtering is complete */
      setIsLoading(false)
    }, 1000)
  }, [
    addressCoordinates,
    ongoingProjects,
    cementPlants,
    co2Depots,
    co2Vendors,
    corpData,
    echoSettings,
    salesOpportunities,
    upcomingProjects,
    zipCodeCoordinates,
    setFilteredOngoingProjects,
    finishedProjects,
    setFilteredFinishedProjects,
  ])

  /** Filter the CO2 vendors table when an address is searched */
  useEffect(() => {
    const co2CompanyNames = filteredCO2Depots.map(depot => depot.companyName)
    const co2States = filteredCO2Depots.map(depot => depot.state)
    /** Turn on loading spinner until filtering is complete */
    setIsLoading(true)
    setTimeout(() => {
      if (addressCoordinates.length) {
        setFilteredCO2Vendors(vendors =>
          vendors.filter(
            vendor =>
              co2CompanyNames.includes(vendor.companyName) &&
              co2States.includes(vendor.state)
          )
        )
      }
      setIsLoading(false)
    }, 1000)
  }, [addressCoordinates.length, filteredCO2Depots])

  /** Reset the project search values atom when the project search is not being used. */
  useEffect(() => {
    if (!isProjectSearchActive(echoSettings)) {
      setProjectSearchValues(defaultProjectSearchValues)
    }
  }, [echoSettings, setProjectSearchValues])

  /** Check if a division has precast and/or ready mix plants */
  useEffect(() => {
    setPrecastTableData(getPrecastTableData(filteredCorpData))
    setReadyMixTableData(getReadyMixTableData(filteredCorpData))
  }, [filteredCorpData])

  /** Handle cement layer filters */
  useCementFilter(filteredCementPlants, echoSettings, setEchoSettings)

  /** Function to clear all filters */
  const clearAllFilters = () => {
    setEchoSettings(defaultEchoSettings)
    setSelectedOptions([])
    setSelectedRows([])
    setZipCodeCoordinates([])
    setZipCodeError(false)
    setAddressCoordinates([])
    setLoadingAddresses(false)
    setPage(0)
    setOrder('desc')
    setOrderBy('plantCount')
    setRowsPerPage(10)
    setFilterGroup('country')
    setViewport(defaultMapViewport)
  }

  /** Options to filter by country, state, city, group, company, zip code, or address */
  const filterSelect = {
    country: <CountrySelectLogical filteredCorpData={filteredCorpData} />,
    state: <StateSelectLogical filteredCorpData={filteredCorpData} />,
    city: <CitySelectLogical filteredCorpData={filteredCorpData} />,
    group: <GroupNameSelectLogical filteredCorpData={filteredCorpData} />,
    company: <CompanyNameSelectLogical filteredCorpData={filteredCorpData} />,
    zipCode: (
      <ZipCodeSearchLogical
        zipCodeError={zipCodeError}
        setZipCodeError={setZipCodeError}
      />
    ),
    address: <AddressFilterLogical />,
  }

  const submitProjectSearch = () => {
    const newSettings = cloneDeep(echoSettings)
    newSettings.projects = projectSearchValues.projects
    newSettings.countries = projectSearchValues.countries
    newSettings.states = projectSearchValues.states
    newSettings.cities = projectSearchValues.cities
    newSettings.groups = projectSearchValues.groups
    newSettings.companies = projectSearchValues.companies
    newSettings.zipCodes = projectSearchValues.zipCodes
    newSettings.startDate = projectSearchValues.startDate
    newSettings.endDate = projectSearchValues.endDate
    newSettings.keywords = projectSearchValues.keywords
    setEchoSettings(newSettings)
  }

  const getCementFilterValue = filter => {
    const options = filter.options || []
    const filterValue = echoSettings[filter.property]

    if (filter.property === 'cementTypes') {
      // Multi select
      const selectedOptions = filter.options.filter(x => {
        return echoSettings[filter.property]?.includes(x.id)
      })
      return selectedOptions || null
    } else {
      // Single select
      const matchedOption = options.find(
        option => option?.id === filterValue[0] || null
      )
      return matchedOption || null
    }
  }

  const cementFilterChangeHandler = (filter, value) => {
    const newSettings = cloneDeep(echoSettings)
    if (filter.property === 'cementTypes') {
      // Multi select
      newSettings[filter.property] = value.map(x => x.id)
    } else {
      // Single select
      newSettings[filter.property] = value ? [value.id] : []
    }
    setEchoSettings(newSettings)
  }

  return (
    <EchoHomePresentational
      corpData={corpData}
      filteredCorpData={filteredCorpData}
      zipCodeError={zipCodeError}
      setZipCodeError={setZipCodeError}
      isLoading={isLoading}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setIsLoading={setIsLoading}
      companyMarkers={companyMarkers}
      page={page}
      setPage={setPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      filteredUpcomingProjects={filteredUpcomingProjects}
      filteredCementPlants={filteredCementPlants}
      zipCodeCoordinates={zipCodeCoordinates}
      filteredCO2Depots={filteredCO2Depots}
      co2Depots={co2Depots}
      filteredCO2Vendors={filteredCO2Vendors}
      filteredSalesOpportunities={filteredSalesOpportunities}
      clearAllFilters={clearAllFilters}
      open={open}
      setOpen={setOpen}
      filterCount={filterCount}
      setFilterCount={setFilterCount}
      expandFilters={expandFilters}
      setExpandFilters={setExpandFilters}
      filterGroup={filterGroup}
      filterSelect={filterSelect}
      submitProjectSearch={submitProjectSearch}
      activeFilters={activeFilters}
      filterChipsHandler={filterChipsHandler}
      echoSettings={echoSettings}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      readyMixTableData={readyMixTableData}
      precastTableData={precastTableData}
      getFilterValue={getCementFilterValue}
      cementFilterChangeHandler={cementFilterChangeHandler}
    />
  )
}

export default EchoHomeLogical
