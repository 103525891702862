import React from 'react'
import PropTypes from 'prop-types'
import stateList from '../../../Common/assets/statelist.json'
import StateSelectPresentational from './StateSelectPresentational'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'
import { EMapLayers } from '../../Enums/EchoEnums'
import { comparedSort } from '../../../Common/Helpers/GeneralHelpers'

StateSelectLogical.propTypes = {
  /** Filtered Echo data */
  filteredCorpData: PropTypes.array.isRequired,
}

function StateSelectLogical(props) {
  const { filteredCorpData } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  /** Combine states from the division and plant level into one list */
  const allStates = new Set()
  filteredCorpData.forEach(corporation =>
    corporation.divisions.forEach(division => {
      division.plants.forEach(plant => allStates.add(plant.state))
      division.states.forEach(state => allStates.add(state.state))
    })
  )

  /** Options (states) for the autocomplete select input */
  const options = Array.from(allStates)
    .filter(state => {
      return !(
        state == null ||
        state.length < 2 ||
        (state.length === 2 && !stateList[state])
      )
    })
    .sort(comparedSort)
    .map(state => {
      /** If the state on file is an abbreviation, display the full name in the dropdown. */
      if (state.length === 2) {
        return { label: stateList[state], value: state }
      } else {
        return { label: state, value: state }
      }
    })

  /** Handle input change. Pass selected filter value to useEffect in EchoHome. */
  const handleChange = (event, value) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.states = value ? value.map(state => state.value) : []
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.states = value ? value.map(state => state.value) : []
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <StateSelectPresentational handleChange={handleChange} options={options} />
  )
}

export default StateSelectLogical
