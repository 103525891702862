import { LineLayer } from '@deck.gl/layers'
import { ownershipColors } from '../../../Constants/Constants'

export function getCO2CustomersLayer(co2CustomerConnections) {
  return [
    new LineLayer({
      id: 'co2Customers',
      data: co2CustomerConnections.map(connection => ({
        ...connection,
        layerType: 'co2Customer',
      })),
      pickable: false,
      getWidth: 2,
      getSourcePosition: connection => connection.customerLongLat,
      getTargetPosition: connection => connection.supplierLongLat,
      getColor: ownershipColors.customers.rgb,
    }),
  ]
}
