import {
  AddDataSettings,
  IAtomCurrentCustomer,
  MixGroupConditions,
} from '../Logic/Types'

export const handleChipLabel = (
  settingName: string,
  settingValue: string,
  currentCustomer: IAtomCurrentCustomer
) => {
  let labelValue = settingValue !== undefined ? settingValue : ''
  let customer = currentCustomer?.division?.name
  let plant = currentCustomer?.plant?.name
  switch (settingName) {
    case 'divisionId':
      labelValue = `${customer !== undefined ? customer : ''}`
      break
    case 'isCO2Design':
      labelValue =
        settingValue === 'true'
          ? `Condition: ${MixGroupConditions.CO2}`
          : `Condition: ${MixGroupConditions.NOCO2}`
      break
    case 'mixCode':
      labelValue = settingValue ? `Mix Code: ${settingValue}` : ''
      break
    case 'plantId':
      labelValue = `${plant !== undefined ? plant : ''}`
      break
    case 'minNumSamples':
      labelValue = `Min. Samples: ${labelValue}`
      break
    case 'maxNumSamples':
      labelValue = `Max. Samples: ${labelValue}`
      break
    case 'showArchived':
      labelValue = 'Hide Archived'
      break
    case 'includeOnlyMissingDesignStrength':
      labelValue =
        settingValue === 'true' ? `Missing Info: Design Strength` : ''
      break
    case 'includeOnlyMissingMixAssociation':
      labelValue =
        settingValue === 'true' ? `Missing Info: Mix Association` : ''
      break
    default:
      break
  }
  const obj = {
    property: settingName,
    label: labelValue,
  }
  return obj
}

export const mapSettingsToParams = (
  addDataSettings: AddDataSettings,
  paramName: string
) => {
  return addDataSettings[paramName]?.length
    ? { [paramName]: addDataSettings[paramName][0] }
    : {}
}
