import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
} from '@material-ui/core'
import {
  getCurrentPageRows,
  getNewCurrentPage,
  roundUpToDecimal,
} from '../../Common/Helpers/GeneralHelpers'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import EnhancedTableHead from './BatchTableEnhancedHead'
import PropTypes from 'prop-types'
import { getCO2DosageUnitLabel } from '../Logic/TSSLogic'
import { getCO2DosagePrecision } from '../Helpers/BaleenHelpers'
import SimpleTablePagination from '../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
}))

const defaultTableSettings = {
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'divisionName',
}

BatchDataTable.propTypes = {
  /** The selected rows of batch data */
  batchData: PropTypes.array.isRequired,
  /** Function to set the batch data */
  setBatchData: PropTypes.func.isRequired,
  /** Whether or not the measurement system is metric */
  isMetric: PropTypes.bool.isRequired,
}
export default function BatchDataTable(props) {
  const { batchData, setBatchData, isMetric = false } = props
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState()
  const [tableSettings, setTableSettings] = useState(defaultTableSettings)

  /** Function to change pages on the table */
  const handleChangePage = (event, newPage) => {
    setTableSettings(prevSettings => {
      return {
        ...prevSettings,
        page: newPage,
      }
    })
  }

  /** Function to update the number of table rows to display per page */
  const handleChangeRowsPerPage = event => {
    setTableSettings(prevSettings => {
      return {
        ...prevSettings,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = batchData[0].value.map(n => n)
      setBatchData([{ value: newSelecteds }])
      return
    }
    setBatchData([{ value: [] }])
    setTableSettings(prevSettings => {
      return {
        ...prevSettings,
        page: 0,
      }
    })
  }

  const handleClick = (event, row) => {
    let batchDataArray = [...batchData]
    let batchDataRow = [...batchDataArray[0].value]
    const selectedIndex = batchDataRow.indexOf(row)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(batchDataRow, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(batchDataRow.slice(1))
    } else if (selectedIndex === batchDataRow.length - 1) {
      newSelected = newSelected.concat(batchDataRow.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        batchDataRow.slice(0, selectedIndex),
        batchDataRow.slice(selectedIndex + 1)
      )
    }

    setBatchData([{ value: [...newSelected] }])
    setTableSettings(prevSettings => {
      const newPage = getNewCurrentPage(
        newSelected.length,
        tableSettings.rowsPerPage,
        tableSettings.page
      )
      return {
        ...prevSettings,
        page: newPage,
      }
    })
  }

  const isSelected = name => batchData[0].value.indexOf(name) !== -1
  const currentPageRows = getCurrentPageRows(
    batchData[0].value,
    tableSettings.page,
    tableSettings.rowsPerPage
  )
  return (
    <>
      <Paper variant="outlined" className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="customers"
            size={'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={batchData.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={batchData.length}
            />
            <TableBody>
              {currentPageRows.map((row, index) => {
                const isItemSelected = isSelected(row)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.batchId + row.strength}
                    selected={isItemSelected}
                    classes={{ selected: classes.selected }}
                    className={classes.tableRow}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<IndeterminateCheckBoxIcon />}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.producer}
                    </TableCell>
                    <TableCell align="center">{row.variationIdLabel}</TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center">{row.condition}</TableCell>

                    <TableCell align="center">{row.cementSupplier}</TableCell>
                    <Tooltip title={isMetric ? 'kg' : 'lb'}>
                      <TableCell align="center">
                        {roundUpToDecimal(row.cementContent, 0)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={'Percentage'}>
                      <TableCell align="center">
                        {row.air?.toFixed(1)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={isMetric ? 'mm' : 'in'}>
                      <TableCell align="center">
                        {isMetric
                          ? row.slump?.toFixed(0)
                          : row.slump?.toFixed(1)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={isMetric ? 'MPa' : 'psi'}>
                      <TableCell align="center">
                        {isMetric
                          ? row.strength?.toFixed(2)
                          : row.strength?.toFixed(0)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={'days'}>
                      <TableCell align="center">{row.interval}</TableCell>
                    </Tooltip>
                    <TableCell align="center">
                      {row.waterCementRatio?.toFixed(3)}
                    </TableCell>
                    <Tooltip
                      title={getCO2DosageUnitLabel(row.co2DosageUnit, isMetric)}
                    >
                      <TableCell align="center">
                        {roundUpToDecimal(
                          row.co2DosageLabel,
                          getCO2DosagePrecision(row.co2DosageUnit, isMetric)
                        )}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[10, 20, 35]}
          page={tableSettings.page}
          count={batchData[0].value.length}
          rowsPerPage={tableSettings.rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
