import React from 'react'
import AddressFilterPresentational from './AddressFilterPresentational'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { atomAddressSearch, atomEchoSettings } from '../../../Common/echoAtoms'

function AddressFilterLogical() {
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Update address search query */
  const setAddressSearch = useSetRecoilState(atomAddressSearch)

  /** Display address options as the user types into the Autocomplete */
  const handleChange = (event, value) => {
    setTimeout(() => {
      if (value.length && value.length >= 6) {
        setAddressSearch(value)
      } else {
        setAddressSearch('')
      }
    }, 300)
  }

  /** Function called when an address option is selected */
  const handleSearch = (event, value) => {
    const newSettings = cloneDeep(echoSettings)
    newSettings.addresses = value ? [value] : []
    setEchoSettings(newSettings)
    setAddressSearch('')
  }

  return (
    <AddressFilterPresentational
      handleChange={handleChange}
      handleSearch={handleSearch}
    />
  )
}

export default AddressFilterLogical
