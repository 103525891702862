import React from 'react'
import PropTypes from 'prop-types'
import { CarbonCureProjectTablePresentational } from './CarbonCureProjectTablePresentational'

export const CarbonCureProjectTableLogical = props => {
  CarbonCureProjectTableLogical.propTypes = {
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Array of data to display as table rows */
    tableRows: PropTypes.array.isRequired,
  }

  const {
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    tableRows,
  } = props

  /** Function to sort the table rows */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <CarbonCureProjectTablePresentational
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      order={order}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      tableRows={tableRows}
    />
  )
}
