import { atom } from 'recoil'
import { CommissioningFilterOptions } from '../TSS/Constants/CommonConstants'
import { GraphFilter } from '../TSS/Logic/GraphFilterClass'

/*
 * The atoms.js will contain all atoms for shared state.
 * As a general rule, only define an atom if a state needs to be shared between views.
 * Try and have states that are dependent on an endpoint/return value of a function state it in the jsdocs for that atom.
 * Below are two examples of how an atom should be documented.
 */

/**
 * Atom for TSS (and possibly in the future others) to keep track of the selected customer.
 *
 * initial structure:  {division: null, plant: null}
 *
 * Current customer should be an object with the shape of/include after being set:
 * {
 *  division: {
 *     divisionId: int,
 *     name: string,
 *     isImperial: bool
 *   },
 *  plant: {
 *     plantId: int,
 *     name: string
 *  }
 * }
 */
const atomPlantsFiltersSettings = atom({
  key: 'atomPlantsFiltersSettings',
  default: {
    cementType: [],
    customer: [],
    tse: [],
    state: [],
    missingInformation: [],
    plantsWithoutInstalls: false,
    isMetric: false,
  },
})

const atomPlantsFiltersOptions = atom({
  key: 'atomPlantsFiltersOptions',
  default: {
    cementType: [],
    customer: [],
    tse: [],
    state: [],
    missingInformation: [],
    cementPlants: [],
  },
})

const atomPlantsConfirmation = atom({
  key: 'atomPlantsConfirmation',
  default: {
    isVisible: false,
    onSubmission: () => {
      // This is intentional
    },
  },
})

export {
  atomPlantsFiltersSettings,
  atomPlantsFiltersOptions,
  atomPlantsConfirmation,
}

export const atomCustomersPageFiltersSettings = atom({
  key: 'atomCustomersPageFiltersSettings',
  default: {
    tse: [],
    commissioningStatus: [],
  },
})

export const atomCustomersPageFiltersOptions = atom({
  key: 'atomCustomersPageFiltersOptions',
  default: {
    tse: [],
    commissioningStatus: [
      {
        id: 'inCommissioning',
        name: CommissioningFilterOptions.InCommissioning,
      },
      {
        id: 'commissioningComplete',
        name: CommissioningFilterOptions.CommissioningComplete,
      },
    ],
  },
})

export const atomGraphSelectedFilters = atom({
  key: 'atomSelectedGraphFilters',
  default: [
    new GraphFilter('batchInterval', null, null, [], true),
    new GraphFilter(null),
  ],
})

export const atomShowCementCompHistory = atom({
  key: 'atomShowCementCompHistory',
  default: {
    show: false,
    plantData: {
      plantName: '',
      plantId: '',
      cementCompositions: [],
    },
  },
})
