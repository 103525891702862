import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ILegendItem } from './BaseLegend'

const useStyles = makeStyles({
  icon: (props: { color: string }) => ({
    color: props.color,
  }),
  itemContainer: {
    gap: '12px',
  },
})

const BaseLegendItem: React.FC<{ item: ILegendItem }> = ({ item }) => {
  const classes = useStyles({ color: item.color })

  return (
    <Grid container alignItems="center" className={classes.itemContainer}>
      <item.Icon className={classes.icon} />
      <Typography>{item.label}</Typography>
    </Grid>
  )
}

export default BaseLegendItem
