import React from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { ServiceCaseStatuses } from '../Constants/Constants'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

function RemoteTroubleshooting(props) {
  RemoteTroubleshooting.propTypes = {
    /** The service case displayed when the modal opens: existing or new */
    disabled: PropTypes.bool.isRequired,
    /** Input class name */
    inputClassName: PropTypes.string.isRequired,
  }
  const { inputClassName, disabled } = props

  const { register, errors, setValue, watch } = useFormContext()

  return (
    <Grid item container justify="space-between" lg={12}>
      <Grid item lg={5}>
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="carbonCureTechnicianRemote"
          label="CarbonCure Remote Technician"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          error={!!errors?.carbonCureTechnicianRemote}
          helperText={errors?.carbonCureTechnicianRemote?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="initialIndication"
          InputLabelProps={{ shrink: true }}
          label="Initial Indication"
          variant="outlined"
          size="small"
          error={!!errors?.initialIndication}
          helperText={errors?.initialIndication?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="partResponsibleForIssue"
          label="Part Responsible"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          error={!!errors?.partResponsibleForIssue}
          helperText={errors?.partResponsibleForIssue?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="errorMessage"
          label="Error Message"
          variant="outlined"
          size="small"
          error={!!errors?.errorMessage}
          InputLabelProps={{ shrink: true }}
          helperText={errors?.errorMessage?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="system"
          label="Faulty System Component"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          error={!!errors?.system}
          helperText={errors?.system?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="plantPointOfContact"
          label="Plant POC"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          error={!!errors?.plantPointOfContact}
          helperText={errors?.plantPointOfContact?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          InputLabelProps={{ shrink: true }}
          name="descriptionOfProblemByCustomer"
          label="Description of Problem by Customer"
          variant="outlined"
          size="small"
          error={!!errors?.descriptionOfProblemByCustomer}
          helperText={errors?.descriptionOfProblemByCustomer?.message}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="actionsTakenRemotely"
          label="Actions Taken Remotely"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          error={!!errors?.actionsTakenRemotely}
          helperText={errors?.actionsTakenRemotely?.message}
          inputRef={register}
        />
      </Grid>
      <Grid item lg={5}>
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="followUpSummary"
          label="Follow Up Summary"
          variant="outlined"
          size="small"
          error={!!errors?.followUpSummary}
          helperText={errors?.followUpSummary?.message}
          InputLabelProps={{ shrink: true }}
          inputRef={register}
        />
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="status"
          label="Status"
          variant="outlined"
          size="small"
          select
          error={!!errors?.status}
          helperText={errors?.status?.message}
          InputLabelProps={{ shrink: true }}
          value={watch('status')}
          onChange={event => {
            setValue('status', event.target.value)
          }}
        >
          {ServiceCaseStatuses.map(status => (
            <MenuItem key={status.value} value={status.value}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={disabled}
          className={inputClassName}
          name="marketDevRepEmail"
          label="Market Dev Rep Email"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          error={!!errors?.marketDevRepEmail}
          helperText={errors?.marketDevRepEmail?.message}
          inputRef={register}
        />
        <FormControlLabel
          name="followUpRequired"
          disabled={disabled}
          label="Follow Up Required"
          control={<Checkbox color="primary" />}
          checked={watch('followUpRequired')}
          onChange={e => setValue('followUpRequired', e.target.checked)}
        />

        <FormControlLabel
          disabled={disabled}
          name="solvedRemotely"
          label="Solved Remotely"
          control={<Checkbox color="primary" />}
          checked={watch('solvedRemotely')}
          onChange={e => setValue('solvedRemotely', e.target.checked)}
        />
      </Grid>
    </Grid>
  )
}

export default RemoteTroubleshooting
