import React from 'react'
import BaseLegend from '../BaseLegend'
import { useRecoilValue } from 'recoil'
import { atomFilterBySelected } from '../../../../Common/echoAtoms'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import { ownershipColors } from '../../../Constants/Constants'
import {
  ICorpData,
  ICorpDataPlant,
  IEchoSettings,
} from '../../../Interfaces/EchoInterfaces'
import { EMapLayers } from '../../../Enums/EchoEnums'

interface IProducersLegendProps {
  echoSettings: IEchoSettings
  filteredCorpData: ICorpData[]
}

const ProducersLegend = (props: IProducersLegendProps) => {
  const { echoSettings, filteredCorpData } = props
  const filterBySelected = useRecoilValue(atomFilterBySelected)

  const isClient = (plant: ICorpDataPlant) => plant.hasSystem === true
  const isPrivatelyOwned = (corporation: ICorpData) =>
    corporation.isVerticallyIntegrated === false

  const isVerticallyIntegrated = (corporation: ICorpData) =>
    corporation.isVerticallyIntegrated === true

  const showCustomerLegend =
    filteredCorpData.length &&
    filteredCorpData.some(company =>
      company.divisions.some(division => division.plants.some(isClient))
    )

  const showPrivatelyOwnedLegend =
    filteredCorpData.length &&
    filteredCorpData.some(isPrivatelyOwned) &&
    !echoSettings.layers.includes('Project Search' as EMapLayers)

  const showVerticallyIntegratedLegend =
    filteredCorpData.length &&
    filteredCorpData.some(isVerticallyIntegrated) &&
    !echoSettings.layers.includes('Project Search' as EMapLayers)

  const legendIcon = filterBySelected
    ? FiberManualRecordOutlinedIcon
    : FiberManualRecordIcon

  const items = [
    {
      label: 'Customers',
      color: ownershipColors.customers.hex,
      show: showCustomerLegend,
    },
    {
      label: 'Privately Owned',
      color: ownershipColors.privatelyOwned.hex,
      show: showPrivatelyOwnedLegend,
    },
    {
      label: 'Vertically Integrated',
      color: ownershipColors.verticallyIntegrated.hex,
      show: showVerticallyIntegratedLegend,
    },
  ]
    .filter(item => item.show)
    .map(item => ({
      label: item.label,
      color: item.color,
      Icon: legendIcon,
    }))

  return <BaseLegend title="Concrete Producers:" items={items} />
}

export default ProducersLegend
