import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  CO2DosageUnit,
  ICommissionReportSettings,
  IMixSelection,
  SummaryTestingType,
  Units,
  VariationTypes,
} from '../../Logic/Types'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { baseColors } from '../../../theme/colors'
import { UndoOutlined } from '@material-ui/icons'
import {
  findVariationByType,
  getCO2DosageUnitLabel,
  getStrength,
  getStrengthUnit,
} from '../../Logic/TSSLogic'
import {
  generateMultipleSummary,
  generateSingleSummary,
  getReportMixCodesString,
  getPropertyValue,
} from '../../Helpers/CommissionReportHelpers'
import cloneDeep from 'lodash.clonedeep'
import { ICommissionReportCustomer } from '../../Views/CommissionReportGeneratorView'

export interface ICommissionReportTestSummary {
  mixSelections: IMixSelection[]
  reportSettings: ICommissionReportSettings | undefined
  setReportSettings: Dispatch<
    SetStateAction<ICommissionReportSettings | undefined>
  >
  isLoadingMixData: boolean
  selectedCustomer: ICommissionReportCustomer
  isValidReportId: boolean
}

const CommissionReportTestSummary = (props: ICommissionReportTestSummary) => {
  const {
    mixSelections,
    reportSettings,
    setReportSettings,
    isLoadingMixData,
    selectedCustomer,
    isValidReportId,
  } = props

  const customerName = selectedCustomer.divisionName
  const locationName = selectedCustomer.plantName
  const corporationName = selectedCustomer.corporationName

  const mixAmount = mixSelections.length
  const summaryType =
    mixAmount > 1 ? SummaryTestingType.Multiple : SummaryTestingType.Single

  const mixReportData = reportSettings?.mixDesignSettings[0]
  const unit = reportSettings?.isMetric ? Units.Metric : Units.Imperial

  const mixCode = mixSelections[0]?.mixCode
  const specifiedMaturityAge = mixSelections[0]?.specifiedMaturityAge as number
  const designStrength = getPropertyValue(
    mixSelections[0],
    'designStrength',
    unit === Units.Metric
  ) as number
  const strengthUnit = getStrengthUnit(unit) as string
  const baselineVariation = findVariationByType(
    mixSelections[0].digestedVariations,
    VariationTypes.BASELINE
  )
  const carboncureVariation = findVariationByType(
    mixSelections[0].digestedVariations,
    VariationTypes.OPTIMIZED
  )
  const baselineStrength = getStrength(
    baselineVariation,
    specifiedMaturityAge,
    unit
  ) as number
  const carboncureStrength = getStrength(
    carboncureVariation,
    specifiedMaturityAge,
    unit
  ) as number

  const carboncureCemAdjustment = mixReportData?.carbonCureVariation
    ?.cementReductionPercent as number
  const carboncureCO2Dosage = carboncureVariation?.cO2DosageLabel as number
  const carboncureCO2DosageUnit =
    (getCO2DosageUnitLabel(
      mixReportData?.carbonCureVariation?.cO2DosageUnit as CO2DosageUnit,
      reportSettings?.isMetric
    ) as string) || '% bwc'

  const mixCodes = getReportMixCodesString(mixSelections)

  const MAX_CHARACTER_LIMIT = 1000

  let DEFAULT_SINGLE_TEXT = generateSingleSummary({
    customerName,
    locationName,
    corporationName,
    mixCode,
    specifiedMaturityAge,
    designStrength,
    strengthUnit,
    baselineStrength,
    carboncureStrength,
    carboncureCemAdjustment,
    carboncureCO2Dosage,
    carboncureCO2DosageUnit,
  })
  let DEFAULT_MULTI_TEXT = generateMultipleSummary({
    customerName,
    locationName,
    mixAmount,
    mixCodes,
  })

  const [showResetButton, setShowResetButton] = useState(false)
  const [testingSummary, setTestingSummary] = useState<string>(
    reportSettings?.testingSummary || summaryType === SummaryTestingType.Single
      ? DEFAULT_SINGLE_TEXT
      : DEFAULT_MULTI_TEXT
  )

  const characterCount = testingSummary.length
  const remainingCharacters = MAX_CHARACTER_LIMIT - characterCount
  const helperText = `Maximum ${MAX_CHARACTER_LIMIT} characters (${remainingCharacters} remaining)`

  const isFirstRender = useRef(true)
  const defaultSingleTextRef = useRef(DEFAULT_SINGLE_TEXT)
  const defaultMultiTextRef = useRef(DEFAULT_MULTI_TEXT)
  const handleChangeTriggered = useRef<boolean>(false)
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const defaultSummaryText =
    summaryType === SummaryTestingType.Single
      ? defaultSingleTextRef.current
      : defaultMultiTextRef.current

  /** Update and set default text with changes to mix group */
  useEffect(() => {
    if (isLoadingMixData) return
    defaultSingleTextRef.current = DEFAULT_SINGLE_TEXT
    defaultMultiTextRef.current = DEFAULT_MULTI_TEXT
    setTestingSummary(defaultSummaryText)
    setShowResetButton(false)
  }, [
    DEFAULT_SINGLE_TEXT,
    DEFAULT_MULTI_TEXT,
    summaryType,
    isLoadingMixData,
    defaultSummaryText,
    mixSelections,
  ])

  /** Update summary text except for cases where handleChange is called */
  useEffect(() => {
    if (isLoadingMixData || isFirstRender.current) return
    if (!handleChangeTriggered.current) {
      // Prevent setting during handleChange
      setTestingSummary(defaultSummaryText)
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings)
        newSettings.testingSummary = defaultSummaryText
        return {
          ...newSettings,
        }
      })
    }
    handleChangeTriggered.current = false
    // Update "reset to default" button visibility
    const newDefaultText =
      summaryType === SummaryTestingType.Single
        ? defaultSingleTextRef.current
        : defaultMultiTextRef.current
    if (reportSettings?.testingSummary) {
      setShowResetButton(reportSettings?.testingSummary !== newDefaultText)
    }
  }, [
    defaultSummaryText,
    isLoadingMixData,
    reportSettings?.testingSummary,
    setReportSettings,
    summaryType,
  ])

  /** Set initial summary text on first render */
  useEffect(() => {
    if (isLoadingMixData) return
    const newDefaultText =
      summaryType === SummaryTestingType.Single
        ? defaultSingleTextRef.current
        : defaultMultiTextRef.current
    if (isFirstRender.current) {
      if (!reportSettings?.testingSummary && !isValidReportId) {
        setTestingSummary(newDefaultText)
      } else {
        // Handle double escaped characters returned from Kelowna
        setTestingSummary(reportSettings?.testingSummary)
      }
      setShowResetButton(reportSettings?.testingSummary !== newDefaultText)
      isFirstRender.current = false
    }
  }, [
    isLoadingMixData,
    reportSettings?.testingSummary,
    summaryType,
    isValidReportId,
  ])

  /** Reset to Default button */
  const handleReset = () => {
    const newText =
      summaryType === SummaryTestingType.Single
        ? defaultSingleTextRef.current
        : defaultMultiTextRef.current
    setTestingSummary(newText)
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      newSettings.testingSummary = newText
      return {
        ...newSettings,
      }
    })
  }

  /** Updates testing summary and report settings with debouncing */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value
    setTestingSummary(newText)
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings)
        newSettings.testingSummary = newText
        return {
          ...newSettings,
        }
      })
      handleChangeTriggered.current = true
    }, 500)
  }

  return (
    <Grid container direction="row">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ marginBottom: 0 }} variant="h4">
            Testing Summary
          </Typography>
        </Grid>
        <Grid item>
          <Box height="36px" display="flex">
            {showResetButton && (
              <Button
                id="reset-button"
                data-testid="reset-button"
                onClick={handleReset}
                startIcon={<UndoOutlined />}
                variant="outlined"
                color="primary"
              >
                Reset To Default
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="summary-text-input"
          data-testid="summary-text-input"
          multiline
          fullWidth
          rows={8}
          value={testingSummary}
          type="text"
          variant="outlined"
          inputProps={{
            maxLength: MAX_CHARACTER_LIMIT,
          }}
          onChange={handleChange}
          helperText={helperText}
          FormHelperTextProps={{ style: { color: baseColors.text.secondary } }}
        />
      </Grid>
    </Grid>
  )
}

export default CommissionReportTestSummary
