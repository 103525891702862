import {
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
} from '@material-ui/core'
import React from 'react'
import CheckIcon from '@material-ui/icons/Check'

interface EditableCellBasePresentationalProps {
  /** Function returning a text/date/dropdown input depending on cell type. */
  renderCellInput: Function
  /** Boolean indicating whether save check should appear in a cell. */
  displayCheck: boolean
  /** Boolean indicating whether the current cell is in a loading state. */
  isLoading: boolean
  /** Onclick handler for saving cell updates. */
  intentChange: () => void
  /** Width of individual cells. */
  cellWidth: string
}

function EditableCellBasePresentational(
  props: EditableCellBasePresentationalProps
) {
  const {
    renderCellInput,
    displayCheck,
    isLoading,
    intentChange,
    cellWidth,
  } = props

  return (
    <TableCell align="left">
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        style={{ minWidth: cellWidth }}
      >
        <Grid item xs={10}>
          {renderCellInput()}
        </Grid>
        {displayCheck && (
          <Grid item xs={2}>
            {isLoading ? (
              <CircularProgress color="primary" size="2em" />
            ) : (
              <IconButton
                aria-label="intentSave"
                color="primary"
                onClick={intentChange}
              >
                <CheckIcon />
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    </TableCell>
  )
}

export default EditableCellBasePresentational
