/** This file contains the fresh property constants used cross Analysis view */

import { getCO2DosagePrecision } from '../Helpers/BaleenHelpers'
import { MixGroupConditions } from '../Logic/Types'

export const freshProperties = [
  { key: 'slump', label: 'Slump' },
  { key: 'air', label: 'Air Content' },
  { key: 'ambientTemperature', label: 'Ambient Temperature' },
  { key: 'concreteTemperature', label: 'Concrete Temperature' },
  { key: 'batchWaterCementRatio', label: 'Water-Cement Ratio' },
  { key: 'batchCementContent', label: 'Cement Content' },
  { key: 'co2Dosage', label: 'CO2 Dosage' },
  { key: 'batchStrength', label: 'Batch Strength' },
  { key: 'batchInterval', label: 'Age' },
]

export const additionalFilters = [
  { key: 'dataSetSize', label: 'Data Set Size' },
  { key: 'productionDate', label: 'Production Date' },
  { key: 'testCategory', label: 'Test Category' },
]
export const freshPropertyLabelsWithImperialUnit = [
  { key: 'slump', label: 'Slump (in)' },
  { key: 'air', label: 'Air Content (%)' },
  { key: 'ambientTemperature', label: 'Ambient Temperature (F)' },
  { key: 'concreteTemperature', label: 'Concrete Temperature (F)' },
  { key: 'batchWaterCementRatio', label: 'Water-Cement Ratio' },
  { key: 'batchCementContent', label: 'Cement Content (lb)' },
  { key: 'co2DosageLabel', label: 'CO₂ Dosage' },
  { key: 'batchStrength', label: 'Batch Strength (psi)' },
  { key: 'batchInterval', label: 'Age' },
]

export const freshPropertyLabelsWithMetricUnit = [
  { key: 'slump', label: 'Slump (mm)' },
  { key: 'air', label: 'Air Content (%)' },
  { key: 'ambientTemperature', label: 'Ambient Temperature (C)' },
  { key: 'concreteTemperature', label: 'Concrete Temperature (C)' },
  { key: 'batchWaterCementRatio', label: 'Water-Cement Ratio' },
  { key: 'batchCementContent', label: 'Cement Content (kg)' },
  { key: 'co2DosageLabel', label: 'CO₂ Dosage' },
  { key: 'batchStrength', label: 'Batch Strength (MPa)' },
  { key: 'batchInterval', label: 'Age' },
]

export const axisLabelsWithImperialUnit = {
  slump: 'Slump (in)',
  air: 'Air Content (%)',
  ambientTemperature: 'Ambient Temperature (F)',
  concreteTemperature: 'Concrete Temperature (F)',
  batchWaterCementRatio: 'Water-Cement Ratio',
  batchCementContent: 'Cement Content (lb)',
  co2DosageLabel: 'CO₂ Dosage',
  batchStrength: 'Batch Strength (psi)',
  batchInterval: 'Interval (Days)',
  samples: 'Sample Indexes',
  frequency: 'Frequency',
  productionDate: 'Date',
  testCategory: 'Test Category',
}

export const axisLabelsWithMetricUnit = {
  slump: 'Slump (mm)',
  air: 'Air Content (%)',
  ambientTemperature: 'Ambient Temperature (C)',
  concreteTemperature: 'Concrete Temperature (C)',
  batchWaterCementRatio: 'Water-Cement Ratio',
  batchCementContent: 'Cement Content (kg)',
  co2DosageLabel: 'CO₂ Dosage',
  batchStrength: 'Batch Strength (MPa)',
  batchInterval: 'Interval (Days)',
  samples: 'Sample Indexes',
  frequency: 'Frequency',
  productionDate: 'Date',
  testCategory: 'Test Category',
}

export const dataPointsSymbols = [
  'circle',
  'square',
  'diamond',
  'triangle',
  'triangle-down',
]

export const colorAxisColors = {
  [MixGroupConditions.NOCO2]: [
    ['#114c75', '#4e7796', '#88a5ba', '#c4d3dd'],
    ['#0c1921', '#495358', '#868c90', '#c3c6c8'],
  ],
  [MixGroupConditions.CO2]: [
    ['#e37f1c', '#e79c52', '#edc093', '#f1d8bf'],
    ['#ffc144', '#ffd16c', '#ffe09c', '#fdf0D2'],
  ],
}

export const controlColorSet = [
  '#114c75',
  '#4e7796',
  '#88a5ba',
  '#c4d3dd',
  '#0c1921',
  '#495358',
]
export const co2ColorSet = [
  '#e37f1c',
  '#e79c52',
  '#edc093',
  '#f1d8bf',
  '#ffc144',
  '#ffd16c',
]

export const histoColorSet = [
  {
    dark: '#E37F1C',
    light: '#EDC09350',
  },
  {
    dark: '#114C75',
    light: '#88A5BA50',
  },
  {
    dark: '#3B8D72',
    light: '#B8E2D460',
  },
]

export const commissioningTrialValues = [
  'DosageRamp',
  'ThreePercentCutTesting',
  'FivePercentCutTesting',
  'CementReduction',
  'CommissioningTrial',
]

export const getBatchSampleUnitPrecision = (
  property,
  isMetric,
  cO2DosageUnit
) => {
  switch (property) {
    case 'slump':
      return isMetric ? 0 : 1
    case 'air':
      return 1
    case 'ambientTemperature':
      return 2
    case 'concreteTemperature':
      return 2
    case 'batchCementContent':
      return 0
    case 'batchWaterCementRatio':
      return 3
    case 'batchStrength':
      return isMetric ? 2 : 0
    case 'co2DosageLabel':
      return getCO2DosagePrecision(cO2DosageUnit, isMetric)
    default:
      return 2
  }
}

export const getTooltipBatchSampleUnitPrecision = (property, isMetric) => {
  switch (property) {
    case 'slump':
      return isMetric ? ':.0f' : ':.1f'
    case 'air':
      return ':.1f'
    case 'ambientTemperature':
      return ':.2f'
    case 'concreteTemperature':
      return ':.2f'
    case 'batchCementContent':
      return ':.0f'
    case 'batchWaterCementRatio':
      return ':.3f'
    case 'batchStrength':
      return isMetric ? ':.2f' : ':.0f'
    case 'cementContent': // for baleen
      return ':.0f'
    default:
      return ''
  }
}
