import React, { ChangeEvent } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

interface IComponentProps {
  handleChange: (event: ChangeEvent<{}>, value: string | null) => void
  companyList: string[]
}

function CompanyNameSelectPresentational(props: IComponentProps) {
  const { handleChange, companyList } = props

  return (
    <Autocomplete
      id="companyNameSelect"
      onChange={handleChange}
      options={companyList}
      renderInput={params => (
        <TextField
          {...params}
          label="Company name"
          variant="outlined"
          size="small"
          placeholder="Select a company name..."
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default CompanyNameSelectPresentational
