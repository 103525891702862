import React, { Dispatch, SetStateAction, ChangeEvent } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { ICommissionReportSettings, IMixSelection } from '../../Logic/Types'
import CommissionReportSelectAnalysis from './CommissionReportSelectAnalysis'
import { Paper, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import TestResultsGraphs from './TestResultsGraphs'
import {
  filterSelectedStrengthAndStDevHours,
  removeIntervalFromAvgStrengthGraph,
} from '../../Helpers/CommissionReportHelpers'

export interface ICommissionReportTestResultsProps {
  reportSettings: ICommissionReportSettings
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  mixSelections: IMixSelection[]
  tabValue: number
  parentClasses: ClassNameMap<string>
  isCommissionReportViewMode: boolean
  isPrinting: boolean
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: '24px 0px',
  },
  pdfContainer: {
    margin: '12px 0px',
  },
}))
function CommissionReportTestResults(
  props: Readonly<ICommissionReportTestResultsProps>
) {
  const {
    reportSettings,
    setReportSettings,
    mixSelections,
    tabValue,
    parentClasses,
    isCommissionReportViewMode,
    isPrinting,
  } = props

  const classes = useStyles()
  const handleAnalysisChange = (event: ChangeEvent<{ id: string }>) => {
    const avgStrengthGraphSelected =
      reportSettings?.mixDesignSettings[tabValue]?.avgStrengthGraph.isSelected
    const frequencyGraphSelected =
      reportSettings?.mixDesignSettings[tabValue]?.frequencyGraph.isSelected
    const isGraphDataDefined =
      frequencyGraphSelected !== undefined &&
      avgStrengthGraphSelected !== undefined
    const type = event.target.id

    if (isGraphDataDefined) {
      if (type === 'frequencyGraph') {
        if (frequencyGraphSelected && !avgStrengthGraphSelected) return // Can't have both graphs unchecked
        setReportSettings(prevSettings => {
          const newSettings = cloneDeep(prevSettings)
          const mixGroupInterval = mixSelections[tabValue].specifiedMaturityAge

          newSettings.mixDesignSettings[
            tabValue
          ].frequencyGraph.isSelected = !newSettings.mixDesignSettings[tabValue]
            .frequencyGraph.isSelected

          filterSelectedStrengthAndStDevHours(
            'frequencyGraph',
            reportSettings,
            newSettings,
            tabValue
          )

          removeIntervalFromAvgStrengthGraph(
            'frequencyGraph',
            avgStrengthGraphSelected,
            frequencyGraphSelected,
            mixGroupInterval,
            newSettings,
            tabValue
          )
          return {
            ...newSettings,
          }
        })
      } else if (type === 'avgStrengthGraph') {
        if (avgStrengthGraphSelected && !frequencyGraphSelected) return // Can't have both graphs unchecked
        setReportSettings(prevSettings => {
          const newSettings = cloneDeep(prevSettings)
          const mixGroupInterval = mixSelections[tabValue].specifiedMaturityAge

          newSettings.mixDesignSettings[
            tabValue
          ].avgStrengthGraph.isSelected = !newSettings.mixDesignSettings[
            tabValue
          ].avgStrengthGraph.isSelected

          filterSelectedStrengthAndStDevHours(
            'avgStrengthGraph',
            reportSettings,
            newSettings,
            tabValue
          )

          removeIntervalFromAvgStrengthGraph(
            'avgStrengthGraph',
            avgStrengthGraphSelected,
            frequencyGraphSelected,
            mixGroupInterval,
            newSettings,
            tabValue
          )
          return {
            ...newSettings,
          }
        })
      }
    }
  }

  const getClassName = () => {
    if (isCommissionReportViewMode) {
      return isPrinting ? classes.pdfContainer : classes.container
    }
    return parentClasses.subSectionContainer
  }

  return (
    <>
      {!isCommissionReportViewMode && (
        <CommissionReportSelectAnalysis
          mixSelections={mixSelections}
          reportSettings={reportSettings}
          tabValue={tabValue}
          handleAnalysisChange={handleAnalysisChange}
        />
      )}
      <Paper
        elevation={isCommissionReportViewMode ? 0 : 1}
        className={getClassName()}
      >
        <TestResultsGraphs
          reportSettings={reportSettings}
          setReportSettings={setReportSettings}
          mixSelections={mixSelections}
          tabValue={tabValue}
          isCommissionReportViewMode={isCommissionReportViewMode}
          isPrinting={isPrinting}
        />
      </Paper>
    </>
  )
}

export default CommissionReportTestResults
