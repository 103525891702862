import { Grid, Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getOverviewTableRows } from '../Data/PCBDataHelpers'
import ArrayOfObjectSearch from '../Components/ArrayOfObjectSearch'
import MasterUnitTable from '../Components/MasterUnitTable'

MasterUnitTableView.propTypes = {
  setSelectedMasterUnit: PropTypes.func.isRequired,
}

function MasterUnitTableView(props) {
  const { setSelectedMasterUnit } = props
  const [masterUnitData, setMasterUnitData] = useState([])
  const [searchedMasterUnits, setSearchedMasterUnits] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState('')
  const [sortColumn, setSortColumn] = useState('')

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = event => {
    const newRowPerPage = event.target.value
    setRowsPerPage(newRowPerPage)
  }

  useEffect(() => {
    const fetchMasterUnitTablePropsAll = async function(
      sortOrder,
      limit,
      offset,
      sortColumn
    ) {
      const overviewWithAlarms = await getOverviewTableRows(
        false,
        sortOrder,
        limit,
        offset,
        false,
        sortColumn
      )

      if (!overviewWithAlarms.ok) {
        return []
      } else {
        return await overviewWithAlarms.json()
      }
    }
    const offset = rowsPerPage * page
    fetchMasterUnitTablePropsAll(
      sortOrder,
      rowsPerPage,
      offset,
      sortColumn
    ).then(result => {
      setMasterUnitData(result)
      setSearchedMasterUnits(result)
    })
  }, [rowsPerPage, page, sortColumn, sortOrder])

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justify="space-between"
      spacing={2}
    >
      <Grid container item spacing={2} wrap="nowrap" alignItems="center">
        <Grid item xs={2}>
          <Typography variant="h4">All Units</Typography>
        </Grid>
        <Grid container item alignItems="center" justify="flex-end">
          <Grid item>
            <ArrayOfObjectSearch
              arraySearchOn={masterUnitData}
              setResultState={setSearchedMasterUnits}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Paper>
          <MasterUnitTable
            page={page}
            handleChangePage={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            data={searchedMasterUnits}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            setSelectedMasterUnit={setSelectedMasterUnit}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MasterUnitTableView
