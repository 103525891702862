import React, { useEffect, useCallback } from 'react'
import {
  getAssociatedMixesByMixDesignId,
  getMixDetailsByMixDesignId,
} from '../../Data/TSSDataHelpers'
import {
  EditMixAssociationOptions,
  MixAssociations,
  MixGroupStatus,
  MixGroupConditions,
} from '../../Logic/Types'
import EditMixAssociationPresentational from './EditMixAssociationPresentational'

export interface IEditMixAssociationLogical {
  /** Boolean indicating whether or not the Edit Mix Association interface is visible */
  isEditMixAssociationMenuOpen: boolean
  /** Function to determine whether or not the Edit Mix Association interface is visible */
  setIsEditMixAssociationMenuOpen: (arg1: boolean) => void
  /** Function to determine whether or not the View & Select Mix Variation interface is visible */
  setIsCopyMixVariationMenuOpen: (arg1: boolean) => void
  /** Number of plant options */
  plantOptionsLength: number
  /** Boolean indicating if a plant has been selected */
  isPlantSelected: boolean
  /** Boolean indicating if a customer has been selected */
  isCustomerSelected: boolean
  /** Mix Association Details */
  mixGroupDetails: Array<MixAssociations>
  /** Function to get Mix Design ID and Baseline information of selected Mix Group  */
  setAssociatedMixGroupDetails: (arg1: string) => void
  /** The Parent Mix Association of the Mix Design being edited  */
  parentMixAssociation: any
  /** Function to handle pre-populated Mix Association for the Mix Design being edited  */
  setParentMixAssociation: () => void
  /** mixDesignId of the mix being edited */
  editMixId: string | null
  /** The remaining details of the mix being edited */
  editedMixGroupDetails: any
  /** Function to set the boolean if a mix is part of association */
  setInAssociation: (arg1: boolean) => void
}

/** Checks if a mix is active or has an active direct descendent the mix */
export const hasActiveDescendent = (
  currentMix: { isActiveMixGroup: boolean; mixDesignId: number },
  associatedMixes: any[]
) => {
  if (!currentMix || !associatedMixes) return
  if (currentMix.isActiveMixGroup) return true

  const children = associatedMixes.filter(
    c => c.parentMixDesignId === currentMix.mixDesignId
  )
  if (children.length < 1) {
    return currentMix.isActiveMixGroup
  }

  return children.some(am => hasActiveDescendent(am, associatedMixes))
}

/** Checks for direct descendents */
export const isDirectDescendent = (
  selectedMix: any,
  currentMix: any,
  associatedMixes: any[]
) => {
  if (!currentMix || !associatedMixes || !selectedMix) return
  if (currentMix.mixDesignId === selectedMix.mixDesignId) return true
  const children = associatedMixes.filter(
    c => c.parentMixDesignId === currentMix.mixDesignId
  )
  if (children.length < 1) {
    return false
  }

  return children.some(am =>
    isDirectDescendent(selectedMix, am, associatedMixes)
  )
}

/** Checks if the selected associated mix is part of   */
export const isPartOfAssociation = (
  selectedMix: any,
  associatedMixes: any[]
) => {
  for (const mix of associatedMixes) {
    if (selectedMix && selectedMix['mixDesignId'] === mix['mixDesignId']) {
      return true
    }
  }
  return false
}

const EditMixAssociationLogical = (props: IEditMixAssociationLogical) => {
  const {
    isEditMixAssociationMenuOpen,
    setIsEditMixAssociationMenuOpen,
    setIsCopyMixVariationMenuOpen,
    plantOptionsLength,
    isPlantSelected,
    isCustomerSelected,
    mixGroupDetails,
    setAssociatedMixGroupDetails,
    parentMixAssociation,
    setParentMixAssociation,
    editMixId,
    editedMixGroupDetails,
    setInAssociation,
  } = props
  const [conditionTag, setConditionTag] = React.useState<string>('')
  const [statusTag, setStatusTag] = React.useState<string>('')
  const [associatedMixDesign, setAssociatedMixDesign] = React.useState<any>(
    parentMixAssociation
  )
  const [
    associatedMixDesignDetails,
    setAssociatedMixDesignDetails,
  ] = React.useState<any>(null)
  const [editedMixAssociations, setEditedMixAssociations] = React.useState<any>(
    []
  )
  const [isAcceptButtonDisabled, setIsAcceptButtonDisabled] = React.useState<
    any
  >(false)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = React.useState<
    any
  >(false)
  const [options, setOptions] = React.useState<EditMixAssociationOptions>()
  const acceptAndClose = () => {
    setIsEditMixAssociationMenuOpen(false)
    if (!associatedMixDesign && parentMixAssociation) {
      setAssociatedMixGroupDetails(parentMixAssociation)
    } else {
      setAssociatedMixGroupDetails(associatedMixDesign)
    }
    //only show copy variation if associatedMixDesign is truthy
    setIsCopyMixVariationMenuOpen(!!associatedMixDesign)
  }
  const clearAll = useCallback(() => {
    setConditionTag('')
    setStatusTag('')
    setParentMixAssociation()
    setAssociatedMixDesign(null)
  }, [setParentMixAssociation])

  const handleOptionFilters = useCallback(
    (mixGroup: Array<any>) => {
      if (!statusTag && !conditionTag) return
      let filteredMixGroup = mixGroup
      const status = statusTag === MixGroupStatus.ACTIVE
      const condition = conditionTag === MixGroupConditions.CO2
      if (statusTag) {
        filteredMixGroup = filteredMixGroup.filter(function(mix) {
          return mix.isActiveMixGroup === status
        })
      }
      if (conditionTag) {
        filteredMixGroup = filteredMixGroup.filter(function(mix) {
          return mix.isCO2Design === condition
        })
      }
      return filteredMixGroup
    },
    [statusTag, conditionTag]
  )

  const createOptions = (data: any) => {
    // Map the properties that will be shown in Mix Association Options, and used for function references
    const newOptions = data?.map(
      ({ mixDesignId, mixCode, baseLineCO2, baseLineCementReduction }) => ({
        mixDesignId,
        mixCode,
        baseLineCO2,
        baseLineCementReduction,
      })
    )
    // Converts property values to strings
    for (let obj of newOptions) {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          obj[key] = String(obj[key])
        }
      }
    }
    return newOptions
  }

  /** Closes Association menu if no plant or customer is selected. */
  useEffect(() => {
    if (!isPlantSelected || !isCustomerSelected) {
      setIsEditMixAssociationMenuOpen(false)
    }
  }, [
    plantOptionsLength,
    isPlantSelected,
    setIsEditMixAssociationMenuOpen,
    isCustomerSelected,
  ])

  /** Controls the dropdown options when selecting a mix association */
  useEffect(() => {
    if (!mixGroupDetails) return
    // Set Filters
    let mixGroups = mixGroupDetails
    if (editMixId) {
      mixGroups = mixGroups.filter(mixGroup => {
        return mixGroup.mixDesignId !== Number(editMixId)
      })
    }
    if (!statusTag && !conditionTag) {
      return setOptions(createOptions(mixGroups))
    } else {
      let filteredOptions = handleOptionFilters(mixGroups)
      return setOptions(createOptions(filteredOptions))
    }
  }, [mixGroupDetails, conditionTag, statusTag, handleOptionFilters, editMixId])

  /** Gets the Mix Details of the Selected Associated Mix */
  useEffect(() => {
    if (!associatedMixDesign) return
    // Create object to pass as argument into fetch request
    const mixObject = {
      id: associatedMixDesign.mixDesignId,
    }
    // Get Mix Details of the selected Associated Mix
    getMixDetailsByMixDesignId(mixObject)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setAssociatedMixDesignDetails(data)
          })
      })
      .catch(e => console.log(e))
  }, [associatedMixDesign])

  /** Get Mix Associations for the Edited Mix */
  useEffect(() => {
    if (!editedMixGroupDetails) return
    // Create object to pass as argument into fetch request
    const mixObject = {
      mixDesignId: editedMixGroupDetails.mixDesignId,
    }
    // Get Associated Mixes of the selected Associated Mix
    getAssociatedMixesByMixDesignId(mixObject)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const { results } = data
            // if the array has more than one element, the mix is in association
            if (results.length > 1) setInAssociation(true)
            setEditedMixAssociations(results)
          })
      })
      .catch(e => console.log(e))
  }, [editedMixGroupDetails, setInAssociation])

  /** Controls Accept & Close button. Determines if selected Associated Mix meets the requirements. */
  useEffect(() => {
    if (!associatedMixDesign) return
    // Create object to pass as argument into fetch request
    const mixObject = {
      mixDesignId: associatedMixDesign.mixDesignId,
    }
    // Get Associated Mixes of the selected Associated Mix
    getAssociatedMixesByMixDesignId(mixObject)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const { results } = data
            if (editedMixAssociations.length > 1) {
              const activeDescendent = hasActiveDescendent(
                editedMixGroupDetails,
                editedMixAssociations
              )
              const directDescendent = isDirectDescendent(
                associatedMixDesignDetails,
                editedMixGroupDetails,
                results
              )
              const inAssociation = isPartOfAssociation(
                editedMixGroupDetails,
                results
              )
              setIsAcceptButtonDisabled(
                directDescendent || (activeDescendent && !inAssociation)
              )
            }
          })
      })
      .catch(e => console.log(e))
  }, [
    associatedMixDesign,
    associatedMixDesignDetails,
    editedMixGroupDetails,
    editedMixAssociations,
  ])

  /** Controls delete button. Determines if an edited mix can be disassociated. */
  useEffect(() => {
    const activeDescendent = hasActiveDescendent(
      editedMixGroupDetails,
      editedMixAssociations
    )
    setIsDeleteButtonDisabled(activeDescendent)
  }, [
    editedMixGroupDetails,
    editedMixAssociations,
    isEditMixAssociationMenuOpen,
  ])

  return (
    <EditMixAssociationPresentational
      isEditMixAssociationMenuOpen={isEditMixAssociationMenuOpen}
      isAcceptButtonDisabled={isAcceptButtonDisabled}
      isDeleteButtonDisabled={isDeleteButtonDisabled}
      conditionTag={conditionTag}
      setConditionTag={setConditionTag}
      statusTag={statusTag}
      setStatusTag={setStatusTag}
      associatedMixDesign={associatedMixDesign}
      setAssociatedMixDesign={setAssociatedMixDesign}
      acceptAndClose={acceptAndClose}
      clearAll={clearAll}
      options={options}
      isPlantSelected={isPlantSelected}
      plantOptionsLength={plantOptionsLength}
      parentMixAssociation={parentMixAssociation}
    />
  )
}

export default EditMixAssociationLogical
