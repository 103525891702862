import { TextField } from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'

function SearchMixCodeField(props) {
  const { analysisFilterSettings, setAnalysisFilterSettings } = props
  const [mixCodeInternal, setMixCodeInternal] = useState(
    analysisFilterSettings['mixCode'][0]
  )

  /*
   Only triggers changing the mix code after half a second of not typing, 
   otherwise prevents the mixCode from being set.

   This is to prevent unnecessary number of requests before a user finishes typing   
  */
  const handleFilterValueChange = useCallback(() => {
    setAnalysisFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        mixCode: mixCodeInternal ? [mixCodeInternal] : [],
      }
    })
  }, [mixCodeInternal, setAnalysisFilterSettings])

  useEffect(() => {
    const timeOut = setTimeout(() => handleFilterValueChange(), 500)
    return () => clearTimeout(timeOut)
  }, [mixCodeInternal, setAnalysisFilterSettings, handleFilterValueChange])

  return (
    <TextField
      variant="outlined"
      onChange={event => setMixCodeInternal(event.target.value)}
      name="mixCode"
      placeholder="Search by Mix Code"
      size="small"
      value={mixCodeInternal}
      label="Mix Code"
      type="search"
    />
  )
}
export default SearchMixCodeField
