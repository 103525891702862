import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { atomCurrentCustomer } from '../../../Common/atoms'
import { Grid, Tooltip } from '@material-ui/core'
import { Visibility, Add } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '1em',
  },
}))

function TableHeadButtons() {
  const classes = useStyles()
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)
  const isSelected = !!selectedCustomer.division

  const toolTipText = 'Select a customer to navigate to these pages.'
  // get base url - customers page is no longer the default homepage
  const baseUrl = '/Concrete'

  return (
    <Tooltip title={toolTipText} disableHoverListener={isSelected}>
      <div>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <Button
            component={Link}
            to={
              isSelected &&
              `${baseUrl}/AddData?divisionId=${selectedCustomer.division.divisionId}`
            }
            variant="outlined"
            disabled={!isSelected}
            className={classes.button}
            color="primary"
            startIcon={<Add />}
          >
            Add data
          </Button>
          <Button
            component={Link}
            to={`${baseUrl}/Designs`}
            variant="outlined"
            disabled={!isSelected}
            color="primary"
            startIcon={<Visibility />}
          >
            View designs
          </Button>
        </Grid>
      </div>
    </Tooltip>
  )
}

export default TableHeadButtons
