import React, { useEffect, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import HighchartsReact from 'highcharts-react-official'
import HighCharts from 'highcharts/highstock'

import WebSocketCommandController from '../Components/WebSocketCommandController'

require('highcharts/modules/export-data')(HighCharts)
require('highcharts/modules/offline-exporting')(HighCharts)
require('highcharts/modules/exporting')(HighCharts)

const INLET_GAS_PRESSURE_OVERRIDE = 1 << 2
const INLET_LIQUID_PRESSURE_OVERRIDE = 1 << 3
const DOWNSTREAM_PRESSURE_0_OVERRIDE = 1 << 1
const DOWNSTREAM_PRESSURE_1_OVERRIDE = 1

const GAS_VALVE_ID = 1
const LIQUID_VALVE_ID = 2

function getUniqueLocalRemoteId(localId) {
  return `remoteUnit${localId}`
}

function getUTCNow() {
  const d1 = new Date()
  const now = d1.getTime() - getUTCTimezoneOffsetInMilli()
  return now
}

function getUTCTimezoneOffsetInMilli() {
  const d1 = new Date()
  const offsetInMilli = d1.getTimezoneOffset() * 60 * 1000
  return offsetInMilli
}

function createPlotLine(color, value, labelText) {
  return {
    color,
    value,
    width: 2,
    label: {
      text: labelText,
    },
    zIndex: 5,
  }
}

function getGenericChartOptions(remoteLocalId) {
  const now = getUTCNow()
  return {
    xAxis: {
      type: 'datetime',
      tickInterval: 1000,
      minRange: 20000,
      range: 20000,
      //Give a right-side lead time of 5 seconds
      overscroll: 5000,
      max: now + 25000,
      plotBands: [],
      plotLines: [],
    },
    exporting: {
      enabled: true,
      error: function(options, err) {
        console.error(err)
      },
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: 'Download as PNG',
              onclick: function() {
                this.exportChart()
              },
            },
            {
              text: 'Download as XLS',
              onclick: function() {
                this.downloadXLS()
              },
            },
          ],
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    yAxis: [
      {
        //--- Pressure yAxis
        title: {
          text: 'Pressure',
        },
      },
      {
        //--- Temperature yAxis
        title: {
          text: 'Temperature',
        },
        opposite: true,
      },
    ],
    series: [
      {
        showInNavigator: true,
        type: 'line',
        data: [],
        yAxis: 0,
        name: 'Inlet Gas Pressure',
        color: '#7dbafb',
      },
      {
        showInNavigator: true,
        type: 'line',
        data: [],
        yAxis: 0,
        name: 'Inlet Liquid Pressure',
        color: '#aeb0f5',
      },
      {
        showInNavigator: true,
        type: 'line',
        data: [],
        yAxis: 0,
        name: 'Upstream Pressure',
        color: '#d3deb0',
      },
      {
        type: 'line',
        data: [],
        yAxis: 1,
        name: 'Upstream Temperature',
        color: '#d3deb0',
      },
      {
        showInNavigator: true,
        type: 'line',
        data: [],
        yAxis: 0,
        name: 'Downstream Pressure 1',
        color: '#faf0a4',
      },
      {
        showInNavigator: true,
        type: 'line',
        data: [],
        yAxis: 0,
        name: 'Downstream Pressure 2',
        color: '#ffba85',
      },
    ],
    plotOptions: {
      series: {
        pointStart: now,
        pointInterval: 1000,
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: `Remote Unit ${remoteLocalId}`,
    },
  }
}

//reducer for updating many chart options
function chartReducer(state, action) {
  switch (action.type) {
    case 'reset':
      //reset the state
      return initializeChartStates(action.payload.remoteUnits)
    default:
      return state
  }
}

function initializeChartStates(remoteUnits) {
  const initializedChartsArray = remoteUnits.map(remote => {
    const statusObject = {}
    const remoteUnitUniqueId = getUniqueLocalRemoteId(remote.localId)
    statusObject[remoteUnitUniqueId] = getGenericChartOptions(remote.localId)
    return statusObject
  })

  let initializedChartsObject = {}
  initializedChartsArray.forEach(chart => {
    initializedChartsObject = { ...initializedChartsObject, ...chart }
  })

  return initializedChartsObject
}

/*
    
*/

LiveStatusChartView.propTypes = {
  remoteUnits: PropTypes.array.isRequired,
  webSocket: PropTypes.object,
  handleWebSocketOnClose: PropTypes.func.isRequired,
}

function LiveStatusChartView(props) {
  const { remoteUnits, webSocket, handleWebSocketOnClose } = props
  const [chartOptions, setChartOptions] = useReducer(
    chartReducer,
    remoteUnits,
    initializeChartStates
  )
  const chartRefOne = useRef()
  const chartRefTwo = useRef()
  const chartRefThree = useRef()
  const chartRefFour = useRef()
  const chartRefs = useRef({
    1: chartRefOne,
    2: chartRefTwo,
    3: chartRefThree,
    4: chartRefFour,
  })
  const inletGasOverrideRef = useRef({
    1: {
      active: false,
    },
    2: {
      active: false,
    },
    3: {
      active: false,
    },
    4: {
      active: false,
    },
  })

  const inletLiquidOverrideRef = useRef({
    1: {
      active: false,
    },
    2: {
      active: false,
    },
    3: {
      active: false,
    },
    4: {
      active: false,
    },
  })

  const downsteramPressure0OverrideRef = useRef({
    1: {
      active: false,
    },
    2: {
      active: false,
    },
    3: {
      active: false,
    },
    4: {
      active: false,
    },
  })

  const downsteramPressure1OverrideRef = useRef({
    1: {
      active: false,
    },
    2: {
      active: false,
    },
    3: {
      active: false,
    },
    4: {
      active: false,
    },
  })

  ////////////////////
  //WEBSOCKET LOGIC///
  ////////////////////

  useEffect(() => {
    if (webSocket === null) {
      return
    } else if (
      webSocket.readyState === WebSocket.OPEN ||
      webSocket.readyState === WebSocket.CONNECTING
    ) {
      setChartOptions({ type: 'reset', payload: { remoteUnits } })
    }
    const updateChartAddPoint = (localId, data) => {
      const chartRef = chartRefs.current[localId].current.chart
      const now = getUTCNow()
      let [
        inletGasPressureSeries,
        inletLiquidPressureSeries,
        upstreamPressureSeries,
        upstreamTemperatureSeries,
        downstreamPressure0Series,
        downstreamPressure1Series,
      ] = chartRef.series

      inletGasPressureSeries.addPoint(
        { x: now, y: data.inletGasPressure },
        false,
        false
      )
      inletLiquidPressureSeries.addPoint(
        { x: now, y: data.inletLiquidPressure },
        false,
        false
      )
      upstreamPressureSeries.addPoint(
        { x: now, y: data.upstreamPressure },
        false,
        false
      )
      upstreamTemperatureSeries.addPoint(
        { x: now, y: data.upstreamTemperature },
        false,
        false
      )
      downstreamPressure0Series.addPoint(
        { x: now, y: data.downstreamPressure0 },
        false,
        false
      )
      downstreamPressure1Series.addPoint(
        { x: now, y: data.downstreamPressure1 },
        true,
        false
      )
    }

    const updateChartAddPlotLineValveOpenClose = (
      localId,
      valveId,
      opened = true
    ) => {
      const chartRef = chartRefs.current[localId].current.chart
      let valveText = ''
      let valveTextNavigator = ''
      if (opened) {
        if (valveId === GAS_VALVE_ID) {
          valveText = 'Gas Valve Opened'
          valveTextNavigator = 'GVO'
        } else if (valveId === LIQUID_VALVE_ID) {
          valveText = 'Liquid Valve Opened'
          valveTextNavigator = 'LVO'
        }
      } else {
        if (valveId === GAS_VALVE_ID) {
          valveText = 'Gas Valve Closed'
          valveTextNavigator = 'GVC'
        } else if (valveId === LIQUID_VALVE_ID) {
          valveText = 'Liquid Valve Closed'
          valveTextNavigator = 'LVC'
        }
      }
      const xAxis = chartRef.xAxis[0]
      const navigatorxAxis = chartRef.navigator.xAxis
      const utcNow = getUTCNow()
      const plotLine = createPlotLine('black', utcNow, valveText)
      const navigatorPlotLine = createPlotLine(
        'black',
        utcNow,
        valveTextNavigator
      )

      xAxis.addPlotLine(plotLine)
      navigatorxAxis.addPlotLine(navigatorPlotLine)
    }

    const updateReflectOverrides = (localId, overrides) => {
      const chartRef = chartRefs.current[localId].current.chart
      const [
        inletGasPressureSeries,
        inletLiquidPressureSeries,
        ,
        ,
        downstreamPressure0Series,
        downstreamPressure1Series,
      ] = chartRef.series
      const xAxis = chartRef.xAxis[0]
      const navigatorxAxis = chartRef.navigator.xAxis
      const now = getUTCNow()
      const liquidOverride = inletLiquidOverrideRef.current[localId]
      const gasOverride = inletGasOverrideRef.current[localId]
      const downstream0Override =
        downsteramPressure0OverrideRef.current[localId]
      const downstream1Override =
        downsteramPressure1OverrideRef.current[localId]
      if (overrides & INLET_GAS_PRESSURE_OVERRIDE) {
        inletGasPressureSeries.setName('Inlet Gas Pressure FIXED')
        if (!gasOverride.active) {
          gasOverride.active = true
          const plotLine = createPlotLine(
            inletGasPressureSeries.color,
            now,
            "Inlet Gas Pressure <span style='font-weight: bold;'>FIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            inletGasPressureSeries.color,
            now,
            'IGP F'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      } else {
        inletGasPressureSeries.setName('Inlet Gas Pressure')
        if (gasOverride.active) {
          gasOverride.active = false
          const plotLine = createPlotLine(
            inletGasPressureSeries.color,
            now,
            "Inlet Gas Pressure <span style='font-weight: bold;'>UNFIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            inletGasPressureSeries.color,
            now,
            'IGP U'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      }
      if (overrides & INLET_LIQUID_PRESSURE_OVERRIDE) {
        inletLiquidPressureSeries.setName('Inlet Liquid Pressure FIXED')
        if (!liquidOverride.active) {
          liquidOverride.active = true
          const plotLine = createPlotLine(
            inletLiquidPressureSeries.color,
            now,
            "Inlet Liquid Pressure <span style='font-weight: bold;'>FIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            inletLiquidPressureSeries.color,
            now,
            'ILP F'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      } else {
        inletLiquidPressureSeries.setName('Inlet Liquid Pressure')
        if (liquidOverride.active) {
          liquidOverride.active = false
          const plotLine = createPlotLine(
            inletLiquidPressureSeries.color,
            now,
            "Inlet Liquid Pressure <span style='font-weight: bold;'>UNFIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            inletLiquidPressureSeries.color,
            now,
            'ILP U'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      }
      if (overrides & DOWNSTREAM_PRESSURE_0_OVERRIDE) {
        downstreamPressure0Series.setName('Downstream Pressure 1 FIXED')
        if (!downstream0Override.active) {
          downstream0Override.active = true
          const plotLine = createPlotLine(
            downstreamPressure0Series.color,
            now,
            "Downstream 1 Pressure <span style='font-weight: bold;'>FIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            downstreamPressure0Series.color,
            now,
            'D0 F'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      } else {
        downstreamPressure0Series.setName('Downstream Pressure 1')
        if (downstream0Override.active) {
          downstream0Override.active = false
          const plotLine = createPlotLine(
            downstreamPressure0Series.color,
            now,
            "Downstream 1 Pressure <span style='font-weight: bold;'>UNFIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            downstreamPressure0Series.color,
            now,
            'D1 U'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      }
      if (overrides & DOWNSTREAM_PRESSURE_1_OVERRIDE) {
        downstreamPressure1Series.setName('Downstream Pressure 2 FIXED')
        if (!downstream1Override.active) {
          downstream1Override.active = true
          const plotLine = createPlotLine(
            downstreamPressure1Series.color,
            now,
            "Downstream 2 Pressure <span style='font-weight: bold;'>FIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            downstreamPressure1Series.color,
            now,
            'D2 F'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      } else {
        downstreamPressure1Series.setName('Downstream Pressure 2')
        if (downstream1Override.active) {
          downstream1Override.active = false
          const plotLine = createPlotLine(
            downstreamPressure1Series.color,
            now,
            "Downstream 2 Pressure <span style='font-weight: bold;'>UNFIXED</span>"
          )
          const navigatorPlotLine = createPlotLine(
            downstreamPressure1Series.color,
            now,
            'D2 U'
          )
          navigatorxAxis.addPlotLine(navigatorPlotLine)
          xAxis.addPlotLine(plotLine)
        }
      }
    }

    const handleAlarm = (view, offset, localId) => {
      const unixTimeMs = Number(view.getBigUint64(offset)) * 1000
      offset += 8
      const alarmCode = view.getUint8(offset)
      offset += 1
      const alarmObject = {
        localId: localId,
        alarmCode,
        timeStamp: unixTimeMs,
      }
      return [offset, alarmObject]
    }

    const handleValveClosedResponse = view => {
      let offset = 0
      const localId = Number(view.getUint8(offset))
      offset++
      const valveId = Number(view.getUint8(offset))

      updateChartAddPlotLineValveOpenClose(localId, valveId, false)
    }

    const handleValveOpenedResponse = view => {
      let offset = 0
      const localId = Number(view.getUint8(offset))
      offset++
      const valveId = Number(view.getUint8(offset))

      updateChartAddPlotLineValveOpenClose(localId, valveId, true)
    }

    const handleStatusResponse = function(view) {
      let inletLiquidPressure,
        inletGasPressure,
        upstreamPressure,
        upstreamTemperature,
        downstreamPressure0,
        downstreamPressure1

      //Fix the floating point number to 3 digits after the decimal place
      const toFixedThree = num => Number(Number(num).toFixed(3))

      let offset = 0
      for (let i = 0; i < remoteUnits.length; i++) {
        const localId = Number(view.getUint8(offset))
        offset += 1
        inletLiquidPressure = toFixedThree(view.getFloat32(offset))
        offset += 4
        inletGasPressure = toFixedThree(view.getFloat32(offset))
        offset += 4
        upstreamPressure = toFixedThree(view.getFloat32(offset))
        offset += 4
        downstreamPressure0 = toFixedThree(view.getFloat32(offset))
        offset += 4
        downstreamPressure1 = toFixedThree(view.getFloat32(offset))
        offset += 4
        upstreamTemperature = toFixedThree(view.getFloat32(offset))
        offset += 4

        let statusObject = {
          localId,
          inletLiquidPressure,
          inletGasPressure,
          upstreamPressure,
          upstreamTemperature,
          downstreamPressure1,
          downstreamPressure0,
        }
        const sensorOverrides = view.getUint8(offset)
        offset += 1
        updateReflectOverrides(localId, sensorOverrides)
        const numAlarms = Number(view.getUint8(offset))
        offset += 1
        for (let i = 0; i < numAlarms; i++) {
          const [newOffset] = handleAlarm(view, offset, localId)
          offset = newOffset
        }

        updateChartAddPoint(localId, statusObject)
      }
    }

    const incomingMessageCallBacks = {
      128: handleStatusResponse,
      129: handleValveOpenedResponse,
      130: handleValveClosedResponse,
    }

    const handleOnMessage = function(event) {
      let view = new DataView(event.data)
      let code = view.getUint8()

      if (code in incomingMessageCallBacks) {
        return [
          incomingMessageCallBacks[code](new DataView(event.data, 1)),
          code,
        ]
      }
    }

    webSocket.onmessage = handleOnMessage
    webSocket.onclose = handleWebSocketOnClose
    function cleanup() {
      if (webSocket) webSocket.close()
    }
    return cleanup
  }, [webSocket, remoteUnits, handleWebSocketOnClose])

  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        {remoteUnits.map(remoteUnit => {
          const remoteUID = getUniqueLocalRemoteId(remoteUnit.localId)

          return (
            <Grid key={remoteUID} item container spacing={2} direction="column">
              <Grid
                container
                item
                alignItems="flex-start"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h4">
                    Remote Unit {remoteUnit.localId}
                  </Typography>
                  <Typography variant="h5">Status</Typography>
                </Grid>
                <Grid item>
                  {webSocket !== null ? (
                    <WebSocketCommandController
                      remoteUnitLocalId={remoteUnit.localId}
                      webSocket={webSocket}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <HighchartsReact
                  highcharts={HighCharts}
                  options={chartOptions[remoteUID]}
                  ref={chartRefs.current[remoteUnit.localId]}
                  constructorType={'stockChart'}
                />
              </Grid>
              <Grid item>
                <hr />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default LiveStatusChartView
