import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@material-ui/core'
import EngTableHead from './EngTableHead'
import PropTypes from 'prop-types'
import Status from '../Logic/Status'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  wideCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    overflow: 'hidden',
  },
}))

/**
 *
 * @param {Object} props
 * @param {Status []} props.inletData
 * @returns
 */
function InletTablePresentational(props) {
  InletTablePresentational.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Data from the inlet data endpoint */
    inletData: PropTypes.arrayOf(Status).isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Total number of results from the service cases endpoint  */
    count: PropTypes.number.isRequired,
    /** Function to go to another page of the table */
    handleChangePage: PropTypes.func.isRequired,
    /** Function to update the number of table rows displayed */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Table Column headers */
    headCells: PropTypes.array.isRequired,
  }
  const {
    handleTableSort,
    inletData,
    order,
    orderBy,
    page,
    rowsPerPage,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
    headCells,
  } = props
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer>
        <Table aria-label="inlet data" size={'small'}>
          <EngTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleTableSort}
            headCells={headCells}
            includeCheckbox={false}
          />
          <TableBody>
            {/* Map through the inlet data and display it as sortable rows */}
            {inletData.map((row, index) => {
              return (
                <TableRow tabIndex={-1} key={index}>
                  <TableCell className={classes.wideCell}>
                    {row.formattedTimestamp}
                  </TableCell>
                  <TableCell>{row.inletLiquidPressurePSI}</TableCell>
                  <TableCell>{row.inletGasPressurePSI}</TableCell>
                  <TableCell>{Math.round(row.upstreamTemperatureC)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleTablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default InletTablePresentational
