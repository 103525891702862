import PropTypes from 'prop-types'
import React from 'react'
import Status from '../Logic/Status'
import InjectionLineChartPresentational from './InjectionLineChartPresentational'

/**
 *
 * @param {Object} props
 * @param {Status[]} props.statusArray
 * @param {Boolean} props.metric
 */
function GasChartLogical(props) {
  const data = props.statusArray.map(status => [
    status.timestamp.getTime(),
    props.metric ? status.inletGasPressureKPa : status.inletGasPressurePSI,
  ])

  return (
    <InjectionLineChartPresentational
      data={data}
      title="Inlet Gas Pressure"
      unit={props.metric ? 'kpa' : 'psi'}
    />
  )
}

GasChartLogical.propTypes = {
  statusArray: PropTypes.arrayOf(PropTypes.instanceOf(Status)).isRequired,
  metric: PropTypes.bool,
}

GasChartLogical.defaultProps = {
  metric: false,
}

export default GasChartLogical
