import React from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { SimpleTableRow } from '../../../Common/Logic/Types'
import {
  IMaterial,
  IMaterialCard,
  MaterialManagerComponent,
  MaterialType,
} from '../../Logic/Types'
import { EditOutlined } from '@material-ui/icons'
import { tssCanWrite } from '../../Logic/TSSLogic'
import PlantCompositionCardLogical from '../PlantCompositionCard/PlantCompositionCardLogical'

export interface IMaterialTableCollapsibleRowProps {
  roles: Array<string>
  row: SimpleTableRow
  displayCell: (
    rowCell: string | number | null,
    id: string | number,
    key: string
  ) => null | string | number | JSX.Element | JSX.Element[] | undefined
  materials: Array<IMaterial>
  materialCards: Array<IMaterialCard>
  setCurrentView: (view: MaterialManagerComponent) => void
  handleEditMaterial: (arg0: number) => void
  setIsFilterPanelOpen?: (arg0: boolean) => void
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0 4%',
  },
  materialCard: {
    flex: '0 0 20%',
    margin: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  editButton: {
    position: 'sticky',
    right: '5%',
    zIndex: 50,
  },
}))

const MaterialTableCollapsibleRow = (
  props: IMaterialTableCollapsibleRowProps
) => {
  const {
    roles,
    row,
    materialCards,
    displayCell,
    setCurrentView,
    handleEditMaterial,
    setIsFilterPanelOpen = () => undefined,
  } = props
  const classes = useStyles()

  const CO2KeysToRender = [
    'Company Name',
    'Material Type',
    'Material Subtype',
    'Specific Gravity',
    'Alias',
  ]

  return (
    <div className={classes.container}>
      {materialCards
        .filter(card => card.id === row.id)
        .map(card => (
          <div key={card.id} className={classes.cardContainer}>
            {Object.entries(card).map(([key, value]) => {
              // Handle data contained within cementSupplier object
              value = key === 'Cement Supplier' ? value?.plantName : value
              // This line is corrected with curly braces
              // Not to render metadata & only specific keys for CO2
              return (card['Material Type'] !== MaterialType.CO2 &&
                !['id', 'materialId', 'divisionId'].includes(key)) ||
                (card['Material Type'] === MaterialType.CO2 &&
                  CO2KeysToRender.includes(key)) ? (
                <Card key={key} elevation={0} className={classes.materialCard}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ marginBottom: '16px' }}
                    >
                      {key}
                    </Typography>
                    <Typography variant="body2">
                      {displayCell(value, row.id, key)}
                    </Typography>
                  </CardContent>
                </Card>
              ) : null
            })}
            {card['Material Type'] === MaterialType.CEMENT && (
              <PlantCompositionCardLogical
                divisionId={card.divisionId}
                currentMaterialId={card.materialId}
                isEditMode={false}
              />
            )}
          </div>
        ))}
      {tssCanWrite(roles) && (
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            startIcon={<EditOutlined />}
            onClick={() => {
              handleEditMaterial(row.id as number)
              setIsFilterPanelOpen(false)
              setCurrentView(MaterialManagerComponent.EditMaterial)
            }}
          >
            Edit Material
          </Button>
        </div>
      )}
      <Divider
        variant="middle"
        style={{ display: 'block', width: '100%', margin: '24px 0 0 0' }}
      />
    </div>
  )
}

export default MaterialTableCollapsibleRow
