import React from 'react'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { CommissioningFilterOptions } from '../../Constants/CommonConstants'

function CommissioningFilter(props) {
  CommissioningFilter.propTypes = {
    /** Table rows */
    tableRows: PropTypes.array.isRequired,
    /** Boolean to determine whether the filter is being displayed on the Customers page. */
    customersPage: PropTypes.bool.isRequired,
  }

  const {
    tableRows,
    customersPage,
    commissioningFilter,
    onChangeHandler,
    disabled,
  } = props

  /** Options for the dropdown */
  const options = [
    CommissioningFilterOptions.InCommissioning,
    CommissioningFilterOptions.CommissioningComplete,
  ]
  return (
    <Autocomplete
      value={
        !customersPage &&
        commissioningFilter === CommissioningFilterOptions.CommissioningComplete
          ? null
          : commissioningFilter
      }
      options={options}
      disabled={disabled || !!!tableRows}
      getOptionDisabled={option =>
        !customersPage &&
        option === CommissioningFilterOptions.CommissioningComplete
      }
      renderInput={params => (
        <TextField
          {...params}
          label="Commissioning"
          variant="outlined"
          size="small"
          name="commissioningDropdown"
          placeholder="Select Commissioning"
        />
      )}
      onChange={onChangeHandler}
    />
  )
}

export default CommissioningFilter
