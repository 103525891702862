import React, { useEffect, useState } from 'react'
import { PrecastTablePresentational } from './PrecastTablePresentational'
import PropTypes from 'prop-types'
import { formatCorpTableData } from '../../Logic/DataFormatters'

export const PrecastTableLogical = props => {
  PrecastTableLogical.propTypes = {
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** The table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Array of data to display on the precast table. */
    precastTableData: PropTypes.array.isRequired,
  }

  const {
    setSelectedRows,
    selectedRows,
    setIsLoading,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setRowsPerPage,
    precastTableData,
  } = props

  const [formattedPrecastTableData, setFormattedPrecastTableData] = useState([])

  /** Function to sort the table rows */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Function to handle selecting table rows */
  const handleClick = (event, row) => {
    /** Turn on the loading spinner until the selected company is highlighted on the map */
    setIsLoading(true)
    setTimeout(() => {
      const selectedIndex = selectedRows.indexOf(row)
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, row)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1))
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        )
      }
      setSelectedRows(newSelected)
      /** Turn off the loading spinner once the selected company is highlighted on the map */
      setIsLoading(false)
    }, 1000)
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    /** The precast table data, with division list and state list added */
    setFormattedPrecastTableData(formatCorpTableData(precastTableData))
  }, [precastTableData])

  return (
    <PrecastTablePresentational
      {...props}
      handleRequestSort={handleRequestSort}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      formattedPrecastTableData={formattedPrecastTableData}
    />
  )
}
