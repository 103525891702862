import { PropertyUnit } from '../../../TSS/Logic/Types'

const getCollapsibleHeadCells = (isMetric?: boolean) => {
  return [
    {
      id: 'dropdownCol',
      width: '30px',
      name: '',
    },
    {
      id: 'cementReduction',
      width: '120px',
      name: 'Avg. Cmt. Red.',
      tooltip: 'Average Cement Reduction',
    },
    {
      id: 'avgCO2Dosage',
      width: '120px',
      name: `Avg. CO₂ Dos. (${
        isMetric ? PropertyUnit.MilliLitrePerM3 : PropertyUnit.OzPerYd3
      })`,
      tooltip: 'Average CO₂ Dosage',
      sortable: true,
    },
    {
      id: 'designStrength',
      width: '100px',
      name: `Design Str. (${isMetric ? 'MPa' : 'psi'})`,
      tooltip: `Design Strength`,
    },
    {
      id: 'cementLoading',
      width: '90px',
      name: `Cmt. Load.  (${
        isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
      })`,
      tooltip: `Cement Loading`,
    },
    {
      id: 'slump',
      width: '70px',
      name: `Slump  (${isMetric ? 'mm' : 'in'})`,
    },
    {
      id: 'waterCementRatio',
      width: '90px',
      name: 'W/CM',
    },
    {
      id: 'scmType',
      width: '120px',
      name: 'SCM Type',
    },
    {
      id: 'scmPercent',
      width: '70px',
      name: '%SCM',
    },
    {
      id: 'productionDate',
      width: '110px',
      name: 'Prod. Date',
      tooltip: 'Production Date',
    },
    {
      id: 'testResult',
      width: '120px',
      name: '',
    },
  ]
}

const getTableCellStyles = () => {
  const styles: Record<string, any> = {} //need to be any type here

  getCollapsibleHeadCells().forEach((cell, index) => {
    styles[`${cell.id}-headCell`] = {
      width: cell.width,
    }
    styles[`${cell.id}-bodyCell`] = {
      width: cell.width,
      padding: index === 0 ? '0px 0px 0px 4px' : '6px 16px',
    }
  })
  return styles
}

const helper = {
  getCollapsibleHeadCells,
  getTableCellStyles,
}

export default helper
