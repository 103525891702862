import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { PlantsEditableCellDropdown } from './EditableCell.types'

interface EditableCellDropdownProps {
  /** Currently selected value in dropdown cell. */
  value: PlantsEditableCellDropdown | null
  /** Function to update selected value. */
  setValue: Function
  /** Options for dropdown selection. */
  options: Array<PlantsEditableCellDropdown>
  /** Whether field is currently focused. Used to highlight associated fields. */
  isFocused: boolean
  /** Whether the field is disabled */
  disabled?: boolean
}

function EditableCellDropdown(props: EditableCellDropdownProps) {
  const { value, setValue, options, isFocused, disabled } = props

  return (
    <Autocomplete
      value={value}
      options={options}
      onChange={(_, newValue) => {
        setValue(newValue?.id)
      }}
      getOptionSelected={(option, selection) => option.id === selection.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          focused={isFocused}
        />
      )}
      disabled={disabled}
    />
  )
}

export default EditableCellDropdown
