import PropTypes from 'prop-types'
import React from 'react'
import Injection from '../Logic/Injection'
import CO2InjectedGraphPresentational from './CO2InjectedGraphPresentational'

const colors = ['#e5892e']

/**
 *
 * @param {Object} props
 * @param {Injection []} props.injectionArray
 * @param {Boolean} props.metric
 */
function CO2InjectedGraphLogical(props) {
  const data = props.injectionArray.map((injection, index) => ({
    x: injection.startTime.getTime(),
    y: props.metric ? injection.injectedCO2Kg : injection.injectedCO2Lb,
    color: colors[index % colors.length],
  }))
  const unit = props.metric ? 'kg' : 'lb'
  const title = props.title

  return (
    <CO2InjectedGraphPresentational data={data} unit={unit} title={title} />
  )
}

CO2InjectedGraphLogical.propTypes = {
  injectionArray: PropTypes.arrayOf(PropTypes.instanceOf(Injection)).isRequired,
  metric: PropTypes.bool,
}

CO2InjectedGraphLogical.defaultProps = {
  metric: false,
}

export default CO2InjectedGraphLogical
