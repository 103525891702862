import { Backdrop, CircularProgress, Paper } from '@material-ui/core'
import React, { Dispatch } from 'react'
import { useRouteMatch } from 'react-router-dom'
import SimpleTablePresentational from '../../../Common/Components/SimpleTable/SimpleTablePresentational'
import { reportLibraryHeadCells } from '../../Constants/CommissionReportConstants'
import { reportsToRows } from '../../Helpers/ReportLibraryHelpers'
import {
  ISimpleTableSettings,
  ITargetEvent,
  SimpleTableTypes,
} from '../../../Common/Logic/Types'
import { ILibraryReport } from '../../Logic/Types'
import PreviewReportModal from './PreviewReportModal'
import { usePreviewReport } from '../../Logic/Hooks'

interface IReportLibraryTableLogical {
  reports: ILibraryReport[]
  reportCount: number
  tableSettings: ISimpleTableSettings
  setTableSettings: Dispatch<ISimpleTableSettings>
  roles: string[]
}

const ReportLibraryTableLogical = (props: IReportLibraryTableLogical) => {
  const { reports, reportCount, tableSettings, setTableSettings, roles } = props
  let { url } = useRouteMatch()

  const {
    isLoading,
    pdfData,
    handlePreviewClick,
    handlePreviewModalClose,
    selectedReport,
    previewOpen,
  } = usePreviewReport(reports)

  const handleRequestSort = (event: ITargetEvent, property: string) => {
    const { orderBy, order } = tableSettings
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        order: newOrder,
        orderBy: property,
      }
    })
  }

  const handleChangeRowsPerPage = (event: ITargetEvent) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const handleChangePage = (event: ITargetEvent, newPage: number) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: newPage,
      }
    })
  }

  const getCommissionViewUrl = (id: number | string) => {
    const baseUrl = url.split('/')[2]
    const commissionUrl = `${baseUrl}/${id}`
    return commissionUrl ?? ''
  }

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Paper variant="outlined">
        <SimpleTablePresentational
          headCells={reportLibraryHeadCells}
          //@ts-ignore (For future resolution)
          rows={reportsToRows(reports, roles)}
          isExpandable={false}
          tableType={SimpleTableTypes.ReportLibraryTable}
          rowCount={reportCount}
          //@ts-ignore (For future resolution)
          order={tableSettings.order}
          orderBy={tableSettings.orderBy}
          page={tableSettings.page}
          rowsPerPage={tableSettings.rowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
          //@ts-ignore (For future resolution)
          onChangePage={handleChangePage}
          //@ts-ignore (For future resolution)
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onSort={handleRequestSort}
          handlePreviewClick={handlePreviewClick}
          getCommissionViewUrl={getCommissionViewUrl}
        />
      </Paper>
      {selectedReport && pdfData && (
        <PreviewReportModal
          open={previewOpen}
          pdfData={pdfData}
          selectedReport={selectedReport}
          handleClose={handlePreviewModalClose}
        />
      )}
    </>
  )
}

export default ReportLibraryTableLogical
