import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UpcomingProjectsTableLogical } from '../UpcomingProjectsTable/UpcomingProjectsTableLogical'
import { CarbonCureProjectTableLogical } from '../CarbonCureProjectTable/CarbonCureProjectTableLogical'
import { ReadyMixTableLogical } from '../ReadyMixTable/ReadyMixTableLogical'
import { PrecastTableLogical } from '../../Components/PrecastTable/PrecastTableLogical'
import {
  Tabs,
  Tab,
  Box,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import TabPanel from '../../../Common/Components/TabPanel'
import { useRecoilValue } from 'recoil'
import {
  atomEchoSettings,
  atomFilteredOngoingProjects,
  atomFilteredFinishedProjects,
} from '../../../Common/echoAtoms'
import CO2VendorsTableLogical from '../CO2VendorsTable/CO2VendorsTableLogical'
import { EMapLayers } from '../../Enums/EchoEnums'
import { baseColors } from '../../../theme/colors'
import CementTable from '../CementTable/CementTable'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: '1200',
  },
  tab: {
    opacity: 1,
    '&.Mui-selected': {
      color: baseColors.primary.main,
    },
    '& .MuiTab-wrapper': {
      opacity: 1,
    },
  },
  tabLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Urbanist',
    textTransform: 'capitalize',
    marginBottom: '-4px',
  },
  selectedTab: {
    color: baseColors.primary.main,
  },
  indicator: {
    marginLeft: '20px',
    maxWidth: 120,
  },
  filterCountContainer: {
    color: 'white',
    padding: '3px 6.5px 3px 6.5px',
    fontSize: '12px',
    borderRadius: '64px',
    fontFamily: 'Inter',
  },
}))

RenderTablePresentational.propTypes = {
  /** State setter function to keep track of which table rows have been selected */
  setSelectedRows: PropTypes.func.isRequired,
  /** The table rows that have been selected */
  selectedRows: PropTypes.array.isRequired,
  /** State setter to toggle loading spinner */
  setIsLoading: PropTypes.func.isRequired,
  /** Table page number */
  page: PropTypes.number.isRequired,
  /** State setter to update table page */
  setPage: PropTypes.func.isRequired,
  /** Ascending or descending sort order of table column */
  order: PropTypes.string.isRequired,
  /** State setter to update ascending or descending table sort order */
  setOrder: PropTypes.func.isRequired,
  /** Name of table column to sort by */
  orderBy: PropTypes.string.isRequired,
  /** State setter to update table column to sort by */
  setOrderBy: PropTypes.func.isRequired,
  /** Number of table rows to display */
  rowsPerPage: PropTypes.number.isRequired,
  /** State setter to update number of table rows to display */
  setRowsPerPage: PropTypes.func.isRequired,
  /** upcoming project data that has been returned after being filtered */
  filteredUpcomingProjects: PropTypes.array.isRequired,
  /** Array of data to display on the ready mix table. */
  readyMixTableData: PropTypes.array.isRequired,
  /** Array of data to display on the precast table. */
  precastTableData: PropTypes.array.isRequired,
  /** The value of the currently selected tab */
  tabValue: PropTypes.number.isRequired,
  /** Function called when a different tab is clicked */
  handleChange: PropTypes.func.isRequired,
  /** List of table tabHeaders to display */
  tableTabs: PropTypes.array.isRequired,
  /** Boolean to display or not display loading spinner */
  isLoading: PropTypes.bool,
  /** Filtered and formatted array of CO2 vendors */
  formattedCO2VendorData: PropTypes.array.isRequired,
  /** Cement table's settings */
  cementTableSettings: PropTypes.object.isRequired,
  /** Setter for cement table's settings */
  setCementTableSettings: PropTypes.func.isRequired,
  /** Zip code coordinates */
  zipCodeCoordinates: PropTypes.array,
}

function RenderTablePresentational(props) {
  const echoSettings = useRecoilValue(atomEchoSettings)
  const {
    setSelectedRows,
    selectedRows,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    readyMixTableData,
    precastTableData,
    tabValue,
    handleChange,
    tableTabs,
    isLoading,
    formattedCO2VendorData,
    cementTableSettings,
    setCementTableSettings,
    zipCodeCoordinates,
  } = props

  const classes = useStyles()

  const [isCementTableLoading, setIsCementTableLoading] = useState(false)

  /** Atom with filtered ongoing CarbonCure construction project data */
  const filteredOngoingProjects = useRecoilValue(atomFilteredOngoingProjects)
  /** Atom with filtered completed CarbonCure construction project data */
  const filteredFinishedProjects = useRecoilValue(atomFilteredFinishedProjects)
  return (
    <>
      <Backdrop
        open={isLoading || isCementTableLoading}
        className={classes.backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box className={classes.tabBackground}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
          }}
        >
          {tableTabs
            .filter(table => table !== null)
            .sort((a, b) => a.order - b.order)
            .map((table, index) => {
              const isSelected = tabValue === table.order
              return (
                <Tab
                  key={table.order}
                  value={table.order}
                  className={classes.tab}
                  label={
                    <span>
                      {/* Render Label */}
                      <span
                        style={{
                          color: isSelected
                            ? baseColors.primary.main
                            : baseColors.action.active,
                          padding: '2px 2px',
                        }}
                        className={`${classes.tabLabel} ${
                          isSelected ? classes.selectedTab : ''
                        }`}
                      >
                        {table.label}{' '}
                      </span>
                      {/* Render Number Badge */}
                      {!isLoading &&
                        echoSettings?.markerLocation.length > 0 &&
                        table.label === 'Ready Mix' && (
                          <span
                            className={classes.filterCountContainer}
                            style={{
                              backgroundColor: isSelected
                                ? baseColors.primary.main
                                : baseColors.secondary.main,
                            }}
                          >
                            {readyMixTableData?.length}
                          </span>
                        )}
                    </span>
                  }
                />
              )
            })}
        </Tabs>
      </Box>
      <TabPanel tabValue={tabValue} index={0}>
        {(echoSettings.layers.includes(EMapLayers.rmPlants) ||
          echoSettings.layers.includes(EMapLayers.rmCompanies)) && (
          <ReadyMixTableLogical
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            readyMixTableData={readyMixTableData}
          />
        )}
      </TabPanel>
      <TabPanel tabValue={tabValue} index={1}>
        {(echoSettings.layers.includes(EMapLayers.precastPlants) ||
          echoSettings.layers.includes(EMapLayers.precastCompanies)) && (
          <PrecastTableLogical
            setIsLoading={setIsLoading}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            page={page}
            setPage={setPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            precastTableData={precastTableData}
          />
        )}
      </TabPanel>
      <TabPanel tabValue={tabValue} index={2}>
        {echoSettings.layers.includes(EMapLayers.cementPlants) && (
          <CementTable
            setIsLoading={setIsCementTableLoading}
            cementTableSettings={cementTableSettings}
            setCementTableSettings={setCementTableSettings}
            zipCodeCoordinates={zipCodeCoordinates}
          />
        )}
      </TabPanel>
      <TabPanel tabValue={tabValue} index={3}>
        <UpcomingProjectsTableLogical
          setIsLoading={setIsLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          filteredUpcomingProjects={filteredUpcomingProjects}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={4}>
        <CarbonCureProjectTableLogical
          setIsLoading={setIsLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          tableRows={filteredOngoingProjects}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={5}>
        <CarbonCureProjectTableLogical
          setIsLoading={setIsLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          tableRows={filteredFinishedProjects}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={6}>
        <CO2VendorsTableLogical
          setIsLoading={setIsLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          formattedCO2VendorData={formattedCO2VendorData}
        />
      </TabPanel>
    </>
  )
}

export default RenderTablePresentational
