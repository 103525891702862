import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TableViewPresentational from './TableViewPresentational'
import stateList from '../../../Common/assets/statelist.json'
import helper from '../../Components/CementTable/cementTableHelper'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import { SimpleTableOrder } from '../../../Common/Logic/Types'
import { EMapLayers } from '../../Enums/EchoEnums'

function TableViewLogical(props) {
  TableViewLogical.propTypes = {
    /** Data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Data table page number */
    page: PropTypes.number,
    /** State setter to update data table page */
    setPage: PropTypes.func,
    /** Ascending or descending sort order of data table column */
    order: PropTypes.string,
    /** State setter to update ascending or descending data table sort order */
    setOrder: PropTypes.func,
    /** Name of data table column to sort by */
    orderBy: PropTypes.string,
    /** State setter to update data table column to sort by */
    setOrderBy: PropTypes.func,
    /** Number of data table rows to display */
    rowsPerPage: PropTypes.number,
    /** State setter to update number of data table rows to display */
    setRowsPerPage: PropTypes.func,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Filtered array of CO2 vendors */
    filteredCO2Vendors: PropTypes.array,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
    /** Array of data to display on the precast table. */
    precastTableData: PropTypes.array.isRequired,
    /** The coordinates of the zip code */
    zipCodeCoordinates: PropTypes.array,
  }
  const {
    selectedRows,
    setSelectedRows,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    isLoading,
    filteredCO2Vendors,
    readyMixTableData,
    precastTableData,
    zipCodeCoordinates,
  } = props
  const echoSettings = useRecoilValue(atomEchoSettings)

  const [formattedCO2VendorData, setFormattedCO2VendorData] = useState([])

  const [cementTableSettings, setCementTableSettings] = useState(
    helper.defaultCementTableSettings
  )

  const [cementTableDataForDownload, setCementTableDataForDownload] = useState(
    []
  )

  /** Format the CO2 vendor data for the table (full state name instead of abbreviation) */
  const formatCO2VenderTableData = vendorData => {
    return vendorData.map(vendor => {
      /** If the state on file is an abbreviation, display the full name on the table. */
      let fullStateName
      if (vendor.state && vendor.state.length === 2) {
        fullStateName = stateList[vendor.state]
      } else {
        fullStateName = vendor.state
      }
      return {
        ...vendor,
        fullStateName: fullStateName,
      }
    })
  }
  useEffect(() => {
    setFormattedCO2VendorData(formatCO2VenderTableData(filteredCO2Vendors))
  }, [filteredCO2Vendors])

  useEffect(() => {
    if (!echoSettings.layers.includes(EMapLayers.cementPlants)) return
    setIsLoading(true)
    const getCements = async () => {
      const { params, arrayParams } = helper.getCementTableFilterParams(
        echoSettings,
        zipCodeCoordinates
      )
      const constraints = {
        Offset: 0,
        SortColumn: cementTableSettings.orderBy,
        SortOrder:
          cementTableSettings.order === SimpleTableOrder.asc
            ? 'Ascending'
            : 'Descending',
        ...params,
      }

      try {
        const cementTableDownloadResponse = await helper.getCements(
          constraints,
          arrayParams
        )
        const cementTableDownloadData = await cementTableDownloadResponse.json()
        const digestedCements = helper.digestCementsForDownload(
          cementTableDownloadData.results
        )
        const cementsForDownload = helper.generateCementRowsForDownload(
          digestedCements
        )
        setCementTableDataForDownload(cementsForDownload)
      } catch (err) {
        console.error(err)
        setCementTableDataForDownload([])
      } finally {
        setIsLoading(false)
      }
    }

    getCements()
  }, [
    cementTableSettings.order,
    cementTableSettings.orderBy,
    setIsLoading,
    echoSettings,
    zipCodeCoordinates,
  ])

  return (
    <TableViewPresentational
      readyMixTableData={readyMixTableData}
      precastTableData={precastTableData}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setIsLoading={setIsLoading}
      page={page}
      setPage={setPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      filteredUpcomingProjects={filteredUpcomingProjects}
      isLoading={isLoading}
      formattedCO2VendorData={formattedCO2VendorData}
      cementTableSettings={cementTableSettings}
      setCementTableSettings={setCementTableSettings}
      cementTableDataForDownload={cementTableDataForDownload}
      zipCodeCoordinates={zipCodeCoordinates}
    />
  )
}

export default TableViewLogical
