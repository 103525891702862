import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core'
import EngTableHead from './EngTableHead'
import PropTypes from 'prop-types'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}))
/**
 *
 * @param {Object} props props passed
 * @param {Function} props.handleTableSort
 * @param {String} props.order
 * @param {String} props.orderBy
 * @param {ServiceCaseTableObject []} props.serviceCases
 * @param {Number} props.page
 * @param {Number} props.rowsPerPage
 * @param {Number} props.count
 * @param {ServiceCaseTableObject | null} props.selectedServiceCase
 * @param {Function} props.handleClick
 * @param {Function} props.handleChangePage
 * @param {Function} props.handleChangeRowsPerPage
 * @returns
 */
function ServiceCasesTablePresentational(props) {
  const {
    handleTableSort,
    serviceCases,
    order,
    orderBy,
    page,
    rowsPerPage,
    count,
    selectedServiceCase,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    headCells,
  } = props
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer>
        <Table aria-label="alarms" size={'small'}>
          <EngTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleTableSort}
            headCells={headCells}
            includeCheckbox={true}
          />
          <TableBody>
            {/* Map through the service case data and display it as sortable rows */}
            {serviceCases.map((row, index) => {
              /** Keep track of selected rows */
              const isItemSelected =
                selectedServiceCase?.serviceCaseId === row.serviceCaseId
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, row)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                >
                  {/* Row checkbox */}
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      icon={<CircleUnchecked />}
                      checkedIcon={<RadioButtonChecked />}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell>{row.caseOpenedTimeString}</TableCell>
                  <TableCell>CS-{row.serviceCaseId}</TableCell>
                  <TableCell>{row.hmiId}</TableCell>
                  <TableCell>{row.customerLocation}</TableCell>
                  <TableCell>{row.statusString}</TableCell>
                  <TableCell>{row.faultyComponent}</TableCell>
                  <TableCell>{row.uniqueIssueString}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleTablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

ServiceCasesTablePresentational.propTypes = {
  /** Function to sort the data table rows */
  handleTableSort: PropTypes.func.isRequired,
  /** Order of the sorted table column: ascending or descending */
  order: PropTypes.string.isRequired,
  /** Name of the table column to sort by */
  orderBy: PropTypes.string.isRequired,
  /** Data from the service cases endpoint */
  serviceCases: PropTypes.arrayOf(PropTypes.instanceOf(ServiceCaseTableObject)),
  /** Current page number of the table */
  page: PropTypes.number.isRequired,
  /** Number of table rows to display per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** Total number of results from the service cases endpoint  */
  count: PropTypes.number.isRequired,
  /** Keep track of which service case has been selected */
  selectedServiceCase: PropTypes.instanceOf(ServiceCaseTableObject),
  /** Function to handle selecting table rows */
  handleClick: PropTypes.func.isRequired,
  /** Function to go to another page of the table */
  handleChangePage: PropTypes.func.isRequired,
  /** Function to update the number of table rows displayed */
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  /** Table Column headers */
  headCells: PropTypes.array.isRequired,
}

ServiceCasesTablePresentational.defaultProps = {
  selectedServiceCase: null,
}

export default ServiceCasesTablePresentational
