import React, { SetStateAction } from 'react'
import {
  IMaterialTypeMetadata,
  MaterialType,
  MaterialDataType,
  MaterialObjectKeys,
  MaterialKey,
  MaterialSubTypeOptions,
  MaterialObject,
  MaterialTypeOptions,
  IMetaData,
  PlantComposition,
  CementComposition,
  CementComponent,
  IMaterial,
  IMaterialForDownload,
  CurrentPlantCompositions,
  CustomerOptions,
  MaterialExceptionType,
  MixGroupConditions,
} from '../Logic/Types'
import cloneDeep from 'lodash.clonedeep'
import { Tooltip } from '@material-ui/core'

/**
 * This function adds a kelownaKeyName property to the 'fields' array to help reference
 * key names when adding values to Kelowna's POST/PUT objects.
 * @param {Object[]} arr Metadata returned from Kelowna
 * @returns {Object[]} Array of objects.
 */
export function addKeyNameToMaterialHashmap(
  arr: IMaterialTypeMetadata[]
): IMaterialTypeMetadata[] {
  return arr.map(obj => {
    const { key } = obj
    const matchingEnumValue =
      MaterialObjectKeys[key as keyof typeof MaterialObjectKeys]

    if (matchingEnumValue) {
      const kelownaKeyName = matchingEnumValue.toString()
      return { ...obj, kelownaKeyName }
    }

    return obj
  })
}

export function searchMaterialHashmap(
  hashmap: IMaterialTypeMetadata[],
  searchValue: string,
  returnProperty: string
): any {
  if (!hashmap) return
  for (const obj of hashmap) {
    if (obj['key'] === searchValue) {
      return obj[returnProperty as keyof IMaterialTypeMetadata]
    }
  }
  return null
}

/**
 * This function is primarily for the Material Manager page. This takes an array of objects
 * and creates a hashmap that helps indicate which entry fields need to be rendered based on the Material Type.
 * @param {Object[]} payload The data returned from Kelowna's Meta Data endpoint
 * @returns The new map based off of metadata
 */
export const getMaterialTypesMetadata = (payload: any) => {
  // create Key Names for use with Kelowna POST objects
  const newPayload = addKeyNameToMaterialHashmap(payload.fields)
  // t is the material type in string form
  // p would be an individual object within the array that's returned from Kelowna's Metadata endpoint.
  const insertMetadataForAMaterialType = (t: string, p: any) => {
    const typedDataType = p.dataType as keyof typeof MaterialDataType // Convert string in payload entry to enum in Orca
    let metadata: IMaterialTypeMetadata = {
      key: p.key,
      displayName: p.displayName,
      dataType: p.dataType,
      applicableValues: p.applicableValues,
      kelownaKeyName: p.kelownaKeyName,
    }

    // Frontend adjustment for Slag Processing option labels
    if (metadata.kelownaKeyName === MaterialObjectKeys.SlagProcessing) {
      metadata.applicableValues = convertArrayStringsToTitleCase(
        metadata.applicableValues
      )
    }

    // Frontend adjustment for Aggregate Size label
    if (metadata.displayName === 'Aggregate Size') {
      metadata.displayName = 'Aggregate Size (ASTM C33)'
    }
    // Frontend adjustment converting 'Id' to 'ID'
    if (metadata.displayName.includes('Id')) {
      metadata.displayName = metadata.displayName.replace(/\bId\b/g, 'ID')
    }

    if (typedDataType === MaterialDataType.ENUM.toString()) {
      metadata.applicableValues = p.applicableValues
    }

    let existingMetadataList = map.get(t)
    if (existingMetadataList) {
      map.set(t, [...existingMetadataList, metadata])
    } else {
      map.set(t, [metadata])
    }
  }

  // We are hard coding list of material types in Orca but it could alternatively be done by parsing the metadata end point to get it.
  let materialTypes = Object.keys(MaterialType)

  // Parse the Kelowna endpoint payload again to map the metadata for each key in the map
  let map = new Map<string, IMaterialTypeMetadata[]>()
  newPayload.forEach((p: any) => {
    if (p.applicablePrimaryMaterialType === null) {
      // This applies to all material types
      materialTypes.forEach(t => {
        insertMetadataForAMaterialType(t, p)
      })
    } else {
      insertMetadataForAMaterialType(
        p.applicablePrimaryMaterialType.toUpperCase(),
        p
      )
    }
  })

  // Map the Material Subtypes to the appropriate "appplicableValues" property
  map.forEach((value: any, _key: any) => {
    const materialEntry = map.get(value)
    const materialTypeKey = MaterialType[value as keyof typeof MaterialType]
    // Map Material Subtypes based on the different keys of the hashmap
    const subtypeEntry = materialEntry?.find(
      prop => prop.key === MaterialKey.MATERIALTYPE
    )
    // Map Material Types selection list to all keys within the hashmap
    const materialTypeEntry = materialEntry?.find(
      prop => prop.key === MaterialKey.PRIMARYMATERIALTYPE
    )

    if (subtypeEntry)
      subtypeEntry.applicableValues =
        payload['materialTypeMapping'][materialTypeKey]
    if (materialTypeEntry)
      materialTypeEntry.applicableValues = Object.keys(
        payload['materialTypeMapping']
      )
  })
  return map
}

/**
 * This function is primarily for the Material Manager page. This looks through the "fields"
 * property of Kelowna's Metadata to get the values of properties based on the key
 * @param {Object[]} payload The data returned from Kelowna's Meta Data endpoint
 * @returns The new map based off of metadata
 */
export const getMetadataFieldsByKey = (
  payload: IMetaData,
  keyName: string,
  keyRef: string
) => {
  const fields = payload.fields
  const metaKey: any = fields.find(field => field.key === keyName)

  if (metaKey && metaKey[keyRef]) {
    return metaKey[keyRef]
  }

  return null
}

/**
 * This function is primarily for the Material Manager page. This looks through the "materialTypeMapping"
 * property of Kelowna's Metadata to get the values of properties based on the key
 * @param {Object[]} payload The data returned from Kelowna's Meta Data endpoint
 * @param {string} keyName The name of the key to retrieve the values from
 * @returns An array of options
 */
export const getMaterialTypeMappingValues = (
  payload: IMetaData,
  keyName: string
) => {
  let newArray
  if (payload.materialTypeMapping.hasOwnProperty(keyName)) {
    newArray = payload.materialTypeMapping[keyName]
  } else {
    newArray = []
  }

  const enumValues = Object.keys(MaterialSubTypeOptions)
  const result: MaterialObject[] = []

  for (const value of newArray) {
    if (enumValues.includes(value)) {
      const option = value
      const name =
        MaterialSubTypeOptions[value as keyof typeof MaterialSubTypeOptions]
      result.push({ option, name })
    }
  }
  return result
}

/** Uses the Metadata to create an array of options suitable for use with Autocomplete components
 * @param {Object[]} myObject The data returned from Kelowna's Meta Data endpoint
 * @returns An array of options. e.g. [{option: key, name: value}]
 */
export const createMaterialTypeOptions = (myObject: IMetaData) => {
  // Get the materialTypeMapping object, since we won't need "fields" for this.
  const { materialTypeMapping } = myObject
  const newArray: MaterialObject = []
  // Check materialTypeMapping for key names that match the key name of the Enum,
  // then use the Enum's value to create an array of option/name objects for use with Autocomplete
  for (const key in MaterialTypeOptions) {
    const value = MaterialTypeOptions[key as keyof typeof MaterialTypeOptions]
    if (materialTypeMapping[key]) {
      newArray.push({ name: value, option: key })
    }
  }
  return newArray
}

/**
 * This function helps with sorting an array of objects based on the value of the key.
 * e.g sortByKey(array, 'keyName'));
 * @param {Object[]} objs The array of objects that needs to be sorted
 * @param {string} key The key of the property you are using to sort
 * @param {boolean} descOrder If you want the objects sorted in descending order. Defaults to Ascending.
 * @returns An array of objects
 */
export const sortByKey = (
  objs: MaterialObject[],
  key: string,
  descOrder: boolean = false
): MaterialObject[] => {
  const sortedArray = [...objs]
  return sortedArray.sort((a, b) => {
    const valueA = a[key]
    const valueB = b[key]

    if (descOrder) {
      if (valueA > valueB) {
        return -1
      }
      if (valueA < valueB) {
        return 1
      }
      return 0
    } else {
      if (valueA < valueB) {
        return -1
      }
      if (valueA > valueB) {
        return 1
      }
      return 0
    }
  })
}

/**
 * This function helps with turning the key names of objects into a format
 * suitable for frontend (Labels, headings, etc.)
 * @returns {string}
 */
export const convertToNameFormat = (inputString: string) => {
  if (!inputString) return null
  if (inputString.includes(' ') || /^[0-9]+$/.test(inputString))
    return inputString
  let result = ''
  let prevChar = ''

  for (let i = 0; i < inputString.length; i++) {
    const char = inputString[i]

    if (
      i !== 0 &&
      char.toUpperCase() === char &&
      prevChar.toLowerCase() === prevChar &&
      prevChar !== '-' &&
      char !== '-' &&
      prevChar !== '_'
    ) {
      result += ' '
    }

    if (char === '_') {
      result += '/'
    } else {
      if (i === 0) {
        result += char.toUpperCase()
      } else {
        result += char
      }
    }

    prevChar = char
  }

  return convertCO2ToSubscript(result)
}

/**
 * This function helps with excluding certain properties from being
 * displayed when using mapping functions.
 * @param {string} keyName The key that will be checked.
 * @param {string[]} exclusionArray The array containing the keys that will be excluded
 * @returns {boolean}
 */
export const excludeKeys = (keyName: string, exclusionArray: string[]) => {
  for (let i = 0; i < exclusionArray.length; i++) {
    if (keyName === exclusionArray[i]) {
      return false
    }
  }
  return true
}

/**
 * This function is for detecting and removing duplicate aliases.
 * Finds duplicate strings in an array and removes them.
 * @param {string[]} aliasArray The array of aliases that will be checked.
 * @returns {string[]} returns a new array of aliases that should have no duplicates.
 */
export const removeDuplicateAliases = (aliasArray: string[]) => {
  const uniqueAliases = new Set()
  const result = []

  for (const alias of aliasArray) {
    if (!uniqueAliases.has(alias)) {
      uniqueAliases.add(alias)
      result.push(alias)
    }
  }
  return result
}

/**
 * This function is for detecting duplicate aliases in the input fields for Material Manager.
 * Each string in the array is checked to see if any duplicates exist.
 * If there are, the indexes of the duplicates are returned.
 * @param {string[]} aliasArray The array of aliases that will be checked.
 * @returns {number[]} the indexes of the strings that are duplicates.
 */
export const getIndexesOfDuplicates = (aliases: string[]) => {
  const duplicateIndexes: { [key: string]: number[] } = {}
  const lowerCasedAliases = convertAliasesToLowerCase(aliases)

  // This will create an object with the alias as the key name.
  // The indexes will then be pushed to an array, which is set as the value of the key that has duplicate aliases.
  lowerCasedAliases.forEach((alias, index) => {
    const trimmedAlias = alias.trim()
    if (trimmedAlias !== '') {
      duplicateIndexes[trimmedAlias] = duplicateIndexes[trimmedAlias] || []
      duplicateIndexes[trimmedAlias].push(index)
    }
  })

  // Pull the nested arrays from each key in the object.
  return Object.values(duplicateIndexes)
    .filter(indexArray => indexArray.length > 1)
    .flat()
}

export const convertAliasesToLowerCase = (aliases: string[]) => {
  return aliases.map(alias => alias.toLowerCase())
}

/**
 * This function dynamically creates headers for the Historical Cement Composition table based
 * on the plants that have data
 * @param {PlantComposition} plantData The composition data of the plant.
 * @returns {Object} returns an array of objects formatted for our GeneralizedEnhancedTableHead component.
 */
export const generateMaterialHistoricalTableHeadCells = (
  plantData: PlantComposition | null
) => {
  const defaultHead = {
    numeric: false,
    disablePadding: false,
    sortable: false,
  }

  // Start tracking Material IDs to prevent duplicate headers
  const uniqueMaterialIds = new Set()
  // Add Date column
  const headerCells = [
    {
      ...defaultHead,
      id: 'date',
      label: 'Date',
      sortable: true,
    },
  ]

  // Adds more header cells based on the unique materials in Cement Component
  if (plantData) {
    plantData.cementCompositions.forEach((composition: CementComposition) => {
      composition.cementComponents.forEach((component: CementComponent) => {
        let modifiedCementString = ''
        const materialId = component.materialId
        // Remove "Cement" from the Cement Type strings for generated header labels
        if (component.cementType) {
          const stringInput = convertToNameFormat(
            component.cementType
          ) as string
          const stringToRemove = 'Cement'
          const stringIndex = stringInput?.indexOf(stringToRemove)
          modifiedCementString = stringInput
            .substring(stringIndex + stringToRemove.length)
            .trim()
        }
        // Generate a header label
        const headerLabel = component.cementName
          ? component.cementName
          : `${modifiedCementString} - ${component.cementPlantName}`
        if (!uniqueMaterialIds.has(materialId)) {
          uniqueMaterialIds.add(materialId)
          headerCells.push({
            ...defaultHead,
            id: `cementCompositionPlant-${materialId}`,
            label: headerLabel,
          })
        }
      })
    })
  }

  return headerCells
}

export const convertStringToPascalUpper = (string: string) => {
  const pascalString = string.replace(/\s+/g, '')
  const upperPascalString = pascalString?.toUpperCase()
  return upperPascalString
}

export const convertArrayStringsToTitleCase = (
  arr: Array<string> | undefined
) => {
  // Convert each option in the array to a standard label format
  arr?.forEach(function(str, index, array) {
    array[index] = convertToNameFormat(str) as string
  })
  return arr
}

export const getMaterialCellString = (key: string, value: string) => {
  // The keys excluded from PascalCase string conversion
  const checkedKeys = [
    'Company Name',
    'Material Type',
    'Material Subtype',
    'Supplier Company',
    'Supplier Plant',
    'Alias',
    '',
  ]
  if (!checkedKeys.includes(key)) {
    const titleCaseString = convertToNameFormat(value)
    return titleCaseString
  }
  return convertCO2ToSubscript(value)
}

export const getMaterialModalInfo = (key: string, value: string) => {
  const materialTypeKeys = ['materialType', 'MaterialType']
  const checkedKeys = ['slagProcessing', 'SlagProcessing']
  if (materialTypeKeys.includes(key)) {
    const subtypeInfo = `Subtype: ${convertToNameFormat(value)}`
    return subtypeInfo
  }
  if (checkedKeys.includes(key)) {
    const newInfo = `${convertToNameFormat(key)}: ${convertToNameFormat(value)}`
    return newInfo
  }
  return `${convertToNameFormat(key)}: ${convertCO2ToSubscript(value)}`
}

/**
 * Transforms an array of IMaterial objects into a formatted array for download.
 * Maps through each material and extracts specific properties optimized for a CSV download.
 * @param {Array<IMaterial>} materials - The array of material objects that need to be formatted.
 * @returns {object[]} - Returns an array of formatted material objects suitable for download.
 */
export const digestMaterialsForDownload = (
  materials: Array<IMaterial>
): Array<IMaterialForDownload> => {
  return materials.map(material => {
    const materialSubtype = Object.entries(MaterialSubTypeOptions).find(
      ([key]) => key === material.materialType
    )
    const materialType = Object.entries(MaterialTypeOptions).find(
      ([key]) => key === material.primaryMaterialType
    )

    return {
      divisionName: material.divisionName,
      primaryMaterialType: materialType?.[1], // use the value of the enum property
      materialType: materialSubtype?.[1], // use the value of the enum property
      specificGravity: material.specificGravity,
      supplierCompany: material.supplierCompany,
      supplierPlant: material.supplierPlant,
      aliases: material.aliases?.map(alias => alias.replace(/"/g, '""')), //add double quotation to aliases with " (inch) in it
      isIngested: material.isIngested,
    }
  })
}

/**
 * Changes the current materialId in cementCompositions to null.
 *
 * Kelowna's reasoning nullifying the current materialId:
 * "In the DB we have materials and material_mappings when we edit a material in material manager,
 * we are changing the material_mapping (which is the connection to a specific customer).
 * The cement compositions refer to materials and not mappings, so if the material is heavily edited,
 * Kelowna will create a new material and update the existing mapping, but it then needs to connect this new
 * material to the cement composition as well (and not the old material)"
 * @param {CurrentPlantCompositions[]} plantData The selected plants' composition data.
 * @param {number} materialId The current material ID
 * @returns {CurrentPlantCompositions[]} The modified plant data with the nulled material ID
 */
export const nullifyCurrentCementID = (
  plantData: CurrentPlantCompositions[],
  materialId: number
) => {
  for (const plant of plantData) {
    for (const component of plant.cementComponents) {
      if (component.materialId === materialId) {
        component.materialId = null
      }
    }
  }
  return plantData
}

/**
 * Takes the cement component data from each plant and verifies the cumulative cementPercentage values
 * add up to 100 for the individual plants.
 * @param {CurrentPlantCompositions[]} plantData The selected plants' composition data.
 * @returns {boolean}
 */
export const verifyAllCementPercentages = (
  plantData: CurrentPlantCompositions[]
) => {
  let correctTotal = true

  plantData?.forEach(plant => {
    // Tally all cementPercentage values for each plant
    const cumulativePercentage = plant.cementComponents.reduce(
      (cumulative: number, component: CementComponent) =>
        cumulative + (component.cementPercentage || 0),
      0
    )

    if (cumulativePercentage !== 100) {
      correctTotal = false
    }
  })

  return correctTotal
}

export const verifyCementPercentage = (componentData: CementComposition) => {
  const totalPercentage = (componentData.cementComponents || []).reduce(
    (accumulator, component) => accumulator + (component.cementPercentage || 0),
    0
  )

  return totalPercentage === 100
}

/**
 * Converts PlantComposition data to CurrentPlantCompositions format. This prepares POST data
 * @param {PlantComposition} plantData The selected plants' composition data.
 * @returns {CurrentPlantCompositions}
 */
export const convertPlantCompositionData = (plantData: PlantComposition[]) => {
  const convertedData: CurrentPlantCompositions[] = []

  plantData?.forEach(plant => {
    if (plant.cementCompositions.length > 0) {
      const firstComposition = plant.cementCompositions[0]
      const convertedComposition = {
        effectiveDate: firstComposition.effectiveDate,
        cementComponents: firstComposition.cementComponents.map(component => ({
          materialId: component.materialId,
          aliases: component.aliases,
          cementName: component.cementName,
          cementType: component.cementType,
          cementPlantName: component.cementPlantName,
          cementPercentage: component.cementPercentage,
        })),
        plantId: plant.plantId,
      }
      convertedData.push(convertedComposition)
    }
  })

  return convertedData
}

/**
 * Finds the matching materialId
 * @param {PlantComposition[]} plantData The selected plants' composition data.
 * @returns {PlantComposition[]}
 */
export const sortCurrentCement = (
  plantData: PlantComposition[],
  materialId: number
) => {
  const clonedPlantData: PlantComposition[] = cloneDeep(plantData)
  for (const plant of clonedPlantData) {
    // Check if there is at least one cementComposition
    if (plant.cementCompositions.length > 0) {
      const firstComposition = plant.cementCompositions[0]

      // Find the index of the matching materialId in the first composition's cementComponents
      const index = firstComposition.cementComponents.findIndex(
        component => component.materialId === materialId
      )

      // If a matching materialId is found, move it to the beginning
      if (Array.isArray(firstComposition.cementComponents) && index !== -1) {
        firstComposition.cementComponents = [
          firstComposition.cementComponents[index], // Place the matched component at the beginning
          ...firstComposition.cementComponents.slice(0, index), // Copy elements before the matched component
          ...firstComposition.cementComponents.slice(index + 1), // Copy elements after the matched component
        ]
      }
    }
  }
  return clonedPlantData
}

/**
 * Extracts all the unique plantNames from Plant Composition data and
 * places them into an array to use as selected plants.
 * @param {PlantComposition[]} plantData The selected plants' composition data.
 * @returns {string[]}
 */
export const getPlantNames = (plantData: PlantComposition[]) => {
  const uniquePlantNames: string[] = []

  // Loop through the array of objects
  for (const obj of plantData) {
    const plantName = obj.plantName

    // Check if the plantName is not already in the plantNames array
    if (!uniquePlantNames.includes(plantName)) {
      uniquePlantNames.push(plantName)
    }
  }

  return uniquePlantNames
}

/**
 * For Add/Edit Material View. Adds the necessary values of the current cement to the cementComponents of the "Assigned Plants".
 * @param {PlantComposition[]} plants The selected plants' composition data.
 * @param {number} materialId The current material ID
 * @param {string} plantName The plant name, which is also known as cementPlantName in other APIs.
 * @param {string} materialType The current material type
 * @returns {PlantComposition[]}
 */
export const initializeCementComponent = (
  plants: PlantComposition[],
  materialId: number | null,
  plantName: string,
  materialType: string,
  isEditMode: boolean = false
) => {
  const currentDate = new Date().toISOString().split('T')[0]
  const newComponent = {
    materialId: materialId,
    aliases: null,
    cementName: null,
    cementType: materialType,
    cementPlantName: plantName,
    cementPercentage: 100,
    pendingCement: !isEditMode,
  }
  const newComposition = {
    effectiveDate: currentDate,
    cementComponents: [newComponent],
  }
  const initializedPlants = plants.map(plant => {
    // Not all plant data will have cementComposition and cementComponent data
    // This checks for the existence of cementComposition data before initializing the cementComponents
    if (plant.cementCompositions.length > 0) {
      const firstComposition = plant.cementCompositions[0]
      const cementComponents = firstComposition?.cementComponents
      const foundComponent = cementComponents.find(
        component => component.materialId === materialId
      )
      if (foundComponent) {
        // Do not add any data since the current cement already exists in the cement components
        return plant
      } else {
        for (const component of cementComponents) {
          if (component.materialId === null) {
            // Prevents duplicate Current Cements.
            break
          } else {
            if (component?.length === 0) {
              // Insert new component into the empty cementComponent array
              // cementPercentage must default to 100 when there are no other cement components
              cementComponents.push(newComponent)
              break
            } else {
              // cement component exists, set cementPercentage to 0
              cementComponents.push({ ...newComponent, cementPercentage: 0 })
              break
            }
          }
        }
      }

      return plant
    } else {
      plant.cementCompositions = [newComposition]
    }
    return plant
  })
  const sortedPlants = sortCurrentCement(
    initializedPlants,
    materialId as number
  )
  return sortedPlants
}

export const convertExistingMaterialKeys = (material: MaterialObject) => {
  return Object.keys(material).reduce(
    (result: Record<string, any>, key: string) => {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1)

      if (Array.isArray(material[key])) {
        // If the property is an array, recursively convert keys inside objects in the array
        result[newKey] = material[key].map((item: any) => {
          if (typeof item === 'object' && item !== null) {
            return convertExistingMaterialKeys(item)
          }
          return item
        })
      } else if (typeof material[key] === 'object' && material[key] !== null) {
        // If the property is an object, recursively convert keys of the object
        result[newKey] = convertExistingMaterialKeys(material[key])
      } else {
        result[newKey] = material[key]
      }

      return result
    },
    {}
  )
}

export const updatePlants = (
  prevPlants: PlantComposition[],
  newPlants: PlantComposition[],
  isEditMode: boolean = false
) => {
  const updatedPlants = prevPlants
    .map(prevPlant => {
      // Check if any selected plantIds match the newly initialized plants
      const matchingPlant = newPlants.find(
        newPlant => newPlant.plantId === prevPlant.plantId
      )
      if (matchingPlant) {
        return prevPlant
      }
      return null // Return null for plants that should be removed
    })
    .filter(Boolean) as PlantComposition[] // Filter out null (falsy) values to get the existing plants

  // Add new initialized plants that don't have an existing Id
  newPlants.forEach(newPlant => {
    if (!updatedPlants.some(plant => plant.plantId === newPlant.plantId)) {
      updatedPlants.push(newPlant)
    }
  })
  if (isEditMode && hasPendingCement(updatedPlants)) {
    // This is for when we transition from the Add Material view to the Edit Material view.
    // Pending cements, which are current cements added in Add Material view, should be
    // removed from components so that the edited cement becomes the current cement in Edit Material view.
    return removePendingCements(updatedPlants)
  }

  return updatedPlants
}

export const removePendingCements = (prevPlants: PlantComposition[]) => {
  return prevPlants.map(plant => ({
    ...plant,
    cementCompositions: plant.cementCompositions.map(composition => ({
      ...composition,
      cementComponents: composition.cementComponents.filter(
        component => !component.pendingCement
      ),
    })),
  }))
}

export const hasPendingCement = (prevPlants: PlantComposition[]) =>
  prevPlants.some(plant =>
    plant.cementCompositions.some(composition =>
      composition.cementComponents.some(
        component => component.pendingCement === true
      )
    )
  )

/**
 * A function for getting the options for the supplier company and supplier plant options in the filter panel
 * @param data
 * @param supplierCompanies
 * @param supplierPlants
 * @param cementSuppliers
 * @returns
 */
export const getSupplierCompanyAndPlantOptionsForFilterPanel = (
  data: any,
  supplierCompanies: any,
  supplierPlants: any,
  cementSuppliers: any
) => {
  const supplierCompanyNames = new Set()
  const supplierPlantNames = new Set()

  const supplierCompanyOptionsWithoutCement = []
  const supplierPlantOptionsWithoutCement = []

  const cementCompanyOptions = []
  const cementPlantOptions = []

  for (const supplierCompany of supplierCompanies) {
    if (supplierCompanyNames.has(supplierCompany)) continue
    supplierCompanyNames.add(supplierCompany)
    supplierCompanyOptionsWithoutCement.push({
      id: supplierCompany,
      name: supplierCompany,
    })
  }

  for (const cementSupplier of cementSuppliers) {
    if (supplierCompanyNames.has(cementSupplier.companyName)) continue
    supplierCompanyNames.add(cementSupplier.companyName)
    cementCompanyOptions.push({
      id: cementSupplier.cementPlantId?.toString(),
      name: cementSupplier.companyName,
    })
  }

  for (const supplierPlant of supplierPlants) {
    if (supplierPlantNames.has(supplierPlant)) continue
    supplierPlantNames.add(supplierPlant)
    supplierPlantOptionsWithoutCement.push({
      id: supplierPlant,
      name: supplierPlant,
    })
  }

  for (const cementSupplier of cementSuppliers) {
    if (supplierPlantNames.has(cementSupplier.plantName)) continue
    supplierPlantNames.add(cementSupplier.plantName)
    cementPlantOptions.push({
      id: cementSupplier.cementPlantId?.toString(),
      name: cementSupplier.plantName,
    })
  }
  return {
    supplierCompanyOptionsUnsorted: [
      ...supplierCompanyOptionsWithoutCement,
      ...cementCompanyOptions,
    ],
    supplierPlantOptionsUnsorted: [
      ...supplierPlantOptionsWithoutCement,
      ...cementPlantOptions,
    ],
  }
}

/** Creates an object with Division ID and Name to pass into materialPostObject. Populates Company Name select field */
export const createDivisionData = (
  customerOptions: CustomerOptions[] | null,
  divisionId: number | null
) => {
  const foundCustomer = customerOptions.find(
    (obj: { divisionId: number }) => obj.divisionId === divisionId
  )
  if (foundCustomer) {
    const { divisionId, name } = foundCustomer
    return { divisionId, divisionName: name }
  } else {
    return null // Return null if no object with the given id is found
  }
}

/**
 * A function preparing and displaying the generic errors returned from Kelowna
 * @param {string} errorDetail
 * @param {SetStateAction<string>} setErrorMessage
 * @param {SetStateAction<string | undefined>} setModalType
 * @param {SetStateAction<boolean>} setModalOpen
 * @returns
 */
export const handleMaterialManagerGenericError = (
  errorDetail: string,
  setErrorMessage: React.Dispatch<SetStateAction<string | undefined>>,
  setModalType: React.Dispatch<SetStateAction<MaterialExceptionType | null>>,
  setModalOpen: React.Dispatch<SetStateAction<boolean>>
) => {
  setErrorMessage(errorDetail || '')
  setModalType(MaterialExceptionType.ERROR)
  setModalOpen(true)
}

/**
 * Creates a title that dynamically displays a number of aliases
 * Examples:
 * Cement Name (Alias1, Alias2)
 * Cement Name (Alias1, Alias2 +7)
 * Cement Name (No Alias)
 * @param {string[] | undefined | null} aliases an array of aliases for the material
 * @param {number} aliasAmount the amount of full alias names to display before the remaining amount is shown as a number
 * @returns {string | JSX.Element} the text that is rendered within a component
 */
export const getCementTitleWithAliases = (
  aliases: string[],
  aliasAmount: number
) => {
  if (!aliases) return 'No Alias'
  aliases = aliases.filter(
    (item: string) => item.trim() !== '' && item !== undefined
  )
  const displayedAliases = aliases?.slice(0, aliasAmount)
  const remainingAliasesCount = aliases && aliases?.length - aliasAmount
  const aliasText = displayedAliases?.join(', ')
  const aliasCountText = ` +${remainingAliasesCount}`
  if (!displayedAliases?.length) return 'No Alias'
  if (remainingAliasesCount > 0) {
    return (
      <>
        {aliasText}
        <Tooltip arrow title={aliases.slice(aliasAmount).join(', ')}>
          <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
            {aliasCountText}
          </span>
        </Tooltip>
      </>
    )
  } else {
    return <span>{aliasText}</span>
  }
}

/**
 * This is used to retrieve the value of a property based on a typescript key/value enum
 * @param {Object} typeEnum The typescript enum
 * @param {string} propertyKey The name of the key. The value of the key you're searching will be matched to the enum, returning a proper string to display
 * @returns {string} the text that will be used as a display value
 */
export const getDisplayStringFromEnum = (
  typeEnum: Object,
  propertyKey: string
) => {
  const arrayWithKeyValue = Object.entries(typeEnum).find(
    ([key, _value]) => key === propertyKey
  )
  return arrayWithKeyValue?.[1]
}

/**
 * This gets the names of a material's Assigned Plants based on materialId.
 * Used primarily for the Existing Material data in Kelowna's response when triggering a Similar Material modal
 * @param {number} materialId The ID of the material
 * @param {PlantComposition[]} plantData The name of the key. The value of the key you're searching will be matched to the enum, returning a proper string to display
 * @returns {string[]} The array of strings containing the plant names assigned to the material
 */
export const getPlantNamesByMaterialId = (
  plantData: PlantComposition[],
  materialId: number
): string[] => {
  const matchingPlantNames: string[] = plantData.flatMap(
    (plant: PlantComposition) =>
      plant.cementCompositions.flatMap((composition: CementComposition) =>
        composition.cementComponents
          .filter(
            (component: CementComponent) =>
              component.materialId === materialId &&
              component.cementPercentage > 0
          )
          .map(() => plant.plantName)
      )
  )
  // removeDuplicateAliases is a function that removes duplicate strings. Originally meant for alias arrays. Disregard the function name
  const uniqueMatchingPlantNames = removeDuplicateAliases(matchingPlantNames)
  return uniqueMatchingPlantNames
}

/**
 * This is gets an array of plantIds to indicate which plants have cement compositions based on the current material being added/edited.
 * When preparing the POST object in Add/Edit Material, Kelowna does not accept Assigned Plant names, so we can't reference the POST object
 * directly to retrieve Assigned Plant names to display in the Similar Material modal. We also can't directly reference the selected plants in the
 * Assigned Plants dropdown input because those may contain plants that have the current cement set to 0. Lastly, the reason why we are searching
 * for a null materialId is because nulled materialIds serve as the indicator for the current material being added/edited (as per Kelowna's rule).
 * @param {IMaterial} currentMaterial the current material POST object.
 * @returns {number[]} The array of plantIds that are found
 */
export const findPlantIdsByNullMaterialId = (currentMaterial: IMaterial) => {
  if (!currentMaterial.currentPlantCompositions) return []
  return currentMaterial.currentPlantCompositions.flatMap(composition =>
    composition.cementComponents
      .filter(
        component =>
          component.materialId === null && component.cementPercentage > 0
      )
      .map(_component => composition.plantId)
  )
}

/**
 * This function takes an array of plantIds and looks through plant data to find the associated plant name.
 * @param {PlantComposition[]} plantData the plant data that contains the company's plants and their respective compositions
 * @param {number[]} plantIds the array of plantIds
 * @returns {string[]} The plantNames based on the plantIds that are passed into the function
 */
export const findPlantNamesByPlantIds = (
  plantData: PlantComposition[],
  plantIds: number[]
) => {
  return plantData
    .filter(item => plantIds.includes(item.plantId as number))
    .map(item => item.plantName)
}

/**
 * This runs other utility functions to get the currently assigned plant names of the material that's currently being added/edited
 * @param {PlantComposition[]} plantData the plant data that contains the company's plants and their respective compositions
 * @param {number[]} plantIds the array of plantIds
 * @returns {string[]} The plantNames based on the plantIds that are passed into the function
 */
export const getPlantNamesForCurrentMaterial = (
  plantData: PlantComposition[],
  currentMaterial: IMaterial
) => {
  const plantIds = findPlantIdsByNullMaterialId(currentMaterial)
  const plantNames = findPlantNamesByPlantIds(plantData, plantIds as number[])
  return plantNames
}

export const showMaterialAliasInputs = (
  isEditMode: boolean,
  isUnclassifiedMaterial: boolean,
  materialType: string
) => {
  if (!isUnclassifiedMaterial && materialType) return true
  if (isEditMode) return true
  return false
}

export const isUnclassifiedMaterial = (currentMaterial: MaterialObject) => {
  if (currentMaterial) {
    return currentMaterial.materialId === null
  }
  return false
}

/**
 * This checks a string to see if the value is CO2, and converts it to CO₂.
 * @param {string} stringVal the string that's being checked for "CO2"
 * @returns {string} The resulting string. Keeps the original value if the value it's not CO2
 */
export const convertCO2ToSubscript = (stringVal: string) => {
  // Customize the rendering of each option here
  if (stringVal === 'CO2') {
    return MixGroupConditions.CO2
  }
  return stringVal
}
