import React, { useEffect, useState } from 'react'
import { ReadyMixTablePresentational } from './ReadyMixTablePresentational'
import PropTypes from 'prop-types'
import { formatCorpTableData } from '../../Logic/DataFormatters'

export const ReadyMixTableLogical = props => {
  ReadyMixTableLogical.propTypes = {
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
  }

  const {
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setRowsPerPage,
    readyMixTableData,
  } = props

  const [formattedReadyMixTableData, setFormattedReadyMixTableData] = useState(
    []
  )

  /** Function to sort the table rows */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    /** The ready mix table data, with division list and state list added */
    setFormattedReadyMixTableData(formatCorpTableData(readyMixTableData))
  }, [readyMixTableData])

  return (
    <ReadyMixTablePresentational
      {...props}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      formattedReadyMixTableData={formattedReadyMixTableData}
    />
  )
}
