import React from 'react'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import BaseLegend from '../BaseLegend'
import { projectColors } from '../../../Constants/Constants'

/** Function to return Finished Projects icon for the map legend */
function FinishedProjectsLegend({ echoSettings }) {
  FinishedProjectsLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }

  if (!echoSettings.projects.includes('Finished Projects')) return null

  const items = [
    {
      label: 'Finished Projects',
      color: projectColors.finished.hex,
      Icon: FiberManualRecordIcon,
    },
  ]

  return <BaseLegend title="Finished Projects:" items={items} />
}

export default FinishedProjectsLegend
