import React, { useEffect, useState } from 'react'
import {
  getVariabilityGraphOptionsAndErrors,
  preserveWatermarkAspectRatio,
} from '../../Logic/TSSLogic'
import VariabilityGraphPresentational from './VariabilityGraphPresentational'
import {
  BaleenMixVariation,
  BaleenOutlierStatus,
  BaleenOutlierType,
  BaleenYAxis,
  FreshPropertyNumberKeys,
  MixGroupVariation,
} from '../../Logic/Types'
import { getBaleenVariabilityGraphOptionsAndErrors } from '../../Helpers/BaleenHelpers'
import { VariabilityGraphPage } from '../../../Common/Logic/Types'
interface VariabilityGraphLogicalProps {
  /** The selected rows of mix design table */
  selectedMixVariations: Array<MixGroupVariation> | Array<BaleenMixVariation>
  /** function to add point to batch data table*/
  addToBatchDataTable?: Function
  /** Whether or not the measurement system is metric */
  isMetric: boolean
  /** Whether or not the filter panel is open */
  isFilterPanelOpen: boolean
  /** The selected property for the variability graph */
  selectedProperty: FreshPropertyNumberKeys | BaleenYAxis
  /** Function to set the selected property for the variability graph*/
  setSelectedProperty?: (arg1: FreshPropertyNumberKeys) => void
  /** String indicating which page the variability graph is on */
  page: VariabilityGraphPage
  /** The selected interval for the data */
  interval?: string | number
  /** onClick handler for data point */
  handlePointClick: (
    arg1: number,
    arg2: BaleenOutlierStatus,
    arg3: string | null,
    arg4: string,
    arg5: BaleenOutlierType
  ) => void
  /** Boolean to determine whether or not the snackbar should be open */
  showSnackbar?: boolean
  /** Function to do something when snackbar is closing */
  handleSnackbarClose?: () => void
  /** Message to be shown in snackbar */
  snackbarMessage?: { id: string; message: string }
  /** Amount of time before the snackbar disappears */
  snackbarHideDuration?: number
}

export default function VariabilityGraphLogical(
  props: VariabilityGraphLogicalProps
) {
  const {
    selectedMixVariations,
    addToBatchDataTable,
    isMetric,
    isFilterPanelOpen,
    selectedProperty,
    setSelectedProperty,
    page,
    interval,
    handlePointClick,
    showSnackbar,
    handleSnackbarClose,
    snackbarMessage,
  } = props

  const [node, setRef] = useState(null)

  let newGraphOptions
  let newDataErrors
  if (page === VariabilityGraphPage.ANALYSIS) {
    ;[newGraphOptions, newDataErrors] = getVariabilityGraphOptionsAndErrors(
      selectedMixVariations,
      selectedProperty,
      addToBatchDataTable,
      isMetric
    )
  } else {
    ;[
      newGraphOptions,
      newDataErrors,
    ] = getBaleenVariabilityGraphOptionsAndErrors(
      selectedMixVariations,
      selectedProperty,
      isMetric,
      interval,
      handlePointClick
    )
  }

  useEffect(() => {
    const watermarkedImage = document.querySelector(
      '.highcharts-container image'
    )
    preserveWatermarkAspectRatio(node, watermarkedImage)
  }, [node])

  useEffect(() => {
    let timer = setTimeout(() => {
      const graph = node?.chart
      if (graph) graph.reflow(false)
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [node, isFilterPanelOpen])

  return (
    <VariabilityGraphPresentational
      graphOptions={newGraphOptions}
      selectedProperty={selectedProperty}
      setSelectedProperty={setSelectedProperty}
      dataErrors={newDataErrors}
      setRef={setRef}
      isMetric={isMetric}
      page={page}
      showSnackbar={showSnackbar}
      handleSnackbarClose={handleSnackbarClose}
      snackbarMessage={snackbarMessage}
      snackbarHideDuration={5000}
    />
  )
}
