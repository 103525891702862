import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import {
  ICommissionReportSettings,
  IInsufficientVariationSamples,
  IMixSelection,
  TestCategories,
  VariationTypes,
} from '../../Logic/Types'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { baseColors } from '../../../theme/colors'
import { isVariationSampleCountInsufficient } from '../../Helpers/CommissionReportHelpers'

interface IViewMixSelectionTable {
  mixSelections: IMixSelection[]
  reportSettings: ICommissionReportSettings
  variationsWithInsufficientSamplesList: IInsufficientVariationSamples[]
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    padding: '16px',
    boxSizing: 'border-box',
    height: '100%',
  },
  tableCell: {
    padding: '0px 24px 8px 24px',
    borderBottom: 'none',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    width: '20%',
    height: '30px',
    wordBreak: 'break-word',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  text: {
    height: '100%',
  },
  errorIcon: {
    color: baseColors.error.main,
    marginLeft: 'auto',
  },
  variationTableCellDiv: {
    display: 'flex',
    height: '100%',
  },
}))

const ViewMixSelectionTable = (props: IViewMixSelectionTable) => {
  const {
    mixSelections,
    reportSettings,
    variationsWithInsufficientSamplesList,
  } = props
  const classes = useStyles()
  const getValue = (
    mixSelection: IMixSelection,
    property: 'variation' | 'testCategory',
    variationType: VariationTypes,
    index?: number
  ) => {
    if (property === 'variation' && variationType === VariationTypes.BASELINE) {
      const match = mixSelection.digestedVariations.find(
        variation => variation.orcaVariationType === VariationTypes.BASELINE
      )
      if (!match) return null
      return match.variationIdLabel
    } else if (
      property === 'variation' &&
      variationType === VariationTypes.OPTIMIZED
    ) {
      const match = mixSelection.digestedVariations.find(
        variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
      )
      if (!match) return null
      return match.variationIdLabel
    } else if (
      property === 'testCategory' &&
      variationType === VariationTypes.BASELINE &&
      index !== undefined &&
      reportSettings.mixDesignSettings[index].baselineVariation
    ) {
      const categoriesArray = reportSettings.mixDesignSettings[
        index
      ].baselineVariation?.selectedTestCategories
        .filter(categoryKey => !!TestCategories[categoryKey])
        .map(categoryKey => TestCategories[categoryKey])
      return categoriesArray?.join(', ')
    } else if (
      property === 'testCategory' &&
      variationType === VariationTypes.OPTIMIZED &&
      index !== undefined &&
      reportSettings.mixDesignSettings[index].carbonCureVariation
    ) {
      const categoriesArray = reportSettings.mixDesignSettings[
        index
      ].carbonCureVariation?.selectedTestCategories
        .filter(categoryKey => !!TestCategories[categoryKey])
        .map(categoryKey => TestCategories[categoryKey])
      return categoriesArray?.join(', ')
    }
    return null
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="Mix Selection Table">
        <TableRow>
          <TableCell style={{ paddingLeft: 0 }} className={classes.tableCell}>
            <Typography className={classes.text} variant="h5">
              Mix Group
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography className={classes.text} variant="h5">
              Baseline
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography className={classes.text} variant="h5">
              Test Categories
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography className={classes.text} variant="h5">
              CarbonCure
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography className={classes.text} variant="h5">
              Test Categories
            </Typography>
          </TableCell>
        </TableRow>
        {mixSelections.map((mixSelection, index) => (
          <TableRow key={mixSelection.mixDesignId}>
            <TableCell
              className={classes.tableCell}
              style={{
                paddingLeft: 0,
                paddingBottom:
                  index === mixSelections.length - 1 ? '0px' : '8px',
              }}
            >
              <Typography className={classes.text} variant="body1">
                {mixSelection.mixCode}
              </Typography>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{
                paddingBottom:
                  index === mixSelections.length - 1 ? '0px' : '8px',
              }}
            >
              <div className={classes.variationTableCellDiv}>
                <Typography className={classes.text} variant="body1">
                  {getValue(mixSelection, 'variation', VariationTypes.BASELINE)}
                </Typography>
                {isVariationSampleCountInsufficient(
                  mixSelections,
                  variationsWithInsufficientSamplesList,
                  VariationTypes.BASELINE,
                  index
                ) && (
                  <ReportProblemOutlinedIcon className={classes.errorIcon} />
                )}
              </div>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{
                paddingBottom:
                  index === mixSelections.length - 1 ? '0px' : '8px',
              }}
            >
              <Typography className={classes.text} variant="body1">
                {getValue(
                  mixSelection,
                  'testCategory',
                  VariationTypes.BASELINE,
                  index
                )}
              </Typography>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{
                paddingBottom:
                  index === mixSelections.length - 1 ? '0px' : '8px',
              }}
            >
              <div className={classes.variationTableCellDiv}>
                <Typography className={classes.text} variant="body1">
                  {getValue(
                    mixSelection,
                    'variation',
                    VariationTypes.OPTIMIZED
                  )}
                </Typography>
                {isVariationSampleCountInsufficient(
                  mixSelections,
                  variationsWithInsufficientSamplesList,
                  VariationTypes.OPTIMIZED,
                  index
                ) && (
                  <ReportProblemOutlinedIcon className={classes.errorIcon} />
                )}
              </div>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{
                borderRight: 'none',
                paddingBottom:
                  index === mixSelections.length - 1 ? '0px' : '8px',
                paddingRight: '0px',
              }}
            >
              <Typography className={classes.text} variant="body1">
                {getValue(
                  mixSelection,
                  'testCategory',
                  VariationTypes.OPTIMIZED,
                  index
                )}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default ViewMixSelectionTable
