import React from 'react'
import { makeStyles, Typography, Paper, Grid, Button } from '@material-ui/core'
import ServiceCasesTableLogical from '../Components/ServiceCasesTableLogical'
import PropTypes from 'prop-types'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'

const useStyles = makeStyles(theme => ({
  innerTabPanel: {
    padding: '3em',
    marginBottom: '2em',
  },
  buttonContainer: {
    margin: '1em 0',
  },
  input: {
    marginTop: '1em',
    width: '300px',
    '& input': {
      background: '#FFFFFF',
    },
  },
}))

function ServiceCasesPresentational(props) {
  const {
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    count,
    selectedServiceCase,
    setSelectedServiceCase,
    setPage,
    setRowsPerPage,
    handleOpen,
    serviceCases,
  } = props

  const classes = useStyles()

  return (
    <Paper variant="outlined" className={classes.innerTabPanel}>
      <Typography variant="h3">Service Cases</Typography>
      {/* Add Back Later When backend is wired up for this */}
      {/* <TextField
        label="Filter Faulty Component"
        variant="outlined"
        size="small"
        className={classes.input}
      /> */}
      <Grid
        container
        item
        justify="flex-end"
        className={classes.buttonContainer}
      >
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            disabled={selectedServiceCase === null}
            onClick={handleOpen}
          >
            View & Edit Service Case
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <ServiceCasesTableLogical
          serviceCases={serviceCases}
          handleTableSort={handleTableSort}
          selectedServiceCase={selectedServiceCase}
          order={order}
          orderBy={orderBy}
          setSelectedServiceCase={setSelectedServiceCase}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={count}
        />
      </Grid>
    </Paper>
  )
}

ServiceCasesPresentational.propTypes = {
  /** Function to sort the data table rows */
  handleTableSort: PropTypes.func.isRequired,
  /** Order of the sorted table column: ascending or descending */
  order: PropTypes.string.isRequired,
  /** Name of the table column to sort by */
  orderBy: PropTypes.string.isRequired,
  /** Current page number of the table */
  page: PropTypes.number.isRequired,
  /** Number of table rows to display per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** Keep track of which service case has been selected */
  selectedServiceCase: PropTypes.instanceOf(ServiceCaseTableObject),
  /** State setter function to keep track of which service case has been selected */
  setSelectedServiceCase: PropTypes.func.isRequired,
  /** State setter to update table page */
  setPage: PropTypes.func.isRequired,
  /** State setter to update number of table rows per page */
  setRowsPerPage: PropTypes.func.isRequired,
  /** Function to open the service case modal */
  handleOpen: PropTypes.func.isRequired,
  /** Data from the service cases endpoint */
  serviceCases: PropTypes.array.isRequired,
  /** Number of rows from the table endpoint */
  count: PropTypes.number.isRequired,
}
export default ServiceCasesPresentational
