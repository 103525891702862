import React, { ChangeEvent } from 'react'
import {
  Grid,
  Card,
  Divider,
  makeStyles,
  Typography,
  IconButton,
  TextField,
  GridSize,
} from '@material-ui/core'
import {
  CementComponent,
  PlantComposition,
  MaterialSubTypeOptions,
  CompositionChangeType,
} from '../../Logic/Types'
import { History } from '@material-ui/icons'
import { tssCanWrite } from '../../Logic/TSSLogic'
import { baseColors } from '../../../theme/colors'
import { useSetRecoilState } from 'recoil'
import { atomShowCementCompHistory } from '../../../Common/tssAtoms'
import {
  getCementTitleWithAliases,
  getDisplayStringFromEnum,
  verifyCementPercentage,
} from '../../Helpers/MaterialManagerHelpers'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

const useStyles = makeStyles(theme => ({
  inputField: {
    minWidth: 170,
    maxWidth: 350,
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
  card: {
    backgroundColor: baseColors.table.background,
    padding: '1em',
    height: '100%',
  },
  cardHeader: {
    padding: '2px',
    margin: '10px 0px',
  },
  cardRow: {
    padding: '2px',
    margin: '10px 0px',
  },
  currentCement: {
    backgroundColor: baseColors.primary.twentySixMainOpacity,
    padding: '2px',
    margin: '10px 0px',
  },
  currentCementAddEditView: {
    backgroundColor: baseColors.primary.twentySixMainOpacity,
    padding: '2px',
    margin: '10px 60px 10px 0px',
  },
}))

export interface IPlantCompositionCardPresentationalProps {
  roles?: Array<string>
  plants: Array<PlantComposition>
  currentMaterialId: number | string
  currentAliases: string[]
  currentPlantName: string
  currentMaterialSubtype: string
  getGridSize: (
    arg0: boolean | undefined,
    arg1: number,
    arg2: number
  ) => GridSize
  handleCementCompositionChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    plantId: number | string,
    materialId: number | string | null,
    changeType?: CompositionChangeType | undefined
  ) => void
  isEditMode: boolean
  isAddEditView: boolean
}

const PlantCompositionCardPresentational = (
  props: IPlantCompositionCardPresentationalProps
) => {
  const {
    roles,
    plants,
    currentMaterialId,
    currentAliases,
    currentPlantName,
    currentMaterialSubtype,
    isEditMode,
    isAddEditView,
    getGridSize,
    handleCementCompositionChange,
  } = props
  const classes = useStyles()

  const setShowCementCompHistory = useSetRecoilState(atomShowCementCompHistory)

  const plantsWithCurrentCement = plants.filter(
    plant =>
      plant.cementCompositions?.[0]?.cementComponents?.[0]?.cementPercentage > 0
  )

  return (
    <>
      {plantsWithCurrentCement?.length > 0 && !isAddEditView && (
        <>
          <Divider
            variant="middle"
            style={{ display: 'block', width: '100%', marginBottom: '16px' }}
          />
          <Grid item style={{ marginLeft: '24px' }}>
            <Typography variant="h4" align="left">
              Assigned Plant
            </Typography>
          </Grid>
          <Grid container style={{ marginLeft: '24px' }}>
            {/* only render plant list if more than one plant */}
            {plantsWithCurrentCement?.length > 1 &&
              plantsWithCurrentCement?.map((plant, index) => (
                <Typography
                  key={plant.plantId}
                  variant="body2"
                  align="left"
                  style={{ margin: '1em 0.5em 2em 0em' }}
                >
                  {plant.plantName}
                  {index !== plantsWithCurrentCement.length - 1 ? ', ' : null}
                </Typography>
              ))}
          </Grid>
        </>
      )}
      <Grid
        container
        justify="space-between"
        spacing={2}
        style={{ margin: '0px 16px' }}
      >
        {plants?.map((plant, index) => {
          const currentComposition = plant.cementCompositions[0]
          const currentCementPercentage =
            currentComposition?.cementComponents[0].cementPercentage

          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={getGridSize(isAddEditView, 12, 6)}
              lg={getGridSize(isAddEditView, 6, 4)}
              style={{
                marginBottom: '2em',
                display:
                  !isAddEditView && currentCementPercentage === 0
                    ? 'none'
                    : 'block',
              }}
              key={plant.plantId}
            >
              <Card className={classes.card}>
                <Grid container justify="space-between" alignItems="center">
                  <Typography variant="h4" align="left">
                    {plant.plantName}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setShowCementCompHistory({ show: true, plantData: plant })
                    }}
                  >
                    <History />
                  </IconButton>
                </Grid>
                {!verifyCementPercentage(currentComposition) && (
                  <Grid container style={{ margin: '0.5em 0 0.5em 0.5em' }}>
                    <ReportProblemOutlinedIcon
                      style={{
                        color: baseColors.error.main,
                        marginRight: '0.35em',
                      }}
                    />
                    <Typography
                      style={{ color: baseColors.error.main }}
                      variant="caption"
                    >
                      Cement compositions for each plant must be exactly 100%
                    </Typography>
                  </Grid>
                )}
                <Divider style={{ marginBottom: '0.5em' }} />
                <Grid
                  container
                  spacing={2}
                  style={{ padding: '0 0.5em 0 0.5em' }}
                >
                  {currentComposition?.cementComponents.map(
                    (component: CementComponent) => {
                      const isCurrentCementRow =
                        component.materialId === currentMaterialId
                      const mappedAlias =
                        isCurrentCementRow && currentAliases
                          ? currentAliases
                          : component?.aliases
                      const mappedSupplier =
                        isCurrentCementRow && currentPlantName
                          ? currentPlantName
                          : component?.cementPlantName

                      const mappedSubtype =
                        isCurrentCementRow && currentMaterialSubtype
                          ? getDisplayStringFromEnum(
                              MaterialSubTypeOptions,
                              currentMaterialSubtype
                            )
                          : getDisplayStringFromEnum(
                              MaterialSubTypeOptions,
                              component.cementType
                            )

                      const title = getCementTitleWithAliases(mappedAlias, 1)

                      let subtypeCaption
                      let supplierCaption
                      if (mappedSubtype)
                        subtypeCaption = `Subtype: ${mappedSubtype}`
                      if (mappedSupplier)
                        supplierCaption = `Supplier: ${mappedSupplier}`
                      return (
                        <React.Fragment key={component.materialId}>
                          <Grid
                            item
                            xs={12}
                            sm={getGridSize(isEditMode, 12, 6)}
                            md={getGridSize(isEditMode, 7, 7)}
                            className={
                              isCurrentCementRow
                                ? classes.currentCement
                                : classes.cardRow
                            }
                          >
                            {component.materialId === currentMaterialId ? (
                              <Typography variant="h5" align="left">
                                Current Cement ({title})
                              </Typography>
                            ) : (
                              <div>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  style={{ margin: '2px 2px 0 2px' }}
                                >
                                  {title}
                                </Typography>
                              </div>
                            )}
                            <div>
                              {subtypeCaption && (
                                <Typography variant="caption">
                                  {subtypeCaption}
                                </Typography>
                              )}
                            </div>
                            <div>
                              {supplierCaption && (
                                <Typography variant="caption">
                                  {supplierCaption}
                                </Typography>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={getGridSize(isEditMode, 12, 6)}
                            md={getGridSize(isEditMode, 5, 5)}
                            className={
                              isCurrentCementRow && !isAddEditView
                                ? classes.currentCement
                                : classes.cardRow
                            }
                          >
                            {isAddEditView ? (
                              <TextField
                                data-testid={`test-cementComposition-${index}-${component.materialId}`}
                                id={`cementComposition-${index}-${component.materialId}`}
                                value={parseInt(component.cementPercentage)}
                                label={'Cement Composition (%)'}
                                variant="outlined"
                                onChange={event =>
                                  handleCementCompositionChange(
                                    event,
                                    plant.plantId,
                                    component.materialId
                                  )
                                }
                                size="small"
                                className={classes.inputField}
                                type="number"
                                onKeyPress={event => {
                                  if (!/^\d*$/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                disabled={isEditMode && !tssCanWrite(roles)}
                              />
                            ) : (
                              <Typography
                                align="left"
                                style={{ padding: '3px 0px' }}
                              >
                                {parseInt(component.cementPercentage)}%
                              </Typography>
                            )}
                          </Grid>
                        </React.Fragment>
                      )
                    }
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={getGridSize(isEditMode, 12, 6)}
                    md={getGridSize(isEditMode, 7, 7)}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      className={classes.cardHeader}
                    >
                      Effective Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={getGridSize(isEditMode, 12, 6)}
                    md={getGridSize(isEditMode, 5, 5)}
                  >
                    {isAddEditView ? (
                      <TextField
                        data-testid={`test-effectiveDate-${index}`}
                        key={plant.plantId}
                        id="effectiveDate"
                        value={currentComposition?.effectiveDate}
                        label={'Effective Date'}
                        variant="outlined"
                        onChange={event =>
                          handleCementCompositionChange(
                            event,
                            plant.plantId,
                            null,
                            CompositionChangeType.Date
                          )
                        }
                        size="small"
                        className={classes.inputField}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        disabled={isEditMode && !tssCanWrite(roles)}
                      />
                    ) : (
                      <Typography align="left" className={classes.cardHeader}>
                        {currentComposition.effectiveDate}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default PlantCompositionCardPresentational
