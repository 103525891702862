import PropTypes from 'prop-types'
import React from 'react'
import Status from '../Logic/Status'
import InjectionLineChartPresentational from './InjectionLineChartPresentational'

/**
 *
 * @param {Object} props
 * @param {Boolean} props.metric
 * @param {Boolean} props.downstreamTwo
 * @param {Status []} props.statusArray
 */
function DownstreamPressureChartLogical(props) {
  const title = props.downstreamTwo
    ? 'Downstream Pressure 2'
    : 'Downstream Pressure 1'
  const unit = props.metric ? 'kpa' : 'psi'
  let data = []

  if (props.downstreamTwo) {
    data = props.statusArray.map(status => [
      status.timestamp.getTime(),
      props.metric
        ? status.downstreamPressure0KPa
        : status.downstreamPressure0PSI,
    ])
  } else {
    data = props.statusArray.map(status => [
      status.timestamp.getTime(),
      props.metric
        ? status.downstreamPressure1KPa
        : status.downstreamPressure1PSI,
    ])
  }

  return (
    <InjectionLineChartPresentational data={data} unit={unit} title={title} />
  )
}

DownstreamPressureChartLogical.propTypes = {
  statusArray: PropTypes.arrayOf(PropTypes.instanceOf(Status)).isRequired,
  metric: PropTypes.bool,
  downstreamTwo: PropTypes.bool,
}

DownstreamPressureChartLogical.defaultProps = {
  metric: false,
  downstreamTwo: false,
}

export default DownstreamPressureChartLogical
