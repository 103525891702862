import React from 'react'
import { Grid, TextField } from '@material-ui/core'

interface IComponentProps {
  handleChangeStartDate: (event: { target: { value: string } }) => void
  handleChangeEndDate: (event: { target: { value: string } }) => void
}

function DateRangePresentational(props: IComponentProps) {
  const { handleChangeStartDate, handleChangeEndDate } = props
  return (
    <Grid container item justify="space-between" spacing={2}>
      <Grid item lg={12}>
        <TextField
          label="From"
          size="small"
          type="date"
          onChange={handleChangeStartDate}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item lg={12}>
        <TextField
          label="To"
          size="small"
          type="date"
          onChange={handleChangeEndDate}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangePresentational
