import React from 'react'
import { ICommissionReportSettings, IMixSelection } from '../Logic/Types'
import { Grid, Typography } from '@material-ui/core'
import { getViewOutliersText } from '../Helpers/CommissionReportHelpers'

export interface IViewCommissioningReportOutliersCardProps {
  reportSettings: ICommissionReportSettings
  mixSelection: IMixSelection
  showOutliers: boolean
}

function ViewCommissionReportOutliersCard(
  props: Readonly<IViewCommissioningReportOutliersCardProps>
) {
  const { reportSettings, mixSelection, showOutliers } = props

  return (
    <>
      {showOutliers && (
        <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4">Excluded Samples (Outliers)</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {getViewOutliersText(mixSelection, reportSettings)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default ViewCommissionReportOutliersCard
