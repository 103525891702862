import React from 'react'
import { Card, Grid, Typography, CardContent } from '@material-ui/core'
import PropTypes from 'prop-types'

AddDataHighLevelSummary.propTypes = {
  /** Object containing the three metrics to display. */
  mixDesignMetrics: PropTypes.object.isRequired,
}

/**
 * Component used to display high level metrics on AddDataView.
 * @param {Object} props - props for the component
 * @param {Object} props.mixDesignMetrics - contains the three metrics to display.
 * @param {Number} props.mixDesignMetrics.count - count of all mix designs.
 * @param {Number} props.mixDesignMetrics.numControlSamples - count of control batches.
 * @param {Number} props.mixDesignMetrics.numCO2Samples - count of CO2 batches
 */
function AddDataHighLevelSummary(props) {
  const { mixDesignMetrics } = props

  const metrics = [
    { title: 'MIX DESIGNS', value: mixDesignMetrics.count || '-' },
    {
      title: (
        <>
          NO CO<sub>2</sub> SAMPLES
        </>
      ),
      value: mixDesignMetrics.numNonCO2Samples || '-',
    },
    {
      title: (
        <>
          CO<sub>2</sub> SAMPLES
        </>
      ),
      value: mixDesignMetrics.numCO2Samples || '-',
    },
  ]

  return (
    <Card elevation={1}>
      <CardContent>
        <Grid container direction="row" alignItems="center">
          {metrics.map(metric => (
            <Grid
              item
              xs
              container
              direction="column"
              alignItems="center"
              key={metric.title}
            >
              <Typography variant="h4">{metric.title}</Typography>
              <Typography color="primary" variant="h2">
                {metric.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
export default AddDataHighLevelSummary
