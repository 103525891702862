import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from '@material-ui/core'
import EchoTableHead from '../EchoTableHead/EchoTableHead'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import PropTypes from 'prop-types'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: '10px',
  },
  wideCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    overflow: 'hidden',
  },
}))

export const CarbonCureProjectTablePresentational = props => {
  CarbonCureProjectTablePresentational.propTypes = {
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** Function to sort the table */
    handleRequestSort: PropTypes.func.isRequired,
    /** Function to go to a different page of the table */
    handleChangePage: PropTypes.func.isRequired,
    /** Function to change the number of rows to display for the table */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Array of data to display as table rows */
    tableRows: PropTypes.array.isRequired,
  }

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    tableRows,
  } = props
  const classes = useStyles()

  /** Table column headers */
  const headCells = [
    {
      id: 'projectName',
      numeric: false,
      label: 'Project',
    },
    {
      id: 'state',
      numeric: false,
      label: 'State',
    },
    {
      id: 'customer',
      numeric: false,
      label: 'Cust.',
    },
    {
      id: 'concreteDelivered',
      numeric: false,
      label: 'Conc. Del. (yd3)',
    },
    {
      id: 'co2Savings',
      numeric: false,
      label: 'Est. CO2 Sav. (tons)',
    },
    {
      id: 'startDate',
      numeric: false,
      label: 'Start',
    },
    {
      id: 'projectStatus',
      numeric: false,
      label: 'Status',
    },
  ]

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="CarbonCure projects"
            size={'small'}
          >
            <EchoTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {/* Map through the filtered data and display it as sortable rows */}
              {stableSort(tableRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.projectName}
                      className={classes.tableRow}
                    >
                      {/* Project name */}
                      <TableCell className={classes.wideCell}>
                        {row.projectName}
                      </TableCell>
                      {/* State */}
                      <TableCell>{row.state}</TableCell>
                      {/* Customer */}
                      <TableCell className={classes.wideCell}>
                        {row.customer}
                      </TableCell>
                      {/* Concrete delivered */}
                      <TableCell>
                        {row.concreteDelivered.toLocaleString()}
                      </TableCell>
                      {/* Est. CO2 Savings */}
                      <TableCell>{row.co2Savings}</TableCell>
                      {/* Project start date */}
                      <TableCell>{row.startDate}</TableCell>
                      {/* Project status */}
                      <TableCell>{row.projectStatus}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
