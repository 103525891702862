import { DotNetSix } from '../../../Common/assets/FeatureFlags'
import { getHTMLDateString } from './AlarmsManagementLogicHelpers'

class ServiceCaseObject {
  serviceCaseId
  remoteUnitId
  alarm
  alarmId
  systemDownTimestamp
  systemUpTimestamp
  system
  wasSolvedRemotely
  isUniqueIssue
  caseOpenedTimestamp
  caseClosedTimestamp
  replacementPartsNeededDate
  partsShippedDate
  partsReceivedDate
  notes
  carbonCureTechnicianRemote

  initialIndication
  partResponsibleForIssue
  isFollowUpRequired
  followUpSummary
  errorMessage
  plantPointOfContact
  status
  marketDevRepEmail
  descriptionOfProblemByCustomer
  actionsTakenRemotely
  isOnsiteServiceRequired
  onsiteTechnician
  criticalRepairPlan
  anticipatedRepairDate
  wasRepairPlanApproved
  hasArrivedOnsite
  wasSolvedOnsite
  actionsTakenOnsite
  replacedComponents

  /**
   *
   * @param {Object} kelownaObject
   * @param {number} kelownaObject.serviceCaseId
   * @param {number} kelownaObject.remoteUnitId
   * @param {Object} kelownaObject.alarm
   * @param {number} kelownaObject.alarmId
   * @param {String} kelownaObject.systemDownTimestamp
   * @param {String} kelownaObject.systemUpTimestamp
   * @param {String} kelownaObject.system
   * @param {boolean} kelownaObject.wasSolvedRemotely
   * @param {boolean} kelownaObject.isUniqueIssue
   * @param {String} kelownaObject.caseOpenedTimestamp
   * @param {String} kelownaObject.caseClosedTimestamp
   * @param {String} kelownaObject.replacementPartsNeededDate
   * @param {String} kelownaObject.partsShippedDate
   * @param {String} kelownaObject.partsReceivedDate
   * @param {Object[]} kelownaObject.notes
   * @param {String} kelownaObject.carbonCureTechnicianRemote
   * @param {String} kelownaObject.initialIndication
   * @param {String} kelownaObject.partResponsibleForIssue
   * @param {boolean} kelownaObject.isFollowUpRequired
   * @param {String} kelownaObject.followUpSummary
   * @param {String} kelownaObject.errorMessage
   * @param {String} kelownaObject.plantPointOfContact
   * @param {String} kelownaObject.status
   * @param {String} kelownaObject.marketDevRepEmail
   * @param {String} kelownaObject.descriptionOfProblemByCustomer
   * @param {String} kelownaObject.actionsTakenRemotely
   * @param {boolean} kelownaObject.isOnsiteServiceRequired
   * @param {String} kelownaObject.onsiteTechnician
   * @param {String} kelownaObject.criticalRepairPlan
   * @param {String} kelownaObject.anticipatedRepairDate
   * @param {boolean} kelownaObject.wasRepairPlanApproved
   * @param {boolean} kelownaObject.hasArrivedOnsite
   * @param {boolean} kelownaObject.wasSolvedOnsite
   * @param {String} kelownaObject.actionsTakenOnsite
   * @param {String} kelownaObject.replacedComponents
   */
  constructor(kelownaObject) {
    this.serviceCaseId = kelownaObject.serviceCaseId
    this.remoteUnitId = kelownaObject.remoteUnitId
    this.alarm = kelownaObject.alarm
    this.alarmId = kelownaObject.alarmId
    this.systemDownTimestamp = kelownaObject.systemDownTimestamp
    this.systemUpTimestamp = kelownaObject.systemUpTimestamp
    this.system = kelownaObject.system
    this.wasSolvedRemotely = kelownaObject.wasSolvedRemotely
    this.isUniqueIssue = kelownaObject.isUniqueIssue
    this.caseOpenedTimestamp = kelownaObject.caseOpenedTimestamp
    this.caseClosedTimestamp = kelownaObject.caseClosedTimestamp
    this.replacementPartsNeededDate = kelownaObject.replacementPartsNeededDate
    this.partsShippedDate = kelownaObject.partsShippedDate
    this.partsReceivedDate = kelownaObject.partsReceivedDate
    if (DotNetSix) {
      this.notes = kelownaObject.serviceCaseNotes
    } else {
      this.notes = kelownaObject.notes
    }
    this.carbonCureTechnicianRemote = kelownaObject.carbonCureTechnicianRemote
    this.initialIndication = kelownaObject.initialIndication
    this.partResponsibleForIssue = kelownaObject.partResponsibleForIssue
    this.isFollowUpRequired = kelownaObject.isFollowUpRequired
    this.followUpSummary = kelownaObject.followUpSummary
    this.errorMessage = kelownaObject.errorMessage
    this.plantPointOfContact = kelownaObject.plantPointOfContact
    this.status = kelownaObject.status
    this.marketDevRepEmail = kelownaObject.marketDevRepEmail
    this.descriptionOfProblemByCustomer =
      kelownaObject.descriptionOfProblemByCustomer
    this.actionsTakenRemotely = kelownaObject.actionsTakenRemotely
    this.isOnsiteServiceRequired = kelownaObject.isOnsiteServiceRequired
    this.onsiteTechnician = kelownaObject.onsiteTechnician
    this.criticalRepairPlan = kelownaObject.criticalRepairPlan
    this.anticipatedRepairDate = kelownaObject.anticipatedRepairDate
    this.wasRepairPlanApproved = kelownaObject.wasRepairPlanApproved
    this.hasArrivedOnsite = kelownaObject.hasArrivedOnsite
    this.wasSolvedOnsite = kelownaObject.wasSolvedOnsite
    this.actionsTakenOnsite = kelownaObject.actionsTakenOnsite
    this.replacedComponents = kelownaObject.replacedComponents
  }

  get serviceCaseInput() {
    return {
      systemDown: new Date(this.systemDownTimestamp),
      systemUp: new Date(this.systemUpTimestamp),
      solvedRemotely: this.wasSolvedRemotely,
      uniqueIssue: this.isUniqueIssue,
      note: null,
      system: this.system,
      caseOpened: getHTMLDateString(this.caseOpenedTimestamp),
      caseClosed: getHTMLDateString(this.caseClosedTimestamp),
      replacementPartsNeededDate: getHTMLDateString(
        this.replacementPartsNeededDate
      ),
      partsShippedDate: getHTMLDateString(this.partsShippedDate),
      partsReceivedDate: getHTMLDateString(this.partsReceivedDate),
      carbonCureTechnicianRemote: this.carbonCureTechnicianRemote,
      initialIndication: this.initialIndication,
      partResponsibleForIssue: this.partResponsibleForIssue,
      followUpRequired: this.isFollowUpRequired,
      followUpSummary: this.followUpSummary,
      errorMessage: this.errorMessage,
      plantPointOfContact: this.plantPointOfContact,
      status: this.status ?? 'Open',
      marketDevRepEmail: this.marketDevRepEmail,
      descriptionOfProblemByCustomer: this.descriptionOfProblemByCustomer,
      actionsTakenRemotely: this.actionsTakenRemotely,
      onsiteServiceRequired: this.isOnsiteServiceRequired,
      onsiteTechnician: this.onsiteTechnician,
      criticalRepairPlan: this.criticalRepairPlan,
      anticipatedRepairDate: getHTMLDateString(this.anticipatedRepairDate),
      repairPlanApproved: this.wasRepairPlanApproved,
      arrivedOnsite: this.hasArrivedOnsite,
      solvedOnsite: this.wasSolvedOnsite,
      actionsTakenOnsite: this.actionsTakenOnsite,
      replacedComponents: this.replacedComponents,
    }
  }
}

export default ServiceCaseObject
