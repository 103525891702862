import React from 'react'
import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  CheckBoxOutlineBlank,
  CheckBox,
  InfoOutlined,
} from '@material-ui/icons'
import { getMaterialOptions } from '../Logic/AddBatchColumnSelectLogic'
import { preventEventOnKeyDown } from '../../Common/Helpers/GeneralHelpers'
import {
  CustomerOptions,
  IDigestedMaterial,
  MaterialObject,
  MaterialSelectType,
  MaterialSubTypeOptions,
} from '../Logic/Types'
import { baseColors } from '../../theme/colors'

export interface IMaterialSelectProps {
  type: MaterialSelectType
  materials: Array<IDigestedMaterial>
  isViewOnly: boolean
  currentCustomer: CustomerOptions
  selected: Array<IDigestedMaterial>
  handleChange: (value: IDigestedMaterial | string) => void
  hasCheckBox: boolean
}

function MaterialSelect(props: IMaterialSelectProps) {
  const {
    type,
    materials,
    isViewOnly,
    currentCustomer,
    selected,
    handleChange,
    hasCheckBox = true,
  } = props

  const useStyles = makeStyles(theme => ({
    info: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: baseColors.info.dark,
      whiteSpace: 'nowrap',
    },
  }))

  const classes = useStyles()

  const materialOptions =
    type === MaterialSelectType.MergeMaterial
      ? materials
      : getMaterialOptions(materials)

  let labelText = ''
  let placeholderText = ''

  switch (type) {
    case MaterialSelectType.AddMix:
      labelText = 'Materials'
      placeholderText = 'Select Materials'
      break
    case MaterialSelectType.MergeMaterial:
      labelText = 'Merge Material'
      placeholderText = 'Select an existing material'
      break
    default:
      labelText = 'Material Columns'
      placeholderText = 'Select the Batch Materials'
  }

  /** Render checkbox next to each selected option. */
  const renderCheckBox = (option: MaterialObject, { selected }) => {
    const maxDisplayedAliases = 4
    const displayedAliases = option.aliases.slice(0, maxDisplayedAliases)
    const remainingAliasesCount = option.aliases.length - maxDisplayedAliases

    const title = displayedAliases.length ? (
      <>
        {displayedAliases.join(', ')}
        {remainingAliasesCount > 0 && (
          <Tooltip
            arrow
            title={option.aliases.slice(maxDisplayedAliases).join(', ')}
          >
            <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
              {` +${remainingAliasesCount}`}
            </span>
          </Tooltip>
        )}
      </>
    ) : (
      'No Alias'
    )

    const materialSubtype = Object.entries(MaterialSubTypeOptions).find(
      ([key, value]) => key === option.materialType
    )
    let caption
    if (materialSubtype)
      caption = `Subtype: ${materialSubtype[1]} / Supplier Company: ${option.supplierCompany}`

    return (
      <>
        {hasCheckBox && (
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" color="primary" />}
            style={{ marginRight: 12 }}
            checked={selected}
          />
        )}
        <>
          <div>
            <Typography variant="body2">{title}</Typography>
            <div>
              <Typography variant="caption">
                {option.isDefaultCement || option.isDefaultCO2 ? null : caption}
              </Typography>
            </div>
          </div>
        </>
      </>
    )
  }

  const getHandler = () => {
    if (
      type === MaterialSelectType.AddMix ||
      type === MaterialSelectType.MergeMaterial
    ) {
      return (event, value) => handleChange(value)
    } else {
      return handleChange('materials')
    }
  }

  return (
    <Grid item>
      <Autocomplete
        options={materialOptions}
        value={selected}
        groupBy={
          type !== MaterialSelectType.MergeMaterial
            ? option => option.primaryMaterialType
            : () => ''
        }
        disableClearable
        disableCloseOnSelect={type !== MaterialSelectType.MergeMaterial}
        getOptionSelected={(option, value) =>
          option.materialId === value.materialId && option.title === value.title
        }
        getOptionLabel={option => option.title}
        renderOption={renderCheckBox}
        multiple={type !== MaterialSelectType.MergeMaterial}
        disabled={
          type === MaterialSelectType.MergeMaterial
            ? isViewOnly
            : isViewOnly || !currentCustomer?.division
        }
        renderInput={params => (
          <TextField
            {...params}
            label={labelText}
            variant="outlined"
            size="small"
            name="materials"
            placeholder={placeholderText}
            onKeyDown={preventEventOnKeyDown(['Backspace'])}
            helperText={
              <Grid item lg={12}>
                {type !== MaterialSelectType.MergeMaterial &&
                  !currentCustomer.division && (
                    <Grid item className={classes.info}>
                      <InfoOutlined fontSize="small" data-testid="info-icon" />
                      <Typography
                        style={{ color: baseColors.info.dark }}
                        variant="caption"
                      >
                        {
                          'Please select a customer to enable material selection'
                        }
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            }
          />
        )}
        onChange={getHandler()}
      />
    </Grid>
  )
}

export default MaterialSelect
