import React from 'react'
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core'
import AlarmsTableLogical from '../Components/AlarmsTableLogical'
import PropTypes from 'prop-types'
import DownloadDataLogical from '../Components/DownloadDataLogical'

const useStyles = makeStyles(theme => ({
  innerTabPanel: {
    padding: '3em',
    marginBottom: '2rem',
  },
  buttonContainer: {
    margin: '1em 0',
  },
  chartContainer: {
    marginTop: '1em',
  },
}))

function AlarmsPresentational(props) {
  AlarmsPresentational.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Keep track of which alarm has been selected */
    selectedAlarm: PropTypes.object.isRequired,
    /** State setter function to keep track of which alarm has been selected */
    setSelectedAlarm: PropTypes.func.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Show inactive alarms filter */
    showInactiveAlarms: PropTypes.bool.isRequired,
    /** Function to open the service case modal */
    handleOpen: PropTypes.func.isRequired,
    /** Data from the alarms endpoint */
    alarms: PropTypes.array.isRequired,
    /** Number of rows available in the database */
    tableCount: PropTypes.number.isRequired,
    /** Timestamp for when the Alarms table was last refreshed */
    refreshedAt: PropTypes.string.isRequired,
    /** Boolean to determine whether the Alarms table is refreshing */
    refreshing: PropTypes.bool.isRequired,
    /** List of remote units with plants */
    remoteUnitsWithPlants: PropTypes.array.isRequired,
  }

  const {
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    selectedAlarm,
    setSelectedAlarm,
    setPage,
    setRowsPerPage,
    showInactiveAlarms,
    handleOpen,
    alarms,
    tableCount,
    refreshedAt,
    refreshing,
    remoteUnitsWithPlants,
  } = props

  const classes = useStyles()

  return (
    <>
      <Paper variant="outlined" className={classes.innerTabPanel}>
        <Typography variant="h3">Alarms</Typography>
        <Grid
          container
          item
          justify="space-between"
          className={classes.buttonContainer}
        >
          <Grid
            container
            item
            lg={4}
            spacing={1}
            alignContent="center"
            justify="flex-start"
          >
            <Grid item>
              {refreshing ? (
                <CircularProgress size={15} thickness={6} />
              ) : (
                <CircularProgress size={15} thickness={0} />
              )}
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: 'bold' }}>
                Last refreshed:
              </Typography>
            </Grid>
            <Grid item>
              <Typography id="refreshedAt">{refreshedAt}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Button
              variant="outlined"
              color="primary"
              disabled={Object.keys(selectedAlarm).length === 0}
              onClick={handleOpen}
            >
              Clear Alarm & Create Service Case
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <AlarmsTableLogical
            alarms={alarms}
            handleTableSort={handleTableSort}
            selectedAlarm={selectedAlarm}
            order={order}
            orderBy={orderBy}
            setSelectedAlarm={setSelectedAlarm}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={tableCount}
            remoteUnitsWithPlants={remoteUnitsWithPlants}
          />
        </Grid>
        <Grid item container justify="flex-end">
          <DownloadDataLogical
            dataType={'alarms'}
            order={order}
            orderBy={orderBy}
            showInactiveAlarms={showInactiveAlarms}
          />
        </Grid>
      </Paper>
      <Grid
        container
        item
        justify="space-between"
        className={classes.chartContainer}
        spacing={6}
      >
        {/* {
        false &&
        (
          <Grid item lg={6}>
          <UptimeChart />
        </Grid>
        <Grid item lg={6}>
          <ResponseChart />
        </Grid>
      )
    } */}
      </Grid>
    </>
  )
}

export default AlarmsPresentational
