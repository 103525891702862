import React, { Dispatch, SetStateAction } from 'react'
import SimpleTablePresentational from '../../../Common/Components/SimpleTable/SimpleTablePresentational'
import hook from './useCementTable'
import cementTableHelper from './cementTableHelper'
import {
  ISimpleTableSettings,
  SimpleTableTypes,
} from '../../../Common/Logic/Types'
import { Paper } from '@material-ui/core'

export interface ICementTable {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  cementTableSettings: ISimpleTableSettings
  setCementTableSettings: Dispatch<SetStateAction<ISimpleTableSettings>>
  zipCodeCoordinates: number[]
}
const CementTable = (props: ICementTable) => {
  const {
    setIsLoading,
    cementTableSettings,
    setCementTableSettings,
    zipCodeCoordinates,
  } = props
  const {
    cementRows,
    cementCount,
    expandedRowIds,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleExpanderClick,
  } = hook.useCementTable(
    setIsLoading,
    cementTableSettings,
    setCementTableSettings,
    zipCodeCoordinates
  )

  return (
    <Paper variant="outlined" style={{ marginBottom: '40px' }}>
      <SimpleTablePresentational
        isExpandable
        headCells={cementTableHelper.cementTableHeadCells}
        rows={cementRows}
        rowCount={cementCount}
        tableType={SimpleTableTypes.EchoCementTable}
        page={cementTableSettings.page}
        rowsPerPage={cementTableSettings.rowsPerPage}
        order={cementTableSettings.order}
        orderBy={cementTableSettings.orderBy}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleRequestSort}
        onCollapseToggle={handleExpanderClick}
        expanded={expandedRowIds}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </Paper>
  )
}

export default CementTable
