import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'

function CustomerTableHead(props) {
  CustomerTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func,
  }

  const { order, orderBy, onRequestSort } = props

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  // Column headers
  const headCells = [
    {
      id: 'divisionName',
      numeric: false,
      disablePadding: false,
      label: 'Producer',
      width: '25%',
    },
    {
      id: 'inCommissioning',
      numeric: true,
      disablePadding: false,
      label: 'Commissioning Complete',
      width: '15%',
    },
    {
      id: 'numMixDesigns',
      numeric: true,
      disablePadding: false,
      label: 'Mix Des.',
      width: '10%',
    },
    {
      id: 'numControlDesigns',
      numeric: true,
      disablePadding: false,
      label: 'Ctrl Des.',
      width: '10%',
    },
    {
      id: 'numCO2Designs',
      numeric: true,
      disablePadding: false,
      label: 'CO₂ Des.',
      width: '10%',
    },
    {
      id: 'tssRep',
      numeric: false,
      disablePadding: false,
      label: 'TSS Specialist',
      width: '15%',
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: false,
      label: 'Priority',
      width: '10%',
    },
  ]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'inCommissioning' ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomerTableHead
