import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { getLocalDateTimeFromISODateString } from '../Logic/PCBLogic'

InjectionReadingsTableModal.propTypes = {
  /** The readings to be displayed */
  readings: PropTypes.array.isRequired,
  /** The function to fire when closing the modal */
  handleClose: PropTypes.func.isRequired,
  /** Is the modal open or closed */
  isOpen: PropTypes.bool.isRequired,
}

function InjectionReadingsTableModal(props) {
  const { readings, handleClose, isOpen } = props
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xl">
      <DialogTitle>
        <Typography variant="h4">Injection details</Typography>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Timestamp </Typography>
                </TableCell>
                <TableCell>
                  <Typography>Inlet Liquid Pressure (KPa)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Inlet Gas Pressure (KPa)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Upstream Pressure (KPa)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Downstream Pressure 1 (KPa)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Downstream Pressure 2 (KPa)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Upstream Temperature (C)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Flow Rate (Kg/s)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Injected CO2 (Kg)</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readings.map(reading => {
                return (
                  <TableRow key={reading.injectionReadingId}>
                    <TableCell>
                      <Typography>
                        {getLocalDateTimeFromISODateString(reading.timestamp)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* Liquid Pressure */}
                        {reading.inletLiquidPressureKPa}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* Gas Pressure */}
                        {reading.inletGasPressureKPa}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* Upstream Pressure */}
                        {reading.upstreamPressureKPa}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* Downstream Pressure 1 */}
                        {reading.downstreamPressure0KPa}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* Downstream Pressure 2 */}
                        {reading.downstreamPressure1KPa}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{reading.upstreamTemperatureC}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{reading.flowRateKgPerSec}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{reading.injectedCO2Kg}</Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InjectionReadingsTableModal
