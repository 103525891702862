import React, { Dispatch, SetStateAction } from 'react'
import ResolveAlarmDialogPresentational from './ResolveAlarmDialogPresentational'
import AlarmForTable from '../../Logic/AlarmForTable'

interface IComponentProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  row: AlarmForTable
  handleClearAlarm: (alarmRow: AlarmForTable) => null
}

function ResolveAlarmDialogLogical(props: IComponentProps) {
  const { open, setOpen, row, handleClearAlarm } = props

  /** Function to close the modal */
  const handleClose = () => {
    setOpen(false)
  }

  /** Mark the selected alarm as resolved */
  const resolveAlarm = () => {
    handleClearAlarm(row)
    handleClose()
  }

  return (
    <ResolveAlarmDialogPresentational
      open={open}
      handleClose={handleClose}
      resolveAlarm={resolveAlarm}
    />
  )
}

export default ResolveAlarmDialogLogical
