import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import hc from 'highcharts/modules/histogram-bellcurve.js'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { baseColors } from '../../../theme/colors'

hc(Highcharts)

HistogramPresentational.propTypes = {
  /** The selected variations of mix group table */
  mixVariationSelectedToggle: PropTypes.bool,
  /** Object with rendering options for grap */
  graphData: PropTypes.array.isRequired,
  /** Warning messages to be displayed when there aren't enough 28 day samples */
  noSampleDataMsg: PropTypes.array,
  /** Watermark for graph, null or one of the watermarks */
  graphWaterMark: PropTypes.string,
  /** A function to set the ref */
  setRef: PropTypes.func,
  /** Boolean to set metric or imperial units */
  isMetric: PropTypes.bool,
  /** Title of the histogram */
  title: PropTypes.string,
  /** Boolean to tell if the histogram is being rendered in Commission Report*/
  isCommissionReport: PropTypes.bool,
}

/** Component for rendering Histogram with necessary options */
export default function HistogramPresentational(props) {
  const {
    mixVariationSelectedToggle,
    graphData,
    noSampleDataMsg,
    graphWaterMark,
    setRef,
    isMetric,
    title,
    isCommissionReport,
  } = props
  const units = isMetric ? 'MPa' : 'psi'

  const useStyles = makeStyles(theme => ({
    warning: {
      paddingLeft: '2%',
      color: baseColors.error.main,
      minHeight: '340px',
    },
  }))
  const classes = useStyles()

  const options = {
    chart: {
      height: 390,
      plotBackgroundImage: graphWaterMark,
      events: {
        // Create custom label for the xAxis title
        render: function() {
          const chart = this
          if (chart.customLabel) {
            chart.customLabel.destroy()
          }

          const chartWidth = chart.chartWidth
          const xAxis = chart.xAxis[0]
          const xOffset = (chartWidth - 140) / 2
          const yOffset = xAxis.top + xAxis.height + 30 // adjust value for chart layout

          chart.customLabel = chart.renderer
            .label(
              `${isCommissionReport ? 'Batch Strength' : 'Sample Strength'}
                 (${units})`,
              xOffset,
              yOffset
            )
            .css({
              width: '140px',
              color: '#666666',
              align: 'center',
            })
            .add()
        },
      },
    },

    title: {
      text: title || '',
      align: 'center',
      x: -10,
    },

    plotOptions: {
      series: {
        stickyTracking: false,
        dataLabels: {
          enabled: true,
          inside: true,
          formatter() {
            return this.point.percent ?? ''
          },
        },
      },
      spline: {
        tooltip: {
          headerFormat: '',
          pointFormat: isMetric
            ? `<b>{series.name}<br/>` +
              `<b>Strength` +
              `: </b>{point.x:.2f} ${units}`
            : `<b>{series.name}<br/>` +
              `<b>Strength` +
              `: </b>{point.x:.0f} ${units}`,
        },
      },
      areaspline: {
        tooltip: {
          headerFormat: '',
          pointFormat: isMetric
            ? `<b>{series.name}<br/>` +
              `<b>Strength` +
              `: </b>{point.x:.2f} ${units}`
            : `<b>{series.name}<br/>` +
              `<b>Strength` +
              `: </b>{point.x:.0f} ${units}`,
        },
        lineWidth: 0,
      },
      histogram: {
        tooltip: {
          headerFormat: '',
          pointFormat: isMetric
            ? `<b>Samples:</b> {point.y} <br/>` +
              `<b>Strength` +
              `: </b>{point.x:.2f} - {point.x2:.2f} ${units}`
            : `<b>Samples:</b> {point.y} <br/>` +
              `<b>Strength` +
              `: </b>{point.x:.0f} - {point.x2:.0f} ${units}`,
        },
      },
      scatter: {
        showInLegend: false,
      },
    },

    xAxis: [
      {
        title: {
          text: '___', //Placeholder for the custom label above
          y: 16,
          style: {
            color: 'white', // Hide text in the white background
          },
        },
        alignTicks: false,
        labels: {
          enabled: true,
        },
      },
    ],

    yAxis: [
      {
        title: '', //Do not remove or will cause highcharts to crash
        labels: {
          enabled: false,
        },
        opposite: true,
      },
      {
        title: {
          text: isCommissionReport ? 'Sample Count' : '# of Samples',
        },
        min: 0,
        allowDecimals: false,
        labels: {
          enabled: true,
        },
        opposite: false,
      },
    ],

    legend: {
      verticalAlign: 'bottom',
      align: 'left',
      itemStyle: {
        fontSize: '10px',
      },
    },
    credits: {
      enabled: false,
    },

    series: graphData,
  }

  return (
    <React.Fragment>
      {mixVariationSelectedToggle || isCommissionReport ? (
        <Grid item xs={12} style={{ alignContent: 'center', width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={setRef}
            style={{ margin: '0px', padding: '0px' }}
          />
        </Grid>
      ) : (
        <Grid item>
          <Typography className={classes.warning}>
            {'Select a mix design from the table above to analyze'}
          </Typography>
        </Grid>
      )}
      {!isCommissionReport &&
        noSampleDataMsg.map((message, key) => (
          <Grid item key={key} xs={12}>
            <Typography className={classes.warning}>{message}</Typography>
          </Grid>
        ))}
    </React.Fragment>
  )
}

HistogramPresentational.defaultProps = {
  graphWaterMark: null,
}
