import { FreshPropertyNumberKeys } from './Types'

type SelectedValue = string | number | null

/**
 * Class to contain all the Graph Filtering logic
 *
 */
export class GraphFilter {
  property: FreshPropertyNumberKeys | 'dataSetSize' | null = null
  rangeMinValue: number | null = null
  rangeMaxValue: number | null = null
  selectedMinValue: SelectedValue = null
  selectedMaxValue: SelectedValue = null
  defaultFilter: boolean = false
  options: Array<string | number> = []
  selectedOptions: Array<string | number> = []
  isDisabled: boolean = false
  label: string | null = ''
  expanded: boolean = true

  constructor(
    property = null,
    minValue = null,
    maxValue = null,
    options = [],
    defaultFilter = false,
    isDisabled = false
  ) {
    this.updateProperty(
      property,
      minValue,
      maxValue,
      options,
      defaultFilter,
      isDisabled
    )
  }

  updateDisable(value: boolean) {
    this.isDisabled = value
  }

  updateProperty(
    property: FreshPropertyNumberKeys | 'dataSetSize' | null,
    minValue: number | null = null,
    maxValue: number | null = null,
    options: string[] | number[] = [],
    defaultFilter = false,
    isDisabled = false
  ) {
    this.property = property
    this.defaultFilter = defaultFilter
    this.isDisabled = isDisabled
    this.updateFilterRange(minValue, maxValue)
    this.updateOptions(options)
  }

  updateExpandedProperty(expanded: boolean) {
    this.expanded = expanded
  }

  updateLabelProperty(label: string) {
    this.label = label
  }

  updateFilterRange(
    minValue: number | null = null,
    maxValue: number | null = null
  ) {
    this.rangeMinValue = minValue
    this.rangeMaxValue = maxValue
    this.updateSelectedRange(minValue, maxValue)
  }

  updateSelectedRange(
    minValue: SelectedValue = null,
    maxValue: SelectedValue = null
  ) {
    this.selectedMinValue = minValue
    this.selectedMaxValue = maxValue
  }

  updateOptions(options: number[] | string[] = []) {
    this.options = options
    const defaultInterval: number | string = 28
    let selectedOptions: any[]
    if (options.includes(defaultInterval))
      selectedOptions = [options[options.indexOf(defaultInterval)]]
    else if (options.length > 0) selectedOptions = [options[options.length - 1]]
    else selectedOptions = []
    return this.updateSelectedOptions(selectedOptions)
  }

  updateSelectedOptions(selectedOptions: Array<string | number> = []) {
    this.selectedOptions = selectedOptions
    return selectedOptions
  }
}
