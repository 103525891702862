import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

MasterUnitCommandsTable.propTypes = {
  commands: PropTypes.array.isRequired,
}

function MasterUnitCommandsTable(props) {
  const { commands } = props

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Timestamp</Typography>
              </TableCell>
              <TableCell>
                <Typography>Command - Arguments</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.map(command => {
              return (
                <TableRow key={command.commandId}>
                  <TableCell>
                    <Typography>{command.dispatchTimestamp}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {command.code} - {command.args}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default MasterUnitCommandsTable
