import React from 'react'
import { Helmet } from 'react-helmet'
import { Grid, Typography, makeStyles, Container } from '@material-ui/core'
import AlarmTabs from './AlarmTabs'
import PropTypes from 'prop-types'
import ServiceCaseModalLogical from './ServiceCaseModalLogical'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'
import FilterButton from '../../../Common/Components/FilterPanel/FilterButton'
import theme from '../../../theme/muiTheme'
import FilterPanelLogical from '../../../Common/Components/FilterPanel/FilterPanelLogical'
import { titleCaseToCamelCase } from '../../../Common/Helpers/GeneralHelpers'
import { injectionGraphOptions } from '../Constants/Constants'

const useFilterStyles = makeStyles(() => ({
  checkboxMultiFormControlLabelWithTooltip: {
    marginLeft: 0,
    minWidth: '104%', // width to match expand button
    [theme.breakpoints.down(600)]: {
      minWidth: '101%',
    },
  },
  checkboxMultiWithTooltip: {
    marginLeft: 'auto',
  },
}))

const useStyles = makeStyles(() => ({
  viewContainer: {
    margin: '0 auto',
  },
  viewTitle: {
    marginBottom: '1em',
  },
  //Filter Panel Styling
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '72px',
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    top: '72px',
  },
  btnContainer: {
    marginBottom: '16px',
  },
}))

function AlarmsManagementHomePresentational(props) {
  AlarmsManagementHomePresentational.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Keep track of which alarm has been selected */
    selectedAlarm: PropTypes.object.isRequired,
    /** State setter function to keep track of which alarm has been selected */
    setSelectedAlarm: PropTypes.func.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Boolean to determine whether the service case modal is open */
    open: PropTypes.bool.isRequired,
    /** State setter to open and close the service case modal */
    setOpen: PropTypes.func.isRequired,
    /** State setter function to keep track of which service case has been selected */
    setSelectedServiceCase: PropTypes.func.isRequired,
    /** The service case that has been selected */
    selectedServiceCase: PropTypes.instanceOf(ServiceCaseTableObject),
    /** The value of the currently selected tab */
    tabValue: PropTypes.number.isRequired,
    /** State setter to for switching tabs */
    setTabValue: PropTypes.func.isRequired,
    /** The name of the selected tab */
    selectedTab: PropTypes.string.isRequired,
    /** The service case displayed when the modal opens: existing or new */
    serviceCaseSource: PropTypes.object,
    /** State setter to update the service case displayed in the modal */
    setServiceCaseSource: PropTypes.func,

    /** Array of remote units with plants taken from  */
    remoteUnitsWithPlants: PropTypes.arrayOf(
      PropTypes.shape({
        plantId: PropTypes.number,
        plantName: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        masterUnitHardwareId: PropTypes.number,
        remoteUnitIds: PropTypes.arrayOf(PropTypes.number),
      })
    ),
    /** Boolean to determine whether the filter panel is open */
    panelOpen: PropTypes.bool.isRequired,
    /** Function to set whether the filter panel is open or not */
    setPanelOpen: PropTypes.func.isRequired,
    /** The number of filters that are currently active */
    filterCount: PropTypes.number.isRequired,
    /** Function to handle the click event of the filter button */
    filterButtonClickHandler: PropTypes.func.isRequired,
    /** Boolean to determine whether the filter panel should be expanded */
    expandFilters: PropTypes.bool.isRequired,
    /** Function to set whether the filter panel should be expanded or not */
    setExpandFilters: PropTypes.func.isRequired,
    /** Boolean to determine whether to show inactive alarms or not */
    showInactiveAlarms: PropTypes.bool.isRequired,
    /** Boolean to determine whether to show closed service cases or not */
    showClosedCases: PropTypes.bool.isRequired,
    /** Function to set whether to show closed service cases or not */
    setShowClosedCases: PropTypes.func.isRequired,
    /** The data type to display (Mixer Manifold, Injection, Inlet) */
    systemDataType: PropTypes.string.isRequired,
    /** An object containing the visibility of each graph to be shown */
    graphVisibility: PropTypes.shape({
      cO2GraphVisible: PropTypes.bool.isRequired,
      upstreamPressureGraphVisible: PropTypes.bool.isRequired,
      temperatureGraphVisible: PropTypes.bool.isRequired,
      downstreamPressure0GraphVisible: PropTypes.bool.isRequired,
      downstreamPressure1GraphVisible: PropTypes.bool.isRequired,
      liquidGraphVisible: PropTypes.bool.isRequired,
      gasGraphVisible: PropTypes.bool.isRequired,
    }).isRequired,
    /** Function to handle changes in the filter panel */
    filterPanelChangeHandler: PropTypes.func.isRequired,
    /** Function to get the selected value or the default value of an input */
    getValue: PropTypes.func.isRequired,
  }

  const {
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    selectedAlarm,
    setSelectedAlarm,
    setPage,
    setRowsPerPage,
    open,
    setOpen,
    selectedServiceCase,
    setSelectedServiceCase,
    tabValue,
    setTabValue,
    selectedTab,
    serviceCaseSource,
    setServiceCaseSource,
    remoteUnitsWithPlants,
    panelOpen,
    setPanelOpen,
    filterCount,
    filterButtonClickHandler,
    expandFilters,
    setExpandFilters,
    showInactiveAlarms,
    showClosedCases,
    setShowClosedCases,
    systemDataType,
    graphVisibility,
    filterPanelChangeHandler,
    getValue,
    activeFilters,
    setActiveFilters,
    filterChipsHandler,
    clearAllFilters,
  } = props

  const classes = useStyles()
  const filtersClasses = useFilterStyles()

  const createOptions = options => {
    return options.map(option => ({
      id: option,
      name: option,
    }))
  }

  const createPlantOptions = remoteUnitsWithPlants => {
    if (!remoteUnitsWithPlants) {
      return []
    }

    return remoteUnitsWithPlants.map(({ label, remoteUnitId }) => ({
      name: label,
      id: remoteUnitId,
    }))
  }

  const createSystemDataOptions = options => {
    return options.map(option => ({
      id: titleCaseToCamelCase(option),
      name: option,
    }))
  }

  const filterPanel = [
    {
      name: 'FILTER BY',
      category: 'filterBy',
      filters: [
        {
          property: 'location',
          name: 'Plant Location/ HMI ID',
          componentType: 'AutocompleteSingle',
          options: createPlantOptions(remoteUnitsWithPlants),
          onChangeHandler: filterPanelChangeHandler,
        },
        {
          property: 'dateRange',
          name: 'Date Range',
          componentType: 'DateRange',
          options: [],
          onChangeHandler: filterPanelChangeHandler,
        },
      ],
    },
    {
      name: 'ALARMS',
      category: 'alarms',
      filters: [
        {
          property: 'alarms',
          name: 'Alarms',
          componentType: 'CheckboxMulti',
          options: createOptions([
            'International',
            'US/Canada',
            'Inactive Alarms',
          ]),
          onChangeHandler: filterPanelChangeHandler,
          color: 'primary',
        },
      ],
    },
    {
      name: 'SERVICE CASES',
      category: 'serviceCases',
      filters: [
        {
          property: 'serviceCases',
          name: 'Service Cases',
          componentType: 'CheckboxMulti',
          options: createOptions([
            'International',
            'US/Canada',
            'Closed Cases',
          ]),
          onChangeHandler: filterPanelChangeHandler,
          color: 'primary',
        },
      ],
    },
    {
      name: 'SYSTEM DATA',
      category: 'systemData',
      filters: [
        {
          property: 'systemData',
          name: 'System Data',
          componentType: 'RadioSingleSelect',
          options: createSystemDataOptions([
            'Mixer Manifold',
            'Injection',
            'Inlet',
          ]),
          onChangeHandler: filterPanelChangeHandler,
          isHorizontal: false,
          color: 'primary',
        },
      ],
    },
    {
      name: 'INJECTION GRAPHS',
      category: 'injectionGraphs',
      filters: [
        {
          property: 'injectionGraphs',
          name: 'Injection Graphs',
          componentType: 'CheckboxMultiWithTooltip',
          options: injectionGraphOptions,
          onChangeHandler: filterPanelChangeHandler,
          checkboxIconType: 'visibility',
          getTooltipTitle: val => (val ? 'Hide' : 'Show'),
        },
      ],
    },
  ]

  const getFilterPanel = tabValue => {
    const locationFilter = filterPanel[0]
    const tabFilter = filterPanel[tabValue + 1] // tabValue starts with 0
    return [locationFilter, tabFilter]
  }

  return (
    <>
      <Helmet>
        <title>Alarms Management</title>
      </Helmet>
      <Grid container item direction="column">
        <ServiceCaseModalLogical
          open={open}
          setOpen={setOpen}
          tabValue={tabValue}
          selectedTab={selectedTab}
          serviceCaseSource={serviceCaseSource}
          setServiceCaseSource={setServiceCaseSource}
        />
        <Container
          maxWidth="xl"
          style={{ padding: '0em 0.5em' }}
          className={classes.mainContainer}
          id="alarms-management-container"
        >
          <FilterPanelLogical
            open={panelOpen}
            setOpen={setPanelOpen}
            expandFilters={expandFilters}
            setExpandFilters={setExpandFilters}
            filterPanel={getFilterPanel(tabValue)}
            parentClasses={classes}
            getValue={getValue}
            filtersClasses={filtersClasses}
          />
          <div
            className={
              panelOpen ? classes.wrapperShifted : classes.wrapperUnshifted
            }
          >
            <Grid container item justify="space-between">
              <Typography variant="h2" className={classes.viewTitle}>
                Alarms Management
              </Typography>
              <Grid
                item
                container
                xs={12}
                direction="row"
                justify="space-between"
              >
                <Grid item>
                  <FilterButton
                    open={panelOpen}
                    parentClasses={classes}
                    filterCount={filterCount}
                    onClickHandler={filterButtonClickHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AlarmTabs
                handleTableSort={handleTableSort}
                selectedAlarm={selectedAlarm}
                order={order}
                orderBy={orderBy}
                setSelectedAlarm={setSelectedAlarm}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setOpen={setOpen}
                selectedServiceCase={selectedServiceCase}
                setSelectedServiceCase={setSelectedServiceCase}
                tabValue={tabValue}
                setTabValue={setTabValue}
                selectedTab={selectedTab}
                remoteUnitsWithPlants={remoteUnitsWithPlants}
                showInactiveAlarms={showInactiveAlarms}
                showClosedCases={showClosedCases}
                setShowClosedCases={setShowClosedCases}
                systemDataType={systemDataType}
                graphVisibility={graphVisibility}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterChipsHandler={filterChipsHandler}
                clearAllFilters={clearAllFilters}
              />
            </Grid>
          </div>
        </Container>
      </Grid>
    </>
  )
}

export default AlarmsManagementHomePresentational
