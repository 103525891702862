import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ServiceCaseNotesPresentational from './ServiceCaseNotesPresentational'
import { useFormContext } from 'react-hook-form'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import ServiceCaseObject from '../Logic/ServiceCaseObject'

/**
 *
 * @param {Object} props
 * @param {ServiceCaseObject} props.serviceCaseDraft
 * @returns
 */
function ServiceCaseNotesLogical(props) {
  const { selectedTab, serviceCaseDraft, setServiceCaseDraft, disabled } = props

  const { register, errors } = useFormContext()

  /** The note that the user types into the form */
  const [noteInputValue, setNoteInputValue] = useState('')
  /** The note that the user types into the form */
  const [firstNote, setFirstNote] = useState([])

  /** Capture the note that the user types into the form */
  const handleChange = e => {
    setNoteInputValue(e.target.value)
  }

  const handleSendNewNote = async () => {
    if (serviceCaseDraft.serviceCaseId) {
      const response = await AlarmsManagementDataHelpers.postServiceCaseNote(
        serviceCaseDraft.serviceCaseId,
        noteInputValue
      )
      if (response.ok) {
        const noteResponse = await response.json()
        setServiceCaseDraft(new ServiceCaseObject(noteResponse))
        setNoteInputValue('')
      }
    } else {
      setFirstNote([
        {
          note: noteInputValue,
          timestamp: new Date(Date.now()).toLocaleString(),
        },
      ])
    }
  }

  return (
    <ServiceCaseNotesPresentational
      noteInputValue={noteInputValue}
      handleChange={handleChange}
      register={register}
      errors={errors}
      disabled={disabled}
      selectedTab={selectedTab}
      serviceCaseDraft={serviceCaseDraft}
      handleSendNewNote={handleSendNewNote}
      firstNote={firstNote}
    />
  )
}

ServiceCaseNotesLogical.propTypes = {
  /** The service case displayed when the modal opens: existing or new */
  serviceCaseNotes: PropTypes.arrayOf(
    PropTypes.shape({
      serviceCaseNoteId: PropTypes.number,
      timestamp: PropTypes.string,
      note: PropTypes.string,
    })
  ),
  /** The name of the selected tab */
  selectedTab: PropTypes.string.isRequired,
  /** Draft of the service case changes */
  serviceCaseDraft: PropTypes.object.isRequired,
}

export default ServiceCaseNotesLogical
