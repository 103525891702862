import { useState, useEffect, useRef } from 'react'
//@ts-ignore FUTURE: Define the type for the react-router-dom package
import { useLocation, useHistory } from 'react-router-dom'
import { tabsConfig } from './SonarTabHelper'
import { getSonarFeatureFlags } from '../../../Common/Helpers/GeneralHelpers'
import { ISonarFeatureFlags } from '../../../Common/Logic/Types'

const useSonarTabs = () => {
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const tabQueryParam = query.get('tab')

  const featureFlags = getSonarFeatureFlags()
  const enabledTabs = tabsConfig.filter(
    tab => featureFlags[tab.flag as keyof ISonarFeatureFlags]
  )

  const getInitialTabValue = () => {
    const initialTabIndex = enabledTabs.findIndex(
      tab => tab.urlLabel === tabQueryParam
    )
    return initialTabIndex >= 0 ? initialTabIndex : 0
  }

  const [tabValue, setTabValue] = useState(getInitialTabValue)
  const isUpdatingRef = useRef(false)

  useEffect(() => {
    if (isUpdatingRef.current) {
      isUpdatingRef.current = false
      return
    }

    const syncTabWithUrl = () => {
      const currentQueryParams = new URLSearchParams(location.search)
      const newUrlTab = enabledTabs[tabValue]?.urlLabel

      if (newUrlTab) {
        isUpdatingRef.current = true
        // Preserve existing parameters and set the 'tab' parameter
        currentQueryParams.set('tab', newUrlTab)
        history.replace({
          pathname: location.pathname,
          search: currentQueryParams.toString(),
        })
      }
    }

    syncTabWithUrl()
  }, [tabValue, enabledTabs, history, location.pathname, location.search])

  return {
    tabValue,
    setTabValue,
    enabledTabs,
  }
}

const hooks = {
  useSonarTabs,
}

export default hooks
