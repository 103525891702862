import { Grid, Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getOverviewTableRows } from '../Data/PCBDataHelpers'
import MasterUnitTable from '../Components/MasterUnitTable'

MasterUnitAlarmedTableView.propTypes = {
  setSelectedMasterUnit: PropTypes.func.isRequired,
}

function MasterUnitAlarmedTableView(props) {
  const { setSelectedMasterUnit } = props
  const [alarmedMasterUnitData, setAlarmedMasterUnitData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState('')
  const [sortColumn, setSortColumn] = useState('')

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = event => {
    const newRowPerPage = event.target.value
    setRowsPerPage(newRowPerPage)
  }

  useEffect(() => {
    const fetchMasterUnitTablePropsOnlyAlarms = async function(
      sortOrder,
      limit,
      offset,
      sortColumn
    ) {
      const overviewOnlyAlarms = await getOverviewTableRows(
        true,
        sortOrder,
        limit,
        offset,
        false,
        sortColumn
      )

      if (!overviewOnlyAlarms.ok) {
        return []
      } else {
        return await overviewOnlyAlarms.json()
      }
    }
    const offset = page * rowsPerPage
    fetchMasterUnitTablePropsOnlyAlarms(
      sortOrder,
      rowsPerPage,
      offset,
      sortColumn
    ).then(alarmResults => {
      setAlarmedMasterUnitData(alarmResults)
      setIsLoaded(true)
    })
  }, [sortOrder, sortColumn, page, rowsPerPage])

  console.log(isLoaded)

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justify="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h4">Active Alarms</Typography>
      </Grid>
      <Grid item>
        <Paper>
          <MasterUnitTable
            data={alarmedMasterUnitData}
            alarmVariant={true}
            setSelectedMasterUnit={setSelectedMasterUnit}
            handleChangePage={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MasterUnitAlarmedTableView
