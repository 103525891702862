import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  TextField,
  Box,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
const disabledClassNameProps = { className: 'Mui-disabled' }

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 'bold',
  },
  formGroup: {
    padding: '1em 0.5em',
    width: '100%',
  },
  historical: {
    border: 'solid #DADADA 1px',
    borderRadius: '4px',
    width: '100%',
    padding: '0.5em 1em',
  },
  timestamp: {
    fontStyle: 'italic',
    margin: 0,
  },
  note: {
    margin: 0,
  },
  input: {
    '& input': {
      background: '#FFFFFF',
    },
  },
}))

/**
 *
 * @param {Object} props
 * @param {Array<{timestamp: String,
 *  serviceCaseNoteId:
 *  Number, note: String}>} props.serviceCaseNotes
 */
function ServiceCaseNotesPresentational(props) {
  ServiceCaseNotesPresentational.propTypes = {
    /** The service case displayed when the modal opens: existing or new */
    serviceCaseNotes: PropTypes.arrayOf(
      PropTypes.shape({
        timestamp: PropTypes.string,
        serviceCaseNoteId: PropTypes.number,
        note: PropTypes.string,
      })
    ),
    /** The name of the selected tab */
    selectedTab: PropTypes.string.isRequired,
    /** The note that the user types into the form */
    noteInputValue: PropTypes.string,
    /** Function called when the note form input value changes */
    handleChange: PropTypes.func.isRequired,
    /** Draft of the service case changes */
    serviceCaseDraft: PropTypes.object.isRequired,
    /** Array containing the note added to a new service case */
    firstNote: PropTypes.array.isRequired,
    /** Function to post a new note to Kelowna */
    handleSendNewNote: PropTypes.func.isRequired,
  }
  const {
    handleSendNewNote,
    serviceCaseDraft,
    register,
    handleChange,
    noteInputValue,
    disabled,
    firstNote,
    selectedTab,
  } = props

  const classes = useStyles()

  return (
    <>
      <Grid item container>
        <Typography variant="h3" className={classes.subtitle}>
          Notes
        </Typography>
      </Grid>
      <Paper elevation={0} className={classes.formGroup}>
        <Grid item container lg={12}>
          <Box className={classes.historical}>
            {selectedTab === 'Service Cases'
              ? serviceCaseDraft?.notes?.map(note => {
                  return (
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                      key={note.timestamp}
                    >
                      <Grid item container lg={3}>
                        <Typography
                          className={classes.timestamp}
                          component="span"
                        >
                          {new Date(note.timestamp).toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item container lg={9}>
                        <Typography className={classes.note}>
                          {note.note}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })
              : firstNote.map(note => {
                  return (
                    <Grid
                      container
                      justify="space-between"
                      spacing={2}
                      key={note.timestamp}
                    >
                      <Grid item container lg={3}>
                        <Typography
                          className={classes.timestamp}
                          component="span"
                        >
                          {note.timestamp}
                        </Typography>
                      </Grid>
                      <Grid item container lg={9}>
                        <Typography className={classes.note}>
                          {note.note}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
          </Box>
          <Grid container item alignItems="center">
            <Grid item lg={11}>
              <TextField
                label="Add a new note"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={noteInputValue}
                name="note"
                className={classes.input}
                disabled={disabled}
                inputRef={register}
                inputProps={{
                  readOnly: selectedTab === 'Alarms' && firstNote.length,
                }}
                InputProps={
                  selectedTab === 'Alarms' && firstNote.length
                    ? { ...disabledClassNameProps }
                    : {}
                }
              />
            </Grid>
            <Grid lg={1} item container justify="center">
              <Button
                color="primary"
                variant="text"
                onClick={handleSendNewNote}
                disabled={
                  (selectedTab === 'Alarms' && firstNote.length > 0) ||
                  disabled ||
                  !noteInputValue.length
                }
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

ServiceCaseNotesPresentational.defaultProps = {
  serviceCaseDraft: {},
}

export default ServiceCaseNotesPresentational
