import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import DialogModal from '../../../Common/Components/DialogModal/DialogModal'
import { DialogModalSize, DialogModalType } from '../../Logic/Types'
import theme from '../../../theme/muiTheme'

export interface IAddDataProgressLostAlertPresentationalProps {
  showAlert: boolean
  closeModal: () => void
  handleLeavePage: () => void
}

const useStyles = makeStyles({
  // @ts-ignore
  ...theme.customClasses.warningModal,
})

function AddDataProgressLostAlertPresentational(
  props: IAddDataProgressLostAlertPresentationalProps
) {
  const classes = useStyles()
  const { showAlert, closeModal, handleLeavePage } = props

  return (
    <DialogModal
      modalOpen={showAlert}
      modalType={DialogModalType.warning}
      headerText="Progress Will Be Lost"
      contentText={
        <Typography variant="body2">
          By leaving this page, any progress you have made will be lost. <br />
          Do you wish to continue?
        </Typography>
      }
      parentClasses={classes}
      modalSize={DialogModalSize.Small}
      cancelButtonText="No, Go Back"
      confirmButtonText="Yes, Leave Page"
      handleCancel={closeModal}
      handleConfirm={handleLeavePage}
      hasAction={true}
    />
  )
}

export default AddDataProgressLostAlertPresentational
