import { CellBehavior } from '../../Common/Logic/Types'
import { baseColors } from '../../theme/colors'
import {
  draftTag,
  pendingReviewTag,
  reviewedTag,
} from '../Constants/CommonConstants'
import { getFormattedDate, orcaReadOnly } from '../Logic/TSSLogic'
import {
  CommissionReportStatus,
  ILibraryReport,
  IUndigestedLibraryReport,
} from '../Logic/Types'

export const digestLibraryReport = (reports: IUndigestedLibraryReport[]) => {
  const digestedReports = reports.map(report => {
    const orcaReport: ILibraryReport = {
      divisionName: report.divisionName,
      reportId: report.commissioningReportId,
      createdBy: report.createdBy,
      mixCodes: report.mixCodes,
      isMetric: report.isMetric,
      lastUpdated: report.lastUpdatedTimestamp,
      status: CommissionReportStatus[report.status],
    }
    if (report.reviewedTimestamp)
      orcaReport.reviewedDate = report.reviewedTimestamp
    return orcaReport
  })
  return digestedReports
}

export const getStatusColor = (status: CommissionReportStatus) => {
  if (status === CommissionReportStatus.Draft) {
    return draftTag
  } else if (status === CommissionReportStatus.PendingReview) {
    return pendingReviewTag
  } else if (status === CommissionReportStatus.Reviewed) {
    return reviewedTag
  }
}

export const reportsToRows = (
  reports: ILibraryReport[],
  roles: string[] = []
) => {
  const filterCondition = orcaReadOnly(roles)

  const createCells = (report: ILibraryReport) => {
    const cells = [
      report.divisionName,
      report.mixCodes.join(', '),
      report.createdBy,
      {
        behavior: CellBehavior.Chips,
        content: [
          {
            text: report.status,
            style: getStatusColor(report.status),
          },
        ],
      },
      {
        behavior: CellBehavior.StyledText,
        content: [
          {
            text: report.lastUpdated
              ? getFormattedDate(report.lastUpdated, 'YYYYMMDD', '-', true)
              : '',
            style: report.reviewedDate
              ? { color: baseColors.text.disabled }
              : {},
          },
        ],
      },
      report.reviewedDate
        ? getFormattedDate(report.reviewedDate, 'YYYYMMDD', '-', true)
        : null,
      {
        behavior: CellBehavior.ButtonLink,
        content: {
          text:
            report.status === CommissionReportStatus.Reviewed
              ? 'Preview'
              : 'View',
        },
      },
    ]
    return cells
  }

  return filterCondition
    ? reports
        .filter(report => report.status === 'Reviewed')
        .map(report => ({
          id: report.reportId,
          cells: createCells(report),
        }))
    : reports.map(report => ({
        id: report.reportId,
        cells: createCells(report),
      }))
}

/**
 * Generic function to define the filter chip for the report library.
 * @param filterSettings
 * @param options
 * @param filterKey
 * @param optionKey
 * @param property
 */
export const getFilterChip = (
  filterSettings,
  options,
  filterKey,
  optionKey,
  property
) => {
  if (filterSettings[filterKey].length > 0) {
    const matchedOption = options.find(
      option => filterSettings[filterKey][0] === option[optionKey]
    )
    if (matchedOption) {
      return { property, label: matchedOption.name }
    }
  }
  return null
}
