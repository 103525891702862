import React from 'react'
import {
  Grid,
  Typography,
  Divider,
  Chip,
  Checkbox,
  FormControlLabel,
  makeStyles,
  GridSize,
  Tooltip,
} from '@material-ui/core'
import {
  ICommissionReportVariation,
  ITestResultsPropertyOption,
} from '../../Logic/Types'
import {
  getTestResultsPropertyPercentage,
  getTestResultsPropertyValue,
} from '../../Helpers/CommissionReportHelpers'
import { baseColors } from '../../../theme/colors'

export interface ITestResultsTablePresentationalProps {
  rows: ITestResultsPropertyOption[]
  testResultsPropertyOptions: ITestResultsPropertyOption[]
  baselineVariation: ICommissionReportVariation | undefined
  carbonCureVariation: ICommissionReportVariation | undefined
  showDiff: boolean
  isDifferentCO2DosageUnit: boolean
  isMetric: boolean
  handleChipClick: (option: ITestResultsPropertyOption) => void
  isChipActive: (option: ITestResultsPropertyOption) => boolean
  getOptionDisabled: (option: ITestResultsPropertyOption) => boolean
  handleCheckboxClick: () => void
  horizontalView: boolean
  isCommissionReportViewMode: boolean
}

interface StylesProps {
  isCommissionReportViewMode: boolean | undefined
}

const useStyles = makeStyles(theme => ({
  inactiveChip: {
    backgroundColor: '#ffffff !important',
    color: 'rgba(0, 0, 0, 0.35)',
    border: '0.41px solid rgba(0, 0, 0, 0.35)',
  },
  activeChip: {
    backgroundColor: 'rgba(32, 59, 91, 0.3) !important',
    color: 'black',
    border: '0.41px solid rgba(0, 0, 0, 0.6)',
  },
  label: {
    padding: '3px 6px',
  },
  gridRowContainer: {
    marginTop: (props: StylesProps) =>
      props.isCommissionReportViewMode ? '24px' : '8px',
  },
}))

const TestResultsTablePresentational = (
  props: ITestResultsTablePresentationalProps
) => {
  const {
    rows,
    testResultsPropertyOptions,
    baselineVariation,
    carbonCureVariation,
    showDiff,
    isDifferentCO2DosageUnit,
    isMetric,
    handleChipClick,
    isChipActive,
    getOptionDisabled,
    handleCheckboxClick,
    horizontalView,
    isCommissionReportViewMode,
  } = props

  const classes = useStyles({ isCommissionReportViewMode })

  const verticalViewGridSize = (gridSize: GridSize): GridSize =>
    showDiff ? gridSize : ((parseInt(gridSize as string) + 1) as GridSize)
  const verticalShowDiffAdjustment = (pixels: number) =>
    !horizontalView && showDiff ? `${pixels}px` : undefined
  const horizontalViewAdjustment = (pixels: number) =>
    horizontalView ? `${pixels}px` : undefined

  const getLabelWidth = (property: ITestResultsPropertyOption) => {
    return property.type !== 'freshProperty' ? '140px' : '130px'
  }

  return (
    <Grid container direction="row">
      <Grid
        item
        container
        direction="row"
        justify="space-around"
        spacing={horizontalView ? 1 : undefined}
      >
        <Grid
          item
          container
          direction={horizontalView ? 'column' : 'row'}
          xs={horizontalView ? 1 : undefined}
          style={{ marginLeft: horizontalViewAdjustment(-36) }}
        >
          <Grid item xs={horizontalView ? 1 : verticalViewGridSize(5)}>
            <Typography
              variant="h5"
              style={{ height: horizontalView ? '50px' : undefined }}
            >
              Description
            </Typography>
          </Grid>
          <Grid
            item
            xs={horizontalView ? 1 : verticalViewGridSize(2)}
            style={{ marginLeft: verticalShowDiffAdjustment(5) }}
          >
            <Typography variant="body1">Baseline</Typography>
          </Grid>
          <Grid
            item
            xs={horizontalView ? 1 : 3}
            style={{ marginLeft: verticalShowDiffAdjustment(10) }}
          >
            <Typography variant="body1">CarbonCure</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: showDiff ? 'block' : 'none',
              marginLeft: verticalShowDiffAdjustment(-12),
            }}
          >
            <Tooltip
              title="CarbonCure/Baseline"
              placement={horizontalView ? 'left' : 'top-start'}
              style={{ margin: '0px' }}
            >
              <span>
                <Typography variant="body1">Comparison</Typography>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        {rows?.map(property => (
          <Grid
            item
            container
            direction={horizontalView ? 'column' : 'row'}
            xs={horizontalView ? 1 : undefined}
            key={property.id}
            className={horizontalView ? undefined : classes.gridRowContainer}
            style={{ marginLeft: horizontalViewAdjustment(-10) }}
          >
            <Grid item xs={horizontalView ? 1 : verticalViewGridSize(5)}>
              <Typography
                variant="h5"
                style={{
                  width: horizontalView ? getLabelWidth(property) : undefined,
                  height: horizontalViewAdjustment(50),
                }}
              >
                {isDifferentCO2DosageUnit && property.id === 'cO2Dosage'
                  ? property.label
                  : property.labelWithUnit}
              </Typography>
            </Grid>
            <Grid
              item
              xs={horizontalView ? 1 : verticalViewGridSize(2)}
              style={{ marginLeft: verticalShowDiffAdjustment(5) }}
            >
              <Typography variant="body1" style={{ width: '130px' }}>
                {getTestResultsPropertyValue(
                  baselineVariation,
                  property,
                  isMetric,
                  isDifferentCO2DosageUnit
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={horizontalView ? 1 : 3}
              style={{ marginLeft: verticalShowDiffAdjustment(10) }}
            >
              <Typography variant="body1" style={{ width: '130px' }}>
                {getTestResultsPropertyValue(
                  carbonCureVariation,
                  property,
                  isMetric,
                  isDifferentCO2DosageUnit
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: showDiff ? 'block' : 'none',
                marginLeft: verticalShowDiffAdjustment(-10),
              }}
            >
              <Typography
                variant="body1"
                style={{ width: horizontalViewAdjustment(130) }}
              >
                {getTestResultsPropertyPercentage(
                  baselineVariation,
                  carbonCureVariation,
                  property,
                  isDifferentCO2DosageUnit
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {!isCommissionReportViewMode && (
        <Grid item xs={12}>
          <Divider style={{ margin: '12px 0px' }} />
          <div style={{ margin: '12px 0px', width: '100%' }}>
            <Typography
              style={{
                color: baseColors.text.secondary,
                fontStyle: 'italic',
              }}
              variant="caption"
            >
              Select up to 6 properties max.
            </Typography>
          </div>
          <Grid container spacing={1} wrap="wrap">
            {testResultsPropertyOptions?.map((option: any) => (
              <Grid item key={option.id}>
                <Chip
                  label={option.label}
                  className={
                    isChipActive(option)
                      ? classes.activeChip
                      : classes.inactiveChip
                  }
                  onClick={() => handleChipClick(option)}
                  disabled={getOptionDisabled(option)}
                  classes={{
                    label: classes.label,
                  }}
                  data-testid={`chip-${option.id}`}
                />
              </Grid>
            ))}
          </Grid>
          <Divider style={{ marginTop: '12px' }} />
          <FormControlLabel
            control={
              <Checkbox
                id="showDiff"
                name="showDiff"
                color="primary"
                checked={showDiff}
                onChange={() => handleCheckboxClick()}
                data-testid="checkbox-showDiff"
                style={{ marginBottom: '0px' }}
              />
            }
            label={<Typography>Comparison</Typography>}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default TestResultsTablePresentational
