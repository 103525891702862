/**
 * Handler function to handle API response when it is not "ok".
 * @param {*} res - response object from API call.
 * @param {*} setErrorMessage - optional setter function to display error message in parent component.
 */
export async function handleResNotOk(res, setErrorMessage = null) {
  const errorString403 = `You are not authorized to proceed with this action. If you wish to have your 
                          permissions changed, please contact Hannah LeBlanc.`

  res.json().then(
    // Try to parse the response to get the error message from Kelowna.
    data => {
      console.error(data.error)
      setErrorMessage?.(data.error)
    },
    // If there is no Kelowna error, display the status.
    e => {
      console.error(e)
      // Adds statusText from response if available
      let errorStatusText = res.statusText
      // Changes Status Text based on error code
      if (res.status === 403) {
        errorStatusText = errorString403
      }
      setErrorMessage?.(`${res.status}: ${errorStatusText}`)
    }
  )
}
