import React from 'react'
import { PlantsTableData } from './PlantsEditableTable.types'
import { Table, TableBody, TableContainer, Paper } from '@material-ui/core'
import GeneralizedEnhancedTableHead from '../../../Common/Components/GeneralizedEnhancedTableHead'
import { PlantsEditableTableHeadCells } from './PlantsEditableTableHeadCells'
import PlantsEditableTableRow from './PlantsEditableTableRow'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

interface PlantsEditableTablePresentationalProps {
  /** Data from parent component. Array of objects. */
  rows: PlantsTableData
  /** Updates single row from table. Each row corresponds with one plant. */
  updateTableRow: (...args: any[]) => any
  /** the function to fire when one clicks a sortable table header */
  handleSortRequest: (...args: any[]) => any
  /** The order of the column, either ascending or dsecending */
  order: string
  /** The column to order by, should be the id of a headcell */
  orderBy: string
  /** the number of results returned by the endpoint, used for pagination */
  count: number
  /** the current page */
  page: number
  /** the number of rows to display per page */
  rowsPerPage: number
  /** function to fire when the user interacts with the arrows on the table pagination */
  handleChangePage: (...args: any[]) => any
  /** function to fire when the user changes the number of rows to display per page */
  handleChangeRowsPerPage: (...args: any[]) => any
}

function PlantsEditableTablePresentational(
  props: PlantsEditableTablePresentationalProps
) {
  const {
    rows,
    updateTableRow,
    handleSortRequest,
    order,
    orderBy,
    count,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props

  return (
    <Paper variant="outlined" style={{ marginBottom: '2rem' }}>
      <TableContainer>
        <Table size="small">
          <GeneralizedEnhancedTableHead
            headCells={PlantsEditableTableHeadCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleSortRequest}
            checkboxColumn
          />
          <TableBody>
            {rows?.map(rowData => (
              <PlantsEditableTableRow
                key={rowData.plantId}
                rowData={rowData}
                updateTableRow={updateTableRow}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleTablePagination
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 40, 60]}
        page={page}
        rowsPerPage={rowsPerPage}
        component="div"
      />
    </Paper>
  )
}

export default PlantsEditableTablePresentational
