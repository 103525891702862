import React from 'react'
import { Chip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

interface IConditionalDeleteChip {
  /** The label of the chip */
  label: string
  /** The function that's called when delete icon is click.  */
  onDelete: () => void | undefined
  /** Determines whether delete buttons will be shown in the chip */
  isDeletable: boolean
}

function ConditionalDeleteChip(props: IConditionalDeleteChip) {
  const { label, onDelete, isDeletable } = props

  return (
    <Chip
      data-testid={'delete-icon'}
      label={label}
      onDelete={isDeletable ? onDelete : undefined}
      deleteIcon={isDeletable ? <CancelIcon /> : undefined}
      size="small"
      style={{ margin: '5px' }}
    />
  )
}

export default ConditionalDeleteChip
