import React from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'

function EditButton(props) {
  EditButton.propTypes = {
    /** Boolean to determine whether a service case is being edited */
    editModeActive: PropTypes.bool.isRequired,
    /** State setter to toggle edit mode for the service case modal */
    setEditModeActive: PropTypes.func.isRequired,
  }
  const { editModeActive, setEditModeActive } = props

  const toggleEditMode = () => setEditModeActive(editMode => !editMode)

  return (
    <Button onClick={toggleEditMode} color="primary">
      {editModeActive ? 'Cancel Edit' : 'Edit'}
    </Button>
  )
}

export default EditButton
