import React from 'react'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  ConvertCtoF,
  ConvertKgPerM3ToLbPerFt3,
  ConvertKgToLb,
  ConvertLitreToGal,
  ConvertLitreToOz,
  ConvertM3ToYd3,
  ConvertMMtoINCH,
  ConvertMPAToPSIRounded,
  getKeyByValue,
  removeEmptyFromObject,
} from '../../Common/Helpers/GeneralHelpers'
import {
  testCategories,
  addBatchRequiredCols,
  admixtureNames,
  spreadsheetColumnWidth,
  flyAshClassDropdown,
  batchMaterialUnits,
} from '../Constants/AddDataConstants'
import { addNewSpreadsheetColumn } from './AddBatchColumnSelectLogic'
import * as AddBatchViewLogic from './AddBatchViewLogic'
import { tssCanWrite } from './TSSLogic'

/** This file includes the logic used in AddBatchView.js. Most of these methods are
 *  concerned with extracting data from BatchTestSample objects retrieved from Kelowna,
 *  as well as validating/cleaning spreadsheet data and preparing it to send back to Kelowna.
 */

/** Set header depending on whether data is being edited or added. */
export const setHeadersText = (isEditMode, hasSampleIds, roles) => {
  if (hasSampleIds) {
    if (isEditMode) {
      return {
        h1: 'Edit Batch Data',
        h2: 'Select Materials and Customize Template',
      }
    } else {
      return {
        h1: 'View Batch Data',
        h2: 'View Materials and Template Customization',
      }
    }
  } else {
    if (tssCanWrite(roles)) {
      return {
        h1: 'Add Batch Data',
        h2: 'Select Materials and Customize Template',
      }
    } else {
      return
    }
  }
}

/** Sets snackbar alert depending on error in AddBatchView.
 *  Should be extracted to its own component if more variants are required.
 */
export const setSnackbarAlert = (alert, onClose) => {
  switch (alert?.alertType) {
    case 'missingData':
      return (
        <Alert onClose={onClose} severity="error">
          The data could not be submitted. Please ensure that the following
          columns are included and do not contain empty values:
          {alert.missingCols?.map(col => (
            <Typography key={col}>{addBatchRequiredCols[col]}</Typography>
          ))}
        </Alert>
      )
    case 'editRowsMismatch':
      return (
        <Alert onClose={onClose} severity="error">
          The number of rows does not match the number of batches selected for
          editing. Please make sure that no rows have been added or removed
          before submitting.
        </Alert>
      )
    case 'duplicateTicketId':
      return (
        <Alert onClose={onClose} severity="error">
          The following Ticket IDs have duplicates in the spreadsheet:
          {alert.duplicateIds.map(id => (
            <Typography key={id}>{id}</Typography>
          ))}
          Please make sure that data for a single batch is contained in a single
          row.
        </Alert>
      )
    case 'kelownaIdChanged':
      return (
        <Alert onClose={onClose} severity="error">
          Kelowna ID acts as a reference number for our system and cannot be
          changed.<br></br>
          {alert.kelownaIdChanges.map(entry => {
            return (
              <Typography key={entry.originalKelownaId}>
                Ticket ID {entry.ticketId} for {entry.mixCode} should have the
                Kelowna ID {entry.originalKelownaId}.
              </Typography>
            )
          })}
          Please update and resubmit.
        </Alert>
      )
    default:
      return null
  }
}

/** Extracts batch properties, materials, and strength readings into an object storing all of the
 *  respective columns, and a JSON storing the data itself. This function is used in representing
 *  batching data back into the entry spreadsheet from the backend.
 *
 * @param {Object} data - batch data sent from backend.
 * @param {Boolean} isImperial - boolean indicating whether batch should be converted to Imperial.
 * @param {Object} data.batchProperties - contains all batch properties (e.g. mix code, slump, condition, etc.) from backend.
 * @param {Object[]} data.strengthReadings - list of all strength readings objects from backend.
 * @param {{name: String, title: String, type: String, width: Number}[]} possibleBatchProps
 *      - list of all possible batch property columns for a given unit system, generated by the addUnitsToBatchPropsAddMode() function.
 * @param {{name: String, title: String, type: String, width: Number}[]} possibleMaterials - list of material columns, generated by addUnitsToMaterials().
 * @returns {Object} - returns matched column objects and JSON data for the add batch spreasheet.
 */
export const extractBatchData = (
  data,
  isImperial,
  possibleBatchProps,
  possibleMaterials,
  setStrengthAndMaterialCols,
  isEditable
) => {
  // Extract columns.
  const matchedPropCols = isEditable
    ? [{ title: 'Kelowna ID', name: 'batchTestSampleId', type: 'text' }]
    : []
  const matchedMaterials = new Set() //using a Set to avoid duplicates when multiple batches having the same materials
  const matchedReadings = []
  const rows = []

  data.forEach(batch => {
    // Only batch properties have empty values we want to remove.
    const extractedProperties = extractBatchSampleProperties(batch, isImperial)
    // Convert all values to imperial if required.
    if (isImperial) {
      convertBatchPropertiesToImperial(extractedProperties)
      convertBatchMaterialsToImperial(batch.batchIngredients)
      convertReadingsToImperial(batch.strengthReadings)
    } else {
      convertBatchMaterialsUnits(batch.batchIngredients)
    }

    // Match property columns.
    Object.keys(extractedProperties).forEach(col => {
      const matchedProp = possibleBatchProps.find(
        batchProp => batchProp.name === col
      )
      if (matchedProp && !matchedPropCols.find(p => p.name === col))
        matchedPropCols.push(matchedProp)
    })
    const batchRow = isEditable
      ? { batchTestSampleId: batch.batchTestSampleId, ...extractedProperties }
      : { ...extractedProperties }

    // Match materials.
    if (possibleMaterials.length) {
      batch.batchIngredients.forEach(batchMat => {
        const matchedMaterial = extractBatchMaterial(
          batchMat,
          possibleMaterials,
          batchRow
        )
        matchedMaterials.add(matchedMaterial)
      })
    }
    // Match strength readings.
    batch.strengthReadings.forEach(reading => {
      const readingCol = extractBatchReading(reading)
      batchRow[readingCol.name] = reading.batchStrength

      if (!matchedReadings.find(r => r.name === readingCol.name)) {
        addNewSpreadsheetColumn(
          readingCol,
          setStrengthAndMaterialCols,
          'strengthReadings'
        )
        matchedReadings.push(readingCol)
      }
    })
    // Add data to list of spreadsheet rows.
    rows.push(batchRow)
  })

  return [
    {
      properties: matchedPropCols,
      materials: [...matchedMaterials], //convert Set into an array
      strengthReadings: matchedReadings,
    },
    rows,
  ]
}

/** Extract properties from a batch sample API object.
 * @param apiBatchObject - batch sample object coming from Kelowna.
 * @param isImperial - bool indicating whether the values should be converted to imperial units.
 */
export const extractBatchSampleProperties = (apiBatchObject, isImperial) => {
  const extractedProperties = removeEmptyFromObject(
    apiBatchObject.batchProperties
  )

  // Get presentational names for test columns if they are present.
  const testCategory = extractedProperties.testCategory
  if (testCategory) {
    // If DotDurabilityStudy is found, convert to Regulatory.
    // Kelowna will eventually migrate all of these properties to Regulatory in the backend
    if (testCategory === 'DotDurabilityStudy') {
      extractedProperties.testCategory = testCategories['Regulatory']
    } else {
      extractedProperties.testCategory = testCategories[testCategory]
    }
  }

  // Use != because the values can be falsy.
  if (extractedProperties.testingCondition != null) {
    extractedProperties.testingCondition = extractedProperties.testingCondition
      ? 'Job Field Site'
      : 'Concrete Plant'
  }
  if (extractedProperties.curingConditions != null) {
    extractedProperties.curingConditions = extractedProperties.curingConditions
      ? 'Field'
      : 'Lab'
  }

  // Extract dosage as percentage of cementitious or mL/oz.
  if (extractedProperties.cO2Dosage != null) {
    if (extractedProperties.cO2DosageUnit === 'PercentOfCement') {
      extractedProperties.CO2Dose = extractedProperties.cO2Dosage
    } else {
      extractedProperties.CO2DoseAbsolute = extractAbsoluteCO2Dose(
        extractedProperties.cO2Dosage,
        isImperial
      )
    }
  }

  // Kelowna expects "CO2" and "Control" as possible values for the isC02Design property
  // This changes "Control" to "NOCO2" while maintaining Kelowna compatibility
  if (extractedProperties.isCO2Design === 'Control') {
    extractedProperties.isCO2Design = 'No CO2'
  } else {
    extractedProperties.isCO2Design = 'CO2'
  }

  return extractedProperties
}

/** Creates a unique key to using material type and unit in order to track multiples of the same material type. */
export const determineColumnKey = batchMaterial => {
  /** Cement type and fly ash class are stored in batchMaterial.type, however all cement colomns and
   *  all fly ash columns need to share the same column key to prevent adding additional columns for each sub type. */
  if (batchMaterial.type.includes('Cement')) {
    return 'Cement' + batchMaterial.unit
  } else if (batchMaterial.type.includes('FlyAsh')) {
    return 'FlyAsh' + batchMaterial.unit
  } else {
    return batchMaterial.type + batchMaterial.unit
  }
}

/** Checks all batch properties that depend on a unit system, and converts their values to Imperial values. */
export const convertBatchPropertiesToImperial = batchProps => {
  /** Comparisons are made with != so that undefined and null resolve to false, but 0 resolves true. */
  if (batchProps.loadSize != null)
    batchProps.loadSize = ConvertM3ToYd3(batchProps.loadSize)
  if (batchProps.concreteTemp != null)
    batchProps.concreteTemp = ConvertCtoF(batchProps.concreteTemp)
  if (batchProps.ambientTemp != null)
    batchProps.ambientTemp = ConvertCtoF(batchProps.ambientTemp)
  if (batchProps.slump != null)
    batchProps.slump = ConvertMMtoINCH(batchProps.slump)
  if (batchProps.unitWeight != null)
    batchProps.unitWeight = ConvertKgPerM3ToLbPerFt3(batchProps.unitWeight)
}

/** Checks all batch materials, converts their values to Imperial values or converts Kelowna units to frontend units. */
export const convertBatchMaterialsToImperial = batchMats => {
  batchMats.forEach(mat => {
    if (mat.unit === 'Kg') {
      mat.quantity = ConvertKgToLb(mat.quantity)
      mat.unit = 'lb'
    } else if (admixtureNames.includes(mat.type) || !mat.type) {
      mat.quantity = ConvertLitreToOz(mat.quantity)
      mat.unit = 'oz'
    } else if (mat.unit === 'Litre') {
      mat.quantity = ConvertLitreToGal(mat.quantity)
      mat.unit = 'gal'
    } else {
      const unitToMatch = mat.unit
      const unitTitle = batchMaterialUnits.Imperial.find(
        unit => unit.unitName === unitToMatch
      )?.unitTitle
      mat.unit = unitTitle
    }
  })
}

/** Loops through materials and converts admix materials to mL from L.
 *  Also changes unit label for water from "Litres" to "L" that it can be matched.
 *  Also converts kelowna units to frontend units to display
 */
export const convertBatchMaterialsUnits = batchMats => {
  batchMats.forEach(mat => {
    if (
      (admixtureNames.includes(mat.type) && mat.unit === 'Litre') ||
      !mat.type
    ) {
      mat.quantity *= 1000
      mat.unit = 'mL'
    } else if (mat.type === 'Water' || mat.unit === 'Litre') mat.unit = 'L'
    else {
      const unitToMatch = mat.unit
      const unitTitle = batchMaterialUnits.Metric.find(
        unit => unit.unitName === unitToMatch
      )?.unitTitle
      mat.unit = unitTitle
    }
  })
}

/** Loops through readings and converts them from MPa to PSI. */
export const convertReadingsToImperial = readings => {
  readings.forEach(
    reading =>
      (reading.batchStrength = ConvertMPAToPSIRounded(reading.batchStrength))
  )
}

export const extractAbsoluteCO2Dose = (dosageAmount, isImperial) => {
  if (isImperial) return ConvertLitreToOz(dosageAmount)
  // Convert litre to mL.
  else return dosageAmount * 1000
}

/** Extract material data from a backend object into columns and JSON data for the add batch spreadsheet.
 *
 * @param {{type: String, unit: String}} material - material object retrieved from the backend in API call.
 * @param {{name: String, title: String, type: String, width: Number}} possibleMaterials
 *      - list of all possible material columns for a given unit system, generated by addUnitsToMaterials().
 * @param {Object} batchRow - spreadsheet row to add material data to. They keys are generated dynamically, depending on what data is retrieved from backend.
 * @returns {Object} - returns the matched column object from possibleMaterials.
 */
export const extractBatchMaterial = (material, possibleMaterials, batchRow) => {
  const matchedMaterial = possibleMaterials.find(
    mat => mat.materialId === material.materialId
  )

  if (matchedMaterial) {
    batchRow[`${matchedMaterial.name}Unit`] = material.unit
    batchRow[matchedMaterial.name] = material.quantity
  } else {
    //error handling when no match - there is missing material at kelowna endpoint
    console.log(
      `Error: sampleId:${batchRow.batchTestSampleId} Material: ${material.materialName} (materialId:${material.materialId}) has no match!`
    )
  }
  return matchedMaterial
}

export const extractBatchReading = reading => {
  const readingCol = {
    type: 'numeric',
    width: spreadsheetColumnWidth.large,
    columnNumber: reading.testNumber,
  }

  if (reading.batchIntervalDay < 1) {
    readingCol.name = `${reading.batchIntervalHour}hourStrength${reading.testNumber}`
    readingCol.intervalType = 'Hour Intervals'
    readingCol.title = `${reading.batchIntervalHour} Hour Strength`
    readingCol.intervalDuration = reading.batchIntervalHour
  } else {
    readingCol.name = `${reading.batchIntervalDay}dayStrength${reading.testNumber}`
    readingCol.intervalType = 'Day Intervals'
    readingCol.title = `${reading.batchIntervalDay} Day Strength`
    readingCol.intervalDuration = reading.batchIntervalDay
  }
  if (reading.testNumber > 1) {
    readingCol.title += ` (${reading.testNumber})`
  }

  return readingCol
}

/** Function to validate batch data extracted from the batch entry spreadsheet.
 *  Used in AddBatchView, triggered when a user hits the submit button.
 *
 * @param {Object[]} spreadSheetData - data extracted from entry spreadsheet. Each element in the array represents one row.
 * @param {Object} selectedCols - columns object from AddBatchView column
 * @param {Object[]} selectedCols.properties - list of property columns selected in AddBatchView.
 * @param {Object[]} selectedCols.materials - list of material columns selected in AddBatchView.
 * @param {Object[]} selectedCols.strengthReadings - list of strength reading columns selected in AddBatchView.
 * @param {Array} plants - list of plants used in spreadsheet dropdown, used to match plantId to selected plant name.
 * @param {Array} batchTestSampleIds - batches being edited. Length needs to match number of rows if in edit mode.
 * @param {Array} kelownaIdsInOrder - batches being edited. An array of kelowna IDs in the order returned by the API. Also the order they're entered in the spreadsheet.
 * @returns {{alertType: String, missingCols: Array batchData: Object[]}} - returns alertType string to trigger specific snackbar alert if data fails validation.
 *          - If the alertType is 'missingData', then an additional property (missingCols) is returned with an array of columns that still require data.
 *          - If data passes validation, alert is returned as null and backend-ready batch data is returned.
 */
export const validateSpreadsheetData = (
  spreadSheetData,
  selectedCols,
  plants,
  batchTestSampleIds = null,
  kelownaIdsInOrder = []
) => {
  // Remove all empty rows first.
  const dataNoEmptyRows = spreadSheetData.filter(row =>
    Object.keys(row).some(col => row?.[col] !== '')
  )

  // Raise alert if no rows left.
  if (dataNoEmptyRows.length < 1) {
    return { alertType: 'missingData' }
  }

  // Alert mismatch if number of rows is not equal to selected batches in edit mode.
  if (
    batchTestSampleIds &&
    batchTestSampleIds.length !== 0 &&
    dataNoEmptyRows.length !== batchTestSampleIds.length
  ) {
    return { alertType: 'editRowsMismatch' }
  }

  const missingRequiredCols = new Set()
  const ticketIds = {}
  let changedKelownaId = false
  const kelownaIdChanges = []
  dataNoEmptyRows.forEach((row, index) => {
    // An array of values of all CO2 material columns for the row that's currently being checked
    let AllCO2MaterialColumns = [
      row.CO2Oz1,
      row.CO2OzPerHundredWeight1,
      row.CO2OzPerYd31,
      row.CO2Ml1,
      row.CO2MlPerHundredWeight1,
      row.CO2MlPerM31,
    ]
    // An array of values of all C02 material columns that do not force Load Size to be a required column
    let nonLoadSizeCO2MaterialColumns = [
      row.CO2OzPerHundredWeight1,
      row.CO2OzPerYd31,
      row.CO2MlPerHundredWeight1,
      row.CO2MlPerM31,
    ]

    // Check that every row has an entry for the required fields.
    Object.keys(addBatchRequiredCols).forEach(reqCol => {
      if (cellIsEmpty(row, reqCol)) {
        missingRequiredCols.add(reqCol)
      }
    })

    if (batchTestSampleIds?.length > 0) {
      if (kelownaIdsInOrder[index] !== Number(row.batchTestSampleId)) {
        kelownaIdChanges.push({
          ticketId: row.ticketId,
          mixCode: row.mixCode,
          originalKelownaId: kelownaIdsInOrder[index],
        })
        changedKelownaId = true
      }
    }
    // Removes Plant from required fields if Test Category is CarbonCure Lab
    if (row.testCategory === testCategories.CarbonCureResearch) {
      missingRequiredCols.delete('plant')
    }

    // Removes CO2 Dose (%) from required fields if any of the other CO2 material columns have a value
    if (!row.CO2Dose && anyCellHasValue(AllCO2MaterialColumns)) {
      missingRequiredCols.delete('CO2Dose')
    }

    // Checks if CO2 oz/ml is the only value of all CO2 column entries
    if (
      !row.CO2Dose &&
      (row.CO2Oz1 || row.CO2Ml1) &&
      !allCellsHaveValue(nonLoadSizeCO2MaterialColumns) &&
      !row.loadSize
    ) {
      missingRequiredCols.add('loadSize')
    }

    // Check for duplicate Ticket IDs across rows.
    if (ticketIds[row.ticketId]) ticketIds[row.ticketId]++
    else ticketIds[row.ticketId] = 1
  })

  // Adds 'Load Size' to the list of required columns so it properly populates the Alert List
  if (missingRequiredCols.has('loadSize')) {
    addBatchRequiredCols.loadSize = 'Load Size'
  }

  const duplicateIds = Object.keys(ticketIds).filter(id => ticketIds[id] > 1)
  if (missingRequiredCols.size) {
    return { alertType: 'missingData', missingCols: [...missingRequiredCols] }
  } else if (duplicateIds.length) {
    return { alertType: 'duplicateTicketId', duplicateIds }
  } else if (changedKelownaId) {
    return { alertType: 'kelownaIdChanged', kelownaIdChanges }
  } else {
    return {
      batchData: AddBatchViewLogic.createBatchDataPostObject(
        dataNoEmptyRows,
        selectedCols,
        plants
      ),
    }
  }
}

/** Validation helper function. Returns true if table row object has a value for the specified column key. */
export const cellIsEmpty = (row, col) => row[col] == null || row[col] === ''

/** Helper to check if any element in array has a value that is not undefined */
export const anyCellHasValue = array =>
  array.some(element => element !== undefined)

/** Helper to check if all elements in array have a value */
export const allCellsHaveValue = array =>
  array.every(element => element !== undefined)

/** Post object creation helper function. Parses a number from a number or string, accounts for commas. */
export const extractNumberFromCell = text => {
  const parsableText = typeof text === 'string' ? text.replace(/,/g, '') : text
  return Number(parsableText)
}

/** Create batch data object to send to backend.
 *
 * @param {Object[]} data - data extracted from entry spreadsheet, after cleaning/validation in validateSpreadsheetData()
 * @param {Object} selectedCols - columns object from AddBatchView column
 * @param {Object[]} selectedCols.properties - list of property columns selected in AddBatchView.
 * @param {Object[]} selectedCols.materials - list of material columns selected in AddBatchView.
 * @param {Object[]} selectedCols.strengthReadings - list of strength reading columns selected in AddBatchView.
 * @param {Array} plants - list of plants used in spreadsheet dropdown, used to match plantId to selected plant name.
 * @returns {Object[]} - array of batch data objects, each element in array corresponds to a different batch.
 */
export const createBatchDataPostObject = (data, selectedCols, plants) =>
  data.map(row => {
    // Gather and format materials.
    const batchMaterials = []

    selectedCols.materials.forEach(col => {
      if (!cellIsEmpty(row, col.name)) {
        const frontendUnit = row[`${col.title}Unit`]
        const allUnits = Object.values(batchMaterialUnits).flatMap(
          units => units
        ) //Both imperial and metric units
        const kelownaUnit = allUnits.find(
          unit => unit.unitTitle === frontendUnit
        )?.unitName
        const batchMaterial = {
          amount: extractNumberFromCell(row[col.name]),
          unit: kelownaUnit,
          materialName: col.title,
          ...(col.materialId
            ? { materialId: col.materialId }
            : { materialType: col.materialType }),
        }

        batchMaterials.push(batchMaterial)
      }
    })

    // Gather and format strength readings.
    const batchReadings = []
    selectedCols.strengthReadings.forEach(col => {
      if (!cellIsEmpty(row, col.name)) {
        const batchReading = {
          compressiveStrength: extractNumberFromCell(row[col.name]),
          intervalType: col.intervalType === 'Day Intervals' ? 'Days' : 'Hours',
          intervalDuration: col.intervalDuration,
          testNumber: col.columnNumber,
          externalTicketId: row.ticketId,
        }
        batchReadings.push(batchReading)
      }
    })
    return {
      ...extractPropertiesFromSpreadsheetRow(row, selectedCols, plants),
      batchIngredients: batchMaterials,
      batchStrengthReadings: batchReadings,
    }
  })

/** Used to translate a presentational fly ash name to its backend material type.
 * @param {string} flyAshClass - presentational string to match to backend type.
 */
export const translateFlyAshClassToMaterialType = flyAshClass => {
  if (!flyAshClass) {
    return flyAshClassDropdown['No Class']
  } else {
    return flyAshClassDropdown[flyAshClass]
  }
}

/** Function extracts batch properties (e.g. ticketId, mixCode, slump, etc.) from a
 *  a spreasheet row and prepares them for the post object when saving data.
 * @param row - row object coming directly from spreadsheet.
 * @param selectedCols - array of column objects selected in AddBatchView
 * @param plants - array of plant objects used to match selected plant with its plantId
 */
export const extractPropertiesFromSpreadsheetRow = (
  row,
  selectedCols,
  plants
) => {
  const batchProperties = {}
  selectedCols.properties.forEach(col => {
    if (row[col.name] !== '') {
      batchProperties[col.name] =
        col.type === 'numeric'
          ? extractNumberFromCell(row[col.name])
          : row[col.name]
    }
  })

  // Formatting for backend.
  batchProperties.ticketId = batchProperties.ticketId.toString()
  batchProperties.productionDate = batchProperties.productionDate.split(' ')[0]

  // Mark test category as commissioning trial for Kelowna.
  if (batchProperties.isCommissioningTrial === 'TRUE') {
    batchProperties.testCategory = 'CommissioningTrial'
  } else {
    batchProperties.testCategory = getKeyByValue(
      testCategories,
      batchProperties.testCategory
    )
  }
  delete batchProperties.isCommissioningTrial

  batchProperties.isCO2Design = batchProperties.isCO2Design === 'CO2'
  if (batchProperties.wasTestedInField) {
    batchProperties.wasTestedInField =
      batchProperties.wasTestedInField === 'Job Field Site'
  }
  if (batchProperties.wasCuredInField) {
    batchProperties.wasCuredInField =
      batchProperties.wasCuredInField === 'Field'
  }
  if (batchProperties.plant) {
    const selectedPlant = plants.find(
      plant => plant.name === batchProperties.plant
    )
    batchProperties.plantId = selectedPlant?.plantId

    // Remove property to avoid sending unnecessary data to server.
    delete batchProperties.plant
  }

  return batchProperties
}

/** Sort Strength Readings first by type (Hour, Day), then by duration, then by name if have same duration */
export const sortStrengthReadings = (a, b) => {
  return (
    b.intervalType.localeCompare(a.intervalType) ||
    a.intervalDuration - b.intervalDuration ||
    a.name.localeCompare(b.name)
  )
}

export const isColumnReadOnly = (isViewOnly, colName) => {
  return isViewOnly || colName === 'batchTestSampleId'
}
