import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { ChangeEvent } from 'react'
import { IAutocompleteOption } from '../../Interfaces/EchoInterfaces'

interface IComponentProps {
  handleChange: (
    event: ChangeEvent<{}>,
    value: IAutocompleteOption | null
  ) => void
  options: Array<IAutocompleteOption>
}

function GroupNameSelectPresentational(props: IComponentProps) {
  const { handleChange, options } = props

  return (
    <Autocomplete
      id="groupNameSelect"
      onChange={handleChange}
      options={options}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.label === value.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Group name"
          variant="outlined"
          size="small"
          placeholder="Select a group name..."
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default GroupNameSelectPresentational
