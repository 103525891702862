import React, { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
} from '@material-ui/core'
import {
  createStrengthIntervals,
  addUnitsToBatchPropsAddMode,
  getAllMatCols,
  setPlantDropdownOptions,
  addUnitsToBatchPropsEditMode,
} from '../Logic/AddBatchColumnSelectLogic'
import {
  addBatchRequiredCols,
  editBatchRequiredCols,
  addBatchInstructionList,
} from '../Constants/AddDataConstants'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import AddBatchSpreadsheet from '../Components/AddBatchSpreadsheet'
import AddBatchConfirmDialog from '../Components/AddBatchConfirmDialog'
import AddBatchColumnSelect from '../Components/AddBatchColumnSelect'
import AddDataProgressLostAlertLogical from '../Components/AddDataProgressLostAlert/AddDataProgressLostAlertLogical'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { atomCustomerOptions } from '../../Common/tssAtomsB'
import { useRecoilValue, useRecoilState } from 'recoil'
import CustomerAndPlantSelect from '../Components/CustomerAndLocationSelect'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import {
  fetchBatchesByBatchTestSampleIds,
  getMaterialsByDivisionId,
  getNewSettings,
  getSupplierNames,
} from '../Data/TSSDataHelpers'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import EditModeAlert from '../Components/EditModeAlert'
import { Helmet } from 'react-helmet'
import {
  extractBatchData,
  setHeadersText,
  setSnackbarAlert,
  validateSpreadsheetData,
  sortStrengthReadings,
} from '../Logic/AddBatchViewLogic'
import { tssCanWrite, digestMaterials } from '../Logic/TSSLogic'
import isEqual from 'lodash.isequal'
import { AddDefaultMaterialsType } from '../Logic/Types'
import { useAddDefaultMaterials } from '../Logic/Hooks'
import GeneralList from '../../Common/Components/GeneralList/GeneralList'

/* Custom styling for page. */
const useStyles = makeStyles({
  mainContainer: {
    textAlign: 'left',
    paddingLeft: '0.5em',
  },
  materialsContainer: {
    marginTop: '1em',
  },
  comfirmButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2em',
    paddingBottom: '2em',
  },
})

/** Instantiate columns. */
const strengthIntervals = createStrengthIntervals()
const batchPropertiesAddMode = addUnitsToBatchPropsAddMode()
const batchPropertiesEditMode = addUnitsToBatchPropsEditMode()

/** Define form schema. Validate whether customer and location fields are filled. */
const addBatchSchema = yup.object().shape({
  customer: yup.string().when('isCustomerDropdownDisabled', {
    is: false,
    then: yup.string().required('Please select a customer'),
    otherwise: yup.string(),
  }),
})

/**
 * Page for adding batch actual data (strength tests, fresh properties, and batch materials).
 */
function AddBatchView() {
  const classes = useStyles()
  const { search } = useLocation()

  /** Get recoil values. */
  const [currentCustomer, setCurrentCustomer] = useRecoilState(
    atomCurrentCustomer
  )
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const [addDataSettings, setAddDataSettings] = useState({
    divisionId: currentCustomer.division?.divisionId
      ? [currentCustomer.division?.divisionId]
      : [],
    plantId: currentCustomer.plant?.plantId
      ? [currentCustomer.plant?.plantId]
      : [],
    mixCode: [],
    mixDesignId: [],
    showArchived: [],
  })
  const JWT = useRecoilValue(atomJWT)
  /** State variable unit system. */
  const [unitsSystem, setUnitsSystem] = useState('Imperial')
  const [hasUserInput, setHasUserInput] = useState(false)

  /**  Initialize customer options dropdown */
  useEffect(() => {
    if (customerOptions.length === 0) {
      getDivisionNames(true)
        .then(res => {
          if (res.ok)
            res.json().then(data => {
              setCustomerOptions(data)
            })
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  /** Only used to retrieve data from spreadsheet on save. */
  const [batchData, setBatchData] = useState([])

  /** Set customer plants for dropdown. */
  const [plants, setPlants] = useState([])

  /** Set cement plants. */
  const [cementPlants, setCementPlants] = useState([])
  useEffect(() => {
    getSupplierNames()
      .then(result => {
        if (result.ok)
          result.json().then(data => {
            setCementPlants(data)
          })
      })
      .catch(e => console.error(e))
  }, [])

  /** Track strength reading columns to dynamically add new columns for same-day tests. */
  const [strengthAndMaterialCols, setStrengthAndMaterialCols] = useState({
    materials: [],
    strengthReadings: strengthIntervals,
  })
  const batchMaterials = strengthAndMaterialCols.materials

  /** Instantiate form validation. */
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addBatchSchema),
  })

  /** State variable for data. */
  const [dataSubmitAlert, setDataSubmitAlert] = useState(null)
  const closeDataSubmitAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setDataSubmitAlert(null)
  }

  const params = new URLSearchParams(search)
  const [isEditMode, setIsEditMode] = useState(false)
  /** Check if any batches were passed for editing and set corresponding headings. */
  const batchTestSampleIds = params
    .getAll('batchTestSampleIds')
    .map(x => Number(x))
  const redirect = batchTestSampleIds.length === 0 && !tssCanWrite(JWT.roles)
  const isEditable = isEditMode && batchTestSampleIds.length > 0
  const isViewOnly = !isEditMode && batchTestSampleIds.length > 0
  const [isFirstRender, setIsFirstRender] = useState(true)
  const headersText = setHeadersText(
    isEditMode,
    batchTestSampleIds.length > 0,
    JWT.roles
  )
  const [dataForEdit, setDataForEdit] = useState([])
  const [detailsForEdit, setDetailsForEdit] = useState({
    plantId: null,
    divisionId: null,
  })
  const [originalKelownaIds, setOriginalKelownaIds] = useState([])

  /** Initialize spreadsheet columns and data. */
  const [selectedCols, setSelectedCols] = useState(() => {
    const defaultColNames = [
      ...Object.keys(isEditable ? editBatchRequiredCols : addBatchRequiredCols),
      'loadSize',
      'Co2Dose',
      'airContent',
      'slump',
      'unitWeight',
      'wcRatio',
      'notes',
    ]
    const currentBatchProperties = isEditable
      ? batchPropertiesEditMode
      : batchPropertiesAddMode
    return {
      // Set default columns.
      properties: currentBatchProperties[unitsSystem].filter(property =>
        defaultColNames.includes(property.name)
      ),
      materials: [],
      strengthReadings: [],
    }
  })

  useEffect(() => {
    //Retrieve currentCustomer from sessionStorage since recoil can't carry over to new tab
    const jsonCurrentCustomer = sessionStorage.getItem('currentCustomer')
    if (!jsonCurrentCustomer) return
    const currentCustomerStringified = JSON.parse(jsonCurrentCustomer)
    setCurrentCustomer(currentCustomerStringified)
    setAddDataSettings(prevSettings => ({
      ...prevSettings,
      divisionId: [currentCustomerStringified.division.divisionId],
    }))
    //Clear sessionStorage to avoid conflict with other data
    sessionStorage.removeItem('currentCustomer')
  }, [setCurrentCustomer])

  /** Init on url params */
  useEffect(() => {
    if (isFirstRender) {
      // FIRST RENDER
      const params = new URLSearchParams(search)

      const newSettings = getNewSettings(addDataSettings, params)

      // conditionally update settings
      if (!isEqual(newSettings, addDataSettings)) {
        setAddDataSettings(newSettings)
      }
      setIsFirstRender(false)
    }
  }, [addDataSettings, setAddDataSettings, isFirstRender, search])

  /** Get batches if IDs were passed from another page. */
  useEffect(() => {
    if (batchTestSampleIds.length === 0) return

    if (detailsForEdit.divisionId === null && batchMaterials.length) {
      fetchBatchesByBatchTestSampleIds({ batchTestSampleIds })
        .then(res => {
          if (res.ok)
            res.json().then(data => {
              // Set division and unit system.
              const newSystem = data.isImperial ? 'Imperial' : 'Metric'
              const divisionId = data.divisionId
              const kelownaIds = []
              const isEditable = batchTestSampleIds.length > 0
              const currentBatchProperties = isEditable
                ? batchPropertiesEditMode
                : batchPropertiesAddMode
              setUnitsSystem(newSystem)
              setDetailsForEdit({ divisionId })

              setAddDataSettings(prev => {
                return {
                  ...prev,
                  divisionId: [divisionId],
                }
              })

              const [matchedColumns, matchedData] = extractBatchData(
                data.batchTestSamples,
                data.isImperial,
                currentBatchProperties[newSystem],
                batchMaterials,
                setStrengthAndMaterialCols,
                isEditable
              )
              matchedData.forEach(entry => {
                kelownaIds.push(entry.batchTestSampleId)
              })
              setSelectedCols(matchedColumns)
              setDataForEdit(matchedData)
              setOriginalKelownaIds(kelownaIds)
            })
        })
        .catch(e => console.error(e))
    }
  }, [
    batchTestSampleIds,
    detailsForEdit.divisionId,
    isEditable,
    batchMaterials,
    setAddDataSettings,
  ])

  /** Set units when customer division is changed */
  useEffect(() => {
    if (!currentCustomer.division) return
    const newSystem = currentCustomer.division.isImperial
      ? 'Imperial'
      : 'Metric'
    setUnitsSystem(newSystem)
  }, [currentCustomer.division])

  /** Clear material dropdown & selected materials when clearing customer or customer is changed. */
  useEffect(() => {
    if (!currentCustomer.division?.divisionId) {
      setStrengthAndMaterialCols(prevCols => ({
        ...prevCols,
        materials: [],
      }))
    }
    //only reset selected materials when adding new batch
    if (!isEditMode && !isViewOnly)
      setSelectedCols(prevCols => ({
        ...prevCols,
        materials: [],
      }))
  }, [currentCustomer.division?.divisionId, isEditMode, isViewOnly])

  /** Set properties to match unit system. */
  useEffect(() => {
    const currentBatchProperties = isEditable
      ? batchPropertiesEditMode
      : batchPropertiesAddMode
    setSelectedCols(prevSelection => ({
      ...prevSelection,
      properties: currentBatchProperties[unitsSystem].filter(batchProp =>
        prevSelection.properties.find(
          selected => selected.name === batchProp.name
        )
      ),
    }))
  }, [unitsSystem, isEditable])

  /** Set plants as location dropdown in spreadsheet when data/unit/mode changes. */
  useEffect(() => {
    if (!plants.length) return
    setPlantDropdownOptions(plants, setSelectedCols)
  }, [plants, dataForEdit, unitsSystem, isEditMode])

  /** Get materials of the selected customer */
  useEffect(() => {
    if (!addDataSettings.divisionId.length) return

    getMaterialsByDivisionId(Number(addDataSettings.divisionId[0]))
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            const digestedMaterials = digestMaterials(data)
            setStrengthAndMaterialCols(prevCols => ({
              ...prevCols,
              materials: digestedMaterials,
            }))
          })
      })
      .catch(e => console.log(e))
  }, [addDataSettings.divisionId])

  /** Custom hook to check if there is default cement / default CO2, if not, add to materials */
  useAddDefaultMaterials(
    strengthAndMaterialCols.materials,
    currentCustomer,
    setStrengthAndMaterialCols,
    AddDefaultMaterialsType.AddBatch
  )

  /** Validate data and open dialog. */
  const submitData = () => {
    const validatedData = validateSpreadsheetData(
      batchData,
      selectedCols,
      plants,
      batchTestSampleIds,
      originalKelownaIds
    )

    // Raise alert if data fails validation.
    if (validatedData.alertType) {
      setDataSubmitAlert(validatedData)
    } else {
      setBatchData(validatedData.batchData)
      setOpenDialog(true)
    }
  }

  /** sort  the strength readings on selectedCols */
  const selectedColsCopy = { ...selectedCols }
  const strengthReadingsCopy = selectedColsCopy.strengthReadings
  strengthReadingsCopy.sort(sortStrengthReadings)

  /** Create dialog with summary card once data is submitted. */
  const [openDialog, setOpenDialog] = useState(false)

  const editPageTitle = tssCanWrite(JWT.roles) ? 'Edit Batches' : 'View Batches'
  const subheading = isViewOnly ? 'View Data' : 'Edit Data'

  if (redirect) return <Redirect to="/Concrete/AddData" />

  return (
    <Container
      className={classes.mainContainer}
      maxWidth="xl"
      style={{ padding: '0em 0.5em' }}
    >
      <Helmet>
        {batchTestSampleIds.length > 0 ? (
          <title>{editPageTitle}</title>
        ) : (
          <title>Add Batches</title>
        )}
      </Helmet>
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {batchTestSampleIds.length > 0 && (
              <EditModeAlert
                customer={currentCustomer}
                roles={JWT.roles}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">{headersText.h1}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomerAndPlantSelect
              register={register}
              errors={errors}
              setPlantsInParent={setPlants}
              inputsAlwaysEnabled={false}
              sizes={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              addDataSettings={addDataSettings}
              setAddDataSettings={setAddDataSettings}
              isViewOnly={isViewOnly || isEditMode}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">{headersText.h2}</Typography>
            {!isViewOnly && (
              <Typography>
                Select all of the relevant materials to update and customize the
                template.
              </Typography>
            )}
          </Grid>
          <Grid item container spacing={1}>
            <AddBatchColumnSelect
              setHasUserInput={setHasUserInput}
              selectedCols={selectedCols}
              setSelectedCols={setSelectedCols}
              strengthAndMaterialCols={strengthAndMaterialCols}
              setStrengthAndMaterialCols={setStrengthAndMaterialCols}
              unitsSystem={unitsSystem}
              setUnits={setUnitsSystem}
              plants={plants}
              roles={JWT.roles}
              isEditMode={isEditable}
              isViewOnly={isViewOnly}
              currentCustomer={currentCustomer}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {batchTestSampleIds.length > 0 ? subheading : 'Add Data'}
              </Typography>
              {!isViewOnly && (
                <>
                  <Typography>
                    Ensure the following instructions are followed for data
                    upload and entry. For questions, use the #info-orca-tss
                    Slack channel
                  </Typography>
                  {addBatchInstructionList.map(item => (
                    <GeneralList
                      key={item.id}
                      item={
                        <Typography key={item.id}>{item.listItem}</Typography>
                      }
                      listMargin={-0.8}
                    />
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <AddBatchSpreadsheet
                setHasUserInput={setHasUserInput}
                selectedCols={[
                  ...strengthReadingsCopy,
                  ...selectedCols.properties,
                  ...getAllMatCols(
                    selectedCols.materials,
                    cementPlants,
                    unitsSystem
                  ),
                ]}
                setSelectedCols={setSelectedCols}
                possibleCols={{
                  properties: isEditable
                    ? batchPropertiesEditMode[unitsSystem]
                    : batchPropertiesAddMode[unitsSystem],
                  strengthReadings: strengthIntervals,
                  materials: batchMaterials,
                }}
                batchData={batchData}
                saveData={setBatchData}
                setStrengthAndMaterialCols={setStrengthAndMaterialCols}
                updateData={dataForEdit}
                setUpdateData={setDataForEdit}
                cementPlants={cementPlants}
                search={search}
                roles={JWT.roles}
                isEditMode={isEditable}
                isViewOnly={isViewOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <AddBatchConfirmDialog
        batchData={batchData}
        open={openDialog}
        setOpen={setOpenDialog}
        materials={selectedCols.materials}
        unitsSystem={unitsSystem}
        batchTestSampleIds={batchTestSampleIds}
        setHasUserInput={setHasUserInput}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!dataSubmitAlert}
        autoHideDuration={12000}
        onClose={closeDataSubmitAlert}
      >
        {setSnackbarAlert(dataSubmitAlert, closeDataSubmitAlert)}
      </Snackbar>
      <AddDataProgressLostAlertLogical hasUserInput={hasUserInput} />
    </Container>
  )
}

export default AddBatchView
