import { useRecoilValue } from 'recoil'
import { atomSonarFilter } from '../../../Common/atoms'
import { ISonarFilter } from '../../../Common/Logic/Types'

/**
 * Custom React hook to get the current value of the sonar filter.
 *
 * This hook uses the Recoil library's `useRecoilValue` function to read the current state of the `atomSonarFilter` atom.
 * The `atomSonarFilter` atom represents the state of the sonar filter in the global Recoil state.
 *
 * @returns The current value of the sonar filter.
 */
export const useSonarFilter = () => {
  const sonarFilter: ISonarFilter = useRecoilValue(atomSonarFilter)
  return sonarFilter
}
