import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

function BatchNotes(props) {
  BatchNotes.propTypes = {
    /** Batch notes to display below the graph */
    batchNotes: PropTypes.array.isRequired,
  }
  const { batchNotes } = props

  if (batchNotes.length > 0) {
    return (
      <Grid item container direction="column" style={{ padding: '1em' }}>
        <Grid item style={{ marginBottom: '1em' }}>
          <Typography style={{ fontWeight: '700' }}>Batch Notes</Typography>
        </Grid>
        <Grid
          item
          container
          style={{
            background: '#F6F6F6',
            padding: '0.5em 0.5em 0em 1em',
            maxHeight: '100px',
            overflowY: 'auto',
          }}
        >
          {batchNotes.map((note, index) => {
            return (
              <Grid
                key={index}
                item
                container
                direction="column"
                style={{ marginBottom: '1em' }}
              >
                <Grid item container>
                  <Grid item style={{ marginRight: '1.5em' }}>
                    <Typography style={{ fontWeight: '600' }}>
                      Mix Code - {note.mixCode}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontWeight: '600' }}>
                      Ticket ID - {note.ticketId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                    {note.batchNote}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  } else return null
}

export default BatchNotes
