import React from 'react'
import { Typography, makeStyles, Button, Grid, Box } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import AccessAlarmsOutlinedIcon from '@material-ui/icons/AccessAlarmsOutlined'
import theme from '../../../theme/muiTheme'
import hooks from './UseAlarms'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  lastRefreshedText: {
    ...theme.typography.body2,
    fontStyle: 'italic',
    opacity: 0.6,
  },
  subTitle: {
    ...theme.typography.subtitle2,
  },
  tableWrapper: {
    height: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '8px',
  },
  button: { margin: '8px 0px' },
}))

const Alarms: React.FC = () => {
  const classes = useStyles()
  const { lastRefreshed } = hooks.useAlarms()

  return (
    <Grid container direction="column">
      <Grid item className={classes.container}>
        <Typography variant="h3" align="left" className={classes.header}>
          <AccessAlarmsOutlinedIcon className={classes.header} />
          Alarms
        </Typography>
        <Typography variant="body2" className={classes.lastRefreshedText}>
          Last Refreshed: {lastRefreshed}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.subTitle}>
          View and manage all alarms data. Down Systems are filtered out by
          default. If you wish to include Down Systems in the table, use the
          Filters panel above.
        </Typography>
      </Grid>
      <Grid item>
        <Box className={classes.tableWrapper}>{/* Table will be here */}</Box>
      </Grid>
      <Grid item className={classes.buttonWrapper}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<GetAppIcon />}
          className={classes.button}
        >
          Download Data
        </Button>
      </Grid>
    </Grid>
  )
}

export default Alarms
