import React, { useState, useEffect } from 'react'
import AlarmsManagementHomePresentational from './AlarmsManagementHomePresentational'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  atomCodeMapper,
  atomCurrentRemote,
  atomDateRange,
  atomSelectedTab,
  atomRegion,
} from '../../../Common/engAtoms'
import {
  getNewSettings,
  getUrlFromFilters,
} from '../../../TSS/Data/TSSDataHelpers'
import { useLocation } from 'react-router-dom'
import { injectionGraphOptions } from '../Constants/Constants'
import { removeActiveFilter } from '../../../Common/Helpers/GeneralHelpers'

function AlarmsManagementHomeLogical() {
  /** Sort data table rows by ascending or descending values */
  const [order, setOrder] = useState('desc')
  /** Indicate data table column by which to sort table rows */
  const [orderBy, setOrderBy] = useState('timestamp')
  /** Data table pagination */
  const [page, setPage] = useState(0)
  /** Set number of data table rows to display per page */
  const [rowsPerPage, setRowsPerPage] = useState(10)
  /** Alarm that has been selected on the Alarms table */
  const [selectedAlarm, setSelectedAlarm] = useState({})
  /** Service case that has been selected on the service case table */
  const [selectedServiceCase, setSelectedServiceCase] = useState(null)
  /** Boolean to determine whether the service case modal is open */
  const [open, setOpen] = useState(false)
  /** The value of the currently selected tab */
  const [tabValue, setTabValue] = useState(0)
  /** The name of the selected tab */
  const [selectedTab, setSelectedTab] = useRecoilState(atomSelectedTab)
  /** The service case displayed when the modal opens: existing or new */
  const [serviceCaseSource, setServiceCaseSource] = useState({})

  const [remotesWithPlants, setRemotesWithPlants] = useState([])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [remoteSelected, setRemoteSelected] = useRecoilState(atomCurrentRemote)
  const [dateRange, setDateRange] = useRecoilState(atomDateRange)
  const [region, setRegion] = useRecoilState(atomRegion)
  const alarmCodeMapper = useRecoilValue(atomCodeMapper)
  const { search } = useLocation()

  //Filter panel and associated states
  const [activeFilters, setActiveFilters] = useState([])
  const [panelOpen, setPanelOpen] = useState(false)
  const [expandFilters, setExpandFilters] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [showInactiveAlarms, setShowInactiveAlarms] = useState(true)
  const [showClosedCases, setShowClosedCases] = useState(false)
  const [systemDataType, setSystemDataType] = useState('mixerManifold')
  const defaultGraphVisibility = {
    cO2GraphVisible: true,
    upstreamPressureGraphVisible: true,
    temperatureGraphVisible: true,
    downstreamPressure0GraphVisible: true,
    downstreamPressure1GraphVisible: true,
    liquidGraphVisible: true,
    gasGraphVisible: true,
  }
  const [graphVisibility, setGraphVisibilty] = useState(defaultGraphVisibility)

  alarmCodeMapper.initializeInstance()

  useEffect(() => {
    const fetchAndSetRemoteUnits = async () => {
      const response = await AlarmsManagementDataHelpers.getRemoteUnits()
      if (response.ok) {
        const remotes = await response.json()
        setRemotesWithPlants(remotes)
      }
    }
    fetchAndSetRemoteUnits()
  }, [])

  /** Function to sort the data table rows */
  const handleTableSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Keep track of the name of the selected tab */
  useEffect(() => {
    if (tabValue === 3) {
      setSelectedTab('Injection Graphs')
    } else if (tabValue === 2) {
      setSelectedTab('System Data')
    } else if (tabValue === 1) {
      setSelectedTab('Service Cases')
    } else setSelectedTab('Alarms')
    // setServiceCaseSource({})
  }, [tabValue, setSelectedTab])

  /** When the service case modal opens, display an existing or blank service case depending on which tab is active */
  useEffect(() => {
    if (selectedTab === 'Service Cases') {
      setServiceCaseSource(selectedServiceCase)
    } else {
      setServiceCaseSource(selectedAlarm)
    }
  }, [selectedTab, selectedServiceCase, selectedAlarm])

  useEffect(() => {
    if (!isFirstRender) {
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(
          {
            tab: [selectedTab],
            startTime: dateRange.startTime ? [dateRange.startTime] : [],
            endTime: dateRange.endTime ? [dateRange.endTime] : [],
            plant: remoteSelected?.displayString
              ? [remoteSelected.displayString]
              : [],
            hmiId: remoteSelected?.remoteId ? [remoteSelected.remoteId] : [],
          },
          'AlarmsManagement'
        )
      )
    } else {
      // FIRST RENDER
      const params = new URLSearchParams(search)
      const newSettings = getNewSettings(
        {
          tab: [selectedTab],
          startTime: dateRange.startTime ? [dateRange.startTime] : [],
          endTime: dateRange.endTime ? [dateRange.endTime] : [],
          plant: remoteSelected?.displayString
            ? [remoteSelected.displayString]
            : [],
          hmiId: remoteSelected?.remoteId ? [remoteSelected.remoteId] : [],
        },
        params
      )
      setDateRange(currVal => ({
        ...currVal,
        startTime: newSettings.startTime[0],
        endTime: newSettings.endTime[0],
      }))
      setRemoteSelected(currVal => ({
        ...currVal,
        displayString: newSettings.plant[0],
        remoteId:
          newSettings.hmiId.length > 0 ? Number(newSettings.hmiId[0]) : null,
      }))
      const activeTab = newSettings.tab[0]
      setSelectedTab(activeTab)
      if (activeTab === 'Injection Graphs') {
        setTabValue(3)
      } else if (activeTab === 'System Data') {
        setTabValue(2)
      } else if (activeTab === 'Service Cases') {
        setTabValue(1)
      } else {
        setTabValue(0)
      }
      //setPlantsFiltersSettings(newSettings)
      setIsFirstRender(false)
    }
  }, [
    isFirstRender,
    dateRange,
    remoteSelected,
    search,
    setDateRange,
    setRemoteSelected,
    selectedTab,
    setSelectedTab,
  ])

  const filterButtonClickHandler = () => {
    setPanelOpen(prev => !prev)
  }

  useEffect(() => {
    let count = 0
    const activeFilters = []
    const commonFilters = [
      { property: 'location', value: remoteSelected.displayString },
      { property: 'startTime', value: dateRange.startTime },
      { property: 'endTime', value: dateRange.endTime },
    ]
    for (const filter of commonFilters) {
      if (filter.value) {
        let label = filter.value
        if (filter.property === 'startTime') {
          label = `Start Date: ${label}`
        } else if (filter.property === 'endTime') {
          label = `End Date: ${label}`
        }
        activeFilters.push({ property: filter.property, label: label })
        count++
      }
    }

    switch (tabValue) {
      case 0:
        const alarmsFilters = [
          { property: 'International', value: region.international },
          { property: 'US/Canada', value: region.canadaUS },
          { property: 'Inactive Alarms', value: showInactiveAlarms },
        ]
        for (const filter of alarmsFilters) {
          if (filter.value) {
            activeFilters.push({
              property: filter.property,
              label: filter.property,
            })
            count++
          }
        }
        break
      case 1:
        const serviceCasesFilters = [
          { property: 'International', value: region.international },
          { property: 'US/Canada', value: region.canadaUS },
          { property: 'Closed Cases', value: showClosedCases },
        ]
        for (const filter of serviceCasesFilters) {
          if (filter.value) {
            activeFilters.push({
              property: filter.property,
              label: filter.property,
            })
            count++
          }
        }
        break
      case 2:
        //System Data Filter is a radio button, does not count as filterCount
        break
      case 3:
        for (const option of injectionGraphOptions) {
          const filter = option.id
          if (graphVisibility[filter]) {
            activeFilters.push({ property: filter, label: option.name })
            count++
          }
        }
        break
      default:
        return null
    }
    setFilterCount(count)
    setActiveFilters(activeFilters)
  }, [
    remoteSelected,
    dateRange,
    tabValue,
    region,
    showInactiveAlarms,
    showClosedCases,
    graphVisibility,
  ])

  const filterPanelChangeHandler = (filter, value, option) => {
    switch (filter.property) {
      case 'location':
        setRemoteSelected({
          displayString: value?.name || null,
          remoteId: value?.id || null,
        })
        break

      case 'dateRange':
        if (option === 'startDate') {
          setDateRange(prev => ({
            ...prev,
            startTime: value || null,
          }))
        } else {
          setDateRange(prev => ({
            ...prev,
            endTime: value || null,
          }))
        }
        break

      case 'alarms':
      case 'serviceCases':
        if (option?.name === 'International') {
          setRegion(prev => ({
            ...prev,
            international: !prev.international,
          }))
        } else if (option?.name === 'US/Canada') {
          setRegion(prev => ({
            ...prev,
            canadaUS: !prev.canadaUS,
          }))
        } else {
          filter.property === 'alarms'
            ? setShowInactiveAlarms(!showInactiveAlarms)
            : setShowClosedCases(!showClosedCases)
        }
        break

      case 'systemData':
        setSystemDataType(value)
        break

      case 'injectionGraphs':
        setGraphVisibilty(prev => {
          return {
            ...prev,
            [option.id]: !prev[option.id],
          }
        })
        break

      default:
        return null
    }
  }

  const filterChipsHandler = filter => {
    setActiveFilters(prev => removeActiveFilter(prev, filter))

    switch (filter.property) {
      case 'location':
        setRemoteSelected({
          displayString: null,
          remoteId: null,
        })
        break
      case 'startTime':
        setDateRange(prev => ({
          ...prev,
          startTime: null,
        }))
        break
      case 'endTime':
        setDateRange(prev => ({
          ...prev,
          endTime: null,
        }))
        break
      case 'International':
        setRegion(prev => ({
          ...prev,
          international: false,
        }))
        break
      case 'US/Canada':
        setRegion(prev => ({
          ...prev,
          canadaUS: false,
        }))
        break
      case 'Inactive Alarms':
        setShowInactiveAlarms(false)
        break
      case 'Closed Cases':
        setShowClosedCases(false)
        break
      default:
        if (graphVisibility.hasOwnProperty(filter.property)) {
          setGraphVisibilty(prev => ({
            ...prev,
            [filter.property]: !prev[filter.property],
          }))
        }
        break
    }
  }

  const clearAllFilters = () => {
    setRemoteSelected({
      displayString: null,
      remoteId: null,
    })
    setDateRange({
      startTime: null,
      endTime: null,
    })

    switch (tabValue) {
      case 0:
      case 1:
        setRegion({
          international: false,
          canadaUS: false,
        })
        tabValue === 0
          ? setShowInactiveAlarms(false)
          : setShowClosedCases(false)
        break
      case 2:
        //reset to default
        setSystemDataType('mixerManifold')
        break
      case 3:
        setGraphVisibilty({
          cO2GraphVisible: false,
          upstreamPressureGraphVisible: false,
          temperatureGraphVisible: false,
          downstreamPressure0GraphVisible: false,
          downstreamPressure1GraphVisible: false,
          liquidGraphVisible: false,
          gasGraphVisible: false,
        })
        break
      default:
        return null
    }
  }

  const getValue = (filter, option) => {
    switch (filter.property) {
      case 'location':
        const selected = filter.options.find(
          option => option.id === remoteSelected.remoteId
        )
        return selected ? selected : null

      case 'dateRange':
        if (option === 'startDate') {
          return dateRange.startTime ? dateRange.startTime : ''
        } else {
          return dateRange.endTime ? dateRange.endTime : ''
        }

      case 'alarms':
      case 'serviceCases':
        if (option.name === 'International') return region.international
        else if (option.name === 'US/Canada') return region.canadaUS
        else
          return filter.property === 'alarms'
            ? showInactiveAlarms
            : showClosedCases

      case 'systemData':
        return systemDataType

      case 'injectionGraphs':
        return graphVisibility[option.id]

      default:
        return null
    }
  }

  return (
    <AlarmsManagementHomePresentational
      handleTableSort={handleTableSort}
      selectedAlarm={selectedAlarm}
      order={order}
      orderBy={orderBy}
      setSelectedAlarm={setSelectedAlarm}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      open={open}
      setOpen={setOpen}
      selectedServiceCase={selectedServiceCase}
      setSelectedServiceCase={setSelectedServiceCase}
      tabValue={tabValue}
      setTabValue={setTabValue}
      selectedTab={selectedTab}
      serviceCaseSource={serviceCaseSource}
      setServiceCaseSource={setServiceCaseSource}
      remoteUnitsWithPlants={remotesWithPlants}
      panelOpen={panelOpen}
      setPanelOpen={setPanelOpen}
      filterCount={filterCount}
      filterButtonClickHandler={filterButtonClickHandler}
      expandFilters={expandFilters}
      setExpandFilters={setExpandFilters}
      showInactiveAlarms={showInactiveAlarms}
      showClosedCases={showClosedCases}
      setShowClosedCases={setShowClosedCases}
      systemDataType={systemDataType}
      graphVisibility={graphVisibility}
      filterPanelChangeHandler={filterPanelChangeHandler}
      getValue={getValue}
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      filterChipsHandler={filterChipsHandler}
      clearAllFilters={clearAllFilters}
    />
  )
}

export default AlarmsManagementHomeLogical
