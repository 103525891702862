import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlyToInterpolator } from 'react-map-gl'
import { getFilteredPlantMarkers } from '../../Logic/MapLogicHelpers'
import { getMapMarkerZoom, getMarker } from './MapComponentHelpers'
import { mergeArraysByProperty } from '../../Logic/GeneralEchoLogicHelpers'
import { isFilterByCityActive } from '../../Logic/FilterLogicHelpers'
import MapComponentPresentational from './MapComponentPresentational'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
  atomAddressCoordinates,
  atomEchoSettings,
  atomPlantMarkers,
  atomMapViewportSettings,
} from '../../../Common/echoAtoms'
import { EMapLayers } from '../../Enums/EchoEnums'

function MapComponentLogical(props) {
  MapComponentLogical.propTypes = {
    /** Array that holds any data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** Flat array of companies, used for the map markers */
    companyMarkers: PropTypes.array.isRequired,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Filtered array of cement plants */
    filteredCementPlants: PropTypes.array,
    /** Coordinates of zip code that was selected in "FilterBy" */
    zipCodeCoordinates: PropTypes.array,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Filtered array of CO2 depots */
    filteredCO2Depots: PropTypes.array,
    /** Array of CO2 depots */
    co2Depots: PropTypes.array,
    /** Filtered array of sales opportunities */
    filteredSalesOpportunities: PropTypes.array,
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
  }
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  const addressCoordinates = useRecoilValue(atomAddressCoordinates)
  const filterByCountry =
    echoSettings.countries.length > 0 ? echoSettings.countries[0] : ''
  const filterByCity =
    echoSettings.cities.length > 0 ? echoSettings.cities[0] : ''
  const plantMarkers = useRecoilValue(atomPlantMarkers)
  const {
    selectedRows,
    companyMarkers,
    filteredUpcomingProjects,
    filteredCementPlants,
    zipCodeCoordinates,
    isLoading,
    filteredCO2Depots,
    co2Depots,
    filteredSalesOpportunities,
    filteredCorpData,
    readyMixTableData,
  } = props

  /** Viewport settings for the map */
  const setViewport = useSetRecoilState(atomMapViewportSettings)

  /** Customers for which we have CO2 supplier information */
  const [co2Customers, setCO2Customers] = useState([])
  /** CO2 depots that are supplying our customers */
  const [co2Suppliers, setCO2Suppliers] = useState([])
  /** Array of customers and CO2 depots to draw lines between on the map */
  const [co2CustomerConnections, setCO2CustomerConnections] = useState([])

  const [markerLocation, setMarkerLocation] = useState(null)

  /** When a country/state/city/zip code/address filter is selected, zoom in on the selected location. */
  useEffect(() => {
    let newLocation
    if (filterByCountry) {
      if (
        plantMarkers.find(plant => plant.country === filterByCountry) !==
        undefined
      ) {
        newLocation = plantMarkers.find(
          plant => plant.country === filterByCountry
        ).longLat
        setViewport({
          latitude: newLocation[1],
          longitude: newLocation[0],
          zoom: 2,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        })
      }
    } else if (echoSettings.states.length === 1) {
      if (
        plantMarkers.find(plant =>
          echoSettings.states.includes(plant.state)
        ) !== undefined
      ) {
        newLocation = plantMarkers.find(plant =>
          echoSettings.states.includes(plant.state)
        ).longLat
        setViewport({
          latitude: newLocation[1],
          longitude: newLocation[0],
          zoom: 5,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        })
      }
    } else if (echoSettings.states.length > 1) {
      setViewport({
        latitude: 10,
        longitude: -150,
        zoom: 1,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      })
    } else if (isFilterByCityActive(filterByCity)) {
      if (
        plantMarkers.find(
          plant => [plant.city, plant.state].join(', ') === filterByCity.label
        ) !== undefined
      ) {
        newLocation = filterByCity.longLat
        setViewport({
          latitude: newLocation[1],
          longitude: newLocation[0],
          zoom: 7,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        })
      }
    } else if (zipCodeCoordinates.length) {
      newLocation = zipCodeCoordinates
      setViewport({
        latitude: newLocation[1],
        longitude: newLocation[0],
        zoom: 6,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      })
    } else if (
      addressCoordinates.length &&
      echoSettings.layers.includes(EMapLayers.co2Depots)
    ) {
      newLocation = addressCoordinates
      setViewport({
        latitude: newLocation[1],
        longitude: newLocation[0],
        zoom: 9,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      })
    } else if (markerLocation?.length) {
      newLocation = markerLocation
      setViewport({
        latitude: newLocation[1],
        longitude: newLocation[0],
        zoom: getMapMarkerZoom(echoSettings.rmProducerRange),
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      })
    } else {
      setViewport(viewPort => {
        return {
          latitude: viewPort.latitude,
          longitude: viewPort.longitude,
          zoom: viewPort.zoom,
        }
      })
    }
  }, [
    plantMarkers,
    filterByCountry,
    filterByCity,
    zipCodeCoordinates,
    addressCoordinates,
    setViewport,
    echoSettings.states,
    echoSettings.layers,
    echoSettings.rmProducerRange,
    markerLocation,
  ])

  /** Update array of customers for which we have CO2 supplier information as filters are changed */
  useEffect(() => {
    setCO2Customers(
      getFilteredPlantMarkers(plantMarkers, echoSettings)
        .filter(plantMarker => plantMarker.cO2SupplierId !== null)
        .map(plantMarker => {
          return {
            customerLongLat: plantMarker.longLat,
            cO2SupplierId: plantMarker.cO2SupplierId,
          }
        })
    )
  }, [echoSettings, plantMarkers])

  /** Update array of CO2 depots that are supplying our customers as filters are changed */
  useEffect(() => {
    setCO2Suppliers(
      co2Depots
        .filter(depot =>
          co2Customers
            .map(customer => customer.cO2SupplierId)
            .includes(depot.cO2SupplierId)
        )
        .map(supplier => {
          return {
            supplierLongLat: [supplier.longitude, supplier.latitude],
            cO2SupplierId: supplier.cO2SupplierId,
          }
        })
    )
  }, [co2Customers, co2Depots])

  /** Update array of connections between CO2 depots and customers as filters are changed */
  useEffect(() => {
    setCO2CustomerConnections(
      mergeArraysByProperty(co2Suppliers, co2Customers, 'cO2SupplierId')
    )
  }, [co2Customers, co2Suppliers])

  const handleMarkerClick = getMarker(
    markerLocation,
    setMarkerLocation,
    echoSettings,
    setEchoSettings,
    setViewport
  )

  return (
    <MapComponentPresentational
      selectedRows={selectedRows}
      zipCodeCoordinates={zipCodeCoordinates}
      companyMarkers={companyMarkers}
      filteredUpcomingProjects={filteredUpcomingProjects}
      filteredCementPlants={filteredCementPlants}
      isLoading={isLoading}
      filteredCO2Depots={filteredCO2Depots}
      co2CustomerConnections={co2CustomerConnections}
      filteredSalesOpportunities={filteredSalesOpportunities}
      handleMarkerClick={handleMarkerClick}
      markerLocation={markerLocation}
      filteredCorpData={filteredCorpData}
      readyMixTableData={readyMixTableData}
    />
  )
}

export default MapComponentLogical
