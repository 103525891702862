import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AlarmIcon from './AlarmIcon'
import { getHighestAlarmLevel } from '../Logic/PCBLogic'
import { EnhancedTableHead } from './EnhancedTableHead'
import CollapsableRemoteInfo from './CollapsableRemotInfo'
import { Link } from 'react-router-dom'

const rowClasses = makeStyles(theme => ({
  revealedRow: {
    backgroundColor: '#E1E1E1',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    cursor: 'pointer',
  },
  highLightedRow: {
    backgroundColor: 'rgba(288,132,37,0.1)',
    '& > *': {
      borderBottom: 'unset',
    },
  },
}))

Row.propTypes = {
  masterUnit: PropTypes.object.isRequired,

  setSelectedMasterUnit: PropTypes.func.isRequired,
}

function Row(props) {
  const { masterUnit, setSelectedMasterUnit } = props
  const [open, setOpen] = useState(false)
  const classes = rowClasses()

  const handleClick = function(event, masterUnit) {
    setOpen(!open)
  }

  const remoteUnitsCollapsedView = (
    <CollapsableRemoteInfo
      expanded={open}
      setSelectedMasterUnit={() => {
        setSelectedMasterUnit(masterUnit)
      }}
      remotes={masterUnit.remotes}
      variant="management"
    />
  )

  return (
    <>
      <TableRow
        className={classes.root}
        onClick={event => {
          handleClick(event, masterUnit)
        }}
      >
        <TableCell align="center">
          <Typography>{masterUnit.corporationName}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{masterUnit.plantName}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{masterUnit.remotes.length}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{masterUnit.activeAlarms.length}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.revealedRow} colSpan={4}>
          {/* Collapse has an error/warning during strict mode from Material UI https://github.com/mui-org/material-ui/issues/20561  */}
          {remoteUnitsCollapsedView}
        </TableCell>
      </TableRow>
    </>
  )
}

function AlarmRow(props) {
  const { masterUnit, setSelected, setMasterUnit, index, selected } = props
  const classes = rowClasses()
  const alarmLevel = getHighestAlarmLevel(masterUnit.activeAlarms)
  const handleClick = function() {
    if (!selected) {
      setSelected(index)
      setMasterUnit(masterUnit)
    } else {
      setSelected(-1)
      setMasterUnit()
    }
  }

  return (
    <TableRow
      className={selected ? classes.highLightedRow : classes.root}
      onClick={handleClick}
    >
      <TableCell align="center">
        <Typography component={Link} to="/selected">
          {masterUnit.corporationName}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{masterUnit.plantName}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{masterUnit.remotes.length}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{masterUnit.activeAlarms.length}</Typography>
      </TableCell>
      <TableCell align="left">
        <AlarmIcon alertLevel={alarmLevel} />
      </TableCell>
    </TableRow>
  )
}

const headCells = [
  {
    clickable: true,
    id: 'Corporation',
    align: 'center',
    disablePadding: true,
    label: 'Company Name',
  },
  {
    clickable: true,
    id: 'Plant',
    align: 'center',
    disablePadding: false,
    label: 'Plant Name',
  },
  {
    clickable: true,
    id: 'RemoteUnitCount',
    align: 'center',
    disablePadding: false,
    label: 'Number of Remote Units',
  },
  {
    clickable: true,
    id: 'ActiveAlarmCount',
    align: 'center',
    disablePadding: false,
    label: 'Alarms',
  },
]

const headCellsAlarm = [
  {
    clickable: true,
    id: 'Corporation',
    align: 'center',
    disablePadding: true,
    label: 'Company Name',
  },
  {
    clickable: true,
    id: 'Plant',
    align: 'center',
    disablePadding: false,
    label: 'Plant Name',
  },
  {
    clickable: true,
    id: 'RemoteUnitCount',
    align: 'center',
    disablePadding: false,
    label: 'Number of Remote Units',
  },
  {
    clickable: true,
    id: 'ActiveAlarmCount',
    align: 'center',
    disablePadding: false,
    label: 'Alarms',
  },
  {
    clickable: false,
    id: 'alarmLevel',
    align: 'left',
    disablePadding: false,
    label: 'Severity',
  },
]

const tableStyles = makeStyles({
  container: {
    maxHeight: '1000px',
  },
})

/*Master Unit renders a table that will display all master units available to the
  user that is requesting them.
  Props required: 
  data: the list of master units as returned by a fetch request
  onRequestSort: still need to figure that out
  optional:
  defaultSelected:  If there is a default selected remote-unit then that will need to be passed
                    to the prop
  classes:          Still need to be figured out
*/
function MasterUnitTable(props) {
  const {
    data,
    setSelectedMasterUnit,
    alarmVariant,
    page,
    handleChangePage,
    rowsPerPage,
    handleRowsPerPageChange,
    sortOrder,
    setSortOrder,
    sortColumn,
    setSortColumn,
  } = props
  const [selectedRow, setSelectedRow] = useState()
  const classes = tableStyles()
  //to be used if there's a remote unit that will selected as default
  // const [selected,setSelected] = useState(defaultSelected);

  //provide an associated index for every row, probably by order to maintain
  //selected state properly

  const handleRequestSort = (event, property) => {
    const isAsc = sortColumn === property && sortOrder === 'Ascending'
    setSortOrder(isAsc ? 'Descending' : 'Ascending')
    setSortColumn(property)
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table>
          <EnhancedTableHead
            order={sortOrder}
            orderBy={sortColumn}
            onRequestSort={handleRequestSort}
            headCells={alarmVariant ? headCellsAlarm : headCells}
          />
          <TableBody>
            {data.map((masterUnit, index) => {
              if (alarmVariant) {
                return (
                  <AlarmRow
                    index={index}
                    key={index}
                    masterUnit={masterUnit}
                    setSelected={setSelectedRow}
                    selected={index === selectedRow}
                    setMasterUnit={setSelectedMasterUnit}
                  />
                )
              } else {
                return (
                  <Row
                    key={index}
                    masterUnit={masterUnit}
                    setSelectedMasterUnit={setSelectedMasterUnit}
                  />
                )
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
        colSpan={4}
        count={-1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  )
}

MasterUnitTable.propTypes = {
  data: PropTypes.array.isRequired,
  alarmVariant: PropTypes.bool,
  setSelectedMasterUnit: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  handleRowsPerPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortColumn: PropTypes.string.isRequired,
  setSortColumn: PropTypes.func.isRequired,
}

MasterUnitTable.defaultProps = {
  alarmVariant: false,
  setSelectedMasterUnit: () => {},
}

export default MasterUnitTable
