import React from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import MapLegend from '../MapLegend/MapLegend'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import typography from '../../../theme/typography'
import { baseColors } from '../../../theme/colors'

const useStyles = makeStyles({
  container: {
    borderRadius: '8px',
    padding: '16px',
  },
  hoverContainer: {
    '&:hover': {
      backgroundColor: baseColors.action.hover,
      cursor: 'pointer',
    },
  },
  showLegendText: {
    fontWeight: typography.fontWeight.bold,
    margin: '0',
  },
  iconContainer: {
    alignSelf: 'flex-start',
  },
  icon: {
    margin: '0px 16px',
    marginLeft: 'auto',
  },
})

function RenderMapLegend(props) {
  RenderMapLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** Boolean to determine whether or not to display the map legend */
    showMapLegend: PropTypes.bool.isRequired,
    /** Function to show/hide the map legend */
    toggleMapLegend: PropTypes.func.isRequired,
  }
  const { filteredCorpData, showMapLegend, toggleMapLegend } = props

  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={`${classes.container} ${
        showMapLegend ? '' : classes.hoverContainer
      }`}
    >
      <Grid item xs={11}>
        {showMapLegend ? (
          <MapLegend filteredCorpData={filteredCorpData} />
        ) : (
          <Typography
            variant="body1"
            className={classes.showLegendText}
            onClick={toggleMapLegend}
          >
            Show Map Legend
          </Typography>
        )}
      </Grid>
      <Grid container item xs={1} className={classes.iconContainer}>
        <IconButton onClick={toggleMapLegend} className={classes.icon}>
          {showMapLegend ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default RenderMapLegend
