import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import XLSX from 'xlsx'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import {
  getAllMatCols,
  extractColumnGroup,
} from '../Logic/AddBatchColumnSelectLogic'

const isExcelDate = (name, cellValue) =>
  name === 'productionDate' &&
  !isNaN(cellValue) &&
  !isNaN(parseFloat(cellValue))

const ExcelDateToJSDate = excelDate => {
  // Excel date is offset from 1900-01-01
  const date = new Date('1900-01-01')
  date.setDate(date.getDate() + excelDate - 1)

  // Add leading zeroes if single digit date.
  const month =
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`

  return `${date.getFullYear()}-${month}-${day}`
}

/**
 * Component used upload data to a spreadsheet component.
 * @param {Function} setUpdateData - function to update table with new data.
 * @param {Function} setSelectedCols - used to set columns in parent component. Only used when a file is uploaded.
 * @param {Object} possibleCols - list of all possible spreadsheet columns. Used to match column in file upload.
 * @param {Function} setStrengthAndMaterialCols - function to update running list of strength columns.
 * @param {Array} cementPlants - list of cement plants as fetched from Kelowna.
 */
function UploadBatchData(props) {
  UploadBatchData.propTypes = {
    /** Function to update table with new data. */
    setUpdateData: PropTypes.func.isRequired,
    /** Used to set columns in parent component. Only used when a file is uploaded. */
    setSelectedCols: PropTypes.func.isRequired,
    /** List of all possible spreadsheet columns. Used to match column in file upload. */
    possibleCols: PropTypes.object.isRequired,
    /** Function to update running list of strength columns. */
    setStrengthAndMaterialCols: PropTypes.func.isRequired,
    /** List of cement plants as fetched from Kelowna. */
    cementPlants: PropTypes.array.isRequired,
    /** Function to update progress loss alert. */
    setHasUserInput: PropTypes.func,
    /** Boolean to disable the button */
    isViewOnly: PropTypes.bool,
  }

  const {
    setHasUserInput,
    setUpdateData,
    setSelectedCols,
    possibleCols,
    setStrengthAndMaterialCols,
    cementPlants,
    isViewOnly,
  } = props

  /** Alerts to inform user if file type if not accepted or columns could not be matched from uploaded file. */
  const [fileTypeAlert, setFileTypeAlert] = useState(false)
  const [colMatchAlert, setColMatchAlert] = useState(false)

  /** Function used to match uploaded file date to permitted columns.
   * This prevents users from adding new columns in the template, only accepting the ones from the dropdowns.
   */
  const matchData = (jsonData, customerPlants) => {
    // Get all columns being uploaded, even if the do not appear in the first row's object.
    const allUploadedCols = []
    jsonData.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!allUploadedCols.includes(col)) {
          allUploadedCols.push(col)
        }
      })
    })

    // Get columns that match the permitted ones.
    const matchedProperties = possibleCols.properties.filter(col =>
      allUploadedCols.includes(col.title)
    )

    // Handle materials and strengths separately to account for multiples.
    const [matchedStrengths, matchedMaterials] = [
      'strengthReadings',
      'materials',
    ].map(colGroupName => {
      // Filter to only the CSV columns for a column group (i.e. strengthReadings or materials).
      const uploadCols = allUploadedCols.filter(col =>
        // The column is a match if its title appears in the list of possible column titles.
        possibleCols[colGroupName].find(possibleCol =>
          // Check if possible column title is a substring of the uploaded column name.
          // For example "28 Day Strength" and "28 Day Strength (2)" will both be matches.
          col.includes(possibleCol.title)
        )
      )
      return extractColumnGroup(
        uploadCols,
        possibleCols[colGroupName],
        setStrengthAndMaterialCols,
        colGroupName
      )
    })

    // Combine all columns to match with data.
    const allNewColumns = [
      ...matchedProperties,
      ...getAllMatCols(matchedMaterials, customerPlants),
      ...matchedStrengths,
    ]

    // Reassign uploaded data to matched column names.
    const mappedData = jsonData
      .map(row => {
        const newRow = {}
        allNewColumns.forEach(col => {
          const cellValue = row[col.title]

          // For date columns, check if date is in excel date format, then parse accordingly.
          newRow[col.name] = isExcelDate(col.name, cellValue)
            ? ExcelDateToJSDate(parseFloat(cellValue))
            : cellValue
        })
        return newRow
      })
      .filter(row => Object.keys(row).length > 0) // Remove empty rows.
    return [
      {
        properties: matchedProperties,
        materials: matchedMaterials,
        strengthReadings: matchedStrengths,
      },
      mappedData,
    ]
  }

  /** Read CSV/XLSX file and set table data. */
  const readFile = (file, customerPlants) => {
    const reader = new FileReader()
    reader.onload = e => {
      // Read the file using XLSX.
      const data = e.target.result
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: true,
        cellText: false,
        cellNF: false,
      })

      // Convert first worksheet to JSON.
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        raw: false,
        cellDates: true,
        dateNF: 'YYYY-MM-DD',
      })

      // Return and alert user if no data is parsed from file.
      if (jsonData.length < 1) {
        setColMatchAlert(true)
        return
      }

      // Match uploaded columns to permitted columns and reassign data.
      const [matchedCols, matchedData] = matchData(jsonData, customerPlants)

      if (
        matchedCols.strengthReadings.length ||
        matchedCols.properties.length ||
        matchedCols.materials.length
      ) {
        // Set new columns in parent component dropdowns.
        setSelectedCols(matchedCols)
        setUpdateData(matchedData)
      } else {
        // Alert user in the event that no columns could be matched.
        setColMatchAlert(true)
      }
    }
    reader.readAsArrayBuffer(file)
  }

  /** Event handler to upload data, return if no file was inputted. */
  const uploadData = e => {
    if (!e.target.files) return
    const file = e.target.files[0]
    // Check if uploaded file is of a permitted type, then read file or raise alert.
    if (file?.name.includes('.xlsx')) {
      readFile(file, cementPlants)
      // if file upload success, trigger progress lost alert
      setHasUserInput(true)
    } else {
      setFileTypeAlert(true)
    }
    e.target.value = '' // allow uploading the same file
  }

  /** Close alert functions. */
  const closeFileAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setFileTypeAlert(false)
  }
  const closeColMatchAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setColMatchAlert(false)
  }

  return (
    <>
      <input
        accept=".csv, .xlsx"
        onInput={uploadData}
        hidden
        id="upload-button"
        type="file"
        disabled={isViewOnly}
      />
      <label htmlFor="upload-button">
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          component="span"
          disabled={isViewOnly}
          style={{ marginLeft: '1.5em' }}
        >
          Upload File
        </Button>
      </label>
      <Snackbar
        open={fileTypeAlert}
        autoHideDuration={8000}
        onClose={closeFileAlert}
      >
        <Alert onClose={closeFileAlert} severity="warning">
          Uploaded files must be in XLSX format.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={colMatchAlert}
        autoHideDuration={10000}
        onClose={closeColMatchAlert}
      >
        <Alert onClose={closeColMatchAlert} severity="error">
          None of the columns could be successfully matched from the uploaded
          file or the file is empty. Please download and re-upload the filled
          template to ensure that all of the columns can be matched.
        </Alert>
      </Snackbar>
    </>
  )
}

export default UploadBatchData
