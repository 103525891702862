const defaultHead = {
  numeric: false,
  disablePadding: false,
  sortable: false,
}

export const PlantsEditableTableHeadCells = [
  {
    ...defaultHead,
    id: 'divisionName',
    label: 'Customer',
    sortable: true,
    width: 200,
  },
  {
    ...defaultHead,
    id: 'name',
    label: 'Plant Name',
    sortable: true,
  },
  {
    ...defaultHead,
    id: 'state',
    label: 'State',
  },
  {
    ...defaultHead,
    id: 'cO2DosePercent',
    label: 'CO\u2082 Dosage',
  },
  {
    ...defaultHead,
    id: 'cO2DosePercentTimestamp',
    label: 'Effective Date CO\u2082 Dose',
  },
  {
    ...defaultHead,
    id: 'averageCementCut',
    label: 'Cement Reduction',
  },
  {
    ...defaultHead,
    id: 'averageCementCutTimestamp',
    label: 'Effective Date Cement Reduc.',
  },
  {
    ...defaultHead,
    id: 'averageCementLoading',
    label: 'Avg Cement Loading',
    tooltip: 'Average cement loading after CarbonCure implementation',
  },
  {
    ...defaultHead,
    id: 'averageCementLoadingTimestamp',
    label: 'Effective Date Cement Loading',
  },
  {
    ...defaultHead,
    id: 'yardsPerTruck',
    label: 'Avg Yards / Truck',
    metricLabel: 'Avg Meters / Truck',
  },
  {
    ...defaultHead,
    id: 'tankRentalFee',
    label: 'Tank Rental Fee',
  },
  {
    ...defaultHead,
    id: 'residentialPercentage',
    label: 'Res. %',
    tooltip: 'Residential %',
  },
  {
    ...defaultHead,
    id: 'commercialPercentage',
    label: 'Comm. %',
    tooltip: 'Commercial %',
  },
  {
    ...defaultHead,
    id: 'dotPercentage',
    label: 'DOT. %',
  },
  {
    ...defaultHead,
    id: 'installDate',
    label: 'Install Date',
    sortable: true,
  },
  {
    ...defaultHead,
    id: 'tse',
    label: 'TSS Specialist',
    sortable: true,
  },
  {
    ...defaultHead,
    id: 'notes',
    label: 'Notes',
  },
]
