import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import InjectionReadingsTableModal from './InjectionReadingsTableModal'
import { getLocalDateTimeFromISODateString } from '../Logic/PCBLogic'

const useStyles = makeStyles({
  injectionTimestamp: {
    verticalAlign: 'top',
  },
  clickableRow: {
    cursor: 'pointer',
  },
})

RemoteUnitInjectionsTable.propTypes = {
  injections: PropTypes.array.isRequired,
}

function RemoteUnitInjectionsTable(props) {
  const classes = useStyles()
  const [readings, setReadings] = useState(null)
  const { injections } = props

  const handleCloseInjectionModal = () => {
    setReadings(null)
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Start Timestamp</Typography>
              </TableCell>
              <TableCell>
                <Typography>End Timestamp</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {injections.map(injection => {
              return (
                <TableRow
                  key={injection.startTimestamp}
                  className={classes.clickableRow}
                  onClick={() => setReadings(injection.readings)}
                >
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(
                        injection.startTimestamp
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(
                        injection.endTimestamp
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {readings ? (
        <InjectionReadingsTableModal
          handleClose={handleCloseInjectionModal}
          isOpen={readings !== null}
          readings={readings}
        />
      ) : (
        <></>
      )}
    </Paper>
  )
}

RemoteUnitInjectionsTable.defaultProps = {
  injections: [],
}

export default RemoteUnitInjectionsTable
