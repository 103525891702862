import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  getAlarmDescription,
  getLocalDateTimeFromISODateString,
} from '../Logic/PCBLogic'

AlarmsTable.propTypes = {
  alarms: PropTypes.array.isRequired,
}

function AlarmsTable(props) {
  const { alarms } = props

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Timestamp</Typography>
              </TableCell>
              <TableCell>
                <Typography>Resolved Timestamp</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Alarm</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarms.map(alarm => {
              return (
                <TableRow key={alarm.alarmId}>
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(alarm.timestamp)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(
                        alarm.resolvedTimestamp
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{getAlarmDescription(alarm.code)}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

AlarmsTable.defaultProps = {
  alarms: [],
}

export default AlarmsTable
