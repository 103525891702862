/**
 * Singleton - on construction gets the mapping and persists until the page unloads
 */

import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'

class AlarmCodeMapper {
  alarmMapping

  ready

  constructor() {
    this.ready = false
  }

  /**
   * Function to get error code details
   * @param {number} code The error code
   * @returns {{
   *  code: number,
   *  message: string,
   *  severity: 'Low' | 'Medium' | 'High'
   * }}
   */
  alarmDetails(code) {
    if (this.ready) {
      return this.alarmMapping[code]
    }
    return { code: 0, message: 'Loading...', severity: 'Low' }
  }

  async initializeInstance() {
    if (!this.ready) {
      try {
        const response = await AlarmsManagementDataHelpers.getAlarmCodeDetails()

        if (response.ok) {
          const mapping = await response.json()
          this.ready = true
          this.alarmMapping = mapping
        }
      } catch (error) {
        console.error('Error when Initializing AlarmCodeMapper', error)
      }
    }
  }
}

export default AlarmCodeMapper
