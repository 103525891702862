import PropTypes from 'prop-types'
import React from 'react'
import Status from '../Logic/Status'
import InjectionLineChartPresentational from './InjectionLineChartPresentational'

/**
 *
 * @param {Object} props
 * @param {Status []} props.statusArray
 * @param {Boolean} props.metric
 */
function UpstreamPressureLogical(props) {
  const data = props.statusArray.map(status => [
    status.timestamp.getTime(),
    props.metric ? status.upstreamPressureKPa : status.upstreamPressurePSI,
  ])

  return (
    <InjectionLineChartPresentational
      data={data}
      unit={props.metric ? 'kpa' : 'psi'}
      title="Upstream Pressure"
    />
  )
}

UpstreamPressureLogical.propTypes = {
  statusArray: PropTypes.arrayOf(PropTypes.instanceOf(Status)).isRequired,
  metric: PropTypes.bool,
}

UpstreamPressureLogical.defaultProps = {
  metric: false,
}

export default UpstreamPressureLogical
