import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import theme from '../../theme/muiTheme'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import {
  BaleenIntervals,
  BaleenMixVariation,
  BaleenModalData,
  BaleenOutlierReasons,
  BaleenOutlierStatus,
  BaleenOutlierType,
  BaleenSample,
  BaleenYAxis,
  DialogModalSize,
  DialogModalType,
} from '../Logic/Types'
import {
  Filter,
  FilterComponentTypes,
  FilterOption,
  ISimpleTableSettings,
  SimpleTableTypes,
  VariabilityGraphPage,
} from '../../Common/Logic/Types'
import BaleenVariationTableLogical from '../Components/BaleenVariationTableLogical'
import { baseColors } from '../../theme/colors'
import BaleenSamplesTableLogical from '../Components/BaleenSamplesTableLogical'
import {
  getMixGroupByPlant,
  getMixGroupWithSamples,
  getNewSettings,
  getUrlFromFilters,
  putUpdateOutliers,
} from '../Data/TSSDataHelpers'
import { isAbortedFetchError } from '../../Common/Helpers/GeneralHelpers'
import {
  BALEEN_MAX_VARIATION_COLUMNS,
  BALEEN_SELECTED_VARIATIONS_LIMIT,
  accumulateCementEfficiencyDataForAveraging,
  accumulatePropertyDataForAveraging,
  accumulateStrengthDataForAveraging,
  addAveragedPropertiesToVariation,
  changeSampleOutlierReasonDropdown,
  changeSampleOutlierReasonTextField,
  changeSampleOutlierStatus,
  changeVariationSampleOutlierReasonDropdown,
  changeVariationSampleOutlierReasonTextField,
  changeVariationSampleOutlierStatus,
  computeBaleenVariationStrengths,
  convertSampleUnits,
  createVariationOptions,
  dataColumnOptions,
  digestBaleenData,
  extractFrontendSettings,
  getBaleenOutlierSubmitErrors,
  getBaleenSampleHeaders,
  getDefaultVariationData,
  getSelectedVariationIntervalOptions,
  matchVariationsToVariationIds,
  matchhDataColumnToDataColumnIds,
  samplesToRows,
  showPointClickConfirmationModal,
  variationRecalculation,
  updateVariationIdLabel,
  populateOutlierSavePayloadArray,
  IFormattedOutlierForSave,
  IPopulateOutlierSavePayloadArrayParams,
  getNewVariationsAndSamplesAfterSaving,
  resetUncheckedSamples,
  resetUncheckedVariationSamples,
  getIsOutlierFormatInvalidForSubmission,
  resetSamplesWhoseVariationsAreRemoved,
  resetVariationSamplesWhoseVariationsAreRemoved,
  getIsUnsavedChangesModalVisible,
  getUpdatedOutliersAfterPointClick,
} from '../Helpers/BaleenHelpers'
import { Alert } from '@material-ui/lab'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import { atomCustomerOptions, atomPlantOptions } from '../../Common/tssAtomsB'
import { useRecoilState, useRecoilValue } from 'recoil'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { useLocation } from 'react-router-dom'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import cloneDeep from 'lodash.clonedeep'
import MixGroupOptionWithBaselineConditions from '../Components/MixGroupOptionWithBaselineConditions'
import VariabilityGraphLogical from '../Components/VariabilityGraph/VariabilityGraphLogical'
import BaleenDialogModalLogical from '../Components/BaleenDialogModalLogical'
import DialogModal from '../../Common/Components/DialogModal/DialogModal'
import AddDataProgressLostAlertLogical from '../Components/AddDataProgressLostAlert/AddDataProgressLostAlertLogical'
import { handleResNotOk } from '../../Common/Helpers/ErrorHandlers'
import breakpoints from '../../theme/breakpoints'
import { tssCanWrite } from '../Logic/TSSLogic'

const useStyles = makeStyles(() => ({
  //Filter Panel Styling
  ...theme.customClasses.filterPanel,
  filterPanelContainer: {
    position: 'relative',
    [theme.breakpoints.down(breakpoints.sm)]: {
      position: 'static',
    },
  },
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '12px',
    zIndex: 50,
    [theme.breakpoints.up(1340)]: {
      top: '16px',
    },
    [theme.breakpoints.down(breakpoints.sm)]: {
      top: '50px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    zIndex: -50,
    top: '12px',
    [theme.breakpoints.up(1340)]: {
      top: '16px',
    },
  },
  btnContainer: {
    paddingLeft: '2px',
  },
  chipRoot: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    margin: '2px',
    padding: '4px',
    maxWidth: 160,
    [theme.breakpoints.down(Number(breakpoints.sm) - 1)]: {
      maxWidth: '95%',
    },
    '& .MuiAvatar-root': {
      margin: '4px 0px',
    },
    '& .MuiChip-label': {
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip',
      fontSize: 12,
      maxWidth: 160,
      [theme.breakpoints.down(Number(breakpoints.sm) - 1)]: {
        maxWidth: '95%',
      },
    },
  },
  ...theme.customClasses.backdrop,
}))

// Modal styling
const useStyles2 = makeStyles({
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.warningModal,
})

const useStyles3 = makeStyles({
  // @ts-ignore
  ...theme.customClasses.errorModal,
})

export interface IBaleenFrontendFilterSettings {
  interval: Array<BaleenIntervals>
  variationIds: Array<string>
  dataColumns: Array<string>
  yAxis: Array<BaleenYAxis>
  isMetric: boolean
}

export interface IURLParamSettings {
  divisionId: number[]
  plantId: number[]
  mixGroup: number[]
  variationIds?: string[]
  interval?: BaleenIntervals[]
  yAxis?: BaleenYAxis[]
  dataColumns?: string[]
  isMetric?: boolean
}
export interface IBaleenFilterSettings {
  divisionId: number[]
  plantId: number[]
  mixGroup: number[]
}

export interface IActiveFilter {
  property: string
  label: string
  id?: number | string
}

export interface ICustomerOption {
  corporationId: number
  isImperial: boolean
  divisionId: number
  name: string
}

export interface IBaleenSamplesObj {
  [id: string]: BaleenSample
}

export interface IBaleenVariationObj {
  [id: string]: BaleenMixVariation
}

export type PropertiesToAverage =
  | 'slump'
  | 'concreteTemperature'
  | 'air'
  | 'cementContent'
  | 'densityKgPerM3'

export type ISampleAverages = {
  [key in PropertiesToAverage]: {
    total: number | null
    count: number
  }
}

export type IAveragePerInterval = {
  [key in BaleenIntervals]?: {
    total: number
    count: number
  }
}

export interface ISubmitErrors {
  [id: string]: {
    name: string
    message: string
  }[]
}

const measurementOptions = [
  {
    name: 'Metric',
    id: true,
  },
  {
    name: 'Imperial',
    id: false,
  },
]

const yAxisOptions = [
  { id: 'air', name: 'Air Content' },
  { id: 'slump', name: 'Slump' },
  { id: 'concreteTemperature', name: 'Concrete Temperature' },
  { id: 'batchWaterCementRatio', name: 'Water-Cement Ratio' },
  { id: 'cementContent', name: 'Cement Content' },
  { id: 'cO2Dosage', name: 'CO₂ Dosage' },
  { id: 'batchStrength', name: 'Batch Strength' },
]

export const UNSAVED_CHANGES_MODAL_HEADER_TEXT =
  'Unsaved Work Will Be Discarded'
export const UNSAVED_CHANGES_MODAL_CONTENT_TEXT =
  'Editing or removing a filter before saving the current outlier selection will result in discarding unsaved work. Do you wish to continue?'
export const UNSAVED_CHANGES_MODAL_CONFIRM_TEXT = 'Yes, Discard Unsaved Work'
export const UNSAVED_CHANGES_MODAL_CANCEL_TEXT = 'No, Go Back'

const BaleenView = () => {
  const classes: ClassNameMap = useStyles()
  const modalClasses: ClassNameMap = useStyles2()
  const errorModalClasses: ClassNameMap = useStyles3()
  const currentCustomer = useRecoilValue(atomCurrentCustomer)
  const JWT = useRecoilValue(atomJWT)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false)
  const [filterSettings, setFilterSettings] = useState<IBaleenFilterSettings>({
    divisionId: currentCustomer.division?.divisionId
      ? [currentCustomer.division?.divisionId]
      : [],
    plantId: currentCustomer.plant?.plantId
      ? [currentCustomer.plant?.plantId]
      : [],
    mixGroup: [],
  })
  const [samplesTableSettings, setSamplesTableSettings] = useState<
    ISimpleTableSettings
  >({ order: 'asc', orderBy: '', page: 0, rowsPerPage: 50 })
  const [outliersTableSettings, setOutliersTableSettings] = useState<
    ISimpleTableSettings
  >({ order: 'asc', orderBy: '', page: 0, rowsPerPage: 10 })

  // "dataColumns" refers to the columns of the variation table
  const [frontendFilterSettings, setFrontendFilterSettings] = useState<
    IBaleenFrontendFilterSettings
  >({
    interval: [],
    variationIds: [],
    dataColumns: ['slump', 'cO2DosageLabel', 'strengths'],
    yAxis: ['batchStrength'],
    isMetric: false,
  })
  const [selectedVariations, setSelectedVariations] = useState<
    BaleenMixVariation[]
  >([])
  const [selectedDataColumns, setSelectedDataColumns] = useState<
    FilterOption[]
  >([])
  const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>([])
  const [filterCount, setFilterCount] = useState(0)
  const [samples, setSamples] = useState<BaleenSample[]>([])
  const [outliers, setOutliers] = useState<BaleenSample[]>([])
  const [originalOutliers, setOriginalOutliers] = useState<IBaleenSamplesObj>(
    {}
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [expandFilters, setExpandFilters] = useState({ customer: false })
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const plantOptions = useRecoilValue(atomPlantOptions)

  const [mixGroupOptions, setMixGroupOptions] = useState<FilterOption[]>([])
  const [mixVariationOptions, setMixVariationOptions] = useState<
    FilterOption[]
  >([])
  const [intervalOptions, setIntervalOptions] = useState<FilterOption[]>([])
  const [variations, setVariations] = useState<BaleenMixVariation[]>([])
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    id: '',
    message: '',
  })
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false)
  /** There is a dialog modal In the customer and location component. This variable determines if it is open or closed. */
  const [
    isCustomerAndLocationUnsavedDataModalOpen,
    setIsCustomerAndLocationUnsavedDataModalOpen,
  ] = useState(false)
  const [isUnsavedDataModalOpen, setIsUnsavedDataModalOpen] = useState<boolean>(
    false
  )
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(false)
  const [
    isClearAllUncheckedBtnDisabled,
    setIsClearAllUncheckedBtnDisabled,
  ] = useState<boolean>(false)
  const [
    isClearAllUncheckedTooltipVisible,
    setIsClearAllUncheckedTooltipVisible,
  ] = useState<boolean>(false)
  const [isSaveBtnTooltipVisible, setIsSaveBtnTooltipVisible] = useState<
    boolean
  >(false)

  const [submitErrorsPerOutlier, setSubmitErrorsPerOutlier] = useState<
    ISubmitErrors
  >({})
  const [isValidationCheckActive, setIsValidationCheckActive] = useState<
    boolean
  >(false)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalData, setModalData] = useState<BaleenModalData | null>(null)
  const [unsavedChangesModalData, setUnsavedChangesModalData] = useState(null)
  // filterChangeType will only be storing filter types that can trigger the unsaved changes modal
  const [filterChangeType, setFilterChangeType] = useState<string>('')
  const [saveBtnText, setSaveBtnText] = useState<string>('Save')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { search } = useLocation()

  // Function that goes through the passed in *selected* variations' strength intervals and returns an interval to (maybe)
  // be used for selection. Also returns array of interval options
  const getNewIntervalInfo = useCallback(
    (
      newIds: string[],
      selectedVariations: BaleenMixVariation[],
      currentInterval?: BaleenIntervals
    ) => {
      const {
        intervalOptionsArr,
        intervalOptionsSet,
      } = getSelectedVariationIntervalOptions(selectedVariations)
      let newInterval: BaleenIntervals[]
      if (newIds.length === 0 || intervalOptionsArr.length === 0) {
        newInterval = []
      } else {
        if (!currentInterval || !intervalOptionsSet.has(currentInterval)) {
          if (intervalOptionsSet.has('672')) newInterval = ['672']
          else {
            newInterval = [intervalOptionsArr[intervalOptionsArr.length - 1].id]
          }
        } else {
          newInterval = [currentInterval]
        }
      }
      return { newInterval, intervalOptionsArr }
    },
    []
  )

  const getCustomerAndMixGroupLabel = () => {
    const divisionId = filterSettings.divisionId[0]
    if (!divisionId) return
    const mixGroupId = filterSettings.mixGroup[0]
    if (!mixGroupId) return
    const customer = customerOptions.find(
      (option: ICustomerOption) => option.divisionId === Number(divisionId)
    )
    if (!customer) return
    const mixGroup = mixGroupOptions.find(
      option => option.id === Number(mixGroupId)
    )
    if (!mixGroup) return
    return `${customer.name} | ${mixGroup.name}`
  }
  // Reset relevant state when customer or plant changes
  const resetBaleenDependents = () => {
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      variationIds: [],
      interval: [],
    }))
    setSamples([])
    setOutliers([])
    setOriginalOutliers({})
    setSelectedVariations([])
    setVariations([])
    setMixVariationOptions([])
    setIntervalOptions([])
    setSubmitErrorsPerOutlier({})
    setIsValidationCheckActive(false)
    setSaveBtnText('Save')
  }
  // Determine whether or not the data column chip should be active in the filter panel
  const isChipActive = (option: FilterOption) => {
    return frontendFilterSettings.dataColumns.includes(option.id)
  }

  const helperVariables = {
    resetBaleenDependents: resetBaleenDependents,
    isChipActive: isChipActive,
    setIsUnsavedDataModalOpen: setIsCustomerAndLocationUnsavedDataModalOpen,
    unsavedChangesModalData: unsavedChangesModalData,
    setUnsavedChangesModalData: setUnsavedChangesModalData,
    getIsUnsavedChangePresent: getIsUnsavedChangesModalVisible,
    originalOutliers: originalOutliers,
    selectedVariations: selectedVariations,
    isUnsavedDataModalOpen: isCustomerAndLocationUnsavedDataModalOpen,
    modalClasses: modalClasses,
    isLocationRequired: true,
  }

  const filterButtonClickHandler = () => {
    setIsFilterPanelOpen(prev => !prev)

    const windowWidth = window.innerWidth
    if (windowWidth < 600) {
      window.scrollTo({
        left: 0,
        top: 50,
        behavior: 'smooth',
      })
    }
  }

  const confirmCustomerRemoval = () => {
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      divisionId: [],
      plantId: [],
      mixGroup: [],
    }))
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      variationIds: [],
      interval: [],
    }))
    setSelectedVariations([])
    setVariations([])
    setIntervalOptions([])
    setMixGroupOptions([])
    setSaveBtnText('Save')
  }

  const confirmPlantRemoval = () => {
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      plantId: [],
      mixGroup: [],
    }))
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      interval: [],
      variationIds: [],
    }))
    setSelectedVariations([])
    setVariations([])
    setIntervalOptions([])
    setMixGroupOptions([])
    setSaveBtnText('Save')
  }

  const confirmMixGroupChange = (selectedMixGroup: number[]) => {
    setOutliers([])
    setSamples([])
    setOriginalOutliers({})
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      variationIds: [],
      interval: [],
    }))
    setSelectedVariations([])
    setVariations([])
    setIntervalOptions([])
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      mixGroup: selectedMixGroup,
    }))
    setSubmitErrorsPerOutlier({})
    setIsValidationCheckActive(false)
    setSaveBtnText('Save')
  }

  // onChange handler for mix group autocomplete in filter panel
  const handleMixGroupChange = (_: Filter, mixGroup: FilterOption) => {
    const selectedMixGroup = mixGroup ? [mixGroup.id] : []
    const showModal = getIsUnsavedChangesModalVisible(
      originalOutliers,
      selectedVariations,
      []
    )
    if (showModal) {
      setIsUnsavedDataModalOpen(true)
      setFilterChangeType('mixGroup')
      setUnsavedChangesModalData({
        newIds: [],
        mixGroup: selectedMixGroup,
      })
      return
    }
    confirmMixGroupChange(selectedMixGroup)
  }

  const confirmSelectedVariationsChange = (
    newIds: string[],
    newSelectedVariations: BaleenMixVariation[],
    newOutliers: BaleenSample[]
  ) => {
    // get the new interval options and (maybe) the new selected interval from the new array of selected variations
    const { newInterval, intervalOptionsArr } = getNewIntervalInfo(
      newIds,
      newSelectedVariations,
      frontendFilterSettings.interval[0]
    )

    const clonedVariations: BaleenMixVariation[] = cloneDeep(variations)
    resetVariationSamplesWhoseVariationsAreRemoved(
      clonedVariations,
      frontendFilterSettings.variationIds,
      originalOutliers
    )
    const newSelectedVariationsAfterReset = []
    for (const variation of newSelectedVariations) {
      const matchedVariation = clonedVariations.find(
        clonedVariation => clonedVariation.variationId === variation.variationId
      )
      if (matchedVariation)
        newSelectedVariationsAfterReset.push(matchedVariation)
    }
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      variationIds: newIds,
      interval: newInterval,
    }))
    setIntervalOptions(intervalOptionsArr)
    setSamples(prevSamples => {
      resetSamplesWhoseVariationsAreRemoved(
        prevSamples,
        frontendFilterSettings.variationIds,
        originalOutliers
      )
      return [...prevSamples]
    })
    setVariations([...clonedVariations])
    setOutliers(newOutliers)
    setSelectedVariations(newSelectedVariationsAfterReset)
    setUnsavedChangesModalData(null)
    setSaveBtnText('Save')
  }

  const confirmDeleteAllFilters = () => {
    setFilterSettings(prevSettings => ({
      ...prevSettings,
      divisionId: [],
      plantId: [],
      mixGroup: [],
    }))
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      variationIds: [],
      dataColumns: [],
      interval: [],
      yAxis: ['batchStrength'],
    }))
    setSelectedVariations([])
    setSelectedDataColumns([])
    setIntervalOptions([])
    setSaveBtnText('Save')
  }

  const addOriginalOutliersToCurrentOutliers = (
    newIds: string[],
    newOutliers: BaleenSample[]
  ) => {
    // Go through original outliers and see if any need to be added to the outliers list.
    for (const batchId in originalOutliers) {
      const isVariationOfOutlierSelected = newIds.includes(
        originalOutliers[batchId].variationId
      )
      const isOutlierMissingFromTable =
        newOutliers.find(
          outlierOption => outlierOption.batchId === Number(batchId)
        ) === undefined

      if (isVariationOfOutlierSelected && isOutlierMissingFromTable)
        newOutliers.push({ ...originalOutliers[batchId] })
    }
  }

  // onChange handler for mix variation autocomplete in filter panel
  const handleMixVariationChange = (
    _: Filter,
    mixVariations: FilterOption[]
  ) => {
    const selectedOptions: string[] = mixVariations.map(
      variation => variation.id
    )
    const currentVariationIds = frontendFilterSettings.variationIds
    let newIds: string[] = []
    // if 'all' is a possible option
    if (
      variations.length > 0 &&
      variations.length <= BALEEN_SELECTED_VARIATIONS_LIMIT
    ) {
      if (currentVariationIds.includes('all')) {
        if (selectedOptions.includes('all')) {
          // if the current & new ids both have 'all', another id must have been removed. so remove 'all' from the selected ids.
          newIds = selectedOptions.filter(id => id !== 'all')
        }
      } else {
        const includesAll = selectedOptions.includes('all')
        // if the current ids includes 'all' OR if it doesnt but the new ids are all selected except 1 (all must be the one that isnt..so select all options)
        if (
          includesAll ||
          (!includesAll &&
            selectedOptions.length === mixVariationOptions.length - 1)
        ) {
          const allIds = mixVariationOptions.map(el => el.id)
          newIds = allIds
        } else {
          // if the current ids includes all but the new ids do not AND more than 1 option HASN'T been selected, take what the autocomplete value gives you
          newIds = selectedOptions
        }
      }
    } else {
      // if 'all' isn't a possible option, take what the autocomplete value gives you.
      newIds = selectedOptions
    }
    const newSelectedVariations = matchVariationsToVariationIds(
      newIds,
      variations
    )
    const newOutliers = outliers.filter(outlier =>
      newIds.includes(outlier.variationId)
    )
    addOriginalOutliersToCurrentOutliers(newIds, newOutliers)

    // look for unsaved changes if we have deselected a variation
    if (newSelectedVariations.length < selectedVariations.length) {
      const showModal = getIsUnsavedChangesModalVisible(
        originalOutliers,
        selectedVariations,
        newSelectedVariations
      )
      if (showModal) {
        setIsUnsavedDataModalOpen(true)
        setUnsavedChangesModalData({
          newIds,
          newSelectedVariations,
          newOutliers,
        })
        setFilterChangeType('variation')
        return
      }
    }
    confirmSelectedVariationsChange(newIds, newSelectedVariations, newOutliers)
  }

  // onChange handler for interval select dropdown in filter panel
  const handleIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      interval: e.target.value ? [e.target.value as BaleenIntervals] : [],
    }))
    setSaveBtnText('Save')
  }

  // onClick handler for data columns in filter panel
  const handleChipClick = (_: Filter, option: FilterOption) => {
    const isSelected = frontendFilterSettings.dataColumns.includes(option.id)
    const newSettings = cloneDeep(frontendFilterSettings)
    // if the option is not currently selected, add it to the list of selected data columns
    if (!isSelected) {
      newSettings.dataColumns.push(option.id)
      setSelectedDataColumns(prevColumns => [...prevColumns, option])
    } else {
      // if the option is selected, deselect it
      newSettings.dataColumns = frontendFilterSettings.dataColumns.filter(
        el => el !== option.id
      )
      const filteredDataColumns = selectedDataColumns.filter(
        el => el.id !== option.id
      )
      setSelectedDataColumns(filteredDataColumns)
    }
    setFrontendFilterSettings(newSettings)
    setSaveBtnText('Save')
  }

  const handleYAxisChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFrontendFilterSettings(prevSettings => ({
      ...prevSettings,
      yAxis: e.target.value ? [e.target.value as BaleenYAxis] : [],
    }))
    setSaveBtnText('Save')
  }

  // Function to determine whether or not the mix group filter is disabled
  const isMixGroupFilterDisabled = () => {
    if (!!filterSettings.divisionId.length && !!filterSettings.plantId.length)
      return false
    return true
  }

  // Function to determine whether the mix variation filter is disabled
  const isMixVariationFilterDisabled = () => {
    if (
      !!filterSettings.divisionId.length &&
      !!filterSettings.plantId.length &&
      !!filterSettings.mixGroup.length
    )
      return false
    return true
  }

  // Function to determine if a mix variation option is disabled
  const getVariationOptionDisabled = (option: FilterOption) => {
    if (
      frontendFilterSettings.variationIds.length ===
      BALEEN_SELECTED_VARIATIONS_LIMIT
    ) {
      if (frontendFilterSettings.variationIds.includes(option.id)) return false
      return true
    }
    return false
  }

  // Function to determine if the data column option is disabled
  const getCardOptionDisabled = (optionId: string) => {
    if (
      frontendFilterSettings.dataColumns.length < BALEEN_MAX_VARIATION_COLUMNS
    )
      return false
    return !frontendFilterSettings.dataColumns.includes(optionId)
  }

  // onClick handler for the Clear All Filters button
  const deleteAllChipHandler = () => {
    const showModal = getIsUnsavedChangesModalVisible(
      originalOutliers,
      selectedVariations,
      []
    )
    if (showModal) {
      setIsUnsavedDataModalOpen(true)
      setFilterChangeType('removeAll')
      return
    }
    confirmDeleteAllFilters()
  }

  const handleTextFieldSelectChange = (
    e: React.ChangeEvent<{ value: BaleenOutlierReasons }>,
    batchId: number
  ) => {
    const outlierReasonDropdown = e.target.value ? e.target.value : null
    const matchedSample = samples.find(sample => sample.batchId === batchId)
    if (!matchedSample) return
    setSamples((prevSamples: BaleenSample[]) => {
      return changeSampleOutlierReasonDropdown(
        batchId,
        prevSamples,
        outlierReasonDropdown
      )
    })
    setOutliers((prevOutliers: BaleenSample[]) => {
      return changeSampleOutlierReasonDropdown(
        batchId,
        prevOutliers,
        outlierReasonDropdown
      )
    })
    setSelectedVariations(prevSelectedVariations => {
      return changeVariationSampleOutlierReasonDropdown(
        batchId,
        matchedSample.variationId,
        prevSelectedVariations,
        outlierReasonDropdown
      )
    })
    setVariations(prevVariations => {
      return changeVariationSampleOutlierReasonDropdown(
        batchId,
        matchedSample.variationId,
        prevVariations,
        outlierReasonDropdown
      )
    })
  }

  const handleTextFieldChange = useCallback(
    (batchId: number, variationId: string, outlierReasonTextField: string) => {
      setSamples(prevSamples => {
        return changeSampleOutlierReasonTextField(
          batchId,
          prevSamples,
          outlierReasonTextField
        )
      })
      setOutliers(prevOutliers => {
        return changeSampleOutlierReasonTextField(
          batchId,
          prevOutliers,
          outlierReasonTextField
        )
      })
      setSelectedVariations(prevSelectedVariations => {
        return changeVariationSampleOutlierReasonTextField(
          batchId,
          variationId,
          prevSelectedVariations,
          outlierReasonTextField
        )
      })
      setSelectedVariations(prevVariations => {
        return changeVariationSampleOutlierReasonTextField(
          batchId,
          variationId,
          prevVariations,
          outlierReasonTextField
        )
      })
    },
    []
  )

  const handleSampleCheckboxChange = (batchId: number) => {
    const match = samples.find(sample => sample.batchId === batchId)
    const clonedMatch = cloneDeep(match)
    if (!match) return
    setSamples((prevSamples: BaleenSample[]) => {
      return changeSampleOutlierStatus(
        batchId,
        prevSamples,
        'PotentialOutlierChecked',
        false
      )
    })
    setOutliers(prevOutliers => {
      // used clone to change status instead of using the original match object and relying on
      // the change that occured in setSamples
      const preExistingOutlierMatch = prevOutliers.find(
        outlier => outlier.batchId === batchId
      )
      // prevent double clicking from putting two of the same sample in the outlier table
      if (preExistingOutlierMatch !== undefined) return prevOutliers
      clonedMatch.outlierStatus = 'PotentialOutlierChecked'
      return [...prevOutliers, clonedMatch]
    })

    setSelectedVariations(prevSelectedVariations => {
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevSelectedVariations,
        'PotentialOutlierChecked',
        false
      )
    })

    setVariations(prevVariations => {
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevVariations,
        'PotentialOutlierChecked',
        false
      )
    })
    setSaveBtnText('Save')
  }

  const handleOutlierCheckboxChange = (batchId: number, e) => {
    const isChecked = e.target.checked
    // "match" is used to find the variation the sample belongs to and make changes to that variation below
    const match = samples.find(sample => sample.batchId === batchId)
    const newOutlierStatus = isChecked
      ? 'PotentialOutlierChecked'
      : 'PotentialOutlierUnchecked'
    if (!match) return
    setOutliers((prevOutliers: BaleenSample[]) => {
      return changeSampleOutlierStatus(
        batchId,
        prevOutliers,
        newOutlierStatus,
        false
      )
    })
    setSamples((prevSamples: BaleenSample[]) => {
      return changeSampleOutlierStatus(
        batchId,
        prevSamples,
        newOutlierStatus,
        false
      )
    })
    setSelectedVariations(prevSelectedVariations => {
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevSelectedVariations,
        newOutlierStatus,
        false
      )
    })

    setVariations(prevVariations => {
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevVariations,
        newOutlierStatus,
        false
      )
    })
    setSaveBtnText('Save')
  }

  const removePotentialOutlierFromOutliersTable = (batchId: number) => {
    // "match" is used to find the variation the sample belongs to and make changes to that variation below
    const match = samples.find(sample => sample.batchId === batchId)
    if (!match) return
    setOutliers(prevOutliers => {
      const newOutliers = prevOutliers.filter(
        outlier => outlier.batchId !== batchId
      )
      return newOutliers
    })
    setSamples(prevSamples => {
      return changeSampleOutlierStatus(batchId, prevSamples, 'Sample', true)
    })
    setVariations(prevVariations => {
      // find the matching variation and sample. change the sample's status
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevVariations,
        'Sample',
        true
      )
    })
    setSelectedVariations(prevSelectedVariations => {
      // find the matching variation and sample. change the sample's status
      return changeVariationSampleOutlierStatus(
        batchId,
        match.variationId,
        prevSelectedVariations,
        'Sample',
        true
      )
    })
    setSaveBtnText('Save')
  }

  const handleEditOrUndoClick = (
    batchId: number,
    newOutlierStatus: BaleenOutlierStatus
  ) => {
    // "match" is used to find the variation the sample belongs to and make changes to that variation below
    if (newOutlierStatus === 'PotentialOutlierChecked') {
      const match = samples.find(sample => sample.batchId === batchId)
      if (!match) return
      setOutliers(prevOutliers => {
        return changeSampleOutlierStatus(
          batchId,
          prevOutliers,
          newOutlierStatus,
          false
        )
      })
      setSamples(prevSamples => {
        return changeSampleOutlierStatus(
          batchId,
          prevSamples,
          newOutlierStatus,
          false
        )
      })
      setVariations(prevVariations => {
        // find the matching variation and sample. change the sample's status
        return changeVariationSampleOutlierStatus(
          batchId,
          match.variationId,
          prevVariations,
          newOutlierStatus,
          false
        )
      })
      setSelectedVariations(prevSelectedVariations => {
        // find the matching variation and sample. change the sample's status
        return changeVariationSampleOutlierStatus(
          batchId,
          match.variationId,
          prevSelectedVariations,
          newOutlierStatus,
          false
        )
      })
    } else {
      const match = samples.find(sample => sample.batchId === batchId)
      if (!match) return
      setOutliers(prevOutliers => {
        const matchedIndex = prevOutliers.findIndex(
          outlier => outlier.batchId === batchId
        )
        const prevOutliersCopy = cloneDeep(prevOutliers)
        prevOutliersCopy[matchedIndex] = cloneDeep(originalOutliers[batchId])
        return [...prevOutliersCopy]
      })
      setVariations(prevVariations => {
        const matchedVariation = prevVariations.find(
          variation => variation.variationId === match.variationId
        )
        if (!matchedVariation) return prevVariations
        matchedVariation.samples[batchId] = cloneDeep(originalOutliers[batchId])
        variationRecalculation(matchedVariation)
        return [...prevVariations]
      })
      setSelectedVariations(prevSelectedVariations => {
        const matchedVariation = prevSelectedVariations.find(
          variation => variation.variationId === match.variationId
        )
        if (!matchedVariation) return prevSelectedVariations
        matchedVariation.samples[batchId] = cloneDeep(originalOutliers[batchId])
        variationRecalculation(matchedVariation)
        return [...prevSelectedVariations]
      })
    }
    setSaveBtnText('Save')
  }

  // the handler for whether or not we open up the modal. must be no errors.
  const handleSaveBtnClick = () => {
    const errors: ISubmitErrors = {}
    getBaleenOutlierSubmitErrors(errors, outliers, originalOutliers)
    if (Object.keys(errors).length === 0) {
      setSubmitErrorsPerOutlier({})
      setIsSaveModalOpen(true)
      setIsValidationCheckActive(false)
    } else {
      setSubmitErrorsPerOutlier(errors)
      setIsSaveBtnDisabled(true)
      setIsValidationCheckActive(true)
    }
  }

  // Handler for filters are cleared via filter chip and there are unsaved changes
  // Determine which type of filter was cleared and handle it accordingly
  const confirmUnsavedChanges = () => {
    if (filterChangeType === 'customer') {
      confirmCustomerRemoval()
    } else if (filterChangeType === 'plant') {
      confirmPlantRemoval()
    } else if (filterChangeType === 'mixGroup') {
      confirmMixGroupChange(unsavedChangesModalData.mixGroup)
    } else if (filterChangeType === 'variation') {
      confirmSelectedVariationsChange(
        unsavedChangesModalData.newIds,
        unsavedChangesModalData.newSelectedVariations,
        unsavedChangesModalData.newOutliers
      )
    } else if (filterChangeType === 'removeAll') {
      confirmDeleteAllFilters()
    }
    setIsUnsavedDataModalOpen(false)
    setUnsavedChangesModalData(null)
  }

  // onClick handler for individual filter chips
  const deleteChipHandler = (filterToDelete: IActiveFilter) => {
    switch (filterToDelete.property) {
      case 'divisionId': {
        // determine if there are unsaved changes that would be discarded. if so, show unsaved changes modal.
        // if not, remove chip and selected filter.
        const showUnsavedChangesModalAfterCustomerChange = getIsUnsavedChangesModalVisible(
          originalOutliers,
          selectedVariations,
          []
        )
        if (showUnsavedChangesModalAfterCustomerChange) {
          setIsUnsavedDataModalOpen(true)
          setFilterChangeType('customer')
          return
        }
        confirmCustomerRemoval()
        break
      }
      case 'plantId': {
        // determine if there are unsaved changes that would be discarded. if so, show unsaved changes modal.
        // if not, remove chip and selected filter.
        const showUnsavedChangesModalAfterPlantChange = getIsUnsavedChangesModalVisible(
          originalOutliers,
          selectedVariations,
          []
        )
        if (showUnsavedChangesModalAfterPlantChange) {
          setIsUnsavedDataModalOpen(true)
          setFilterChangeType('plant')
          return
        }
        confirmPlantRemoval()
        break
      }
      case 'mixGroup': {
        // determine if there are unsaved changes that would be discarded. if so, show unsaved changes modal.
        // if not, remove chip and selected filter.
        const showModalAfterMixGroupChange = getIsUnsavedChangesModalVisible(
          originalOutliers,
          selectedVariations,
          []
        )
        if (showModalAfterMixGroupChange) {
          setIsUnsavedDataModalOpen(true)
          setFilterChangeType('mixGroup')
          setUnsavedChangesModalData({
            mixGroup: [],
          })
          return
        }
        confirmMixGroupChange([])
        break
      }
      case 'variationIds': {
        let newIds: string[] = []
        // if the id to remove isn't "all", remove the id and remove 'all' in case it is currently selected.
        if (filterToDelete.id !== 'all') {
          newIds = frontendFilterSettings.variationIds.filter(
            id => id !== filterToDelete.id && id !== 'all'
          )
        }
        // match the new array of variation ids to their variations
        const newSelectedVariations = matchVariationsToVariationIds(
          newIds,
          variations
        )
        // determine if there are unsaved changes that would be discarded.
        const showModal = getIsUnsavedChangesModalVisible(
          originalOutliers,
          selectedVariations,
          newSelectedVariations
        )
        // determine which outliers would be contained in the outliers table if the change of selected variations is confirmed
        const newOutliers = outliers.filter(outlier =>
          newIds.includes(outlier.variationId)
        )
        if (showModal) {
          setIsUnsavedDataModalOpen(true)
          setUnsavedChangesModalData({
            newIds,
            newSelectedVariations,
            newOutliers,
          })
          setFilterChangeType('variation')
          return
        }
        confirmSelectedVariationsChange(
          newIds,
          newSelectedVariations,
          newOutliers
        )
        break
      }
      case 'dataColumns': {
        const newColumns = frontendFilterSettings.dataColumns.filter(
          column => column !== filterToDelete.id
        )
        const selectedDataColumnOptions = matchhDataColumnToDataColumnIds(
          newColumns
        )
        setSelectedDataColumns(selectedDataColumnOptions)
        setFrontendFilterSettings(prevSettings => ({
          ...prevSettings,
          dataColumns: newColumns,
        }))
        setSaveBtnText('Save')
        break
      }
      default:
        break
    }
  }

  // A function to clear all unchecked potential outliers
  const clearAllUnchecked = () => {
    setSamples(prevSamples => {
      resetUncheckedSamples(prevSamples)
      return [...prevSamples]
    })
    setOutliers(prevOutliers => {
      const newOutliers = prevOutliers.filter(
        outlier =>
          outlier.outlierStatus !== 'PotentialOutlierUnchecked' ||
          outlier.outlierType !== null
      )
      return newOutliers
    })
    setSelectedVariations(prevSelectedVariations => {
      resetUncheckedVariationSamples(prevSelectedVariations)
      return [...prevSelectedVariations]
    })
    setVariations(prevVariations => {
      resetUncheckedVariationSamples(prevVariations)
      return [...prevVariations]
    })
    setSaveBtnText('Save')
  }

  const convertVariationOptionsAndFilterChips = (
    newVariationOptions: FilterOption[],
    newFilterChips: IActiveFilter[],
    convertedVariationsObj: IBaleenVariationObj
  ) => {
    newVariationOptions.forEach((option: FilterOption) => {
      const match = convertedVariationsObj[option.id]
      if (match) option.name = match.variationIdLabel
    })

    newFilterChips.forEach((filterChip: IActiveFilter) => {
      if (
        filterChip.property === 'variationIds' &&
        filterChip.id !== undefined &&
        convertedVariationsObj[filterChip.id] !== undefined
      ) {
        filterChip.label = `Variation ID: ${
          convertedVariationsObj[filterChip.id].variationIdLabel
        }`
      }
    })
  }

  // onChange handler for the imperial & metric radio buttons
  const radioSingleSelectChangeHandler = (_: Filter, value: string) => {
    const newVal = value !== 'false'
    const propertiesToAverage: PropertiesToAverage[] = [
      'slump',
      'concreteTemperature',
      'air',
      'cementContent',
      'densityKgPerM3',
    ]
    const samplesCopy = cloneDeep(samples)
    const outliersCopy = cloneDeep(outliers)
    const originalOutliersCopy = cloneDeep(originalOutliers)
    const variationsCopy = cloneDeep(variations)
    const selectedVariationsCopy = cloneDeep(selectedVariations)
    const samplesObj: IBaleenSamplesObj = {}
    const convertedVariationsObj: IBaleenVariationObj = {}
    // Function to convert the samples in samplesCopy to metric or imperial
    convertSampleUnits(samplesCopy, samplesObj, newVal)

    const newVariations = variationsCopy.map(
      (variation: BaleenMixVariation) => {
        const variationSamples: IBaleenSamplesObj = variation.samples
        // used to store data necessary for averaging values that will be assigned to a variation
        const averages: ISampleAverages = {
          slump: {
            total: null,
            count: 0,
          },
          concreteTemperature: {
            total: null,
            count: 0,
          },
          air: {
            total: null,
            count: 0,
          },
          cementContent: {
            total: null,
            count: 0,
          },
          densityKgPerM3: {
            total: null,
            count: 0,
          },
        }
        // used to store data necessary for calculating strength average per inerval that will be assigned to a variation
        const strengthAverageObj: IAveragePerInterval = {}

        const cementEfficiencyAverageObj: IAveragePerInterval = {}

        // convert samples that are stored on variations by using samplesObj which already has converted samples
        for (const batchId in variationSamples) {
          const currentSample = variationSamples[batchId]
          currentSample.slump = samplesObj[batchId].slump
          currentSample.cementContent = samplesObj[batchId].cementContent
          currentSample.concreteTemperature =
            samplesObj[batchId].concreteTemperature
          currentSample.strengths = { ...samplesObj[batchId].strengths }
          currentSample.densityKgPerM3 = samplesObj[batchId].densityKgPerM3
          currentSample.cO2Dosage = samplesObj[batchId].cO2Dosage
          currentSample.cementEfficiency = {
            ...samplesObj[batchId].cementEfficiency,
          }
          if (currentSample.outlierStatus === 'Sample') {
            // Function to get necessary data for averaging sampple properties
            accumulatePropertyDataForAveraging(
              currentSample,
              averages,
              propertiesToAverage
            )
            // Function to get necessary data for averaging sample strengths per interval
            accumulateStrengthDataForAveraging(
              currentSample,
              strengthAverageObj
            )
            // Function to get necessary data for averaging sample cement efficiency per interval
            accumulateCementEfficiencyDataForAveraging(
              currentSample,
              cementEfficiencyAverageObj
            )
          }

          if (
            (currentSample.outlierStatus === 'Sample' ||
              currentSample.outlierStatus === 'PotentialOutlierUnchecked') &&
            currentSample.cO2Dosage !== null
          ) {
            variation.cO2DosageLabel = currentSample.cO2Dosage
          }
          //update variationId when switching units
          variation.variationIdLabel = updateVariationIdLabel(
            currentSample.variationIdLabel,
            currentSample,
            newVal
          )
        }

        for (const outlier of outliersCopy) {
          const batchId = outlier.batchId
          outlier.variationIdLabel = samplesObj[batchId].variationIdLabel
          outlier.slump = samplesObj[batchId].slump
          outlier.cementContent = samplesObj[batchId].cementContent
          outlier.concreteTemperature = samplesObj[batchId].concreteTemperature
          outlier.strengths = { ...samplesObj[batchId].strengths }
          outlier.densityKgPerM3 = samplesObj[batchId].densityKgPerM3
          outlier.cO2Dosage = samplesObj[batchId].cO2Dosage
          outlier.cementEfficiency = { ...samplesObj[batchId].cementEfficiency }
        }

        for (const batchId in originalOutliersCopy) {
          originalOutliersCopy[batchId].variationIdLabel =
            samplesObj[batchId].variationIdLabel
          originalOutliersCopy[batchId].slump = samplesObj[batchId].slump
          originalOutliersCopy[batchId].cementContent =
            samplesObj[batchId].cementContent
          originalOutliersCopy[batchId].concreteTemperature =
            samplesObj[batchId].concreteTemperature
          originalOutliersCopy[batchId].strengths = {
            ...samplesObj[batchId].strengths,
          }
          originalOutliersCopy[batchId].densityKgPerM3 =
            samplesObj[batchId].densityKgPerM3
          originalOutliersCopy[batchId].cO2Dosage =
            samplesObj[batchId].cO2Dosage
          originalOutliersCopy[batchId].cementEfficiency = {
            ...samplesObj[batchId].cementEfficiency,
          }
        }

        addAveragedPropertiesToVariation(
          variation,
          propertiesToAverage,
          averages,
          strengthAverageObj,
          cementEfficiencyAverageObj
        )
        // store the variation in an object for future use
        convertedVariationsObj[variation.variationId] = cloneDeep(variation)
        return variation
      }
    )
    // convert selectedVariations by referencing convertedVariationsObj which already has converted variations
    const newSelectedVariations = selectedVariationsCopy.map(
      (variation: BaleenMixVariation) => {
        return { ...convertedVariationsObj[variation.variationId] }
      }
    )

    const newVariationOptions = cloneDeep(mixVariationOptions)
    const newFilterChips = cloneDeep(activeFilters)
    convertVariationOptionsAndFilterChips(
      newVariationOptions,
      newFilterChips,
      convertedVariationsObj
    )

    setMixVariationOptions(newVariationOptions)
    setActiveFilters(newFilterChips)
    setSamples(samplesCopy)
    setOutliers(outliersCopy)
    setOriginalOutliers(originalOutliersCopy)
    setSelectedVariations(newSelectedVariations)
    setVariations(newVariations)
    setFrontendFilterSettings(prev => {
      return {
        ...prev,
        isMetric: newVal,
      }
    })
    setSaveBtnText('Save')
  }

  const getIntervalDisabled = () => {
    return selectedVariations.length === 0
  }

  // Function to handle digesting data and setting state after a mix group is selected
  const handleMixGroupWithSamplesData = useCallback(
    data => {
      const {
        digestedSamples,
        digestedMixVariations,
        digestedOutliers,
        digestedOriginalOutliers,
      } = digestBaleenData(
        data,
        frontendFilterSettings.isMetric,
        frontendFilterSettings.yAxis[0],
        frontendFilterSettings.interval
      )
      const variationOptions = createVariationOptions(digestedMixVariations)
      const { variationsWithStrengths } = computeBaleenVariationStrengths(
        digestedMixVariations
      )
      // get the url param interval and variation ids
      const storedInterval = localStorage.getItem('baleenInterval')
      const storedVariationIds = localStorage.getItem('baleenVariations')
        ? JSON.parse(localStorage.getItem('baleenVariations'))
        : []

      let finalInterval: BaleenIntervals[]
      // get the default variations and their ids
      const {
        newSelectedVariations,
        newVariationIds,
      } = getDefaultVariationData(
        storedVariationIds,
        variationOptions,
        variationsWithStrengths
      )

      // get interval options and a candidate for the selected interval
      const { newInterval, intervalOptionsArr } = getNewIntervalInfo(
        newVariationIds,
        newSelectedVariations,
        frontendFilterSettings.interval[0]
      )
      // if there was a url parameter with a selected interval and it's valid, use it. if not, use the candidate returned from getNewIntervalInfo
      if (
        storedInterval &&
        intervalOptionsArr.some(option => option.id === storedInterval)
      ) {
        finalInterval = [storedInterval as BaleenIntervals]
      } else {
        finalInterval = newInterval
      }
      setVariations(prevVariations => {
        // if there are no variations, return the same empty array so we don't trigger infinite loop
        if (prevVariations.length === 0 && variationsWithStrengths.length === 0)
          return prevVariations
        return cloneDeep(variationsWithStrengths)
      })
      setFrontendFilterSettings(prevSettings => {
        if (
          prevSettings.variationIds.length === 0 &&
          variationsWithStrengths.length === 0
        ) {
          return prevSettings
        }
        return {
          ...prevSettings,
          variationIds: newVariationIds,
          interval: finalInterval,
          yAxis:
            prevSettings.yAxis.length > 0
              ? prevSettings.yAxis
              : ['batchStrength'],
        }
      })
      setIntervalOptions(intervalOptionsArr)

      setMixVariationOptions(variationOptions)
      setSelectedVariations(cloneDeep(newSelectedVariations))

      setSamples(digestedSamples)
      setOutliers(digestedOutliers)
      setOriginalOutliers(digestedOriginalOutliers)
      setSaveBtnText('Save')
      setIsLoading(false)
      localStorage.removeItem('baleenInterval')
      localStorage.removeItem('baleenVariations')
    },
    [
      frontendFilterSettings.interval,
      frontendFilterSettings.isMetric,
      frontendFilterSettings.yAxis,
      getNewIntervalInfo,
    ]
  )

  // Function to set state based on url parameters in first render
  const setAllFirstRenderFilterSettings = (
    newSettings: IURLParamSettings,
    finalDataColumnIds: string[],
    hasMixGroup: boolean,
    hasMixVariation: boolean,
    variationIds: string[],
    interval: BaleenIntervals[],
    yAxis: BaleenYAxis[],
    isMetric: boolean
  ) => {
    if (!yAxisOptions.some(option => option.id === yAxis[0]))
      yAxis = ['batchStrength']
    // if there's no customer or plant, there can't be any mix group, variation ids or intervals
    if (
      newSettings.divisionId.length === 0 ||
      newSettings.plantId.length === 0
    ) {
      setFilterSettings({
        ...newSettings,
        plantId: newSettings.divisionId.length !== 0 ? newSettings.plantId : [],
        mixGroup: [],
      })
      setFrontendFilterSettings(prevSettings => ({
        ...prevSettings,
        variationIds: [],
        interval: [],
        yAxis: yAxis,
        dataColumns: finalDataColumnIds,
        isMetric: isMetric,
      }))
    } else {
      if (newSettings.mixGroup[0])
        newSettings.mixGroup[0] = Number(newSettings.mixGroup[0])
      setFilterSettings(newSettings)
      setFrontendFilterSettings(prevSettings => ({
        ...prevSettings,
        variationIds: hasMixGroup ? variationIds : [],
        interval: hasMixGroup && hasMixVariation ? interval : [],
        yAxis: yAxis,
        dataColumns: finalDataColumnIds,
        isMetric: isMetric,
      }))
    }
  }

  // add filter chips based on filterSettings
  const addFilterSettingsFilterChips = useCallback(
    (setting: string, filterChips: IActiveFilter[], id: any) => {
      switch (setting) {
        case 'divisionId':
          const customer = customerOptions.find(
            (option: ICustomerOption) => Number(id) === option.divisionId
          )
          if (customer)
            filterChips.push({
              property: setting,
              label: customer.name,
              id: customer.id,
            })
          break
        case 'plantId':
          const plant = plantOptions.find(
            option => Number(id) === option.plantId
          )
          if (plant)
            filterChips.push({
              property: setting,
              label: plant.displayName,
              id: plant.plantId,
            })
          break
        case 'mixGroup':
          const mixGroup = mixGroupOptions.find(
            option => Number(id) === option.id
          )
          if (mixGroup)
            filterChips.push({
              property: setting,
              label: mixGroup.name,
              id: mixGroup.id,
            })
          break
        default:
          break
      }
    },
    [customerOptions, plantOptions, mixGroupOptions]
  )

  const getNewOutlierStatusAfterGraphClick = (
    currentOutlierStatus: BaleenOutlierStatus,
    outlierType: BaleenOutlierType
  ) => {
    let newOutlierStatus: BaleenOutlierStatus = 'Sample'
    if (currentOutlierStatus === 'Sample')
      newOutlierStatus = 'PotentialOutlierChecked'
    else if (
      currentOutlierStatus === 'PotentialOutlierChecked' &&
      outlierType !== null
    )
      newOutlierStatus = 'PotentialOutlierUnchecked'
    else if (
      currentOutlierStatus === 'PotentialOutlierUnchecked' &&
      outlierType !== null
    )
      newOutlierStatus = 'PotentialOutlierChecked'
    return newOutlierStatus
  }

  // Function to handle variability data points being clicked
  const handlePointClick = (
    batchId: number,
    outlierStatus: BaleenOutlierStatus,
    outlierReasonDropdown: string | null,
    variationId: string,
    outlierType: BaleenOutlierType
  ) => {
    setSaveBtnText('Save')
    if (outlierStatus === 'Outlier') {
      const snackbarMsg = setShowSnackbar(true)
      setSnackbarMessage({
        id: `${batchId} ${snackbarMsg}`,
        message: 'Not in edit mode, see Outliers table',
      })
      return
    }
    if (outlierType === null && outlierReasonDropdown) {
      showPointClickConfirmationModal(
        batchId,
        variationId,
        outlierStatus,
        setModalOpen,
        setModalData
      )
      return
    }
    // Force status to 'Sample' if the modal is open
    const newOutlierStatus = getNewOutlierStatusAfterGraphClick(
      outlierStatus,
      outlierType
    )
    const clearReason =
      newOutlierStatus === 'Sample' && outlierReasonDropdown === null
    const snackbarMsg =
      newOutlierStatus === 'Sample' ||
      newOutlierStatus === 'PotentialOutlierUnchecked'
        ? 'Outlier unchecked'
        : 'Outlier checked'
    setSamples(prevSamples => {
      // find the matching sample and change its status
      return changeSampleOutlierStatus(
        batchId,
        prevSamples,
        newOutlierStatus,
        true
      )
    })
    setOutliers(prevOutliers => {
      // if an outlier is being removed from outliers table
      return getUpdatedOutliersAfterPointClick(
        batchId,
        prevOutliers,
        samples,
        outlierStatus,
        newOutlierStatus
      )
    })
    setVariations(prevVariations => {
      // find the matching variation and nested sample. change the nested sample's status
      return changeVariationSampleOutlierStatus(
        batchId,
        variationId,
        prevVariations,
        newOutlierStatus,
        clearReason
      )
    })
    setSelectedVariations(prevSelectedVariations => {
      // find the matching variation and nested sample. change the nested sample's status
      return changeVariationSampleOutlierStatus(
        batchId,
        variationId,
        prevSelectedVariations,
        newOutlierStatus,
        clearReason
      )
    })
    setShowSnackbar(true)
    setSnackbarMessage({
      id: `${batchId} ${snackbarMsg}`,
      message: snackbarMsg,
    })
  }

  const handleSnackbarClose = () => {
    setShowSnackbar(false)
  }

  // Send Kelowna new, modified, or removed outliers
  const handleSubmit = (
    outliersToAdd: BaleenSample[],
    outliersToRemove: BaleenSample[]
  ) => {
    const changedSamplesDictionary: IBaleenSamplesObj = {}
    setIsSaving(true)
    setIsSaveModalOpen(false)
    const outlierArray: IFormattedOutlierForSave[] = []
    const newOriginalOutliers: IBaleenSamplesObj = cloneDeep(originalOutliers)
    const params: IPopulateOutlierSavePayloadArrayParams = {
      newOriginalOutliers,
      outlierArray,
      outliersToAdd,
      outliersToRemove,
      changedSamplesDictionary,
    }
    populateOutlierSavePayloadArray(params)

    putUpdateOutliers(outlierArray)
      .then(result => {
        if (result.ok) {
          const {
            newSamples,
            newOutliers,
            newSelectedVariations,
            newVariations,
          } = getNewVariationsAndSamplesAfterSaving({
            samples,
            outliers,
            variations,
            selectedVariations,
            changedSamplesDictionary,
            newOriginalOutliers,
          })
          setSamples(newSamples)
          setOutliers(newOutliers)
          setOriginalOutliers(newOriginalOutliers)
          setSelectedVariations(newSelectedVariations)
          setVariations(newVariations)
          setIsSaveBtnDisabled(true)
          setIsSaving(false)
          setSaveBtnText('Saved')
        } else {
          setIsSaving(false)
          handleResNotOk(result, setErrorMessage)
        }
      })
      .catch(e => {
        setIsSaving(false)
        console.error(e)
      })
  }

  const filterPanel = [
    {
      name: 'CUSTOMER',
      category: 'customer',
      filters: [
        {
          property: 'producer',
          name: 'Producer',
          componentType: 'CustomerAndLocationSelect',
        },
      ],
    },
    {
      name: 'MIX DESIGN',
      category: 'mixDesign',
      filters: [
        {
          property: 'mixGroup',
          name: 'Mix Group *',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: mixGroupOptions,
          onChangeHandler: handleMixGroupChange,
          disabled: isMixGroupFilterDisabled,
          renderOption: option => (
            <MixGroupOptionWithBaselineConditions option={option} />
          ),
        },
        {
          property: 'mixVariation',
          name: `Mix Variation ID (Max ${BALEEN_SELECTED_VARIATIONS_LIMIT})`,
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: mixVariationOptions,
          onChangeHandler: handleMixVariationChange,
          getOptionDisabled: getVariationOptionDisabled,
          disabled: isMixVariationFilterDisabled,
          renderTags: (value: FilterOption[]) => {
            // if all is selected, only render the All chip in the materialui autocomplete component
            // otherwise render the variation id chips
            const isAllSelected = frontendFilterSettings.variationIds.includes(
              'all'
            )
            if (isAllSelected) {
              const allOption = value.find(option => option.id === 'all')
              return (
                <>
                  {!!allOption && (
                    <Chip
                      style={{ margin: '2px', padding: '4px' }}
                      label={allOption.name}
                      size="small"
                      onDelete={() => handleMixVariationChange(_, [])}
                    />
                  )}
                </>
              )
            } else {
              return (
                <>
                  {value.map(option => (
                    <div key={option.id}>
                      <Chip
                        className={classes.chipRoot}
                        label={option.name}
                        size="small"
                        onDelete={() =>
                          handleMixVariationChange(
                            _,
                            value.filter(
                              valOption => valOption.id !== option.id
                            )
                          )
                        }
                      />
                    </div>
                  ))}
                </>
              )
            }
          },
          renderOption: (option: FilterOption) => {
            // if all is an option, render a different look
            const allSelected =
              frontendFilterSettings.variationIds.includes('all') &&
              selectedVariations.length === variations.length
            if (allSelected) {
              return (
                <div>
                  <div style={{ marginLeft: option.id !== 'all' ? '8px' : 0 }}>
                    {option.name}
                  </div>
                </div>
              )
            } else {
              return (
                <div>
                  <div>{option.name}</div>
                </div>
              )
            }
          },
        },
      ],
    },
    {
      name: 'DATA',
      category: 'data',
      filters: [
        {
          property: 'interval',
          name: 'Interval',
          label: 'Interval',
          componentType: FilterComponentTypes.TextFieldSelect,
          options: intervalOptions,
          disabled: getIntervalDisabled,
          onChangeHandler: handleIntervalChange,
        },
        {
          property: 'yAxis',
          name: 'Y-Axis',
          label: 'Y-Axis',
          componentType: FilterComponentTypes.TextFieldSelect,
          options: yAxisOptions,
          disabled: getIntervalDisabled,
          onChangeHandler: handleYAxisChange,
        },
        {
          property: 'variationTableColumns',
          name: 'Cards (Max. 4)',
          componentType: FilterComponentTypes.ChipsMulti,
          options: dataColumnOptions,
          onClickHandler: handleChipClick,
          disabled: getCardOptionDisabled,
          heading: (
            <>
              <div
                style={{ marginTop: '8px', paddingLeft: '4px', width: '100%' }}
              >
                <Typography variant="body1">Cards</Typography>
              </div>
              <div style={{ paddingLeft: '4px', width: '100%' }}>
                <Typography
                  style={{
                    color: baseColors.text.secondary,
                    fontStyle: 'italic',
                  }}
                  variant="caption"
                >
                  Max 4
                </Typography>
              </div>
            </>
          ),
        },
      ],
    },
    {
      name: 'MEASUREMENT',
      category: 'isMetric',
      filters: [
        {
          property: 'isMetric',
          name: 'Measurements',
          componentType: FilterComponentTypes.RadioSingleSelect,
          options: measurementOptions,
          onChangeHandler: radioSingleSelectChangeHandler,
          isHorizontal: true,
          color: 'secondary',
        },
      ],
    },
  ]

  useEffect(() => {
    if (!isFirstRender) {
      const settings = {
        ...filterSettings,
        ...frontendFilterSettings,
      }
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(settings, 'Baleen')
      )
    } else {
      const params = new URLSearchParams(search)
      const settings = {
        ...filterSettings,
        ...frontendFilterSettings,
      }
      const newSettings = getNewSettings(settings, params) as IURLParamSettings
      const {
        variationIds,
        interval,
        yAxis,
        dataColumnIds,
      } = extractFrontendSettings(newSettings)
      const isMetric = newSettings.isMetric ?? false
      delete newSettings.variationIds
      delete newSettings.interval
      delete newSettings.dataColumns
      delete newSettings.isMetric
      delete newSettings.yAxis
      const hasMixGroup = newSettings.mixGroup.length > 0
      const hasMixVariation = variationIds.length > 0
      let finalDataColumnIds: string[] = []
      for (const id of dataColumnIds) {
        if (dataColumnOptions.some(option => option.id === id)) {
          finalDataColumnIds.push(id)
        }
      }
      setAllFirstRenderFilterSettings(
        newSettings,
        finalDataColumnIds,
        hasMixGroup,
        hasMixVariation,
        variationIds,
        interval,
        yAxis,
        isMetric
      )
      const selectedDataColumnOptions = matchhDataColumnToDataColumnIds(
        dataColumnIds
      )
      setSelectedDataColumns(selectedDataColumnOptions)
      if (interval.length > 0)
        localStorage.setItem('baleenInterval', interval[0])
      if (variationIds.length > 0)
        localStorage.setItem('baleenVariations', JSON.stringify(variationIds))
      setIsFirstRender(false)
    }
  }, [filterSettings, frontendFilterSettings, isFirstRender, search])

  /** Get Division Names for Customer filter options */
  useEffect(() => {
    if (customerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(result => {
          if (result.ok)
            result
              .json()
              .then(data => {
                setCustomerOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  /** Get Mix Group options for autocomplete */
  useEffect(() => {
    if (!currentCustomer.plant) return
    setIsLoading(true)
    getMixGroupByPlant(currentCustomer.plant.plantId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            const convertedOptions = data.map((option: any) => {
              return {
                id: option.mixDesignId,
                name: option.mixCode,
                mixCode: option.mixCode,
                baseLineCementReduction: option.baseLineCementReduction,
                baseLineCO2: option.baseLineCO2,
              }
            })
            setMixGroupOptions(convertedOptions)
            setIsLoading(false)
          })
        }
      })
      .catch(e => console.error(e))
  }, [currentCustomer.plant])

  useEffect(() => {
    // just in case the person switches pages quickly
    return () => {
      localStorage.removeItem('baleenInterval')
      localStorage.removeItem('baleenVariations')
    }
  }, [])

  useEffect(() => {
    if (filterSettings.mixGroup.length === 0) return
    if (Number(filterSettings.mixGroup[0]) === variations[0]?.mixDesignId)
      return
    setIsLoading(true)
    const abortController = new AbortController()
    const getVariations = () => {
      getMixGroupWithSamples(
        filterSettings.mixGroup[0],
        {},
        {},
        abortController
      )
        .then(result => {
          if (result.ok) {
            result.json().then(data => {
              handleMixGroupWithSamplesData(data)
            })
          }
        })
        .catch(e => {
          if (!isAbortedFetchError) {
            console.error(e)
          }
        })
    }
    getVariations()

    return () => {
      abortController.abort()
    }
  }, [filterSettings.mixGroup, variations, handleMixGroupWithSamplesData])

  // Add filter chips based on selections in filter panel
  useEffect(() => {
    const filterChips: IActiveFilter[] = []
    for (const setting in filterSettings) {
      const selectedValuesArray =
        filterSettings[setting as keyof typeof filterSettings]
      if (!selectedValuesArray.length) continue
      const id = selectedValuesArray[0]
      addFilterSettingsFilterChips(setting, filterChips, id)
    }

    if (frontendFilterSettings.variationIds.includes('all')) {
      filterChips.push({
        property: 'variationIds',
        label: `Variation ID: All`,
        id: 'all',
      })
    } else {
      frontendFilterSettings.variationIds.forEach(id => {
        const variation = mixVariationOptions.find(option => id === option.id)
        if (variation)
          filterChips.push({
            property: 'variationIds',
            label: `Variation ID: ${variation.name}`,
            id: variation.id,
          })
      })
    }

    frontendFilterSettings.dataColumns.forEach(selectedColumn => {
      const column = dataColumnOptions.find(
        option => selectedColumn === option.id
      )
      if (column)
        filterChips.push({
          property: 'dataColumns',
          label: column.name,
          id: column.id,
        })
    })
    setFilterCount(filterChips.length)
    setActiveFilters(filterChips)
  }, [
    filterSettings,
    frontendFilterSettings.dataColumns,
    frontendFilterSettings.variationIds,
    customerOptions,
    plantOptions,
    mixGroupOptions,
    mixVariationOptions,
    intervalOptions,
    addFilterSettingsFilterChips,
  ])

  useEffect(() => {
    // if validation checks are active, don't use logic contained here to determine if button is disabled
    if (isValidationCheckActive) return
    let isSaveBtnTooltipVisibleVar = false
    // if at least 1 outlier has the proper formatting, enable save button
    let isDisabled = true
    for (const outlier of outliers) {
      const isOriginalOutlier = !!originalOutliers[outlier.batchId]
      // determine if the current outlier is valid for submission.
      isDisabled = getIsOutlierFormatInvalidForSubmission(
        outlier,
        originalOutliers
      )
      if (isDisabled && isOriginalOutlier) {
        const currentOutlierReasonDropdown = outlier.outlierReasonDropdown
        const currentOutlierReasonTextField = outlier.outlierReasonTextField
        const originalOutlierReasonDropdown =
          originalOutliers[outlier.batchId].outlierReasonDropdown
        const originalOutlierReasonTextField =
          originalOutliers[outlier.batchId].outlierReasonTextField
        // if an original outlier is invalid because its reason has changed but is invalid, show tooltip
        if (
          !(
            currentOutlierReasonDropdown === originalOutlierReasonDropdown &&
            originalOutlierReasonTextField === currentOutlierReasonTextField
          )
        ) {
          isSaveBtnTooltipVisibleVar = true
        }
      } else if (isDisabled && !isOriginalOutlier)
        isSaveBtnTooltipVisibleVar = true
      if (!isDisabled) break
    }
    setIsSaveBtnDisabled(isDisabled)
    setIsSaveBtnTooltipVisible(isDisabled && isSaveBtnTooltipVisibleVar)
  }, [outliers, originalOutliers, isValidationCheckActive])

  useEffect(() => {
    // if validation checks aren't active, don't use the logic contained here to determine if the button is disabled
    if (!isValidationCheckActive) return
    const errors: ISubmitErrors = {}
    getBaleenOutlierSubmitErrors(errors, outliers, originalOutliers)
    let isInvalid = true
    for (const outlier of outliers) {
      // determine if the current outlier is valid for submission
      isInvalid = getIsOutlierFormatInvalidForSubmission(
        outlier,
        originalOutliers
      )
      if (!isInvalid) break
    }
    if (Object.values(errors).length === 0) {
      setSubmitErrorsPerOutlier({})
      setIsSaveBtnTooltipVisible(false)
      if (!isInvalid) {
        setIsSaveBtnDisabled(false)
      } else {
        setIsSaveBtnDisabled(true)
      }
    } else if (Object.values(errors).length !== 0 || isInvalid) {
      setSubmitErrorsPerOutlier({ ...errors })
      setIsSaveBtnDisabled(true)
      setIsSaveBtnTooltipVisible(true)
    }
  }, [outliers, originalOutliers, isValidationCheckActive])

  // Determine if the Clear All Unchecked button should be disabled and if
  // the tooltip for it should show.
  useEffect(() => {
    let isDisabled = true
    let isConfirmedOutlierUnchecked
    for (const outlier of outliers) {
      if (
        outlier.outlierType === null &&
        outlier.outlierStatus === 'PotentialOutlierUnchecked'
      ) {
        isDisabled = false
        break
      }
      if (
        outlier.outlierType !== null &&
        outlier.outlierStatus === 'PotentialOutlierUnchecked'
      ) {
        isConfirmedOutlierUnchecked = true
      }
    }
    setIsClearAllUncheckedBtnDisabled(isDisabled)
    if (isDisabled && isConfirmedOutlierUnchecked)
      setIsClearAllUncheckedTooltipVisible(true)
    else setIsClearAllUncheckedTooltipVisible(false)
  }, [outliers, originalOutliers])

  // Get the value for filter components in the filter panel
  const getValue = (filter: Filter) => {
    if (filter.property === 'mixGroup') {
      const selectedOption = mixGroupOptions.find(
        option => option.id === filterSettings.mixGroup[0]
      )
      return selectedOption ?? null
    } else if (filter.property === 'mixVariation') {
      return mixVariationOptions.filter(option =>
        frontendFilterSettings['variationIds'].includes(option.id)
      )
    } else if (filter.property === 'interval') {
      const selectedOption = intervalOptions.find(
        option => option.id === frontendFilterSettings.interval[0]
      )
      return selectedOption ? selectedOption.id : ''
    } else if (filter.property === 'yAxis') {
      const selectedOption = yAxisOptions.find(
        option => option.id === frontendFilterSettings.yAxis[0]
      )
      return selectedOption ? selectedOption.id : ''
    } else if (filter.property === 'isMetric') {
      return frontendFilterSettings.isMetric
    }
  }

  return (
    <>
      <Backdrop open={isLoading} className={classes.loadingScreen}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em', position: 'relative' }}
      >
        <DialogModal
          modalOpen={modalOpen}
          modalType={DialogModalType.warning}
          headerText={'Remove Outlier Selection'}
          contentText={
            <Typography variant="body2">
              You have checked this outlier and provided a reason. By
              continuing, you will remove this outlier.{' '}
              <strong>Do you wish to remove this outlier before saving?</strong>
            </Typography>
          }
          parentClasses={modalClasses}
          modalSize={DialogModalSize.Small}
          handleCancel={() => {
            setModalOpen(false)
            setModalData(null)
          }}
          handleConfirm={() => {
            setModalOpen(false)
            handlePointClick(
              modalData.batchId,
              modalData.outlierStatus,
              modalData.outlierReasonDropdown,
              modalData.variationId,
              modalData.outlierType
            )
          }}
          cancelButtonText="No, Go Back"
          confirmButtonText="Yes, Continue And Remove"
          hasAction={true}
          hasCard={false}
        />
        <DialogModal
          modalOpen={isUnsavedDataModalOpen}
          modalType={DialogModalType.warning}
          headerText={UNSAVED_CHANGES_MODAL_HEADER_TEXT}
          contentText={UNSAVED_CHANGES_MODAL_CONTENT_TEXT}
          parentClasses={modalClasses}
          modalSize={DialogModalSize.Small}
          handleCancel={() => {
            setIsUnsavedDataModalOpen(false)
            setUnsavedChangesModalData(null)
          }}
          handleConfirm={() => confirmUnsavedChanges()}
          cancelButtonText={UNSAVED_CHANGES_MODAL_CANCEL_TEXT}
          confirmButtonText={UNSAVED_CHANGES_MODAL_CONFIRM_TEXT}
          hasAction={true}
          hasCard={false}
        />
        <div style={{ position: 'relative' }}>
          <div
            className={
              isFilterPanelOpen
                ? classes.wrapperShifted
                : classes.wrapperUnshifted
            }
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2">Baleen</Typography>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: '32px' }}
                >
                  Review data set to identify outliers and analyze graphs.
                </Typography>
                <Typography variant="h3">Outlier Analysis</Typography>
                <Typography variant="body1">
                  Selecting outliers from the graph or the Samples table will
                  populate selected data in the Outliers table. The Outliers
                  table allows you to select a reason for why the Ticket ID is
                  an outlier and save the selection. Clicking the Filters button
                  will expand the filtering selection and allow you to filter
                  different types of data to help focus your analysis.
                </Typography>
              </Grid>
              {!!getCustomerAndMixGroupLabel() && (
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="secondary"
                    style={{ margin: 0, paddingBottom: '12px' }}
                  >
                    {getCustomerAndMixGroupLabel()}
                  </Typography>
                </Grid>
              )}
              {selectedVariations.length > 0 && (
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <BaleenVariationTableLogical
                    selectedMixVariations={selectedVariations}
                    selectedDataColumns={selectedDataColumns}
                    frontendFilterSettings={frontendFilterSettings}
                    mixVariations={variations}
                  />
                </Grid>
              )}
              {selectedVariations.length === 0 && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Use the filters below to select a producer, location, and
                    mix group to populate data.
                  </Alert>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <div className={classes.filterPanelContainer}>
          <FilterPanelLogical
            filterPanel={filterPanel}
            parentClasses={classes}
            expandFilters={expandFilters}
            setExpandFilters={setExpandFilters}
            helperVariables={helperVariables}
            open={isFilterPanelOpen}
            setOpen={setIsFilterPanelOpen}
            addDataSettings={filterSettings}
            setAddDataSettings={setFilterSettings}
            getValue={getValue}
          />
          <div
            className={
              isFilterPanelOpen
                ? classes.wrapperShifted
                : classes.wrapperUnshifted
            }
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <FilterButton
                  parentClasses={classes}
                  filterCount={filterCount}
                  open={isFilterPanelOpen}
                  onClickHandler={filterButtonClickHandler}
                />
                <FilterPanelChips
                  activeFilters={activeFilters}
                  chipClickHandler={deleteChipHandler}
                  buttonClickHandler={deleteAllChipHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined" style={{ padding: '16px' }}>
                  <VariabilityGraphLogical
                    selectedMixVariations={selectedVariations}
                    selectedProperty={frontendFilterSettings.yAxis[0]}
                    isFilterPanelOpen={isFilterPanelOpen}
                    isMetric={frontendFilterSettings.isMetric}
                    page={VariabilityGraphPage.BALEEN}
                    interval={frontendFilterSettings.interval[0]}
                    handlePointClick={handlePointClick}
                    showSnackbar={showSnackbar}
                    handleSnackbarClose={handleSnackbarClose}
                    snackbarMessage={snackbarMessage}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">Outliers</Typography>
                <Typography variant="body1">
                  To mark a sample as an outlier, check the box in the “Outlier”
                  column. You can try marking and unmarking outliers to see how
                  it affects the summary cards at the top of the page. When
                  you’re sure about an outlier, check the checkbox next to your
                  selection and add a reason to why it is an outlier. Then, save
                  your selection. To remove an outlier or selection, click the
                  red X, and don’t forget to save before leaving Baleen.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BaleenSamplesTableLogical
                  setSamples={setOutliers}
                  frontendFilterSettings={frontendFilterSettings}
                  tableType={SimpleTableTypes.BaleenOutliersTable}
                  rowsPerPageOptions={[10, 20, 30]}
                  headCells={getBaleenSampleHeaders(
                    SimpleTableTypes.BaleenOutliersTable,
                    frontendFilterSettings.isMetric,
                    frontendFilterSettings.interval[0]
                  )}
                  rows={samplesToRows(
                    outliers,
                    frontendFilterSettings.interval,
                    frontendFilterSettings.variationIds,
                    frontendFilterSettings.isMetric,
                    SimpleTableTypes.BaleenOutliersTable,
                    submitErrorsPerOutlier
                  )}
                  handleSampleCheckboxChange={handleOutlierCheckboxChange}
                  removePotentialOutlierFromOutliersTable={
                    removePotentialOutlierFromOutliersTable
                  }
                  handleTextFieldSelectChange={handleTextFieldSelectChange}
                  handleTextFieldChange={handleTextFieldChange}
                  textFieldSelectOptions={Object.values(BaleenOutlierReasons)}
                  handleEditOrUndoClick={handleEditOrUndoClick}
                  setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                  areTableRowsDisabled={isSaving}
                  tableSettings={outliersTableSettings}
                  setTableSettings={setOutliersTableSettings}
                />
                {outliers.length === 0 && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Select a data point from the chart or table to edit
                      outlier status.
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} style={{ position: 'relative' }}>
                <div style={{ display: 'flex', width: 'calc(100% - 300px)' }}>
                  {Object.keys(submitErrorsPerOutlier).length > 0 && (
                    <Typography
                      variant="body2"
                      style={{
                        color: baseColors.error.light,
                        display: 'inline',
                        marginLeft: 'auto',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      Remove unselected outliers or check the outlier column,
                      add reasoning to save.
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                    flexWrap: 'nowrap',
                    width: '300px',
                  }}
                >
                  {outliers.length > 0 && (
                    <>
                      <Tooltip
                        title={
                          isClearAllUncheckedTooltipVisible
                            ? 'You cannot clear previously confirmed outliers before saving'
                            : ''
                        }
                      >
                        <span>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={clearAllUnchecked}
                            disabled={isClearAllUncheckedBtnDisabled}
                          >
                            Clear All Unchecked
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={
                          isSaveBtnTooltipVisible &&
                          saveBtnText === 'Save' &&
                          tssCanWrite(JWT.roles)
                            ? 'You must confirm outlier selection and add reason to save'
                            : ''
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveBtnClick}
                            disabled={
                              isSaveBtnDisabled || !tssCanWrite(JWT.roles)
                            }
                          >
                            {isSaving ? (
                              <>
                                <CircularProgress
                                  color="inherit"
                                  size="1em"
                                  style={{ marginRight: '8px' }}
                                />{' '}
                                Saving
                              </>
                            ) : (
                              <>{saveBtnText}</>
                            )}
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">Samples</Typography>
                <Typography variant="body1">
                  Selecting an outlier in the table below will move that Ticket
                  ID to the Outliers table above. To confirm this selection as
                  an outlier you must provide a reason in the table above and
                  save the selection.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BaleenSamplesTableLogical
                  setSamples={setSamples}
                  frontendFilterSettings={frontendFilterSettings}
                  tableType={SimpleTableTypes.BaleenSamplesTable}
                  rowsPerPageOptions={[50, 75, 100]}
                  headCells={getBaleenSampleHeaders(
                    SimpleTableTypes.BaleenSamplesTable,
                    frontendFilterSettings.isMetric,
                    frontendFilterSettings.interval[0]
                  )}
                  rows={samplesToRows(
                    samples,
                    frontendFilterSettings.interval,
                    frontendFilterSettings.variationIds,
                    frontendFilterSettings.isMetric,
                    SimpleTableTypes.BaleenSamplesTable
                  )}
                  handleSampleCheckboxChange={handleSampleCheckboxChange}
                  tableSettings={samplesTableSettings}
                  setTableSettings={setSamplesTableSettings}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <BaleenDialogModalLogical
          outliers={outliers}
          originalOutliers={originalOutliers}
          isSaveModalOpen={isSaveModalOpen}
          setIsSaveModalOpen={setIsSaveModalOpen}
          modalClasses={modalClasses}
          customerAndMixGroupLabel={getCustomerAndMixGroupLabel()}
          handleSubmit={handleSubmit}
        />
        <AddDataProgressLostAlertLogical
          hasUserInput={getIsUnsavedChangesModalVisible(
            originalOutliers,
            selectedVariations,
            []
          )}
        />
      </Container>
      <DialogModal
        modalOpen={errorMessage.length > 0}
        modalType={DialogModalType.error}
        headerText={'Your data could not be saved'}
        contentText={
          <>
            <Typography variant="body2">
              An error occurred when trying to perform your request. This can
              happen when there is an issue connecting to the server or an issue
              with your internet.{' '}
            </Typography>
            <Typography variant="body2" style={{ marginTop: '16px' }}>
              First, please confirm your internet connection. If the issue
              persists, reach out to the <u>#info-orca</u> Slack channel for
              assistance and, if available, include the error code below.
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: '16px' }}
            >
              {errorMessage}
            </Typography>
          </>
        }
        parentClasses={errorModalClasses}
        modalSize={DialogModalSize.Small}
        handleConfirm={() => setErrorMessage('')}
        confirmButtonText={'Go Back'}
        hasAction={true}
        hasCard={false}
      />
    </>
  )
}

export default BaleenView
