import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {
  SimpleTableComplexCell,
  SimpleTableRow,
} from '../../../Common/Logic/Types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { baseColors } from '../../../theme/colors'
import { VariationTypes, AddDataTableVariation } from '../../Logic/Types'

export interface IMixVariationCollapsibleRow {
  row: SimpleTableRow
  displaySelectors: (arg1: any) => null | JSX.Element
  displayCell: (
    rowCell: string | number | SimpleTableComplexCell | null,
    id: string | number,
    key?: string
  ) => null | string | number | JSX.Element | JSX.Element[] | undefined
  headCells: Array<{
    id: string
    name: string
    sortable?: boolean
    width: string
  }>
  parentClasses: ClassNameMap<string>
  selected: Array<number | string>
}

const useStyles = makeStyles(theme => ({
  tableRow: {
    borderBottom: '0.5px solid rgba(224, 224, 224, 1)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  variationTypeIconContainer: {
    display: 'flex',
    verticalAlign: 'bottom',
    alignItems: 'center',
    width: '28px',
    justifyContent: 'center',
  },
  variationTypeIcon: {
    fontSize: '16px',
    marginLeft: '4px',
  },
}))

const MixVariationCollapsibleRow = (props: IMixVariationCollapsibleRow) => {
  const {
    row,
    displaySelectors,
    displayCell,
    headCells,
    parentClasses,
    selected,
  } = props
  const classes2 = useStyles()
  return (
    <Table size="medium">
      <TableBody>
        {row.variations?.map((variation: AddDataTableVariation) => (
          <TableRow
            key={variation.variationId}
            className={classes2.tableRow}
            hover
            selected={selected.includes(variation.variationId)}
          >
            {
              <TableCell
                padding="checkbox"
                className={parentClasses.selectorCell}
              >
                <div className={classes2.container}>
                  <div className={classes2.variationTypeIconContainer}>
                    {(variation.variationType === VariationTypes.BASELINE ||
                      variation.variationType === VariationTypes.OPTIMIZED) && (
                      <FiberManualRecordIcon
                        style={{
                          color:
                            variation.variationType === VariationTypes.BASELINE
                              ? baseColors.variations.baseline
                              : baseColors.variations.optimized,
                        }}
                        className={classes2.variationTypeIcon}
                      />
                    )}
                  </div>
                  {displaySelectors(variation.variationId)}
                </div>
              </TableCell>
            }
            {variation.cells.map(
              (
                variationCell: string | number | SimpleTableComplexCell | null,
                index: number
              ) => (
                <TableCell
                  className={
                    index + 1 <= headCells.length
                      ? parentClasses[headCells[index + 1].id]
                      : undefined
                  }
                  key={parentClasses[headCells[index + 1].id]}
                  style={
                    headCells[index + 1].id === 'editAssociation'
                      ? { padding: '0 16px' }
                      : { padding: '4px 16px', border: 'none' }
                  }
                >
                  {displayCell(variationCell, headCells[index + 1].id)}
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default MixVariationCollapsibleRow
