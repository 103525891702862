import { Tab, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import NavSubMenuItem from './NavSubMenuItem'
import { baseColors } from '../../theme/colors'

const useStyles = makeStyles({
  tabText: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  listItem: {
    width: 'inherit',
  },
  wideMenu: {
    width: '210px',
  },
  tabLabel: {
    color: baseColors.text.primary,
  },
})

function NavMenuItem(props) {
  const [anchorEl, setAnchorEl] = useState(() => null)
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const { menuItem, setTabsValue } = props

  const handleOnTabClick = event => {
    setAnchorEl(event.currentTarget)
    setTabsValue(menuItem.department)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setTabsValue(null)
  }

  /**
   * Creates the menu listings as an array of arrays, where the first element of the array
   * is the MenuItem and the second element of the array are the sub-paths for that project
   *
   * Done this way as Menu doesn't accept fragments as children (gives a warning but doesn't throw an error)
   * @param {{title: String, path: String}} pathObj The path objects of a project
   * @returns { [MenuItem, NavSubMenuItem][]} Menu Listings for a department
   */
  const createMenuListings = pathObj => [
    pathObj.title === 'Concrete' ||
    pathObj.title === 'QuickSight Dashboards' ||
    pathObj.title === 'Producers' ||
    pathObj.title === 'Hardware'
      ? pathObj.subPaths.map(project => (
          <MenuItem
            onClick={handleCloseMenu}
            key={project.title}
            component={Link}
            to={project.path}
          >
            {project.title}
          </MenuItem>
        ))
      : [
          <MenuItem
            onClick={handleCloseMenu}
            key={pathObj.title}
            component={Link}
            to={pathObj.path}
          >
            {pathObj.title}
          </MenuItem>,
          ...pathObj.subPaths.map(subPath => (
            <NavSubMenuItem onClick={handleCloseMenu} {...subPath} />
          )),
        ],
  ]

  return (
    <>
      <Tab
        label={
          <Typography variant="h5" className={classes.tabLabel}>
            {menuItem.department}
          </Typography>
        }
        onClick={handleOnTabClick}
        className={classes.tabText}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.wideMenu,
        }}
        getContentAnchorEl={null}
      >
        {menuItem.projects.map(createMenuListings)}
      </Menu>
    </>
  )
}

NavMenuItem.propTypes = {
  /** Menu item object as defined in projectLists.js */
  menuItem: PropTypes.shape({
    title: PropTypes.string,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        subPaths: PropTypes.array,
      })
    ),
    featuresOfInterest: PropTypes.array,
  }),
  /** Function to set the tabs value */
  setTabsValue: PropTypes.func.isRequired,
}

export default NavMenuItem
