import { Typography } from '@material-ui/core'
import React from 'react'
import { MixGroupConditions } from '../Logic/Types'
import { isNullOrUndefined } from '../../Common/Helpers/GeneralHelpers'

export interface IBaselineMixGroups {
  mixCode: string
  baseLineCementReduction: number
  baseLineCO2: number
  name?: string
  id?: number
  mixDesignId?: number
  timestamp?: string
  plantName?: string
  isCO2Design?: boolean
}
interface IMixGroupOptionWithBaselineConditions {
  option: IBaselineMixGroups
  forceBaselineData?: boolean
}

const MixGroupOptionWithBaselineConditions: React.FC<IMixGroupOptionWithBaselineConditions> = ({
  option,
  forceBaselineData = false,
}) => {
  let text1 = ''
  let text2 = ''
  if (forceBaselineData) {
    // Baseline values of 0 should be shown. Null values should not be shown.
    text1 = !isNullOrUndefined(option.baseLineCO2)
      ? `Baseline ${MixGroupConditions.CO2} Dose: ${option.baseLineCO2}`
      : ''
    text2 = !isNullOrUndefined(option.baseLineCementReduction)
      ? `Baseline Cem Red: ${option.baseLineCementReduction}`
      : ''
  } else {
    // Hide falsy baseline values (including 0).
    text1 = option.baseLineCO2
      ? `Baseline ${MixGroupConditions.CO2} Dose: ${option.baseLineCO2}`
      : ''
    text2 = option.baseLineCementReduction
      ? `Baseline Cem Red: ${option.baseLineCementReduction}`
      : ''
  }

  return (
    <>
      <div>
        {option.plantName ? (
          <Typography variant="body2">{`${option.mixCode} - ${option.plantName}`}</Typography>
        ) : (
          <Typography variant="body2">{option.mixCode}</Typography>
        )}
        <div>
          <Typography variant="caption"> {text1}</Typography>
        </div>
        <div>
          <Typography variant="caption"> {text2}</Typography>
        </div>
      </div>
    </>
  )
}

export default MixGroupOptionWithBaselineConditions
