import React from 'react'
import PropTypes from 'prop-types'
import AlarmsTablePresentational from './AlarmsTablePresentational'

function AlarmsTableLogical(props) {
  AlarmsTableLogical.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Data from the alarms endpoint */
    alarms: PropTypes.array.isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** State setter function to keep track of which alarm has been selected */
    setSelectedAlarm: PropTypes.func.isRequired,
    /** The alarm that has been selected */
    selectedAlarm: PropTypes.object.isRequired,
    /** The number of rows available in the database */
    count: PropTypes.number.isRequired,
    /** Array of objects. List of remote units with plants */
    remoteUnitsWithPlants: PropTypes.array.isRequired,
  }
  const {
    handleTableSort,
    alarms,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    selectedAlarm,
    setSelectedAlarm,
    count,
  } = props

  /** Function to handle selecting table rows */
  const handleClick = (event, row) => {
    if (selectedAlarm.alarmId === row.alarmId) {
      setSelectedAlarm({})
    } else {
      setSelectedAlarm(row)
    }
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  /** Table column headers */
  const headCells = [
    {
      id: 'timestamp',
      numeric: false,
      label: 'Timestamp',
      sortable: true,
    },
    {
      id: 'hmiId',
      numeric: false,
      label: 'HMI ID',
      sortable: true,
    },
    {
      id: 'customerName',
      numeric: false,
      label: 'Customer Name',
      sortable: false,
    },
    {
      id: 'customerLocation',
      numeric: false,
      label: 'Customer Location',
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      sortable: false,
    },
    {
      id: 'code',
      numeric: false,
      label: 'Message',
      sortable: true,
    },
    {
      id: 'resolvedTimestamp',
      numeric: false,
      label: '',
      sortable: true,
    },
    {
      id: 'close',
      numeric: false,
      label: '',
      sortable: false,
    },
  ]

  return (
    <AlarmsTablePresentational
      alarms={alarms}
      handleClick={handleClick}
      selectedAlarm={selectedAlarm}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleTableSort={handleTableSort}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      order={order}
      orderBy={orderBy}
      headCells={headCells}
    />
  )
}

export default AlarmsTableLogical
