import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import AlarmCodeMapper from './AlarmCodeMapper'

type AlarmForTableConstructorAsObject = {
  alarmId: number
  alarmCodeMapper: AlarmCodeMapper
  code: number
  remoteUnitId: number
  customerLocation: string
  customerName: string
  timestamp: string
  resolvedTimestamp: string | null
  hmiId: number | null
}

class AlarmForTable {
  alarmId

  codeMapper

  code

  remoteUnitId

  customerLocation

  customerName

  timestamp

  resolvedTimestamp

  resolved

  hmiId

  constructor(
    alarmId: number,
    code: number,
    remoteUnitId: number,
    customerLocation: string,
    customerName: string,
    timestamp: string,
    resolvedTimestamp: string | null,
    hmiId: number | null,
    alarmCodeMapper: AlarmCodeMapper
  ) {
    this.codeMapper = alarmCodeMapper
    this.alarmId = alarmId
    this.code = code
    this.remoteUnitId = remoteUnitId
    if (customerLocation === ', ') {
      this.customerLocation = 'Location Not Available'
    } else {
      this.customerLocation = customerLocation
    }
    if (customerName === null) {
      this.customerName = 'Name Not Available'
    } else {
      this.customerName = customerName
    }
    this.timestamp = new Date(timestamp)
    if (resolvedTimestamp !== null) {
      this.resolvedTimestamp = new Date(resolvedTimestamp)
    } else {
      this.resolvedTimestamp = null
    }
    this.resolved = resolvedTimestamp !== null
    this.hmiId = hmiId
  }

  get status() {
    if (this.resolved) {
      return 'Resolved'
    }

    return this.codeMapper.alarmDetails(this.code).severity
  }

  get message() {
    return this.codeMapper.alarmDetails(this.code).message
  }

  get alarmCodeObject() {
    return this.codeMapper.alarmDetails(this.code)
  }

  async clearAlarm() {
    const response = await AlarmsManagementDataHelpers.postResolveAlarm(
      this.alarmId
    )
    if (response.ok) {
      this.resolved = true
      this.resolvedTimestamp = new Date()
      return true
    }
    return false
  }

  static fromKelownaObject({
    alarmId,
    code,
    remoteUnitId,
    timestamp,
    resolvedTimestamp,
    customerLocation,
    customerName,
    hmiId,
    alarmCodeMapper,
  }: AlarmForTableConstructorAsObject) {
    return new AlarmForTable(
      alarmId,
      code,
      remoteUnitId,
      customerLocation,
      customerName,
      timestamp,
      resolvedTimestamp,
      hmiId,
      alarmCodeMapper
    )
  }
}

export default AlarmForTable
