import { ConvertKgToLb } from '../../../Common/Helpers/GeneralHelpers'
import Status from './Status'

class Reading extends Status {
  injectionReadingId

  injectionId

  flowRateKgPerSec

  injectedCO2Kg

  constructor(
    injectionReadingId,
    injectionId,
    inletLiquidPressureKPa,
    inletGasPressureKPa,
    upstreamPressureKPa,
    downstreamPressure0KPa,
    downstreamPressure1KPa,
    upstreamTemperatureC,
    flowRateKgPerSec,
    injectedCO2Kg,
    timestamp
  ) {
    super(
      null,
      null,
      inletLiquidPressureKPa,
      inletGasPressureKPa,
      upstreamPressureKPa,
      downstreamPressure0KPa,
      downstreamPressure1KPa,
      upstreamTemperatureC,
      timestamp
    )
    this.injectionReadingId = injectionReadingId
    this.injectionId = injectionId
    this.flowRateKgPerSec = flowRateKgPerSec
    this.injectedCO2Kg = injectedCO2Kg
  }

  get flowRateLbPerSec() {
    return ConvertKgToLb(this.flowRateKgPerSec)
  }

  get injectedCO2Lb() {
    return ConvertKgToLb(this.injectedCO2Kg)
  }

  static fromKelownaObject(kelownaReading) {
    return new Reading(
      kelownaReading.injectionReadingId,
      kelownaReading.injectionId,
      kelownaReading.inletLiquidPressureKPa,
      kelownaReading.inletGasPressureKPa,
      kelownaReading.upstreamPressureKPa,
      kelownaReading.downstreamPressure0KPa,
      kelownaReading.downstreamPressure1KPa,
      kelownaReading.upstreamTemperatureC,
      kelownaReading.flowRateKgPerSec,
      kelownaReading.injectedCO2Kg,
      kelownaReading.timestamp
    )
  }
}
export default Reading
