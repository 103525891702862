/** This file contains recurring logics used on the View Design Page for TSS.
 *  It includes functions for updating colors of the graph by condition
 */

import { co2ColorSet, controlColorSet } from '../Constants/AnalysisConstants'

/** Get the color of the graph according to the mixdesign condition */
export function getColorByCondition(condition, index) {
  let mixdesignColor

  if (condition === 'Control') {
    mixdesignColor = controlColorSet[index % 6]
  } else {
    mixdesignColor = co2ColorSet[index % 6]
  }
  return mixdesignColor
}
