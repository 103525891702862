import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { getCustomerPlants } from '../../Common/Helpers/DataHelpers'
import { Autocomplete } from '@material-ui/lab'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'
import { atomCustomerOptions, atomPlantOptions } from '../../Common/tssAtomsB'
import cloneDeep from 'lodash.clonedeep'
import {
  disableCustomerAndLocationSelect,
  tssCanWrite,
} from '../Logic/TSSLogic'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { baseColors } from '../../theme/colors'
import DialogModal from '../../Common/Components/DialogModal/DialogModal'
import { DialogModalSize, DialogModalType } from '../Logic/Types'
import {
  UNSAVED_CHANGES_MODAL_CANCEL_TEXT,
  UNSAVED_CHANGES_MODAL_CONFIRM_TEXT,
  UNSAVED_CHANGES_MODAL_CONTENT_TEXT,
  UNSAVED_CHANGES_MODAL_HEADER_TEXT,
} from '../Views/BaleenView'

CustomerAndLocationSelect.propTypes = {
  /** Register to collect and validate form inputs. */
  register: PropTypes.func,
  /** List of error messages to display for fields. */
  errors: PropTypes.object,
  /** Boolean to display the plant selection or not. It is not displayed when adding batch data. */
  showPlants: PropTypes.bool,
  /** Return the fetched list of plants to a parent component. Used to fill spreadsheet plant dropdown options. */
  setPlantsInParent: PropTypes.func,
  /** Use to reset mix code search input field*/
  setMixCodeInternal: PropTypes.func,
  /** Boolean to disable the customer and plant selection when viewing associations.*/
  isAssociationView: PropTypes.bool,
  /** Booleans to disable the customer and plant selection when editing a mix.*/
  inAssociation: PropTypes.bool,
  /** Function to reset cascading filter and state values reliant on customer and location */
  resetBaleenDependents: PropTypes.func,
  /** Data required for the CONFIRM action of unsaved changes modal */
  unsavedChangesModalData: PropTypes.object,
  /** Function to set whether the unsaved changes modal is visible */
  setIsUnsavedDataModalOpen: PropTypes.func,
  /** Function to set the data required for the CONFIRM action of unsaved changes modal */
  setUnsavedChangesModalData: PropTypes.func,
  /** Function to determine if there is modified data that hasn't been saved to the database yet */
  getIsUnsavedChangePresent: PropTypes.func,
  /** The original, unmodified, outliers on baleen */
  originalOutliers: PropTypes.object,
  /** The selected variations on baleen */
  selectedVariations: PropTypes.array,
  /** Whether or not the unsaved changes modal is open */
  isUnsavedDataModalOpen: PropTypes.bool,
  /** Classes for the unsaved changes modal */
  modalClasses: PropTypes.object,
  isViewOnly: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isLocationRequired: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: baseColors.info.dark,
    whiteSpace: 'nowrap',
  },
  error: {
    display: 'flex',
    gap: '4px',
    color: baseColors.error.dark,
  },
}))

/**
 * Component used to select the customer and location (plant) in the add data module.
 * @param {Function} register - Register to collect and validate form inputs.
 * @param {Object} errors - List of error messages to display for fields.
 * @param {Boolean} showPlants - Boolean to display the plant selection or not. It is not displayed when adding batch data.
 * @param {Function} setPlantsInParent - Return the fetched list of plants to a parent component. Used to fill spreadsheet plant dropdown options.
 * @param {Function} setMixCodeInternal - Used to reset mix code search input field.
 * @param {Boolean} isAssociationView - Boolean to disable the customer and plant selection when viewing associations.

/**
 * Component for selecting the current customer and location (division and plant).
 */
function CustomerAndLocationSelect(props) {
  const {
    register,
    errors,
    showPlants,
    setPlantsInParent,
    setMixCodeInternal,
    inputsAlwaysEnabled,
    infoIconVisible,
    isAssociationView,
    sizes,
    addDataSettings,
    setAddDataSettings,
    inAssociation,
    isViewOnly,
    resetBaleenDependents,
    unsavedChangesModalData,
    setIsUnsavedDataModalOpen,
    setUnsavedChangesModalData,
    getIsUnsavedChangePresent,
    originalOutliers,
    selectedVariations,
    isUnsavedDataModalOpen,
    modalClasses,
    isLocationRequired,

    isEditMode,
  } = props
  const classes = useStyles()
  const JWT = useRecoilValue(atomJWT)
  /** Get recoil values. */
  const [currentCustomer, setCurrentCustomer] = useRecoilState(
    atomCurrentCustomer
  )
  const customerOptions = useRecoilValue(atomCustomerOptions)
  /* State variable declarations. */
  const [plantOptions, setPlantOptions] = useRecoilState(atomPlantOptions)
  const [plantsAvailable, setPlantsAvailable] = useState(null)
  /* API call to fetch locations based on selected customer. */

  useEffect(() => {
    // determine selected customer
    const selectedCustomer = customerOptions.find(
      c => c.divisionId === Number(addDataSettings.divisionId[0])
    )
    // look for that customer's plant options
    const parseAndSetPlantData = res => {
      res.json().then(data => {
        const filterPlantsCustomerFirst = (plant1, plant2) =>
          // if plant 2 is a customer while plant 1 is not, plant2 gets priority
          !!plant2.monthlyFee - !!plant1.monthlyFee ||
          // otherwise compare by name
          plant1.name.localeCompare(plant2.name)

        const prioritizedSortedPlantList = data.sort(filterPlantsCustomerFirst)
        if (setPlantsInParent) setPlantsInParent(prioritizedSortedPlantList)
        if (showPlants) {
          setPlantOptions(prioritizedSortedPlantList)
          // Autofill plant in edit mode.
          if (addDataSettings.plantId && data.length) {
            const selectedPlant = data.find(
              c => c.plantId === Number(addDataSettings.plantId)
            )
            setCurrentCustomer(prevDetails => ({
              division: selectedCustomer,
              plant: selectedPlant,
            }))
          } else {
            setCurrentCustomer({ division: selectedCustomer, plant: null })
          }
        } else {
          // if there is no plant field
          // and if the newly selected customer is not the same as the previously selected customer..remove the plant selection
          if (
            selectedCustomer.divisionId !== currentCustomer.division?.divisionId
          ) {
            setCurrentCustomer({ division: selectedCustomer, plant: null })
          }
        }
        setPlantsAvailable(data.length !== 0)
      })
    }
    if (selectedCustomer) {
      getCustomerPlants(selectedCustomer.divisionId)
        .then(res => res.ok && parseAndSetPlantData(res))
        .catch(e => console.log(e))
      return
    }
    //clear fields when customer changes
    setPlantsAvailable(null)
    if (setMixCodeInternal) setMixCodeInternal('')
    setPlantOptions([])
    setCurrentCustomer(prevDetails => ({
      ...prevDetails,
      division: null,
      plant: null,
    }))
  }, [
    addDataSettings,
    customerOptions,
    setCurrentCustomer,
    setMixCodeInternal,
    currentCustomer.division,
    setPlantsInParent,
    showPlants,
    setPlantOptions,
  ])

  const getCustomerValue = () => {
    if (customerOptions.length > 0 && addDataSettings.divisionId.length > 0) {
      let idx = customerOptions.findIndex(
        x => x.divisionId === Number(addDataSettings.divisionId[0])
      )
      if (idx > -1) {
        return customerOptions[idx]
      }
    }
    return null
  }

  const getLocationValue = () => {
    if (plantOptions.length > 0 && addDataSettings.plantId.length > 0) {
      let idx = plantOptions.findIndex(
        x => x.plantId === Number(addDataSettings.plantId[0])
      )
      if (idx > -1) {
        return plantOptions[idx]
      }
    }
    return null
  }

  return (
    <Grid container spacing={sizes.spacing}>
      <Grid item xs={sizes.xs} sm={sizes.sm} md={sizes.md} lg={sizes.lg}>
        <Autocomplete
          data-testid={'customerSelect'}
          options={customerOptions}
          value={getCustomerValue()}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => option.name}
          disabled={disableCustomerAndLocationSelect(
            'customer',
            customerOptions,
            inputsAlwaysEnabled,
            JWT.roles,
            isAssociationView,
            inAssociation,
            isViewOnly,
            isEditMode
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Producer *"
              variant="outlined"
              inputRef={register ? register : null}
              size="small"
              name="customer"
              placeholder="Select A Producer"
              error={errors?.customer ? true : false}
              helperText={
                <Grid container lg={12}>
                  {infoIconVisible && (
                    <Grid item className={classes.info}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        data-testid="info-icon"
                      />
                      <Typography
                        style={{ color: baseColors.info.dark }}
                        variant="caption"
                      >
                        {tssCanWrite(JWT.roles) && inAssociation
                          ? 'Associated mixes must have the same producer and location'
                          : 'This customer is in the commissioning phase'}
                      </Typography>
                    </Grid>
                  )}
                  {errors?.customer?.message && (
                    <Grid item>
                      <div data-testid="error-message">
                        <Typography variant="caption" color="error">
                          {errors.customer.message}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </Grid>
              }
            />
          )}
          onChange={(_, newValue) => {
            const newSettings = cloneDeep(addDataSettings)
            if (newValue) {
              newSettings.divisionId = [newValue.divisionId]
              newSettings.plantId = [] //reset plant when a new customer is selected
              if (errors) {
                delete errors.customer
              }
            } else {
              newSettings.divisionId = []
              newSettings.plantId = []
            }
            if (newSettings.mixGroup) newSettings.mixGroup = []
            if (resetBaleenDependents) {
              const showModal = getIsUnsavedChangePresent(
                originalOutliers,
                selectedVariations,
                []
              )
              if (showModal) {
                setIsUnsavedDataModalOpen(true)
                setUnsavedChangesModalData(newSettings)
                return
              }
              resetBaleenDependents()
            }
            setAddDataSettings(newSettings)
          }}
        />
      </Grid>
      <Grid item xs={sizes.xs} sm={sizes.sm} md={sizes.md} lg={sizes.lg}>
        {showPlants && (
          <Autocomplete
            data-testid="plantSelect"
            options={plantOptions}
            value={getLocationValue()}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={option => option.name}
            groupBy={option => (option.monthlyFee ? 'Customer' : 'Other')}
            disabled={
              !currentCustomer.division ||
              disableCustomerAndLocationSelect(
                'location',
                plantOptions,
                inputsAlwaysEnabled,
                JWT.roles,
                isAssociationView,
                inAssociation,
                isViewOnly,
                isEditMode
              )
            }
            renderInput={params => (
              <TextField
                {...params}
                label={`Location ${isLocationRequired ? '*' : ''}`}
                variant="outlined"
                inputRef={register ? register : null}
                size="small"
                name="location"
                placeholder="Select A Location"
                error={errors?.location ? true : false}
                helperText={
                  <Grid container lg={12}>
                    {currentCustomer?.division &&
                      plantsAvailable !== null &&
                      !plantsAvailable && (
                        <Grid item className={classes.error}>
                          <ReportProblemOutlinedIcon
                            fontSize="small"
                            style={{ color: baseColors.error.main }}
                          />
                          <div data-testid="plant-warning-message">
                            <Typography
                              style={{ color: baseColors.error.main }}
                              variant="caption"
                            >
                              This customer does not have a plant location.
                              Please add a location in Salesforce before
                              submitting this mix.
                            </Typography>
                          </div>
                        </Grid>
                      )}
                    {errors?.location?.message && (
                      <Grid item>
                        <div data-testid="error-message">
                          <Typography variant="caption" color="error">
                            {errors.location.message}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                }
              />
            )}
            onChange={(_, newValue) => {
              const newSettings = cloneDeep(addDataSettings)
              if (newValue) {
                newSettings.plantId = [newValue.plantId]
                if (errors) {
                  delete errors.location
                }
              } else {
                newSettings.plantId = []
              }
              if (newSettings.mixGroup) newSettings.mixGroup = []

              if (resetBaleenDependents) {
                const showModal = getIsUnsavedChangePresent(
                  originalOutliers,
                  selectedVariations,
                  []
                )
                if (showModal) {
                  setIsUnsavedDataModalOpen(true)
                  setUnsavedChangesModalData(newSettings)
                  return
                }
              }
              setAddDataSettings(newSettings)
            }}
          />
        )}
        {resetBaleenDependents && (
          <DialogModal
            modalOpen={isUnsavedDataModalOpen}
            modalType={DialogModalType.warning}
            headerText={UNSAVED_CHANGES_MODAL_HEADER_TEXT}
            contentText={UNSAVED_CHANGES_MODAL_CONTENT_TEXT}
            parentClasses={modalClasses}
            modalSize={DialogModalSize.Small}
            handleCancel={() => {
              setIsUnsavedDataModalOpen(false)
              setUnsavedChangesModalData(null)
            }}
            handleConfirm={() => {
              setAddDataSettings(unsavedChangesModalData)
              setIsUnsavedDataModalOpen(false)
              resetBaleenDependents()
              setUnsavedChangesModalData(null)
            }}
            cancelButtonText={UNSAVED_CHANGES_MODAL_CANCEL_TEXT}
            confirmButtonText={UNSAVED_CHANGES_MODAL_CONFIRM_TEXT}
            hasAction={true}
            hasCard={false}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default CustomerAndLocationSelect
