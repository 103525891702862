import { Button, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import GraphFilterBase from './GraphFilterBase'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import theme from '../../theme/muiTheme'

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  addFilterBtn: {
    ...theme.customClasses.filterPanel.filterButtonInactive,
    width: '100%',
    margin: '10px 0',
  },
})

export default function GraphFiltersContainerPresentational(props) {
  GraphFiltersContainerPresentational.propTypes = {
    /** Function updates the property to filter data by. */
    updateFilterProperty: PropTypes.func.isRequired,
    /** Function updates the currently selected range of a filter. */
    updateFilterSelectedRange: PropTypes.func.isRequired,
    /** Running list of filters currently applied to the graph and displayed in the container. */
    selectedFilters: PropTypes.array.isRequired,
    /** Function adds a blank new filter to sidebar. */
    addFilter: PropTypes.func.isRequired,
    /** Function that updates the selected interval for the graphs */
    updateSelectedAge: PropTypes.func.isRequired,
    /** Function that updates whether or not the graph filter is collapsed or not */
    updateFilterExpandedProperty: PropTypes.func.isRequired,
    /** Boolean indicating whether or not the Add Filter button is disabled */
    disableAddFilterBtn: PropTypes.bool.isRequired,
    /* Whether or not the measurement system is metric */
    isMetric: PropTypes.bool.isRequired,
  }

  const {
    updateFilterProperty,
    updateFilterSelectedRange,
    updateFilterSelectedOptions,
    selectedFilters,
    addFilter,
    updateSelectedAge,
    updateFilterExpandedProperty,
    disableAddFilterBtn,
    isMetric,
  } = props

  const classes = useStyles()

  return (
    <Grid container direction="column">
      {selectedFilters.map((graphFilter, index) => (
        <GraphFilterBase
          key={index}
          updateFilterSelectedRange={updateFilterSelectedRange}
          updateFilterSelectedOptions={updateFilterSelectedOptions}
          updateFilterProperty={updateFilterProperty}
          graphFilter={graphFilter}
          updateSelectedAge={updateSelectedAge}
          updateFilterExpandedProperty={updateFilterExpandedProperty}
          isMetric={isMetric}
        />
      ))}
      <Grid item container xs={12} lg={12} className={classes.buttonContainer}>
        <Button
          onClick={addFilter}
          size="medium"
          variant="outlined"
          startIcon={<AddIcon />}
          className={classes.addFilterBtn}
          disabled={disableAddFilterBtn}
        >
          Add Filter
        </Button>
      </Grid>
    </Grid>
  )
}
