import React from 'react'
import PropTypes from 'prop-types'
import {
  getAlertHeader,
  getCommissionAlertHeader,
  getAlertText,
  getCommissionAlertText,
} from '../Logic/TSSLogic'
import { makeStyles } from '@material-ui/core'
import ExpandablePrompt from '../../Common/Components/ExpandablePrompt/ExpandablePrompt'
import { AddDataMode, CommissionReportMode } from '../Logic/Types'
import theme from '../../theme/muiTheme'

EditModeAlert.propTypes = {
  /** Specify the size variant of the heading. */
  customer: PropTypes.object,
  /** Name of the producer. It's a string that represents the producer's name.*/
  producerName: PropTypes.string,
  /** Location string representing where the producer or production is based. */
  location: PropTypes.string,
  /** If true, set color and icon for an editable view. */
  mixCode: PropTypes.string,
  /** Boolean flag that indicates whether the component is in edit mode. Optional. */
  isEditMode: PropTypes.bool,
  /** function to set the isEditMode boolean */
  setIsEditMode: PropTypes.func,
  /** Boolean that indicates whether the component is in edit mode. Optional. */
  isCommissionReportViewMode: PropTypes.bool,
  /** function to set the isCommissionReportViewMode boolean */
  setIsCommissionReportViewMode: PropTypes.func,
  /** function to control the UI Logic to display the Alert using isCommissionReport boolean */
  isCommissionReport: PropTypes.bool,
  roles: PropTypes.array.isRequired,
}

const useStyles = makeStyles({
  ...theme.customClasses.expandablePrompt,
  container: {
    ...theme.customClasses.expandablePrompt.container,
    margin: '16px 0px',
  },
})

/** Header variations for adding data, shared across pages. */
function EditModeAlert(props) {
  const classes = useStyles()
  const {
    customer,
    mixCode,
    isEditMode,
    location,
    producerName,
    setIsEditMode,
    isCommissionReportViewMode,
    setIsCommissionReportViewMode,
    isCommissionReport,
  } = props
  const customerText = customer?.division ? `${customer.division.name} ` : ''

  const switchToEditMode = () => {
    setIsEditMode(true)
  }

  const viewCommissionSwitchToEditMode = () => {
    setIsCommissionReportViewMode(prevMode => !prevMode)
  }

  const getButtonText = () => {
    return isEditMode ? 'Switch From Edit to View' : 'Switch From View To Edit'
  }

  const getCommissionReportButtonText = () => {
    return isCommissionReportViewMode
      ? 'Switch To Edit Mode'
      : 'Switch To View Mode'
  }

  return (
    <>
      {isCommissionReport ? (
        <ExpandablePrompt
          promptType={
            isCommissionReportViewMode
              ? CommissionReportMode.edit
              : CommissionReportMode.view
          }
          headerText={getCommissionAlertHeader(isCommissionReportViewMode)}
          contentText={getCommissionAlertText(
            mixCode,
            location,
            producerName,
            props.roles,
            isCommissionReportViewMode
          )}
          expandButtonText={getCommissionReportButtonText()}
          roles={props.roles}
          onClickHandler={viewCommissionSwitchToEditMode}
          parentClasses={classes}
        />
      ) : (
        <ExpandablePrompt
          promptType={isEditMode ? AddDataMode.edit : AddDataMode.view}
          headerText={getAlertHeader(isEditMode)}
          contentText={getAlertText(
            customerText,
            mixCode,
            props.roles,
            isEditMode
          )}
          expandButtonText={getButtonText()}
          roles={props.roles}
          onClickHandler={switchToEditMode}
          parentClasses={classes}
        />
      )}
    </>
  )
}

export default EditModeAlert
