import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { baseColors } from '../../theme/colors'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    backgroundColor: baseColors.error.background,
    color: baseColors.error.headerText,
  },
  dialogPaper: {
    minWidth: '40%',
    maxWidth: '100%',
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'left',
  },
  errorIcon: {
    color: baseColors.error.headerText,
    marginRight: theme.spacing(1),
    fontSize: '2rem',
    marginTop: 0,
  },
  errorText: {
    fontWeight: 600,
    color: baseColors.error.headerText,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '150%',
    letterSpacing: '0.2px',
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  dialogContent: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    color: baseColors.error.headerText,
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0.6px',
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  dialogActions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  goBackButton: {
    backgroundColor: baseColors.error.dark,
    color: baseColors.error.contrast,
    textTransform: 'capitalize',
    display: 'flex',
    padding: '6px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    '&:hover': {
      backgroundColor: baseColors.error.headerText,
    },
  },
}))

ErrorDialog.propTypes = {
  /** The error message to display to users. */
  errorMessage: PropTypes.node.isRequired,
  /** Function used to set displayed message to null, hiding the dialog. */
  setErrorMessage: PropTypes.func.isRequired,
  /** The header text to display to users. */
  headerText: PropTypes.string,
}

/**
 * This dialog component simply displays an error message.
 * @param {String} errorMessage - the error message to display to users.
 * @param {Function} setErrorMessage - function used to set displayed message to null, hiding the dialog.
 */
function ErrorDialog(props) {
  const classes = useStyles()
  const { errorMessage, setErrorMessage, headerText } = props

  /** Set message to null and hide the dialog. */
  const handleClose = () => {
    setErrorMessage(null)
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <div className={classes.titleContent}>
          <HighlightOffIcon className={classes.errorIcon} />
          <Typography variant="h6" className={classes.errorText}>
            {headerText ?? 'An Error Has Occurred'}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>{errorMessage}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleClose}
          size="small"
          className={classes.goBackButton}
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
