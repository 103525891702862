import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import PropTypes from 'prop-types'

export default function BatchTableEnhancedHead(props) {
  BatchTableEnhancedHead.propTypes = {
    /** style class */
    classes: PropTypes.object.isRequired,
    /** function for selecting all rows  */
    onSelectAllClick: PropTypes.func.isRequired,
    /** ascending or descending order style */
    order: PropTypes.string.isRequired,
    /** column selected for sorting */
    orderBy: PropTypes.string,
    /** total number of rows selected */
    numSelected: PropTypes.number.isRequired,
    /** total rows of the table */
    rowCount: PropTypes.number.isRequired,
    /** function to sort the values by selected header */
    onRequestSort: PropTypes.func.isRequired,
  }

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  const getOrder = () => {
    return order === 'desc' ? 'sorted descending' : 'sorted ascending'
  }

  // Column headers
  const headCells = [
    { id: 'producer', numeric: false, disablePadding: true, label: 'Producer' },
    {
      id: 'mixCode',
      numeric: false,
      disablePadding: false,
      label: 'Mix #',
    },
    {
      id: 'location',
      numeric: true,
      disablePadding: false,
      label: 'Loc.',
    },
    {
      id: 'condition',
      numeric: true,
      disablePadding: false,
      label: 'Cond.',
    },
    {
      id: 'cementSupplier',
      numeric: true,
      disablePadding: false,
      label: 'Cmt Sup.',
    },
    {
      id: 'cementContent',
      numeric: true,
      disablePadding: false,
      label: 'Cmt Cont.',
    },
    {
      id: 'air',
      numeric: true,
      disablePadding: false,
      label: 'Air',
    },
    {
      id: 'slump',
      numeric: true,
      disablePadding: false,
      label: 'Slump',
    },
    {
      id: 'strength',
      numeric: true,
      disablePadding: false,
      label: 'Str.',
    },
    {
      id: 'interval',
      numeric: false,
      disablePadding: false,
      label: 'Int.',
    },
    {
      id: 'waterCementRatio',
      numeric: false,
      disablePadding: false,
      label: 'W/CM',
    },

    {
      id: 'co2Dosage',
      numeric: true,
      disablePadding: false,
      label: 'CO2 Dos.',
    },
  ]

  return (
    <TableHead>
      <TableRow>
        {/* "Select all" checkbox, hidden for now unless we want to use it. */}
        <TableCell padding="checkbox">
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<IndeterminateCheckBoxIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>

        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'producer' ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{getOrder()}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
