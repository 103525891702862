import React, { useEffect, useState } from 'react'

import { MixGroupVariation } from '../../Logic/Types'
import { generateErrorMessages } from './ChartsLowSampleErrorHelper'
import ChartsLowSampleErrorPresentational from './ChartsLowSampleErrorPresentational'

interface ChartsLowSampleErrorLogicalProps {
  /** The mix designs that were selected for the graph */
  selectedMixVariations: Array<MixGroupVariation>
  /** The number of mixes that would result in an error message, defaults to 15 */
  errorThreshold?: number
  /** The number of mixes that would result in a warning, defaults to 30 */
  warningThreshold?: number
}

const defaultProps: ChartsLowSampleErrorLogicalProps = {
  selectedMixVariations: [],
  errorThreshold: 15,
  warningThreshold: 30,
}

function ChartsLowSampleErrorLogical(props: ChartsLowSampleErrorLogicalProps) {
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [errorTitle, setErrorTitle] = useState<string>('')

  useEffect(() => {
    setErrorMessages(
      generateErrorMessages(
        props.selectedMixVariations,
        props.errorThreshold!,
        props.warningThreshold!
      )
    )

    const hasError = props.selectedMixVariations.some(
      digestedMixDesign => digestedMixDesign.sampleCount < props.errorThreshold!
    )

    const hasWarning = props.selectedMixVariations.some(
      digestedMixDesign =>
        digestedMixDesign.sampleCount < props.warningThreshold!
    )

    if (hasError) {
      setErrorTitle(`Error - Under ${props.errorThreshold} Samples`)
    } else if (hasWarning) {
      setErrorTitle(`Warning - Under ${props.warningThreshold} Samples`)
    }
  }, [
    props.selectedMixVariations,
    props.errorThreshold,
    props.warningThreshold,
  ])

  if (errorMessages.length > 0) {
    return (
      <ChartsLowSampleErrorPresentational
        errorMessages={errorMessages}
        errorTitle={errorTitle}
      />
    )
  }
  return null
}

ChartsLowSampleErrorLogical.defaultProps = defaultProps

export default ChartsLowSampleErrorLogical
