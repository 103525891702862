/* What belongs here? Great question!
 * In this file you'll want to have functions that are related to: formatting, unit conversions,
 * and generalized functions that have applications across the application (for example stableSort).
 */
import { DigestedMixDesign } from '../../TSS/Logic/Types'
import {
  IActiveFilter,
  IFeatureFlags,
  ISonarFeatureFlags,
  NavBarDepartment,
  NavBarSubpath,
  NullableValue,
} from '../Logic/Types'
import {
  GallonToLitre,
  KPaToPSI,
  LbPerFt3ToKgPerM3,
  LbPerYd3ToKgPerM3,
  LbToKg,
  MlToLitre,
  OzPerYd3ToLitrePerM3,
  OzToLitre,
  PsiToMPa,
  Yd3ToM3,
} from './UnitConversions'

/**
 * Convert a unix timestamp into a YYYY/MM/DD HH:MM:SS time
 * @param {number} unixTimeStamp A unix timestamp, will convert it to milliseconds by multiplying by 1000
 */
export function getPrettyDate(unixTimeStamp: number) {
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  let date = new Date(unixTimeStamp * 1000)
  // Hours part from the timestamp
  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear()

  let hours = date.getHours()
  // Minutes part from the timestamp
  let minutes = date.getMinutes()
  // Seconds part from the timestamp
  let seconds = date.getSeconds()

  // Will display time in 10:30:23 format
  let formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  return `${year}/${month}/${day} ${formattedTime}`
}

/** Function to sort MUI table rows by ascending/descending (enhanced table) */
export const descendingComparator = function(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy] || (b[orderBy] === null && a[orderBy] !== null)) {
    return -1
  }
  if (b[orderBy] > a[orderBy] || (a[orderBy] === null && b[orderBy] !== null)) {
    return 1
  }
  return 0
}

/** Comparator to sort MUI table rows (enhanced table) */
export const getComparator = function(order: 'desc' | 'asc', orderBy: string) {
  return order === 'desc'
    ? (a: Object, b: Object) => descendingComparator(a, b, orderBy)
    : (a: Object, b: Object) => -descendingComparator(a, b, orderBy)
}

/** Sort MUI table rows (enhanced table) */
export const stableSort = function(array: Array<any>, comparator: Function) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

/** Function to return only unique values from an array */
export const onlyUnique = function(
  value: any,
  index: number,
  self: Array<any>
) {
  return self.indexOf(value) === index
}

export const ConvertPSIToMPARounded = (psiStrength: number | null) => {
  if (psiStrength === null) return null
  const conversionFactor = psiStrength * PsiToMPa
  return Number(conversionFactor?.toFixed(2))
}

export const ConvertPSIToMPAUnrounded = (psiStrength: number | null) => {
  if (psiStrength === null) return null
  const conversionFactor = psiStrength * PsiToMPa
  return conversionFactor
}

export function ConvertMPAToPSIRounded(mpaStrength: number | null) {
  if (mpaStrength === null) {
    return null
  }
  const conversionFactor = mpaStrength / PsiToMPa
  return roundUpToSigFigs((conversionFactor / 10) * 10)
}

export function ConvertMPAToPSIUnrounded(mpaStrength: number | null) {
  /* No rounding */
  if (mpaStrength === null) {
    return null
  }
  const conversionFactor = mpaStrength / PsiToMPa
  return conversionFactor
}

export function ConvertPSILbToMPAKg(efficiency: number | null) {
  if (efficiency === null) return null
  return Number((efficiency / 86.047468889)?.toFixed(2))
}

export function ConvertPSILbToMPAKgUnrounded(efficiency: number | null) {
  if (efficiency === null) return null
  return Number(efficiency / 86.047468889)
}

export function ConvertMPaKgToPSILb(efficiency: number | null) {
  if (efficiency === null) {
    return null
  }
  return roundUpToSigFigs(efficiency * 86.047468889)
}

export function ConvertMPaKgToPSILbUnrounded(efficiency: number | null) {
  if (efficiency === null) {
    return null
  }
  return Number(efficiency * 86.047468889)
}

export function ConvertINCHtoMM(inchSlump: number | null) {
  if (inchSlump === null) {
    return null
  }
  return inchSlump / 0.0393701
}

export function ConvertMMtoINCH(mmSlump: number | null) {
  if (mmSlump === null) {
    return null
  }
  return roundUpToSigFigs(mmSlump * 0.0393701)
}

export function ConvertMMtoINCHUnrounded(mmSlump: number | null) {
  if (mmSlump === null) {
    return null
  }
  return mmSlump * 0.0393701
}

export function ConvertFtoC(fTemperature: number | null) {
  if (fTemperature === null) {
    return null
  }
  return Number(((fTemperature - 32) * (5 / 9))?.toFixed(2))
}

export function ConvertFtoCUnrounded(fTemperature: number | null) {
  if (fTemperature === null) {
    return null
  }
  return (fTemperature - 32) * (5 / 9)
}

export function ConvertCtoF(cTemperature: number | null) {
  if (cTemperature === null) {
    return null
  }
  return roundUpToSigFigs(cTemperature * (9 / 5) + 32)
}

export function ConvertCtoFUnrounded(cTemperature: number | null) {
  if (cTemperature === null) {
    return null
  }
  return cTemperature * (9 / 5) + 32
}

export function ConvertM3ToYd3(m3LoadSize: number | null) {
  if (m3LoadSize === null) {
    return null
  }
  return roundUpToSigFigs(m3LoadSize / Yd3ToM3)
}
export function ConvertKgPerM3ToLbPerFt3(kgPerM3Density: number | null) {
  if (kgPerM3Density === null) {
    return null
  }
  return roundUpToSigFigs(kgPerM3Density / LbPerFt3ToKgPerM3)
}
export function ConvertKgToLb(kgWeight: number | null) {
  if (kgWeight === null) {
    return null
  }
  return roundUpToSigFigs(kgWeight / LbToKg)
}
export function ConvertLitreToGal(litreWeight: number | null) {
  if (litreWeight === null) {
    return null
  }
  return roundUpToSigFigs(litreWeight / GallonToLitre)
}
export function ConvertLitreToOz(litreWeight: number | null) {
  if (litreWeight === null) {
    return null
  }
  return roundUpToSigFigs(litreWeight / OzToLitre)
}

export function ConvertLitreToOzUnrounded(litreWeight: number | null) {
  if (litreWeight === null) {
    return null
  }
  return litreWeight / OzToLitre
}

export function ConvertOzToLitreUnrounded(ozWeight: number | null) {
  if (ozWeight === null) {
    return null
  }
  return ozWeight * OzToLitre
}

export function ConvertOzToMilliLitreUnrounded(ozWeight: number | null) {
  if (ozWeight === null) {
    return null
  }
  return (ozWeight * OzToLitre) / MlToLitre
}

export function ConvertMilliLitreToOzUnrounded(mlWeight: number | null) {
  if (mlWeight === null) {
    return null
  }
  return (mlWeight / OzToLitre) * MlToLitre
}

export function ConvertOzPerYd3ToMilliLitrePerM3Unrounded(
  ozWeight: number | null
) {
  if (ozWeight === null) {
    return null
  }
  return (ozWeight * OzPerYd3ToLitrePerM3) / MlToLitre
}

export function ConvertMilliLitrePerM3ToOzPerYd3Unrounded(
  millilitreWeight: number | null
) {
  if (millilitreWeight === null) {
    return null
  }
  return (millilitreWeight / OzPerYd3ToLitrePerM3) * MlToLitre
}

/**
 *
 * @param {Number} volumeYd3
 * @returns {Number}
 */
export function ConvertYd3ToM3(volumeYd3: number): number {
  return roundUpToSigFigs(volumeYd3 * Yd3ToM3) as number
}

/**
 *
 * @param {Number} densityLbYd3
 * @returns {Number}
 */
export function ConvertLbPerYd3ToKgPerM3(
  densityLbYd3: number | null
): number | null {
  if (densityLbYd3 === null) return null
  return roundUpToSigFigs(densityLbYd3 * LbPerYd3ToKgPerM3) as number
}

/**
 *
 * @param {Number} densityLbYd3
 * @returns {Number}
 */
export function ConvertLbPerYd3ToKgPerM3Unrounded(
  densityLbYd3: number | null
): number | null {
  if (densityLbYd3 === null) return null
  return densityLbYd3 * LbPerYd3ToKgPerM3
}

export function ConvertKPaToPSI(kpaPressure: number) {
  return roundUpToSigFigs(kpaPressure * KPaToPSI)
}

/** Function to convert camelCase text to Sentence Case */
export function StringFormatter(name: string) {
  name = name.replace(/([A-Z])/g, ' $1')
  name = name.charAt(0).toUpperCase() + name.slice(1)
  return name
}

/** Function to convert a value from kg/m3 to lb/yd3 */
export function convertKgM3ToLbYd3(kgM3Value: number | null) {
  if (kgM3Value === null) return null
  const CONVERSION_FACTOR = 1 / LbPerYd3ToKgPerM3
  return roundUpToSigFigs(kgM3Value * CONVERSION_FACTOR)
}

/** Function to convert a value from kg/m3 to lb/yd3 */
export function convertKgM3ToLbYd3Unrounded(kgM3Value: number | null) {
  if (kgM3Value === null) return null
  const CONVERSION_FACTOR = 1 / LbPerYd3ToKgPerM3
  return kgM3Value * CONVERSION_FACTOR
}

/** Function to convert strength readings to values with comma - 4,250*/
export function getFormattedInteger(strength: number | null): string {
  if (strength === null) {
    return ''
  }
  return strength.toLocaleString()
}

/** Retrieves a value from an object using a given key. */
export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find(key => object[key] === value)

/** Rounds to specified decimal places if necessary. */
export const roundUpToDecimal = (value: number | null, decimalPlace = 2) => {
  if (value === null || typeof value !== 'number') return null
  const roundingFactor = 10 ** decimalPlace
  return Math.round((value + Number.EPSILON) * roundingFactor) / roundingFactor
}

export const roundUpToSigFigs = (
  value: number | string | null | undefined,
  sigFigs = 6
) => {
  if (value === null || value === undefined) return null
  if (isNaN(value)) return NaN
  if (value === 0) return 0
  value = Number(value)
  const multiplier =
    10 ** (sigFigs - Math.floor(Math.log10(Math.abs(value))) - 1)

  return Math.round(value * multiplier) / multiplier
}

export const removeEmptyFromObject = (obj: Object) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

/** Function to get percentage increase between two values. */
export const calculatePercentage = (
  firstValue: number,
  secondValue: number
) => {
  return Number(((firstValue / secondValue) * 100).toFixed(0))
}

/**
 * Function used extract distinct object property values from an array of objects.
 * Note: this will not work as expected if the properties are objects themselves as the Set data
 * structure will consider each object unique, regardless of whether they contain the same values.
 * @param {Object[]} array - array containing the objects.
 * @param {String} key - key/property to collect distinct values for.
 * @param {Bool} includeNull - include null/undefined in the list of distinct values.
 * @returns {Array} - array of distinct property values found in original array.
 */
export const getDistinctValuesByKey = (
  array: Array<any>,
  key: string,
  includeNull: boolean = false
): Array<any> => {
  const distinctList = new Set<any>()
  array.forEach(object => {
    const value = object[key]
    if (value != null || includeNull) distinctList.add(value)
  })

  return Array.from(distinctList)
}

/** Simple function to prevent an event from propagating when specified keyboard keys are pressed.
 *  Can be passed to TextField component's onKeyDown prop with list of keys (e.g. ['Backspace', 'Enter']).
 */
export const preventEventOnKeyDown = (keys: Array<string> = []) => (
  event: KeyboardEvent
) => {
  if (keys.includes(event.key)) {
    event.stopPropagation()
  }
}

/**
 * DO NOT USE THIS IF YOU DON'T *ABSOLUTELY* NEED IT
 *
 * This is to get around the issue that is around destructing only creating a shallow copy of an object.
 * This *SHOULD NOT BE USED* as a fix-all solution. We don't want to be creating extra objects around the place bloating the application
 *
 * Generally, this should not be needed to be used since you want to be using new objects returned rather than editing the one in place,
 * so the issue of an object's children referring to each other shouldn't be occuring. If you're using this, there's something else that's wrong.
 *
 * @param {Object} objectToCopy The object you want to deep copy
 * @returns A deep copy of the object
 */
export function deepCopy(objectToCopy: any) {
  return JSON.parse(JSON.stringify(objectToCopy))
}
/**
 * Takes a kelowna timestamp (ISO 8601) and returns a localized string representation of only the date
 * @param {String | null} kelownaTimestamp Timestamp as returned by kelowna
 * @returns {String} String representation of only the date or empty string if no timestamp is given.
 */
export function removeDateTimezone(kelownaTimestamp: string) {
  if (kelownaTimestamp === null) {
    return ''
  }
  return kelownaTimestamp.split('T')[0]
}

/** Function to convert ISO 8601 timestamp to YYYY/MM/DD format */
export const convertTimestamp = (timestamp: string) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  let month = String(date.getMonth() + 1)
  let day = String(date.getDate())
  if (Number(day) < 10) {
    day = '0' + day
  }
  if (Number(month) < 10) {
    month = '0' + month
  }
  return year + '/' + month + '/' + day
}

export function isAbortedFetchError(error: Error): boolean {
  return error instanceof DOMException && error.name === 'AbortError'
}

/** Function to convert camel case to pascal case, useful to creating link/path */
export function convertCamelCaseToPascalCase(string: string): string {
  return string.slice(0, 1).toUpperCase() + string.slice(1)
}

/** Function to convert pascal case to camel case */
export function convertPascalCaseToCamelCase(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

/** Function to convert pascal case to title case */
export function convertPascalCaseToTitleCase(string: string): string {
  const words = string.split(/(?=[A-Z])/)
  return words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
/** Function to convert title case to camel case */
export function titleCaseToCamelCase(str: string): string {
  return str
    .replace(/\s(\w)/g, function(match: string, letter: string) {
      return letter.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function(match: string, letter: string) {
      return letter.toLowerCase()
    })
}

/** Function to remove active chips from filter array */

export function removeActiveFilter(
  prev: Array<IActiveFilter>,
  filter: IActiveFilter
) {
  const index = prev.findIndex(
    obj => obj.label === filter.label && obj.property === filter.property
  )
  if (index === -1) return prev // filter chip not found in array
  const updatedFilters = [...prev]
  updatedFilters.splice(index, 1) // remove filter chip from array
  return updatedFilters
}

/**
 * This function is primarily for the Add Data page. This gets the sum of cylinder counts from
 * each variation of the mix that is returned from the '/TSS/MixDesigns/MixGroup' endpoint called by the
 * getMixGroupByBatch function. Kelowna currently returns an undefined value for the totalCylinders property.
 * @param {Object[]} arr Array containing the objects. Object as returned by Kelowna
 * @returns {Object[]} Array of objects, each having the updated totalCylinders value.
 */
export function addTotalCylinders(arr: Array<DigestedMixDesign>) {
  arr.forEach(object => {
    // Sum should be at least 0 instead of 'undefined', which Kelowna returns by default
    let sum = 0
    if (Array.isArray(object.variations)) {
      // Add up the cylinderCount value for each variation
      object.variations.forEach(variation => {
        if (typeof variation.cylinderCount === 'number') {
          sum += variation.cylinderCount
        }
      })
    }
    // Update the totalCylinders property
    object.totalCylinders = sum
  })

  return arr
}

/** Function to convert a unix timestamp into YYYY-MM-DD format for the downloaded filename */
export const getDate = (unixTimestamp: number) => {
  let date = new Date(unixTimestamp)
  return date.toLocaleString('en-CA', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

/** A function to be used for the onKeyPress prop of TextField components. Forces numbers only for inputs */
export const keyPressNumericStringsOnly = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  if (!/^\d*$/.test(event.key)) {
    event.preventDefault()
  }
}

export const isPositiveInteger = (val: string) => {
  const isNumeric = /^\d*$/.test(val)
  const isPositive = !val.startsWith('-')
  const isAllowed = isNumeric && isPositive
  return isAllowed
}

export const comparedSort = (
  a: string | number | undefined,
  b: string | number | undefined
) => {
  if (a === null && b === null) return 0
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  const formattedA = typeof a === 'string' ? a.toLowerCase() : a
  const formattedB = typeof b === 'string' ? b.toLowerCase() : b

  if (formattedA < formattedB) {
    return -1
  }
  if (formattedA > formattedB) {
    return 1
  }
  return 0
}

export const getFeatureFlags = (): IFeatureFlags => {
  return {
    showCommissionReport: true,
  }
}

export const getSonarFeatureFlags = (): ISonarFeatureFlags => {
  return {
    showSonarOverview:
      process.env.REACT_APP_ENV !== 'prod' &&
      process.env.REACT_APP_ENV !== 'qa' &&
      process.env.REACT_APP_ENV !== 'dev',
    showSonarAlarms:
      process.env.REACT_APP_ENV !== 'prod' &&
      process.env.REACT_APP_ENV !== 'qa' &&
      process.env.REACT_APP_ENV !== 'dev',
    showSonarDownSystems: true,
    showSonarSnoozed:
      process.env.REACT_APP_ENV !== 'prod' &&
      process.env.REACT_APP_ENV !== 'qa' &&
      process.env.REACT_APP_ENV !== 'dev',
  }
}

export const getCurrentPageRows = (
  data: any[],
  page: number,
  rowsPerPage: number
) => {
  return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
}

export const getNewCurrentPage = (
  totalResultsCount: number,
  rowsPerPage: number,
  currentPage: number
) => {
  const newLastPage = Math.max(
    0,
    Math.ceil(totalResultsCount / rowsPerPage) - 1
  )
  let newPage = currentPage
  if (newLastPage < currentPage) newPage = newLastPage
  return newPage
}

export const isNullOrUndefined = (value: NullableValue) => {
  return value === null || value === undefined
}

/**
 * This function is used to filter specific subPaths that are used to display links on our Nav Bar.
 * @param {Object[]} departmentData The data used to populate the navigation bar (as seen in projectLists.js)
 * @param {string} departmentTitle The department name
 * @param {string} projectTitle The project name
 * @param {string[]} subPathTitles The subpath names that will be filtered
 * @returns {NavBarDepartment[]} The data used to populate the navigation bar (as seen in projectLists.js)
 */
export const filterSubPath = (
  departmentData: NavBarDepartment[],
  departmentTitle: string,
  projectTitle: string,
  subPathTitles: string
): NavBarDepartment[] => {
  const updatedDepartmentData = departmentData.map(department => {
    if (department.department === departmentTitle) {
      const updatedProjects = department.projects.map(project => {
        if (project.title === projectTitle) {
          const filteredSubPaths = project.subPaths.filter(
            (subPath: NavBarSubpath) => subPathTitles.includes(subPath.title)
          )
          return { ...project, subPaths: filteredSubPaths }
        }

        return project
      })

      return { ...department, projects: updatedProjects }
    }
    return department
  })

  return updatedDepartmentData
}

/**
 * Function to parse a Date object into a string with format based on passed structure
 * @param {date} Date object to be parsed
 * @param {format} String format to be used for the date
 * @param {isUTCBooleanString} String optional parameter to determine if the date returned is in local time or UTC
 * @return {formattedDate} String representation of the date
 */
export const formatDateObjectToString = (
  date: Date,
  format: string,
  isUTCBooleanString?: string
) => {
  if (isNaN(date.getTime())) {
    return 'Invalid Date'
  }
  const isUTC = isUTCBooleanString === 'true'

  const pad = (num: number) => num.toString().padStart(2, '0')
  const hours = isUTC ? date.getUTCHours() : date.getHours()
  const replacements = {
    YYYY: isUTC ? date.getUTCFullYear() : date.getFullYear().toString(),
    MM: isUTC ? pad(date.getUTCMonth() + 1) : pad(date.getMonth() + 1),
    DD: isUTC ? pad(date.getUTCDate()) : pad(date.getDate()),
    HH: pad(hours % 12 || 12),
    H: (hours % 12 || 12).toString(),
    mm: isUTC ? pad(date.getUTCMinutes()) : pad(date.getMinutes()),
    ss: isUTC ? pad(date.getUTCSeconds()) : pad(date.getSeconds()),
    AMPM: hours >= 12 ? 'PM' : 'AM',
  }

  return format.replace(
    /YYYY|MM|DD|HH|H|mm|ss|AMPM/g,
    //@ts-ignore - We know that key is a key of replacements
    match => replacements[match]
  )
}

/*
 * A functions that returns the string for connecting things in a list given the length of the list and the current index.
 * @param {number} length
 * @param {number} currentIndex
 * @returns
 */
export const getConnectorString = (length: number, currentIndex: number) => {
  if (currentIndex < length - 1) {
    return currentIndex < length - 2 ? ', ' : ' and '
  }
  return ''
}

/**
 * Function to convert minutes to HH:MM format
 * @param {number} minutes a number of minutes
 * @returns {string} A time in HH:MM format
 */
export const convertMinsToHrsMins = (minutes: number) => {
  let hours: string | number = Math.floor(minutes / 60)
  let remainderOfMinutes: string | number = minutes % 60
  hours = String(hours).padStart(2, '0')
  remainderOfMinutes = String(remainderOfMinutes).padStart(2, '0')
  return `${hours}:${remainderOfMinutes}`
}

/**
 * A function to determine the local timezone offset from UTC in the format "+/-HH:MM"
 * @returns {string} The local timezone offset from UTC in the format "+/-HH:MM"
 */
export const getLocalTimezoneOffsetFromUTC = (): string => {
  const offset = new Date().getTimezoneOffset()
  const sign = offset > 0 ? '-' : '+'
  const absOffset = Math.abs(offset)
  return sign + convertMinsToHrsMins(absOffset)
}
