import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import EditableCellText from './EditableCellText'
import EditableCellDropdown from './EditableCellDropdown'
import {
  PlantsEditableCellAdornment,
  PlantsTableRowProperties,
} from '../PlantsEditableTable/PlantsEditableTable.types'
import { PlantsEditableCellDropdown } from './EditableCell.types'
import EditableCellDate from './EditableCellDate'
import EditableCellBasePresentational from './EditableCellBasePresentational'
import PlantsTableRow from '../PlantsEditableTable/PlantsTableRow'
import { atomPlantsConfirmation } from '../../../Common/tssAtoms'
import cloneDeep from 'lodash.clonedeep'
import { metricSwitch } from '../../../Common/atoms'
import {
  convertKgM3ToLbYd3,
  ConvertM3ToYd3,
} from '../../../Common/Helpers/GeneralHelpers'
import * as yup from 'yup'

/* Define form schema. */
const schemas = {
  averageCementLoading: yup.number().moreThan(0),
  yardsPerTruck: yup.number().moreThan(0),
  averageCementCut: yup
    .number()
    .min(0)
    .max(100),
  tankRentalFee: yup.number().min(0),
  cO2DosePercent: yup
    .number()
    .min(0)
    .max(100),
  residentialPercent: yup
    .number()
    .min(0)
    .max(100),
  commercialPercent: yup
    .number()
    .min(0)
    .max(100),
  dotPercent: yup
    .number()
    .min(0)
    .max(100),
}

interface EditableCellBaseLogicalProps {
  /** Row containing plant data; corresponds with single plant. */
  rowData: PlantsTableRow
  /** Updates single row from table. Each row corresponds with one plant. */
  updateTableRow: (...args: any[]) => any
  /** Initially a copy of rowData, this object contains updates made to the row that are not yet saved. */
  internalRowData: PlantsTableRow
  /** Function to update a single property of the internal row. */
  setInternalValue: (
    property: keyof PlantsTableRow,
    newValue: string | number | PlantsEditableCellDropdown
  ) => void
  /** Property displayed in the current cell. */
  property: PlantsTableRowProperties
  /** An associated property, this is used for cells that have a corresponding date field. */
  associatedProperty?: PlantsTableRowProperties
  /** Boolean indicating if the cell is a date field. */
  isDate?: boolean
  /** List of dropdown options for selection. */
  dropdownOptions?: Array<PlantsEditableCellDropdown>
  /** Adornment type to be used with numeric fields. */
  adornment?: PlantsEditableCellAdornment
  /** Width in CSS em units to determine row width. */
  minWidthEm?: number
  /** Determines if this property cell needs a Notes modal **/
  isNoted?: boolean
  /** Boolean indicating if cell is disabled */
  disabled?: boolean
  /** Determines if field is focused */
  focused?: boolean
}

function EditableCellBaseLogical(props: EditableCellBaseLogicalProps) {
  const {
    rowData,
    updateTableRow,
    internalRowData,
    setInternalValue,
    property,
    associatedProperty,
    isDate = false,
    dropdownOptions,
    adornment,
    minWidthEm = 10,
    isNoted = false,
    focused,
    disabled = false,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [plantsConfirmation, setPlantsConfirmation] = useRecoilState(
    atomPlantsConfirmation
  )
  const [isValid, setIsValid] = useState(true)
  const [associateIsValid, setAssociateIsValid] = useState(true)

  const isMetric = useRecoilValue(metricSwitch)
  const currentBackendValue = rowData.original[property]
  const internalValue = internalRowData.edited[property]

  type fieldType = string | null | number

  //for imperial, we need to check if it changed, converting the original (metric) values and comparing them
  const compareImperial = (
    original: fieldType,
    newVal: fieldType,
    siblingCheck: boolean
  ) => {
    if (
      ('averageCementLoading' === property && !siblingCheck) ||
      ('averageCementLoadingTimestamp' === property && siblingCheck)
    ) {
      return newVal !== convertKgM3ToLbYd3(original)
    }

    if ('averageCementLoading' === property && !siblingCheck) {
      if (isNaN(newVal)) {
        return false
      }
    }

    if ('yardsPerTruck' === property) {
      return newVal !== ConvertM3ToYd3(original)
    }

    return null
  }

  // CompareValues to infer if the cell or associated cell has been updated, and do metric checks
  const compareValues = (
    original: fieldType,
    newVal: fieldType,
    siblingCheck: boolean
  ) => {
    if (original === '' && newVal === '') {
      return false
    }

    if (!isMetric) {
      const compImp = compareImperial(original, newVal, siblingCheck)
      if (compImp !== null) {
        return compImp
      }
    }

    return newVal !== original
  }

  const hasUpdate = compareValues(currentBackendValue, internalValue, false)

  // Track updates for associated property.
  const associatedHasUpdate = associatedProperty
    ? compareValues(
        rowData.original[associatedProperty],
        internalRowData.edited[associatedProperty],
        true
      )
    : false

  if (associatedProperty && schemas.hasOwnProperty(associatedProperty)) {
    schemas[associatedProperty]
      .isValid(internalRowData.edited[associatedProperty])
      .then(function(valid: boolean) {
        setAssociateIsValid(valid)
      })
  }
  // check if there is an update on the property & associated property (if any).
  const updateIsValid =
    (hasUpdate && !associatedProperty) || (hasUpdate && associatedHasUpdate)

  // If property has associated date, the check is only displayed on the date cell.
  const displayCheck =
    updateIsValid &&
    (!associatedProperty || isDate) &&
    isValid &&
    associateIsValid

  // Extend cell width when updating value.
  const cellWidth = hasUpdate ? `${minWidthEm + 2}em` : `${minWidthEm}em`

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (schemas.hasOwnProperty(property)) {
      schemas[property].isValid(value).then(function(valid: boolean) {
        setInternalValue(property, value)
        setIsValid(valid)
      })
    } else {
      setInternalValue(property, value)
    }
  }

  const onChangeDropdown = (newValue: string) => {
    setInternalValue(property, newValue)
  }

  const saveChange = async (notesValue: string) => {
    setIsLoading(true)
    let updateObject = { ...rowData.edited }

    if (!associatedProperty) {
      updateObject[property] = internalValue
    } else {
      updateObject[property] = internalValue
      updateObject[associatedProperty] =
        internalRowData.edited[associatedProperty]
    }

    updateObject['plantEditNote'] = {
      columnName: property,
      previousValue: currentBackendValue.toString(),
      updatedValue: internalValue.toString(),
      reason: notesValue,
    }

    rowData.setEdited(updateObject)
    await updateTableRow(rowData)

    const newPlantsConfirmation = cloneDeep(plantsConfirmation)
    newPlantsConfirmation.isVisible = false
    setPlantsConfirmation(newPlantsConfirmation)

    //TODO: error handling.

    setPlantsConfirmation(newPlantsConfirmation)
    setIsLoading(false)
  }

  const intentChange = async () => {
    if (isNoted) {
      const newPlantsConfirmation = cloneDeep(plantsConfirmation)
      newPlantsConfirmation.isVisible = true
      newPlantsConfirmation.onSubmission = saveChange
      setPlantsConfirmation(newPlantsConfirmation)
    } else {
      saveChange('')
    }
  }

  const isFocused = focused ?? (hasUpdate || associatedHasUpdate)
  const renderCellInput = () => {
    if (isDate && typeof internalValue === 'string') {
      return (
        <EditableCellDate
          value={internalValue}
          onChange={onChange}
          isFocused={isFocused}
        />
      )
    } else if (typeof dropdownOptions !== 'undefined') {
      const dropValue = dropdownOptions.find(
        value => value.id === internalValue
      )

      return (
        <EditableCellDropdown
          value={dropValue ?? null}
          setValue={onChangeDropdown}
          options={dropdownOptions}
          isFocused={isFocused}
          disabled={disabled}
        />
      )
    } else if (
      typeof internalValue === 'string' ||
      typeof internalValue === 'number'
    ) {
      return (
        <EditableCellText
          value={internalValue}
          onChange={onChange}
          adornment={adornment}
          isFocused={isFocused}
          isValid={isValid}
        />
      )
    }
  }

  return (
    <EditableCellBasePresentational
      renderCellInput={renderCellInput}
      displayCheck={displayCheck}
      isLoading={isLoading}
      intentChange={intentChange}
      cellWidth={cellWidth}
    />
  )
}

export default EditableCellBaseLogical
