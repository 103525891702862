import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import { Grid, Paper, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HistogramLogical from './Histogram/HistogramLogical'
import StrengthDevelopmentLogical from './StrengthDevelopment/StrengthDevelopmentLogical'
import VarGraphLogical from './VariabilityGraph/VariabilityGraphLogical'
import SandBoxLogical from './SandBox/SandBoxLogical'
import offline_exporting from 'highcharts/modules/exporting.js'
import exporting from 'highcharts/modules/offline-exporting.js'
import PropTypes from 'prop-types'
import { baseColors } from '../../theme/colors'
import { getGraphingThresholds } from '../Logic/TSSLogic'
import BatchNotes from './BatchNotes/BatchNotes'
import CommissioningBarGraphLogical from './CommissioningBarGraph/CommissioningBarGraphLogical'
import ChartsLowSampleErrorLogical from './ChartsLowSampleError/ChartsLowSampleErrorLogical'

offline_exporting(Highcharts)
exporting(Highcharts)

const useStyles = makeStyles({
  graphHeader: {
    align: 'left',
    fontSize: 18,
  },
  paper: {
    width: '100%',
    marginBottom: '20px',
  },
  filter: {
    minWidth: '150px',
    minHeight: '500px',
  },
  select: {
    minWidth: '200px',
    padding: 0,
    marginTop: '10px',
    fontSize: '18px',
  },
  alerts: {
    display: 'flex',
    justifyContent: 'space-between',
    align: 'left',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  visible: { width: '100%', align: 'left' },
  buttonRight: { alignContent: 'right', justify: 'flex-end' },
  currentGraphHeading: { marginRight: '0.5em', fontWeight: '700' },
  normalizeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2em',
    paddingRight: '2em',
  },
  padLeftChartsLowSample: {
    paddingLeft: '58px',
  },
})
Graphs.propTypes = {
  /** The selected rows of mix design table */
  selectedMixVariations: PropTypes.array.isRequired,
  /** Function to set altered mix group table data globally */
  setTableData: PropTypes.func.isRequired,
  /** The selected rows of batch data */
  batchData: PropTypes.array.isRequired,
  /** Function to set the batch data */
  setBatchData: PropTypes.func.isRequired,
  /** Set flag to scroll batch table into view. */
  setScroll: PropTypes.func.isRequired,
  /** Graph data based on selected filters */
  selectedFilters: PropTypes.array.isRequired,
  /** Value for the selected graph type */
  graphType: PropTypes.string.isRequired,
  /** The selected age to use as a filter */
  selectedAge: PropTypes.number.isRequired,
  /** Whether or not a filter panel is open on the page */
  isFilterPanelOpen: PropTypes.bool.isRequired,
  /** Whether or not the measurement system is metric */
  isMetric: PropTypes.bool.isRequired,
}
export default function Graphs(props) {
  const {
    selectedMixVariations,
    batchData,
    setBatchData,
    setScroll,
    filteredData,
    graphType,
    selectedAge,
    isFilterPanelOpen,
    isMetric,
    page,
  } = props
  const [batchNotes, setBatchNotes] = useState([])
  const [selectedProperty, setSelectedProperty] = useState('slump')
  const classes = useStyles()

  /** Get batch notes to display below the graph */
  useEffect(() => {
    setBatchNotes(() => {
      const uniqueNotes = new Set()
      selectedMixVariations.forEach(mixDesign => {
        mixDesign.freshProperties.forEach(property => {
          const note = {
            batchNote: property.batchNotes,
            mixCode: mixDesign.mixCode,
            ticketId: property.externalTicketId,
          }
          if (note.batchNote != null) {
            uniqueNotes.add(note)
          }
        })
      })
      return [...uniqueNotes]
    })
  }, [selectedMixVariations])

  /** Function to add batch data to the table when point in the graph is clicked */
  const addToBatchDataTable = (batchId, strength) => {
    let batchDataArray = [...batchData]
    let tempFilteredData = [...selectedMixVariations]

    tempFilteredData.forEach(selected => {
      selected.freshProperties
        .filter(
          element =>
            element.batchId === batchId &&
            Number(element.batchStrength) === strength
        )
        .forEach((freshProperty, index) => {
          if (index === 0) {
            batchDataArray[0].value.push({
              mixDesignId: selected.mixDesignId,
              producer: selected.producer,
              mixCode: selected.mixCode,
              location: selected.locationName,
              cementContent: freshProperty.batchCementContent,
              cementContentUnit: freshProperty.batchCementContentUnit,
              air: freshProperty.air,
              slump: freshProperty.slump,
              strength: freshProperty.batchStrength,
              interval: freshProperty.batchInterval,
              waterCementRatio: freshProperty.batchWaterCementRatio,
              condition: selected.condition,
              co2Dosage: freshProperty.co2Dosage,
              co2DosageUnit: freshProperty.co2DosageUnit,
              isMetric: freshProperty.isMetric,
              co2DosageLabel: selected.cO2DosageLabel,
              co2DosageUnitLabel: selected.cO2DosageUnitLabel,
              variationIdLabel: selected.variationIdLabel,
            })
          }
        })
    })
    setBatchData(batchDataArray)
    setScroll(true)
  }

  const graphOptions = {
    Histogram: (
      <HistogramLogical
        key={isFilterPanelOpen}
        selectedMixVariations={filteredData}
        selectedAge={selectedAge}
        isFilterPanelOpen={isFilterPanelOpen}
        isMetric={isMetric}
      />
    ),
    Variability: (
      <VarGraphLogical
        key={batchData}
        selectedMixVariations={filteredData}
        addToBatchDataTable={addToBatchDataTable}
        isFilterPanelOpen={isFilterPanelOpen}
        isMetric={isMetric}
        page={page}
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
      />
    ),
    Sandbox: (
      <SandBoxLogical
        key={batchData}
        selectedMixVariations={filteredData}
        addToBatchDataTable={addToBatchDataTable}
        isFilterPanelOpen={isFilterPanelOpen}
        isMetric={isMetric}
      />
    ),
    'Strength Development': (
      <StrengthDevelopmentLogical
        key={batchData}
        addToBatchDataTable={addToBatchDataTable}
        selectedMixVariations={filteredData}
        isFilterPanelOpen={isFilterPanelOpen}
        isMetric={isMetric}
      />
    ),
    Bar: (
      <CommissioningBarGraphLogical
        mixVariationsData={filteredData}
        isMetric={isMetric}
        isFilterPanelOpen={isFilterPanelOpen}
      />
    ),
  }

  /* Set Option for Highcharts to remove the ending abbreviation like 4k for all graphs. */
  Highcharts.setOptions({
    lang: {
      numericSymbols: null,
    },
  })
  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            variant="outlined"
            className={classes.paper}
            style={{ marginTop: '10px', paddingBottom: '10px' }}
          >
            <Grid
              container
              direction="row"
              style={{ paddingTop: '10px' }}
              justify="space-between"
            >
              <Grid
                item
                md={5}
                style={{ paddingLeft: '20px', paddingTop: '10px' }}
              ></Grid>
            </Grid>

            <Grid item container style={{ paddingTop: '10px' }}>
              <Grid item xs={12} className={classes.padLeftChartsLowSample}>
                <ChartsLowSampleErrorLogical
                  errorThreshold={getGraphingThresholds(graphType).error}
                  warningThreshold={getGraphingThresholds(graphType).warning}
                  selectedMixVariations={selectedMixVariations}
                />
              </Grid>
              {selectedMixVariations.length ? (
                graphOptions[graphType]
              ) : (
                <Box style={{ minHeight: '340px', paddingLeft: '1%' }}>
                  <Typography
                    align="left"
                    style={{ paddingLeft: '2%', color: baseColors.error.main }}
                  >
                    {'Select a mix design from the table above to analyze'}
                  </Typography>
                </Box>
              )}
              <BatchNotes batchNotes={batchNotes} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
