import { Button, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import EditRemoteModal from './EditRemoteModal'

ExpandedRemote.propTypes = {
  /** The remote unit you plan on passing */
  remoteUnit: PropTypes.object.isRequired,
  /** Is the expanded remote for the management or inventory page. Defaults to management */
  variant: PropTypes.oneOf(['management', 'inventory']),
}
export function ExpandedRemote(props) {
  const { remoteUnit } = props
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = function() {
    setOpenModal(true)
  }

  const handleCloseModal = function() {
    setOpenModal(false)
  }

  const editModal = (
    <EditRemoteModal
      remoteUnit={remoteUnit}
      handleClose={handleCloseModal}
      isOpen={openModal}
    />
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h4">Remote Unit {remoteUnit.localId}</Typography>
      </Grid>
      <Grid item>
        <Typography> Last Seen: {remoteUnit?.lastSeen}</Typography>
      </Grid>
      <Grid container item justify="flex-end">
        <Grid item>
          <Button
            variant="text"
            color="primary"
            onClick={handleOpenModal}
            disabled={!remoteUnit.configuration}
          >
            {' '}
            Edit Configuration
          </Button>
        </Grid>
      </Grid>
      {remoteUnit.configuration ? editModal : <></>}
    </Grid>
  )
}
