import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PlantTableViewPresentational from './PlantTableViewPresentational'
import { useRecoilValue } from 'recoil'
import { atomPlantsFiltersSettings } from '../../Common/tssAtoms'
import { getEditableTablePlantData, updatePlant } from '../Data/TSSDataHelpers'
import { plantDataColumns } from '../Constants/AddDataConstants'
import PlantsTableRow from '../Components/PlantsEditableTable/PlantsTableRow'
import { metricSwitch } from '../../Common/atoms'

/**
 * Creates an object with boolean values for backend filtering based on missing columns.
 * @param {String[]} columnList list of selected column property names
 * @returns {Object}
 */
const createMissingColumnsObject = columnList => {
  const postObject = {}
  for (const property in plantDataColumns) {
    postObject[property] = columnList.includes(property)
  }
  return postObject
}

function PlantTableViewLogical(props) {
  const [page, setPage] = useState(() => 0)
  const [rowsPerPage, setRowsPerPage] = useState(() => 10)
  const [order, setOrder] = useState(() => 'asc')
  const [orderBy, setOrderBy] = useState(() => 'name')
  const [plantData, setPlantData] = useState(() => ({
    results: [],
    count: 0,
  }))

  const plantsFilterSettings = useRecoilValue(atomPlantsFiltersSettings)
  const isMetric = useRecoilValue(metricSwitch)

  const { setIsLoading } = props

  useEffect(() => {
    setPage(0)
  }, [order, orderBy, plantsFilterSettings])

  useEffect(() => {
    setPlantData(prevRows => ({
      count: prevRows.count,
      results: prevRows.results.map(row => {
        if (isMetric && row.edited.isMetric !== isMetric) {
          row.convertToMetric(row.edited)
        } else if (!isMetric && row.edited.isMetric !== isMetric) {
          row.convertToImperial(row.edited)
        }
        return row
      }),
    }))
  }, [isMetric, plantData.count])

  useEffect(() => {
    let isSubscribed = true
    /** Send filter settings to backend to fetch data. */
    const setTableResults = async () => {
      const filterSettings = {
        cementTypes: plantsFilterSettings.cementType,
        divisionIds: plantsFilterSettings.customer,
        missingColumns: createMissingColumnsObject(
          plantsFilterSettings.missingInformation
        ),
        tseList: plantsFilterSettings.tse,
        states: plantsFilterSettings.state,
        onlyPlantsWithInstalls: !plantsFilterSettings.plantsWithoutInstalls,
      }

      setIsLoading(true)
      const response = await getEditableTablePlantData(
        page * rowsPerPage,
        rowsPerPage,
        orderBy,
        order === 'desc' ? 'Descending' : 'Ascending',
        filterSettings
      )

      if (response.ok) {
        const data = await response.json()
        if (isSubscribed) {
          setPlantData({
            count: data.count,
            results: data.results.map(row => {
              let elem = PlantsTableRow.fromKelownaObject(row)
              if (!isMetric) {
                elem.convertToImperial(elem.edited)
              }
              return elem
            }),
          })
        }
        setIsLoading(false)
      }
    }
    setTableResults()
    return () => (isSubscribed = false)
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    plantsFilterSettings,
    isMetric,
    setIsLoading,
  ])

  /**
   * Updates a table row (single plant) in the backend.
   * @param {PlantsTableRow} updatedRow row with updated data.
   * @returns {PlantsTableRow} as fetched from the backend.
   */
  const updateTableRow = async updatedRow => {
    setIsLoading(true)
    const response = await updatePlant(
      updatedRow.toKelownaObject(updatedRow.edited)
    )

    if (!response.ok) {
      console.error('Error while fetching TableRow: ', response.statusText)
      return
    }

    const updatedPlant = await response.json()
    setPlantData(prevData => {
      const updatedTable = []
      prevData.results.forEach(row => {
        if (row.edited.plantId === updatedPlant.plantId) {
          const newRow = PlantsTableRow.fromKelownaObject(updatedPlant)
          if (!isMetric) {
            newRow.convertToImperial(newRow.edited)
          }
          updatedTable.push(newRow)
        } else updatedTable.push(row)
      })
      return { count: prevData.count, results: updatedTable }
    })
    setIsLoading(false)
  }

  return (
    <PlantTableViewPresentational
      count={plantData.count}
      plantData={plantData.results}
      updateTableRow={updateTableRow}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
    />
  )
}

PlantTableViewLogical.propTypes = {
  isClient: PropTypes.bool.isRequired,
  setIsClient: PropTypes.func.isRequired,
  setIsLoading: PropTypes.bool.isRequired,
}

export default PlantTableViewLogical
