import React from 'react'
import { SimpleTableTypes } from '../../Common/Logic/Types'

/** This file contains constants used in the data entry module of TSS. */
export const addDataRouteString = '/Tss/AddData'

/** Add Mix Design Constants */
export const cementTypes = {
  CementTypeI: 'Type I',
  CementTypeIS: 'Type IS',
  CementTypeIP: 'Type IP',
  CementTypeIL: 'Type IL',
  CementTypeII: 'Type II',
  CementTypeI_II: 'Type I/II',
  CementTypeI_IIMH: 'Type I/II MH',
  CementTypeI_II_LA: 'Type I/II LA',
  CementTypeIII: 'Type III',
  CementTypeIV: 'Type IV',
  CementTypeII_V: 'Type II/V',
  CementTypeN: 'Type N',
  CementTypeS: 'Type S',
}

export const plantDataColumns = {
  CO2Dosage: 'CO2 Dosage',
  CementReduction: 'Cement Reduction',
  AvgCementLoading: 'Avg Cement Loading',
  AvgYardsPerTruck: 'Avg Yards / Truck',
  TankRentalFee: 'Tank Rental Fee',
  ResidentialPercent: 'Res. %',
  CommercialPercent: 'Comm. %',
  DotPercent: 'DOT. %',
  Tse: 'TSS Specialist',
}

export const exposureClasses = ['C', 'F', 'N', 'A', 'S', 'R']
export const weightUnits = {
  GallonPerYd3: 'gal / yd\u00B3',
  LbPerYd3: 'lb / yd\u00B3',
  OzPerYd3: 'oz / yd\u00B3',
  OzPerHundredWeight: 'oz / cwt',
  KgPerM3: 'kg / m\u00B3',
  LitrePerM3: 'L / m\u00B3',
  MlPerM3: 'mL / m\u00B3',
  MlPerHundredWeight: 'ml / cwt',
  PercentOfCementitious: '% bwt',
}

export const aggregateTypes = {
  FineAggregate: 'Fine',
  IntermediateAggregate: 'Intermediate',
  CoarseAggregate: 'Coarse',
  Fiber: 'Fiber',
}
export const scmTypes = {
  Slag: 'Slag',
  FlyAshC: 'Class C (Fly Ash)',
  FlyAshF: 'Class F (Fly Ash)',
  FlyAshN: 'Class N (Fly Ash)',
  FlyAsh: 'No Class (Fly Ash)',
  SilicaFume: 'Silica Fume',
}
export const admixTypes = {
  Accelerator: 'Accelerator',
  AirEntrainer: 'AirEntrainer',
  Retarder: 'Retarder',
  SuperPlasticizer: 'SuperPlasticizer',
  WaterReducer: 'WaterReducer',
  HighRangeWaterReducer: 'HighRangeWaterReducer',
  MidRangeWaterReducer: 'MidRangeWaterReducer',
  LowRangeWaterReducer: 'LowRangeWaterReducer',
  ViscosityModifier: 'ViscosityModifier"',
  HydrationStabilizer: 'HydrationStabilizer',
  CorrosionInhibitor: 'CorrosionInhibitor',
  ShrinkageReducer: 'ShrinkageReducer',
  ChlorideAccelerator: 'ChlorideAccelerator',
  NonChlorideAccelerator: 'NonChlorideAccelerator',
  CO2: 'CO2',
  Admixture: 'Admixture',
}
export const allMaterialTypes = {
  Cement: 'Cement',
  Water: 'Water',
  ...aggregateTypes,
  ...scmTypes,
  ...admixTypes,
}

/** Add Batch Constants */
export const weatherConditions = [
  'Broken Clouds',
  'Clear Sky',
  'Drizzle',
  'Dust',
  'Extreme Rain',
  'Few Clouds',
  'Fog',
  'Freezing Rain',
  'Haze',
  'Heavy Intensity Rain',
  'Heavy Intensity Hower Rain',
  'Heavy Snow',
  'Light Intensity Rizzle',
  'Light Intensity Shower Rain',
  'Light Rain',
  'Light Rain and Snow',
  'Light Shower Snow',
  'Light Snow',
  'mist',
  'Moderate Rain',
  'Overcast Clouds',
  'Scattered Clouds',
  'Shower Drizzle',
  'Shower Rain',
  'Shower Sleet',
  'Smoke',
  'Snow',
  'Squalls',
  'Thunderstorm',
  'Thunderstorm with Heavy Rain',
  'Thunderstorm with Light Rain',
  'Thunderstorm with Rain',
  'Very Heavy Rain',
  'No Weather Data',
]
export const testCategories = {
  FieldData: 'Field Data',
  HistoricalData: 'Historical',
  ThreePointCurves: 'Three Points Curve',
  Regulatory: 'Regulatory',
  CarbonCureResearch: 'CarbonCure Lab',
  ExternalLab: 'NonCarbonCure Lab',
  CommissioningTrial: 'Commissioning Trial',
}

export const mixSelectionTestCategories = {
  FieldData: 'Field Data',
  HistoricalData: 'Historical',
  ThreePointCurves: 'Three Points Curve',
  Regulatory: 'Regulatory',
  CarbonCureResearch: 'CarbonCure Lab',
  ExternalLab: 'NonCarbonCure Lab',
  CommissioningTrial: 'Commissioning Trial',
  // The following properties are for handling legacy data
  Other: 'Other',
  IssueTroubleshooting: 'Issue Troubleshooting',
  DosageRamp: 'Dosage Ramp',
  ThreePercentCutTesting: '3% Cut Testing',
  FivePercentCutTesting: '5% Cut Testing',
  CementReduction: 'Cement Reduction',
  DotDurabilityStudy: 'Regulatory', //  Dot Durability Study should be relabeled Regulatory
  CommercialMixDesignData: 'Commericial Mix Design Data',
}

export const commissioningCategoryNames = [
  'CommissioningTrial',
  'CementReduction',
  'DosageRamp',
  'FivePercentCutTesting',
  'ThreePercentCutTesting',
]

const aggregateNames = [
  'FineAggregate',
  'IntermediateAggregate',
  'CoarseAggregate',
  'FlyAsh',
  'Slag',
  'SilicaFume',
]
export const admixtureNames = [
  'CO2',
  'AirEntrainer',
  'Retarder',
  'SuperPlasticizer',
  'HighRangeWaterReducer',
  'MidRangeWaterReducer',
  'LowRangeWaterReducer',
  'ViscosityModifier',
  'HydrationStabilizer',
  'CorrosionInhibitor',
  'ShrinkageReducer',
  'Accelerator',
  'CorrosionInhibitor',
  'NonChlorideAccelerator',
  'Admixture',
  'WaterReducer',
]

/** Fly ash classes used in add batch spreadsheet */
export const flyAshClassDropdown = {
  'Class C': 'FlyAshC',
  'Class F': 'FlyAshF',
  'Class N': 'FlyAshN',
  'No Class': 'FlyAsh',
}

export const spreadsheetColumnWidth = {
  small: 115,
  medium: 130,
  large: 150,
  xl: 160,
  xxl: 185,
}

/** Lists of batch properties and materials, used in spreadsheet columns. */
// Function to create unit objects to avoid sonarCloud duplication
const createUnit = (unitTitle, unitName, appliesTo) => ({
  unitTitle,
  unitName,
  type: 'numeric',
  width: spreadsheetColumnWidth.small,
  appliesTo,
})

//No superscript since these units will be used in spreadsheet upload
export const batchMaterialUnits = {
  Imperial: [
    createUnit('lb', 'Lb', [...aggregateNames, 'Water', 'Cement']),
    createUnit('lb/yd3', 'LbPerYd3', [...aggregateNames, 'Water', 'Cement']),
    createUnit('gal', 'Gal', ['Water']),
    createUnit('gal/yd3', 'GalPerYd3', ['Water']),
    createUnit('oz', 'Oz', [...admixtureNames, 'Water']),
    createUnit('oz/yd3', 'OzPerYd3', [...admixtureNames, 'Water']),
    createUnit('oz/cwt', 'OzPerHundredWeight', [...admixtureNames, 'Water']),
  ],
  Metric: [
    createUnit('kg', 'Kg', [...aggregateNames, 'Water', 'Cement']),
    createUnit('kg/m3', 'KgPerM3', [...aggregateNames, 'Water', 'Cement']),
    createUnit('L', 'L', ['Water']),
    createUnit('L/m3', 'LPerM3', ['Water']),
    createUnit('mL', 'Ml', [...admixtureNames, 'Water']),
    createUnit('mL/m3', 'MlPerM3', [...admixtureNames, 'Water']),
    createUnit('mL/cwt', 'MlPerHundredWeight', [...admixtureNames, 'Water']),
  ],
}

export const addBatchProperties = [
  { title: 'Ticket ID *', name: 'ticketId', type: 'text' },
  { title: 'Mix Code *', name: 'mixCode', type: 'text' },
  {
    title: 'Mix Group Condition *',
    name: 'isCO2Design',
    type: 'dropdown',
    source: ['CO2', 'No CO2'],
    width: spreadsheetColumnWidth.xxl,
  },
  { title: 'Plant *', name: 'plant', type: 'text' },
  {
    title: 'Production Date *',
    name: 'productionDate',
    type: 'calendar',
    width: spreadsheetColumnWidth.xl,
    options: {
      format: 'YYYY-MM-DD',
    },
  },
  { title: 'Parent Mix Code', name: 'parentMixCode', type: 'text' },
  {
    title: 'Test Category *',
    name: 'testCategory',
    type: 'dropdown',
    source: Object.values(testCategories),
  },
  { title: 'Testing Company', name: 'testingCompany', type: 'text' },
  {
    title: 'Testing Condition',
    name: 'wasTestedInField',
    type: 'dropdown',
    source: ['Concrete Plant', 'Job Field Site'],
  },
  {
    title: 'Weather Condition',
    name: 'weatherCondition',
    type: 'dropdown',
    width: spreadsheetColumnWidth.xl,
    source: weatherConditions,
  },
  {
    title: 'Curing Condition',
    name: 'wasCuredInField',
    type: 'dropdown',
    source: ['Field', 'Lab'],
  },

  {
    title: 'Cement Reduction (%) *',
    name: 'cementReduction',
    type: 'numeric',
    width: spreadsheetColumnWidth.xxl,
  },
  { title: 'Load Size', name: 'loadSize', type: 'numeric' },
  { title: 'CO\u2082 Dose (%) *', name: 'CO2Dose', type: 'numeric' },
  {
    title: 'Concrete Temperature',
    name: 'concreteTemp',
    type: 'numeric',
  },
  {
    title: 'Ambient Temperature',
    name: 'ambientTemp',
    type: 'numeric',
  },
  { title: 'Air Content (%)', name: 'airContent', type: 'numeric' },
  { title: 'Slump', name: 'slump', type: 'numeric' },
  { title: 'Unit Weight', name: 'unitWeight', type: 'numeric' },
  { title: 'Water/Cementitious Ratio', name: 'wcRatio', type: 'numeric' },
  { title: 'Truck Number', name: 'truckNumber', type: 'text' },
  {
    title: 'Notes',
    name: 'notes',
    type: 'text',
    width: spreadsheetColumnWidth.xl,
  },
]

export const editBatchProperties = [
  { title: 'Kelowna ID', name: 'batchTestSampleId', type: 'text' },
  ...addBatchProperties,
]

export const addBatchMaterials = [
  { materialTitle: 'Cement', materialName: 'Cement' },
  { materialTitle: 'Water', materialName: 'Water' },
  { materialTitle: 'Fine Agg', materialName: 'FineAggregate' },
  {
    materialTitle: 'Intermediate Agg',
    materialName: 'IntermediateAggregate',
  },
  { materialTitle: 'Coarse Agg', materialName: 'CoarseAggregate' },
  { materialTitle: 'Fly Ash', materialName: 'FlyAsh' },
  { materialTitle: 'Slag', materialName: 'Slag' },
  { materialTitle: 'Silica Fume', materialName: 'SilicaFume' },
  { materialTitle: 'CO2', materialName: 'CO2' },
  { materialTitle: 'AEA', materialName: 'AirEntrainer' },
  {
    materialTitle: 'Retarder',
    materialName: 'Retarder',
  },
  {
    materialTitle: 'Superplasticizer',
    materialName: 'SuperPlasticizer',
  },
  {
    materialTitle: 'HRWR',
    materialName: 'HighRangeWaterReducer',
  },
  {
    materialTitle: 'MRWR',
    materialName: 'MidRangeWaterReducer',
  },
  {
    materialTitle: 'LRWR',
    materialName: 'LowRangeWaterReducer',
  },
  {
    materialTitle: 'VMA',
    materialName: 'ViscosityModifier',
  },
  {
    materialTitle: 'HS',
    materialName: 'HydrationStabilizer',
  },
  {
    materialTitle: 'CNI',
    materialName: 'CorrosionInhibitor',
  },
  {
    materialTitle: 'SRA',
    materialName: 'ShrinkageReducer',
  },
  {
    materialTitle: 'Accelerator',
    materialName: 'Accelerator',
  },
  {
    materialTitle: 'CI',
    materialName: 'CorrosionInhibitor',
  },
  {
    materialTitle: 'NCI',
    materialName: 'NonChlorideAccelerator',
  },
  {
    materialTitle: 'Other',
    materialName: 'Admixture',
  },
  {
    materialTitle: 'WRA',
    materialName: 'WaterReducer',
  },
]

/** List of required columns for validation in AddBatchView when adding. */
export const addBatchRequiredCols = {
  ticketId: 'Ticket ID',
  mixCode: 'Mix Code',
  isCO2Design: 'Condition',
  cementReduction: 'Cement Reduction (%)',
  CO2Dose: 'CO₂ Dose (%)',
  productionDate: 'Production Date',
  testCategory: 'Test Category',
  plant: 'Plant',
}

/** List of required columns for validation in AddBatchView when editing. */
export const editBatchRequiredCols = {
  batchTestSampleId: 'Sample Id',
  ticketId: 'Ticket ID',
  mixCode: 'Mix Code',
  isCO2Design: 'Condition',
  cementReduction: 'Cement Reduction (%)',
  CO2Dose: 'CO₂ Dose (%)',
  productionDate: 'Production Date',
  testCategory: 'Test Category',
  plant: 'Plant',
}

export const headCellsColumnWidth = {
  size1: '5.87%',
  size2: '5.88%',
  wide: '7.5%',
}

/** Headers to be used in various tables */
const dropdownHeader = {
  id: 'dropdownCol',
  name: '',
  sortable: false,
  width: '50px',
  xxlWidth: headCellsColumnWidth.size2,
}
const divisionNameHeader = {
  id: 'divisionName',
  numeric: false,
  name: 'Producer',
  sortable: true,
  width: '76px',
  xxlWidth: headCellsColumnWidth.size2,
  wordBreak: false,
}
const plantNameHeader = {
  id: 'plantName',
  numeric: false,
  name: 'Loc.',
  tooltip: 'Location',
  sortable: true,
  width: '76px',
  xxlWidth: headCellsColumnWidth.size2,
  wordBreak: false,
}
const mixCodeHeader = {
  id: 'mixCode',
  numeric: false,
  name: 'Mix #',
  tooltip: 'Mix Code',
  sortable: true,
  width: '90px',
  xxlWidth: headCellsColumnWidth.size2,
}
const statusHeader = {
  id: 'isActiveMixGroup',
  numeric: false,
  name: 'Status',
  sortable: true,
  width: '90px',
  xxlWidth: headCellsColumnWidth.size2,
}
const conditionHeader = {
  id: 'isCO2Design',
  numeric: false,
  name: 'Cond.',
  tooltip: 'Condition',
  sortable: true,
  width: '90px',
  xxlWidth: headCellsColumnWidth.size2,
}
const cementReductionHeader = {
  id: 'cementReductionPercent',
  name: 'Cmt. Red.',
  tooltip: 'Cement Reduction',
  sortable: true,
  width: '40px',
  xxlWidth: headCellsColumnWidth.size2,
}
const co2DosageHeader = {
  id: 'co2Dosage',
  name: 'CO2 Dos.',
  tooltip: 'CO2 Dosage',
  sortable: true,
  width: '50px',
  xxlWidth: headCellsColumnWidth.size1,
}
const co2DosageUnitHeader = {
  id: 'cO2DosageUnit',
  name: 'CO2 Dos. Unit',
  tooltip: 'CO2 Dosage Unit',
  width: '70px',
  xxlWidth: headCellsColumnWidth.size2,
}
const totalBatchesHeader = {
  id: 'totalBatches',
  numeric: true,
  name: '# Samp.',
  tooltip: 'Samples',
  width: '50px',
  xxlWidth: headCellsColumnWidth.size2,
}
const designStrengthHeader = {
  id: 'designStrengthMpa',
  numeric: true,
  name: 'Des. Str.',
  tooltip: 'Design Strength',
  sortable: true,
  width: '60px',
  xxlWidth: headCellsColumnWidth.size2,
}
const averageStrengthHeader = {
  id: 'averageStrengthMpa',
  numeric: true,
  name: 'Avg. Str.',
  tooltip: 'Average Strength',
  width: '60px',
  xxlWidth: headCellsColumnWidth.size2,
}
const cementitiousLoadingHeader = {
  id: 'cementitiousContentKgPerM3',
  numeric: true,
  name: 'Cmt. Load.',
  tooltip: 'Cementitious Loading',
  width: '60px',
  xxlWidth: headCellsColumnWidth.size2,
}
const cementitiousEfficiencyHeader = {
  id: 'cementitiousEfficiency',
  numeric: true,
  name: 'Cmt. Eff.',
  tooltip: 'Cementitious Efficiency',
  width: '60px',
  xxlWidth: headCellsColumnWidth.size2,
}
const waterCementRatioHeader = {
  id: 'waterCementRatio',
  numeric: true,
  name: 'W/CM',
  tooltip: 'Water/Cement Ratio',
  sortable: true,
  width: '50px',
  xxlWidth: headCellsColumnWidth.size2,
}
const percentSCMHeader = {
  id: 'scmPercent',
  numeric: true,
  name: '%SCM',
  tooltip: '% Supplementary Cementitious Material',
  sortable: true,
  width: '50px',
  xxlWidth: headCellsColumnWidth.size2,
}
const totalYieldHeader = {
  id: 'yieldUnitless',
  numeric: true,
  name: 'Tot. Yld.',
  tooltip: 'Total Yield',
  width: '80px',
  xxlWidth: '5.92%',
}
const totalCylindersHeader = {
  id: 'totalCylinders',
  name: '# Cyl.',
  tooltip: 'Total Cylinders',
  width: '50px',
  xxlWidth: headCellsColumnWidth.size1,
}
const editAssociationHeader = {
  id: 'editAssociation',
  name: '',
  width: '100px',
  xxlWidth: headCellsColumnWidth.wide,
}
const intervalHeader = {
  id: 'strengthIntervalDays',
  name: 'Int.',
  tooltip: 'Interval',
  sortable: true,
  width: '70px',
  xxlWidth: headCellsColumnWidth.size1,
}

/** AddDataView columns widths. widths must add up to ~100% */
export const headCellsAddDataView = [
  { ...dropdownHeader },
  { ...divisionNameHeader },
  { ...plantNameHeader },
  { ...mixCodeHeader },
  { ...statusHeader },
  { ...conditionHeader },
  { ...cementReductionHeader },
  { ...co2DosageHeader },
  { ...co2DosageUnitHeader },
  { ...totalBatchesHeader },
  { ...totalCylindersHeader },
  { ...designStrengthHeader },
  { ...intervalHeader },
  { ...waterCementRatioHeader },
  { ...cementitiousLoadingHeader },
  { ...totalYieldHeader },
  { ...editAssociationHeader },
]
export const addStyles = (tableType, theme, selected = []) => {
  let styles = {}
  if (tableType === SimpleTableTypes.AddDataMixGroup) {
    styles.selectorCell = {
      width: '78px',
      [theme.breakpoints.up(2000)]: {
        width: headCellsColumnWidth.size1,
      },
    }
    headCellsAddDataView.forEach(cell => {
      //show sticky column only when selecting mix group(id: number), but not variations (id:string)
      if (selected.some(Number) && cell.id === 'editAssociation') {
        styles[cell.id] = {
          width: cell.width,
          [theme.breakpoints.up(2000)]: {
            width: cell.xxlWidth,
          },
          [theme.breakpoints.down(2000)]: {
            position: 'sticky',
            right: 0,
            backgroundColor: '#f9f9f9',
          },
        }
      } else {
        styles[cell.id] = {
          width: cell.width,
          [theme.breakpoints.up(2000)]: {
            width: cell.xxlWidth,
          },
        }
      }
    })
  }
  if (tableType === SimpleTableTypes.ViewDesignMixGroup) {
    styles.selectorCell = {
      width: '78px',
      [theme.breakpoints.up(2200)]: {
        width: '5.88%',
      },
    }
    headCellsMixDesigns.forEach(cell => {
      styles[cell.id] = {
        width: cell.width,
        [theme.breakpoints.up(2200)]: {
          width: cell.xxlWidth ? cell.xxlWidth : cell.width,
        },
      }
    })
  }
  return styles
}
/* TSS Analysis column widths. widths must add up to ~100% */
export const headCellsMixDesigns = [
  { ...dropdownHeader },
  { ...divisionNameHeader },
  { ...plantNameHeader },
  { ...mixCodeHeader },
  { ...statusHeader },
  { ...conditionHeader },
  { ...cementReductionHeader },
  { ...co2DosageHeader },
  { ...co2DosageUnitHeader },
  { ...totalBatchesHeader },
  { ...designStrengthHeader },
  { ...averageStrengthHeader },
  { ...cementitiousLoadingHeader },
  { ...cementitiousEfficiencyHeader },
  { ...waterCementRatioHeader },
  { ...percentSCMHeader },
  { ...totalYieldHeader },
]

export const addDataTooltips = {
  editMix: 'Edit Mix Group',
  viewMix: 'View Mix Group',
  viewAssociation: 'View Mix Association',
  baleen: 'Baleen',
}

export const defaultCement = {
  aliases: ['DefaultCement'],
  cementSupplier: null,
  columnNumber: 1,
  frontendName: 'Cement-Cement-null-null',
  isIngested: false,
  materialId: null,
  materialMappingId: null,
  materialType: 'Cement',
  name: 'DefaultCement',
  primaryMaterialType: 'Cement',
  specificGravity: null,
  supplierCompany: null,
  supplierPlant: null,
  title: 'DefaultCement',
  isDefaultCement: true,
  isDefaultCO2: false,
}

export const defaultCO2 = {
  aliases: ['DefaultCO2'],
  cementSupplier: null,
  columnNumber: 1,
  frontendName: 'CO2-CO2-null-null',
  isIngested: false,
  materialId: null,
  materialMappingId: null,
  materialType: 'CO2',
  name: 'DefaultCO2',
  primaryMaterialType: 'CO2',
  specificGravity: null,
  supplierCompany: null,
  supplierPlant: null,
  title: 'DefaultCO2',
  isDefaultCement: false,
  isDefaultCO2: true,
}

export const addMixMaterialTooltips = {
  Type: 'Material Type',
  Subtype: 'Material Subtype',
  Supplier: 'Supplier Company',
  Plant: 'Supplier Plant',
}

export const addBatchInstructionList = [
  {
    id: 1,
    listItem: <span>Required columns are marked with an asterisk (*).</span>,
  },
  {
    id: 2,
    listItem: (
      <span>
        Use the correct formatting for each cell (e.g. correct date format in
        the <i>Production Date</i> column).
      </span>
    ),
  },
  {
    id: 3,
    listItem: (
      <span>
        Make sure the number of rows matches the number of batches selected.
        When editing, don't add or remove rows.
      </span>
    ),
  },
  {
    id: 4,
    listItem: (
      <span>
        Duplicate Ticket IDs are not accepted. All Ticket IDs must be unique.
      </span>
    ),
  },
]
