import { cementTypes } from './AddDataConstants'

export const echoFilterByPanel = {
  name: 'GENERAL',
  category: 'general',
  filters: [
    {
      property: 'filterBy',
      name: 'Filter By',
      componentType: 'EchoFilterBy',
    },
  ],
}

export const echoSalesPanel = {
  name: 'SALES',
  category: 'sales',
  filters: [
    {
      property: 'sales',
      name: 'Sales',
      componentType: 'EchoSales',
    },
  ],
}

export const echoMapLayersPanel = {
  name: 'MAP LAYERS',
  category: 'mapLayers',
  filters: [
    {
      property: 'mapLayers',
      name: 'Map Layers',
      componentType: 'EchoMapLayer',
    },
  ],
}

export const echoProducersPanel = {
  name: 'PRODUCERS',
  category: 'producers',
  filters: [
    {
      property: 'producers',
      name: 'Producers',
      componentType: 'EchoProducer',
    },
  ],
}

export const echoProjectSearchPanel = {
  name: 'PROJECT SEARCH',
  category: 'projectSearch',
  filters: [
    {
      property: 'projectSearch',
      name: 'Project Search',
      componentType: 'EchoProjectFilters',
    },
  ],
}

export const cementTypeOptions = [
  { id: 'CementBlendedHydraulic', name: 'Blended Hydraulic' },
  ...(cementTypes
    ? Object.keys(cementTypes).map(key => ({
        id: key,
        name: cementTypes[key as keyof typeof cementTypes],
      }))
    : []),
  { id: 'Cement', name: 'Cement' },
  { id: 'Other', name: 'Other' },
]

export const labResultOptions = [
  // id is pascal case to match with kelowna's values
  { id: 'All', name: 'All' },
  { id: 'Positive', name: 'Positive' },
  { id: 'Negative', name: 'Negative' },
  { id: 'NotTested', name: 'Not Tested' },
]

export const rmProducerRangeOptions = [
  { id: '8km', name: '5 mi / 8 km' },
  { id: '16km', name: '10 mi / 16 km' },
  { id: '40km', name: '25 mi / 40 km' },
  { id: '80km', name: '50 mi / 80 km' },
  { id: '160km', name: '100 mi / 160 km' },
  { id: '320km', name: '200 mi / 320 km' },
  { id: '800km', name: '500 mi / 800 km' },
]
