import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ServiceCaseModalPresentational from './ServiceCaseModalPresentational'
import AlarmForTable from '../Logic/AlarmForTable'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import ServiceCaseObject from '../Logic/ServiceCaseObject'
import { emptyStringToNull } from '../Logic/AlarmsManagementLogicHelpers'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import { DotNetSix } from '../../../Common/assets/FeatureFlags'

const yesterdaysDate = new Date()
yesterdaysDate.setHours(0, 0, 0, 1)
yesterdaysDate.setDate(yesterdaysDate.getDate() - 1)

const serviceCaseSchema = yup.object().shape({
  solvedRemotely: yup.boolean(),
  uniqueIssue: yup.boolean(),
  note: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  system: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  caseOpened: yup.date().typeError('Case Opened Must be filled'),
  caseClosed: yup
    .date()
    .transform(emptyStringToNull)
    .nullable(),
  replacementPartsNeededDate: yup
    .date()
    .transform(emptyStringToNull)
    .nullable(),
  partsShippedDate: yup
    .date()
    .transform(emptyStringToNull)
    .nullable(),
  partsReceivedDate: yup
    .date()
    .transform(emptyStringToNull)
    .nullable(),
  carbonCureTechnicianRemote: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  initialIndication: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  partResponsibleForIssue: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  followUpRequired: yup.boolean(),
  followUpSummary: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  errorMessage: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  plantPointOfContact: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  status: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  marketDevRepEmail: yup
    .string()
    .email('Must be valid email')
    .transform(emptyStringToNull)
    .nullable(),
  descriptionOfProblemByCustomer: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  actionsTakenRemotely: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  onsiteServiceRequired: yup.boolean(),
  onsiteTechnician: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  criticalRepairPlan: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  anticipatedRepairDate: yup
    .date()
    .nullable()
    .transform(emptyStringToNull),
  repairPlanApproved: yup.boolean(),
  arrivedOnsite: yup.boolean(),
  solvedOnsite: yup.boolean(),
  actionsTakenOnsite: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
  replacedComponents: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(),
})

/**
 *
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Function} props.setOpen
 * @param {0 | 1 | 2 | 3} props.selectedTab
 * @param {ServiceCaseTableObject | AlarmForTable} props.serviceCaseSource
 * @param {Function} props.setServiceCaseSource
 * @returns
 */
function ServiceCaseModalLogical(props) {
  const { open, setOpen, selectedTab, serviceCaseSource } = props

  /** The value of the currently selected tab */
  const [tabValue, setTabValue] = useState(0)
  /** Boolean to determine whether a service case is being edited */
  const [editModeActive, setEditModeActive] = useState(false)
  /** Draft of the service case changes */
  const [serviceCaseDraft, setServiceCaseDraft] = useState({})

  const [systemDown, setSystemDown] = useState(null)
  const [systemUp, setSystemUp] = useState(null)

  const [errorMessageFromFetch, setErrorMessageFromFetch] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)

  const formMethods = useForm({
    resolver: yupResolver(serviceCaseSchema),
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      systemDown: null,
      systemUp: null,
      solvedRemotely: false,
      uniqueIssue: false,
      note: null,
      system: null,
      caseOpened: null,
      caseClosed: null,
      replacementPartsNeededDate: null,
      partsShippedDate: null,
      partsReceivedDate: null,
      carbonCureTechnicianRemote: null,
      initialIndication: null,
      partResponsibleForIssue: null,
      followUpRequired: false,
      followUpSummary: null,
      errorMessage: null,
      plantPointOfContact: null,
      status: 'Open',
      marketDevRepEmail: null,
      descriptionOfProblemByCustomer: null,
      actionsTakenRemotely: null,
      onsiteServiceRequired: false,
      onsiteTechnician: null,
      criticalRepairPlan: null,
      anticipatedRepairDate: null,
      repairPlanApproved: false,
      arrivedOnsite: false,
      solvedOnsite: false,
      actionsTakenOnsite: null,
      replacedComponents: null,
    },
  })

  const { handleSubmit, reset } = formMethods

  useEffect(() => {
    async function getServiceCaseFromTableObject() {
      if (open && serviceCaseSource instanceof ServiceCaseTableObject) {
        // get service case and notes from Kelowna
        const response = await AlarmsManagementDataHelpers.getServiceCase(
          serviceCaseSource.serviceCaseId
        )
        if (response.ok) {
          const data = await response.json()
          const serviceCase = new ServiceCaseObject(data)
          const systemDownDateObj = serviceCase.systemDownTimestamp
            ? new Date(serviceCase.systemDownTimestamp)
            : null
          const systemUpDateObj = serviceCase.systemUpTimestamp
            ? new Date(serviceCase.systemUpTimestamp)
            : null
          reset(serviceCase.serviceCaseInput)
          setServiceCaseDraft(serviceCase)
          setSystemDown(systemDownDateObj)
          setSystemUp(systemUpDateObj)
        }
      }
    }
    getServiceCaseFromTableObject()
  }, [open, serviceCaseSource, reset])

  /** Switch to a different tab */
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleCloseSnackbar = () => {
    setAlertOpen(false)
  }

  /** Close the service case modal */
  const handleClose = () => {
    setSystemDown(null)
    setSystemUp(null)
    setOpen(false)
    setEditModeActive(false)
    setTabValue(0)
    setServiceCaseDraft({})
    reset({})
  }

  /** Save the service case changes */
  const submitFormData = async data => {
    if (DotNetSix) {
      data.replacementPartsNeededDate = data.replacementPartsNeededDate?.toLocaleDateString()
      data.partsShippedDate = data.partsShippedDate?.toLocaleDateString()
      data.partsReceivedDate = data.partsReceivedDate?.toLocaleDateString()
      data.anticipatedRepairDate = data.anticipatedRepairDate?.toLocaleDateString()
    }
    data.systemDown = systemDown
    data.systemUp = systemUp
    let response = null
    if (serviceCaseSource instanceof AlarmForTable) {
      data.alarmId = serviceCaseSource.alarmId
      response = await AlarmsManagementDataHelpers.postServiceCase(data)
      if (!serviceCaseSource.resolved && response.ok) {
        serviceCaseSource.clearAlarm()
      }
    } else if (serviceCaseSource instanceof ServiceCaseTableObject) {
      data.alarmId = serviceCaseDraft.alarmId
      data.serviceCaseId = serviceCaseDraft.serviceCaseId
      response = await AlarmsManagementDataHelpers.putServiceCase(data)
    }

    if (response) {
      if (response.ok) {
        handleClose()
      } else {
        setAlertOpen(true)
        setErrorMessageFromFetch(`${response.status} - ${response.statusText}`)
      }
    }
  }

  const onError = (errors, e) => console.error(errors, e)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitFormData, onError)} autoComplete="off">
        <ServiceCaseModalPresentational
          open={open}
          selectedTab={selectedTab}
          serviceCaseSource={serviceCaseSource}
          tabValue={tabValue}
          editModeActive={editModeActive}
          setEditModeActive={setEditModeActive}
          handleChange={handleChange}
          handleClose={handleClose}
          handleSubmit={handleSubmit(submitFormData)}
          setServiceCaseDraft={setServiceCaseDraft}
          serviceCaseDraft={serviceCaseDraft}
          systemDownDateObj={systemDown}
          handleChangeSystemDown={setSystemDown}
          systemUpDateObj={systemUp}
          handleChangeSystemUp={setSystemUp}
          hmiId={serviceCaseSource?.hmiId}
        />
      </form>
      <Snackbar
        open={alertOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={5000}
      >
        <Alert
          severity="error"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessageFromFetch}
        </Alert>
      </Snackbar>
    </FormProvider>
  )
}

ServiceCaseModalLogical.propTypes = {
  /** Boolean to determine whether the service case modal is open */
  open: PropTypes.bool.isRequired,
  /** State setter to open and close the service case modal */
  setOpen: PropTypes.func.isRequired,
  /** The name of the selected tab */
  selectedTab: PropTypes.string.isRequired,
  /** The service case displayed when the modal opens: existing or new */
  serviceCaseSource: PropTypes.oneOfType([
    PropTypes.instanceOf(AlarmForTable),
    PropTypes.instanceOf(ServiceCaseTableObject),
    PropTypes.object,
  ]),
  /** State setter to update the service case displayed in the modal */
  setServiceCaseSource: PropTypes.func,
}

export default ServiceCaseModalLogical
