import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Box,
} from '@material-ui/core'
import EchoTableHead from '../EchoTableHead/EchoTableHead'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import PropTypes from 'prop-types'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  wideCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    width: '200px',
    overflow: 'hidden',
  },
}))

function CO2VendorsTablePresentational(props) {
  CO2VendorsTablePresentational.propTypes = {
    /** Array of selected table rows */
    selectedRows: PropTypes.array.isRequired,
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** Function to sort the table rows */
    handleRequestSort: PropTypes.func.isRequired,
    /** Function to handle selecting table rows */
    handleClick: PropTypes.func.isRequired,
    /** Function to switch to a different table page */
    handleChangePage: PropTypes.func.isRequired,
    /** Change the number of table rows displayed per page */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Filtered and formatted array of CO2 vendors */
    formattedCO2VendorData: PropTypes.array.isRequired,
  }

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleRequestSort,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    selectedRows,
    formattedCO2VendorData,
  } = props

  const classes = useStyles()

  /** Keep track of which table rows are selected */
  const isSelected = name => selectedRows.indexOf(name) !== -1

  /** Table column headers */
  const headCells = [
    {
      id: 'companyName',
      numeric: false,
      label: 'Owner',
    },
    {
      id: 'fullStateName',
      numeric: false,
      label: 'State',
    },
    {
      id: 'depotCount',
      numeric: true,
      label: '# Dep.',
    },
    {
      id: 'avgCO2PriceUsdPerKg',
      numeric: false,
      label: 'Avg CO₂ $',
    },
    {
      id: 'tankLeadTimeWeeks',
      numeric: true,
      label: 'Tank Lead Time (wks)',
    },
    {
      id: 'contact',
      numeric: false,
      label: 'Contact',
    },
  ]

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="CO2 vendors"
            size={'small'}
          >
            <EchoTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              includeCheckbox={true}
            />
            <TableBody>
              {/* Map through the filtered data and display it as sortable rows */}
              {stableSort(formattedCO2VendorData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  /** Keep track of selected rows */
                  const isItemSelected = isSelected(row)
                  const labelId = `enhanced-table-checkbox-${row.id}`
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* Row checkbox */}
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          id={`${row.id}`}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell id={labelId} className={classes.wideCell}>
                        {row.companyName}
                      </TableCell>
                      <TableCell className={classes.wideCell}>
                        {row.fullStateName}
                      </TableCell>
                      <TableCell>{row.depotCount}</TableCell>
                      <TableCell>{row.avgCO2PriceUsdPerKg}</TableCell>
                      <TableCell>{row.tankLeadTimeWeeks}</TableCell>
                      <TableCell className={classes.wideCell}>
                        {row.contact}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={formattedCO2VendorData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default CO2VendorsTablePresentational
