import { ownershipColors } from '../Constants/Constants'

/** This function takes in the latitude and longitude of two locations and returns the straight-line distance between them (in km) */
export const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1) // deg2rad below
  let dLon = deg2rad(lon2 - lon1)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in km
}
function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

/** Function to convert ISO 8601 timestamp to YYYY/MM/DD format */
export const convertTimestamp = timestamp => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }
  return year + '/' + month + '/' + day
}

/** Merges two arrays of objects based on a shared property.
 * @param {Array} array1 - The first array of objects.
 * @param {Array} array2 - The second array of objects.
 * @param {String} prop - A property that both arrays of objects have in common.
 * @returns {Array} - Returns a single array of objects. Objects that share the chosen property as a key are merged together.
 */
export const mergeArraysByProperty = (array1, array2, prop) => {
  return array2.map(function(array2Item) {
    let array1Item = array1.find(function(item1) {
      return item1[prop] === array2Item[prop]
    })
    return { ...array1Item, ...array2Item }
  })
}

/** Determines the colour of the circle icon to display on each table row of the ready mix and precast tables.
 * @param {Array} row - The table row
 * @param {Boolean} filterBySelected - Boolean to determine whether any of the "Filter by" filters is active
 * @returns {String} - Returns a string with a colour hex code.
 */
export const getConcreteTableColor = (row, filterBySelected) => {
  let rowColor
  if (!filterBySelected) {
    if (row.customerRow) {
      /** Customers */
      rowColor = ownershipColors.customers.hex
      /** Privately owned */
    } else if (!row.customerRow && !row.isVerticallyIntegrated) {
      rowColor = ownershipColors.privatelyOwned.hex
      /** Vertically integrated */
    } else if (!row.customerRow && row.isVerticallyIntegrated) {
      rowColor = ownershipColors.verticallyIntegrated.hex
    }
  } else rowColor = row.uniqueColor.hex
  return rowColor
}
