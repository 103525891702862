//breakpoints in numeric values for easier manipulation, add 'px' in code when using them
const breakpoints = {
  sm: '600',
  md: '740',
  lg: '1080',
  xl: '1280',
  xxl: '1600',
  xxxl: '1920',
}

export default breakpoints
