import React, { Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import {
  ICommissionReportSettings,
  IMixSelection,
  VariationTypes,
} from '../../Logic/Types'
import { Button, Grid, Paper, makeStyles } from '@material-ui/core'
import {
  getFilteredSampleIds,
  getVariationWithFilteredSamples,
} from '../../Helpers/CommissionReportHelpers'
import TestResultsHistogramSettings from './TestResultsHistogramSettings'
import { ArrowBack } from '@material-ui/icons'
import TestResultsHistogram from './TestResultsHistogram'
import CommissioningBarGraphLogical from '../CommissioningBarGraph/CommissioningBarGraphLogical'
import TestResultsLegend from './TestResultsLegend'
import { BarGraphPage } from '../../../Common/Logic/Types'
import TestResultsBarGraphSettings from './TestResultsBarGraphSettings'
import { getMaturityIntervalOptions } from './CommissionReportGeneratorHelpers'
import TestResultsTableLogical from './TestResultsTableLogical'

export interface ITestResultsGraphsProps {
  reportSettings: ICommissionReportSettings
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  mixSelections: IMixSelection[]
  tabValue: number
  isCommissionReportViewMode: boolean
  isPrinting: boolean
}

const useStyles = makeStyles(() => ({
  pdfGraphItems: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  testResultsPaper: {
    padding: '16px 24px',
    margin: '24px',
    width: '100%',
  },
  pdfTestResultsPaper: {
    padding: '16px 24px 16px 0px',
    margin: '0px 24px 24px 24px',
    width: '100%',
  },
}))

function TestResultsGraphs(props: Readonly<ITestResultsGraphsProps>) {
  const {
    reportSettings,
    setReportSettings,
    mixSelections,
    tabValue,
    isCommissionReportViewMode,
    isPrinting,
  } = props
  const classes = useStyles()

  if (!mixSelections.length || !reportSettings) return null

  const digestedVariations = mixSelections[tabValue]?.digestedVariations
  const baselineVariation = getVariationWithFilteredSamples(
    digestedVariations,
    VariationTypes.BASELINE,
    reportSettings
  )
  const carbonCureVariation = getVariationWithFilteredSamples(
    digestedVariations,
    VariationTypes.OPTIMIZED,
    reportSettings
  )

  const mixDesignId = mixSelections[tabValue].mixDesignId
  const mixDesignSettings = reportSettings.mixDesignSettings[tabValue]

  const showFrequencyGraph = mixDesignSettings
    ? mixDesignSettings.frequencyGraph?.isSelected
    : false
  const showAvgStrengthGraph = mixDesignSettings
    ? mixDesignSettings.avgStrengthGraph.isSelected
    : false

  const showDesignStrength =
    mixDesignSettings.avgStrengthGraph.showDesignStrength
  const selectedInterval = mixDesignSettings.avgStrengthGraph.selectedInterval

  const batchIds = [
    ...(baselineVariation ? getFilteredSampleIds(baselineVariation) : []),
    ...(carbonCureVariation ? getFilteredSampleIds(carbonCureVariation) : []),
  ]
  const path = `/Concrete/AddData/AddBatchData?divisionId=${
    reportSettings?.producerId
  }&${batchIds.join('&')}`
  const maturityIntervalOptions = getMaturityIntervalOptions(
    mixSelections[tabValue]
  )

  return (
    <Grid container spacing={4}>
      {showAvgStrengthGraph && showFrequencyGraph && (
        <Paper
          elevation={1}
          className={
            isPrinting ? classes.pdfTestResultsPaper : classes.testResultsPaper
          }
        >
          <TestResultsTableLogical
            mixSelection={mixSelections[tabValue]}
            reportSettings={reportSettings}
            setReportSettings={setReportSettings}
            tabValue={tabValue}
            isCommissionReportViewMode={isCommissionReportViewMode}
            isPrinting={isPrinting}
          />
        </Paper>
      )}
      <Grid
        item
        xs={6}
        className={isPrinting ? classes.pdfGraphItems : undefined}
      >
        {showFrequencyGraph && (
          <>
            <TestResultsHistogram
              baselineVariation={baselineVariation}
              carbonCureVariation={carbonCureVariation}
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              mixSelections={mixSelections}
              tabValue={tabValue}
            />
            {!showAvgStrengthGraph && (
              <div
                style={{
                  marginTop: '16px',
                  marginBottom: '10px',
                  marginRight: '28px',
                }}
              >
                <TestResultsLegend isPrinting={isPrinting} />
              </div>
            )}
            {!isCommissionReportViewMode && (
              <TestResultsHistogramSettings
                reportSettings={reportSettings}
                setReportSettings={setReportSettings}
                mixSelections={mixSelections}
                tabValue={tabValue}
              />
            )}
          </>
        )}
        {showAvgStrengthGraph && !showFrequencyGraph && (
          <>
            <CommissioningBarGraphLogical
              mixVariationsData={mixSelections}
              isMetric={reportSettings?.isMetric}
              pageName={BarGraphPage.COMMISSION}
              showDesignStrength={showDesignStrength}
              selectedInterval={selectedInterval}
              tabValue={tabValue}
            />
            <div style={{ marginLeft: '68px' }}>
              <TestResultsLegend
                showDesignStrength={showDesignStrength}
                isPrinting={isPrinting}
              />
            </div>
            {!isCommissionReportViewMode && (
              <TestResultsBarGraphSettings
                maturityIntervalOptions={maturityIntervalOptions}
                showDesignStrength={showDesignStrength}
                selectedInterval={selectedInterval}
                mixDesignId={mixDesignId}
                setReportSettings={setReportSettings}
                dualGraph={showFrequencyGraph && showAvgStrengthGraph}
                tabValue={tabValue}
              />
            )}
          </>
        )}
      </Grid>
      <Grid
        item
        xs={6}
        className={isPrinting ? classes.pdfGraphItems : undefined}
      >
        <Grid container direction="column" justify="space-between">
          <Grid
            item
            xs
            style={
              showFrequencyGraph && showAvgStrengthGraph
                ? { borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }
                : {}
            }
          >
            {showFrequencyGraph && showAvgStrengthGraph ? (
              <>
                <CommissioningBarGraphLogical
                  mixVariationsData={mixSelections}
                  isMetric={reportSettings?.isMetric}
                  pageName={BarGraphPage.COMMISSION}
                  showDesignStrength={showDesignStrength}
                  selectedInterval={selectedInterval}
                  tabValue={tabValue}
                />
                {!isCommissionReportViewMode && (
                  <TestResultsBarGraphSettings
                    maturityIntervalOptions={maturityIntervalOptions}
                    showDesignStrength={showDesignStrength}
                    selectedInterval={selectedInterval}
                    mixDesignId={mixDesignId}
                    setReportSettings={setReportSettings}
                    tabValue={tabValue}
                    isCommissionReportViewMode={isCommissionReportViewMode}
                    isPrinting={isPrinting}
                    dualGraph={showFrequencyGraph && showAvgStrengthGraph}
                  />
                )}
              </>
            ) : (
              <Paper
                elevation={1}
                style={{ padding: '24px', marginBottom: '24px' }}
              >
                <TestResultsTableLogical
                  mixSelection={mixSelections[tabValue]}
                  reportSettings={reportSettings}
                  setReportSettings={setReportSettings}
                  tabValue={tabValue}
                  isCommissionReportViewMode={isCommissionReportViewMode}
                  isPrinting={isPrinting}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        {showFrequencyGraph && showAvgStrengthGraph && (
          <Grid style={{ alignSelf: 'center', marginLeft: '68px' }}>
            <TestResultsLegend
              showDesignStrength={showDesignStrength}
              isPrinting={isPrinting}
            />
          </Grid>
        )}
        {!isCommissionReportViewMode && (
          <Grid style={{ padding: '0px 0px 24px 24px' }}>
            <Button
              startIcon={<ArrowBack />}
              color="primary"
              size="medium"
              style={{ textTransform: 'capitalize' }}
              component={Link}
              target="_blank"
              to={path}
            >
              View Batch Data
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default TestResultsGraphs
