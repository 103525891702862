import React from 'react'
import PropTypes from 'prop-types'
import CO2VendorsTablePresentational from './CO2VendorsTablePresentational'
import { CO2DepotTableFeature } from '../../../Common/assets/FeatureFlags'

function CO2VendorsTableLogical(props) {
  CO2VendorsTableLogical.propTypes = {
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** The table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Filtered and formatted array of CO2 vendors */
    formattedCO2VendorData: PropTypes.array.isRequired,
  }

  const {
    setSelectedRows,
    selectedRows,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    formattedCO2VendorData,
  } = props

  /** Function to sort the table rows */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Function to handle selecting table rows */
  const handleClick = (event, row) => {
    /** Turn on the loading spinner until the selected vendor is highlighted on the map */
    setIsLoading(true)
    setTimeout(() => {
      setSelectedRows(prevRows => {
        if (prevRows.some(prevRow => prevRow === row))
          return prevRows.filter(prevRow => prevRow !== row)
        else return [...prevRows, row]
      })
      setIsLoading(false)
    }, 1000)
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  if (CO2DepotTableFeature) {
    return (
      <CO2VendorsTablePresentational
        handleRequestSort={handleRequestSort}
        handleClick={handleClick}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selectedRows={selectedRows}
        page={page}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        formattedCO2VendorData={formattedCO2VendorData}
      />
    )
  }
  return null
}

export default CO2VendorsTableLogical
