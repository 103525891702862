import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import UpcomingProjectsLegend from './LegendItems/UpcomingProjectsLegend'
import OngoingProjectsLegend from './LegendItems/OngoingProjectsLegend'
import FinishedProjectsLegend from './LegendItems/FinishedProjectsLegend'
import CementPlantLegend from './LegendItems/CementPlantLegend'
import CO2DepotsLegend from './LegendItems/CO2DepotsLegend'
import OpportunitiesLegend from './LegendItems/OpportunitiesLegend'
import ProducersLegend from './LegendItems/ProducersLegend'

const useStyles = makeStyles(() => ({
  container: {
    gap: '12px',
  },
}))

/** The map legend, displayed under the map */
function MapLegend(props) {
  MapLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
  }
  const { filteredCorpData } = props
  const classes = useStyles()

  /** Atom with Echo Settings **/
  const echoSettings = useRecoilValue(atomEchoSettings)

  return (
    <Grid container item xs={12} className={classes.container}>
      <ProducersLegend
        echoSettings={echoSettings}
        filteredCorpData={filteredCorpData}
      />
      <CementPlantLegend echoSettings={echoSettings} />
      <UpcomingProjectsLegend echoSettings={echoSettings} />
      <OngoingProjectsLegend echoSettings={echoSettings} />
      <FinishedProjectsLegend echoSettings={echoSettings} />
      <CO2DepotsLegend echoSettings={echoSettings} />
      <OpportunitiesLegend echoSettings={echoSettings} />
    </Grid>
  )
}

export default MapLegend
