import PropTypes from 'prop-types'
import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import HighCharts from 'highcharts'
import ChartOptionHelpers from '../Data/ChartOptionHelpers'

/**
 *
 * @param {Object} props
 * @param {'psi'|'kpa' | 'C' | 'F'} props.unit
 * @param {String} props.title
 * @param {Number[][]} props.data
 * @returns
 */
function InjectionLineChartPresentational(props) {
  const chartOptions = ChartOptionHelpers.defaultChartOptions(props)

  return <HighchartsReact highcharts={HighCharts} options={chartOptions} />
}

InjectionLineChartPresentational.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  unit: PropTypes.oneOf(['psi', 'kpa', 'C', 'F']).isRequired,
  title: PropTypes.string,
}

InjectionLineChartPresentational.defaultProps = {
  name: '',
  title: '',
}

export default InjectionLineChartPresentational
