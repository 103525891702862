import { useCallback, useMemo, useState } from 'react'
import { ISonarDownSystem } from '../../../../Common/Logic/Types'
import { useRecoilValue } from 'recoil'
import { atomJWT, atomSonarFrontendFilter } from '../../../../Common/atoms'
import downSystemsTableHelper from './DownSystemsTableHelper'

export interface IUseDownSystemsTableProps {}

const useDownSystemsTable = () => {
  // State and Setters
  const [showSnoozeMenu, setShowSnoozeMenu] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showSnoozeModal, setShowSnoozeModal] = useState<boolean>(false)
  const [showSnoozeSuccessModal, setShowSnoozeSuccessModal] = useState<boolean>(
    false
  )
  const [
    clickedDownSystem,
    setClickedDownSystem,
  ] = useState<ISonarDownSystem | null>(null)
  const sonarFrontendFilters = useRecoilValue(atomSonarFrontendFilter)
  const JWT = useRecoilValue(atomJWT)

  const downSystemsTableHeadCells = useMemo(
    () =>
      downSystemsTableHelper.getDownsSystemsTableHeadCells(
        sonarFrontendFilters.isUTC?.[0]
      ),
    [sonarFrontendFilters.isUTC]
  )

  // Callbacks
  const handleActionButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, downSystem: ISonarDownSystem) => {
      setAnchorEl(event.currentTarget)
      setClickedDownSystem({ ...downSystem })
      setShowSnoozeMenu(true)
    },
    []
  )

  const handleSnoozeMenuClose = useCallback(() => {
    setClickedDownSystem(null)
    setAnchorEl(null)
    setShowSnoozeMenu(false)
  }, [])

  const handleSnoozeModalOpen = () => {
    setShowSnoozeModal(true)
    setAnchorEl(null)
    setShowSnoozeMenu(false)
  }

  // Effects

  return {
    JWT,
    showSnoozeMenu,
    anchorEl,
    handleActionButtonClick,
    handleSnoozeMenuClose,
    showSnoozeModal,
    handleSnoozeModalOpen,
    setShowSnoozeModal,
    showSnoozeSuccessModal,
    setShowSnoozeSuccessModal,
    clickedDownSystem,
    setClickedDownSystem,
    sonarFrontendFilters,
    downSystemsTableHeadCells,
  }
}

const hook = {
  useDownSystemsTable,
}

export default hook
