import { TextField, MenuItem } from '@material-ui/core'
import React, { useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { MixGroupConditions, AddDataSettings } from '../Logic/Types'

export interface ISearchConditionFieldForParams {
  conditionInternal: string
  setConditionInternal: (arg1: string) => void
  addDataSettings: AddDataSettings
  setAddDataSettings: Dispatch<SetStateAction<AddDataSettings>>
  isAssociationView: boolean
}

const SearchConditionFieldForParams = (
  props: ISearchConditionFieldForParams
) => {
  const {
    conditionInternal,
    setConditionInternal,
    addDataSettings,
    setAddDataSettings,
    isAssociationView,
  } = props

  const handleChange = (event: ChangeEvent<{}>, value: string) => {
    const element = event.target as HTMLInputElement
    // Update condition for input values
    setConditionInternal(element.value)
    // Update URL Params
    const newSettings = cloneDeep(addDataSettings)
    if (element.value !== 'all') {
      newSettings.isCO2Design[0] = element.value
      setAddDataSettings(newSettings)
    } else {
      newSettings.isCO2Design = []
      setAddDataSettings(newSettings)
    }
  }

  /** Checks for the existence of isCO2Design URL param and updates inputs */
  useEffect(() => {
    if (addDataSettings?.isCO2Design[0] === conditionInternal) return
    if (addDataSettings.isCO2Design.length > 0)
      setConditionInternal(addDataSettings?.isCO2Design[0])
  }, [addDataSettings, setConditionInternal, conditionInternal])

  /** Handles the resetting of condition input when customer is changed or cleared */
  useEffect(() => {
    const isDivisionSet = addDataSettings?.divisionId.length > 0
    const isCO2DesignSet = addDataSettings?.isCO2Design.length > 0
    if ((conditionInternal === 'all' || !isDivisionSet) && isCO2DesignSet) {
      const newSettings = cloneDeep(addDataSettings)
      newSettings.isCO2Design = []
      setAddDataSettings(newSettings)
      setConditionInternal('all')
    }
  }, [
    addDataSettings,
    setAddDataSettings,
    conditionInternal,
    setConditionInternal,
  ])

  return (
    <TextField
      select
      id="select"
      label="Condition"
      variant="outlined"
      size="small"
      value={conditionInternal}
      onChange={handleChange}
      defaultValue="all"
      disabled={isAssociationView || !addDataSettings.divisionId.length}
    >
      <MenuItem value="true">{MixGroupConditions.CO2}</MenuItem>
      <MenuItem value="false">{MixGroupConditions.NOCO2}</MenuItem>
      <MenuItem value="all">All</MenuItem>
    </TextField>
  )
}
export default SearchConditionFieldForParams
