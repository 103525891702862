import React from 'react'
import { Collapse, makeStyles } from '@material-ui/core'
import { CopyVariationTableType, MixGroupVariation } from '../../Logic/Types'
import ExpandablePrompt from '../../../Common/Components/ExpandablePrompt/ExpandablePrompt'
import CopyMixVariationTable from './CopyMixVariationTable'
import theme from '../../../theme/muiTheme'

const useStyles = makeStyles({
  ...theme.customClasses.expandablePrompt,
})

export interface ICopyMixVariationPresentational {
  /** Boolean indicating whether or not the View & Select Mix Variation interface is visible */
  isCopyMixVariationMenuOpen: boolean
  /** Function to determine whether or not the View & Select Mix Variation interface is visible */
  setIsCopyMixVariationMenuOpen: (arg1: boolean) => void
  /** Mix design object  */
  mixCode: number | string | undefined
  /** Function to fetch data and copy variation when confirm autofill */
  fetchAndCopyMixVariation: (event: React.FormEvent<HTMLFormElement>) => void
  /** Array of mix group variations for populating the table */
  associatedMixVariations: Array<MixGroupVariation>
  /** Function to handle selecting variation when the row is clicked */
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MixGroupVariation
  ) => void
  /** Selected Variation to be copied and autofilled */
  selectedVariation: MixGroupVariation | undefined | null
  /** Boolean indicating whether to show the copy mix variation prompt as editing a mix will not */
  addMixMode: boolean
  /** Boolean indicating whether to show the process icon and to disable buttons */
  isLoading: boolean
}

const CopyMixVariationPresentational = (
  props: ICopyMixVariationPresentational
) => {
  const {
    isCopyMixVariationMenuOpen,
    setIsCopyMixVariationMenuOpen,
    mixCode,
    fetchAndCopyMixVariation,
    handleClick,
    associatedMixVariations,
    selectedVariation,
    addMixMode,
    isLoading,
  } = props

  const classes = useStyles()

  return (
    <>
      <Collapse in={isCopyMixVariationMenuOpen && addMixMode}>
        <ExpandablePrompt
          promptType="info"
          setIsMenuOpen={setIsCopyMixVariationMenuOpen}
          headerText={`View & select mix variations from associated group ${mixCode}`}
          contentText={`Selecting a variation from Associated Mix Group ${mixCode} will auto-fill it’s information into your new mix group.`}
          expandButtonText="View Variations"
          expandedContent={
            <CopyMixVariationTable
              tableType={CopyVariationTableType.AddData}
              fetchAndCopyMixVariation={fetchAndCopyMixVariation}
              handleClick={handleClick}
              associatedMixVariations={associatedMixVariations}
              selectedVariation={selectedVariation}
              isLoading={isLoading}
            />
          }
          parentClasses={classes}
        />
      </Collapse>
    </>
  )
}

export default CopyMixVariationPresentational
