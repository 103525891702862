import React from 'react'
import {
  makeStyles,
  Dialog,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { baseColors } from '../../../../theme/colors'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minWidth: '30%',
    maxWidth: '100%',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.2px',
    color: baseColors.warning.hover,
    backgroundColor: baseColors.warning.background,
    margin: 0,
  },
  dialogHeader: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    backgroundColor: baseColors.warning.background,
    color: baseColors.warning.hover,
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'left',
    marginBottom: theme.spacing(-0.5),
    marginTop: theme.spacing(-0.5),
  },
  warningIcon: {
    color: baseColors.warning.hover,
    marginRight: theme.spacing(0.5),
    fontSize: '2rem',
    width: '28px',
    height: '28px',
    padding: '4px',
  },
  dialogContent: {
    padding: theme.spacing(2),
    color: baseColors.warning.hover,
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0.6px',
  },
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  goBackButton: {
    borderColor: baseColors.warning.dark,
    color: baseColors.warning.dark,
    textTransform: 'capitalize',
    marginRight: theme.spacing(-0.2),
  },
  resolveButton: {
    backgroundColor: baseColors.warning.dark,
    color: baseColors.secondary.contrast,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: baseColors.warning.hover,
    },
  },
}))

interface IComponentProps {
  open: boolean
  handleClose: () => void
  resolveAlarm: () => void
}

function ResolveAlarmDialogPresentational(props: IComponentProps) {
  const { open, handleClose, resolveAlarm } = props

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
    >
      <DialogTitle disableTypography className={classes.dialogHeader}>
        <div className={classes.titleContent}>
          <ReportProblemOutlinedIcon className={classes.warningIcon} />
          <Typography variant="h6" className={classes.dialogTitle}>
            Resolve Alarm
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          This action will permanently mark the selected alarm as resolved.{' '}
          <br />
          Do you wish to continue?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.goBackButton}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          onClick={resolveAlarm}
          className={classes.resolveButton}
        >
          Resolve Alarm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResolveAlarmDialogPresentational
