export enum EProjectTypes {
  upcoming = 'Upcoming Projects',
  ongoing = 'Ongoing Projects',
  finished = 'Finished Projects',
}

export enum EMapLayers {
  rmPlants = 'RM Plants',
  rmCompanies = 'RM Companies',
  precastPlants = 'Precast Plants',
  precastCompanies = 'Precast Companies',
  cementPlants = 'Cement Plants',
  co2Depots = 'CO₂ Depots',
  opportunities = 'Opportunities',
  projectSearch = 'Project Search',
}

export enum EOwnershipFilterOptions {
  privatelyOwned = 'privatelyOwned',
  verticallyIntegrated = 'verticallyIntegrated',
  customer = 'customer',
}
