import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

export interface IGeneralListProps {
  item: string
  color?: string
  listMargin: number
}

/** This component is meant to facilitate creating unordered lists that are consistent with UI/UX guidelines. */

const GeneralList = (props: IGeneralListProps) => {
  const { item, color, listMargin } = props

  const listStyle = {
    width: '100%',
  }
  const listItemStyle = {
    margin: `${listMargin}em 0`,
    color: color ?? '',
  }
  const listIconStyle = {
    minWidth: 'auto',
    paddingRight: '12px',
  }
  const recordIconStyle = {
    fontSize: '0.3rem',
  }

  return (
    <List disablePadding>
      <ListItem dense style={listStyle}>
        <ListItemIcon style={listIconStyle}>
          <FiberManualRecordIcon
            data-testid="record-icon"
            style={recordIconStyle}
            color={color ?? ''}
          />
        </ListItemIcon>
        <ListItemText style={listItemStyle}>{item}</ListItemText>
      </ListItem>
    </List>
  )
}

export default GeneralList
