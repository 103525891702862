import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import QuicksightDashboard from '../Components/QuicksightDashboard'
import {
  dashboardNameList,
  quicksightDashboardList,
} from '../Constants/UrlMapping'
import QuickSightDashboardExperimental from '../Components/QuicksightDashboardExperimental'

export default function ProductionDashboards(props) {
  const { currentDashboard, setCurrentDashboard, isExperimental } = props
  const isQuickSightEnabled =
    process.env.REACT_APP_IS_QUICKSIGHT_ENABLED === 'true'
  const history = useHistory()
  const [enablePrint, setEnablePrint] = useState(false)
  const [enableUndoRedo, setEnableUndoRedo] = useState(false)

  const changeCurrentDashboard = event => {
    setCurrentDashboard(event.target.value)
    history.push(`QuickSight/${event.target.value}`)
  }

  const renderQuickSightComponent = () => {
    if (isQuickSightEnabled) {
      return isExperimental ? (
        <QuickSightDashboardExperimental
          dashboard={quicksightDashboardList[currentDashboard]}
          enablePrint={enablePrint}
          enableUndoRedo={enableUndoRedo}
        />
      ) : (
        <QuicksightDashboard
          dashboard={quicksightDashboardList[currentDashboard]}
        />
      )
    } else {
      return (
        <Alert severity="warning">
          <AlertTitle>Warning: Ongoing Maintenance</AlertTitle>
          Dashboards are disabled during planned maintenance window. If urgent,
          please contact #info-devops.
        </Alert>
      )
    }
  }

  const handlePrintChange = event => {
    setEnablePrint(event.target.checked)
  }

  const handleUndoRedoChange = event => {
    setEnableUndoRedo(event.target.checked)
  }

  return (
    <Grid
      container
      direction="row"
      justifycontent="flex-start"
      alignItems="flex-start"
      spacing={2}
      style={{ marginTop: '1%' }}
    >
      {isExperimental && (
        <>
          <Grid item xs={12} style={{ textAlign: 'left', marginLeft: '1%' }}>
            <Typography variant="h2">Experimental Dashboard</Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'left', marginLeft: '1%' }}>
            <FormControl component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enablePrint}
                      onChange={handlePrintChange}
                    />
                  }
                  label="Enable Print / Export"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableUndoRedo}
                      onChange={handleUndoRedoChange}
                    />
                  }
                  label="Enable Undo/Redo"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </>
      )}

      <Grid item style={{ textAlign: 'left', marginLeft: '1%' }}>
        <Typography> Select Dashboard </Typography>
        <Select
          id="dashboard-list"
          value={currentDashboard}
          defaultValue=""
          label="Select Dashboard"
          onChange={changeCurrentDashboard}
        >
          {dashboardNameList.map(dashboard => (
            <MenuItem
              value={dashboard}
              key={quicksightDashboardList[dashboard].dashboardId}
            >
              {quicksightDashboardList[dashboard].title}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12}>
        {renderQuickSightComponent()}
      </Grid>
    </Grid>
  )
}

ProductionDashboards.propTypes = {
  currentDashboard: PropTypes.string.isRequired,
  setCurrentDashboard: PropTypes.func.isRequired,
  isExperimental: PropTypes.bool,
}
