import React, { useEffect, useState } from 'react'
import { Slider } from '@material-ui/core'
import PropTypes from 'prop-types'

const propertyStepValues = {
  concreteTemperature: 1,
  ambientTemperature: 1,
  batchWaterCementRatio: 0.03,
  co2Dosage: 0.01,
  batchCementContent: 1,
  batchStrength: 500,
  slump: 0.1,
  air: 0.1,
  dataSetSize: 1,
}

export default function GraphFilterSliderSelector(props) {
  GraphFilterSliderSelector.propTypes = {
    /** Function updates the currently selected range of a filter. */
    updateFilterSelectedRange: PropTypes.func.isRequired,
    /** GraphFilter object containing the property, total range, and currently selected range.*/
    graphFilter: PropTypes.object.isRequired,
  }

  const { updateFilterSelectedRange, graphFilter } = props
  const stepSize = propertyStepValues[graphFilter.property]

  const [sliderRangeInternal, setSliderRangeInternal] = useState([])

  useEffect(() => {
    setSliderRangeInternal([
      graphFilter.selectedMinValue,
      graphFilter.selectedMaxValue,
    ])
  }, [graphFilter.selectedMinValue, graphFilter.selectedMaxValue])

  return (
    <Slider
      min={graphFilter.rangeMinValue}
      max={graphFilter.rangeMaxValue}
      step={stepSize}
      valueLabelDisplay="on"
      onChange={(_, newValue) => setSliderRangeInternal(newValue)}
      onChangeCommitted={(_, newValue) => {
        updateFilterSelectedRange(graphFilter, newValue[0], newValue[1])
      }}
      value={sliderRangeInternal}
      color="secondary"
    />
  )
}
