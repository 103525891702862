import {
  addBatchMaterials,
  addBatchProperties,
  addBatchRequiredCols,
  batchMaterialUnits,
  editBatchProperties,
  editBatchRequiredCols,
  spreadsheetColumnWidth,
} from '../Constants/AddDataConstants'
import { MaterialObject } from './Types'

interface MaterialColumn {
  title: string
  name: string
  type: 'text' | 'dropdown'
  columnNumber: number
  width: string
  source?: Array<string>
}

/** Contains all logic used for creating and managing spreadsheet column objects.
 *  Some of these methods are used only in the AddBatchColumnSelectLogic.js, however,
 *  other methods are also used in UploadBatchData.js and AddBatchView.js to create
 *  new column objects as needed.
 */

export const createStrengthIntervals = () => {
  const dayIntervals = [1, 2, 3, 5, 6, 7, 14, 21, 28, 56, 60, 180, 365]
  const days = dayIntervals.map(interval => ({
    title: `${interval} Day Strength`,
    name: `${interval}dayStrength1`,
    intervalDuration: interval,
    intervalType: 'Day Intervals',
    columnNumber: 1,
    type: 'numeric',
    width: spreadsheetColumnWidth.large,
  }))

  const hours = []
  for (let i = 1; i < 24; i++) {
    hours.push({
      title: `${i} Hour Strength`,
      name: `${i}hourStrength1`,
      intervalDuration: i,
      intervalType: 'Hour Intervals',
      columnNumber: 1,
      type: 'numeric',
      width: spreadsheetColumnWidth.large,
    })
  }
  return [...days, ...hours]
}

export const getUnitsToAdd = (material, unitsSystem) =>
  batchMaterialUnits[unitsSystem].filter(unit =>
    unit.appliesTo.includes(material.materialName)
  )

/** Create metric and imperial column variations for all materials and batch props. */
export const addUnitsToMaterials = () => {
  const materialsWithUnits = {}
  const unitSystems = Object.keys(batchMaterialUnits)

  unitSystems.forEach(system => {
    materialsWithUnits[system] = []
    addBatchMaterials.forEach(material => {
      const unitsToAdd = getUnitsToAdd(material, system)
      unitsToAdd.forEach(unit =>
        materialsWithUnits[system].push({
          ...material,
          ...unit,
          columnNumber: 1,
          title: `${material.materialTitle} ${unit.unitTitle}`,
          name: `${material.materialName}${unit.unitName}1`,
        })
      )
    })
  })
  return materialsWithUnits
}

const getBatchPropObject = (batchProp, isMetric: boolean) => {
  if (isMetric) {
    switch (batchProp.name) {
      case 'loadSize':
        return { ...batchProp, title: `${batchProp.title} (m\u00B3)` }
      case 'unitWeight':
        return { ...batchProp, title: `${batchProp.title} (kg / m\u00B3)` }
      case 'concreteTemp':
      case 'ambientTemp':
        return { ...batchProp, title: `${batchProp.title} (C)` }
      case 'slump':
        return { ...batchProp, title: `${batchProp.title} (mm)` }
      case 'CO2DoseAbsolute':
        return { ...batchProp, title: `${batchProp.title} (mL)` }
      default:
        return batchProp
    }
  } else {
    switch (batchProp.name) {
      case 'loadSize':
        return { ...batchProp, title: `${batchProp.title} (yd\u00B3)` }
      case 'unitWeight':
        return { ...batchProp, title: `${batchProp.title} (lb / ft\u00B3)` }
      case 'concreteTemp':
      case 'ambientTemp':
        return { ...batchProp, title: `${batchProp.title} (F)` }
      case 'slump':
        return { ...batchProp, title: `${batchProp.title} (In)` }
      case 'CO2DoseAbsolute':
        return { ...batchProp, title: `${batchProp.title} (oz)` }
      default:
        return batchProp
    }
  }
}

/** Add units to batch properties. */
export const addUnitsToBatchPropsAddMode = () => ({
  Metric: addBatchProperties.map(batchProp => {
    return getBatchPropObject(batchProp, true)
  }),
  Imperial: addBatchProperties.map(batchProp => {
    return getBatchPropObject(batchProp, false)
  }),
})

/** Add units to batch properties. */
export const addUnitsToBatchPropsEditMode = () => ({
  Metric: editBatchProperties.map(batchProp => {
    return getBatchPropObject(batchProp, true)
  }),
  Imperial: editBatchProperties.map(batchProp => {
    return getBatchPropObject(batchProp, false)
  }),
})

/** Each materials column is inserted with additional columns not present in dropdown.
 * For example: "Fine Agg (kg)" adds columns "Fine Agg (kg)", "Fine Agg Name", "Fine Agg Supplier"
 */
export const isCementSupplierColumn = (materialName, columnName) =>
  materialName === 'Cement' && columnName === 'Supplier'

export const getAllMatCols = (materials, cementPlants, unitsSystem) => {
  if (unitsSystem === undefined) unitsSystem = 'Imperial'
  let materialCols: MaterialColumn[] = []

  if (materials && materials.length > 0) {
    materials.forEach(mat => {
      materialCols.push(mat)
      // Set title string and width of column based on column number.
      const [colTitleString, width] = getMaterialTitleAndWidth(mat)

      // Add unit column to all materials.
      const colsToAdd = ['Unit']
      colsToAdd.forEach(colName => {
        const newCol: MaterialColumn = {
          title: `${colName} ${colTitleString}`,
          name: colTitleString + colName,
          type: 'dropdown',
          columnNumber: mat.columnNumber,
          width,
        }
        newCol.source = batchMaterialUnits[unitsSystem].map(
          unit => unit.unitTitle
        )
        materialCols.push(newCol)
      })
    })
  }
  return materialCols
}

/** Extracts the column title and width for a MaterialColumn object.
 *  Columns that have a column number greater than one return larger widths since
 *  the heading includes the column number.
 * @param {Object} materialColumn - material column to extract details from.
 */
export const getMaterialTitleAndWidth = materialColumn => {
  if (materialColumn.columnNumber && materialColumn.columnNumber > 1) {
    const materialTitle = `${materialColumn.materialTitle ||
      materialColumn.title} (${materialColumn.columnNumber})`
    return [materialTitle, spreadsheetColumnWidth.xl]
  }
  return [
    materialColumn.materialTitle || materialColumn.title,
    spreadsheetColumnWidth.large,
  ]
}

export const createNewColumnObject = (currSelection: MaterialColumn) => {
  // Last char of name is the column number.
  const currColNumber = currSelection.columnNumber
  const newColNumber = currColNumber + 1

  // Subsequent test include a column number in the title.
  const newTitle =
    currColNumber > 1
      ? currSelection.title.replace(`(${currColNumber})`, `(${newColNumber})`)
      : `${currSelection.title} (${newColNumber})`
  return {
    ...currSelection,
    columnNumber: newColNumber,
    title: newTitle,
    name: currSelection.name.replace(/.$/, String(newColNumber)),
  }
}

/** Function for adding multiples of a material type. */
export const addNewSpreadsheetColumn = (
  currSelection,
  setAllColumns,
  columnKey
) => {
  // Create next column object to be selected from dropdown.
  const newCol = createNewColumnObject(currSelection)

  // With Material Manager no longer need to make duplicates for selected options
  if (columnKey !== 'materials') {
    setAllColumns(prevItems => {
      const updateCol = prevItems[columnKey]
      if (!updateCol.find(col => col.name === newCol.name)) {
        return { ...prevItems, [columnKey]: [...updateCol, newCol] }
      } else return prevItems
    })
  }

  // Also return the column, used in upload component.
  return newCol
}

/** Since multiple same-day tests are dynamically added, they need to be individually
 * extracted from the upload and dynamically added to the full list of strength reading columns.
 */
export const extractColumnGroup = (
  uploadedCols,
  listOfColumnsInGroup,
  setStrengthAndMaterialCols,
  columnKey
) => {
  const matchedColumns = []
  const newColumns = []
  uploadedCols.forEach(strengthCol => {
    // Get initial test from list of known columns.
    const firstTestCol = listOfColumnsInGroup.find(
      possibleCol => possibleCol.title === strengthCol
    )

    // Get subsequent tests as they are being added.
    const subsequentTestCol = newColumns.find(
      possibleCol => possibleCol.title === strengthCol
    )

    // If there is a match on the first test, add to list and create option for second test.
    if (firstTestCol) {
      matchedColumns.push(firstTestCol)
      newColumns.push(
        addNewSpreadsheetColumn(
          firstTestCol,
          setStrengthAndMaterialCols,
          columnKey
        )
      )
    }
    // Subsequent tests will now have entries in newTestStrengthCols
    else if (subsequentTestCol) {
      matchedColumns.push(subsequentTestCol)
      newColumns.push(
        addNewSpreadsheetColumn(
          subsequentTestCol,
          setStrengthAndMaterialCols,
          columnKey
        )
      )
    }
  })

  return matchedColumns
}

export const tryingToRemoveRequiredColumn = (
  action,
  optionName,
  isEditMode: boolean
) => {
  if (isEditMode) {
    return action === 'remove-option' && optionName in editBatchRequiredCols
  }
  return action === 'remove-option' && optionName in addBatchRequiredCols
}

export const canSetPlantDropdownOptions = (action, optionName, numPlants) =>
  optionName === 'plant' && action === 'select-option' && numPlants > 0

export const setPlantDropdownOptions = (plants, setSelectedCols) => {
  const plantColumnObject = {
    title: 'Plant',
    name: 'plant',
    type: 'dropdown',
    source: plants.map(plant => plant.name),
  }
  setSelectedCols(prevSelection => {
    const newProperties = prevSelection.properties.filter(
      prop => prop.name !== 'plant'
    )

    // The first 6 columns are required and cannot be removed.
    // Therefore the updated plants column can safely be inserted back into its position.
    newProperties.splice(3, 0, plantColumnObject)
    return { ...prevSelection, properties: newProperties }
  })
}

//Dropdown will only show default cement / defaut CO2 and non-ingested materials
export const getMaterialOptions = (materials: Array<MaterialObject>) => {
  return materials
    .filter(
      material =>
        (material.isIngested === false &&
          material.isDefaultCement === false &&
          material.isDefaultCO2 === false) ||
        material.isDefaultCement === true ||
        material.isDefaultCO2 === true
    )
    .sort(
      (a, b) =>
        -b.primaryMaterialType.localeCompare(a.primaryMaterialType) ||
        a.materialId - b.materialId
    )
}
