import React, { useState, useEffect } from 'react'
import {
  CopyVariationTableType,
  DigestedMixDesign,
  MixGroupVariation,
} from '../../Logic/Types'
import ExpandablePrompt from '../../../Common/Components/ExpandablePrompt/ExpandablePrompt'
import { Collapse, makeStyles } from '@material-ui/core'
import theme from '../../../theme/muiTheme'
import CopyMixVariationTable from '../CopyMixVariation/CopyMixVariationTable'

export interface ISelectOptimizationPromptProps {
  /** Function to determine whether or not the optimization interface is visible */
  setIsOptimizationMenuOpen: (arg1: boolean) => void
  /** Associated mix object for fetching variations*/
  mixDesignsForOptimization: Array<DigestedMixDesign>
  /** Whether the unit system is metric or not */
  isMetric: boolean
}

const useStyles = makeStyles({
  ...theme.customClasses.expandablePrompt,
  container: {
    ...theme.customClasses.expandablePrompt.container,
    margin: '16px 0px 0px 0px',
  },
})

const SelectOptimizationPrompt = (props: ISelectOptimizationPromptProps) => {
  const {
    setIsOptimizationMenuOpen,
    mixDesignsForOptimization,
    isMetric,
  } = props
  const [isMenuOpen, setIsMenuOpen] = useState<Record<string, boolean>>({})
  const [selectedVariation, setSelectedVariation] = useState<
    MixGroupVariation | null | undefined
  >()
  const classes = useStyles()

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MixGroupVariation
  ) => {
    if (selectedVariation === row) {
      setSelectedVariation(null)
    } else {
      setSelectedVariation(row)
    }
  }

  const CopyMixVariationFromAnalysis = (mixDesignId: number | string) => {
    setIsMenuOpen(prevState => ({
      ...prevState,
      [mixDesignId]: false,
    }))

    //store in sessionStorage since recoil cannot carry over new tab
    const jsonOptimizedVariation = JSON.stringify(selectedVariation)
    sessionStorage.setItem(
      'mixVariationForOptimization',
      jsonOptimizedVariation
    )

    //open add mix page on new tab
    const targetUrl = `${window.location.origin}/Concrete/AddData/AddMixDesign`
    const newTab = window.open(targetUrl, '_blank')
    newTab?.focus()
  }

  //check the menu status for multiple optimization alerts
  useEffect(() => {
    if (!mixDesignsForOptimization.length) return
    const checkMenuStatus = () => {
      for (const key in isMenuOpen) {
        if (isMenuOpen[key]) {
          return true
        }
      }
      return false
    }
    //close the optimization session if all alerts are closed
    setIsOptimizationMenuOpen(checkMenuStatus())
  }, [isMenuOpen, setIsOptimizationMenuOpen, mixDesignsForOptimization])

  return (
    <>
      {mixDesignsForOptimization.map(mixDesign => {
        const mixDesignId = mixDesign.mixDesignId
        if (isMenuOpen[mixDesignId] === undefined) {
          setIsMenuOpen(prevState => ({
            ...prevState,
            [mixDesignId]: true,
          }))
        }
        const isOpen = isMenuOpen[mixDesignId]

        return (
          <React.Fragment key={mixDesignId}>
            <Collapse in={isOpen}>
              <ExpandablePrompt
                promptType="info"
                setIsMenuOpen={() =>
                  setIsMenuOpen(prevState => ({
                    ...prevState,
                    [mixDesignId]: false,
                  }))
                }
                headerText={`Select an optimized mix variation for ${mixDesign.producer} - ${mixDesign.mixCode}`}
                contentText={`Select an optimized mix for ${mixDesign.producer} - ${mixDesign.mixCode} to advance to an Active CO2 mix group or create a new mix group.`}
                expandButtonText="View Variations"
                expandedContent={
                  <CopyMixVariationTable
                    tableType={CopyVariationTableType.Analysis}
                    handleClick={handleClick}
                    CopyMixVariationFromAnalysis={CopyMixVariationFromAnalysis}
                    associatedMixVariations={mixDesign.variations}
                    selectedVariation={selectedVariation}
                    setIsMenuOpen={setIsMenuOpen}
                    mixDesignId={mixDesignId}
                    isMetric={isMetric}
                  />
                }
                parentClasses={classes}
              />
            </Collapse>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default SelectOptimizationPrompt
