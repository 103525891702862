import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { putRemoteUnitConfiguration } from '../Data/PCBDataHelpers'
import useFeedback from '../Hooks/useFeedback'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItemFullWidth: {
    width: '100%',
  },
}))

EditRemoteModal.propTypes = {
  /** The remote unit you wish to edit */
  remoteUnit: PropTypes.object.isRequired,
  /** Whether the modal is opened or not */
  isOpen: PropTypes.bool.isRequired,
  /** Function to fire when closing the */
  handleClose: PropTypes.func.isRequired,
}

const injectionModeValueOptions = [
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'DualCC',
    label: 'Dual CC',
  },
  {
    value: 'DualLinde',
    label: 'Dual Linde',
  },
  {
    value: 'Masonry',
    label: 'Masonry',
  },
]

const dischargeHoseLengthValueOptions = [
  {
    value: 'Unknown',
    label: 'Unknown',
  },
  {
    value: 'Length40Ft',
    label: '40 Ft',
  },
  {
    value: 'Length60Ft',
    label: '60 Ft',
  },
  {
    value: 'Length80Ft',
    label: '80 Ft',
  },
]

const controlModeValueOptions = [
  {
    value: 'Unknown',
    label: 'Unknown',
  },
  {
    value: 'Direct',
    label: 'Direct',
  },
  {
    value: 'Bottle',
    label: 'Bottle',
  },
]

const controlModeValues = controlModeValueOptions.map(option => option.value)
const dischargeHoseLengthValues = dischargeHoseLengthValueOptions.map(
  option => option.value
)
const injectionModeValues = injectionModeValueOptions.map(
  option => option.value
)

function EditRemoteModal(props) {
  const { isOpen, remoteUnit, handleClose } = props
  const [feedbackIcon, setFeedbackIcon] = useFeedback('nothing')
  const remoteUnitSchema = yup.object().shape({
    kgCO2PerPulse: yup
      .number()
      .required()
      .min(0, 'The Kg CO2/Pulse must be more than 0')
      .max(0.5, 'The Kg CO2/Pulse must be less than 0.5')
      .typeError('The Kg CO2/Pulse must be a digit (e.g. 1, 2, 3, 4.5)'),
    orificeDiameter: yup
      .number()
      .required()
      .positive('The orifice diameter must be above 0')
      .typeError('The orifice diameter must be a digit (e.g. 1, 2, 3, 4.5)'),
    gasValveOverlapValue: yup
      .number()
      .required()
      .positive('The gas valve overlap value must be above 0')
      .integer('The gas valve overlap value must be a whole number')
      .typeError('The gas valve overlap must be a digit (e.g. 1, 2, 3)'),
    dischargeHoseLength: yup
      .string()
      .required()
      .oneOf(
        dischargeHoseLengthValues,
        'The discharge hose length must be one of the selected options'
      )
      .typeError(
        'The discharge hose length must be one of the selected options'
      ),
    ghostFlowRate: yup
      .number()
      .required()
      .positive('The ghost flow rate must be above 0')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    deltaPressureAlarmTrigger: yup
      .number()
      .required('You must specify a Delta Pressure Alarm Trigger')
      .min(0, 'The trigger cannot be lower than 0')
      .max(100, 'The trigger cannot be over 100')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    minUpstreamPressureAlarmTrigger: yup
      .number()
      .required()
      .min(75, 'The trigger cannot be less than 75')
      .max(200, 'The trigger cannot be more than 200')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    minDownstreamPressureAlarmTrigger: yup
      .number()
      .required()
      .min(75, 'The trigger cannot be less than 75')
      .max(200, 'The trigger cannot be more than 200')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    maxDownstreamPressureAlarmTrigger: yup
      .number()
      .required()
      .min(100, 'The trigger cannot be lower than 100')
      .max(300, 'The trigger cannot be higher than 300')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    minInletLiquidPressureAlarmTrigger: yup
      .number()
      .required()
      .min(100, 'The trigger cannot be lower than 100')
      .max(200, 'The trigger cannot be higher than 200')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    minInletGasPressureAlarmTrigger: yup
      .number()
      .min(75, 'The trigger cannot be lower than 75')
      .max(150, 'The trigger cannot be higher than 150')
      .typeError('The trigger must be a digit (e.g. 1, 2, 3, 4.5)'),
    minInjectionsInterval: yup
      .number()
      .required()
      .typeError(
        'The minimum injections interval must be a digit (e.g. 1, 2, 3)'
      )
      .integer('The minimum injections interval must be a whole number')
      .positive('The minimum injections interval must be above 0'),
    timeAfterInjection: yup
      .number()
      .required()
      .max(120, 'The time cannot be more than 120 seconds')
      .integer('The time must be a whole number')
      .min(0, 'The time cannot be below 0 seconds')
      .typeError('The time must be a digit (e.g. 1, 2, 3)'),
    controlMode: yup
      .string()
      .required()
      .oneOf(
        controlModeValues,
        'The control mode must be one of the options available'
      )
      .typeError('The control mode must be one of the options available'),
    injectionMode: yup
      .string()
      .required()
      .oneOf(
        injectionModeValues,
        'The injection mode must be one of the options available'
      )
      .typeError('The injection mode must be one of the options available'),
  })
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(remoteUnitSchema),
    defaultValues: { ...remoteUnit.configuration },
  })
  const classes = useStyles()

  useEffect(() => {
    register({ name: 'controlMode' })
    register({ name: 'dischargeHoseLength' })
    register({ name: 'injectionMode' })
  }, [register])

  const handleSave = data => {
    setFeedbackIcon('loading')

    const newConfiguration = {
      orificeDiameter: data.orificeDiameter,
      gasValveOverlapValue: data.gasValveOverlapValue,
      kgCO2PerPulse: data.kgCO2PerPulse,
      dischargeHoseLength: data.dischargeHoseLength,
      ghostFlowRate: data.ghostFlowRate,
      deltaPressureAlarmTrigger: data.deltaPressureAlarmTrigger,
      minUpstreamPressureAlarmTrigger: data.minUpstreamPressureAlarmTrigger,
      minDownstreamPressureAlarmTrigger: data.minDownstreamPressureAlarmTrigger,
      maxDownstreamPressureAlarmTrigger: data.maxDownstreamPressureAlarmTrigger,
      minInletLiquidPressureAlarmTrigger:
        data.minInletLiquidPressureAlarmTrigger,
      minInletGasPressureAlarmTrigger: data.minInletGasPressureAlarmTrigger,
      minInjectionsInterval: data.minInjectionsInterval,
      timeAfterInjection: data.timeAfterInjection,
      controlMode: data.controlMode,
      injectionMode: data.injectionMode,
    }
    putRemoteUnitConfiguration(remoteUnit.remoteUnitId, newConfiguration).then(
      response => {
        if (response.ok) {
          setFeedbackIcon('success')
        } else {
          setFeedbackIcon('error')
        }
      }
    )
  }

  const handleCloseInternal = () => {
    setFeedbackIcon('nothing')
    handleClose()
  }

  return (
    <Dialog
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item>
              <Typography>
                Edit Unit - Remote Unit {remoteUnit.localId}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="text"
                onClick={handleCloseInternal}
              >
                X
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Orifice Diameter"
                defaultValue={remoteUnit.configuration.orificeDiameter}
                name="orificeDiameter"
                error={errors.orificeDiameter ? true : false}
                helperText={errors.orificeDiameter?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Max Downstream Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.maxDownstreamPressureAlarmTrigger
                }
                name="maxDownstreamPressureAlarmTrigger"
                error={errors.maxDownstreamPressureAlarmTrigger ? true : false}
                helperText={errors.maxDownstreamPressureAlarmTrigger?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Gas Valve Overlap"
                defaultValue={remoteUnit.configuration.gasValveOverlapValue}
                name="gasValveOverlapValue"
                error={errors.gasValveOverlapValue ? true : false}
                helperText={errors.gasValveOverlapValue?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Min Inlet Liquid Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.minInletLiquidPressureAlarmTrigger
                }
                name="minInletLiquidPressureAlarmTrigger"
                error={errors.minInletLiquidPressureAlarmTrigger ? true : false}
                helperText={errors.minInletLiquidPressureAlarmTrigger?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Kg CO2 Per Pulse"
                defaultValue={remoteUnit.configuration.kgCO2PerPulse}
                name="kgCO2PerPulse"
                error={errors.kgCO2PerPulse ? true : false}
                helperText={errors.kgCO2PerPulse?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Min Inlet Gas Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.minInletGasPressureAlarmTrigger
                }
                name="minInletGasPressureAlarmTrigger"
                error={errors.minInletGasPressureAlarmTrigger ? true : false}
                helperText={errors.minInletGasPressureAlarmTrigger?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Discharge Hose Length"
                defaultValue={remoteUnit.configuration.dischargeHoseLength}
                name="dischargeHoseLength"
                error={errors.dischargeHoseLength ? true : false}
                helperText={errors.dischargeHoseLength?.message}
                select
                onChange={e => setValue('dischargeHoseLength', e.target.value)}
              >
                {dischargeHoseLengthValueOptions.map(dischargeHoseLength => (
                  <MenuItem
                    key={dischargeHoseLength.value}
                    value={dischargeHoseLength.value}
                  >
                    {dischargeHoseLength.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Min Injections Interval"
                defaultValue={remoteUnit.configuration.minInjectionsInterval}
                name="minInjectionsInterval"
                error={errors.minInjectionsInterval ? true : false}
                helperText={errors.minInjectionsInterval?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Ghost Flow Rate"
                defaultValue={remoteUnit.configuration.ghostFlowRate}
                name="ghostFlowRate"
                error={errors.ghostFlowRate ? true : false}
                helperText={errors.ghostFlowRate?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Time After Injection"
                defaultValue={remoteUnit.configuration.timeAfterInjection}
                name="timeAfterInjection"
                error={errors.timeAfterInjection ? true : false}
                helperText={errors.timeAfterInjection?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Delta Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.deltaPressureAlarmTrigger
                }
                name="deltaPressureAlarmTrigger"
                error={errors.deltaPressureAlarmTrigger ? true : false}
                helperText={errors.deltaPressureAlarmTrigger?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Control Mode"
                defaultValue={remoteUnit.configuration.controlMode}
                name="controlMode"
                error={errors.controlMode ? true : false}
                helperText={errors.controlMode?.message}
                select
                onChange={e => setValue('controlMode', e.target.value)}
              >
                {controlModeValueOptions.map(controlMode => (
                  <MenuItem key={controlMode.value} value={controlMode.value}>
                    {controlMode.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Min Downstream Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.minDownstreamPressureAlarmTrigger
                }
                name="minDownstreamPressureAlarmTrigger"
                error={errors.minDownstreamPressureAlarmTrigger ? true : false}
                helperText={errors.minDownstreamPressureAlarmTrigger?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Injection Mode"
                defaultValue={remoteUnit.configuration.injectionMode}
                name="injectionMode"
                error={errors.injectionMode ? true : false}
                helperText={errors.injectionMode?.message}
                select
                onChange={e => setValue('injectionMode', e.target.value)}
              >
                {injectionModeValueOptions.map(injectionMode => (
                  <MenuItem
                    key={injectionMode.value}
                    value={injectionMode.value}
                  >
                    {injectionMode.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register}
                size="small"
                variant="outlined"
                className={classes.gridItemFullWidth}
                label="Min Upstream Pressure Alarm Trigger"
                defaultValue={
                  remoteUnit.configuration.minUpstreamPressureAlarmTrigger
                }
                name="minUpstreamPressureAlarmTrigger"
                error={errors.minUpstreamPressureAlarmTrigger ? true : false}
                helperText={errors.minUpstreamPressureAlarmTrigger?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {feedbackIcon}
          <Button color="primary" variant="outlined" type="submit">
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditRemoteModal
