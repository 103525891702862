import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Drawer,
  makeStyles,
} from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

NavDrawer.propTypes = {
  /** Array of project URLs each in it's own category  */
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          path: PropTypes.string,
          subPaths: PropTypes.array,
        })
      ),
      featuresOfInterest: PropTypes.array,
    })
  ).isRequired,
}

/**
 * Nav drawer to collapse main nav bar on smaller screens.
 * @param {Object} navLinks - list of page names and URLs to populate drawer.
 */
function NavDrawer(props) {
  const { navLinks } = props
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    )
      return
    setIsOpen(open)
  }

  return (
    <>
      <IconButton
        edge="start"
        onClick={toggleDrawer(true)}
        style={{ zIndex: 50 }}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <List component="nav">
          {navLinks.map(({ department, projects }) => (
            <React.Fragment key={`${department}_top_level`}>
              <ListItem key={department}>
                <ListItemText
                  key={`${department}_text`}
                  primary={
                    <Typography variant="body1">{department}</Typography>
                  }
                />
              </ListItem>
              <List disablePadding key={`${department}_sub`}>
                {projects
                  .flatMap(project =>
                    department === 'Concrete' ||
                    department === 'Hardware' ||
                    department === 'QuickSight' ||
                    department === 'Producers'
                      ? project.subPaths
                      : [project]
                  )
                  .map(({ title: projectTitle, path }) => (
                    <ListItem
                      button
                      key={path}
                      component={Link}
                      to={path}
                      className={classes.nested}
                    >
                      <ListItemText
                        key={projectTitle}
                        primary={
                          <Typography variant="body1">
                            {projectTitle}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </>
  )
}
export default NavDrawer
