/** This file is where Chart Configurations are stored for Injection Graphs */

function defaultChartOptions(data) {
  const chartOptions = {
    yAxis: {
      labels: {
        enabled: true,
      },
      title: {
        text: `${data.unit}`,
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: true,
        format: '{value:%Y-%m-%d %H:%M}',
        rotation: -90,
      },
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: {
      text: `${data.title}`,
      align: 'center',
      verticalAlign: 'top',
      margin: 20,
    },
    tooltip: {
      pointFormat:
        '<b>{point.y:.2f} {series.options.custom.unit}</b><br />{point.x:%b %e, %Y at %l:%M %p}',
    },
    series: [
      {
        type: 'scatter',
        name: `${data.title}`,
        data: data.data,
        color: '#e5892e',
        custom: {
          unit: `${data.unit}`,
        },
      },
    ],
    legend: {
      enabled: true,
      align: 'center',
      layout: 'horizontal',
    },
  }

  if (data.unit === 'C') {
    chartOptions.yAxis.title.text = 'Degrees Celsius'
  } else if (data.unit === 'F') {
    chartOptions.yAxis.title.text = 'Degrees Fahrenheit'
  } else if (data.unit === 'kg') {
    chartOptions.yAxis.title.text = 'kilograms'
  } else if (data.unit === 'lb') {
    chartOptions.yAxis.title.text = 'pounds'
  } else {
    chartOptions.yAxis.title.text = `${data.unit}`
  }

  return chartOptions
}

const ChartOptionHelpers = {
  defaultChartOptions,
}

export default ChartOptionHelpers
