import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useFeedback from '../Hooks/useFeedback'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { postMasterUnit, postRemoteUnit } from '../Data/PCBDataHelpers'

AddMasterUnitModal.propTypes = {
  /** Boolean to tell you whether the modal is open or not */
  isOpen: PropTypes.bool.isRequired,
  /** Function to be fired when the modal is closed */
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

function AddMasterUnitModal(props) {
  const { isOpen, handleClose, refresh } = props
  const [feedbackIcon, setFeedbackIcon] = useFeedback('nothing')
  const masterUnitScheme = yup.object().shape({
    serialNo: yup
      .number()
      .min(1, `Hardware ID must be >= 1`)
      .required('You must set a hardware ID')
      .integer('Hardware ID must be a whole number')
      .typeError('You must specify a number as a digit (e.g. 1, 2, 3)'),
    numRemoteUnits: yup
      .number()
      .min(1, 'There must be at least 1 remote unit')
      .max(4, "You can't have more than 4 remote units")
      .required('There must be at least 1 remote unit')
      .integer('The number of remotes must be a whole number')
      .typeError('You must specify a number as a digit (e.g. 1, 2, 3)'),
  })
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(masterUnitScheme),
  })

  const handleCloseInternal = () => {
    handleClose()
    setFeedbackIcon('nothing')
  }

  const defaultRemoteConfiguration = {
    deltaPressureAlarmTrigger: 20,
    minUpstreamPressureAlarmTrigger: 150,
    minDownstreamPressureAlarmTrigger: 10,
    maxDownstreamPressureAlarmTrigger: 100,
    minInletLiquidPressureAlarmTrigger: 125,
    minInletGasPressureAlarmTrigger: 100,
    controlMode: 1,
    injectionMode: 1,
    orificeDiameter: 1,
    gasValveOverlapValue: 1,
    dischargeHoseLength: 1,
  }

  const handleSave = async data => {
    setFeedbackIcon('loading')
    const dataToSend = {
      hardwareId: data.serialNo,
      authorized: true,
    }
    const response = await postMasterUnit(dataToSend).catch(() => {
      setFeedbackIcon('error')
    })

    if (response && response.ok) {
      //add remote units
      const addedMasterUnit = await response.json()
      for (let i = 1; i <= data.numRemoteUnits; i++) {
        const remoteUnitData = {
          masterUnitId: addedMasterUnit.masterUnitId,
          localId: i,
          configuration: { ...defaultRemoteConfiguration },
        }
        const addedRemoteUnitResponse = await postRemoteUnit(remoteUnitData)
        if (!addedRemoteUnitResponse.ok) {
          setFeedbackIcon('error')
          return
        }
      }
      refresh()
      setFeedbackIcon('success')
    } else {
      setFeedbackIcon('error')
      try {
        console.log(response.status)
      } catch (e) {
        setFeedbackIcon('error')
        console.error(e)
      }
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleCloseInternal} closeAfterTransition>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle disableTypography>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item>
              <Typography>Add Master Unit</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="text"
                onClick={handleCloseInternal}
              >
                X
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                label="Serial No."
                size="small"
                inputRef={register}
                name="serialNo"
                error={errors.serialNo ? true : false}
                helperText={errors.serialNo?.message}
                defaultValue={1}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Remote Units"
                size="small"
                type="number"
                name="numRemoteUnits"
                inputRef={register}
                defaultValue={1}
                error={errors.numRemoteUnits ? true : false}
                helperText={errors.numRemoteUnits?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {feedbackIcon}
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            id="add_master_unit_submit"
          >
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddMasterUnitModal
