import React from 'react'
import { Grid, Button, Tooltip } from '@material-ui/core'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import { useRecoilValue } from 'recoil'
import { isProjectSearchActive } from '../../Logic/FilterLogicHelpers'

interface IComponentProps {
  submitProjectSearch: () => void
}

const SubmitProjectSearch = (props: IComponentProps) => {
  const { submitProjectSearch } = props

  /** Echo settings Atom */
  const echoSettings = useRecoilValue(atomEchoSettings)
  /** Project search values atom */
  const projectSearchValues = useRecoilValue(atomProjectSearchValues)

  if (
    isProjectSearchActive(echoSettings) &&
    projectSearchValues.projects.length > 0
  ) {
    return (
      <Grid item>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitProjectSearch}
          id="submitProjectSearch"
        >
          Submit Search
        </Button>
      </Grid>
    )
  } else if (
    isProjectSearchActive(echoSettings) &&
    projectSearchValues.projects.length === 0
  ) {
    return (
      <Grid item>
        <Tooltip title="Please select a project type" arrow>
          <span>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled
              id="submitProjectSearch"
            >
              Submit Search
            </Button>
          </span>
        </Tooltip>
      </Grid>
    )
  } else {
    return null
  }
}

export default SubmitProjectSearch
