import React from 'react'
import { Table, TableBody, TableContainer } from '@material-ui/core'
import PropTypes from 'prop-types'
import GeneralizedEnhancedTableHead from '../../../Common/Components/GeneralizedEnhancedTableHead'
import SelectableRow from './AddDataBatchTableRowLogical'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

function AddDataBatchSamplesTablePresentational(props) {
  AddDataBatchSamplesTablePresentational.propTypes = {
    /** State variable containing an array of the selected batches */
    selected: PropTypes.array.isRequired,

    /** The batches currently visible in the page */
    visibleBatches: PropTypes.array.isRequired,

    /** Total number of batches associated with the mix design, used for pagination */
    totalBatchCount: PropTypes.number.isRequired,

    /** Head cells for the table */
    headCells: PropTypes.array.isRequired,

    /** Handler for selecting each individual batch row */
    handleRowClick: PropTypes.func.isRequired,

    /** Table sorting handler */
    handleRequestSort: PropTypes.func.isRequired,

    /** The direction order, passed to table head */
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,

    /** The column used to order, passed to table head */
    orderBy: PropTypes.string.isRequired,

    /** Number of rows displayed on each page, used in pagination */
    rowsPerPage: PropTypes.number.isRequired,

    /** Current page, used in pagination */
    page: PropTypes.number.isRequired,

    /** Handler to change the current page */
    handleChangePage: PropTypes.func.isRequired,

    /** Handler to change number of rows displayed on each page */
    handleChangeRowsPerPage: PropTypes.func.isRequired,

    /** Changes pagination view based on customer selection */
    hidePagination: PropTypes.bool,
  }

  const {
    selected,
    visibleBatches,
    totalBatchCount,
    headCells,
    handleRowClick,
    handleRequestSort,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    hidePagination,
  } = props

  return (
    <>
      <TableContainer>
        <Table size="small">
          <GeneralizedEnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            checkboxColumn
          />
          <TableBody>
            {/* Have table be actually sort-able, or find out what the volume
                of these rows are going to be
            */}
            {visibleBatches.map(batch => (
              <SelectableRow
                key={batch.batchTestSampleId}
                batchDetails={batch}
                onClickHandler={handleRowClick}
                selected={selected.includes(batch)}
                radioVariant={false}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleTablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component="div"
        count={totalBatchCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        hidePagination={hidePagination}
      />
    </>
  )
}

export default AddDataBatchSamplesTablePresentational
