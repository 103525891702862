import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import MasterUnitView from './MasterUnitView'
import MasterUnitAlarmedTableView from './MasterUnitAlarmedTableView'
import MasterUnitTableView from './MasterUnitTableView'
import { Route, Switch, useParams } from 'react-router-dom'

function PCBView(props) {
  const [selectedMasterUnit, setSelectedMasterUnit] = useState()
  const { id } = useParams()
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justify="space-between"
      spacing={4}
    >
      <Grid item>
        <Switch>
          <Route path={['/selected/:id', '/selected']}>
            {selectedMasterUnit ? (
              <MasterUnitView masterData={selectedMasterUnit} />
            ) : id ? (
              <MasterUnitView masterData={{ masterUnitId: id }} />
            ) : (
              <></>
            )}
          </Route>
        </Switch>
      </Grid>
      {/* active alarms */}
      <Grid item>
        <MasterUnitAlarmedTableView
          setSelectedMasterUnit={setSelectedMasterUnit}
        />
      </Grid>
      <Grid item>
        {/* All master units */}
        <MasterUnitTableView setSelectedMasterUnit={setSelectedMasterUnit} />
      </Grid>
    </Grid>
  )
}

export default PCBView
