import React, { ChangeEvent } from 'react'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'
import CitySelectPresentational from './CitySelectPresentational'
import {
  IConcreteCorporation,
  IAutocompleteOption,
} from '../../Interfaces/EchoInterfaces'
import { EMapLayers } from '../../Enums/EchoEnums'

interface IComponentProps {
  filteredCorpData: Array<IConcreteCorporation>
}

function CitySelectLogical(props: IComponentProps) {
  const { filteredCorpData } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  /** City location of each plant */
  const plantCityList = filteredCorpData
    .map(company => {
      return company.divisions.map(division => {
        return division.plants.map(plant => {
          return {
            city: plant.city,
            state: plant.state,
            longLat: plant.longLat,
          }
        })
      })
    })
    .flat()
    .flat()
    /** Filter out duplicate and nulled city/state pairings */
    .filter(
      (value, index, array) =>
        array.findIndex(
          cityItem =>
            (cityItem.city === value.city && cityItem.state === value.state) ||
            (!cityItem.city && !cityItem.state)
        ) === index
    )
    /** Sort alphabetically by city name */
    .sort((a, b) => {
      if (a.city > b.city) {
        return 1
      } else if (b.city > a.city) {
        return -1
      } else return 0
    })

  /** Options (cities) for the autocomplete select input. Filters our non-existent labels */
  const options = plantCityList
    .map(city => {
      return {
        label:
          city.city && city.state
            ? `${city.city}, ${city.state}`
            : city.city || city.state || '',
        value: [city.city, city.state].join(', '),
        longLat: city.longLat,
      }
    })
    .filter(obj => obj.label.length > 1)

  /** Handle input change. Pass selected filter value to useEffect in EchoHome. */
  const handleChange = (
    _event: ChangeEvent<{}>,
    value: IAutocompleteOption | null
  ) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.cities = value ? [value] : []
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.cities = value ? [value] : []
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <CitySelectPresentational handleChange={handleChange} options={options} />
  )
}

export default CitySelectLogical
