import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import PropTypes from 'prop-types'

/** Component for selecting fresh property value from the passed available options */
export default function FreshPropertyDropdown(props) {
  FreshPropertyDropdown.propTypes = {
    /** Properties available for selection, displayed in dropdown. */
    propertyOptions: PropTypes.array.isRequired,
    /** Label used for dropdown. */
    labelName: PropTypes.string.isRequired,
    /** Currently selected property. */
    selectedProperty: PropTypes.string,
    /** Function sets a new selected property. */
    setSelectedProperty: PropTypes.func.isRequired,
    /** Bool indicating whether the dropdown is disabled. */
    disabled: PropTypes.bool,
  }
  const {
    propertyOptions,
    labelName,
    selectedProperty,
    setSelectedProperty,
    disabled,
    getOptionDisabled = () => false,
  } = props
  return (
    <Autocomplete
      options={propertyOptions.filter(option => option.key !== 'batchInterval')}
      value={
        propertyOptions.find(option => option.key === selectedProperty) ?? null
      }
      getOptionSelected={(option, value) => option.key === value.key}
      getOptionLabel={option => option.label}
      disabled={disabled ? disabled : false}
      getOptionDisabled={option => getOptionDisabled(option)}
      renderInput={params => (
        <TextField
          {...params}
          label={labelName}
          variant="outlined"
          size="small"
          name="freshPropertyAutocomplete"
          placeholder="Select Property"
        />
      )}
      onChange={(_, newValue) => {
        setSelectedProperty(newValue?.key, newValue?.label)
      }}
    />
  )
}
