import { hardwareCanRead } from '../../Hardware/Logic/HardwareLogic'
import { AllProjects } from '../assets/projectLists'
import { filterSubPath } from './GeneralHelpers'

/**
 * Function to help the tabs highlight the correct department
 * @param {String} pathName
 * @returns {'Producers' | 'R&D' | 'Hardware' | 'QuickSight' | 'Concrete'}
 */
export function getDepartmentNameFromLocation(pathName = '') {
  const location = pathName.toLocaleLowerCase()
  if (location.includes('producers')) {
    return 'Producers'
  } else if (location.includes('cementprofiler')) {
    return 'R&D'
  } else if (location.includes('hardware')) {
    return 'Hardware'
  } else if (location.includes('quicksight')) {
    return 'QuickSight'
  } else if (location.includes('concrete')) {
    return 'Concrete'
  } else {
    return null
  }
}

export function getProjectObjectFromPath(path = '') {
  // convert the path to lowercase to accomodate for legacy and user-typed in urls
  const lowerCasePath = path.toLocaleLowerCase()
  let currentProject = null
  AllProjects.forEach(project => {
    if (lowerCasePath.includes(project.path.toLocaleLowerCase())) {
      currentProject = project
    }
  })
  return currentProject
}

/** Hides different links of the Navigation Bar based on the role of the user. */
export function getFilteredNavLinks(JWT, navLinks) {
  if (JWT.roles.includes('OrcaAdmin')) {
    return navLinks
  }

  let filteredNavLinks = navLinks
  if (!hardwareCanRead(JWT.roles)) {
    filteredNavLinks = filteredNavLinks.filter(
      link => link.department !== 'Hardware'
    )
  }
  if (!JWT.roles.includes('TSS') && !JWT.roles.includes('TSS_W')) {
    filteredNavLinks = filterSubPath(
      filteredNavLinks,
      'Producers',
      'Producers',
      ['Echo', 'Report Library']
    )
    filteredNavLinks = filteredNavLinks.filter(
      link => link.department !== 'Concrete'
    )
  }

  return filteredNavLinks
}
