import React, { useState } from 'react'
import {
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Tooltip,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Close from '@material-ui/icons/Close'
import ResolveAlarmDialogLogical from './ResolveAlarmDialog/ResolveAlarmDialogLogical.tsx'

function AlarmsTableRow(props) {
  AlarmsTableRow.propTypes = {
    /** Function to handle selecting table rows */
    handleClick: PropTypes.func.isRequired,
    /** Whether or not the table row has been selected */
    isItemSelected: PropTypes.bool.isRequired,
    /** The table row */
    row: PropTypes.object.isRequired,
    /** The aria-label */
    labelId: PropTypes.string.isRequired,
  }
  const { handleClick, isItemSelected, row, labelId } = props

  const [resolved, setResolved] = useState(row.resolved)

  /** Boolean to determine whether the modal is open */
  const [open, setOpen] = useState(false)

  async function handleClearAlarm(alarmRow) {
    const cleared = await alarmRow.clearAlarm()
    setResolved(cleared)
  }

  return (
    <>
      <ResolveAlarmDialogLogical
        open={open}
        setOpen={setOpen}
        row={row}
        handleClearAlarm={handleClearAlarm}
      />
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.alarmId}
        selected={isItemSelected}
      >
        {/* Row checkbox */}
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={event => handleClick(event, row)}
            icon={<CircleUnchecked />}
            checkedIcon={<RadioButtonChecked />}
            inputProps={{ 'aria-labelledby': labelId }}
            color="primary"
          />
        </TableCell>
        <TableCell>{row.timestamp.toLocaleString()}</TableCell>
        <TableCell>{row.hmiId}</TableCell>
        <TableCell>{row.customerName}</TableCell>
        <TableCell>{row.customerLocation}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.message}</TableCell>
        <TableCell>
          <Tooltip
            title={
              resolved
                ? row.resolvedTimestamp.toLocaleString()
                : 'Not yet resolved'
            }
            arrow={true}
          >
            <AccessTimeIcon color={resolved ? 'primary' : 'inherit'} />
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={resolved ? '' : 'Clear alarm'} arrow={true}>
            <Button onClick={() => setOpen(true)} disabled={resolved}>
              <Close />
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AlarmsTableRow
