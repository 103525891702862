import { TextField } from '@material-ui/core'
import React from 'react'
interface EditableCellDateProps {
  /** Current date value. */
  value: string | number
  /** Change handler for updating date values. */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Whether field is currently focused. Used to highlight associated fields. */
  isFocused: boolean
}

function EditableCellDate(props: EditableCellDateProps) {
  const { value, onChange, isFocused } = props

  return (
    <TextField
      value={value}
      focused={isFocused}
      size="small"
      variant="outlined"
      onChange={onChange}
      type="date"
    />
  )
}

export default EditableCellDate
