import React, { Dispatch, SetStateAction } from 'react'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import {
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Button,
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
  Visibility,
  VisibilityOff,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import {
  PlantDataFilterSectionExpanded,
  PlantDataFilterSectionCategory,
  ViewDesignsFilterSectionExpanded,
  AlarmsManagementSectionCategory,
  AlarmsManagementFilterSectionExpanded,
  PlantDataHelperVariables,
  ViewDesignsHelperVariables,
  FilterPanelSection,
  ViewDesignsFilterSectionCategory,
  AddDataSettings,
  AddDataHelperVariables,
  EchoHelperVariables,
  SonarDateRange,
  DateRange,
  SonarFilterType,
} from '../../../TSS/Logic/Types'
import CommissioningFilter from '../../../TSS/Components/CommissioningFilter/CommissioningFilter'
import SearchMixCodeField from '../../../TSS/Components/SearchMixCodeField'
import GraphFiltersContainerLogical from '../../../TSS/Components/GraphFiltersContainerLogical'
import CustomerAndLocationSelect from '../../../TSS/Components/CustomerAndLocationSelect'
import SearchMixCodeFieldForParams from '../../../TSS/Components/SearchMixCodeFieldForParams'
import SearchConditionFieldForParams from '../../../TSS/Components/SearchConditionFieldForParams'
import RangeSlider from '../../../TSS/Components/RangeSlider'
import SalesPersonSelectLogical from '../../../Echo/Components/SalesPersonSelect/SalesPersonSelectLogical'
import MapLayerSelectLogical from '../../../Echo/Components/MapLayerSelect/MapLayerSelectLogical'
import OwnershipFilter from '../../../Echo/Components/OwnershipFilter/OwnershipFilter'
import ProjectDropdownLogical from '../../../Echo/Components/ProjectDropdown/ProjectDropdownLogical'
import DateRangeLogical from '../../../Echo/Components/DateRange/DateRangeLogical'
import KeywordSearchLogical from '../../../Echo/Components/KeywordSearch/KeywordSearchLogical'
import NumberOfPlantsSlider from '../../../Echo/Components/NumberOfPlantsSlider/NumberOfPlantsSlider'
import SubmitProjectSearch from '../../../Echo/Components/SubmitProjectSearch/SubmitProjectSearch'
import FilterBy from '../../../Echo/Components/FilterBy/FilterBy'
import { isProjectSearchActive } from '../../../Echo/Logic/FilterLogicHelpers'
import theme from '../../../theme/muiTheme'
import { ClassNameMap } from '@material-ui/styles'
import { Filter, FilterComponentTypes, FilterOption } from '../../Logic/Types'
import FilterPanelAccordion from './FilterPanelAccordion'
import SonarDateRangeSelect from '../../../Hardware/Sonar/Components/SonarDateRangeSelect'

const useStyles = makeStyles({
  filterRow: {
    gap: '8px',
  },
  plantsFiltersSwitch: {
    marginRight: '0em',
  },
  inactiveChip: {
    backgroundColor: '#70707014 !important',
    color: 'black',
    border: '0.41px solid rgba(0, 0, 0, 0.6)',
  },
  activeChip: {
    backgroundColor: 'rgba(32, 59, 91, 0.3) !important',
    color: 'black',
    border: '0.41px solid rgba(0, 0, 0, 0.6)',
  },
  label: {
    padding: '8px',
  },
  select: {
    minWidth: '200px',
    padding: 0,
    marginTop: '10px',
    fontSize: '18px',
  },
  button: {
    ...theme.customClasses.filterPanel.filterButtonInactive,
    width: '100%',
  },
})

interface IFilterPanelFilters {
  setOpen: Dispatch<SetStateAction<boolean>>
  expandFilters:
    | PlantDataFilterSectionExpanded
    | ViewDesignsFilterSectionExpanded
  setExpandFilters: Dispatch<
    SetStateAction<
      PlantDataFilterSectionExpanded | ViewDesignsFilterSectionExpanded
    >
  >
  filterPanel: Array<FilterPanelSection>
  getValue: (
    arg1: Filter,
    arg2?: FilterOption | string | boolean
  ) => Array<FilterOption> | string | boolean
  helperVariables:
    | ViewDesignsHelperVariables
    | PlantDataHelperVariables
    | AddDataHelperVariables
    | EchoHelperVariables
  filtersClasses: ClassNameMap<string>
  disableAll: boolean
  isAssociationView: boolean
  addDataSettings: AddDataSettings
  setAddDataSettings: Dispatch<SetStateAction<AddDataSettings>>
  isLocationRequired: boolean
  dateRangeSettings?: {
    clearRangeSelected?: boolean
    dateRangeStart: DateRange
    dateRangeEnd: DateRange
    rangeSelected: SonarDateRange | null
    setEndDateSelected?: (val: string) => void
    setRangeSelected?: (value: SonarDateRange | null) => void
    setStartDateSelected?: (val: string) => void
    sonarDateRangeFilterType?: SonarFilterType | null
  }
}

const FilterPanelFilters = (props: IFilterPanelFilters) => {
  /** Atom with Plants Filters **/
  const classes = useStyles()
  const {
    setOpen,
    expandFilters,
    setExpandFilters,
    filterPanel,
    getValue,
    helperVariables,
    filtersClasses,
    disableAll,
    isAssociationView,
    addDataSettings,
    setAddDataSettings,
    dateRangeSettings,
  } = props

  const getCheckboxIcon = (name: string) => {
    if (name === 'visibility') {
      return {
        checked: <Visibility fontSize="small" />,
        unchecked: <VisibilityOff fontSize="small" />,
      }
    } else {
      return {
        checked: <CheckBox />,
        unchecked: <CheckBoxOutlineBlank />,
      }
    }
  }

  const renderElement = (filter: Filter) => {
    const {
      onChangeHandler = () => undefined,
      onClickHandler = () => undefined,
      disabled = () => false,
      placeholder = '',
      checkboxIconType = '',
      getTooltipTitle = () => '',
      isHorizontal = true,
      color = 'default',
      labelPlacement = 'start',
      getOptionDisabled = () => false,
      getOptionLabel = () => '',
      heading = null,
      renderTags = undefined,
      renderOption = undefined,
    } = filter

    switch (filter.componentType) {
      case FilterComponentTypes.AutocompleteMulti:
        return (
          <Autocomplete
            key={filter.property}
            id={filter.property + ' Select'}
            multiple
            size="small"
            value={getValue(filter) || []}
            options={filter.options}
            onChange={(_event, value) => {
              onChangeHandler(filter, value)
            }}
            getOptionLabel={option => option.name}
            style={{ minWidth: 150 }}
            renderInput={params => (
              <TextField
                {...params}
                label={filter.name}
                variant="outlined"
                placeholder={placeholder}
              />
            )}
            disabled={disableAll || disabled()}
            data-testid={`filterPanelAutocompleteMulti-${filter.property}`}
            getOptionDisabled={getOptionDisabled}
            renderTags={renderTags}
            renderOption={renderOption}
          />
        )
      case FilterComponentTypes.ChipsMulti:
        return (
          <Grid container spacing={1} wrap="wrap">
            <>
              {!!heading && heading}
              {filter.options.map((option: FilterOption) => (
                <Grid item key={option.id}>
                  <Chip
                    label={option.name}
                    className={
                      helperVariables.isChipActive(option)
                        ? classes.activeChip
                        : classes.inactiveChip
                    }
                    onClick={() => {
                      onClickHandler(filter, option)
                    }}
                    classes={{
                      label: classes.label,
                    }}
                    disabled={disableAll || disabled(option.id)}
                  />
                </Grid>
              ))}
            </>
          </Grid>
        )
      case FilterComponentTypes.CommissioningFilter:
        return (
          <CommissioningFilter
            tableRows={helperVariables.rows}
            customersPage={false}
            commissioningFilter={getValue(filter)}
            onChangeHandler={onChangeHandler}
            disabled={disableAll}
          />
        )
      case FilterComponentTypes.SearchMixCodeField:
        return (
          <SearchMixCodeField
            analysisFilterSettings={helperVariables.analysisFilterSettings}
            setAnalysisFilterSettings={
              helperVariables.setAnalysisFilterSettings
            }
            key={helperVariables.analysisFilterSettings.mixCode[0]}
            disabled={disableAll}
          />
        )
      case FilterComponentTypes.Checkbox:
        return (
          <FormControlLabel
            control={
              <Checkbox
                name={filter.name}
                color={color}
                checked={getValue(filter)}
                onChange={() => onChangeHandler(filter, null)}
                checkedIcon={getCheckboxIcon(checkboxIconType).checked}
                icon={getCheckboxIcon(checkboxIconType).unchecked}
                className={filtersClasses?.checkbox}
              />
            }
            className={filtersClasses?.checkboxFormControlLabel}
            label={filter.label}
            labelPlacement={labelPlacement}
            disabled={disableAll || disabled()}
            data-testid={`${filter.label}-Checkbox`}
          />
        )
      case FilterComponentTypes.AutocompleteSingle:
        return (
          <Autocomplete
            key={filter.property}
            id={filter.property + ' Select'}
            size="small"
            options={filter.options}
            value={getValue(filter)}
            onChange={(_event, value) => {
              onChangeHandler(filter, value)
            }}
            getOptionLabel={option => getOptionLabel(option) || option.name}
            style={{ minWidth: 150 }}
            renderInput={params => (
              <TextField {...params} label={filter.name} variant="outlined" />
            )}
            disabled={disableAll || disabled ? disableAll || disabled() : false}
            data-testid={`filterPanelAutocomplete-${filter.property}`}
            renderOption={filter.renderOption}
          />
        )

      case FilterComponentTypes.GraphFilters:
        return (
          <GraphFiltersContainerLogical
            key={helperVariables.selectedMixVariations}
            selectedMixVariations={helperVariables.selectedMixVariations}
            filteredData={helperVariables.filteredData}
            setFilteredData={helperVariables.setFilteredData}
            graphType={helperVariables.graphType}
            updateSelectedAge={helperVariables.updateSelectedAge}
            isMetric={helperVariables.analysisFrontendFilterSettings.isMetric}
          />
        )

      case FilterComponentTypes.TextFieldSelect:
        return (
          <TextField
            select
            size="small"
            value={getValue(filter)}
            onChange={onChangeHandler}
            className={classes.select}
            variant="outlined"
            label={filter.label}
            disabled={disabled() || disableAll}
            data-testid={`textfieldSelect-${filter.property}`}
          >
            {filter.options.map(option => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              )
            })}
          </TextField>
        )
      case FilterComponentTypes.CheckboxMulti:
        return (
          <>
            {filter.options.map((option: FilterOption) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    color={color}
                    checked={getValue(filter, option)}
                    onChange={(_event, checked) => {
                      onChangeHandler(filter, checked, option)
                    }}
                    checkedIcon={getCheckboxIcon(checkboxIconType).checked}
                    icon={getCheckboxIcon(checkboxIconType).unchecked}
                  />
                }
                label={option.name}
                disabled={disableAll}
              />
            ))}
          </>
        )
      case FilterComponentTypes.RadioSingleSelect:
        return (
          <FormControl
            component="fieldset"
            disabled={disableAll}
            data-testid={`filter-${filter.property}`}
          >
            <RadioGroup
              row={isHorizontal}
              name={filter.property}
              value={getValue(filter)}
              onChange={(_event, value) => {
                onChangeHandler(filter, value)
              }}
            >
              {filter.options.map((option: FilterOption) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio color={color} />}
                  label={option.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      case FilterComponentTypes.CheckboxMultiWithTooltip:
        return (
          <>
            {filter.options.map(option => (
              <FormControlLabel
                key={option.id}
                control={
                  <Tooltip title={getTooltipTitle(getValue(filter, option))}>
                    <Checkbox
                      color="default"
                      checked={getValue(filter, option)}
                      onChange={(_event, checked) => {
                        onChangeHandler(filter, checked, option)
                      }}
                      checkedIcon={getCheckboxIcon(checkboxIconType).checked}
                      icon={getCheckboxIcon(checkboxIconType).unchecked}
                      className={filtersClasses?.checkboxMultiWithTooltip}
                    />
                  </Tooltip>
                }
                className={
                  filtersClasses?.checkboxMultiFormControlLabelWithTooltip
                }
                label={option.name}
                labelPlacement="start"
                disabled={disableAll}
              />
            ))}
          </>
        )
      case FilterComponentTypes.DateRange:
        return (
          <>
            <TextField
              id="start-date"
              label="Start Date"
              type="date"
              value={getValue(filter, 'startDate')}
              onChange={event => {
                onChangeHandler(filter, event.target.value, 'startDate')
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              disabled={disableAll}
            />
            <TextField
              id="end-date"
              label="End Date"
              type="date"
              value={getValue(filter, 'endDate')}
              onChange={event => {
                onChangeHandler(filter, event.target.value, 'endDate')
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              disabled={disableAll}
            />
          </>
        )
      case FilterComponentTypes.Button:
        return (
          <Button
            variant="outlined"
            size="medium"
            onClick={() => onClickHandler(filter, null)}
            disabled={disabled()}
            className={classes.button}
          >
            {filter.label}
          </Button>
        )
      case FilterComponentTypes.CustomerAndLocationSelect:
        return (
          <CustomerAndLocationSelect
            showPlants
            inputsAlwaysEnabled={true}
            isAssociationView={isAssociationView}
            setMixCodeInternal={helperVariables.setMixCodeInternal}
            sizes={{ xs: 12, sm: 12, md: 12, lg: 12, spacing: 0 }}
            addDataSettings={addDataSettings}
            setAddDataSettings={setAddDataSettings}
            resetBaleenDependents={helperVariables.resetBaleenDependents}
            unsavedChangesModalData={helperVariables.unsavedChangesModalData}
            setUnsavedChangesModalData={
              helperVariables.setUnsavedChangesModalData
            }
            setIsUnsavedDataModalOpen={
              helperVariables.setIsUnsavedDataModalOpen
            }
            getIsUnsavedChangePresent={
              helperVariables.getIsUnsavedChangePresent
            }
            originalOutliers={helperVariables.originalOutliers}
            selectedVariations={helperVariables.selectedVariations}
            isUnsavedDataModalOpen={helperVariables.isUnsavedDataModalOpen}
            modalClasses={helperVariables.modalClasses}
            isLocationRequired={helperVariables.isLocationRequired}
          />
        )

      case FilterComponentTypes.SearchMixCodeFieldForParams:
        return (
          <SearchMixCodeFieldForParams
            addDataSettings={addDataSettings}
            setAddDataSettings={setAddDataSettings}
            mixCodeInternal={helperVariables.mixCodeInternal}
            setMixCodeInternal={helperVariables.setMixCodeInternal}
          />
        )

      case FilterComponentTypes.SearchConditionFieldForParams:
        return (
          <SearchConditionFieldForParams
            addDataSettings={addDataSettings}
            setAddDataSettings={setAddDataSettings}
            conditionInternal={helperVariables.conditionInternal}
            setConditionInternal={helperVariables.setConditionInternal}
            isAssociationView={isAssociationView}
          />
        )

      case FilterComponentTypes.RangeSlider:
        return (
          <RangeSlider
            addDataSettings={addDataSettings}
            setAddDataSettings={setAddDataSettings}
            minSamples={helperVariables.minSamples}
            setMinSamples={helperVariables.setMinSamples}
            maxSamples={helperVariables.maxSamples}
            setMaxSamples={helperVariables.setMaxSamples}
            sliderValues={helperVariables.sliderValues}
            setSliderValues={helperVariables.setSliderValues}
            isAssociationView={isAssociationView}
          />
        )

      case FilterComponentTypes.EchoSales:
        return (
          <SalesPersonSelectLogical
            filteredCorpData={helperVariables.filteredCorpData}
          />
        )

      case FilterComponentTypes.EchoMapLayer:
        return (
          <MapLayerSelectLogical
            selectedOptions={helperVariables.selectedOptions}
            setSelectedOptions={helperVariables.setSelectedOptions}
            filterPanelClasses={classes}
          />
        )

      case FilterComponentTypes.EchoProducer:
        return (
          <>
            <OwnershipFilter />
            <NumberOfPlantsSlider corpData={helperVariables.corpData} />
          </>
        )

      case FilterComponentTypes.EchoProjectFilters:
        return (
          <>
            {isProjectSearchActive(helperVariables.echoSettings) ? (
              <>
                <ProjectDropdownLogical />
                <DateRangeLogical />
                <KeywordSearchLogical />
                <SubmitProjectSearch
                  submitProjectSearch={helperVariables.submitProjectSearch}
                />
              </>
            ) : (
              <Typography align="center">
                Select the Project Search Map Layer to access these filters
              </Typography>
            )}
          </>
        )

      case FilterComponentTypes.EchoFilterBy:
        return (
          <>
            <FilterBy />
            {helperVariables.filterSelect[helperVariables.filterGroup]}
          </>
        )

      case FilterComponentTypes.DateRangeSelect:
        return (
          <SonarDateRangeSelect
            clearRangeSelected={dateRangeSettings?.clearRangeSelected}
            dateRangeStart={dateRangeSettings?.dateRangeStart as DateRange}
            dateRangeEnd={dateRangeSettings?.dateRangeEnd as DateRange}
            filterType={dateRangeSettings?.sonarDateRangeFilterType}
            rangeSelected={dateRangeSettings?.rangeSelected as SonarDateRange}
            setRangeSelected={dateRangeSettings?.setRangeSelected}
            setStartDateSelected={dateRangeSettings?.setStartDateSelected}
            setEndDateSelected={dateRangeSettings?.setEndDateSelected}
            disabled={() => disabled()}
          />
        )
    }
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid container item spacing={2} alignItems="center">
        <Grid item lg={10} md={10} sm={10}>
          <Typography variant="h3" color="secondary">
            Filters
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} style={{ marginLeft: 'auto' }}>
          <IconButton
            data-testid="close-panel"
            onClick={() => setOpen(false)}
            disabled={disableAll}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        {filterPanel.map(filterPanelSection => (
          <React.Fragment key={filterPanelSection.name}>
            <Grid container item spacing={2} alignItems="center">
              <Grid item lg={10} md={10} sm={10} style={{ marginTop: '5px' }}>
                <Typography variant="h5" color="secondary">
                  {filterPanelSection.name}
                </Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={2} style={{ marginLeft: 'auto' }}>
                <IconButton
                  data-testid={`${filterPanelSection.category}-expand`}
                  onClick={() =>
                    setExpandFilters(
                      (
                        prev:
                          | PlantDataFilterSectionExpanded
                          | ViewDesignsFilterSectionExpanded
                          | AlarmsManagementFilterSectionExpanded
                      ) => {
                        const key:
                          | PlantDataFilterSectionCategory
                          | ViewDesignsFilterSectionCategory
                          | AlarmsManagementSectionCategory =
                          filterPanelSection.category
                        const prevVal: boolean = prev[key]
                        return {
                          ...prev,
                          [key]: !prevVal,
                        }
                      }
                    )
                  }
                  disabled={disableAll}
                >
                  {expandFilters[filterPanelSection.category] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs>
              <Collapse in={expandFilters[filterPanelSection.category]}>
                <Grid item xs>
                  {filterPanelSection.filters.map(filter => (
                    <React.Fragment key={filter.property}>
                      {filter.componentType ===
                      FilterComponentTypes.Accordion ? (
                        <FilterPanelAccordion
                          renderElement={renderElement}
                          filter={filter?.nestedFilter}
                        />
                      ) : (
                        renderElement(filter)
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              </Collapse>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default FilterPanelFilters
