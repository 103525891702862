import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

//  The TLV remote view Company name, Plant Name, Remote Unit, and number of alarms
//  It is defaulted to being in a collapsed state, but that will probalby change
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['Ascending', 'Descending', '']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
}
export function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  const direction = {
    Ascending: 'asc',
    Descending: 'desc',
    '': false,
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? direction[order] : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? direction[order] : 'asc'}
              onClick={
                headCell.clickable ? createSortHandler(headCell.id) : () => {}
              }
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
