import React, { Dispatch, SetStateAction } from 'react'
import { Typography, makeStyles, Grid } from '@material-ui/core'
import {
  ICommissionReportSettings,
  IMixDesignDetailsSettings,
  IMixSelection,
  IViewMixSelectionProperty,
} from '../Logic/Types'
import {
  getValidMixDetailsProperties,
  getViewMixDesignDetailLabels,
  getViewPropertyValueWithUnit,
} from '../Helpers/CommissionReportHelpers'

export interface IViewCommissionReportMixDesignDetailsProps {
  mixSelections: Array<IMixSelection>
  reportSettings: ICommissionReportSettings
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  isPrinting: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    padding: theme.spacing(2),
  },
  pdfRoot: {
    width: 'auto',
    padding: `16px 0px`,
  },
  gridRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  gridCell: {
    flex: 1,
    position: 'relative',
    wordBreak: 'break-word',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}))

const ViewCommissionReportMixDesignDetails: React.FC<IViewCommissionReportMixDesignDetailsProps> = ({
  reportSettings,
  mixSelections,
  isPrinting,
}) => {
  const classes = useStyles()
  const viewMixDesignDetailsPropertyLabels = getViewMixDesignDetailLabels(
    reportSettings?.plantType,
    reportSettings?.isMetric
  )

  const validMixDesignDetailsProperties = getValidMixDetailsProperties(
    viewMixDesignDetailsPropertyLabels,
    mixSelections,
    reportSettings
  )

  const getMixDesignDetailsPropertyValue = (
    property: IViewMixSelectionProperty,
    mixSelection: IMixSelection,
    reportSettings: ICommissionReportSettings
  ) => {
    const mixDesignSettings = reportSettings.mixDesignSettings.find(
      setting => setting.mixDesignId === mixSelection.mixDesignId
    )!
    const includedOnReport =
      mixDesignSettings.mixDesignPropertiesIncludedOnCustomerReport[
        property.id as keyof IMixDesignDetailsSettings
      ]

    const propertyValue = getViewPropertyValueWithUnit(
      mixSelection,
      property.id,
      reportSettings.isMetric
    )

    if (includedOnReport) {
      return propertyValue ?? '-'
    }
    return 'N/A'
  }

  return (
    <Grid container className={isPrinting ? classes.pdfRoot : classes.root}>
      {/* Row for property names */}
      <Grid item xs={12} container direction="row" className={classes.gridRow}>
        <Grid item xs className={classes.gridCell}>
          <Typography variant="h5">Mix Design</Typography>
        </Grid>
        {validMixDesignDetailsProperties.map(property => (
          <Grid item key={property.id} className={classes.gridCell}>
            <Typography variant="h5">{property.name}</Typography>
          </Grid>
        ))}
      </Grid>
      {/* Row for property values */}
      {mixSelections.map(mixSelection => (
        <Grid
          item
          xs={12}
          container
          direction="row"
          className={classes.gridRow}
          key={mixSelection.mixDesignId}
        >
          <Grid item xs className={classes.gridCell}>
            <Typography>{mixSelection.mixCode}</Typography>
          </Grid>
          {validMixDesignDetailsProperties.map(property => (
            <Grid
              item
              key={property.id + '-value'}
              className={classes.gridCell}
            >
              <Typography>
                {getMixDesignDetailsPropertyValue(
                  property,
                  mixSelection,
                  reportSettings
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}

export default ViewCommissionReportMixDesignDetails
