import { Checkbox, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import { IndeterminateCheckBox } from '@material-ui/icons'
import AddDataBatchSamplesTablePresentational from './AddDataBatchSamplesTablePresentational'

/** Function used to generate the head cells used in the component.
 * Exported here so it can be reused in this component's story.
 * @param {Boolean} allBatchesSelected - bool indicating if all batches are currently selected
 * @param {Function} toggleSelectAll - function used to toggle selecting all batches.
 */
export const generateHeadCells = (allBatchesSelected, toggleSelectAll) => [
  {
    id: 'selectAllIcon',
    label: (
      <Tooltip title="Select all batches" placement="top">
        <Checkbox
          checked={allBatchesSelected}
          onClick={toggleSelectAll}
          size="medium"
          color="primary"
          checkedIcon={<IndeterminateCheckBox />}
        />
      </Tooltip>
    ),
  },
  {
    id: 'producer',
    numeric: false,
    disablePadding: false,
    label: 'Producer',
    sortable: true,
    width: '80px',
  },
  {
    id: 'mixCode',
    numeric: false,
    disablePadding: false,
    label: 'Mix #',
    sortable: true,
    width: '80px',
  },
  {
    id: 'ticketId',
    numeric: false,
    disablePadding: false,
    label: 'ID',
    sortable: true,
    width: '60px',
  },
  {
    id: 'productionDate',
    numeric: false,
    disablePadding: false,
    label: 'Prod. Date',
    sortable: true,
    width: '80px',
  },
  {
    id: 'totalCylinders',
    numeric: true,
    disablePadding: false,
    label: '# Cyl.',
    sortable: true,
  },
  {
    id: 'slump',
    numeric: true,
    disablePadding: false,
    label: 'Slump',
    sortable: true,
  },
  {
    id: 'air',
    numeric: true,
    disablePadding: false,
    label: 'Air',
    sortable: true,
  },
  {
    id: 'concreteTemperature',
    numeric: true,
    disablePadding: false,
    label: 'Conc. Temp',
    sortable: true,
  },
  {
    id: 'cementContent',
    numeric: true,
    disablePadding: false,
    label: 'Cmt Cont.',
    sortable: true,
  },
  {
    id: 'strengthAvg7Days',
    numeric: true,
    disablePadding: false,
    label: '7 Day',
    sortable: true,
  },
  {
    id: 'strengthAvg28Days',
    numeric: true,
    disablePadding: false,
    label: '28 Day',
    sortable: true,
  },
  {
    id: 'densityKgPerM3',
    numeric: true,
    disablePadding: false,
    label: 'Unit Wt.',
    sortable: true,
  },
]

/** Component used to display batch test samples on AddDataView
 * @param {Array} batchSamples - the batch test samples corresponding to a selected mix design.
 * @param {Array} selected - state variable containing an array of the selected mixes/batches.
 * @param {Function} setSelected - state setter for selected batches.
 */
function AddDataBatchSamplesTableLogical(props) {
  AddDataBatchSamplesTableLogical.propTypes = {
    /** The batch test samples corresponding to a selected mix design */
    batchSamples: PropTypes.array.isRequired,

    /** State variable containing an array of the selected mixes/batches */
    selected: PropTypes.array.isRequired,

    /** State setter for selected batches */
    setSelected: PropTypes.func.isRequired,

    /** Changes pagination view based on customer selection */
    hidePagination: PropTypes.bool,
  }

  const { batchSamples, setSelected, selected, hidePagination } = props

  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('mixCode')

  /** Subset of batches that are currently displayed in the table. */
  const visibleBatches = stableSort(
    batchSamples,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleRowClick = batch => {
    /** If batch is already in the selected array, remove it. Otherwise, add it. */
    if (selected.includes(batch)) {
      setSelected(prev => {
        const newSelection = [...prev.selectedBatchActuals]
        newSelection.splice(selected.indexOf(batch), 1)
        return {
          ...prev,
          selectedBatchActuals: newSelection,
        }
      })
    } else {
      setSelected(prev => {
        return {
          ...prev,
          selectedBatchActuals: [...prev.selectedBatchActuals, batch],
        }
      })
    }
  }

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const allBatchesSelected =
    batchSamples.length > 0 && batchSamples.length === selected.length

  /** Function to set all batches in table as selected. */
  const toggleSelectAll = () => {
    setSelected(prev => {
      if (prev.selectedBatchActuals.length === batchSamples.length) {
        return {
          ...prev,
          selectedBatchActuals: [], // Clear selection if all batches are already selected
        }
      }
      return {
        ...prev,
        selectedBatchActuals: batchSamples,
      }
    })
  }

  return (
    <AddDataBatchSamplesTablePresentational
      selected={selected}
      visibleBatches={visibleBatches}
      totalBatchCount={batchSamples.length}
      headCells={generateHeadCells(allBatchesSelected, toggleSelectAll)}
      handleRowClick={handleRowClick}
      handleRequestSort={handleRequestSort}
      order={order}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      hidePagination={hidePagination}
    />
  )
}

export default AddDataBatchSamplesTableLogical
