import React from 'react'
import BaleenVariationTablePresentational, {
  IBaleenVariationTable,
} from './BaleenVariationTablePresentational'

const BaleenVariationTableLogical = (props: IBaleenVariationTable) => {
  const {
    selectedDataColumns,
    selectedMixVariations,
    frontendFilterSettings,
    mixVariations,
  } = props
  return (
    <BaleenVariationTablePresentational
      selectedMixVariations={selectedMixVariations}
      selectedDataColumns={selectedDataColumns}
      frontendFilterSettings={frontendFilterSettings}
      mixVariations={mixVariations}
    />
  )
}

export default BaleenVariationTableLogical
