import React, { useEffect, useState } from 'react'
import MaterialManagerModalPresentational from './MaterialManagerModalPresentational'
import {
  IAliasUpdate,
  IMaterial,
  MaterialExceptionType,
  MaterialManagerComponent,
  MaterialObject,
  MaterialRequestType,
  PlantComposition,
} from '../../Logic/Types'
import {
  convertExistingMaterialKeys,
  getPlantNamesByMaterialId,
  getPlantNamesForCurrentMaterial,
} from '../../Helpers/MaterialManagerHelpers'

interface IMaterialManagerModalLogical {
  /** Determines if modal is open */
  modalOpen: boolean
  /** Controls the opening of the modal */
  setModalOpen: (arg0: boolean) => void
  /** The type of the modal (Similar Material, Duplicate Material, etc.) */
  modalType: MaterialExceptionType | null
  /** Sets the type of the modal (Similar Material, Duplicate Material, etc.) */
  setModalType: (arg0: MaterialExceptionType) => void
  /** States containing material data for comparisons (Similar/Duplicate Modals) */
  existingMaterial: MaterialObject | null
  currentMaterial: MaterialObject | null
  /** The plants data of the current customer */
  plantData: PlantComposition[]
  /** The material data used for display on Success Modal */
  successData: MaterialObject | null
  setSuccessData: (arg0: MaterialObject | null) => void
  /** POST/PUT API call for submitting Material Data */
  sendMaterialData: (
    arg0: IMaterial | IAliasUpdate,
    arg1: MaterialRequestType,
    arg2?: boolean
  ) => void
  sendMaterialAndAliasData: (
    arg0: IMaterial,
    arg1: IAliasUpdate,
    arg2?: boolean
  ) => void
  /** The error data for duplicate aliases. Helps with alias field validation on Modals */
  duplicateAliasModalError: string[] | null | undefined
  /** Sets the error data to help with alias field validation on modals */
  setDuplicateAliasModalError: (arg0: string[] | null) => void
  /** Resets current/existing material data */
  modalDataReset: () => void
  /** Changes the view between Add/Edit/View Materials */
  setCurrentView: (view: MaterialManagerComponent) => void
  /** Set the material to be viewed in Add/Edit view */
  setSelectedMaterial: (arg0: IMaterial) => void
  /** A function that changes the new material data to be sent to Kelowna */
  setMaterialPostObject: (arg0: MaterialObject) => void
  /** The number of rendered alias input fields  */
  setAliasFields: (arg0: number[]) => void
  /** An array containing each value typed in the alias fields */
  setAliasFieldValues: (arg0: string[]) => void
  /** Kelowna generic errors */
  errorMessage: string | undefined
}

function MaterialManagerModalLogical(props: IMaterialManagerModalLogical) {
  const {
    modalOpen,
    setModalOpen,
    modalType,
    existingMaterial,
    currentMaterial,
    plantData,
    successData,
    setSuccessData,
    sendMaterialData,
    sendMaterialAndAliasData,
    duplicateAliasModalError,
    setDuplicateAliasModalError,
    modalDataReset,
    setCurrentView,
    setSelectedMaterial,
    setMaterialPostObject,
    setAliasFields,
    setAliasFieldValues,
    errorMessage,
  } = props

  /** Stores TextField component data in Similar Material Modal */
  const [existingTextFieldValue, setExistingTextFieldValue] = useState('')
  const [currentTextFieldValue, setCurrentTextFieldValue] = useState('')
  const [
    existingMaterialAssignedPlants,
    setExistingMaterialAssignedPlants,
  ] = useState<string[]>([])
  const [
    currentMaterialAssignedPlants,
    setCurrentMaterialAssignedPlants,
  ] = useState<string[]>([])

  /** Handles TextField component data in Similar Material Modal for existing aliases */
  const handleExistingTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDuplicateAliasModalError(null)
    setExistingTextFieldValue(event.target.value)
  }

  /** Handles TextField component data in Similar Material Modal for current aliases */
  const handleCurrentTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDuplicateAliasModalError(null)
    setCurrentTextFieldValue(event.target.value)
  }

  /** Handles the modal cancel button functionality */
  const handleCancel = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setModalOpen(false)
    modalDataReset()
  }

  /** Handles the saving aliases for Similar Material Modal */
  const handleSaveAliases = (_event: React.MouseEvent<HTMLButtonElement>) => {
    let updateAliasData
    let newMaterialData
    // Create Alias Update PUT Object
    if (existingMaterial !== null && existingTextFieldValue !== '') {
      updateAliasData = {
        materialMappingId: existingMaterial['MaterialMappingId'],
        aliasName: existingTextFieldValue,
      }
    }
    // Create new material object with new alias
    if (currentMaterial) {
      newMaterialData = {
        ...currentMaterial,
        aliases: [...currentMaterial.aliases, currentTextFieldValue],
        overrideSimilarCheck: true,
      }
      // Prep data for Success Modal
      setSuccessData({
        ...successData,
        aliases:
          currentTextFieldValue !== ''
            ? [...currentMaterial.aliases, currentTextFieldValue]
            : currentMaterial.aliases,
      })
    }
    sendMaterialAndAliasData(
      newMaterialData as IMaterial,
      updateAliasData as IAliasUpdate
    )
  }

  /** Handles adding an Alias to an existing material with the Duplicate Material Modal */
  const handleAddAlias = (_event: React.MouseEvent<HTMLButtonElement>) => {
    let updateAliasData
    // Create Alias Update PUT Object
    if (existingMaterial !== null) {
      updateAliasData = {
        divisionId: existingMaterial['DivisionId'],
        materialMappingId: existingMaterial['MaterialMappingId'],
        aliasName: existingTextFieldValue,
      }
    }
    // Prep data for Success Modal
    setSuccessData({
      ...currentMaterial,
      aliases: [existingTextFieldValue],
    })
    sendMaterialData(updateAliasData as IAliasUpdate, MaterialRequestType.Alias)
  }

  /** Handles switching to editing the duplicate material when prompted */
  const handleEditMaterial = () => {
    const switchedMaterial =
      existingMaterial && convertExistingMaterialKeys(existingMaterial)
    if (switchedMaterial) {
      switchedMaterial.divisionName = currentMaterial?.divisionName // Division name is always null in existingMaterial
      setSelectedMaterial(switchedMaterial as IMaterial)
      setCurrentView(MaterialManagerComponent.EditMaterial)
      setMaterialPostObject(switchedMaterial as IMaterial)
      /** Set number of fields to be rendered based on Aliases */
      let aliasArray: number[] = []
      for (let i = 0; i < (switchedMaterial.aliases as string[]).length; i++) {
        aliasArray.push(i)
      }
      setAliasFields(aliasArray)
      setAliasFieldValues(switchedMaterial.aliases as string[])
      setModalOpen(false)
    }
  }

  /** Create assigned plant data for display in modal */
  useEffect(() => {
    if (!plantData) return
    currentMaterial &&
      setCurrentMaterialAssignedPlants(
        getPlantNamesForCurrentMaterial(plantData, currentMaterial)
      )
    existingMaterial &&
      setExistingMaterialAssignedPlants(
        getPlantNamesByMaterialId(plantData, existingMaterial?.MaterialId)
      )
  }, [currentMaterial, existingMaterial, plantData])

  return (
    <MaterialManagerModalPresentational
      modalType={modalType}
      existingMaterial={existingMaterial}
      currentMaterial={currentMaterial}
      currentMaterialAssignedPlants={currentMaterialAssignedPlants}
      existingMaterialAssignedPlants={existingMaterialAssignedPlants}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleCancel={handleCancel}
      handleSaveAliases={handleSaveAliases}
      handleAddAlias={handleAddAlias}
      handleEditMaterial={handleEditMaterial}
      currentTextFieldValue={currentTextFieldValue}
      existingTextFieldValue={existingTextFieldValue}
      successData={successData}
      handleExistingTextFieldChange={handleExistingTextFieldChange}
      handleCurrentTextFieldChange={handleCurrentTextFieldChange}
      duplicateAliasModalError={duplicateAliasModalError}
      errorMessage={errorMessage}
    />
  )
}

export default MaterialManagerModalLogical
