import React from 'react'
import PropTypes from 'prop-types'
import { UpcomingProjectsTablePresentational } from './UpcomingProjectsTablePresentational'

export const UpcomingProjectsTableLogical = props => {
  UpcomingProjectsTableLogical.propTypes = {
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** The table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** upcoming project data that has been returned after being filtered */
    filteredUpcomingProjects: PropTypes.array.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
  }

  const {
    setSelectedRows,
    selectedRows,
    filteredUpcomingProjects,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
  } = props

  /** Function to sort the table rows */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
  }

  /** Function to handle selecting table rows */
  const handleClick = (event, row) => {
    /** Turn on the loading spinner until the selected project is highlighted on the map */
    setIsLoading(true)
    setTimeout(() => {
      const selectedIndex = selectedRows.indexOf(row)
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, row)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1))
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        )
      }
      setSelectedRows(newSelected)
      /** Turn off the loading spinner once the selected project is highlighted on the map */
      setIsLoading(false)
    }, 1000)
  }

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <UpcomingProjectsTablePresentational
      handleRequestSort={handleRequestSort}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      selectedRows={selectedRows}
      page={page}
      order={order}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      filteredUpcomingProjects={filteredUpcomingProjects}
    />
  )
}
