import {
  AppBar,
  Container,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { useEffect } from 'react'
import { Route, Switch, useRouteMatch, Link, Redirect } from 'react-router-dom'
import InventoryView from './InventoryView'
import PCBView from './PCBView'

const useStyles = makeStyles({
  header: {
    paddingLeft: '5%',
    paddingRight: '5%',
    marginBottom: '1%',
    boxShadow: '0px 2px 2px grey',
  },
})

function LegacyPCBMainPage() {
  const classes = useStyles()
  const { url } = useRouteMatch()

  const tabIndexAndUrlMap = {
    '/': 0,
    '/inventory': 1,
  }

  useEffect(() => {}, [url])

  return (
    <>
      <AppBar color="transparent" position="static" className={classes.header}>
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item>
            <Tabs value={tabIndexAndUrlMap[url]} indicatorColor="primary">
              <Tab label="Management" component={Link} to="/PCB/management" />
              <Tab label="Inventory" component={Link} to="/PCB/inventory" />
            </Tabs>
          </Grid>
          <Grid item xs={3}>
            <Typography style={{ textAlign: 'right' }} variant="h1">
              PCB
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
      <Container>
        <Switch>
          <Route path="/PCB/management">
            <PCBView />
          </Route>
          <Route path="/PCB/inventory">
            <InventoryView />
          </Route>
        </Switch>
        <Redirect from="/PCB" to="/PCB/management" />
      </Container>
    </>
  )
}

export default LegacyPCBMainPage
