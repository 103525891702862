import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MapViewPresentational from './MapViewPresentational'

MapViewLogical.propTypes = {
  /** Data table rows that have been selected */
  selectedRows: PropTypes.array.isRequired,
  /** Filtered Echo data */
  filteredCorpData: PropTypes.array.isRequired,
  /** Flat array of companies, used for the map markers */
  companyMarkers: PropTypes.array.isRequired,
  /** Array of filtered upcoming project data */
  filteredUpcomingProjects: PropTypes.array,
  /** Filtered array of cement plants */
  filteredCementPlants: PropTypes.array,
  /** Coordinates of zip code that was selected in "FilterBy" */
  zipCodeCoordinates: PropTypes.array,
  /** Boolean to display or not display loading spinner */
  isLoading: PropTypes.bool,
  /** Filtered array of CO2 depots */
  filteredCO2Depots: PropTypes.array,
  /** Array of CO2 depots */
  co2Depots: PropTypes.array,
  /** Filtered array of sales opportunities */
  filteredSalesOpportunities: PropTypes.array,
  /** Array of data to display on the ready mix table. */
  readyMixTableData: PropTypes.array.isRequired,
}

function MapViewLogical(props) {
  const {
    selectedRows,
    filteredCorpData,
    companyMarkers,
    filteredUpcomingProjects,
    filteredCementPlants,
    zipCodeCoordinates,
    isLoading,
    filteredCO2Depots,
    co2Depots,
    filteredSalesOpportunities,
    readyMixTableData,
  } = props

  /** Boolean to determine whether or not to display the map legend */
  const [showMapLegend, setShowMapLegend] = useState(true)

  /** Function to hide/show the map legend */
  const toggleMapLegend = () => setShowMapLegend(!showMapLegend)

  return (
    <MapViewPresentational
      selectedRows={selectedRows}
      filteredCorpData={filteredCorpData}
      companyMarkers={companyMarkers}
      filteredUpcomingProjects={filteredUpcomingProjects}
      filteredCementPlants={filteredCementPlants}
      zipCodeCoordinates={zipCodeCoordinates}
      isLoading={isLoading}
      showMapLegend={showMapLegend}
      toggleMapLegend={toggleMapLegend}
      filteredCO2Depots={filteredCO2Depots}
      co2Depots={co2Depots}
      filteredSalesOpportunities={filteredSalesOpportunities}
      readyMixTableData={readyMixTableData}
    />
  )
}

export default MapViewLogical
