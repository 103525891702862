////////////////////////
// Imperial to Metric //
////////////////////////
export const Yd3ToM3 = 0.764554857984
export const LbToKg = 0.45359237
export const OzToLitre = 0.0295735295625
export const GallonToLitre = 3.785411784
export const PsiToMPa = 0.006894757293168
export const LbPerYd3ToKgPerM3 = 0.593276421
export const OzPerYd3ToLitrePerM3 = 0.038680716
export const GallonPerYd3ToLitrePerM3 = 4.951131687139
export const InToMm = 25.4
export const LbPerFt3ToKgPerM3 = 16.01846337396

////////////////////////
// Metric to Imperial //
////////////////////////
export const M3ToYd3 = 1.307950547
export const KgToLb = 2.204623
export const MlToLitre = 0.001

export const KPaToPSI = 0.1450373773
