import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 'bold',
  },
  formGroup: {
    padding: '3em',
    width: '100%',
  },
}))

function ServiceCaseOverview(props) {
  ServiceCaseOverview.propTypes = {
    /** The service case displayed when the modal opens: existing or new */
    serviceCaseSource: PropTypes.object,

    hmiId: PropTypes.number,

    systemDownDateObj: PropTypes.instanceOf(Date),
    handleChangeSystemDown: PropTypes.func.isRequired,
    systemUpDateObj: PropTypes.instanceOf(Date),
    handleChangeSystemUp: PropTypes.func.isRequired,
  }
  const {
    serviceCaseSource,
    hmiId,
    inputClassName,
    systemDownDateObj,
    handleChangeSystemDown,
    systemUpDateObj,
    handleChangeSystemUp,
    disabled,
  } = props

  const { register, errors, watch, setValue } = useFormContext()

  const classes = useStyles()

  return (
    <>
      <Grid item container>
        <Typography variant="h3" className={classes.subtitle}>
          Overview
        </Typography>
      </Grid>
      <Paper elevation={0} className={classes.formGroup}>
        <Grid item container justify="space-between" lg={12}>
          <Grid item lg={5}>
            <TextField
              className={inputClassName}
              disabled
              label="Case"
              variant="outlined"
              size="small"
              defaultValue={
                serviceCaseSource?.serviceCaseId &&
                `CS-${serviceCaseSource.serviceCaseId}`
              }
              InputLabelProps={{ shrink: true }}
              placeholder="Generated Automatically"
            />
            <TextField
              className={inputClassName}
              disabled
              label="Location"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              defaultValue={serviceCaseSource.customerLocation}
              placeholder="Added Automatically Based On Unit Location"
            />
            <TextField
              disabled={disabled}
              className={inputClassName}
              label="System"
              name="system"
              size="small"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              inputRef={register}
              error={!!errors?.system}
              helperText={errors?.system?.message}
            />
            <TextField
              disabled={disabled}
              className={inputClassName}
              label="Case Opened"
              name="caseOpened"
              size="small"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputRef={register}
              error={!!errors?.caseOpened}
              helperText={errors?.caseOpened?.message}
            />
            <TextField
              disabled={disabled}
              className={inputClassName}
              label="Case Closed"
              name="caseClosed"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputRef={register}
              error={!!errors?.caseClosed}
              helperText={errors?.caseClosed?.message}
              type="date"
            />
            <TextField
              className={inputClassName}
              label="Days to resolve"
              size="small"
              variant="outlined"
              placeholder="Calculated Automatically"
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Grid>
          <Grid item lg={5}>
            <TextField
              className={inputClassName}
              label="HMI ID"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              disabled
              defaultValue={hmiId}
            />

            <DateTimePicker
              className={inputClassName}
              label="System Down Time"
              id="systemDown"
              name="systemDown"
              size="small"
              showTodayButton
              disabled={disabled}
              InputLabelProps={{ shrink: true }}
              autoOk
              clearable
              onChange={handleChangeSystemDown}
              value={systemDownDateObj}
              inputVariant="outlined"
            />
            <DateTimePicker
              id="systemUp"
              className={inputClassName}
              label="System Up Time"
              showTodayButton
              autoOk
              disabled={disabled}
              InputLabelProps={{ shrink: true }}
              clearable
              value={systemUpDateObj}
              onChange={handleChangeSystemUp}
              name="systemUp"
              size="small"
              inputVariant="outlined"
            />

            <TextField
              disabled={disabled}
              className={inputClassName}
              label="Replacement Parts Needed Date"
              name="replacementPartsNeededDate"
              size="small"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputRef={register}
              error={!!errors?.replacementPartsNeededDate}
              helperText={errors?.replacementPartsNeededDate?.message}
            />
            <TextField
              disabled={disabled}
              className={inputClassName}
              label="Parts Shipped Date"
              name="partsShippedDate"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              type="date"
              inputRef={register}
              error={!!errors?.partsShippedDate}
              helperText={errors?.partsShippedDate?.message}
            />
            <TextField
              disabled={disabled}
              className={inputClassName}
              label="Parts Received Date"
              name="partsReceivedDate"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              type="date"
              inputRef={register}
              error={!!errors?.partsReceivedDate}
              helperText={errors?.partsReceivedDate?.message}
            />
            <FormControlLabel
              label="Unique Issue"
              name="uniqueIssue"
              disabled={disabled}
              checked={watch('uniqueIssue')}
              onChange={e => setValue('uniqueIssue', e.target.checked)}
              control={<Checkbox color="primary" />}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ServiceCaseOverview
