import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import ChartOptionHelpers from '../Data/ChartOptionHelpers'

/**
 *
 * @param {Object} props
 * @param {'lb' | 'kg'} props.unit
 * @param {String} props.title
 * @param { { x: Number, y: Number, color: '#90ed7d' | '#8085e9' | '#f15c80' | '#f7a35c' } []} props.data
 * @returns
 */
function CO2InjectedGraphPresentational(props) {
  const chartOptions = ChartOptionHelpers.defaultChartOptions(props)

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}

CO2InjectedGraphPresentational.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      color: PropTypes.string,
    })
  ).isRequired,
  unit: PropTypes.oneOf(['lb', 'kg']).isRequired,
  title: PropTypes.string,
}

export default CO2InjectedGraphPresentational
