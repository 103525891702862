import SnoozeIcon from '@material-ui/icons/Snooze'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import AutoGraphOutlinedIconGrey from '../Assets/AutoGraphOutlined_Grey.svg'
import AutoGraphOutlinedIcon from '../Assets/AutoGraphOutlined.svg'
import AccessAlarmsOutlinedIcon from '@material-ui/icons/AccessAlarmsOutlined'
import DownSystems from './DownSystems'
import Alarms from './Alarms'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'

interface IconMap {
  [key: string]: {
    icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    selectedIcon?: string
    unselectedIcon?: string
  }
}

export const iconMap: IconMap = {
  Overview: {
    selectedIcon: AutoGraphOutlinedIcon,
    unselectedIcon: AutoGraphOutlinedIconGrey,
  },
  Alarms: { icon: AccessAlarmsOutlinedIcon },
  'Down Systems': { icon: ReportProblemOutlinedIcon },
  Snoozed: { icon: SnoozeIcon },
}

export const tabsConfig = [
  {
    label: 'Overview',
    flag: 'showSonarOverview',
    content: 'Overview Content Goes Here',
    urlLabel: 'Overview',
  },
  {
    label: 'Alarms',
    flag: 'showSonarAlarms',
    component: Alarms,
    urlLabel: 'Alarms',
  },
  {
    label: 'Down Systems',
    flag: 'showSonarDownSystems',
    component: DownSystems,
    urlLabel: 'DownSystems',
  },
  {
    label: 'Snoozed',
    flag: 'showSonarSnoozed',
    content: 'Snoozed Alarms Content Goes Here',
    urlLabel: 'Snoozed',
  },
]
