import React from 'react'
import PropTypes from 'prop-types'
import InjectionTablePresentational from './InjectionTablePresentational.js'

function InjectionTableLogical(props) {
  InjectionTableLogical.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Data from the injection data endpoint */
    injectionData: PropTypes.array.isRequired,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Count obtained from the endpoint for injection data */
    count: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
  }
  const {
    handleTableSort,
    injectionData,
    count,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props

  /** Function to switch to a different table page */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to change the number of table rows displayed per page */
  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  /** Table column headers */
  const headCells = [
    {
      id: 'startTime',
      numeric: false,
      label: 'Date & Time',
    },
    {
      id: 'injectionTimeInSeconds',
      numeric: true,
      label: 'Length of Injection (s)',
    },
    {
      id: 'injectedCO2Kg',
      numeric: true,
      label: 'CO2 Injected (kg)',
    },
  ]

  return (
    <InjectionTablePresentational
      injectionData={injectionData}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleTableSort={handleTableSort}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      order={order}
      orderBy={orderBy}
      headCells={headCells}
    />
  )
}

export default InjectionTableLogical
