import React from 'react'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid, Snackbar, SnackbarContent, Typography } from '@material-ui/core'
import hm from 'highcharts/modules/heatmap.js'
import {
  freshPropertyLabelsWithImperialUnit,
  freshPropertyLabelsWithMetricUnit,
} from '../../Constants/AnalysisConstants'
import FreshPropertyDropdown from '../FreshPropertyDropdown'
import { FreshPropertyNumberKeys } from '../../Logic/Types'
import { baseColors } from '../../../theme/colors'
import { VariabilityGraphPage } from '../../../Common/Logic/Types'

hm(Highcharts)

interface VariabilityGraphPresentationalProps {
  /** Config settings for Highchart, includes array with graph data. */
  graphOptions: Options
  /** Currently selected property used for graph point color. */
  selectedProperty: FreshPropertyNumberKeys
  /** Change currently selected property used for graph point color. */
  setSelectedProperty: (...args: any) => any
  /** List of errors in the currently selected data samples. */
  dataErrors: Array<string>
  /** A function to set the ref */
  setRef: (...args: any) => any
  /** Whether or not the measurement system is metric */
  isMetric: boolean
  /** String indicating which page the variability graph is on */
  page: VariabilityGraphPage
  /** Boolean to determine whether or not the snackbar should be open */
  showSnackbar?: boolean
  /** Function to do something when snackbar is closing */
  handleSnackbarClose?: () => void
  /** Message to be shown in snackbar */
  snackbarMessage?: { id: string; message: string }
  /** Amount of time before the snackbar disappears */
  snackbarHideDuration?: number
}

export default function VariabilityGraphPresentational(
  props: VariabilityGraphPresentationalProps
) {
  const {
    graphOptions,
    selectedProperty,
    setSelectedProperty,
    dataErrors,
    setRef,
    isMetric,
    page,
    showSnackbar,
    handleSnackbarClose,
    snackbarHideDuration,
    snackbarMessage,
  } = props

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justify="flex-start"
      style={{ padding: 'lem' }}
    >
      <Grid
        item
        xs={12}
        style={{ alignContent: 'center', width: '100%', position: 'relative' }}
      >
        <Snackbar
          key={snackbarMessage?.id}
          open={showSnackbar}
          onClose={handleSnackbarClose}
          autoHideDuration={snackbarHideDuration}
          ClickAwayListenerProps={{ onClickAway: () => null }}
          style={{ position: 'absolute' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <SnackbarContent
            style={{ minWidth: '75px' }}
            message={snackbarMessage?.message}
          />
        </Snackbar>
        <HighchartsReact
          key={selectedProperty}
          highcharts={Highcharts}
          options={graphOptions}
          ref={setRef}
        />
      </Grid>
      {dataErrors.map((message, key) => (
        <Grid
          item
          key={key}
          xs={12}
          style={{ alignContent: 'center', width: '100%' }}
        >
          <Typography
            align="left"
            style={{ paddingLeft: '2%', color: baseColors.error.main }}
          >
            {message}
          </Typography>
        </Grid>
      ))}

      <Grid item xs={4} style={{ marginBottom: '10px', paddingLeft: '2%' }}>
        {page === VariabilityGraphPage.ANALYSIS && (
          <FreshPropertyDropdown
            propertyOptions={
              isMetric
                ? freshPropertyLabelsWithMetricUnit
                : freshPropertyLabelsWithImperialUnit
            }
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            labelName="Select Property"
          />
        )}
      </Grid>
    </Grid>
  )
}
