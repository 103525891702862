import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ServiceCasesPresentational from './ServiceCasesPresentational'
import { useRecoilValue } from 'recoil'
import { atomDateRange, atomRegion } from '../../../Common/engAtoms'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import ServiceCaseTableObject from '../Logic/ServiceCaseTableObject'

function ServiceCasesLogical(props) {
  ServiceCasesLogical.propTypes = {
    /** State setter to open and close the service case modal */
    setOpen: PropTypes.func.isRequired,
    /** The name of the selected tab */
    selectedTab: PropTypes.string.isRequired,
    /** The service case that has been selected */
    selectedServiceCase: PropTypes.instanceOf(ServiceCaseTableObject),
    /** State setter function to keep track of which service case has been selected */
    setSelectedServiceCase: PropTypes.func.isRequired,
    /** Boolean to determine whether to show closed service cases or not */
    showClosedCases: PropTypes.bool.isRequired,
    /** Function to set whether to show closed service cases or not */
    setShowClosedCases: PropTypes.func.isRequired,
  }

  const {
    setOpen,
    selectedTab,
    selectedServiceCase,
    setSelectedServiceCase,
    showClosedCases,
    setShowClosedCases,
  } = props

  /** Data from the service cases endpoint */
  const [serviceCases, setServiceCases] = useState([])

  const regionSettings = useRecoilValue(atomRegion)
  const dateRange = useRecoilValue(atomDateRange)

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('serviceCaseId')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tableRowCount, setTableRowCount] = useState(0)

  const handleTableSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /** Handle filters */
  useEffect(() => {
    async function getAndSetServiceCases() {
      const response = await AlarmsManagementDataHelpers.getServiceCasesTable({
        Limit: rowsPerPage,
        Offset: rowsPerPage * page,
        SortColumn: orderBy,
        SortOrder: order === 'asc' ? 'Ascending' : 'Descending',
        displayCanadaUs: regionSettings.canadaUS,
        displayInternational: regionSettings.international,
        startTime: dateRange.startTime ?? '',
        endTime: dateRange.endTime ?? '',
      })
      if (response.ok) {
        const { count, results } = await response.json()
        setServiceCases(
          results.map(serviceCase => new ServiceCaseTableObject(serviceCase))
        )
        setTableRowCount(count)
      }
    }
    getAndSetServiceCases()
  }, [
    showClosedCases,
    order,
    orderBy,
    page,
    rowsPerPage,
    regionSettings.international,
    regionSettings.canadaUS,
    dateRange.startTime,
    dateRange.endTime,
  ])

  /** Open the service case modal */
  const handleOpen = () => setOpen(true)

  return (
    <ServiceCasesPresentational
      handleTableSort={handleTableSort}
      order={order}
      orderBy={orderBy}
      page={page}
      rowsPerPage={rowsPerPage}
      selectedServiceCase={selectedServiceCase}
      setSelectedServiceCase={setSelectedServiceCase}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
      count={tableRowCount}
      selectedTab={selectedTab}
      setShowClosedCases={setShowClosedCases}
      showClosedCases={showClosedCases}
      handleOpen={handleOpen}
      serviceCases={serviceCases}
    />
  )
}

export default ServiceCasesLogical
