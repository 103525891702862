import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  atomCommissioningFilter,
  atomCurrentCustomer,
} from '../../../Common/atoms'
import CustomerTablePresentational from './CustomerTablePresentational'
import { CommissioningFilterOptions } from '../../Constants/CommonConstants'

function CustomerTableLogical(props) {
  CustomerTableLogical.propTypes = {
    rows: PropTypes.array,
    filteredRows: PropTypes.array,
    setFilteredRows: PropTypes.func,
    searched: PropTypes.string,
    setSearched: PropTypes.func,
    selectedTssRep: PropTypes.string,
  }

  const {
    rows,
    filteredRows,
    setFilteredRows,
    searched,
    setSearched,
    selectedTssRep,
  } = props
  /** Table sort order */
  const [order, setOrder] = useState('asc')
  /** Table column to sort by */
  const [orderBy, setOrderBy] = useState('divisionName')
  /** Table page being viewed */
  const [page, setPage] = useState(0)
  /** Number of table rows to display per page */
  const [rowsPerPage, setRowsPerPage] = useState(20)
  /** Atom with the selected customer from the table */
  const [selectedCustomer, setSelectedCustomer] = useRecoilState(
    atomCurrentCustomer
  )
  /** Boolean to determine whether the modal is open */
  const [open, setOpen] = useState(false)
  /** The table row that has had its Commissioning Complete checkbox clicked */
  const [commissioningRow, setCommissioningRow] = useState({})
  /** Boolean that indicates whether a customer has just been marked Commissioning Complete */
  const [isCommissioningComplete, setIsCommissioningComplete] = useState(false)
  /** Selected commissioning status filter */
  const commissioningFilter = useRecoilValue(atomCommissioningFilter)

  /** Handle customer page filters */
  useEffect(() => {
    setFilteredRows(rows)
    /** Filter by TSS rep */
    if (selectedTssRep) {
      setFilteredRows(currentRows =>
        currentRows.filter(row => row.tssRep === selectedTssRep)
      )
    }
    /** Filter by commissioning status */
    if (commissioningFilter) {
      setFilteredRows(currentRows => {
        if (
          commissioningFilter === CommissioningFilterOptions.InCommissioning
        ) {
          return currentRows.filter(row => row.inCommissioning)
        } else if (
          commissioningFilter ===
          CommissioningFilterOptions.CommissioningComplete
        ) {
          return currentRows.filter(row => !row.inCommissioning)
        }
      })
    }
    /** Filter by search term */
    if (searched.length > 0) {
      setFilteredRows(currentRows => {
        /** Convert the table rows to strings */
        const stringRows = currentRows.map(row => JSON.stringify(row))
        /** Check if any table rows (as strings) have data matching the query typed into the search bar */
        const filter = stringRows.filter(
          row => row.toLowerCase().indexOf(searched.toLowerCase()) > -1
        )
        /** Convert the filtered rows back into objects and display them */
        return filter.map(row => JSON.parse(row))
      })
    }
  }, [commissioningFilter, rows, searched, selectedTssRep, setFilteredRows])

  /** Function to sort the table */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
  }

  /** Function to handle selecting/unselecting table rows */
  const handleSelectRow = (event, row) => {
    if (selectedCustomer.division?.divisionId === row.divisionId) {
      setSelectedCustomer({
        division: null,
        plant: null,
      })
    } else {
      setSelectedCustomer(previousCustomer => ({
        ...previousCustomer,
        division: { divisionId: row.divisionId, name: row.divisionName },
        plant: null,
      }))
    }
  }

  /** Function to change pages on the table */
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  /** Function to update the number of table rows to display per page */
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  /** Function to open the modal */
  const openModal = sample => {
    setCommissioningRow(sample)
    setOpen(true)
  }

  return (
    <CustomerTablePresentational
      order={order}
      orderBy={orderBy}
      handleRequestSort={handleRequestSort}
      page={page}
      rowsPerPage={rowsPerPage}
      handleSelectRow={handleSelectRow}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rows={rows}
      filteredRows={filteredRows}
      setSearched={setSearched}
      open={open}
      setOpen={setOpen}
      openModal={openModal}
      commissioningRow={commissioningRow}
      setIsCommissioningComplete={setIsCommissioningComplete}
      isCommissioningComplete={isCommissioningComplete}
    />
  )
}

export default CustomerTableLogical
