import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DateRange, SonarDateRange } from '../../../../TSS/Logic/Types'
import { SnoozeDownSystemReason } from '../../../Logic/Types'
import snoozeDownSystemsModalHelper from './snoozeDownSystemsModalHelper'
import { isSystemSnoozed } from '../../Helpers/SonarHelpers'
import { addDays, formatISO } from 'date-fns'
import { ISonarDownSystem } from '../../../../Common/Logic/Types'

export interface IUseSnoozeDownSystemsModal {
  clickedDownSystem: ISonarDownSystem | null
  setClickedDownSystem: Dispatch<SetStateAction<ISonarDownSystem | null>>
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  setSnoozeSavedFlag: Dispatch<SetStateAction<boolean>>
  showSnoozeModal: boolean
  showSnoozeSuccessModal: boolean
  setShowSnoozeSuccessModal: Dispatch<SetStateAction<boolean>>
}

const useSnoozeDownSystemsModal = (props: IUseSnoozeDownSystemsModal) => {
  const {
    clickedDownSystem,
    setClickedDownSystem,
    setShowSnoozeModal,
    setSnoozeSavedFlag,
    showSnoozeModal,
    showSnoozeSuccessModal,
    setShowSnoozeSuccessModal,
  } = props

  const defaultSnoozeEnd = formatISO(addDays(new Date(), 7)).substring(0, 10)
  const isSnoozed = isSystemSnoozed(clickedDownSystem)
  const [reason, setReason] = useState<SnoozeDownSystemReason | null>(null)
  const [reasonDetails, setReasonDetails] = useState<string>('')
  const [rangeSelected, setRangeSelected] = useState<SonarDateRange | null>(
    SonarDateRange.SevenDays
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isSavingModal, setIsSavingModal] = useState<boolean>(false)
  const [snoozeEnd, setSnoozeEnd] = useState<DateRange>(defaultSnoozeEnd)
  const [snoozeEditDate, setSnoozeEditDate] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const defaultModalValuesLoaded = useRef(false)

  const handleSnoozeModalClose = useCallback(() => {
    setShowSnoozeModal(false)
    setReason(null)
    setReasonDetails('')
    setRangeSelected(SonarDateRange.SevenDays)
    setSnoozeEnd(defaultSnoozeEnd)
    setSnoozeEditDate(null)
    setClickedDownSystem(null)
    defaultModalValuesLoaded.current = false
    setErrorMessage(null)
  }, [defaultSnoozeEnd, setClickedDownSystem, setShowSnoozeModal])

  const handleSnoozeSuccessModalClose = useCallback(() => {
    handleSnoozeModalClose()
    setShowSnoozeSuccessModal(false)
  }, [handleSnoozeModalClose, setShowSnoozeSuccessModal])

  const handleSnoozeSuccessModalOpen = useCallback(() => {
    setShowSnoozeModal(false)
    setShowSnoozeSuccessModal(true)
  }, [setShowSnoozeModal, setShowSnoozeSuccessModal])

  const handleReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newReason = event.target.value as SnoozeDownSystemReason
      setReason(newReason)
      setReasonDetails('')
    },
    []
  )

  const handleReasonDetailsChange = useCallback((newReasonDetails: string) => {
    setReasonDetails(newReasonDetails)
  }, [])

  const handleConfirmSnooze = useCallback(async () => {
    setIsSaving(true)
    setErrorMessage(null)

    if (!clickedDownSystem) return
    if (isSnoozed) {
      try {
        await snoozeDownSystemsModalHelper.editSnooze(
          clickedDownSystem.snoozeId,
          reason,
          reasonDetails,
          snoozeEditDate
        )
        handleSnoozeModalClose()
        setSnoozeSavedFlag(prev => !prev)
      } catch (err) {
        console.error(err)
      } finally {
        setIsSaving(false)
      }
    } else {
      try {
        await snoozeDownSystemsModalHelper.saveSnooze(
          clickedDownSystem.remoteUnitId,
          clickedDownSystem.reclaimedWaterUnitId,
          reason,
          reasonDetails,
          snoozeEnd
        )
        handleSnoozeModalClose()
        setSnoozeSavedFlag(prev => !prev)
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          setErrorMessage(err.message)
        } else {
          setErrorMessage(String(err))
        }
      } finally {
        setIsSaving(false)
      }
    }
  }, [
    clickedDownSystem,
    handleSnoozeModalClose,
    isSnoozed,
    reason,
    reasonDetails,
    setSnoozeSavedFlag,
    snoozeEditDate,
    snoozeEnd,
  ])

  const handleUnsnooze = useCallback(async () => {
    setIsSavingModal(true)
    if (!clickedDownSystem) return
    try {
      await snoozeDownSystemsModalHelper.unSnooze(clickedDownSystem.snoozeId)
      handleSnoozeSuccessModalOpen()
      setSnoozeSavedFlag(prev => !prev)
    } catch (err) {
      console.error(err)
    } finally {
      setIsSavingModal(false)
    }
  }, [clickedDownSystem, handleSnoozeSuccessModalOpen, setSnoozeSavedFlag])

  const handleDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = event.target.value
      setSnoozeEditDate(newDate)
    },
    []
  )

  // Effects

  /* Use current data to populate input fields when editing snooze */
  useEffect(() => {
    if (!showSnoozeModal || defaultModalValuesLoaded.current) return

    const { snoozeReason, snoozeReasonDetails, snoozeEnd } =
      clickedDownSystem || {}

    if (snoozeReason && snoozeReason !== reason) {
      setReason(snoozeReason as SnoozeDownSystemReason)
    }

    if (snoozeReasonDetails && snoozeReasonDetails !== reasonDetails) {
      setReasonDetails(snoozeReasonDetails)
    }

    if (snoozeEnd && snoozeEnd !== snoozeEditDate) {
      setSnoozeEditDate(snoozeEnd)
    }

    defaultModalValuesLoaded.current = true
  }, [
    clickedDownSystem,
    reason,
    reasonDetails,
    showSnoozeModal,
    snoozeEditDate,
  ])

  return {
    reason,
    reasonDetails,
    rangeSelected,
    snoozeEnd,
    isSnoozed,
    snoozeEditDate,
    showSnoozeSuccessModal,
    setRangeSelected,
    setSnoozeEnd,
    setSnoozeEditDate,
    handleSnoozeModalClose,
    handleReasonChange,
    handleReasonDetailsChange,
    handleConfirmSnooze,
    handleDateChange,
    handleUnsnooze,
    handleSnoozeSuccessModalOpen,
    handleSnoozeSuccessModalClose,
    setShowSnoozeSuccessModal,
    isSaving,
    isSavingModal,
    errorMessage,
  }
}

const hook = {
  useSnoozeDownSystemsModal,
}

export default hook
