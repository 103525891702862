import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'

interface IComponentProps {
  value: string
  handleCancel(): (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit(): (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChange(): (event: React.ChangeEvent<HTMLInputElement>) => void
}

function PlantsConfirmationPresentational(props: IComponentProps) {
  const { value, handleCancel, handleSubmit, handleChange } = props

  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle id="plantsConfirmationTitle">Confirm Change</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>
            <Typography variant="body2">
              Add a note about why this value was changed
            </Typography>
          </AlertTitle>
        </Alert>

        <Box mt={3}>
          <TextField
            variant="outlined"
            value={value}
            onChange={handleChange}
            multiline
            rows={3}
          ></TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="primary"
          id="plantsConfirmationCancelButton"
        >
          Go Back
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          id="plantsConfirmationSubmitButton"
        >
          Confirm Change
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlantsConfirmationPresentational
