import React from 'react'
import ProjectDropdownPresentational from './ProjectDropdownPresentational'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import cloneDeep from 'lodash.clonedeep'
import { EMapLayers } from '../../Enums/EchoEnums'

function ProjectDropdownLogical() {
  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  const projects = [
    'Upcoming Projects',
    'Ongoing Projects',
    'Finished Projects',
  ]

  /** Handle input change to update project map layers */
  const handleChange = (event, value) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      newSearchValues.projects = value
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      newEchoSettings.projects = value
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <ProjectDropdownPresentational
      options={projects}
      handleChange={handleChange}
    />
  )
}

export default ProjectDropdownLogical
