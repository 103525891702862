import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slider, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import { isProjectSearchActive } from '../../Logic/FilterLogicHelpers'

NumberOfPlantsSlider.propTypes = {
  /** Unfiltered Echo corporation data */
  corpData: PropTypes.array.isRequired,
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  sliderContainer: {
    maxWidth: 'calc(100% - 40px)',
    margin: '0 auto',
  },
})

function NumberOfPlantsSlider(props) {
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  const { corpData } = props

  const classes = useStyles()

  /** The maximum number of plants that any corporation has */
  const [maxPlants, setMaxPlants] = useState(600)
  /** The slider display values, updated as the slider is moved. */
  const [sliderRangeInternal, setSliderRangeInternal] = useState([])

  /** Find the maximum number of plants that any corporation has. */
  let maxPlantsTotal = null
  if (corpData.length > 0) {
    maxPlantsTotal = corpData
      .map(company => company.plantCount)
      .sort((a, b) => b - a)[0]
  }

  /** When corpData is loaded, set the maximum value of the slider to be the maximum number of plants that any corporation has. */
  useEffect(() => {
    if (corpData.length) {
      setMaxPlants(maxPlantsTotal)
      setSliderRangeInternal([0, maxPlantsTotal])
    }
  }, [corpData, maxPlantsTotal])

  /** If the project search is being used or the Echo filters are cleared, reset the slider. */
  useEffect(() => {
    if (
      isProjectSearchActive(echoSettings) ||
      !echoSettings.plantsLimits.length
    ) {
      setSliderRangeInternal([0, maxPlantsTotal])
    }
  }, [echoSettings, maxPlantsTotal])

  return (
    <Box className={classes.root}>
      <Box className={classes.sliderContainer}>
        <Typography id="range-slider" gutterBottom>
          Number of plants
        </Typography>
        <Slider
          id="numPlantsSlider"
          style={{ marginTop: '35px' }}
          max={maxPlants}
          valueLabelDisplay="on"
          onChange={(event, newValue) => setSliderRangeInternal(newValue)}
          onChangeCommitted={(event, newValue) => {
            const newSettings = cloneDeep(echoSettings)
            newSettings.plantsLimits = cloneDeep(newValue)
            setEchoSettings(newSettings)
          }}
          value={sliderRangeInternal}
          disabled={isProjectSearchActive(echoSettings)}
        />
      </Box>
    </Box>
  )
}

export default NumberOfPlantsSlider
