import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import HistogramPresentational from './HistogramPresentational'
import { getGraphData } from '../../Data/TSSDataHelpers'
import {
  getGraphWatermark,
  preserveWatermarkAspectRatio,
} from '../../Logic/TSSLogic'

/** Component for processing histogram data which generates final result
 * that can be fed to the presentational component
 */
export default function HistogramLogical(props) {
  HistogramLogical.propTypes = {
    /** The selected variations of mix group table */
    selectedMixVariations: PropTypes.array.isRequired,
  }

  const {
    selectedMixVariations,
    selectedAge,
    isFilterPanelOpen,
    isMetric,
  } = props
  const [graphData, setGraphData] = useState([])
  const [noSampleDataMsg, setNoSampleDataMsg] = useState([])
  const [graphWaterMark, setGraphWaterMark] = useState(null)
  const [node, setRef] = useState(null)

  /** Updates graphData with a series array for Highcharts  */
  useEffect(() => {
    let messages = []
    setGraphWaterMark(getGraphWatermark(selectedMixVariations))
    setGraphData(getGraphData([...selectedMixVariations], selectedAge))
    setNoSampleDataMsg(messages)
  }, [selectedMixVariations, selectedAge])

  useEffect(() => {
    const watermarkedImage = document.querySelector(
      '.highcharts-container image'
    )
    preserveWatermarkAspectRatio(node, watermarkedImage)
  }, [node])

  // reflow chart to fit width when panel opens/closes
  useEffect(() => {
    let timer = setTimeout(() => {
      const graph = node?.chart
      if (graph) graph.reflow(false)
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [node, isFilterPanelOpen])
  return (
    <HistogramPresentational
      mixVariationSelectedToggle={selectedMixVariations.length > 0}
      graphData={graphData}
      noSampleDataMsg={noSampleDataMsg}
      graphWaterMark={graphWaterMark}
      setRef={setRef}
      isMetric={isMetric}
    />
  )
}
