import { AppBar, Grid, makeStyles, Hidden, Icon, Tabs } from '@material-ui/core'
import carbonCureLogo from '../assets/OrcaLogo.png'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import NavDrawer from './NavDrawer'
import NavMenuItem from './NavMenuItem'
import React, { useState } from 'react'
import AllDepartmentObjects from '../assets/projectLists'
import {
  getDepartmentNameFromLocation,
  getFilteredNavLinks,
} from '../Helpers/NavBarLogic'
import { atomJWT } from '../atoms'
import { useRecoilValue } from 'recoil'

const useStyles = makeStyles({
  logo: {
    maxHeight: '1.4em',
    marginLeft: '0.3em',
  },
  listItem: {
    width: 'auto',
  },
  navAppBar: {
    maxHeight: '3.5em',
    zIndex: '1',
  },
  appBar: {
    borderBottom: '1px solid rgba(112,112,112,0.35)',
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
  },
})

NavBar.propTypes = {
  /** List of page names and URLs to populate nav bar - { title: string, path: string }  */
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.string,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          path: PropTypes.string,
          subPaths: PropTypes.array,
        })
      ),
      featuresOfInterest: PropTypes.array,
    })
  ),
}

/**
 * Nav bar component to be reused throughout Orca project.
 * @param {Object} navLinks - list of page names and URLs to populate nav bar.
 */
function NavBar(props) {
  const { navLinks } = props
  const [tabValue, setTabValue] = useState(null)
  const classes = useStyles()
  const currentPath = useLocation()?.pathname

  /** User's JSON Web Token (JWT) from localStorage */
  const JWT = useRecoilValue(atomJWT)

  const filteredNavLinks = getFilteredNavLinks(JWT, navLinks)

  const handleTabChange = (event, value) => {
    setTabValue(value)
  }

  return (
    <AppBar position="static" color="inherit" className={classes.appBar}>
      <Grid container justify="center">
        <Grid
          container
          direction="row"
          item
          xs={12}
          sm={11}
          md={10}
          xl={9}
          alignContent="center"
          alignItems="center"
          className={classes.navAppBar}
        >
          <Grid item xs={2}>
            <Icon edge="end" color="inherit">
              <img
                alt="CarbonCure Logo"
                src={carbonCureLogo}
                className={classes.logo}
              />
            </Icon>
          </Grid>
          <Grid item xs={10} container justify="flex-end">
            <Hidden smDown>
              <Tabs
                indicatorColor="primary"
                //Uses the currently clicked tab, otherwise uses the path
                value={tabValue || getDepartmentNameFromLocation(currentPath)}
                onChange={handleTabChange}
              >
                {filteredNavLinks.map(project => (
                  /* I set the value here on the NavMenuItem component, even though there's no 
                  prop for it in the component, so it'll be recognized by the Tabs component */
                  <NavMenuItem
                    menuItem={project}
                    setTabsValue={setTabValue}
                    value={project.department}
                    key={project.department}
                  />
                ))}
              </Tabs>
            </Hidden>
            <Hidden mdUp>
              <NavDrawer navLinks={AllDepartmentObjects} />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default NavBar
