import {
  ConvertCtoFUnrounded,
  ConvertFtoCUnrounded,
  ConvertINCHtoMM,
  ConvertLbPerYd3ToKgPerM3Unrounded,
  ConvertMMtoINCHUnrounded,
  ConvertMPAToPSIUnrounded,
  ConvertMPaKgToPSILbUnrounded,
  ConvertMilliLitrePerM3ToOzPerYd3Unrounded,
  ConvertOzPerYd3ToMilliLitrePerM3Unrounded,
  ConvertPSILbToMPAKgUnrounded,
  ConvertPSIToMPAUnrounded,
  convertKgM3ToLbYd3,
  convertKgM3ToLbYd3Unrounded,
  roundUpToDecimal,
} from '../../Common/Helpers/GeneralHelpers'
import {
  CellBehavior,
  FilterOption,
  SimpleTableTypes,
} from '../../Common/Logic/Types'
import { translatedCementTypes } from '../../Echo/Constants/Constants'
import { getCO2DosageUnitLabel, getFormattedDate } from '../Logic/TSSLogic'
import {
  BaleenCementEfficiency,
  BaleenIntervals,
  BaleenMixVariation,
  BaleenModalData,
  BaleenOutlierReasons,
  BaleenOutlierStatus,
  BaleenOutlierType,
  BaleenSample,
  BaleenStrengths,
  BaleenYAxis,
  CO2DosageUnit,
  KelownaBaleenMixGroup,
  KelownaBaleenMixVariation,
  KelownaBaleenSample,
  MixGroupConditions,
  PropertyUnit,
  VariationTypes,
} from '../Logic/Types'
import cloneDeep from 'lodash.clonedeep'
import {
  IAveragePerInterval,
  IURLParamSettings,
  PropertiesToAverage,
  ISampleAverages,
  ISubmitErrors,
  IBaleenSamplesObj,
  IBaleenVariationObj,
} from '../Views/BaleenView'
import { baseColors } from '../../theme/colors'
import { dataPointsSymbols } from '../Constants/AnalysisConstants'
import { Options, SeriesScatterOptions } from 'highcharts'
import { weightUnits } from '../Constants/AddDataConstants'

export interface IParamObj {
  selected: BaleenMixVariation
  selectedProperty: BaleenYAxis
  highChartsSeries: any
  noSampleData: string[]
  outlierStatus: BaleenOutlierStatus
  selectedInterval: BaleenIntervals
  min: number
  max: number
  isMetric: boolean
}

interface IVariationCreationVariablesObj {
  variation: KelownaBaleenMixVariation // an undigested variation
  variationId: string // the variation id of the variation (not shown in ui)
  variationIdLabel: string // the variation id label (shown in ui)
  averages: { [key: string]: { total: number | null; count: number } } // an object containing a total and a count for each property to be averaged
  variationSamples: { [key: number]: BaleenSample } // an object containing digested samples that belong to the variation. the keys are the batchIds of the sample it is paired with
  mixDesignId: number // the mix design id of the mix group
  hasSampleCO2Dosage: boolean // whether or not a sample (not an outlier) of the variation has a cO2Dosage
  hasSampleCementReduction: boolean // whether or not a sample (not an outlier) of the variation has a value for cement reduction
  isMetric: boolean // whether or not the unit system is metric
}

interface ICO2DosageAndCementReductionInfo {
  cO2Dosage: number | null
  cO2DosageUnit: CO2DosageUnit
  cementReduction: number | null
}

export const baleenMixVariationColors = [
  baseColors.primary.light,
  'rgba(186, 104, 200, 1)',
  'rgba(139, 195, 74, 1)',
  'rgba(77, 182, 172, 1)',
  'rgba(66, 122, 152, 1)',
  'rgba(0, 77, 64, 1)',
]

export const baleenMixVariationHaloColors = [
  baseColors.primary.twentySixMainOpacity,
  'rgba(186, 104, 200, 0.26)',
  'rgba(139, 195, 74, 0.26)',
  'rgba(77, 182, 172, 0.26)',
  'rgba(66, 122, 152, 0.26)',
  'rgba(0, 77, 64, 0.26)',
]

export const BALEEN_SELECTED_VARIATIONS_LIMIT = 6

export const BALEEN_MAX_VARIATION_COLUMNS = 4 // not including variation ID column

export const baleenImperialAxisLabels = {
  slump: 'Slump (in)',
  air: 'Air Content (%)',
  concreteTemperature: 'Concrete Temperature (F)',
  batchWaterCementRatio: 'Water-Cement Ratio',
  batchStrength: 'Batch Strength (psi)',
  batchInterval: 'Interval (Days)',
  testCategory: 'Test Category',
  cementContent: `Cement Content (${weightUnits.LbPerYd3})`,
  cO2Dosage: 'CO₂ Dosage',
}

export const baleenMetricAxisLabels = {
  slump: 'Slump (mm)',
  air: 'Air Content (%)',
  concreteTemperature: 'Concrete Temperature (C)',
  batchWaterCementRatio: 'Water-Cement Ratio',
  co2Dosage: 'CO₂ Dosage',
  batchStrength: 'Batch Strength (MPa)',
  testCategory: 'Test Category',
  cementContent: `Cement Content (${weightUnits.KgPerM3})`,
  cO2Dosage: 'CO₂ Dosage',
}

export const outlierReasonOptions = {
  InconsistentCementitiousLoading: 'Inconsistent cementitious loading',
  InconsistentNoncementitiousLoading: 'Inconsistent noncementitious loading',
  UnexpectedSlump: 'Unexpected slump',
  UnexpectedAirContent: 'Unexpected air content',
  UnexpectedConcreteTemperature: 'Unexpected concrete temperature',
  CuringConditions: 'Curing conditions',
}

export const dataColumnOptions: FilterOption[] = [
  {
    id: 'slump',
    name: 'Slump',
  },
  {
    id: 'concreteTemperature',
    name: 'Temp.',
  },
  {
    id: 'cO2DosageLabel',
    name: `${MixGroupConditions.CO2} Dose`,
  },
  {
    id: 'strengths',
    name: 'Strength',
  },
  {
    id: 'air',
    name: 'Air',
  },
  {
    id: 'cementEfficiency',
    name: 'Cmt. Eff.',
  },
  {
    id: 'cementContent',
    name: 'Avg. Cmt. Load.',
  },
  {
    id: 'densityKgPerM3',
    name: 'Unit Weight',
  },
]

/**
 *
 * @param {String[]} selectedInterval an array containing (or not) the selected interval in hours
 * @returns a string label for the selected interval
 */
export const getIntervalString = (selectedInterval: string | number) => {
  if (!selectedInterval || isNaN(Number(selectedInterval))) return
  let intervalString: string
  if (Number(selectedInterval) > 23) {
    const days = Number(selectedInterval) / 24
    intervalString = days === 1 ? '1 Day' : days + ' Days'
  } else {
    const hours = Number(selectedInterval)
    intervalString = hours === 1 ? '1 Hour' : hours + ' Hours'
  }
  return intervalString
}

export const getIntervalHeader = (selectedInterval: string | number) => {
  if (!selectedInterval) return 'Strength'
  let intervalString: string
  if (Number(selectedInterval) > 23) {
    intervalString = Number(selectedInterval) / 24 + '-Day Strength'
  } else {
    intervalString = Number(selectedInterval) + '-Hour Strength'
  }
  return intervalString
}

/**
 * Used for the baleen variability graph missing data error text
 * @param {String[]} selectedInterval an array containing (or not) the selected interval in hours
 * @returns an interval string
 */
export const getVariabilityGraphIntervalErrorString = (
  selectedInterval: string
) => {
  if (!selectedInterval) return 'Strength'
  let intervalString: string = ''
  const intervalNumber = Number(selectedInterval)
  if (intervalNumber > 0 && intervalNumber < 24) {
    intervalString = intervalNumber + ' hour'
  } else if (intervalNumber > 23) {
    intervalString = intervalNumber / 24 + ' day'
  }
  return intervalString
}

/**
 * Function to get the rounding precision for a property in the variaibility graph data point's tooltip
 * @param {BaleenYAxis} property the yAxis for the baleen variability graph
 * @param {boolean} isMetric whether or not the measurement system is in metric
 * @returns {String} precision format for highcharts tooltip based on property and measurement system
 */
export const getBaleenTooltipBatchSampleUnitPrecision = (
  property: BaleenYAxis,
  isMetric: boolean,
  cO2DosageUnit?: CO2DosageUnit
) => {
  switch (property) {
    case 'slump':
      return isMetric ? 0 : 1
    case 'air':
      return 1
    case 'concreteTemperature':
      return 2
    case 'batchWaterCementRatio':
      return 3
    case 'batchStrength':
      return isMetric ? 2 : 0
    case 'cementContent':
      return 0
    case 'cO2Dosage':
      if (!cO2DosageUnit) return 3
      else if (cO2DosageUnit === 'PercentOfCement') return 3
      else if (cO2DosageUnit === 'LitrePerM3' && isMetric) return 0
      else if (cO2DosageUnit === 'LitrePerM3' && !isMetric) return 2
      return 3
    default:
      return 0
  }
}

/**
 * A function that returns the header cells for a table
 * @param {String} tableType the type of table (sample or outlier)
 * @param {String[]} selectedInterval an array containing (or not) the selected interval in hours
 * @returns {Object[]} header cells for the a table
 */
export const getBaleenSampleHeaders = (
  tableType: SimpleTableTypes,
  isMetric: boolean,
  selectedInterval?: string
) => {
  const intervalString = !selectedInterval
    ? 'Strength'
    : getIntervalHeader(selectedInterval)
  const headCells = []
  if (tableType === SimpleTableTypes.BaleenOutliersTable)
    headCells.push({
      id: 'cancelOrEdit',
      width: '50px',
    })
  headCells.push(
    ...[
      {
        id: 'variationIdLabel',
        width: '120px',
        name: 'Variation ID',
        sortable: true,
      },
      {
        id: 'ticketId',
        width: '90px',
        name: 'Ticket ID',
        sortable: true,
      },
      {
        id: 'outlier',
        width: '70px',
        name: 'Outlier',
      },
    ]
  )

  if (tableType === SimpleTableTypes.BaleenOutliersTable)
    headCells.push({
      id: 'outlierReason',
      width: '264px',
      name: 'Reason',
      sortable: false,
    })
  headCells.push(
    ...[
      {
        id: 'productionDate',
        width: '90px',
        name: 'Prod. Date',
        sortable: true,
        tooltip: 'Production Date',
      },
      {
        id: 'slump',
        width: '70px',
        name: `Slump (${isMetric ? 'mm' : 'in'})`,
        sortable: true,
      },
      {
        id: 'air',
        width: '70px',
        name: 'Air (%)',
        sortable: true,
      },
      {
        id: 'cO2Dosage',
        width: '70px',
        name: 'CO₂',
        sortable: true,
      },
      {
        id: 'cO2DosageUnit',
        width: '70px',
        name: 'CO₂ Dos. Unit',
        sortable: true,
      },
      {
        id: 'cementContent',
        width: '100px',
        name: `Cmt Cont. (${
          isMetric ? weightUnits.KgPerM3 : weightUnits.LbPerYd3
        })`,
        sortable: true,
        tooltip: 'Cement Content',
      },
      {
        id: 'scmPercent',
        width: '70px',
        name: 'SCM (%)',
        sortable: true,
        tooltip: 'Supplementary Cementitious Material %',
      },
      {
        id: 'strengths',
        width: '90px',
        name: `${intervalString} (${isMetric ? 'MPa' : 'psi'})`,
        sortable: true,
      },
      {
        id: 'cylinderCount',
        width: '70px',
        name: '# Cyl.',
        sortable: true,
        tooltip: '# Cylinders',
      },
      {
        id: 'cementType',
        width: '90px',
        name: 'Cement Type',
        sortable: true,
      },
      {
        id: 'cementSupplier',
        width: '120px',
        name: 'Cement Source',
        sortable: true,
      },
    ]
  )
  return headCells
}

/**
 * iterate through the properties and, if the value is not null, add it to the stored total and increment the count for that property.
 * preparing to average for variation level
 * @param {Object} sample a disgested sample
 * @param {Object} averages an object containing a total and a count for each property to be averaged
 * @param {Array} propertiesToAverage an array of strings. the strings are keys/properties on the sample that need to be averaged
 */
export const accumulatePropertyDataForAveraging = (
  sample: BaleenSample,
  averages: ISampleAverages,
  propertiesToAverage: PropertiesToAverage[]
) => {
  for (const property of propertiesToAverage) {
    if (sample[property] !== null) {
      averages[property].total =
        averages[property].total === null
          ? sample[property]
          : averages[property].total + sample[property]
      averages[property].count = averages[property].count + 1
    }
  }
}

/**
 * iterate through the intervals for the sample. add the strength to the stored total and increment the count for that interval
 * preparing to average per interval for the variation level
 * @param {Object} sample a digested sample
 * @param {Object} strengthDataPerInterval an object containing a total and a count for each interval.
 */
export const accumulateStrengthDataForAveraging = (
  sample: BaleenSample,
  strengthDataPerInterval: IAveragePerInterval
) => {
  for (const interval in sample.strengths) {
    if (strengthDataPerInterval[interval as BaleenIntervals]) {
      strengthDataPerInterval[interval].total =
        strengthDataPerInterval[interval as BaleenIntervals].total +
        sample.strengths[interval as BaleenIntervals].strength
      strengthDataPerInterval[interval].count =
        strengthDataPerInterval[interval].count + 1
    } else {
      strengthDataPerInterval[interval] = {
        total: sample.strengths[interval].strength,
        count: 1,
      }
    }
  }
}

/**
 * iterate through the intervals for the sample. add the cement efficiency to the stored total and increment the count for that interval
 * preparing to average per interval for the variation level
 * @param {Object} sample a digested sample
 * @param {Object} cementEfficiencyDataPerInterval an object containing a total and a count for each interval.
 */
export const accumulateCementEfficiencyDataForAveraging = (
  sample: BaleenSample,
  cementEfficiencyDataPerInterval: IAveragePerInterval
) => {
  for (const interval in sample.cementEfficiency) {
    if (cementEfficiencyDataPerInterval[interval as BaleenIntervals]) {
      cementEfficiencyDataPerInterval[interval].total =
        cementEfficiencyDataPerInterval[interval].total +
        sample.cementEfficiency[interval as BaleenIntervals]
      cementEfficiencyDataPerInterval[interval].count =
        cementEfficiencyDataPerInterval[interval].count + 1
    } else {
      cementEfficiencyDataPerInterval[interval] = {
        total: sample.cementEfficiency[interval as BaleenIntervals],
        count: 1,
      }
    }
  }
}

/**
 * Called once per Mix Group.
 * A function that organizes samples into samples and outliers. Also accumulates data necessary for getting average values for a variation
 * Stores original outliers (outliers we get from kelowna)
 * @param {Object} baleenSample a digested sample
 * @param {Object[]} samplesArr an array of digested samples
 * @param {Object[]} outliersArr an array of digested samples (populate)
 * @param {Object} originalOutliersObj an object containing the original outliers. referenced by their batchId
 * @param {Object} averagesObj an object containing a total and a count for each property to be averaged
 * @param {String[]} propertiesToAverage an array of strings. the strings are keys/properties on the sample that need to be averaged
 */
export const organizeSamples = (
  baleenSample: BaleenSample,
  samplesArr: BaleenSample[],
  outliersArr: BaleenSample[],
  originalOutliersObj: { [key: string]: BaleenSample },
  averagesObj: ISampleAverages,
  propertiesToAverage: PropertiesToAverage[]
) => {
  samplesArr.push(baleenSample)
  if (baleenSample.outlierStatus === 'Sample') {
    accumulatePropertyDataForAveraging(
      baleenSample,
      averagesObj,
      propertiesToAverage
    )
  }
  if (baleenSample.outlierStatus !== 'Sample') {
    outliersArr.push(cloneDeep(baleenSample))
    originalOutliersObj[baleenSample.batchId] = baleenSample
  }
}

/**
 * A function that creates a digested variation.
 * @param {IVariationCreationVariablesObj} variation an undigested variation
 * @returns {Object} a digested mix variation
 */
export const createBaleenVariation = (
  variationCreationVariablesObj: IVariationCreationVariablesObj
) => {
  const {
    variation,
    variationId,
    variationIdLabel,
    averages,
    variationSamples,
    mixDesignId,
    hasSampleCO2Dosage,
    hasSampleCementReduction,
    isMetric,
  } = variationCreationVariablesObj
  let variationType: VariationTypes | null = null
  if (variation.variationType) {
    variationType =
      variation.variationType === VariationTypes.BASELINE
        ? VariationTypes.BASELINE
        : VariationTypes.OPTIMIZED
  }
  const baleenVariation: BaleenMixVariation = {
    mixDesignId,
    variationId,
    variationIdLabel,
    slump:
      averages['slump'].total !== null
        ? averages['slump'].total / averages['slump'].count
        : null,
    concreteTemperature:
      averages['concreteTemperature'].total !== null
        ? averages['concreteTemperature'].total /
          averages['concreteTemperature'].count
        : null,
    cO2Dosage: variation.cO2Dosage,
    cO2DosageLabel:
      hasSampleCO2Dosage && variation.cO2Dosage !== null
        ? getCO2Dosage(variation.cO2Dosage, variation.cO2DosageUnit, isMetric)
        : null,
    air:
      averages['air'].total !== null
        ? averages['air'].total / averages['air'].count
        : null,
    cementContent:
      averages['cementContent'].total !== null
        ? averages['cementContent'].total / averages['cementContent'].count
        : null,
    densityKgPerM3:
      averages['densityKgPerM3'].total !== null
        ? averages['densityKgPerM3'].total / averages['densityKgPerM3'].count
        : null,
    cO2DosageUnit: variation.cO2DosageUnit,
    cementReduction: variation.cementReductionPercent,
    cementReductionLabel:
      hasSampleCementReduction && variation.cementReductionPercent !== null
        ? variation.cementReductionPercent
        : null,
    strengths: {},
    cementEfficiency: {},
    samples: variationSamples,
    variationType,
    fillColor: '',
    haloColor: '',
  }
  return baleenVariation
}

/**
 * A function that returns the initial CO2Dosage in the proper unit. In the metric case, initial CO2 dosage must be in litres.
 * @param {number | null} cO2Dosage the value for the cO2Dosage
 * @param {string | null} cO2DosageUnit the unit of the cO2Dosage from kelowna
 * @param {boolean} isMetric whether the measurement system is metric or imperial
 * @returns {number | null}
 */
export const getCO2Dosage = (
  cO2Dosage: number | null,
  cO2DosageUnit: CO2DosageUnit,
  isMetric: boolean
) => {
  if (cO2Dosage === null) return null
  if (cO2DosageUnit === 'PercentOfCement') return cO2Dosage
  if (isMetric) {
    cO2Dosage = cO2Dosage * 1000
  } else {
    cO2Dosage = ConvertMilliLitrePerM3ToOzPerYd3Unrounded(
      cO2Dosage * 1000
    ) as number
  }
  return cO2Dosage
}

export const getCO2DosageWithPrecision = (
  cO2Dosage: number | null,
  cO2DosageUnit: CO2DosageUnit,
  isMetric: boolean
) => {
  const precision = getCO2DosagePrecision(cO2DosageUnit, isMetric)
  const digestedCO2Dosage = roundUpToDecimal(
    getCO2Dosage(cO2Dosage, cO2DosageUnit, isMetric),
    precision
  )
  return digestedCO2Dosage !== null && digestedCO2Dosage !== undefined
    ? Number(digestedCO2Dosage)
    : null
}

/**
 * Function to populate strength and cement efficiency per interval for a sample
 * @param sample
 * @param baleenSample
 * @param strengths
 * @param cementEfficiencyPerInterval
 * @param isMetric
 */
export const populateSampleStrengthsAndCementEfficiency = (
  sample: KelownaBaleenSample,
  baleenSample: BaleenSample,
  strengths: BaleenStrengths,
  cementEfficiencyPerInterval: BaleenCementEfficiency,
  isMetric: boolean
) => {
  for (const interval in sample.hourlyStrengthAvg) {
    const strengthValue = sample.hourlyStrengthAvg[interval as BaleenIntervals]
      ? sample.hourlyStrengthAvg[interval as BaleenIntervals].averageMpa
      : null
    const cementEfficiency =
      strengthValue !== null &&
      baleenSample.cementContent !== null &&
      baleenSample.cementContent > 0
        ? strengthValue / baleenSample.cementContent
        : null
    strengths[interval as BaleenIntervals] = {
      strength: isMetric
        ? strengthValue
        : ConvertMPAToPSIUnrounded(strengthValue),
      cylinderCount:
        sample.hourlyStrengthAvg[interval as BaleenIntervals]?.cylinderCount,
    }
    if (cementEfficiency !== null) {
      cementEfficiencyPerInterval[interval] = isMetric
        ? cementEfficiency
        : ConvertMPaKgToPSILbUnrounded(cementEfficiency)
    }
  }
}

/**
 * A function to create a "baleen" sample.
 * @param {Object} sample an undigested sample
 * @param {String} variationId
 * @param {String} variationIdLabel
 * @param {boolean} isMetric whether the measurement system is metric or imperial
 * @param {ICO2DosageAndCementReductionInfo} cO2DosageAndCementReductionInfo object containing the variation co2 dosage, co2 dosage unit, and cement reduction
 * @param {number} variationAvgStrength average strength of the variation (from kelowna)
 * @returns {Object} a digested sample
 */
export const createBaleenSample = (
  sample: KelownaBaleenSample,
  variationId: string,
  variationIdLabel: string,
  isMetric: boolean,
  cO2DosageAndCementReductionInfo: ICO2DosageAndCementReductionInfo
) => {
  const {
    cO2Dosage,
    cO2DosageUnit,
    cementReduction,
  } = cO2DosageAndCementReductionInfo
  const baleenSample: BaleenSample = {
    batchId: sample.batchSampleId,
    ticketId: sample.externalTicketId,
    variationId,
    variationIdLabel,
    air: sample.airContent,
    slump: sample.slumpMm,
    cementContent: sample.cementContentKgPerM3,
    concreteTemperature: sample.concreteTemperature,
    densityKgPerM3: sample.densityKgPerM3,
    cO2Dosage: getCO2Dosage(cO2Dosage, cO2DosageUnit, isMetric),
    cO2DosageUnit: cO2DosageUnit,
    cementReduction: cementReduction,
    outlierType: sample.outlierType ? sample.outlierType : null,
    outlierReasonDropdown: sample.outlierReason ? sample.outlierReason : null,
    outlierReasonTextField: null,
    outlierStatus: sample.outlierType ? 'Outlier' : 'Sample',
    cementSupplier: sample.cementSupplier,
    cementType:
      sample.cementType && translatedCementTypes[sample.cementType]
        ? translatedCementTypes[sample.cementType]
        : null,
    productionDate: new Date(sample.productionDate),
    scmPercent: sample.scmPercent,
    batchWaterCementRatio: sample.batchWaterCementRatio,
    strengths: {},
    cementEfficiency: {},
  }

  if (baleenSample.outlierReasonDropdown !== null) {
    if (
      !BaleenOutlierReasons[
        baleenSample.outlierReasonDropdown as keyof typeof BaleenOutlierReasons
      ]
    ) {
      baleenSample.outlierReasonTextField = baleenSample.outlierReasonDropdown
      baleenSample.outlierReasonDropdown = BaleenOutlierReasons.Other
    }
  }

  const strengths: BaleenStrengths = {}
  const cementEfficiencyPerInterval = {}
  populateSampleStrengthsAndCementEfficiency(
    sample,
    baleenSample,
    strengths,
    cementEfficiencyPerInterval,
    isMetric
  )
  if (!isMetric) {
    baleenSample.slump = ConvertMMtoINCHUnrounded(baleenSample.slump)
    baleenSample.cementContent = convertKgM3ToLbYd3(baleenSample.cementContent)
    baleenSample.concreteTemperature = ConvertCtoFUnrounded(
      baleenSample.concreteTemperature
    )
    baleenSample.densityKgPerM3 = convertKgM3ToLbYd3(
      baleenSample.densityKgPerM3
    )
  }
  baleenSample.strengths = strengths
  baleenSample.cementEfficiency = cementEfficiencyPerInterval
  return baleenSample
}

/**
 * Function to get production dates and determine if a sample with status of 'Sample' has a value for cement reduction and co2 dosage
 * @param baleenSample
 * @param sampleDates
 * @param conditionFlags
 * @returns
 */
const processSampleDatesAndConditionFlags = (
  baleenSample: BaleenSample,
  conditionFlags: {
    hasSampleCementReduction: boolean
    hasSampleCO2Dosage: boolean
  }
) => {
  if (
    baleenSample.outlierStatus === 'Sample' &&
    baleenSample.cO2Dosage !== null
  )
    conditionFlags.hasSampleCO2Dosage = true
  if (
    baleenSample.outlierStatus === 'Sample' &&
    baleenSample.cementReduction !== null
  )
    conditionFlags.hasSampleCementReduction = true
}

/**
 * A function to digest baleen data. Converting it into a format for Orca.
 * @param {Object} mixGroup the mix group received from kelowna
 * @param {boolean} isMetric whether or not the measurement system is imperial or metric
 * @returns {Object} an object containing digest samples, outliers, original outliers, and variations
 */
export const digestBaleenData = (
  mixGroup: KelownaBaleenMixGroup,
  isMetric: boolean
) => {
  const baleenMixVariations: BaleenMixVariation[] = []
  const mixDesignId = mixGroup.mixDesignId
  const samples: BaleenSample[] = []
  const outliers: BaleenSample[] = []
  const originalOutliers: { [key: string]: BaleenSample } = {}
  if (mixGroup?.mixVariations.length) {
    mixGroup.mixVariations.forEach((variation: KelownaBaleenMixVariation) => {
      const precision = getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
      const digestedCO2Dosage = getCO2Dosage(
        variation.cO2Dosage,
        variation.cO2DosageUnit,
        isMetric
      )?.toFixed(precision)
      const cO2DosageUnitLabel =
        digestedCO2Dosage !== null && digestedCO2Dosage !== undefined
          ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
          : ''
      const variationId = `${mixDesignId}-${variation.cementReductionPercent}-${
        variation.cO2Dosage
      }${getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)}`
      const variationIdLabel = `${mixGroup.mixCode}-${
        variation.cementReductionPercent
      }-${digestedCO2Dosage ?? 'null'}${cO2DosageUnitLabel}`
      const propertiesToAverage: PropertiesToAverage[] = [
        'slump',
        'concreteTemperature',
        'air',
        'cementContent',
        'densityKgPerM3',
      ]
      const averages = {
        slump: {
          total: null,
          count: 0,
        },
        concreteTemperature: {
          total: null,
          count: 0,
        },
        cO2Dosage: {
          total: null,
          count: 0,
        },
        air: {
          total: null,
          count: 0,
        },
        cementContent: {
          total: null,
          count: 0,
        },
        densityKgPerM3: {
          total: null,
          count: 0,
        },
        cementReduction: {
          total: null,
          count: 0,
        },
      }
      const variationSamples: { [key: number]: BaleenSample } = {}
      const conditionFlags = {
        hasSampleCO2Dosage: false,
        hasSampleCementReduction: false,
      }
      const cO2DosageAndCementReductionInfo = {
        cO2Dosage: variation.cO2Dosage,
        cO2DosageUnit: variation.cO2DosageUnit,
        cementReduction: variation.cementReductionPercent,
      }
      variation.samples.forEach(sample => {
        const baleenSample = createBaleenSample(
          sample,
          variationId,
          variationIdLabel,
          isMetric,
          cO2DosageAndCementReductionInfo
        )
        processSampleDatesAndConditionFlags(baleenSample, conditionFlags)
        organizeSamples(
          baleenSample,
          samples,
          outliers,
          originalOutliers,
          averages,
          propertiesToAverage
        )
        variationSamples[baleenSample.batchId] = baleenSample
      })
      const variationCreationVariablesObj = {
        variation,
        variationId,
        variationIdLabel,
        averages,
        variationSamples,
        mixDesignId,
        hasSampleCO2Dosage: conditionFlags.hasSampleCO2Dosage,
        hasSampleCementReduction: conditionFlags.hasSampleCementReduction,
        isMetric,
      }
      const baleenVariation: BaleenMixVariation = createBaleenVariation(
        variationCreationVariablesObj
      )
      baleenMixVariations.push(baleenVariation)
    })
  }
  return {
    digestedSamples: samples,
    digestedOutliers: outliers,
    digestedOriginalOutliers: cloneDeep(originalOutliers), // clone so outliers and originalOutliers aren't referencing the same samples and cause accidental changes
    digestedMixVariations: baleenMixVariations,
  }
}

/**
 * Get the decimal precision a property needs for display
 * @param {String} property a property on a variation
 * @param {boolean} isMetric whether or not the measurement system is imperial or metric
 * @returns {number | undefined} precision for a property
 */
export const getPropertyPrecision = (property: string, isMetric: boolean) => {
  if (property === 'slump') {
    return isMetric ? 0 : 1
  } else if (property === 'airContent') return 1
  else if (property === 'concreteTemperature') return 2
  else if (property === 'densityKgPerM3') return 2
  else if (property === 'air') return 1
  else if (property === 'cementContent') return 0
  else if (property === 'strengths') return isMetric ? 2 : 0
  else if (property === 'scmPercent') return 2
  else if (property === 'cementEfficiency') return 2
  else return undefined
}

/**
 * Get the unit of a property needs for baleen variation table
 * @param {String} property a property on a variation
 * @param {boolean} isMetric whether or not the measurement system is imperial or metric
 * @param {BaleenMixVariation} variation of a mix
 * @returns {string} unit for a property
 */
export const getPropertyUnit = (
  property: string,
  isMetric: boolean,
  variation: BaleenMixVariation
) => {
  switch (property) {
    case 'air':
      return '%'
    case 'cementContent': //avg. cmt. loading
      return isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
    case 'cementEfficiency':
      return isMetric ? PropertyUnit.MPaM3PerKg : PropertyUnit.PsiYd3PerLb
    case 'concreteTemperature':
      return isMetric
        ? PropertyUnit.DegreeCelsius
        : PropertyUnit.DegreeFahrenheit
    case 'cO2DosageLabel':
      return getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric)
    case 'densityKgPerM3': //unit weight
      return isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
    case 'slump':
      return isMetric ? 'mm' : 'in'
    case 'strengths':
      return isMetric ? 'MPa' : 'psi'
    default:
      return
  }
}

/**
 * A function that returns the precision for cO2Dosage display
 * @param {String | null} cO2DosageUnit the unit for co2 dosage
 * @param {boolean} isMetric whether or not the measurement system is imperial or metric
 * @returns
 */
export const getCO2DosagePrecision = (
  cO2DosageUnit: CO2DosageUnit,
  isMetric: boolean
) => {
  if (cO2DosageUnit === 'LitrePerM3' && isMetric) {
    return 0
  }
  if (cO2DosageUnit === 'LitrePerM3' && !isMetric) return 2
  return 3
}

/**
 * A function that determines whether or not a sample is valid for inclusion based on its status
 * @param {String} tableType the type of table (sample or outlier)
 * @param {String} outlierStatus the status of an outlier. sample, potential outlier(checked/unchecked), or outlier
 * @returns
 */
const isOutlierStatusValid = (
  tableType:
    | SimpleTableTypes.BaleenSamplesTable
    | SimpleTableTypes.BaleenOutliersTable,
  outlierStatus: BaleenOutlierStatus
) => {
  if (tableType === SimpleTableTypes.BaleenSamplesTable) {
    return outlierStatus === 'Sample'
  }
  return outlierStatus !== 'Sample'
}

/**
 * A function that determines whether or not a sample is valid for inclusion in a table
 * @param {Object} sample a digested sample
 * @param {String[]} selectedVariationIds an array of ids of the selected variations
 * @param {boolean} hasSelectedInterval whether or not an interval has been selected
 * @param {String[]} selectedInterval an array containing (or not) the selected interval in hours
 * @param {String} tableType the type of table (sample or outlier)
 * @returns {boolean}
 */
const isSampleValidForRow = (
  sample: BaleenSample,
  selectedVariationIds: string[],
  tableType:
    | SimpleTableTypes.BaleenSamplesTable
    | SimpleTableTypes.BaleenOutliersTable
) => {
  if (!isOutlierStatusValid(tableType, sample.outlierStatus)) return false
  if (
    !selectedVariationIds.includes('all') &&
    !selectedVariationIds.includes(sample.variationId)
  )
    return false
  return true
}

/**
 * Function to get the tooltip for the checkbox in the baleen sample/outliers table.
 * @param tableType
 * @param outlierStatus
 * @param outlierType the type of confirmed outlier it is
 */
export const getTableCheckboxTooltip = (
  tableType:
    | SimpleTableTypes.BaleenOutliersTable
    | SimpleTableTypes.BaleenSamplesTable,
  outlierStatus: BaleenOutlierStatus,
  outlierType: BaleenOutlierType
) => {
  if (tableType === SimpleTableTypes.BaleenOutliersTable) {
    if (outlierStatus === 'Outlier' && outlierType !== null) return ''
    else if (outlierStatus === 'PotentialOutlierUnchecked')
      return 'Mark as Outlier'
    else if (outlierStatus === 'PotentialOutlierChecked')
      return 'Unmark as Outlier'
  }
}

const getFixedTableCells = (
  sample: BaleenSample,
  selectedInterval: BaleenIntervals[],
  isMetric: boolean
) => {
  return [
    sample.productionDate
      ? getFormattedDate(
          sample.productionDate.toString(),
          'YYYYMMDD',
          '-',
          true
        )
      : null,
    sample.slump?.toFixed(getPropertyPrecision('slump', isMetric)),
    sample.air?.toFixed(getPropertyPrecision('air', isMetric)),
    roundUpToDecimal(
      sample.cO2Dosage,
      getCO2DosagePrecision(sample.cO2DosageUnit, isMetric)
    ),
    getCO2DosageUnitLabel(sample.cO2DosageUnit, isMetric),
    sample.cementContent?.toFixed(
      getPropertyPrecision('cementContent', isMetric)
    ),
    sample.scmPercent?.toFixed(getPropertyPrecision('scmPercent', isMetric)),
    sample.strengths[selectedInterval[0]]?.strength.toFixed(
      getPropertyPrecision('strengths', isMetric)
    ),
    sample.strengths[selectedInterval[0]]?.cylinderCount,
    sample.cementType,
    sample.cementSupplier,
  ]
}

const getBaleenSampleTableRows = (
  sample: BaleenSample,
  selectedInterval: BaleenIntervals[],
  isMetric: boolean
) => {
  return {
    id: sample.batchId,
    cells: [
      sample.variationIdLabel,
      sample.ticketId,
      {
        behavior: CellBehavior.Checkbox,
        content: [
          {
            tooltipTitle: 'Mark as Outlier',
            isSample: sample.outlierStatus !== 'Sample',
            color: 'primary',
          },
        ],
      },
      ...getFixedTableCells(sample, selectedInterval, isMetric),
    ],
  }
}

/**
 * A function to determine if the row in the outliers table has errors that would prevent submission
 * @param {BaleenSample} sample
 * @param {boolean} hasErrors true if the row has errors
 * @param {ISubmitErrors[]} submitErrors errors associated with trying to submit a request to add/remove outliers
 * @returns
 */
export const getRowErrorFlags = (
  sample: BaleenSample,
  hasErrors: boolean,
  submitErrors: ISubmitErrors
) => {
  let hasOutlierReasonDropdownError = false
  let hasOutlierReasonTextFieldError = false
  if (hasErrors) {
    for (const error of submitErrors[sample.batchId]) {
      if (
        !sample.outlierReasonDropdown &&
        error.name === 'missingOutlierReasonDropdown'
      )
        hasOutlierReasonDropdownError = true
      else if (
        sample.outlierReasonDropdown &&
        error.name === 'missingOutlierReasonDropdown'
      )
        hasOutlierReasonDropdownError = false

      if (
        (!sample.outlierReasonTextField ||
          sample.outlierReasonTextField?.trim() === '') &&
        error.name === 'missingOutlierReasonTextField'
      )
        hasOutlierReasonTextFieldError = true
      else if (
        sample.outlierReasonTextField &&
        error.name === 'missingOutlierReasonTextField'
      )
        hasOutlierReasonTextFieldError = false
    }
  }
  return { hasOutlierReasonDropdownError, hasOutlierReasonTextFieldError }
}

/**
 * Function to get the rows for the outlier table
 * @param {BaleenSample} sample digested baleen sample
 * @param {BaleenIntervals[]} selectedInterval 
 * @param {boolean} isMetric whether or not the measurement system is metric
 * @param {ISubmitErrors[]} submitErrors errors associated with trying to submit a request to add/remove outliers

 * @returns 
 */
const getBaleenOutlierTableRows = (
  sample: BaleenSample,
  selectedInterval: BaleenIntervals[],
  isMetric: boolean,
  submitErrors?: ISubmitErrors
) => {
  let firstCellBehavior: CellBehavior
  if (submitErrors === undefined) return
  if (sample.outlierStatus !== 'Outlier' && sample.outlierType === null)
    firstCellBehavior = CellBehavior.Close
  else if (sample.outlierStatus === 'Outlier' && sample.outlierType !== null)
    firstCellBehavior = CellBehavior.EditOutlined
  else firstCellBehavior = CellBehavior.Undo
  const hasErrors = !!submitErrors[sample.batchId]
  const {
    hasOutlierReasonDropdownError,
    hasOutlierReasonTextFieldError,
  } = getRowErrorFlags(sample, hasErrors, submitErrors)
  return {
    id: sample.batchId,
    helperVariables: {
      outlierType: sample.outlierType,
      isSample: sample.outlierStatus === 'Sample',
    },
    cells: [
      {
        behavior: firstCellBehavior,
        content: [
          sample.outlierStatus === 'Outlier' ? 'EditOutlined' : 'Close',
        ],
      },
      sample.variationIdLabel,
      sample.ticketId,
      {
        behavior: CellBehavior.Checkbox,
        content: [
          {
            checked:
              sample.outlierStatus === 'Outlier' ||
              sample.outlierStatus === 'PotentialOutlierChecked',
            color:
              sample.outlierStatus === 'Outlier'
                ? 'action.disabled'
                : 'primary',
            tooltipTitle: getTableCheckboxTooltip(
              SimpleTableTypes.BaleenOutliersTable,
              sample.outlierStatus,
              sample.outlierType
            ),
            disabled: sample.outlierStatus === 'Outlier',
          },
        ],
      },
      {
        behavior: CellBehavior.DropdownWithOther,
        content: [
          {
            outlierReasonDropdown: sample.outlierReasonDropdown,
            options: Object.entries(BaleenOutlierReasons),
            outlierReasonTextField: sample.outlierReasonTextField,
            variationId: sample.variationId,
            outlierStatus: sample.outlierStatus,
            disabled: sample.outlierStatus === 'Outlier',
            hasOutlierReasonDropdownError: hasOutlierReasonDropdownError,
            hasOutlierReasonTextFieldError: hasOutlierReasonTextFieldError,
          },
        ],
      },
      ...getFixedTableCells(sample, selectedInterval, isMetric),
    ],
  }
}

/**
 * A function that takes samples and creates row data for a table
 * @param {Object[]} samples an array of digested samples
 * @param {String[]} selectedInterval an array containing (or not) the selected interval in hours
 * @param {String[]} selectedVariationIds an array of ids of the selected variations
 * @param {boolean} isMetric whether or not the measurement system is imperial or metric
 * @param {String} tableType the type of table (sample or outlier)
 * @param {ISubmitError[]} submitErrors errors associated with trying to submit a request to add/remove outliers
 * @returns {Object} row data for the table
 */
export const samplesToRows = (
  samples: BaleenSample[],
  selectedInterval: BaleenIntervals[],
  selectedVariationIds: string[],
  isMetric: boolean,
  tableType:
    | SimpleTableTypes.BaleenSamplesTable
    | SimpleTableTypes.BaleenOutliersTable,
  submitErrors?: ISubmitErrors
) => {
  if (samples.length === 0 || selectedVariationIds.length === 0) return []
  const filteredSamples = samples.filter(sample => {
    return isSampleValidForRow(sample, selectedVariationIds, tableType)
  })
  const rows = filteredSamples.map(sample => {
    if (tableType === SimpleTableTypes.BaleenSamplesTable) {
      return getBaleenSampleTableRows(sample, selectedInterval, isMetric)
    } else {
      return getBaleenOutlierTableRows(
        sample,
        selectedInterval,
        isMetric,
        submitErrors
      )
    }
  })
  return rows
}

/**
 * A function that takes variations and creates variation options for the filter panel
 * @param {Object[]} variations an array of digested variations
 * @returns {Object[]} an array of variation options
 */
export const createVariationOptions = (variations: BaleenMixVariation[]) => {
  const variationOptions: FilterOption[] = []
  if (variations.length > 0 && variations.length < 7)
    variationOptions.push({
      id: 'all',
      name: `All Variations (${variations.length})`,
    })
  for (const variation of variations) {
    variationOptions.push({
      id: variation.variationId,
      name: variation.variationIdLabel,
    })
  }
  return variationOptions
}

/**
 * A function that sorts samples based on the property, order, and (maybe) interval selected
 * @param {Object[]} prevSamples an array of samples to sort
 * @param property the variation property (key) we want to sort by
 * @param newOrder the new order we want to sort by. ascending or descending.
 * @param interval the selected interval in hours
 */
export const sortSamples = (
  prevSamples: BaleenSample[],
  property: string,
  newOrder: string,
  interval: BaleenIntervals
) => {
  if (property === 'variationIdLabel' || property === 'ticketId') {
    if (newOrder === 'asc') {
      prevSamples.sort(function(a, b) {
        return a[property].localeCompare(b[property], 'en', { numeric: true })
      })
    } else {
      prevSamples.sort(function(a, b) {
        return b[property].localeCompare(a[property], 'en', { numeric: true })
      })
    }
  } else if (property === 'strengths') {
    if (newOrder === 'asc') {
      prevSamples.sort((a, b) => {
        return a[property][interval]?.strength - b[property][interval]?.strength
      })
    } else {
      prevSamples.sort((a, b) => {
        return b[property][interval]?.strength - a[property][interval]?.strength
      })
    }
  } else if (property === 'cylinderCount') {
    if (newOrder === 'asc') {
      prevSamples.sort((a, b) => {
        return (
          a['strengths'][interval]?.cylinderCount -
          b['strengths'][interval]?.cylinderCount
        )
      })
    } else {
      prevSamples.sort((a, b) => {
        return (
          b['strengths'][interval]?.cylinderCount -
          a['strengths'][interval]?.cylinderCount
        )
      })
    }
  } else {
    if (newOrder === 'asc') {
      prevSamples.sort((a, b) => {
        return a[property] - b[property]
      })
    } else {
      prevSamples.sort((a, b) => {
        return b[property] - a[property]
      })
    }
  }
}

/**
 * A function that takes variations without their strengths and adds the strengths per interval by averaging the strengths of their respective samples
 * @param {Object[]} variations an array of digested variations prior to adding individual strengths per interval
 * @returns {Object[]} an array of digested variations and their individual strengths per interval
 */
export const computeBaleenVariationStrengths = (
  variations: BaleenMixVariation[]
) => {
  const variationsCopy = cloneDeep(variations)
  variationsCopy.forEach((variation: BaleenMixVariation) => {
    const strengthAverageObj = {}
    const cementEfficiencyObj = {}
    const variationSamples = variation.samples
    for (const sampleId in variationSamples) {
      if (
        variationSamples[sampleId].outlierStatus === 'Sample' ||
        variationSamples[sampleId].outlierStatus === 'PotentialOutlierUnchecked'
      ) {
        accumulateStrengthDataForAveraging(
          variationSamples[sampleId],
          strengthAverageObj
        )
        accumulateCementEfficiencyDataForAveraging(
          variationSamples[sampleId],
          cementEfficiencyObj
        )
      }
    }
    for (const interval in strengthAverageObj) {
      variation.strengths[interval] =
        strengthAverageObj[interval].total / strengthAverageObj[interval].count
    }
    for (const interval in cementEfficiencyObj) {
      variation.cementEfficiency[interval] =
        cementEfficiencyObj[interval].total /
        cementEfficiencyObj[interval].count
    }
  })
  return { variationsWithStrengths: variationsCopy }
}

/**
 * Convert a baleen sample from imperial to metric. Created to reduce cognitive complexity
 * @param sample
 */
const convertSampleToMetric = (sample: BaleenSample, isMetric: boolean) => {
  sample.slump = ConvertINCHtoMM(sample.slump)
  sample.cementContent = ConvertLbPerYd3ToKgPerM3Unrounded(sample.cementContent)
  sample.concreteTemperature = ConvertFtoCUnrounded(sample.concreteTemperature)
  sample.densityKgPerM3 = ConvertLbPerYd3ToKgPerM3Unrounded(
    sample.densityKgPerM3
  )
  if (sample.cO2DosageUnit === 'LitrePerM3')
    sample.cO2Dosage = ConvertOzPerYd3ToMilliLitrePerM3Unrounded(
      sample.cO2Dosage
    )
  for (const interval in sample.strengths) {
    sample.strengths[
      interval as BaleenIntervals
    ].strength = ConvertPSIToMPAUnrounded(
      sample.strengths[interval as BaleenIntervals]?.strength ?? null
    ) as number
  }
  for (const interval in sample.cementEfficiency) {
    sample.cementEfficiency[
      interval as BaleenIntervals
    ] = ConvertPSILbToMPAKgUnrounded(sample.cementEfficiency[interval])
  }
  sample.variationIdLabel = updateVariationIdLabel(
    sample.variationIdLabel,
    sample,
    isMetric
  )
}

/**
 * Convert a baleen sample from metric to imperial. Created to reduce cognitive complexity
 * @param sample
 */
const convertSampleToImperial = (sample: BaleenSample, isMetric: boolean) => {
  sample.slump = ConvertMMtoINCHUnrounded(sample.slump)
  sample.cementContent = convertKgM3ToLbYd3Unrounded(sample.cementContent)
  sample.concreteTemperature = ConvertCtoFUnrounded(sample.concreteTemperature)
  sample.densityKgPerM3 = convertKgM3ToLbYd3Unrounded(sample.densityKgPerM3)
  if (sample.cO2DosageUnit === 'LitrePerM3')
    sample.cO2Dosage = ConvertMilliLitrePerM3ToOzPerYd3Unrounded(
      sample.cO2Dosage
    )
  for (const interval in sample.strengths) {
    sample.strengths[
      interval as BaleenIntervals
    ].strength = ConvertMPAToPSIUnrounded(
      sample.strengths[interval as BaleenIntervals]?.strength ?? null
    ) as number
  }
  for (const interval in sample.cementEfficiency) {
    sample.cementEfficiency[
      interval as BaleenIntervals
    ] = ConvertMPaKgToPSILbUnrounded(sample.cementEfficiency[interval])
  }
  sample.variationIdLabel = updateVariationIdLabel(
    sample.variationIdLabel,
    sample,
    isMetric
  )
}

/**
 * A function that converts samples from imperial to metric or vise versa
 * @param {Object[]} samplesCopy an array of digested samples
 * @param {Object} samplesObj an object containing samples. accessed by their batchId
 * @param newSystem the system to convert to. imperial or metric
 */
export const convertSampleUnits = (
  samplesCopy: BaleenSample[],
  samplesObj: { [key: number]: BaleenSample },
  newSystem: boolean
) => {
  samplesCopy.forEach((sample: BaleenSample) => {
    if (newSystem) {
      // convert to metric
      convertSampleToMetric(sample, newSystem)
    } else {
      // convert to imperial
      convertSampleToImperial(sample, newSystem)
    }
    // store the sample in an object for future use
    samplesObj[sample.batchId] = sample
  })
}

/**
 * Function to add property values to a baleen variation
 * @param {BaleenMixVariation} matchedVariation a digested variation
 * @param {PropertiesToAverage[]} propertiesToAverage keys of the averages object
 * @param {ISampleAverages} averages an object containing the total and count for each averaged property
 * @param {IAveragePerInterval} strengthAverageObj an object containing the sum of sample strengths and the number of samples included (for averaging purposes)
 * @param {IAveragePerInterval} cementEfficiencyAverageObjan object containing the sum of sample cement efficiencies and the number of samples included (for averaging purposes)
 */
export const addAveragedPropertiesToVariation = (
  matchedVariation: BaleenMixVariation,
  propertiesToAverage: PropertiesToAverage[],
  averages: ISampleAverages,
  strengthAverageObj: IAveragePerInterval,
  cementEfficiencyAverageObj: IAveragePerInterval
) => {
  // use data received to calculate variation values per property
  for (const property of propertiesToAverage) {
    matchedVariation[property] =
      averages[property].total !== null
        ? averages[property].total / averages[property].count
        : null
  }
  // use data received to calculate variation strengths per interval
  for (const interval in strengthAverageObj) {
    matchedVariation.strengths[interval] =
      strengthAverageObj[interval].total / strengthAverageObj[interval].count
  }
  // use data received to calculate cement efficiency per interval
  for (const interval in cementEfficiencyAverageObj) {
    matchedVariation.cementEfficiency[interval] =
      cementEfficiencyAverageObj[interval].total /
      cementEfficiencyAverageObj[interval].count
  }
}

/**
 * A function that deletes strength and cementitious efficiency data from a baleen variation
 * @param matchedVariation a digested variation
 * @param strengthAverageObj an object containing the sum of sample strengths and the number of samples included (for averaging purposes)
 * @param {IAveragePerInterval} cementEfficiencyAverageObjan object containing the sum of sample cement efficiencies and the number of samples included (for averaging purposes)
 */
export const removeInvalidStrengthAndCementEfficiencyIntervals = (
  matchedVariation: BaleenMixVariation,
  strengthAverageObj: IAveragePerInterval,
  cementEfficiencyAverageObj: IAveragePerInterval
) => {
  // delete intervals that are in the variation but not in strengthAverageObj
  for (const interval in matchedVariation.strengths) {
    if (!strengthAverageObj[interval])
      delete matchedVariation.strengths[interval]
  }
  // delete intervals that are in the variation but not in strengthAverageObj
  for (const interval in matchedVariation.cementEfficiency) {
    if (!cementEfficiencyAverageObj[interval])
      delete matchedVariation.cementEfficiency[interval]
  }
}

/**
 * A function that calculates property averages for a variation based on its samples and adds them to the variation
 * @param matchedVariation a digested baleen variation
 */
export const variationRecalculation = (
  matchedVariation: BaleenMixVariation
) => {
  // used to store data necessary for averaging values that will be assigned to a variation
  const averages: ISampleAverages = {
    slump: {
      total: null,
      count: 0,
    },
    concreteTemperature: {
      total: null,
      count: 0,
    },
    air: {
      total: null,
      count: 0,
    },
    cementContent: {
      total: null,
      count: 0,
    },
    densityKgPerM3: {
      total: null,
      count: 0,
    },
  }
  // used to store data necessary for calculating strength average per inerval that will be assigned to a variation
  const strengthAverageObj: IAveragePerInterval = {}

  const cementEfficiencyAverageObj: IAveragePerInterval = {}

  const variationSamples = matchedVariation.samples

  const propertiesToAverage: PropertiesToAverage[] = [
    'slump',
    'concreteTemperature',
    'air',
    'cementContent',
    'densityKgPerM3',
  ]

  let cO2DosageLabel = null
  let cementReductionLabel = null
  // sampleId is synonamous with batchId here
  for (const sampleId in variationSamples) {
    const currentSample = variationSamples[sampleId]
    if (
      currentSample.outlierStatus === 'Sample' ||
      currentSample.outlierStatus === 'PotentialOutlierUnchecked'
    ) {
      accumulatePropertyDataForAveraging(
        currentSample,
        averages,
        propertiesToAverage
      )
      accumulateStrengthDataForAveraging(currentSample, strengthAverageObj)
      accumulateCementEfficiencyDataForAveraging(
        currentSample,
        cementEfficiencyAverageObj
      )
      if (cO2DosageLabel === null && currentSample.cO2Dosage !== null) {
        cO2DosageLabel = currentSample.cO2Dosage
      }
      if (
        cementReductionLabel === null &&
        currentSample.cementReduction !== null
      ) {
        cementReductionLabel = currentSample.cementReduction
      }
    }
  }
  matchedVariation.cO2DosageLabel = cO2DosageLabel
  matchedVariation.cementReductionLabel = cementReductionLabel
  addAveragedPropertiesToVariation(
    matchedVariation,
    propertiesToAverage,
    averages,
    strengthAverageObj,
    cementEfficiencyAverageObj
  )
  removeInvalidStrengthAndCementEfficiencyIntervals(
    matchedVariation,
    strengthAverageObj,
    cementEfficiencyAverageObj
  )
}

/**
 * Function that loops through selected variations ids and returns the matching variation
 * @param {String[]} variationIds an array of ids of the selected variations
 * @param {Object[]} variations all variations of a mix group
 * @returns {Object[]} the variations whose ids were included in variationIds
 */
export const matchVariationsToVariationIds = (
  variationIds: string[],
  variations: BaleenMixVariation[]
) => {
  let index = 0
  const newVariations = []
  for (const id of variationIds) {
    if (id === 'all') continue
    const match = variations.find(variation => id === variation.variationId)
    if (match) {
      match.fillColor = baleenMixVariationColors[index]
      match.haloColor = baleenMixVariationHaloColors[index]
      newVariations.push(match)
      index++
    }
  }
  return newVariations
}

/**
 * Function that iterates through the selected data column ids and returns the matching data column option
 * @param {String[]} selectedIds an array of ids of selected data columns
 * @returns {Object[]} the data columns whods ids were included in selectedIds
 */
export const matchhDataColumnToDataColumnIds = (selectedIds: string[]) => {
  const matched = []
  for (const id of selectedIds) {
    const match = dataColumnOptions.find(option => option.id === id)
    if (match) matched.push(match)
  }
  return matched
}

/**
 * Function that goes through the samples of each variation, creates interval options, and
 * returns those interval options in an array of objects and Set form
 * @param {Object[]} variations selected variations
 * @returns {Object} the interval options in set and array form
 */
export const getSelectedVariationIntervalOptions = (
  variations: BaleenMixVariation[]
) => {
  const intervalOptionsSet = new Set()
  for (const variation of variations) {
    const variationSamples = variation.samples
    for (const batchId in variationSamples) {
      const sample = variationSamples[batchId]
      for (const interval in sample.strengths) {
        if (intervalOptionsSet.has(interval)) continue
        intervalOptionsSet.add(interval)
      }
    }
  }
  const intervalOptionsArr: { id: BaleenIntervals; name: string }[] = []
  intervalOptionsSet.forEach(key => {
    const option = {
      id: key,
      name: getIntervalString(key),
    }
    intervalOptionsArr.push(option)
  })
  intervalOptionsArr.sort((a, b) => Number(a.id) - Number(b.id))
  return { intervalOptionsSet, intervalOptionsArr }
}

/**
 * Function to get default (valid) variation IDs from localStorage
 * @param {String[]} storedVariationIds variation ids that were stored in localStorage aka first render url params
 * @param {Object[]} variationOptions variation options that can be selected via filter panel autocomplete
 * @returns {String[]} the chosen variation ids
 */
export const getDefaultVariationIdsFromUrl = (
  storedVariationIds: string[],
  variationOptions: FilterOption[]
) => {
  let validStoredVariationIds = []
  let variationIds = []
  // determine which of the url parameter variation ids are valid
  for (const id of storedVariationIds) {
    if (variationOptions.some(option => option.id === id)) {
      validStoredVariationIds.push(id)
    }
  }
  // if ALL is an option
  if (variationOptions.some(option => option.id === 'all')) {
    // if all is selected or if it isn't but all other options are selected
    if (
      validStoredVariationIds.includes('all') ||
      (!validStoredVariationIds.includes('all') &&
        variationIds.length === variationOptions.length - 1)
    ) {
      // include all options
      variationIds = variationOptions.map(option => option.id)
    } else {
      variationIds = validStoredVariationIds
    }
  } else {
    variationIds = validStoredVariationIds
  }
  return variationIds
}

/**
 * Function to get the default selected variations and ids of those variations
 * @param {String[]} storedVariationIds variation ids that were stored in localStorage aka first render url params
 * @param {Object[]} variationOptions variation options that can be selected via filter panel autocomplete
 * @param {Object[]} variationsWithStrengths digested variations
 * @returns {Object} the default selected variations and their ids
 */
export const getDefaultVariationData = (
  storedVariationIds: string[],
  variationOptions: FilterOption[],
  variationsWithStrengths: BaleenMixVariation[]
) => {
  let variationIds: string[] = []
  let defaultVariations = []
  // if there are variations in localStorage, check if they're valid and make the valid ones the default selected variations
  if (storedVariationIds?.length) {
    variationIds = getDefaultVariationIdsFromUrl(
      storedVariationIds,
      variationOptions
    )
    const matchedVariations = matchVariationsToVariationIds(
      variationIds,
      variationsWithStrengths
    )
    if (matchedVariations.length > BALEEN_SELECTED_VARIATIONS_LIMIT) {
      defaultVariations = matchedVariations.slice(
        0,
        BALEEN_SELECTED_VARIATIONS_LIMIT
      )
      variationIds = defaultVariations.map(variation => variation.variationId)
    } else {
      defaultVariations = matchedVariations
    }
  } else {
    // otherwise get the default variations by picking from among options extracted from mix group
    if (variationOptions.some(option => option.id === 'all')) {
      variationIds.push('all')
      defaultVariations = variationsWithStrengths
    } else {
      defaultVariations = variationsWithStrengths.slice(
        0,
        BALEEN_SELECTED_VARIATIONS_LIMIT
      )
    }
    defaultVariations.forEach((defaultVariation, index) => {
      defaultVariation.fillColor = baleenMixVariationColors[index]
      defaultVariation.haloColor = baleenMixVariationHaloColors[index]
    })
    for (const variation of defaultVariations) {
      variationIds.push(variation.variationId)
    }
  }
  return {
    newSelectedVariations: defaultVariations,
    newVariationIds: variationIds,
  }
}

/**
 * Function to get variation variation ids, interval, and variation table column ids from url params
 * @param {Object} settings url param settings
 * @returns {Object} variation ids, interval, and variation table column ids
 */
export const extractFrontendSettings = (settings: IURLParamSettings) => {
  const variationIds =
    settings.variationIds && settings.variationIds.length > 0
      ? [...settings.variationIds]
      : []
  const interval =
    settings.interval && settings.interval.length > 0
      ? [settings.interval[0]]
      : []
  const dataColumnIds =
    settings.dataColumns && settings.dataColumns.length > 0
      ? [...settings.dataColumns]
      : []
  const yAxis =
    settings.yAxis && settings.yAxis.length > 0 ? [settings.yAxis[0]] : []
  return { variationIds, interval, yAxis, dataColumnIds }
}

/**
 * Function to get the data point tooltip format for baleen variability graph
 * @param {BaleenYAxis} selectedProperty
 * @param {boolean} isMetric whether or not the measurement system is metric
 * @returns {String} the data point tooltip format
 */
export const tooltipPointFormat = (
  selectedProperty: BaleenYAxis,
  isMetric: boolean,
  cO2DosageUnit: CO2DosageUnit,
  point: Highcharts.TooltipFormatterContextObject
) => {
  const tooltipProperties = ['cementContent', 'batchStrength', 'slump', 'air']
  const newTooltipProperties = tooltipProperties.filter(
    property => property !== selectedProperty
  )
  let tooltip: string = `<b>Date</b>: ${point.productionDate ?? '-'}<br/>
    <b>Ticket ID</b>: ${point.ticketId ?? '-'}<br/>`
  const selectedPropertyAxisLabel = isMetric
    ? baleenMetricAxisLabels[selectedProperty]
    : baleenImperialAxisLabels[selectedProperty]
  const selectedPropertyPrecision = getBaleenTooltipBatchSampleUnitPrecision(
    selectedProperty,
    isMetric,
    cO2DosageUnit
  )
  if (point.y) {
    const selectedPropertyStr = `<b>${selectedPropertyAxisLabel}</b>: ${point.y.toFixed(
      selectedPropertyPrecision
    )}<br/>`
    tooltip = tooltip.concat(selectedPropertyStr)
  }
  newTooltipProperties.forEach(property => {
    if (point[property] !== null) {
      const str = isMetric
        ? `<b>${baleenMetricAxisLabels[property]}: </b>${point[
            property
          ].toFixed(
            getBaleenTooltipBatchSampleUnitPrecision(property, true)
          )}<br/>`
        : `<b>${baleenImperialAxisLabels[property]}: </b>${point[
            property
          ].toFixed(
            getBaleenTooltipBatchSampleUnitPrecision(property, false)
          )}<br/>`
      tooltip = tooltip.concat(str)
    } else {
      const str = `<b>${baleenMetricAxisLabels[property]}: </b>-<br/>`
      tooltip = tooltip.concat(str)
    }
  })
  return tooltip
}

/**
 *
 * @param {BaleenOutlierStatus} outlierStatus the outlier status of the baleen sample
 * @param {String[]} colors an array containing the fill color for the variation's marker in the first slot and the halo colr in the 2nd slot
 * @param {BaleenOutlierType} outlierType type of outlier
 * @returns {Object} the style for the data point based on the outlier status
 */
export const getVariabilityGraphMarker = (
  outlierStatus: BaleenOutlierStatus,
  colors: Array<string>,
  outlierType: BaleenOutlierType
) => {
  if (
    outlierStatus === 'Sample' ||
    outlierStatus === 'PotentialOutlierUnchecked'
  ) {
    return {
      radius: 4.5,
      fillColor: colors[0],
      lineColor: '#FFFFFF',
      lineWidth: 0,
      symbol: dataPointsSymbols[0],
    }
  } else if (
    outlierStatus === 'PotentialOutlierChecked' &&
    outlierType === null
  ) {
    return {
      fillColor: colors[0],
      lineColor: colors[1],
      lineWidth: 10,
      radius: 4.5,
      symbol: dataPointsSymbols[0],
    }
  } else if (
    (outlierStatus === 'Outlier' ||
      outlierStatus === 'PotentialOutlierChecked') &&
    outlierType !== null
  ) {
    return {
      fillColor: 'transparent',
      lineColor: colors[0],
      lineWidth: 2,
      radius: 4.5,
      symbol: dataPointsSymbols[0],
    }
  }
}

/**
 *
 * @param {BaleenOutlierStatus} outlierStatus the outlier status of the baleen sample
 * @param {String} color the line and fill color for the marker
 * @returns {Object} the style for the data point based on the outlier status
 */
export const getVariabilityGraphLegendMarker = (
  outlierStatus: BaleenOutlierStatus,
  color: string
) => {
  if (outlierStatus === 'Outlier') {
    return {
      fillColor: 'transparent',
      lineColor: color,
      lineWidth: 2,
      radius: 4.5,
      symbol: dataPointsSymbols[0],
    }
  } else {
    return {
      radius: 4.5,
      fillColor: color,
      lineColor: '#FFFFFF',
      lineWidth: 0,
      symbol: dataPointsSymbols[0],
    }
  }
}

/**
 *
 * @param {String} variationIdLabel variation id label of the variation that has no data for the selected property
 * @param {String} selectedProperty the selected y axis
 * @param {String} selectedInterval the selected interval
 * @returns {String} error string for the variation and selected property
 */
export const getNoSelectedPropertySampleDataAlert = (
  variationIdLabel: string,
  selectedProperty: string,
  selectedInterval: string
) => {
  switch (selectedProperty) {
    case 'batchStrength':
      return `${variationIdLabel} does not have ${getVariabilityGraphIntervalErrorString(
        selectedInterval
      )} samples`
    case 'air':
      return `${variationIdLabel} does not have Air Content values`
    case 'slump':
      return `${variationIdLabel} does not have Slump values`
    case 'concreteTemperature':
      return `${variationIdLabel} does not have Concrete Temperature values`
    case 'batchWaterCementRatio':
      return `${variationIdLabel} does not have Water-Cement Ratio values`
    case 'cementContent':
      return `${variationIdLabel} does not have Cement Content values`
    case 'cO2Dosage':
      return `${variationIdLabel} does not have CO₂ Dosage values`
    default:
      return ''
  }
}

/**
 * Function to create a new series and add it to the list. Return updated min/max for the Y axis of the graph
 * @param {IParamObj} paramObj
 * @returns {Object}
 */
export const processVariabilityGraphVariation = (paramObj: IParamObj) => {
  const {
    selected,
    selectedProperty,
    highChartsSeries,
    noSampleData,
    outlierStatus,
    selectedInterval,
    isMetric,
  } = paramObj
  let { min, max } = paramObj
  const selectedDataAsTuple: Array<[
    number,
    number,
    number | null,
    number | null,
    number | null,
    number | null,
    Date | null,
    string,
    number | null,
    number,
    BaleenOutlierStatus,
    string | null,
    string,
    BaleenOutlierType
  ]> = []
  const selectedPropertyValues: Array<number> = []
  let testNo = 1

  const variationSamples = []
  // add batchInterval and batchStrength to each of the variation's samples
  for (const batchId in selected.samples) {
    const currentSample = selected.samples[batchId]
    if (currentSample.strengths[selectedInterval]) {
      variationSamples.push({
        ...currentSample,
        batchInterval: Number(selectedInterval),
        batchStrength: currentSample.strengths[selectedInterval].strength,
      })
    } else {
      variationSamples.push({
        ...currentSample,
        batchInterval: Number(selectedInterval),
        batchStrength: null,
      })
    }
  }

  // only consider non null values for the selected property/y-axis
  const supportedFreshProperties = variationSamples.filter(
    freshProperty => freshProperty[selectedProperty] !== null
  )
  // A supported fresh property is one that has a non-null property value
  // Include the data you need for each highcharts dataPoint in selectedDataAsTuple
  supportedFreshProperties.forEach(supportedFreshProperty => {
    selectedDataAsTuple.push([
      testNo,
      supportedFreshProperty[selectedProperty],
      supportedFreshProperty['batchStrength'],
      supportedFreshProperty['cementContent'],
      supportedFreshProperty['slump'],
      supportedFreshProperty['air'],
      supportedFreshProperty['productionDate'],
      supportedFreshProperty['ticketId'],
      supportedFreshProperty['concreteTemperature'],
      supportedFreshProperty['batchId'],
      supportedFreshProperty['outlierStatus'],
      supportedFreshProperty['outlierReasonDropdown'],
      supportedFreshProperty['variationId'],
      supportedFreshProperty['outlierType'],
    ])
    testNo += 1
  })

  // Don't show label that outlier series is missing data
  if (outlierStatus === 'Outlier' && !selectedDataAsTuple.length)
    return { newMin: min, newMax: max }
  // Move to next mix variation if there are no supported fresh properties. Show label that data is missing
  if (!selectedDataAsTuple.length) {
    noSampleData.push(
      getNoSelectedPropertySampleDataAlert(
        selected.variationIdLabel,
        selectedProperty,
        selectedInterval
      )
    )
    return { newMin: min, newMax: max }
  }

  // create an array of values that will be on the y-axis. used to determine min and max.
  selectedDataAsTuple.forEach(([, selectedPropertyValue]) => {
    selectedPropertyValues.push(selectedPropertyValue)
  })
  // sort the values and determine the min and max
  selectedPropertyValues.sort((a, b) => a - b)
  if (selectedPropertyValues.length > 0 && selectedPropertyValues[0] < min) {
    min = selectedPropertyValues[0]
  }
  if (
    selectedPropertyValues.length > 0 &&
    selectedPropertyValues[selectedPropertyValues.length - 1] > max
  ) {
    max = selectedPropertyValues[selectedPropertyValues.length - 1]
  }
  // for each supported fresh property, include the relevant data for each data point of the series. including marker style.
  const freshPropertiesData = selectedDataAsTuple.map(row => {
    const colors = [selected.fillColor, selected.haloColor]
    return {
      x: row[0],
      batchStrength: row[2],
      y: Number(row[1]),
      color: selected.fillColor,
      ticketId: row[7],
      productionDate: getFormattedDate(row[6], 'YYYYMMDD', '-', true),
      cementContent: row[3],
      slump: row[4],
      air: row[5],
      batchId: row[9],
      outlierStatus: row[10],
      outlierReasonDropdown: row[11],
      variationId: row[12],
      outlierType: row[13],
      marker: getVariabilityGraphMarker(row[10], colors, row[13]),
    }
  })
  // add the series to the list
  highChartsSeries.push({
    showInLegend: true,
    name: selected.variationIdLabel,
    data: freshPropertiesData,
    color: selected.fillColor,
    marker: getVariabilityGraphLegendMarker(outlierStatus, selected.fillColor),
    tooltip: {
      headerFormat: '',
      pointFormatter: function() {
        const pointData = this
        if (pointData) {
          return tooltipPointFormat(
            selectedProperty,
            isMetric,
            selected.cO2DosageUnit,
            pointData
          )
        }
      },
    },
    type: 'scatter',
  })
  return { newMin: min, newMax: max }
}

/**
 * Function that goes through a variation's samples and stores its samples and confirmed outliers in separate objects
 * @param {BaleenMixVariation} variation a digested baleen mix variations
 * @param {Object} variationsSamples object that will contain the samples or potential outliers of a variation
 * @param {Object} variationsOutliers object that will contain the confirmed outliers of a variation
 */
export const populateVariationSamplesAndOutliers = (
  variation: BaleenMixVariation,
  variationsSamples: { [key: string]: BaleenSample[] },
  variationsOutliers: { [key: string]: BaleenSample[] }
) => {
  for (const batchId in variation.samples) {
    const variationSample: BaleenSample = variation.samples[batchId]
    const sampleOutlierType = variationSample.outlierType
    if (sampleOutlierType === null) {
      if (variationsSamples[variation.variationId]) {
        variationsSamples[variation.variationId].push({
          ...variationSample,
        })
      } else {
        variationsSamples[variation.variationId] = [{ ...variationSample }]
      }
    } else {
      if (variationsOutliers[variation.variationId]) {
        variationsOutliers[variation.variationId].push({
          ...variationSample,
        })
      } else {
        variationsOutliers[variation.variationId] = [{ ...variationSample }]
      }
    }
  }
}

/**
 * This function keeps track of the unique production dates for the samples of a variation.
 * It also creates a version of the variation that only has samples where the outlier status is not 'Outlier'
 * and another version of the variation that only has samples where the outlier status is 'Outlier'
 * @param {BaleenMixVariation} variation a digested baleen mix variation
 * @param {Set} uniqueDates a set that will contain production dates for each sample of the variation
 * @param {BaleenMixVariation[]} variationsWithOnlySamples an array of variations containing only samples that have not been confirmed as an outlier
 * @param {BaleenMixVariation[]} variationsWithOnlyOutliers an array of variations containing only samples that have been confirmed as outliers
 * @param {Object} variationsSamples object that will contain the samples or potential outliers of a variation
 * @param {Object} variationsOutliers object that will contain the confirmed outliers of a variation
 */
export const isolateVariationsWithSamplesAndOutliers = (
  variation: BaleenMixVariation,
  uniqueDates: Set<string>,
  variationsWithOnlySamples: BaleenMixVariation[],
  variationsWithOnlyOutliers: BaleenMixVariation[],
  variationsSamples: { [key: string]: BaleenSample[] },
  variationsOutliers: { [key: string]: BaleenSample[] },
  selectedProperty: BaleenYAxis,
  selectedInterval: BaleenIntervals
) => {
  if (variationsOutliers[variation.variationId]) {
    const outliersArray = variationsOutliers[variation.variationId]
    const copiedVariation = cloneDeep(variation)
    copiedVariation.variationIdLabel = `${variation.variationIdLabel} Outliers`
    copiedVariation.samples = {}
    for (const outlier of outliersArray) {
      copiedVariation.samples[outlier.batchId] = outlier
      addUniqueDate(uniqueDates, outlier, selectedProperty, selectedInterval)
    }
    variationsWithOnlyOutliers.push(copiedVariation)
  }
  if (variationsSamples[variation.variationId]) {
    const samplesArray = variationsSamples[variation.variationId]
    const copiedVariation = cloneDeep(variation)
    copiedVariation.samples = {}
    for (const sample of samplesArray) {
      copiedVariation.samples[sample.batchId] = sample
      addUniqueDate(uniqueDates, sample, selectedProperty, selectedInterval)
    }
    variationsWithOnlySamples.push(copiedVariation)
  }
}

/**
 * Prepares data for graph, sets config for Highcharts, collects list of data error messages.
 * @param {Array<MixGroupVariation>} selectedMixVariations array of mix variations to appear in the graph.
 * @param {String} selectedProperty currently selected property used to color graph points.
 * @param {Boolean} isMetric whether or not the measurement system is metric
 * @returns {[Options, Array]} A graph options object including data, and an array of messages.
 */
export const getBaleenVariabilityGraphOptionsAndErrors = (
  selectedMixVariations: Array<BaleenMixVariation>,
  selectedProperty: BaleenYAxis,
  isMetric: boolean,
  selectedInterval: BaleenIntervals,
  handlePointClick: (
    arg1: number,
    arg2: BaleenOutlierStatus,
    arg3: string,
    arg4: string,
    arg5: BaleenOutlierType
  ) => void
): [Options, Array<string>] => {
  let noSampleData: Array<string> = []
  let highChartsSeries: Array<SeriesScatterOptions> = []

  let min = 0
  let max = 0
  const variationsSamples: { [key: string]: BaleenSample[] } = {}
  const variationsOutliers: { [key: string]: BaleenSample[] } = {}
  selectedMixVariations.forEach(variation => {
    populateVariationSamplesAndOutliers(
      variation,
      variationsSamples,
      variationsOutliers
    )
  })

  const variationsWithOnlySamples: BaleenMixVariation[] = []
  const variationsWithOnlyOutliers: BaleenMixVariation[] = []
  const uniqueDates = new Set<string>()
  // Create a version of each variation where one has only samples containing confirmed outliers and
  // one only has unconfirmed outliers or regular samples in order to have separate series in highcharts.
  selectedMixVariations.forEach(variation => {
    isolateVariationsWithSamplesAndOutliers(
      variation,
      uniqueDates,
      variationsWithOnlySamples,
      variationsWithOnlyOutliers,
      variationsSamples,
      variationsOutliers,
      selectedProperty,
      selectedInterval
    )
  })

  const dates = Array.from(new Set(uniqueDates)).sort(
    (a, b) => new Date(a) - new Date(b)
  )
  let dateString = ''
  if (dates.length === 1) {
    dateString = `${getFormattedDate(
      new Date(dates[0]),
      'YYYYMMDD',
      '-',
      true
    )}`
  } else if (dates.length > 1) {
    dateString = `${getFormattedDate(
      new Date(dates[0]),
      'YYYYMMDD',
      '-',
      true
    )} - ${getFormattedDate(
      new Date(dates[dates.length - 1]),
      'YYYYMMDD',
      '-',
      true
    )}`
  }

  // go through variations with the outlier samples removed and create a series
  // also update the min and max for the yAxis
  variationsWithOnlySamples.forEach((selected, index) => {
    const paramObj = {
      selected,
      selectedProperty,
      highChartsSeries,
      noSampleData,
      min,
      max,
      outlierStatus: 'Sample' as BaleenOutlierStatus,
      selectedInterval,
      handlePointClick,
      isMetric,
    }
    const { newMin, newMax } = processVariabilityGraphVariation(paramObj)
    min = newMin
    max = newMax
  })

  // go through variations with the non-outlier samples removed and create a series that is added to highChartsSeries
  // also update the min and max for the yAxis
  variationsWithOnlyOutliers.forEach((selected, index) => {
    const paramObj = {
      selected,
      selectedProperty,
      highChartsSeries,
      noSampleData,
      min,
      max,
      outlierStatus: 'Outlier' as BaleenOutlierStatus,
      selectedInterval,
      isMetric,
    }
    const { newMin, newMax } = processVariabilityGraphVariation(paramObj)
    min = newMin
    max = newMax
  })

  const yMaxConstant = isMetric ? 3 : 500
  const graphOptions: Options = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      events: {
        load: function() {
          // Add tooltip for date format in chart
          const chart = this
          const overlay = document.createElement('div')
          overlay.style.position = 'absolute'
          overlay.style.top = '30px'
          overlay.style.left = '10px'
          overlay.style.width = '270px'
          overlay.style.height = '30px'
          overlay.style.backgroundColor = 'transparent'
          overlay.style.pointerEvents = 'all'

          overlay.addEventListener('mouseover', e => {
            chart.dateTooltip = this.renderer
              .label('YYYY-MM-DD', 260, 34, 'rectangle')
              .css({
                color: '#FFFFFF',
              })
              .attr({
                fill: 'rgba(97, 97, 97, 0.90)',
                padding: 8,
                r: 4,
              })
              .add()
              .toFront()
          })

          overlay.addEventListener('mouseout', e => {
            if (chart.dateTooltip) {
              chart.dateTooltip.destroy()
            }
          })

          chart.container.parentNode.appendChild(overlay)
        },
      },
    },
    title: {
      text: 'Variability Graph',
      align: 'left',
      margin: 25,
    },
    subtitle: {
      text: dateString
        ? `<b style = "font-size: 16px;">Date Range: </b><span style = "font-size: 14px;">${dateString}</span>`
        : undefined,
      align: 'left',
      style: {
        fontFamily: '"Urbanist", sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
      },
      y: 45,
    },
    xAxis: {
      title: {
        text: isMetric
          ? baleenMetricAxisLabels.samples
          : baleenImperialAxisLabels.samples,
      },
      tickInterval: 1,
    },
    yAxis: {
      title: {
        text: isMetric
          ? baleenMetricAxisLabels[selectedProperty]
          : baleenImperialAxisLabels[selectedProperty],
      },
      max: selectedProperty === 'batchStrength' ? max + yMaxConstant : null,
      min: min,
    },
    legend: {
      verticalAlign: 'bottom',
      align: 'left',
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function(e) {
              handlePointClick(
                e.point.batchId,
                e.point.outlierStatus,
                e.point.outlierReasonDropdown,
                e.point.variationId,
                e.point.outlierType
              )
            },
          },
        },
      },
    },
    series: highChartsSeries,
  }
  return [graphOptions, noSampleData]
}

export const changeSampleOutlierStatus = (
  batchId: number,
  prevSamples: BaleenSample[],
  newOutlierStatus: BaleenOutlierStatus,
  removeOutlierReason: boolean
) => {
  const matchedSample = prevSamples.find(sample => sample.batchId === batchId)
  if (!matchedSample) return prevSamples
  matchedSample.outlierStatus = newOutlierStatus
  if (removeOutlierReason) {
    matchedSample.outlierReasonDropdown = null
    matchedSample.outlierReasonTextField = null
  }
  return [...prevSamples]
}

export const changeVariationSampleOutlierStatus = (
  batchId: number,
  variationId: string,
  prevVariations: BaleenMixVariation[],
  newOutlierStatus: BaleenOutlierStatus,
  removeOutlierReason: boolean
) => {
  const matchedVariation = prevVariations.find(
    variation => variation.variationId === variationId
  )
  if (!matchedVariation) return prevVariations
  const matchedSample = matchedVariation.samples[batchId]
  matchedSample.outlierStatus = newOutlierStatus
  if (removeOutlierReason) {
    matchedSample.outlierReasonDropdown = null
    matchedSample.outlierReasonTextField = null
  }
  variationRecalculation(matchedVariation)
  return [...prevVariations]
}

export const changeSampleOutlierReasonDropdown = (
  batchId: number,
  prevSamples: BaleenSample[],
  outlierReasonDropdown: string | null
) => {
  const sample = prevSamples.find(sample => sample.batchId === batchId)
  if (!sample) return prevSamples
  sample.outlierReasonDropdown = outlierReasonDropdown
  sample.outlierReasonTextField = null
  return [...prevSamples]
}

export const changeVariationSampleOutlierReasonDropdown = (
  batchId: number,
  variationId: string,
  prevVariations: BaleenMixVariation[],
  outlierReasonDropdown: string | null
) => {
  const matchedVariation = prevVariations.find(
    variation => variation.variationId === variationId
  )
  if (!matchedVariation) return prevVariations
  matchedVariation.samples[
    batchId
  ].outlierReasonDropdown = outlierReasonDropdown
  matchedVariation.samples[batchId].outlierReasonTextField = null
  return [...prevVariations]
}

export const changeSampleOutlierReasonTextField = (
  batchId: number,
  prevSamples: BaleenSample[],
  outlierReasonTextField: string
) => {
  const outlier = prevSamples.find(sample => sample.batchId === batchId)
  if (!outlier) return prevSamples
  outlier.outlierReasonTextField =
    outlierReasonTextField !== '' ? outlierReasonTextField : null
  return [...prevSamples]
}

export const changeVariationSampleOutlierReasonTextField = (
  batchId: number,
  variationId: string,
  prevVariations: BaleenMixVariation[],
  outlierReasonTextField: string
) => {
  const matchedVariation = prevVariations.find(
    variation => variation.variationId === variationId
  )
  if (!matchedVariation) return prevVariations
  matchedVariation.samples[batchId].outlierReasonTextField =
    outlierReasonTextField !== '' ? outlierReasonTextField : null
  return [...prevVariations]
}

/**
 * A function that goes through all the samples and resets any samples belonging to variation that is not
 * among the selected variations
 * @param {BaleenSample[]} samples
 * @param {string[]} variationIds the variation ids of the selected variations
 * @param {IBaleenSamplesObj} originalOutliers the confirmed outliers
 */
export const resetSamplesWhoseVariationsAreRemoved = (
  samples: BaleenSample[],
  variationIds: string[],
  originalOutliers: IBaleenSamplesObj
) => {
  samples.forEach(sample => {
    if (
      !variationIds.includes(sample.variationId) &&
      sample.outlierStatus !== 'Sample' &&
      sample.outlierType === null
    ) {
      sample.outlierStatus = 'Sample'
      sample.outlierReasonDropdown = null
      sample.outlierReasonTextField = null
    } else if (
      !variationIds.includes(sample.variationId) &&
      sample.outlierStatus !== 'Sample' &&
      originalOutliers[sample.batchId]
    ) {
      sample.outlierStatus = 'Outlier'
      sample.outlierReasonDropdown =
        originalOutliers[sample.batchId].outlierReasonDropdown
      sample.outlierReasonTextField =
        originalOutliers[sample.batchId].outlierReasonTextField
    }
  })
}

/**
 * A function that goes through each variation's samples and resets any samples belonging to a
 * variation that is not among the selected variations.
 * @param {BaleenMixVariation[]} variations
 * @param {string[]} variationIds the variation ids of the selected variations
 * @param {IBaleenSamplesObj} originalOutliers the confirmed outliers
 */
export const resetVariationSamplesWhoseVariationsAreRemoved = (
  variations: BaleenMixVariation[],
  variationIds: string[],
  originalOutliers: IBaleenSamplesObj
) => {
  variations.forEach(variation => {
    const variationSamples = variation.samples
    for (const batchId in variationSamples) {
      let sample = variationSamples[batchId]
      if (
        !variationIds.includes(sample.variationId) &&
        sample.outlierStatus !== 'Sample' &&
        sample.outlierType === null
      ) {
        sample.outlierStatus = 'Sample'
        sample.outlierReasonDropdown = null
        sample.outlierReasonTextField = null
      } else if (
        !variationIds.includes(sample.variationId) &&
        sample.outlierStatus !== 'Sample' &&
        originalOutliers[sample.batchId]
      ) {
        sample.outlierStatus = 'Outlier'
        sample.outlierReasonDropdown =
          originalOutliers[sample.batchId].outlierReasonDropdown
        sample.outlierReasonTextField =
          originalOutliers[sample.batchId].outlierReasonTextField
      }
    }
    variationRecalculation(variation)
  })
}

/**
 * A function that goes through all the samples and if there are any unchecked potential outliers
 * turn them into samples and clear their reasons.
 * @param {BaleenSample[]} prevSamples
 */
export const resetUncheckedSamples = (prevSamples: BaleenSample[]) => {
  prevSamples.forEach(sample => {
    if (
      sample.outlierStatus === 'PotentialOutlierUnchecked' &&
      sample.outlierType === null
    ) {
      sample.outlierStatus = 'Sample'
      sample.outlierReasonDropdown = null
      sample.outlierReasonTextField = null
    }
  })
}

/**
 * A function that goes through each variation's samples and if there are any unchecked potential outliers
 * turn them into samples and clear their reasons. This function is used for updating the selectedVariations state
 * and the variations state
 * @param {BaleenMixVariation[]} prevVariations
 */
export const resetUncheckedVariationSamples = (
  prevVariations: BaleenMixVariation[]
) => {
  prevVariations.forEach(variation => {
    const variationSamples = variation.samples
    for (const batchId in variationSamples) {
      if (
        variationSamples[batchId].outlierStatus ===
          'PotentialOutlierUnchecked' &&
        variationSamples[batchId].outlierType === null
      ) {
        variationSamples[batchId].outlierStatus = 'Sample'
        variationSamples[batchId].outlierReasonDropdown = null
        variationSamples[batchId].outlierReasonTextField = null
      }
    }
  })
}

/**
 * A function to get the error list of errors associated with each outlier that is
 * preventing submission from being possible.
 * @param {ISubmitErrors} errors
 * @param {BaleenSample[]} outliers
 * @param {IBaleenSamplesObj} originalOutliers
 */
export const getBaleenOutlierSubmitErrors = (
  errors: ISubmitErrors,
  outliers: BaleenSample[],
  originalOutliers: IBaleenSamplesObj
) => {
  for (const outlier of outliers) {
    const isOriginalOutlier = !!originalOutliers[outlier.batchId]
    const outlierErrorsList = []
    // if it's a new outlier, checkbox needs to be checked and a reason needs to be provided
    if (!outlier.outlierReasonDropdown) {
      outlierErrorsList.push({
        name: 'missingOutlierReasonDropdown',
        message:
          'Remove unselected outliers or check the outlier column, add reasoning to save.',
      })
    }
    // textfield can only be an issue if
    // it is an original outlier, the checkbox is checked ( aka we're editing a confirmed outlier), and the dropdown reason is OTHER or
    // it is not an original outlier and the dropdown reason is Other
    if (
      (isOriginalOutlier &&
        outlier.outlierStatus === 'PotentialOutlierChecked' &&
        outlier.outlierReasonDropdown === BaleenOutlierReasons.Other &&
        !outlier.outlierReasonTextField?.trim()) ||
      (!isOriginalOutlier &&
        outlier.outlierReasonDropdown === BaleenOutlierReasons.Other &&
        !outlier.outlierReasonTextField?.trim())
    ) {
      outlierErrorsList.push({
        name: 'missingOutlierReasonTextField',
        message:
          'Remove unselected outliers or check the outlier column, add reasoning to save.',
      })
    }
    if (
      !isOriginalOutlier &&
      outlier.outlierStatus !== 'PotentialOutlierChecked'
    ) {
      outlierErrorsList.push({
        name: 'incorrectOutlierStatus',
        message:
          'Remove unselected outliers or check the outlier column, add reasoning to save.',
      })
    }
    if (outlierErrorsList.length > 0)
      errors[outlier.batchId] = outlierErrorsList
  }
}

export function updateVariationIdLabel(
  currentVariationIdLabel: string,
  currentSample: BaleenSample,
  isMetric: boolean
) {
  const hyphenIndex = currentVariationIdLabel.lastIndexOf('-')
  let updatedVariationIdLabel = currentVariationIdLabel
  if (hyphenIndex !== -1) {
    updatedVariationIdLabel = currentVariationIdLabel.slice(0, hyphenIndex)
  }
  const precision = getCO2DosagePrecision(currentSample.cO2DosageUnit, isMetric)
  const currentCO2Dosage = currentSample.cO2Dosage?.toFixed(precision)
  const cO2DosageUnitLabel =
    currentCO2Dosage !== null && currentCO2Dosage !== undefined
      ? getCO2DosageUnitLabel(currentSample.cO2DosageUnit, isMetric)
      : ''
  updatedVariationIdLabel = `${updatedVariationIdLabel}-${currentCO2Dosage ??
    'null'}${cO2DosageUnitLabel}`
  return updatedVariationIdLabel
}

export const showPointClickConfirmationModal = (
  batchId: number,
  variationId: string,
  outlierStatus: BaleenOutlierStatus,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<BaleenModalData | null>>
) => {
  // Create data to be passed into handleConfirm prop. The prop will then pass data into handlePointClick function
  setModalData({
    batchId: batchId,
    outlierStatus: outlierStatus,
    outlierReasonDropdown: null,
    variationId: variationId,
    outlierType: null,
  })
  setModalOpen(true)
}

export interface IFormattedOutlierForSave {
  batchTestSampleId: number
  outlierReason: BaleenOutlierReasons | string | null
}
export interface IPopulateOutlierSavePayloadArrayParams {
  outlierArray: IFormattedOutlierForSave[]
  outliersToAdd: BaleenSample[]
  outliersToRemove: BaleenSample[]
  changedSamplesDictionary: IBaleenSamplesObj
  newOriginalOutliers: IBaleenSamplesObj
}

/**
 * A function that creates the payload necessary for updating outliers/samples in Kelowna.  Also stores all the changes
 * to each sample/outlier and what the new confirmed outliers will become assuming the save is successful.
 * @param {IPopulateOutlierSavePayloadArrayParams} populateOutlierSavePayloadArrayParams
 */
export const populateOutlierSavePayloadArray = (
  populateOutlierSavePayloadArrayParams: IPopulateOutlierSavePayloadArrayParams
) => {
  const {
    outliersToAdd,
    outliersToRemove,
    outlierArray,
    changedSamplesDictionary,
    newOriginalOutliers,
  } = populateOutlierSavePayloadArrayParams
  for (const outlier of outliersToAdd) {
    const formattedOutlierToAdd = {
      batchTestSampleId: outlier.batchId,
      outlierReason:
        outlier.outlierReasonDropdown === BaleenOutlierReasons.Other
          ? outlier.outlierReasonTextField
          : outlier.outlierReasonDropdown,
    }

    outlierArray.push(formattedOutlierToAdd)
    const clonedOutlier = cloneDeep(outlier)
    clonedOutlier.outlierType = 'Orca'
    clonedOutlier.outlierStatus = 'Outlier'
    changedSamplesDictionary[clonedOutlier.batchId] = clonedOutlier
    newOriginalOutliers[outlier.batchId] = cloneDeep(clonedOutlier)
  }
  for (const outlier of outliersToRemove) {
    const formattedOutlierToRemove = {
      batchTestSampleId: outlier.batchId,
      outlierReason: null,
    }
    outlierArray.push(formattedOutlierToRemove)
    const clonedOutlier = cloneDeep(outlier)
    clonedOutlier.outlierType = null
    clonedOutlier.outlierStatus = 'Sample'
    clonedOutlier.outlierReasonDropdown = null
    clonedOutlier.outlierReasonTextField = null
    changedSamplesDictionary[clonedOutlier.batchId] = clonedOutlier
    delete newOriginalOutliers[outlier.batchId]
  }
}

interface IGetNewVariationsAndSamplesAfterSavingParams {
  samples: BaleenSample[]
  outliers: BaleenSample[]
  variations: BaleenMixVariation[]
  selectedVariations: BaleenMixVariation[]
  changedSamplesDictionary: IBaleenSamplesObj
  newOriginalOutliers: IBaleenSamplesObj
}

/**
 * A function that clones baleen's previous state values and makes the appropriate updates
 * so they can be used as the new state values. (ie samples, outliers, variations, selected variations, originalOutliers)
 * @param {IGetNewVariationsAndSamplesAfterSavingParams} params
 * @returns {newSamples: BaleenSample[], newOutliers: BaleenSample[], newVariations: BaleenMixVariation[], newSelectedVariations: BaleenMixVariation[]}
 */
export const getNewVariationsAndSamplesAfterSaving = (
  params: IGetNewVariationsAndSamplesAfterSavingParams
) => {
  const {
    samples,
    outliers,
    variations,
    selectedVariations,
    changedSamplesDictionary,
    newOriginalOutliers,
  } = params
  const samplesCopy = cloneDeep(samples)
  const outliersCopy = cloneDeep(outliers)
  const variationsCopy = cloneDeep(variations)
  const selectedVariationsCopy = cloneDeep(selectedVariations)
  const convertedVariationsObj: IBaleenVariationObj = {}
  const newSamples = samplesCopy.map((sample: BaleenSample) => {
    const currentSampleId = sample.batchId
    if (!changedSamplesDictionary[currentSampleId]) return sample
    else return changedSamplesDictionary[currentSampleId]
  })
  const filteredOutliers = outliersCopy.filter((outlier: BaleenSample) => {
    return !!newOriginalOutliers[outlier.batchId]
  })
  const newOutliers = filteredOutliers.map((sample: BaleenSample) => {
    const currentSampleId = sample.batchId
    if (!changedSamplesDictionary[currentSampleId]) return sample
    else return cloneDeep(changedSamplesDictionary[currentSampleId])
  })
  const newVariations = variationsCopy.map((variation: BaleenMixVariation) => {
    const variationSamples: IBaleenSamplesObj = variation.samples
    for (const batchId in changedSamplesDictionary) {
      if (variationSamples[batchId])
        variationSamples[batchId] = cloneDeep(changedSamplesDictionary[batchId])
    }
    convertedVariationsObj[variation.variationId] = cloneDeep(variation)
    return variation
  })

  const newSelectedVariations = selectedVariationsCopy.map(
    (variation: BaleenMixVariation) => {
      return convertedVariationsObj[variation.variationId]
    }
  )
  return { newSamples, newOutliers, newVariations, newSelectedVariations }
}

/**
 * A function that determines if the outlier's changed outlier reason is valid.
 * It is valid if the reason is different or if the checkbox is unchecked
 * @param {string | null} originalOutlierReasonDropdown
 * @param {string | null} originalOutlierReasonTextField
 * @param {string | null} currentOutlierReasonDropdown
 * @param {string | null} currentOutlierReasonTextField
 * @returns {boolean} true if the the reason is valid. false if not.
 */
export const getIsOutlierReasonChangeValid = (
  originalOutlierReasonDropdown: string | null,
  originalOutlierReasonTextField: string | null,
  currentOutlierReasonDropdown: string | null,
  currentOutlierReasonTextField: string | null
) => {
  const outlierReasonChangedButNotOther =
    originalOutlierReasonDropdown !== currentOutlierReasonDropdown &&
    currentOutlierReasonDropdown !== BaleenOutlierReasons.Other
  const isNewReasonOtherWithDetails =
    originalOutlierReasonDropdown !== BaleenOutlierReasons.Other &&
    currentOutlierReasonDropdown === BaleenOutlierReasons.Other &&
    currentOutlierReasonTextField !== null &&
    currentOutlierReasonTextField.trim() !== ''
  const isCurrentOtherReasonDetailsChangedAndValid =
    originalOutlierReasonDropdown === BaleenOutlierReasons.Other &&
    currentOutlierReasonDropdown === BaleenOutlierReasons.Other &&
    originalOutlierReasonTextField !== currentOutlierReasonTextField &&
    currentOutlierReasonTextField !== null &&
    currentOutlierReasonTextField.trim() !== ''
  return (
    outlierReasonChangedButNotOther ||
    isNewReasonOtherWithDetails ||
    isCurrentOtherReasonDetailsChangedAndValid
  )
}

/**
 * A function that determines if the given outlier is valid for submission
 * For confirmed outliers, the outlier must be checked & the reason must have changed, OR it must be unchecked (2 ways).
 * For new outliers, it must be checked and have a reason.
 * @param  {BaleenSample} outlier
 * @param  {IBaleenSamplesObj} originalOutliers
 * @returns {boolean} returns true if the outlier is invalid. false if it is valid for submission.
 */
export const getIsOutlierFormatInvalidForSubmission = (
  outlier: BaleenSample,
  originalOutliers: IBaleenSamplesObj
) => {
  const isOriginalOutlier = !!originalOutliers[outlier.batchId]
  const currentOutlierReasonDropdown = outlier.outlierReasonDropdown
  const currentOutlierReasonTextField = outlier.outlierReasonTextField
  let isDisabled = true
  if (isOriginalOutlier) {
    const originalOutlierReasonDropdown =
      originalOutliers[outlier.batchId].outlierReasonDropdown
    const originalOutlierReasonTextField =
      originalOutliers[outlier.batchId].outlierReasonTextField

    const isOutlierReasonChangeValid = getIsOutlierReasonChangeValid(
      originalOutlierReasonDropdown,
      originalOutlierReasonTextField,
      currentOutlierReasonDropdown,
      currentOutlierReasonTextField
    )

    const isOutlierMarkedForRemoval =
      outlier.outlierStatus === 'PotentialOutlierUnchecked'
    if (isOutlierReasonChangeValid || isOutlierMarkedForRemoval) {
      isDisabled = false
      return isDisabled
    }
  } else {
    if (currentOutlierReasonDropdown === null) return true
    const hasOutlierReason =
      currentOutlierReasonDropdown !== BaleenOutlierReasons.Other ||
      (currentOutlierReasonDropdown === BaleenOutlierReasons.Other &&
        currentOutlierReasonTextField !== null &&
        currentOutlierReasonTextField.trim() !== '')
    const isMarkedForAddition =
      outlier.outlierStatus === 'PotentialOutlierChecked'
    if (hasOutlierReason && isMarkedForAddition) {
      isDisabled = false
      return isDisabled
    }
  }
  return isDisabled
}

/**
 * Determine if an original outlier was edited or if a new outlier was added to the table.
 * Only outliers belonging to the passed in variation are considered
 * @param {BaleenMixVariation} variation
 * @param {IBaleenSamplesObj} originalOutliers confirmed outliers
 * @returns {boolean}
 */
const getIsVariationSampleEdited = (
  variation: BaleenMixVariation,
  originalOutliers: IBaleenSamplesObj
) => {
  const variationSamples = variation.samples
  for (const batchId in variationSamples) {
    const sample = variationSamples[batchId]
    const isOriginalOutlier = originalOutliers[sample.batchId] !== undefined
    const currentOutlierReasonDropdown = sample.outlierReasonDropdown
    const currentOutlierReasonTextField = sample.outlierReasonTextField
    const currentOutlierStatus = sample.outlierStatus
    if (isOriginalOutlier) {
      const originalOutlierReasonDropdown =
        originalOutliers[batchId].outlierReasonDropdown
      const originalOutlierReasonTextField =
        originalOutliers[batchId].outlierReasonTextField
      const originalOutlierStatus = originalOutliers[batchId].outlierStatus
      const matchingReasonDropdown =
        currentOutlierReasonDropdown === originalOutlierReasonDropdown
      const matchingReasonTextField =
        currentOutlierReasonTextField === originalOutlierReasonTextField
      const matchingStatus = currentOutlierStatus === originalOutlierStatus
      if (
        !(matchingReasonDropdown && matchingReasonTextField && matchingStatus)
      )
        return true
    } else if (currentOutlierStatus !== 'Sample') return true
  }
  return false
}

/**
 * A function that determines whether a variation that is being removed has unsaved changes done to its samples
 * If so, the unsaved changes modal should show. Return true. If not, return false.
 * @param originalOutliers
 * @param oldSelectedVariations
 * @param newSelectedVariations
 * @returns {boolean} true if the the unsaved changes modal should show. false if not.
 */
export const getIsUnsavedChangesModalVisible = (
  originalOutliers: IBaleenSamplesObj,
  oldSelectedVariations: BaleenMixVariation[],
  newSelectedVariations: BaleenMixVariation[]
) => {
  if (oldSelectedVariations.length > 1 && newSelectedVariations.length === 0) {
    // if ALL was deselected
    for (const variation of oldSelectedVariations) {
      if (getIsVariationSampleEdited(variation, originalOutliers)) return true
    }
    return false
  }
  // the rest is if an individual variation was deselected
  const variationIdSet = new Set()
  let missingVariationId = ''
  // Determine which variation ID was removed
  for (const variation of newSelectedVariations) {
    variationIdSet.add(variation.variationId)
  }
  for (const variation of oldSelectedVariations) {
    if (!variationIdSet.has(variation.variationId)) {
      missingVariationId = variation.variationId
      break
    }
  }
  // Find the matching variation using the ID we found
  const matchedVariation = oldSelectedVariations.find(
    variation => variation.variationId === missingVariationId
  )
  if (!matchedVariation) return false
  return getIsVariationSampleEdited(matchedVariation, originalOutliers)
}

/**
 * Function that modifies the current outliers to include or exclude the sample that was clicked on the variability graph
 * and returns the new array of outliers
 * @param batchId
 * @param prevOutliers
 * @param samples
 * @param outlierStatus
 * @param newOutlierStatus
 * @returns
 */
export const getUpdatedOutliersAfterPointClick = (
  batchId: number,
  prevOutliers: BaleenSample[],
  samples: BaleenSample[],
  outlierStatus: BaleenOutlierStatus,
  newOutlierStatus: BaleenOutlierStatus
) => {
  if (newOutlierStatus === 'Sample') {
    return [...prevOutliers.filter(outlier => outlier.batchId !== batchId)]
  } else if (outlierStatus === 'Sample') {
    // if an outlier is being added to the outliers table
    // find the matching sample and change its status
    const matchedSample = samples.find(outlier => outlier.batchId === batchId)
    if (!matchedSample) return prevOutliers
    const matchedOutlier = cloneDeep(matchedSample)
    matchedOutlier.outlierStatus = newOutlierStatus
    // there is a weird bug where if you remove and outlier and save, then click on that outlier via the graph to make it PotentialOutlierChecked, it remembers the old reason.
    // can't figure out why this is happening so i am clearing the reason here before bringing the sample to the outliers table.
    matchedOutlier.outlierReasonDropdown = null
    matchedOutlier.outlierReasonTextField = null
    return [...prevOutliers, matchedOutlier]
  } else {
    // if there is no movement between tables
    const matchedOutlier = prevOutliers.find(
      outlier => outlier.batchId === batchId
    )
    if (!matchedOutlier) return prevOutliers
    matchedOutlier.outlierStatus = newOutlierStatus
    return [...prevOutliers]
  }
}

export const addUniqueDate = (
  dates: Set<string | Date>,
  sample: BaleenSample,
  selectedProperty: BaleenYAxis,
  selectedInterval: BaleenIntervals
) => {
  // If Batch Strength is selected for Y Axis, and no data exists for the selected interval, don't add production date
  if (
    selectedProperty === 'batchStrength' &&
    !sample.strengths.hasOwnProperty(selectedInterval)
  )
    return
  if (
    selectedProperty !== 'batchStrength' &&
    (sample[selectedProperty] === null ||
      sample[selectedProperty] === undefined)
  )
    return
  // Check outlier statuses to determine if production date gets added for current sample
  if (
    (sample.outlierStatus === 'Sample' ||
      sample.outlierStatus === 'PotentialOutlierUnchecked') &&
    sample.productionDate
  ) {
    !dates.has(sample.productionDate.toISOString()) &&
      dates.add(sample.productionDate.toISOString())
  }
}
