import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { makeStyles } from '@material-ui/core/styles'
import CustomerTableLogical from '../Components/CustomerTable/CustomerTableLogical'
import { getDivisionsOverview, getTssReps } from '../Data/TSSDataHelpers'
import {
  Grid,
  Typography,
  Container,
  Box,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import theme from '../../theme/muiTheme'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import { cloneDeep } from 'lodash'
import {
  atomCustomersPageFiltersOptions,
  atomCustomersPageFiltersSettings,
} from '../../Common/tssAtoms'
import { atomCommissioningFilter } from '../../Common/atoms'
import Search from '../Components/Search/Search'
import TableHeadButtons from '../Components/TableHeadButtons/TableHeadButtons'
import { removeActiveFilter } from '../../Common/Helpers/GeneralHelpers'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'

const useStyles = makeStyles(theme => ({
  viewTitle: {
    marginBottom: '1em',
  },
}))

const useStylesFilterPanel = makeStyles({
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '128px',
    [theme.breakpoints.up(1378)]: {
      top: '134px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    top: '128px',
    [theme.breakpoints.up(1378)]: {
      top: '134px',
    },
  },
  btnContainer: {
    marginTop: '0px',
    paddingLeft: '0px',
  },
})

export const CustomersPageView = () => {
  const classes = useStyles()
  const filterPanelClass = useStylesFilterPanel()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [mixDesignOverview, setMixDesignOverview] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [searched, setSearched] = useState('')
  const [selectedTssRep, setSelectedTssRep] = useState()

  // Filter panel variables
  const [
    customersPageFiltersSettings,
    setCustomersPageFiltersSettings,
  ] = useRecoilState(atomCustomersPageFiltersSettings)
  const [
    customersPageFiltersOptions,
    setCustomersPageFiltersOptions,
  ] = useRecoilState(atomCustomersPageFiltersOptions)
  const [, setCommissioningFilter] = useRecoilState(atomCommissioningFilter)
  const [panelOpen, setPanelOpen] = useState(false)
  const [expandFilters, setExpandFilters] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [activeFilters, setActiveFilters] = useState([])

  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)

  // Load data in table rows
  useEffect(() => {
    const getCustomerTableRows = async () => {
      setIsLoading(true)
      const response = await getDivisionsOverview()
      if (response && response.ok) {
        const data = await response.json()
        setMixDesignOverview(data)
        setIsLoading(false)
      }
    }
    getCustomerTableRows()
  }, [])

  useEffect(() => {
    async function initFilterOptions() {
      setIsLoading(true)
      const response = await getTssReps()
      if (response && response.ok) {
        const results = await response.json()
        const tseList = results.map(x => ({ name: x, id: x }))
        setCustomersPageFiltersOptions(prevOptions => {
          return {
            ...prevOptions,
            tse: tseList,
          }
        })
        setIsLoading(false)
        setIsFirstRender(false)
      }
    }
    if (isFirstRender) {
      initFilterOptions()
    }
  }, [
    customersPageFiltersOptions,
    setCustomersPageFiltersOptions,
    isFirstRender,
  ])

  useEffect(() => {
    let count = 0
    const activeFilters = []

    for (const setting in customersPageFiltersSettings) {
      if (customersPageFiltersSettings[setting][0]) {
        count++
        const label = customersPageFiltersSettings[setting][0].name
        activeFilters.push({
          property: setting,
          label: setting === 'tse' ? `TSS Specialist: ${label}` : label,
        })
      }
    }
    setFilterCount(count)
    setActiveFilters(activeFilters)
  }, [isLoading, customersPageFiltersSettings])

  const autoCompleteSingleChangeHandler = (filter, value) => {
    const newSettings = cloneDeep(customersPageFiltersSettings)
    newSettings[filter.property] = value ? [value] : []
    setCustomersPageFiltersSettings(newSettings)

    switch (filter.property) {
      case 'tse':
        setSelectedTssRep(value?.name || null)
        break
      case 'commissioningStatus':
        setCommissioningFilter(value?.name || null)
        break
      default:
        return null
    }
  }

  const getValue = filter => {
    return customersPageFiltersSettings[filter.property][0] || null
  }

  const filterButtonClickHandler = () => {
    setPanelOpen(prev => !prev)
  }

  const filterChipsHandler = filter => {
    setActiveFilters(prev => removeActiveFilter(prev, filter))

    const newSettings = cloneDeep(customersPageFiltersSettings)
    newSettings[filter.property] = []
    setCustomersPageFiltersSettings(newSettings)

    switch (filter.property) {
      case 'tse':
        setSelectedTssRep(null)
        break
      case 'commissioningStatus':
        setCommissioningFilter(null)
        break
      default:
        return null
    }
  }

  const clearAllFilters = () => {
    setCustomersPageFiltersSettings({
      tse: [],
      commissioningStatus: [],
    })
    setSelectedTssRep(null)
    setCommissioningFilter(null)
  }

  const filterPanel = [
    {
      name: 'FILTER BY',
      category: 'filterBy',
      filters: [
        {
          property: 'tse',
          name: 'TSS Specialist',
          componentType: 'AutocompleteSingle',
          options: customersPageFiltersOptions.tse,
          onChangeHandler: autoCompleteSingleChangeHandler,
        },
        {
          property: 'commissioningStatus',
          name: 'Commissioning Status',
          componentType: 'AutocompleteSingle',
          options: customersPageFiltersOptions.commissioningStatus,
          onChangeHandler: autoCompleteSingleChangeHandler,
        },
      ],
    },
  ]

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em' }}
        className={filterPanelClass.mainContainer}
        id="customer-page-container"
      >
        <FilterPanelLogical
          open={panelOpen}
          setOpen={setPanelOpen}
          expandFilters={expandFilters}
          setExpandFilters={setExpandFilters}
          filterPanel={filterPanel}
          parentClasses={filterPanelClass}
          getValue={getValue}
        />
        <div
          className={
            panelOpen
              ? filterPanelClass.wrapperShifted
              : filterPanelClass.wrapperUnshifted
          }
        >
          <Typography variant="h2" className={classes.viewTitle}>
            Customers
          </Typography>
          <Grid
            item
            xs={4}
            style={{ marginBottom: '16px', marginTop: '-16px' }}
          >
            <Search setSearched={setSearched} />
          </Grid>
          <Grid item container xs={12} direction="row" justify="space-between">
            <Grid item>
              <FilterButton
                open={panelOpen}
                parentClasses={filterPanelClass}
                filterCount={filterCount}
                onClickHandler={filterButtonClickHandler}
              />
              <FilterPanelChips
                activeFilters={activeFilters}
                chipClickHandler={filterChipsHandler}
                buttonClickHandler={clearAllFilters}
              />
            </Grid>
            <Grid item>
              <TableHeadButtons />
            </Grid>
          </Grid>

          <Box className={classes.tableContainer}>
            <CustomerTableLogical
              rows={mixDesignOverview}
              filteredRows={filteredRows}
              setFilteredRows={setFilteredRows}
              searched={searched}
              setSearched={setSearched}
              selectedTssRep={selectedTssRep}
            />
          </Box>
        </div>
      </Container>
    </>
  )
}
