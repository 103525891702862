import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import { ILibraryReport } from '../../Logic/Types'
import { Close, GetApp } from '@material-ui/icons'
import { getDate } from '../../../Common/Helpers/GeneralHelpers'

export interface IPreviewReportModalProps {
  readonly open: boolean
  readonly pdfData: string
  readonly selectedReport: ILibraryReport
  readonly handleClose: () => void
}

const useStyles = makeStyles(() => ({
  closeButton: { margin: '16px', alignSelf: 'flex-start' },
  downloadButton: { margin: '8px 16px 16px 16px' },
  pdfViewer: { width: '100%', height: '60vh' },
}))

function PreviewReportModal(props: IPreviewReportModalProps) {
  const { open, pdfData, selectedReport, handleClose } = props
  const heading = `Preview Report - ${selectedReport.divisionName}`
  const subheading = selectedReport.mixCodes.join(', ')
  const currentDate = getDate(Date.now()).replace(/-/g, '') //Current date in YYYYMMDD without dashes
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      fullWidth
      maxWidth={'md'}
      disableBackdropClick
    >
      <Grid container justify="space-between">
        <DialogTitle>
          <Typography variant="h3">{heading}</Typography>
          <Typography variant="h4">{subheading}</Typography>
        </DialogTitle>
        <IconButton
          color="primary"
          onClick={handleClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </Grid>
      <DialogContent>
        <embed src={pdfData} className={classes.pdfViewer} title="PDF Viewer" />
      </DialogContent>
      <DialogActions>
        <Button
          component="a"
          href={pdfData}
          download={`CommReport_${selectedReport.divisionName}_${currentDate}.pdf`}
          color="primary"
          variant="contained"
          startIcon={<GetApp />}
          className={classes.downloadButton}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewReportModal
