import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { getLocalDateTimeFromISODateString } from '../Logic/PCBLogic'

RemoteStatusTable.propTypes = {
  statuses: PropTypes.array.isRequired,
}

function RemoteStatusTable(props) {
  const { statuses } = props

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Timestamp </Typography>
              </TableCell>
              <TableCell>
                <Typography>Inlet Liquid Pressure (KPa)</Typography>
              </TableCell>
              <TableCell>
                <Typography>Inlet Gas Pressure (KPa)</Typography>
              </TableCell>
              <TableCell>
                <Typography>Upstream Pressure (KPa)</Typography>
              </TableCell>
              <TableCell>
                <Typography>Downstream Pressure 1 (KPa)</Typography>
              </TableCell>
              <TableCell>
                <Typography>Downstream Pressure 2 (KPa)</Typography>
              </TableCell>
              <TableCell>
                <Typography>Upstream Temperature (C)</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>
                      {getLocalDateTimeFromISODateString(status.timestamp)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Liquid Pressure */}
                      {`${status.inletLiquidPressureKPa}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Gas Pressure */}
                      {`${status.inletGasPressureKPa}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Upstream Pressure */}
                      {`${status.upstreamPressureKPa}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Downstream Pressure 1 */}
                      {`${status.downstreamPressure0KPa}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Downstream Pressure 2 */}
                      {`${status.downstreamPressure1KPa}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {/* Upstream Temperature */}
                      {`${status.upstreamTemperatureC}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

RemoteStatusTable.defaultProps = {
  statuses: [],
}

export default RemoteStatusTable
