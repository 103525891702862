import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import AddBatchSummaryCard from './AddBatchSummaryCard'
import { postNewBatch, putUpdateBatch } from '../Data/TSSDataHelpers'
import { atomCurrentCustomer } from '../../Common/atoms'
import { useRecoilValue } from 'recoil'
import ErrorDialog from '../../Common/Components/ErrorDialog'
import { addDataRouteString } from '../Constants/AddDataConstants'
import { handleResNotOk } from '../../Common/Helpers/ErrorHandlers'
import theme from '../../theme/muiTheme'

AddBatchConfirmDialog.propTypes = {
  /** Array containing all materials entered in the table. */
  batchData: PropTypes.array.isRequired,
  /** Sets whether the dialog is open or not. */
  open: PropTypes.bool.isRequired,
  /** Toggles the dialog off if user cancels. */
  setOpen: PropTypes.func.isRequired,
  /** List of materials to show in summary card. */
  materials: PropTypes.array.isRequired,
  /** Current system of measurement. */
  unitsSystem: PropTypes.string.isRequired,
  /** List of batch IDs for batches to be updated. */
  batchTestSampleIds: PropTypes.array,
}

/** Call post or put API depending on whether the page is in edit mode. */
const sendBatchData = async (batchDetails, batchTestSampleIds) => {
  if (!batchTestSampleIds.length) {
    return postNewBatch(batchDetails)
  } else {
    return putUpdateBatch(batchDetails)
  }
}

const useStyles = makeStyles({
  ...theme.customClasses.warningModal,
  gridContainer: {
    padding: '0px 10px 10px 10px',
    marginTop: '-16px',
  },
})

/**
 * This component shows users the entered data prior to submitting, it is triggered from AddBatchDesignView
 * @param {Array} batchData - array containing all materials entered in the table.
 * @param {Boolean} open - sets whether the dialog is open or not.
 * @param {Function} setOpen - toggles the dialog off if user cancels.
 * @param {Array} materials - list of materials to show in summary card.
 * @param {String} unitsSystem - current system of measurement.
 * @param {Array} batchTestSampleIds - list of batch IDs for batches to be updated.
 */
function AddBatchConfirmDialog(props) {
  const warningClass = useStyles()
  const {
    batchData,
    open,
    setOpen,
    materials,
    unitsSystem,
    batchTestSampleIds,
    setHasUserInput,
  } = props

  /** Error */
  const [errorMessage, setErrorMessage] = useState()

  /** History hook to redirect user after successful data entry. */
  const history = useHistory()

  /** Get current customer.  */
  const selectedCustomer = useRecoilValue(atomCurrentCustomer)

  /* Save new mix in DB. */
  const handleSave = () => {
    // Create object to post.
    sendBatchData(
      {
        divisionId: selectedCustomer.division.divisionId,
        isMetric: unitsSystem === 'Metric',
        batchTestSamples: batchData,
      },
      batchTestSampleIds
    ).then(res => {
      // Disable progress lost modal
      setHasUserInput(false)
      // Redirect to add data page if batch was added.
      if (res.ok) history.push(addDataRouteString)
      else handleResNotOk(res, setErrorMessage)
    })
  }

  /** Close dialog handler. */
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Paper className={warningClass.modal} elevation={1} spacing={2}>
        <Grid
          container
          item
          justify="space-between"
          className={warningClass.headerBackground}
        >
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid item>
              <ReportProblemOutlinedIcon className={warningClass.icon} />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                className={`${warningClass.fontColor} ${warningClass.header}`}
              >
                Confirm & Submit
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column" justify="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className={warningClass.fontColor}>
                Please review and confirm all information below is added
                correctly.
              </Typography>
              <AddBatchSummaryCard
                batchData={batchData}
                materials={materials}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={warningClass.gridContainer}
          >
            <Grid item>
              <Button
                variant="outlined"
                className={warningClass.cancelButton}
                onClick={handleClose}
              >
                No, Go Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="warning"
                className={warningClass.confirmButton}
                onClick={handleSave}
              >
                Confirm & Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {errorMessage && (
          <ErrorDialog
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </Paper>
    </Dialog>
  )
}

export default AddBatchConfirmDialog
