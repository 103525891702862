/** Creates a filename based on a data type string and the current date.
 * @param {String} dataType - type of data ('alarms', 'service cases', etc.)
 * @param {String} timestamp - the current date, formatted as a string
 * @returns {String} - returns formatted filename based on a data type string and the current date.
 */
export const formatFilename = (dataType: string, timestamp: string) => {
  switch (dataType) {
    case 'alarms':
      return `alarms_${timestamp}.csv`
    case 'service cases':
      return `service_cases_${timestamp}.csv`
    case 'mixerManifold':
      return `mixer_manifold_${timestamp}.csv`
    case 'injection':
      return `injection_${timestamp}.csv`
    case 'inlet':
      return `inlet_${timestamp}.csv`
    default:
      return `alarms_${timestamp}.csv`
  }
}

/**
 * For use in yup's transform functions, converts an empty string to a null value, else returns the current value
 * @param {string} currentValue
 * @param {string} originalValue
 * @returns {null | string} */
export function emptyStringToNull(currentValue: string, originalValue: string) {
  if (originalValue === '') {
    return null
  }
  return currentValue
}

/**
 * For use when setting a default value for an input that expects a date
 * Returns YYYY-MM-DD of the kelowna date string
 * @param {string | null} datetimeStringFromKelowna The datetime string from Kelowna
 * @returns {string | null} The YYYY-MM-DD of the kelowna datetime string or null if no dateString is given
 */
export function getHTMLDateString(datetimeStringFromKelowna: string | null) {
  if (datetimeStringFromKelowna === null) {
    return null
  } else {
    return datetimeStringFromKelowna.substring(0, 10)
  }
}
