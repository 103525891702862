import theme from '../../theme/muiTheme'
import { testResultPropertiesKelownaId } from '../Logic/Types'

export const reportLibraryHeadCells = [
  {
    id: 'divisionName',
    width: '120px',
    name: 'Producer',
    sortable: true,
  },
  {
    id: 'mixCodes',
    width: '120px',
    name: 'Mix Group',
    sortable: true,
  },
  {
    id: 'createdBy',
    width: '120px',
    name: 'Created By',
    sortable: true,
  },
  {
    id: 'status',
    width: '120px',
    name: 'Status',
    sortable: true,
  },
  {
    id: 'lastUpdatedTimestamp',
    width: '90px',
    name: 'Last Updated',
    sortable: true,
    tooltip: 'YYYY-MM-DD',
  },
  {
    id: 'reviewedTimestamp',
    width: '90px',
    name: ' Reviewed Date',
    sortable: true,
    tooltip: 'YYYY-MM-DD',
  },
  {
    id: 'view',
    width: '70px',
    name: '',
    sortable: false,
  },
]

export const READ_ONLY_DESCRIPTION =
  'View commissioning reports that are reviewed and finalized by our TSS managers using the table below.'
export const TSS_WRITE_DESCRIPTION =
  'Click “+ Create Report” to get started on the commissioning report, or manage existing reports using the table below.'
export const NO_OUTLIER_DESCRIPTION =
  'No outliers specified for selected variations, if this is incorrect please go to Baleen. This section will not be in the report if there are no outliers.'

export const defaultDigestedVariation = {
  mixDesignId: null,
  variationId: '',
  variationIdLabel: '',
  cO2Dosage: 0,
  cO2DosageUnit: '',
  cementReduction: 0,
  slump: 0,
  slumpStdDev: 0,
  airContent: 0,
  airStdDev: 0,
  cementEfficiency: 0,
  unitWeight: 0,
  cementContent: 0,
  concreteTemperature: 0,
  strengths: {},
  samples: [],
  testCategoryOptions: [],
  variationType: null,
  orcaVariationType: null,
}

export const histogramColor = [
  {
    dark: '#203B5B',
    light: '#e4e7eb',
  },
  {
    dark: '#E37F1C',
    light: '#EDC09350',
  },
]

export const defaultSelectedTestResultsProperties: testResultPropertiesKelownaId[] = [
  'CO2Dose',
  'AverageSlump',
  'AverageAir',
  'AvgCementLoading',
]

export const REPORT_LIBRARY_DEFAULT_TABLE_SETTINGS = {
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 10,
}

export const REPORT_LIBRARY_HELPER_VARIABLES = {
  showPlants: false,
}
