import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { makeStyles, Grid } from '@material-ui/core'
import { CustomersPageView } from '../TSS/Views/CustomersPageView'
import { Helmet } from 'react-helmet'
import PlantManagementPageView from '../TSS/Views/PlantManagementPageView'
import BaleenView from '../TSS/Views/BaleenView'
import { atomJWT } from '../Common/atoms'
import { tssCanWrite, orcaReadOnly } from '../TSS/Logic/TSSLogic'
import { getFeatureFlags } from '../Common/Helpers/GeneralHelpers'
import ReportLibraryView from '../TSS/Views/ReportLibraryView'
import CommissionReportGeneratorView from '../TSS/Views/CommissionReportGeneratorView'
import EchoHomeLogical from '../Echo/Views/EchoHome/EchoHomeLogical'

const useStyles = makeStyles({
  containerOffset: {
    marginTop: '2.6em',
  },
})

interface ProducersHomeProps {
  readonly finalRedirectPath: string
}

function ProducersHome({ finalRedirectPath }: ProducersHomeProps) {
  const classes = useStyles()
  let { path } = useRouteMatch()
  const JWT = useRecoilValue(atomJWT)
  const showCommissionReport = getFeatureFlags().showCommissionReport

  return (
    <>
      <Helmet>
        <title>TSS</title>
      </Helmet>
      <Grid container justify="center" className={classes.containerOffset}>
        <Grid item xs={12} sm={11} md={10} xl={9}>
          <Switch>
            <Route
              exact
              path={path}
              render={() => <Redirect to={`${path}/Plants`} />}
            />
            <Route path={`${path}/Echo`}>
              <EchoHomeLogical />
            </Route>
            <Route exact path={`${path}/Customers`}>
              {orcaReadOnly(JWT.roles) ? (
                <Redirect to={'/'} />
              ) : (
                <CustomersPageView />
              )}
            </Route>
            <Route path={`${path}/Plants`}>
              <Helmet>
                <title>Plant Data</title>
              </Helmet>
              {orcaReadOnly(JWT.roles) ? (
                <Redirect to={'/'} />
              ) : (
                <PlantManagementPageView />
              )}
            </Route>
            <Route path={`${path}/Baleen`}>
              <Helmet>
                <title>Baleen</title>
              </Helmet>
              {orcaReadOnly(JWT.roles) ? <Redirect to={'/'} /> : <BaleenView />}
            </Route>
            {showCommissionReport && (
              <Route exact path={`${path}/ReportLibrary/New`}>
                {tssCanWrite(JWT.roles) ? (
                  <CommissionReportGeneratorView />
                ) : (
                  <ReportLibraryView />
                )}
              </Route>
            )}
            {showCommissionReport && (
              <Route path={`${path}/ReportLibrary/:reportId`}>
                <CommissionReportGeneratorView />
              </Route>
            )}
            {showCommissionReport && (
              <Route path={`${path}/ReportLibrary`}>
                <Helmet>
                  <title>Report Library</title>
                </Helmet>
                <ReportLibraryView />
              </Route>
            )}
            {/* Catch-all route for any non-existing paths within /Producers */}
            <Route path={`${path}/*`}>
              <Redirect to={finalRedirectPath} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </>
  )
}

export default ProducersHome
