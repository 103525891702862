import React from 'react'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import BaseLegend from '../BaseLegend'
import { projectColors } from '../../../Constants/Constants'

/** Function to return Ongoing Projects icon for the map legend */
function OngoingProjectsLegend({ echoSettings }) {
  OngoingProjectsLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }

  if (!echoSettings.projects.includes('Ongoing Projects')) return null

  const items = [
    {
      label: 'Ongoing Projects',
      color: projectColors.ongoing.hex,
      Icon: FiberManualRecordIcon,
    },
  ]

  return <BaseLegend title="Ongoing Projects:" items={items} />
}

export default OngoingProjectsLegend
