import dataHelpers from '../../../../Common/Helpers/DataHelpers'
import { DateRange, SonarDateRange } from '../../../../TSS/Logic/Types'
import {
  ISnoozeDownSystemReqBody,
  SnoozeDownSystemReason,
} from '../../../Logic/Types'
import { isDateWithinRange } from '../../Helpers/SonarHelpers'

async function postDownSystemSnooze(
  remoteUnitId: number | null,
  reclaimedWaterUnitId: number | null,
  body: ISnoozeDownSystemReqBody
) {
  let endpoint = `/Hardware/Sonar/SnoozeSystem?`
  if (remoteUnitId !== null) endpoint += `remoteUnitId=${remoteUnitId}`
  else endpoint += `reclaimedWaterUnitId=${reclaimedWaterUnitId}`

  return dataHelpers.postDataHelper(endpoint, body)
}

async function putDownSystemSnooze(
  snoozeId: number | null,
  body: ISnoozeDownSystemReqBody
) {
  let endpoint = `/Hardware/Sonar/SnoozeSystem/${snoozeId}`

  return dataHelpers.putDataHelper(endpoint, body)
}

async function putDownSystemUnsnooze(snoozeId: number | null) {
  let endpoint = `/Hardware/Sonar/Snooze/Resolve/${snoozeId}`

  return dataHelpers.putDataHelper(endpoint)
}

const saveNewSnooze = async (
  remoteUnitId: number | null,
  reclaimedWaterUnitId: number | null,
  body: ISnoozeDownSystemReqBody
) => {
  const response = await postDownSystemSnooze(
    remoteUnitId,
    reclaimedWaterUnitId,
    body
  )
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

const saveSnooze = async (
  remoteUnitId: number | null,
  reclaimedWaterUnitId: number | null,
  reason: SnoozeDownSystemReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange
) => {
  const body = {
    reasonDetail: reasonDetails,
    snoozeUntilTimestamp: new Date(snoozeEnd as string).toISOString(),
    systemReason: reason,
  }
  await saveNewSnooze(
    remoteUnitId,
    reclaimedWaterUnitId,
    body as ISnoozeDownSystemReqBody
  )
}

const saveEditedSnooze = async (
  snoozeId: number | null,
  body: ISnoozeDownSystemReqBody
) => {
  const response = await putDownSystemSnooze(snoozeId, body)
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

const editSnooze = async (
  snoozeId: number | null,
  reason: SnoozeDownSystemReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange
) => {
  const body = {
    reasonDetail: reasonDetails,
    snoozeUntilTimestamp: new Date(snoozeEnd as string).toISOString(),
    systemReason: reason,
  }
  await saveEditedSnooze(snoozeId, body as ISnoozeDownSystemReqBody)
}

const saveUnsnooze = async (snoozeId: number | null) => {
  const response = await putDownSystemUnsnooze(snoozeId)
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

const unSnooze = async (snoozeId: number | null) => {
  await saveUnsnooze(snoozeId)
}

const isConfirmDisabled = (
  remoteUnitId: number | null | undefined,
  reclaimedWaterUnitId: number | null | undefined,
  reason: SnoozeDownSystemReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange,
  rangeSelected: SonarDateRange | null
) => {
  return (
    (!remoteUnitId && !reclaimedWaterUnitId) ||
    !reason ||
    (reason === SnoozeDownSystemReason.Other && !reasonDetails) ||
    !snoozeEnd ||
    !rangeSelected
  )
}

const isEditConfirmDisabled = (
  snoozeId: number | null | undefined,
  reason: SnoozeDownSystemReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange,
  snoozeEditDate: string | null,
  snoozeMin: string,
  snoozeMax: string
): boolean => {
  return (
    !snoozeId ||
    !reason ||
    (reason === SnoozeDownSystemReason.Other && !reasonDetails) ||
    !snoozeEnd ||
    !snoozeEditDate ||
    !isDateWithinRange(snoozeEditDate, snoozeMin, snoozeMax)
  )
}

const formatUTCISODate = (isoString: string | null) => {
  if (!isoString) return ''
  const date = new Date(isoString)
  return date.toISOString().split('T')[0]
}

const snoozeDownSystemsModalHelper = {
  postDownSystemSnooze,
  putDownSystemSnooze,
  saveNewSnooze,
  saveSnooze,
  saveEditedSnooze,
  editSnooze,
  saveUnsnooze,
  unSnooze,
  isConfirmDisabled,
  isEditConfirmDisabled,
  formatUTCISODate,
}

export default snoozeDownSystemsModalHelper
