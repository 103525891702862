import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import offline_exporting from 'highcharts/modules/exporting.js'
import exporting from 'highcharts/modules/offline-exporting.js'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { baseColors } from '../../../theme/colors'

offline_exporting(Highcharts)
exporting(Highcharts)

export default function StrengthDevelopmentPresentational(props) {
  StrengthDevelopmentPresentational.propTypes = {
    /** The selected rows of mix design table */
    graphOptions: PropTypes.array.isRequired,
    /** List of errors in the currently selected data samples. */
    dataErrors: PropTypes.array.isRequired,
    /** A function to set the ref */
    setRef: PropTypes.func.isRequired,
  }
  const { graphOptions, dataErrors, setRef } = props

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justify="flex-start"
    >
      <Grid item xs={12}>
        <HighchartsReact
          highcharts={Highcharts}
          options={graphOptions}
          ref={setRef}
        />
      </Grid>
      {dataErrors.map((message, key) => (
        <Grid item key={key} xs={12}>
          <Typography
            align="left"
            style={{ paddingLeft: '2%', color: baseColors.error.main }}
          >
            {message}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}
