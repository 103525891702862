import React from 'react'
import { useSetRecoilState } from 'recoil'
import { atomProjectSearchValues } from '../../../Common/echoAtoms'
import DateRangePresentational from './DateRangePresentational'

function DateRangeLogical() {
  /** Project search values atom */
  const setProjectSearchValues = useSetRecoilState(atomProjectSearchValues)

  const handleChangeStartDate = (event: { target: { value: string } }) => {
    const value = event.target.value
    setProjectSearchValues(prevValues => {
      const newValues = { ...prevValues }
      newValues.startDate = value
      return newValues
    })
  }

  const handleChangeEndDate = (event: { target: { value: string } }) => {
    const value = event.target.value
    setProjectSearchValues(prevValues => {
      const newValues = { ...prevValues }
      newValues.endDate = value
      return newValues
    })
  }

  return (
    <DateRangePresentational
      handleChangeStartDate={handleChangeStartDate}
      handleChangeEndDate={handleChangeEndDate}
    />
  )
}

export default DateRangeLogical
