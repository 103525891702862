import React from 'react'
import { Grid, TextField, MenuItem, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'
import {
  addNewSpreadsheetColumn,
  addUnitsToBatchPropsAddMode,
  addUnitsToBatchPropsEditMode,
  canSetPlantDropdownOptions,
  setPlantDropdownOptions,
  tryingToRemoveRequiredColumn,
} from '../Logic/AddBatchColumnSelectLogic'
import { batchMaterialUnits } from '../Constants/AddDataConstants'
import { preventEventOnKeyDown } from '../../Common/Helpers/GeneralHelpers'
import { sortStrengthReadings } from '../Logic/AddBatchViewLogic'
import { MaterialSelectType } from '../Logic/Types'
import MaterialSelect from './MaterialSelect'

AddBatchColumnSelect.propTypes = {
  /** Strength columns to appear in dropdown, updated by UploadBatchData component. */
  selectedCols: PropTypes.object.isRequired,
  /** Set dropdown values in parent.  */
  setSelectedCols: PropTypes.func.isRequired,
  /** Available options for strength and material autocomplete components. */
  strengthAndMaterialCols: PropTypes.object.isRequired,
  /** Used to dynamically update strengthAndMaterialCols when multiple same-day readings are required,
   *  or multiples of the same type of material.
   */
  setStrengthAndMaterialCols: PropTypes.func.isRequired,
  /** Current units system, used to selected Metric or Imperial dropdown options. */
  unitsSystem: PropTypes.string.isRequired,
  /** Change set units. */
  setUnits: PropTypes.func.isRequired,
  /** List of plant locations, used to update spreadsheet dropdown when plant columns is selected. */
  plants: PropTypes.array.isRequired,
}

/** Instantiate autocomplete options. */
const batchPropertiesAddMode = addUnitsToBatchPropsAddMode()
const batchPropertiesEditMode = addUnitsToBatchPropsEditMode()
/**
 * Component used to select columns to appear in batch entry spreadsheet..
 * @param {Object} selectedCols - strength columns to appear in dropdown, updated by UploadBatchData component.
 * @param {Function} setSelectedCols - set dropdown values in parent.
 * @param {Object} strengthAndMaterialCols - available options for strength and material autocomplete components.
 * @param {Function} setStrengthAndMaterialCols - used to dynamically update strengthAndMaterialCols.
 * @param {String} unitsSystem - current units system, used to selected Metric or Imperial dropdown options.
 * @param {Function} setUnits - change set units.
 * @param {Array} plants - list of plant locations, used to update spreadsheet dropdown when plant columns is selected.
 * @param {Function} setHasUserInput - function to trigger progress lost alert modal.
 */
function AddBatchColumnSelect(props) {
  const {
    setHasUserInput,
    selectedCols,
    setSelectedCols,
    strengthAndMaterialCols,
    setStrengthAndMaterialCols,
    unitsSystem,
    setUnits,
    plants,
    isEditMode,
    isViewOnly,
    currentCustomer,
  } = props

  /** Handler for selecting new strength or material columns. */
  const updateAllColumns = columnKey => (_, newVals, reason, detail) => {
    setHasUserInput(true)
    // Set columns
    setSelectedCols(prevCols => {
      // If a new strength/material column was added, a new column object must be created.
      if (reason === 'select-option') {
        addNewSpreadsheetColumn(
          detail.option,
          setStrengthAndMaterialCols,
          columnKey
        )
      }
      const updatedCols = { ...prevCols, [columnKey]: newVals }
      updatedCols.strengthReadings.sort(sortStrengthReadings)
      return updatedCols
    })
  }

  /** Prevent removing required columns. */
  const updatePropertyCols = (_, newProperties, action, selection) => {
    if (
      tryingToRemoveRequiredColumn(action, selection.option.name, isEditMode)
    ) {
      return
    } else if (
      canSetPlantDropdownOptions(action, selection.option.name, plants.length)
    ) {
      setPlantDropdownOptions(plants, setSelectedCols)
    } else {
      setSelectedCols(prevCols => ({ ...prevCols, properties: newProperties }))
      setHasUserInput(true)
    }
  }

  /** Render checkbox next to each selected option. */
  const renderCheckBox = (option, { selected }) => {
    return (
      <>
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" color="primary" />}
          style={{ marginRight: 12 }}
          checked={selected}
        />
        {option.title}
      </>
    )
  }

  return (
    <Grid item container spacing={2} xs={12} sm={9}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          label="Measurement System"
          size="small"
          select
          value={unitsSystem}
          name="unitsSystem"
          placeholder="Select the System of Measurement"
          onChange={e => {
            setUnits(e.target.value)
            setHasUserInput(true)
          }}
          disabled={isViewOnly}
        >
          {Object.keys(batchMaterialUnits).map(system => (
            <MenuItem key={system} value={system}>
              {system}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={strengthAndMaterialCols.strengthReadings.sort(
            (a, b) =>
              -b.intervalType.localeCompare(a.intervalType) ||
              -b.title.localeCompare(a.title, 'en', { numeric: true })
          )}
          value={selectedCols.strengthReadings}
          disableClearable
          disableCloseOnSelect
          getOptionSelected={(option, value) => option.name === value.name}
          groupBy={option => option.intervalType}
          getOptionLabel={option => option.title}
          renderOption={renderCheckBox}
          multiple
          disabled={isViewOnly}
          renderInput={params => (
            <TextField
              {...params}
              label="Compressive Strength Columns"
              variant="outlined"
              size="small"
              name="strengthCols"
              placeholder="Select the Strength Interval"
              onKeyDown={preventEventOnKeyDown(['Backspace'])}
            />
          )}
          onChange={updateAllColumns('strengthReadings')}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={
            isEditMode
              ? batchPropertiesEditMode[unitsSystem]
              : batchPropertiesAddMode[unitsSystem]
          }
          disableClearable
          disableCloseOnSelect
          value={selectedCols.properties}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.title}
          renderOption={renderCheckBox}
          multiple
          disabled={isViewOnly}
          renderInput={params => (
            <TextField
              {...params}
              label="Property Columns"
              variant="outlined"
              size="small"
              name="batchProperties"
              placeholder="Select the Batch Properties"
              onKeyDown={preventEventOnKeyDown(['Backspace'])}
            />
          )}
          onChange={updatePropertyCols}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialSelect
          type={MaterialSelectType.AddBatch}
          materials={strengthAndMaterialCols.materials}
          selected={selectedCols.materials}
          currentCustomer={currentCustomer}
          handleChange={updateAllColumns}
          isViewOnly={isViewOnly}
        />
      </Grid>
    </Grid>
  )
}

export default AddBatchColumnSelect
