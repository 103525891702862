import React, { useState, useEffect } from 'react'
import {
  MaterialObject,
  CustomerOptions,
  HandleChangeEvent,
  HandleChangeValue,
  IMaterialTypeMetadata,
  MaterialKey,
  MaterialObjectKeys,
  MaterialManagerComponent,
  IMetaData,
  IMaterial,
  MaterialExceptionType,
  APIResponse,
  MaterialRequestType,
  IAliasUpdate,
  CurrentPlantCompositions,
  MaterialType,
  CustomerDivision,
  IMaterialExisting,
  PlantComposition,
} from '../../Logic/Types'
import MaterialFieldsPresentational from './MaterialFieldsPresentational'
import {
  createMaterialTypeOptions,
  getMaterialTypeMappingValues,
  getMetadataFieldsByKey,
  removeDuplicateAliases,
  searchMaterialHashmap,
  sortByKey,
  convertStringToPascalUpper,
  verifyAllCementPercentages,
  handleMaterialManagerGenericError,
  getIndexesOfDuplicates,
  convertAliasesToLowerCase,
} from '../../Helpers/MaterialManagerHelpers'
import { defaultMaterialDetails } from '../../Constants/MaterialManagerConstants'
import { getCustomerPlants } from '../../../Common/Helpers/DataHelpers'
import { atomJWT, atomMaterialMetadata } from '../../../Common/atoms'
import { useRecoilValue } from 'recoil'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  getPlantCompositionsByDivisionId,
  postNewMaterial,
  putUpdateMaterial,
  putUpdateMaterialAlias,
} from '../../Data/TSSDataHelpers'
interface IMaterialFieldsLogical {
  /** The customers that can be selected */
  customerOptions: CustomerOptions[]
  /** The metadata returned from Kelowna */
  metaData: IMetaData | undefined
  /** Changes the view between Add/Edit/View Materials */
  setCurrentView: (view: MaterialManagerComponent) => void
  /** The selected Material and the relevant details needed for editing/viewing */
  selectedMaterial: IMaterial | null | undefined
  /** Determines if fields can be edited or just viewed */
  isEditMode: boolean
  /** Manages Unsaved Changes Modal */
  setHasUserInput: (arg0: boolean) => void
  /** Determines if fields can be edited or just viewed */
  isDefaultMaterial: boolean | undefined
  /** Determines if the selected material is unclassified */
  isUnclassifiedMaterial?: boolean
  /** Set the material to be viewed in Add/Edit view */
  setSelectedMaterial: (arg0: IMaterial) => void
  /** Values from the material manager filters */
  customerFilterSelection?: CustomerDivision | null
}

function MaterialFieldsLogical(props: IMaterialFieldsLogical) {
  const {
    customerOptions,
    metaData,
    setCurrentView,
    selectedMaterial,
    isEditMode,
    setHasUserInput,
    isDefaultMaterial,
    setSelectedMaterial,
    customerFilterSelection,
    isUnclassifiedMaterial,
  } = props

  /** The number of rendered alias input fields  */
  const [aliasFields, setAliasFields] = useState([0])
  /** An array containing each value typed in the alias fields */
  const [aliasFieldValues, setAliasFieldValues] = useState<string[]>([])
  /** The value of the selected Material Type. Helps determine the fields to be rendered */
  const [materialType, setMaterialType] = useState<string>('')
  /** The POST object for adding a material */
  const [materialPostObject, setMaterialPostObject] = useState<MaterialObject>(
    selectedMaterial || defaultMaterialDetails
  )
  /** The selected material's hashmap data */
  const [materialHashMap, setMaterialHashMap] = useState<
    IMaterialTypeMetadata[]
  >()
  /** Tracks the plants that are selected for the Cement Composition view */
  const [selectedPlants, setSelectedPlants] = useState<any[]>([])
  /** The list of available plants based on the selected customer */
  const [plantOptions, setPlantOptions] = useState<MaterialObject[]>([])
  /** Contains all of the Division's Plant Composition Data */
  const [plantData, setPlantData] = useState<PlantComposition[]>([])
  /** Used for checking First Render */
  const [isFirstRender, setIsFirstRender] = useState(true)
  /** A filtered hashmap for Material Types */
  const [
    materialTypesMetadata,
    setMaterialTypesMetadata,
  ] = useState<MaterialObject | null>()
  /** The options for Material Types. Used for Autocomplete components */
  const [materialTypeOptions, setMaterialTypeOptions] = useState<
    MaterialObject[]
  >([])
  /** The options for Material Subtypes. Used for Autocomplete components */
  const [materialSubTypeOptions, setMaterialSubTypeOptions] = useState<
    MaterialObject[]
  >([])
  /** The options for Cement Suppliers. Used for Autocomplete components */
  const [cementSupplierOptions, setCementSupplierOptions] = useState<
    MaterialObject[]
  >([])
  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)
  /** Boolean to determine if Modal is open or closed */
  const [modalOpen, setModalOpen] = useState(false)
  /** The type of modal to be shown when called */
  const [modalType, setModalType] = useState<MaterialExceptionType | null>(null)
  /** States containing material data for comparisons (Similar/Duplicate Modals) */
  const [
    existingMaterial,
    setExistingMaterial,
  ] = useState<IMaterialExisting | null>(null)
  const [currentMaterial, setCurrentMaterial] = useState<MaterialObject | null>(
    null
  )
  /** The material data used for display on Success Modal */
  const [successData, setSuccessData] = useState<MaterialObject | null>(null)
  /** The error data for duplicate aliases. Helps with alias field validation on Add Material Page */
  const [duplicateAliasFieldError, setDuplicateAliasFieldError] = useState<
    number[] | null
  >(null)
  /** The error data for duplicate aliases. Helps with alias field validation on Modals */
  const [duplicateAliasModalError, setDuplicateAliasModalError] = useState<
    string[] | null | undefined
  >(null)
  /** The error data for duplicated aliases within the same material. Helps with alias field validation on Add Material Page  */
  const [
    duplicateMaterialAliasFieldError,
    setDuplicateMaterialAliasFieldError,
  ] = useState<number[] | null>(null)
  /** The data used for the currentCementCompositions property of materialPostObject */
  const [cementCompositionData, setCementCompositionData] = useState<
    CurrentPlantCompositions[]
  >([])
  /** Error message returned from Kelowna */
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  /** The Metadata hashmap */
  const metadataMap = useRecoilValue(atomMaterialMetadata)
  /** JWT Token. Used for get role data. */
  const JWT = useRecoilValue(atomJWT)

  const materialFieldSchema = yup.object().shape({
    specificGravity: yup
      .number()
      .positive('Number must be greater than 0')
      .typeError('Only numbers can be entered here')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable(),
    admixtureSolidsContentPercent: yup
      .number()
      .positive('Number must be greater than 0')
      .typeError('Only numbers can be entered here')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable(),
    flyAshLossOfIgnitionPercent: yup
      .number()
      .positive('Number must be greater than 0')
      .typeError('Only numbers can be entered here')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable(),
    fiberDiameterMm: yup
      .number()
      .positive('Number must be greater than 0')
      .typeError('Only numbers can be entered here')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable(),
    fiberLengthMm: yup
      .number()
      .positive('Number must be greater than 0')
      .typeError('Only numbers can be entered here')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable(),
  })

  /** Form Validation Hook Material Fields */
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(materialFieldSchema),
  })

  /** Handler for Kelowna Responses when submitting Material data */
  const handleResponse = (res: APIResponse) => {
    setIsLoading(false)
    if (res.ok) {
      setHasUserInput(false)
      setModalType(MaterialExceptionType.SUCCESS)
      setModalOpen(true)
      setTimeout(() => {
        setModalOpen(false)
        formReset()
        setCurrentView(MaterialManagerComponent.MaterialTable)
      }, 3500)
    } else {
      return res.json().then(handleError)
    }
  }

  /** Handler for Kelowna Error Responses when submitting Material Data */
  const handleError = (errorData: MaterialObject) => {
    // Check for the exception type in the error details
    if (errorData.ExceptionType === MaterialExceptionType.DUPLICATEMAPPING) {
      // Call Duplicate Mapping Modal
      if (errorData.ExistingMapping) {
        setExistingMaterial(errorData.ExistingMapping)
      }
      setModalType(MaterialExceptionType.DUPLICATEMAPPING)
      setModalOpen(true)
    } else if (
      errorData.ExceptionType === MaterialExceptionType.SIMILARMAPPING
    ) {
      // Call Similar Mapping Modal
      if (errorData.ExistingMapping) {
        setExistingMaterial(errorData.ExistingMapping)
      }
      setModalType(MaterialExceptionType.SIMILARMAPPING)
      setModalOpen(true)
    } else if (
      errorData.ExceptionType === MaterialExceptionType.DUPLICATEALIAS
    ) {
      // Set Duplicate Alias data to relevant useStates.
      if (errorData.ExistingMapping) {
        if (modalOpen) {
          // Handle validation for Alias Fields on Modals
          setDuplicateAliasModalError(
            convertAliasesToLowerCase(errorData.ExistingMapping.Aliases)
          )
        } else {
          // Handle validation for Alias Fields on Add Material Page
          setDuplicateAliasFieldError(
            findMatchingIndexes(
              aliasFieldValues,
              errorData.ExistingMapping.Aliases as string[]
            )
          )
        }
      }
    } else {
      handleMaterialManagerGenericError(
        errorData.detail,
        setErrorMessage,
        setModalType,
        setModalOpen
      )
    }
  }

  /** POST/PUT API call for Material Manager related endpoints. Can force a PUT request with update arg. */
  const sendMaterialData = async (
    materialData: MaterialObject | IAliasUpdate,
    dataType: MaterialRequestType,
    update: boolean = false
  ) => {
    setIsLoading(true)
    if (dataType === MaterialRequestType.Material) {
      // Format Material Data for POST request
      const formattedMaterialData = formatMaterialData(materialData)
      if (isEditMode || update) {
        putUpdateMaterial(formattedMaterialData)
          .then(handleResponse)
          .catch(e => console.error(e))
      } else {
        postNewMaterial(formattedMaterialData)
          .then(handleResponse)
          .catch(e => console.error(e))
      }
    }
    // Handle Alias Updates
    if (dataType === MaterialRequestType.Alias) {
      // PUT request to update a material's Alias.
      putUpdateMaterialAlias(materialData)
        .then(handleResponse)
        .catch(e => console.error(e))
    }
  }

  const sendMaterialAndAliasData = async (
    materialData: IMaterial,
    aliasData: IAliasUpdate,
    update: boolean = false
  ) => {
    setIsLoading(true)
    // Format Material Data for POST request
    const formattedMaterialData = formatMaterialData(materialData)
    if (isEditMode || update) {
      putUpdateMaterial(formattedMaterialData, aliasData)
        .then(handleResponse)
        .catch(e => console.error(e))
    } else {
      postNewMaterial(formattedMaterialData, aliasData)
        .then(handleResponse)
        .catch(e => console.error(e))
    }
  }

  /** An array containing the keys of all default fields. Used to handle exclusions for fields rendered through mapping  */
  const defaultFieldKeys: Array<MaterialKey | string> = [
    MaterialKey.DIVISIONAME,
    MaterialKey.PRIMARYMATERIALTYPE,
    MaterialKey.MATERIALTYPE,
    MaterialKey.SUPPLIERCOMPANY,
    MaterialKey.SUPPLIERPLANT,
    MaterialKey.ALIASES,
  ]

  /** Used for determining whether the field to be rendered is a default field */
  const isDefaultField = (mapValue: IMaterialTypeMetadata) => {
    if (defaultFieldKeys.includes(mapValue.key)) {
      return true
    }
  }

  /**
   * Used for Alias field validation on Add Material page.
   * Matches the values in the array of Kelowna's response to the alias values on
   * add material page. If there's a match, then we retrieve the array index of that matching
   * value to determine which textfield will show the error.
   */
  const findMatchingIndexes = (arr1: string[], arr2: string[]) => {
    let matchingIndexes: number[] = []

    for (let i = 0; i < arr1.length; i++) {
      let currentIndex = arr2.findIndex(
        el => el.toLowerCase() === arr1[i].toLowerCase()
      )
      if (currentIndex !== -1) {
        matchingIndexes.push(i)
      }
    }

    return matchingIndexes
  }

  /** Adds more Alias fields */
  const addAliasField = () => {
    setAliasFields(prevAliasFields => [
      ...prevAliasFields,
      prevAliasFields.length,
    ])
  }

  /** Gets the list of options (applicableValues) from the filtered metadata */
  const getFieldOptions = (keyName: string) => {
    if (!materialHashMap) return null
    const options = searchMaterialHashmap(
      materialHashMap,
      keyName,
      'applicableValues'
    )
    return options
  }

  /**
   * When using Autocomplete components from Material UI, it can be difficult to match the array
   * of options that are passed in with the exact values that Kelowna accepts in a POST object.
   * This function searches through the options array and returns the first option that has a
   * matching value for the given key and nested key names.
   */
  const getValueFromOption = (
    options: any[],
    optionKey: string,
    ...keyNames: string[]
  ) => {
    // For handling variable amounts of keys. Checks for an array of strings.
    const findNestedValue: any = (obj: any, keys: string[]) => {
      if (keys.length === 0) {
        return obj
      }
      const [currentKey, ...remainingKeys] = keys
      if (obj[currentKey] !== null) {
        return findNestedValue(obj[currentKey], remainingKeys)
      } else {
        return null
      }
    }

    // Compares values in options to materialPostObject, and returns the matching value.
    return (
      options.find(
        option =>
          option[optionKey] === findNestedValue(materialPostObject, keyNames)
      ) || null
    )
  }

  /** Clears POST object and resets fields */
  const formReset = (keepCustomer: boolean = false) => {
    setMaterialType('')
    !isUnclassifiedMaterial && setAliasFields([0])
    setSelectedPlants([])
    modalDataReset()
    setHasUserInput(false)
    // For retaining customer selection when resetting fields
    if (keepCustomer) {
      setMaterialPostObject({
        ...defaultMaterialDetails,
        divisionId: materialPostObject['divisionId'],
        divisionName: materialPostObject['divisionName'],
        aliases: isUnclassifiedMaterial ? materialPostObject['aliases'] : [''],
      })
    } else {
      // Set material data back to defaults
      const newMaterialDetails = {
        ...defaultMaterialDetails,
        aliases: isUnclassifiedMaterial ? materialPostObject['aliases'] : [''],
      }
      setMaterialPostObject(newMaterialDetails)
    }
  }

  /** Resets current/existing data used for modals */
  const modalDataReset = () => {
    setCurrentMaterial(null)
    setExistingMaterial(null)
    // Clear field error data
    setDuplicateAliasModalError(null)
    setDuplicateAliasFieldError(null)
    setDuplicateMaterialAliasFieldError(null)
  }

  /** Prepares POST object for submission to Kelowna */
  const formatMaterialData = (materialData: MaterialObject) => {
    // Remove any POST object properties that have empty strings and make them null
    for (let prop in materialData) {
      if (materialData.hasOwnProperty(prop) && materialData[prop] === '') {
        materialData[prop] = null
      }
    }
    // Remove empty values from Alias array
    if (
      materialData.hasOwnProperty('aliases') &&
      Array.isArray(materialData.aliases)
    ) {
      materialData.aliases = materialData.aliases.filter(
        (item: string) => item.trim() !== '' && item !== undefined
      )
    }
    // Remove any duplicate aliases within same material since Kelowna doesn't detect this
    materialData.aliases = removeDuplicateAliases(materialData.aliases)
    // Convert Slag Processing options back to PascalCase by removing space
    if (materialData.slagProcessing) {
      materialData.slagProcessing = materialData.slagProcessing.replace(
        /\s+/g,
        ''
      )
    }
    // Add currentCementComposition data
    const sortedCementCompositions = sortByKey(
      cementCompositionData,
      'effectiveDate',
      true
    )
    materialData.currentPlantCompositions = sortedCementCompositions
    return materialData
  }

  /** Checks conditions to enable the Save button. */
  const canSave = (
    materialType: string,
    isDefaultMaterial: boolean | undefined,
    materialPostObject: MaterialObject
  ) => {
    // All saving is prevented if the material is a default Cement or CO2 material.

    // Prevents saving if no Material Type is selected
    return (
      (!materialType && !isDefaultMaterial) ||
      // Prevents saving is no Material Subtype is selected
      (!materialPostObject[MaterialObjectKeys.MaterialType] &&
        !isDefaultMaterial) ||
      // Prevents saving if Material Type is a Cement, and no Cement Supplier is set
      (materialType === MaterialType.CEMENT &&
        !materialPostObject[MaterialObjectKeys.CementSupplier] &&
        !isDefaultMaterial) ||
      // Prevents saving if Cement Components' Cement Percentages don't add up to 100
      (materialType === MaterialType.CEMENT &&
        !verifyAllCementPercentages(cementCompositionData) &&
        !isDefaultMaterial) ||
      // Prevent saving if a duplicate alias error is present
      (duplicateMaterialAliasFieldError as number[])?.length > 0
    )
  }

  /** Handles saving the Material Data on the Add Material page. */
  const handleSave = (_event: React.MouseEvent<HTMLButtonElement>) => {
    // Create copy due to handleSubmit function seemingly modifying materialPostObject before saving?
    const savedData = { ...materialPostObject }
    setCurrentMaterial(savedData)
    setSuccessData(savedData)
    sendMaterialData(savedData, MaterialRequestType.Material)
  }

  /** Handle changes for Alias field(s) */
  const handleAliasChange = (
    _event: HandleChangeEvent,
    value: HandleChangeValue,
    index: number
  ) => {
    setHasUserInput(true)
    // Clear field error data when updating alias fields
    if (duplicateAliasFieldError && duplicateAliasFieldError.length > 0) {
      const newError = duplicateAliasFieldError.filter(
        element => element !== index
      )
      setDuplicateAliasFieldError(newError)
    }

    // Add relevant alias data for maintaining Alias fields and POST data.
    if (value !== null) {
      const updatedFields = [...aliasFieldValues]
      updatedFields[index] = value as string
      // Detect duplicate aliases
      const indexesOfDuplicates = getIndexesOfDuplicates(updatedFields)
      if (indexesOfDuplicates.length > 1) {
        setDuplicateMaterialAliasFieldError(indexesOfDuplicates)
      } else {
        setDuplicateMaterialAliasFieldError(null)
      }
      setAliasFieldValues(updatedFields)
      setMaterialPostObject(prevData => ({
        ...prevData,
        aliases: [...updatedFields],
      }))
    }
  }

  /** Handles the cancel button */
  const handleCancel = (_event: React.MouseEvent<HTMLButtonElement>) => {
    formReset()
    setCurrentView(MaterialManagerComponent.MaterialTable)
  }

  /** Standard handler for all other field changes */
  const handleChange = (
    _event: HandleChangeEvent,
    value: HandleChangeValue,
    keyName?: string
  ) => {
    setHasUserInput(true)
    // Reference key values in Enum
    const objKey =
      MaterialObjectKeys[keyName as keyof typeof MaterialObjectKeys]
    // Force string input for specific fields regardless of input type
    const forcedString =
      keyName === MaterialKey.AGGREGATEPITID ||
      keyName === MaterialKey.SUPPLIERCOMPANY ||
      keyName === MaterialKey.SUPPLIERPLANT
    // Handle different types of values from input fields
    if (value) {
      const regex = new RegExp('^[0-9]+$')
      // Detect if input is a number, and convert to number type
      if (regex.test(value as string) && !forcedString) {
        setMaterialPostObject({
          ...materialPostObject,
          [objKey]: Number(value),
        })
        return
      } else {
        // Create a string value if value is not an object. Otherwise, get the option
        const newValue = (value as { option: string }).option
          ? value.value
          : value
        setMaterialPostObject({ ...materialPostObject, [objKey]: newValue })
        return
      }
    } else {
      setMaterialPostObject({ ...materialPostObject, [objKey]: null })
    }
  }

  /** Creates material subtype options. Updates on Material Type change */
  useEffect(() => {
    if (!metaData) return
    setMaterialSubTypeOptions(
      getMaterialTypeMappingValues(
        metaData,
        materialPostObject.primaryMaterialType
      )
    )
  }, [metaData, materialPostObject.primaryMaterialType])

  /** Filtered metadata based on selected Material Type */
  useEffect(() => {
    if (!materialType) return
    const formattedMaterialType: string = convertStringToPascalUpper(
      materialType
    )
    const selectedMaterialHash = materialTypesMetadata?.get(
      formattedMaterialType
    )
    setMaterialHashMap(selectedMaterialHash)
  }, [materialType, materialTypesMetadata, materialHashMap])

  /** Set plant options based on selected customer */
  useEffect(() => {
    if (materialPostObject.divisionId) {
      setIsLoading(true)
      getCustomerPlants(materialPostObject.divisionId)
        .then(res => {
          if (res.ok)
            res
              .json()
              .then(data => {
                setPlantOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, materialPostObject.divisionId])

  useEffect(() => {
    if (isFirstRender && metaData) {
      /** Creates Material Type Options/Label object */
      setMaterialTypeOptions(
        createMaterialTypeOptions(metaData) as MaterialObject[]
      )
      /** Create Cement Supplier Options */
      let cementSuppliers = getMetadataFieldsByKey(
        metaData,
        MaterialKey.CEMENTSUPPLIER,
        'cementPlants'
      )
      const sortedCementSuppliers = sortByKey(cementSuppliers, 'plantName')
      setCementSupplierOptions(sortedCementSuppliers)
      /** Create hashmap for data relevant to Material Type selection */
      setMaterialTypesMetadata(metadataMap)
      if (selectedMaterial) {
        /** Set Material Type if material details already exist */
        setMaterialType(selectedMaterial.primaryMaterialType as string)
        /** Set number of fields to be rendered based on Aliases */
        let newArray = []
        for (
          let i = 0;
          i < (selectedMaterial.aliases as string[]).length;
          i++
        ) {
          newArray.push(i)
        }
        setAliasFields(newArray)
        setAliasFieldValues(selectedMaterial.aliases as string[])
      }
      /** Set Aliases  */
      setIsFirstRender(false)
    }
  }, [isFirstRender, selectedMaterial, materialType, metaData, metadataMap])

  /** Get selected customer from filter panel for Add Material */
  useEffect(() => {
    if (!isEditMode && isFirstRender && customerFilterSelection) {
      setMaterialPostObject(prevData => ({
        ...prevData,
        divisionId: customerFilterSelection?.divisionId,
        divisionName: customerFilterSelection?.divisionName,
      }))
    }
  }, [isEditMode, isFirstRender, materialPostObject, customerFilterSelection])

  useEffect(() => {
    if (!materialPostObject?.divisionId) return

    const options = {
      divisionId: materialPostObject?.divisionId,
    }
    getPlantCompositionsByDivisionId(options)
      .then(res => {
        if (res.ok)
          res.json().then(data => {
            setPlantData(data)
          })
      })
      .catch(e => console.log(e))
  }, [materialPostObject.divisionId])

  return (
    <MaterialFieldsPresentational
      addAliasField={addAliasField}
      aliasFields={aliasFields}
      customerOptions={customerOptions}
      handleAliasChange={handleAliasChange}
      handleChange={handleChange}
      handleCancel={handleCancel}
      isDefaultField={isDefaultField}
      materialPostObject={materialPostObject}
      setMaterialPostObject={setMaterialPostObject}
      materialType={materialType}
      setMaterialType={setMaterialType}
      materialTypeOptions={materialTypeOptions}
      materialSubTypeOptions={materialSubTypeOptions}
      cementSupplierOptions={cementSupplierOptions}
      plantOptions={plantOptions}
      plantData={plantData}
      setPlantOptions={setPlantOptions}
      materialHashMap={materialHashMap}
      getFieldOptions={getFieldOptions}
      getValueFromOption={getValueFromOption}
      selectedPlants={selectedPlants}
      setSelectedPlants={setSelectedPlants}
      isLoading={isLoading}
      formReset={formReset}
      isEditMode={isEditMode}
      roles={JWT.roles}
      handleSave={handleSave}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      modalType={modalType}
      setModalType={setModalType}
      existingMaterial={existingMaterial}
      currentMaterial={currentMaterial}
      successData={successData}
      setSuccessData={setSuccessData}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      sendMaterialData={sendMaterialData}
      sendMaterialAndAliasData={sendMaterialAndAliasData}
      duplicateAliasFieldError={duplicateAliasFieldError}
      duplicateAliasModalError={duplicateAliasModalError}
      duplicateMaterialAliasFieldError={duplicateMaterialAliasFieldError}
      setDuplicateAliasModalError={setDuplicateAliasModalError}
      modalDataReset={modalDataReset}
      setCurrentView={setCurrentView}
      setHasUserInput={setHasUserInput}
      isDefaultMaterial={isDefaultMaterial}
      isUnclassifiedMaterial={isUnclassifiedMaterial}
      setCementCompositionData={setCementCompositionData}
      canSave={canSave}
      setSelectedMaterial={setSelectedMaterial}
      setAliasFields={setAliasFields}
      setAliasFieldValues={setAliasFieldValues}
      errorMessage={errorMessage}
    />
  )
}

export default MaterialFieldsLogical
