import { isFilterByCityActive, checkIsWithinRadius } from './FilterLogicHelpers'
import { haversineDistance } from './GeneralEchoLogicHelpers'

export const formatCarbonCureProjectData = carbonCureProjects => {
  return carbonCureProjects.map(project => {
    return {
      divisionID: project.divisionId,
      projectName: project.name,
      longitude: project.lon,
      latitude: project.lat,
      city: project.city ? `${project.city}, ${project.state}` : '',
      state: project.state ? project.state : '',
      customer: project.divisionName,
      concreteDelivered: Math.round(project.volumeM3 * 1.308), // cubic meters converted into cubic yards
      co2Savings: parseFloat(
        ((Math.round(project.volumeM3 * 1.308) * 20) / 2240).toFixed(2)
      ), // Imperial tons of CO2 saved = cubic meters of concrete delivered, converted into cubic yards, muliplied by 20, rounded to the nearest integer, and converted into tons (imperial).
      startDate: project.startDate,
      projectStatus: project.isOngoing ? 'Ongoing' : 'Completed',
    }
  })
}

/** Formats corporation data (ready mix or precast) into an array of objects to be displayed as columns and rows on a CSV file.
 * @param {Array} corpData - corporation data from the /Customers/Management/Corporations/SalesOverview endpoint.
 * @param {String} concreteType - type of concrete, either 'Ready Mix' or 'Precast'
 * @param {Array} filterByState - selected states for the state filter
 * @param {Object} filterByCity - selected city for the city filter
 * @param {String} salesPerson - selected sales person for the sales person filter
 * @param {Object} filterByCompany - selected company for the company filter
 * @returns {Array} - returns formatted array of objects to be displayed as columns and rows on a CSV file.
 */
export const formatCorpDataForDownload = (
  corpData,
  concreteType,
  filterByState,
  filterByCity,
  salesPerson,
  filterByCompany
) => {
  const formattedCorpData = []
  corpData.forEach(corporation => {
    corporation.divisions.forEach(division => {
      division.plants
        .filter(plant => {
          const concreteTypeTranslation = {
            'Ready Mix': !plant.isPrecast,
            Precast: plant.isPrecast,
          }
          const concreteFilter = concreteTypeTranslation[concreteType] // only return plants for the selected type: ready mix or precast.
          const salesPersonFilter = salesPerson
            ? plant.salesperson === salesPerson // if a salesperson is selected, only return plants assigned to that salesperson.
            : true
          const companyFilter = filterByCompany
            ? division.divisionName === filterByCompany // if a company is selected, only return plants belonging to that company.
            : true
          const stateFilter =
            filterByState.length > 0
              ? filterByState.includes(plant.state) // if states are selected, only return plants in those states.
              : plant
          const cityFilter = isFilterByCityActive(filterByCity)
            ? checkIsWithinRadius(plant.longLat, filterByCity.longLat, 200) // if a city is selected, only return plants within 200 km of that city.
            : true
          return (
            concreteFilter &&
            salesPersonFilter &&
            stateFilter &&
            cityFilter &&
            companyFilter
          )
        })
        .forEach(plant => {
          const concreteTypeTranslation2 = {
            false: 'Ready Mix',
            true: 'Precast',
          }
          formattedCorpData.push({
            group_name: corporation.corporationName,
            company_name: division.divisionName,
            plant_city: plant.city,
            plant_state: plant.state,
            sales: plant.salesperson,
            type: concreteTypeTranslation2[plant.isPrecast],
          })
        })
    })
  })
  return formattedCorpData
}

/** Adds division list and state list to the corporation data to be displayed on the ready mix/precast tables.
 * @param {Array} corpData - corporation data from the /Customers/Management/Corporations/SalesOverview endpoint.
 * @returns {Array} - returns the corporation data with division list and state list added.
 */
export const formatCorpTableData = corpData => {
  return corpData.map(company => {
    /** List of divisions for each corporation */
    const uniqueDivisions = new Set()
    /** List of states each corporation has divisions in */
    const uniqueStates = new Set()
    company.divisions.forEach(division => {
      if (division.divisionName != null)
        uniqueDivisions.add(division.divisionName)
      division.states.forEach(state => {
        if (state.state != null) uniqueStates.add(state.state)
      })
    })
    const hasSomeCustomerPlants = company.divisions.some(division =>
      division.plants.some(plant => plant.hasSystem)
    )
    return {
      ...company,
      divisionList: [...uniqueDivisions].join(', '),
      stateList: [...uniqueStates].join(', '),
      customerRow: hasSomeCustomerPlants,
    }
  })
}

/** Formats the upcoming project data to better display it on the front end.
 * @param {Array} upcomingProjects - upcoming project data from the /ConstructionProjects endpoint.
 * @param {Array} corpData - corporation data from the /Customers/Management/Corporations/SalesOverview endpoint.
 * @returns {Array} - returns the project data, formatted and with new information added: closest customer plants.
 */
export const formatUpcomingProjectData = (upcomingProjects, corpData) => {
  return upcomingProjects.map(project => {
    /** Check which customer plants are within 200 km of each project location */
    const checkIsNearProject = plant => {
      const distance = haversineDistance(
        plant.longLat[1],
        plant.longLat[0],
        project.latitude,
        project.longitude
      )
      return distance <= 200
    }
    const closestDivisionNames = new Set()
    corpData.forEach(corporation =>
      corporation.divisions.forEach(division =>
        division.plants.forEach(plant => {
          if (
            plant.hasSystem &&
            !plant.isPrecast &&
            checkIsNearProject(plant)
          ) {
            closestDivisionNames.add(division.divisionName)
          }
        })
      )
    )
    return {
      ...project,
      formattedLocation: `${project.city}, ${project.state}`,
      closestPlants: Array.from(closestDivisionNames).join(', '),
      formattedStartDate: project.targetStartDate
        ? project.targetStartDate
        : '',
      formattedSize: project.areaM2
        ? parseInt(project.areaM2).toLocaleString()
        : '',
      formattedValue: project.monetaryValue
        ? `${project.monetaryValue.toLocaleString()} ${project.currencyCode}`
        : '',
      formattedStage: project.stage ? project.stage : '',
    }
  })
}
