import React from 'react'
import { useSetRecoilState } from 'recoil'
import { atomProjectSearchValues } from '../../../Common/echoAtoms'
import KeywordSearchPresentational from './KeywordSearchPresentational'

function KeywordSearchLogical() {
  /** Project search values atom */
  const setProjectSearchValues = useSetRecoilState(atomProjectSearchValues)

  const handleChange = (event: { target: { value: string } }) => {
    const value = event.target.value
    setProjectSearchValues(prevValues => {
      const newValues = { ...prevValues }
      newValues.keywords = value
      return newValues
    })
  }

  return <KeywordSearchPresentational handleChange={handleChange} />
}

export default KeywordSearchLogical
