import React, { useState, useEffect } from 'react'
import PlantsConfirmationPresentational from './PlantsConfirmationPresentational'
import { useRecoilState } from 'recoil'
import { atomPlantsConfirmation } from '../../../Common/tssAtoms'
import cloneDeep from 'lodash.clonedeep'

function PlantsConfirmationLogical() {
  const [value, setValue] = useState('')
  const [plantsConfirmation, setPlantsConfirmation] = useRecoilState(
    atomPlantsConfirmation
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleCancel = () => {
    const newPlantsConfirmation = cloneDeep(plantsConfirmation)
    newPlantsConfirmation.isVisible = false
    setPlantsConfirmation(newPlantsConfirmation)
  }

  const handleSubmit = () => {
    plantsConfirmation.onSubmission(value)
  }

  useEffect(() => {
    setValue('')
  }, [plantsConfirmation.isVisible])

  return (
    <>
      {plantsConfirmation.isVisible && (
        <PlantsConfirmationPresentational
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          value={value}
        />
      )}
    </>
  )
}

export default PlantsConfirmationLogical
