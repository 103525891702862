import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  axisLabelsWithImperialUnit,
  axisLabelsWithMetricUnit,
  colorAxisColors,
  dataPointsSymbols,
  getBatchSampleUnitPrecision,
  getTooltipBatchSampleUnitPrecision,
} from '../../Constants/AnalysisConstants'
import {
  getMaxRanges,
  getMixColor,
  getGraphWatermark,
  preserveWatermarkAspectRatio,
} from '../../Logic/TSSLogic'
import SandBoxPresentational from './SandBoxPresentational'
import { comparedSort } from '../../../Common/Helpers/GeneralHelpers'

/**
 * Prepares data for graph, sets config for Highcharts, collects list of data error messages.
 * Exported here for reuse in Storybook.
 * @param {Array} selectedMixVariations array of mix designs to appear in the graph.
 * @param {String} xVariable currently selected property to plot on x axis.
 * @param {String} yVariable currently selected property to plot on y axis.
 * @param {String} colorVariable currently selected property to color graph points.
 * @param {Function} addToBatchDataTable collects selected graph points and adds them to a table component.
 * @returns {Object}  A graph options object including data.
 */
export const getSandBoxGraphOptions = (
  selectedMixVariations,
  xVariable,
  yVariable,
  colorVariable,
  addToBatchDataTable,
  isMetric
) => {
  let data = []
  let finalData = []
  let colorArray = []
  let freshPropertiesData = []
  let colorAxis = []
  let colorSet = []

  selectedMixVariations.forEach((selected, index) => {
    freshPropertiesData = []
    data = []

    // select the appropriate color by condition
    colorSet = colorAxisColors[selected.condition]?.[index % 2]

    /* create data array from the selected x axis, y axis and 
     property value from drop down */
    selected.freshProperties.forEach(row => {
      data.push([
        row[xVariable],
        row[yVariable],
        row[colorVariable] ? row[colorVariable] : null,
        row['batchId'],
        row['co2DosageUnit'],
      ])
    })

    /* colorArray is the list of property value, which will be 
    represented on the colorAxis color */
    data.forEach(d => {
      colorArray.push(d[2])
    })

    /* The color_array will be divided in the 4 sub-array, these 
    sub-arrays will then have their own color to show the intensity
    of the value*/
    colorArray = colorArray.sort(comparedSort)
    const maxRanges = getMaxRanges(colorArray)

    data.forEach(d => {
      let color = getMixColor(d, maxRanges, colorSet)
      freshPropertiesData.push({
        x: Number(d[0]),
        value:
          d[2] !== null
            ? Number(
                d[2]?.toFixed(
                  getBatchSampleUnitPrecision(colorVariable, isMetric, d[4])
                )
              )
            : null,
        y: Number(d[1]),
        color: color,
        batchId: d[3],
      })
    })
    finalData.push({
      showInLegend: true,
      name: selected.variationIdLabel,
      data: freshPropertiesData,
      color: colorSet?.[1],
      marker: {
        radius: 4.5,
        symbol: dataPointsSymbols[index % dataPointsSymbols.length],
      },
    })
    colorAxis.push({
      id: selected.variationIdLabel,
      min: colorArray[0],
      max: colorArray[colorArray.length - 1],
      minColor: colorSet?.[0],
      maxColor: colorSet?.[3],
      marker: null,
      showInLegend: true,
    })
  })

  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      plotBackgroundImage: getGraphWatermark(selectedMixVariations),
    },
    title: {
      text: null,
    },
    xAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit[xVariable]
          : axisLabelsWithImperialUnit[xVariable],
      },
    },
    yAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit[yVariable]
          : axisLabelsWithImperialUnit[yVariable],
      },
    },

    legend: {
      verticalAlign: 'bottom',
      align: 'left',
    },

    plotOptions: {
      scatter: {
        point: {
          events: {
            click: function() {
              addToBatchDataTable(this.batchId, this.y)
            },
          },
        },
      },
    },

    tooltip: {
      headerFormat: '',
      pointFormat: isMetric
        ? `<b>{series.name} <br/> 
        ${axisLabelsWithMetricUnit[xVariable]} 
        : </b>{point.x${getTooltipBatchSampleUnitPrecision(
          xVariable,
          true
        )}}<br/><b>
        ${axisLabelsWithMetricUnit[yVariable]} 
        : </b> {point.y${getTooltipBatchSampleUnitPrecision(
          yVariable,
          true
        )}}<br/><b>
        ${axisLabelsWithMetricUnit[colorVariable]} 
        : </b>{point.value}`
        : `${axisLabelsWithImperialUnit[xVariable]}
        : </b>{point.x${getTooltipBatchSampleUnitPrecision(
          xVariable,
          false
        )}}<br/><b>
        ${axisLabelsWithImperialUnit[yVariable]} 
        : </b> {point.y${getTooltipBatchSampleUnitPrecision(
          yVariable,
          false
        )}}<br/><b>
        ${axisLabelsWithImperialUnit[colorVariable]} 
        : </b>{point.value}`,
    },
    colorAxis: colorAxis,

    series: finalData,
  }
}

export default function SandBoxLogical(props) {
  SandBoxLogical.propTypes = {
    /** The selected variations of mix group table */
    selectedMixVariations: PropTypes.array.isRequired,
    /** function to add point to batch data table*/
    addToBatchDataTable: PropTypes.func.isRequired,
  }

  const {
    selectedMixVariations,
    addToBatchDataTable,
    isFilterPanelOpen,
    isMetric,
  } = props
  const [colorVariable, setColorVariable] = useState('slump')
  const [yVariable, setYVariable] = useState('batchStrength')
  const [xVariable, setXVariable] = useState('air')

  const graphOptions = getSandBoxGraphOptions(
    selectedMixVariations,
    xVariable,
    yVariable,
    colorVariable,
    addToBatchDataTable,
    isMetric
  )
  const [node, setRef] = useState(null)

  useEffect(() => {
    const watermarkedImage = document.querySelector(
      '.highcharts-container image'
    )
    preserveWatermarkAspectRatio(node, watermarkedImage)
  }, [node])

  // reflow chart to fit width when panel opens/closes
  useEffect(() => {
    const graph = node?.chart
    setTimeout(() => {
      if (graph) graph.reflow(false)
    }, 350)
  }, [node, isFilterPanelOpen])

  return (
    <SandBoxPresentational
      graphOptions={graphOptions}
      yVariable={yVariable}
      setYVariable={setYVariable}
      xVariable={xVariable}
      setXVariable={setXVariable}
      colorVariable={colorVariable}
      setColorVariable={setColorVariable}
      setRef={setRef}
      isMetric={isMetric}
    />
  )
}
