import { useEffect } from 'react'
import { ICementPlant, IEchoSettings } from '../Interfaces/EchoInterfaces'
import { EMapLayers } from '../Enums/EchoEnums'

/**
 * useCementFilter Hook
 *
 * Custom React hook for managing cement filter settings based on the selection of the cement layer.
 * @param {IEchoSettings} echoSettings - The current settings, including selected layers and filter values.
 * @param {React.Dispatch<React.SetStateAction<IEchoSettings>>} setEchoSettings - Function to update the echo settings.
 * - If the cement layer is selected and there are no active rmProducerRange or labResults filters, it sets default values.
 * - If the cement layer is deselected and any cement-related filters are active, it resets these filters.
 * - This hook listens for changes in the layers selection and adjusts the cement filters accordingly.
 */
export const useCementFilter = (
  filteredCementPlants: ICementPlant[],
  echoSettings: IEchoSettings,
  setEchoSettings: React.Dispatch<React.SetStateAction<IEchoSettings>>
) => {
  useEffect(() => {
    const isCementLayerSelected = echoSettings.layers.includes(
      EMapLayers.cementPlants
    )
    const hasActiveFilters =
      echoSettings.cementTypes.length > 0 ||
      echoSettings.cementSuppliers.length > 0 ||
      echoSettings.labResults.length > 0 ||
      echoSettings.rmProducerRange.length > 0

    if (isCementLayerSelected) {
      // Set default cement filter when the cement layer is selected
      if (
        !echoSettings.rmProducerRange.length &&
        !echoSettings.labResults.length
      ) {
        setEchoSettings((prevSettings: IEchoSettings) => ({
          ...prevSettings,
          labResults: ['All'],
          rmProducerRange: ['160km'],
        }))
      }
      // Reset cement supplier filter when there are no cement plants
      if (!filteredCementPlants.length && echoSettings.cementSuppliers.length) {
        setEchoSettings((prevSettings: IEchoSettings) => ({
          ...prevSettings,
          cementSuppliers: [],
        }))
      }
    } else if (hasActiveFilters) {
      // Reset cement filter when the cement layer is deselected
      setEchoSettings((prevSettings: IEchoSettings) => ({
        ...prevSettings,
        cementTypes: [],
        cementSuppliers: [],
        labResults: [],
        rmProducerRange: [],
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [echoSettings.layers, filteredCementPlants])
}
