import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { Button } from '@material-ui/core'
import { formatFilename } from '../Logic/AlarmsManagementLogicHelpers'
import GetAppIcon from '@material-ui/icons/GetApp'

DownloadDataPresentational.propTypes = {
  /** Formatted data for the CSV file to be downloaded */
  formattedData: PropTypes.array.isRequired,
  /** Time stamp of the current date in YYYY-MM-DD format */
  currentTimestamp: PropTypes.string.isRequired,
  /** Data type: alarms or service cases */
  dataType: PropTypes.string.isRequired,
}

function DownloadDataPresentational(props) {
  const {
    currentTimestamp,
    dataType,
    getDownloadedData,
    downloadedData,
    csvInstance,
  } = props

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<GetAppIcon />}
        onClick={getDownloadedData}
      >
        Download data
      </Button>
      {downloadedData && (
        <CSVLink
          data={downloadedData}
          filename={formatFilename(dataType, currentTimestamp)}
          ref={csvInstance}
          data-testid="download-csv"
        />
      )}
    </>
  )
}

export default DownloadDataPresentational
