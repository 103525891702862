import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  atomAddressOptions,
  atomLoadingAddresses,
} from '../../../Common/echoAtoms'
import { useRecoilValue } from 'recoil'

function AddressFilterPresentational(props) {
  AddressFilterPresentational.propTypes = {
    /** Function called when address search field value changes */
    handleChange: PropTypes.func.isRequired,
    /** Function called when address search is submitted */
    handleSearch: PropTypes.func.isRequired,
  }
  /** Address options loaded in the address search Autocomplete */
  const addressOptions = useRecoilValue(atomAddressOptions)
  /** Boolean to determine whether address options are loading in Autocomplete */
  const loadingAddresses = useRecoilValue(atomLoadingAddresses)
  const { handleChange, handleSearch } = props

  return (
    <Autocomplete
      id="addressSearch"
      onInputChange={handleChange}
      onChange={handleSearch}
      options={addressOptions}
      loading={loadingAddresses}
      loadingText={'Loading options...'}
      getOptionLabel={option => option}
      getOptionSelected={(option, value) => option === value}
      renderInput={params => (
        <TextField
          {...params}
          label="Address"
          variant="outlined"
          size="small"
          placeholder="Type an address to load options"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default AddressFilterPresentational
