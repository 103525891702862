import { Box, Button, Collapse, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ExpandedRemote } from './ExpandedRemote'

CollapsableRemoteInfo.propTypes = {
  /** The remote units that will be displayed in this collapsed row */
  remotes: PropTypes.array.isRequired,

  /** Are these remotes for the management or inventory page. Defaults to management */
  variant: PropTypes.oneOf(['management', 'inventory']),

  /** Function to select the master unit of these remotes for more details */
  setSelectedMasterUnit: PropTypes.func.isRequired,

  /** Is this collapsed row expanded (true) or collapsed (false) */
  expanded: PropTypes.bool.isRequired,

  /** The deployment phase */
  deploymentPhase: PropTypes.string,
}

CollapsableRemoteInfo.defaultProps = {
  variant: 'management',
}

function CollapsableRemoteInfo(props) {
  const { remotes, variant, setSelectedMasterUnit, expanded } = props

  return (
    <Collapse in={expanded}>
      <Box margin={1}>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={setSelectedMasterUnit}
              component={Link}
              to="/selected"
            >
              Select Master Unit
            </Button>
          </Grid>
        </Grid>
        {remotes.map(remote => (
          <ExpandedRemote
            key={remote.localId}
            remoteUnit={remote}
            variant={variant}
          />
        ))}
      </Box>
    </Collapse>
  )
}

export default CollapsableRemoteInfo
