import React from 'react'
import { PlantsTableData } from './PlantsEditableTable.types'
import PlantsEditableTablePresentational from './PlantsEditableTablePresentational'

interface PlantsEditableTableLogicalProps {
  rows: PlantsTableData
  /** Updates single row from table. Each row corresponds with one plant. */
  updateTableRow: (...args: any[]) => any
  /** Current page */
  page: number
  /** State setter for the page  */
  setPage: (...args: any[]) => any
  /** the number of rows to display per page */
  rowsPerPage: number
  /** the state setter for the number of rows per page */
  setRowsPerPage: (...args: any[]) => any
  /** the number of results returned by the endpoint, used for pagination */
  count: number
  /** Order of the column either ascending or descending */
  order: string
  /** State setter for the order */
  setOrder: (...args: any[]) => any
  /** The column to order by, should be the id of a headcell */
  orderBy: string
  /** state setter for order by */
  setOrderBy: (...args: any[]) => any
}

function PlantsEditableTableLogical(props: PlantsEditableTableLogicalProps) {
  const {
    rows,
    updateTableRow,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = props

  const handleChangePage = (
    _: React.ChangeEvent<HTMLInputElement>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0)
    setRowsPerPage(Number(event.target.value))
  }

  const handleSortRequest = (
    _: React.ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <PlantsEditableTablePresentational
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSortRequest={handleSortRequest}
      order={order}
      orderBy={orderBy}
      page={page}
      count={count}
      rows={rows}
      updateTableRow={updateTableRow}
      rowsPerPage={rowsPerPage}
    />
  )
}

export default PlantsEditableTableLogical
