import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  ICommissionReportSettings,
  IMaturityIntervalOptions,
  IReportMixSelectionSettings,
  NumericIntervals,
} from '../../Logic/Types'
import cloneDeep from 'lodash.clonedeep'
import CancelIcon from '@material-ui/icons/Cancel'
import { preventEventOnKeyDown } from '../../../Common/Helpers/GeneralHelpers'

export interface ITestResultsBarGraphSettingsProps {
  maturityIntervalOptions: IMaturityIntervalOptions[]
  showDesignStrength: boolean | undefined
  selectedInterval: NumericIntervals[] | undefined
  mixDesignId: number | null
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  dualGraph: boolean
}

function TestResultsBarGraphSettings(
  props: Readonly<ITestResultsBarGraphSettingsProps>
) {
  const {
    maturityIntervalOptions,
    showDesignStrength,
    selectedInterval,
    mixDesignId,
    setReportSettings,
    dualGraph,
  } = props

  const singleOptionSelected = selectedInterval && selectedInterval.length < 2

  const getOptionDisabled = (option: IMaturityIntervalOptions[]) => {
    if (dualGraph && selectedInterval?.length === 2) {
      if (selectedInterval.includes(option.value)) return false
      return true
    } else if (!dualGraph && selectedInterval?.length === 3) {
      return true
    }
    return false
  }

  const handleShowDesignStrength = () => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      const mixDesignSettings = newSettings.mixDesignSettings.find(
        (settings: IReportMixSelectionSettings) =>
          settings.mixDesignId === mixDesignId
      )
      mixDesignSettings.avgStrengthGraph.showDesignStrength = !mixDesignSettings
        .avgStrengthGraph.showDesignStrength
      return { ...newSettings }
    })
  }

  const handleIntervalChange = (
    _event: ChangeEvent<{}>,
    value: IMaturityIntervalOptions[]
  ) => {
    const newSelectedIntervals = value.map(
      (item: IMaturityIntervalOptions) => item.value
    )
    if (newSelectedIntervals.length) {
      // prevents single selections from being removed
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings)
        const mixDesignSettings = newSettings.mixDesignSettings.find(
          (settings: IReportMixSelectionSettings) =>
            settings.mixDesignId === mixDesignId
        )
        mixDesignSettings.avgStrengthGraph.selectedInterval = newSelectedIntervals
        return { ...newSettings }
      })
    }
  }

  const renderTags = (value: IMaturityIntervalOptions[]) =>
    value.map((option: IMaturityIntervalOptions, index: number) => (
      <Chip
        key={index}
        label={option.label}
        onDelete={e => {
          if (!singleOptionSelected) {
            const newSelected: IMaturityIntervalOptions[] = [...value]
            newSelected.splice(index, 1)
            handleIntervalChange(e, newSelected)
          }
        }}
        deleteIcon={!singleOptionSelected ? <CancelIcon /> : <></>}
        size="small"
        style={{ margin: '1px' }}
      />
    ))

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      spacing={3}
      alignItems="center"
      style={{ marginLeft: '32px' }}
    >
      <Grid item xs={6}>
        <Autocomplete
          size="small"
          multiple
          id="maturityAge"
          value={maturityIntervalOptions.filter(option =>
            selectedInterval?.includes(option.value as NumericIntervals)
          )}
          options={maturityIntervalOptions.filter(option => option.label)}
          getOptionLabel={option => option?.label || ''}
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionDisabled={option => getOptionDisabled(option)}
          onChange={handleIntervalChange}
          limitTags={3}
          renderInput={params => (
            <TextField
              {...params}
              label={'Maturity Age'}
              variant="outlined"
              onKeyDown={
                singleOptionSelected && preventEventOnKeyDown(['Backspace'])
              }
            />
          )}
          renderTags={renderTags}
          disableClearable={true}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              id="designStrengthView"
              name="designStrengthView"
              checked={showDesignStrength}
              onChange={handleShowDesignStrength}
              color="primary"
            />
          }
          label={<Typography>Design Strength</Typography>}
        />
      </Grid>
    </Grid>
  )
}

export default TestResultsBarGraphSettings
