import React from 'react'
import {
  Grid,
  Paper,
  MenuItem,
  TextField,
  Collapse,
  Typography,
  IconButton,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  additionalFilters,
  freshPropertyLabelsWithImperialUnit,
  freshPropertyLabelsWithMetricUnit,
} from '../Constants/AnalysisConstants'
import FreshPropertyDropdown from './FreshPropertyDropdown'
import GraphFilterSliderSelector from './GraphFilterSliderSelector'
import GraphFilterDateSelector from './GraphFilterDateSelector'
import { makeStyles } from '@material-ui/styles'
import { TestCategories } from '../Logic/Types'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useRecoilValue } from 'recoil'
import { atomGraphSelectedFilters } from '../../Common/tssAtoms'

const useStyles = makeStyles({
  sliderFilter: { margin: '2.5em 0.5em 0 0.5em' },
  filterCard: {
    paddingBottom: '0em',
    border: 'none',
  },
  separator: {
    marginBottom: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  separatorDouble: {
    margin: '10px 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  separatorCollapsible: {
    marginBottom: '5px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
})

export default function GraphFilterBase(props) {
  GraphFilterBase.propTypes = {
    /** Function updates the currently selected range of a filter. */
    updateFilterSelectedRange: PropTypes.func.isRequired,
    /** GraphFilter object containing the property, total range, and currently selected range.*/
    graphFilter: PropTypes.object.isRequired,
    /** Function updates the property to filter data by. */
    updateFilterProperty: PropTypes.func.isRequired,
    /** Function that updates whether or not the graph filter is collapsed or not */
    updateFilterExpandedProperty: PropTypes.func.isRequired,
  }

  const {
    updateFilterSelectedRange,
    updateFilterSelectedOptions,
    graphFilter,
    updateFilterProperty,
    updateSelectedAge,
    updateFilterExpandedProperty,
    isMetric,
  } = props

  const classes = useStyles()
  const selectedFilters = useRecoilValue(atomGraphSelectedFilters)

  /**
   * Renders a range selector depending on the filter's selected property.
   * @param {String} property the currently selected property in a filter.
   * @returns {Grid} a range selector component wrapped in a Grid, depending on the property.
   */
  const renderRangeSelector = property => {
    switch (property) {
      case 'batchInterval':
        return (
          <Grid item xs className={classes.separatorDouble}>
            <TextField
              select
              size="small"
              variant="outlined"
              label={'Interval (Days)'}
              disabled={graphFilter.isDisabled}
              onChange={(event, newValue) => {
                updateSelectedAge(event.target.value)
                updateFilterSelectedOptions(graphFilter, [event.target.value])
              }}
              value={
                graphFilter.selectedOptions.length > 0
                  ? graphFilter.selectedOptions[0]
                  : ''
              }
            >
              {graphFilter.options.map(val => (
                <MenuItem key={'ageOption' + val} value={val}>
                  {val} days
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )
      case 'productionDate':
        return (
          <Grid item xs>
            <GraphFilterDateSelector
              updateFilterSelectedRange={updateFilterSelectedRange}
              graphFilter={graphFilter}
            />
          </Grid>
        )
      case 'testCategory':
        return (
          <Grid item xs>
            <TextField
              select
              size="small"
              variant="outlined"
              label="Select test category"
              onChange={(event, newValue) => {
                updateFilterSelectedOptions(graphFilter, [event.target.value])
              }}
              value={
                graphFilter.selectedOptions.length > 0
                  ? graphFilter.selectedOptions[0]
                  : ''
              }
            >
              {graphFilter.options.map(val => (
                <MenuItem key={'testCategoryOption' + val} value={val}>
                  {TestCategories[val]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )

      default:
        return (
          <Grid item xs className={classes.sliderFilter}>
            <GraphFilterSliderSelector
              updateFilterSelectedRange={updateFilterSelectedRange}
              graphFilter={graphFilter}
            />
          </Grid>
        )
    }
  }

  const setPropertyValue = (property, label) => {
    updateFilterProperty(graphFilter, property, label)
  }

  const getOptionDisabled = option => {
    return (
      selectedFilters.findIndex(filter => filter.property === option.key) > -1
    )
  }
  return (
    <Paper variant="outlined" className={classes.filterCard}>
      <Grid container alignContent="stretch" justify="center">
        <Grid container item xs>
          {graphFilter.defaultFilter ? (
            renderRangeSelector(graphFilter.property)
          ) : (
            <>
              {graphFilter.property && graphFilter.label ? (
                <Grid container className={classes.separatorCollapsible}>
                  <Grid item lg={10} md={12} xl={12} xs={10}>
                    <Typography variant="h5" color="secondary">
                      {graphFilter.label}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <IconButton
                      onClick={() => updateFilterExpandedProperty(graphFilter)}
                    >
                      {graphFilter.expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                    <Collapse in={graphFilter.expanded}>
                      {graphFilter.property &&
                        renderRangeSelector(graphFilter.property)}
                    </Collapse>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs className={classes.separator}>
                  <FreshPropertyDropdown
                    propertyOptions={
                      isMetric
                        ? freshPropertyLabelsWithMetricUnit.concat(
                            additionalFilters
                          )
                        : freshPropertyLabelsWithImperialUnit.concat(
                            additionalFilters
                          )
                    }
                    labelName="Select a Property"
                    selectedProperty={graphFilter.property}
                    setSelectedProperty={setPropertyValue}
                    disabled={false}
                    getOptionDisabled={getOptionDisabled}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
