import React, { ChangeEvent } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomProjectSearchValues,
} from '../../../Common/echoAtoms'
import {
  IConcreteCorporation,
  IAutocompleteOption,
} from '../../Interfaces/EchoInterfaces'
import GroupNameSelectPresentational from './GroupNameSelectPresentational'
import { EMapLayers } from '../../Enums/EchoEnums'

interface IComponentProps {
  filteredCorpData: Array<IConcreteCorporation>
}

function GroupNameSelectLogical(props: IComponentProps) {
  const { filteredCorpData } = props

  /** Echo settings atom */
  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Atom with project search values */
  const [projectSearchValues, setProjectSearchValues] = useRecoilState(
    atomProjectSearchValues
  )

  /** All groups (corporations) listed in Echo data */
  const groupList = filteredCorpData.map(company => {
    return { groupName: company.corporationName, ID: company.corporationId }
  })

  /** Options (groups) for the autocomplete select input */
  const options = [...groupList]
    .sort((a, b) => a.groupName.localeCompare(b.groupName))
    .map(group => {
      return { label: group.groupName, value: group.ID }
    })

  /** Handle input change. Pass selected filter value to useEffect in EchoHome. */
  const handleChange = (
    _event: ChangeEvent<{}>,
    value: IAutocompleteOption | null
  ) => {
    if (echoSettings.layers.includes(EMapLayers.projectSearch)) {
      const newSearchValues = cloneDeep(projectSearchValues)
      if (value) {
        newSearchValues.groups = [value]
      } else {
        newSearchValues.groups = []
      }
      setProjectSearchValues(newSearchValues)
    } else {
      const newEchoSettings = cloneDeep(echoSettings)
      if (value) {
        newEchoSettings.groups = [value]
        newEchoSettings.isGreenfield = false
      } else {
        newEchoSettings.groups = []
      }
      setEchoSettings(newEchoSettings)
    }
  }

  return (
    <GroupNameSelectPresentational
      handleChange={handleChange}
      options={options}
    />
  )
}

export default GroupNameSelectLogical
