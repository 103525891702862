import React from 'react'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TypographyProps,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  listItem: {
    width: '100%',
  },
  nested: {
    paddingLeft: '2em',
  },
  icon: {
    minWidth: 'auto',
    paddingRight: '2px',
  },
  recordIcon: {
    fontSize: '0.3rem',
  },
}))

interface ChartsLowSampleErrorLogicalProps {
  errorMessages: Array<string>
  errorTitle: string
}

function ChartsLowSampleErrorPresentational(
  props: ChartsLowSampleErrorLogicalProps
) {
  const classes = useStyles()
  const typographyProps: TypographyProps = { color: 'error' }
  const warningTextTypographyProps: TypographyProps = {
    color: 'error',
    variant: 'caption',
  }
  return (
    <List disablePadding>
      <ListItem disableGutters dense className={classes.listItem}>
        <ListItemIcon className={classes.icon}>
          <ReportProblemOutlinedIcon color="error" />
        </ListItemIcon>
        {/* ListItemText acts as a wrapper for typography */}
        <ListItemText primaryTypographyProps={typographyProps}>
          {props.errorTitle}
        </ListItemText>
      </ListItem>
      <List className={classes.nested} disablePadding>
        {props.errorMessages.map(errorMessage => (
          <ListItem
            key={errorMessage}
            disableGutters
            dense
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <FiberManualRecordIcon
                className={classes.recordIcon}
                color="error"
              />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={warningTextTypographyProps}>
              {errorMessage}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </List>
  )
}

export default ChartsLowSampleErrorPresentational
