import React from 'react'
import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { RadioButtonChecked } from '@material-ui/icons'
import { MixGroupVariation } from '../../Logic/Types'
import { roundUpToDecimal } from '../../../Common/Helpers/GeneralHelpers'
import { getCO2DosagePrecision } from '../../Helpers/BaleenHelpers'

export interface ICopyMixVariationTableRow {
  /** Single Variation to be display in the tableRow */
  row: MixGroupVariation
  /** Function to handle selecting variation when the row is clicked */
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: MixGroupVariation
  ) => void
  /** Boolean to indicate if the row is selected */
  isSelected: boolean | null | undefined
  /** Id of the label to the checkbox in table */
  labelId: string
  /** Boolean to indicate the measurement unit */
  isMetric: boolean
}
export default function CopyMixVariationTableRow(
  props: ICopyMixVariationTableRow
) {
  const { row, handleClick, isSelected, labelId, isMetric } = props
  return (
    <TableRow
      hover
      onClick={event => handleClick(event, row)}
      role="checkbox"
      aria-checked={isSelected}
      key={row.variationId}
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isSelected}
          icon={isSelected ? <RadioButtonChecked /> : <CircleUnchecked />}
          checkedIcon={<RadioButtonChecked />}
          inputProps={{
            'aria-labelledby': labelId,
            'data-testid': 'select-variation',
          }}
        />
      </TableCell>
      <TableCell>{row.variationIdLabel}</TableCell>
      <TableCell align="left">{row.cementReductionPercent}</TableCell>
      <TableCell align="left">
        {row.cO2DosageLabel !== null
          ? row.cO2DosageLabel?.toFixed(
              getCO2DosagePrecision(row.cO2DosageUnit, isMetric)
            )
          : null}
      </TableCell>
      <TableCell align="left">{row.totalBatches || row.sampleCount}</TableCell>
      <TableCell align="left">
        {isMetric
          ? row.designStrength?.toFixed(2)
          : roundUpToDecimal(row.designStrength, 0)}
      </TableCell>
      <TableCell align="left">
        {isMetric
          ? row.requiredStrength?.toFixed(2)
          : roundUpToDecimal(row.requiredStrength, 0)}
      </TableCell>
      <TableCell align="left">
        {isMetric
          ? row.averageStrength?.toFixed(2)
          : roundUpToDecimal(row.averageStrength, 0)}
      </TableCell>
      <TableCell align="left">
        {typeof row.overDesign === 'number'
          ? row.overDesign.toFixed(2)
          : row.overDesign}
      </TableCell>
    </TableRow>
  )
}
