import React from 'react'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import { MaterialManagerComponent } from '../Logic/Types'
import { convertPascalCaseToTitleCase } from '../../Common/Helpers/GeneralHelpers'

export interface IMaterialManagerBreadcrumbProps {
  currentView: MaterialManagerComponent
  setCurrentView: (view: MaterialManagerComponent) => void
  hasUserInput: boolean
  setManualPrompt: (arg0: any) => void
}

function MaterialManagerBreadcrumb(props: IMaterialManagerBreadcrumbProps) {
  const { currentView, setCurrentView, hasUserInput, setManualPrompt } = props

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        onClick={() => {
          hasUserInput
            ? setManualPrompt({
                show: true,
                view: MaterialManagerComponent.MaterialTable,
              })
            : setCurrentView(MaterialManagerComponent.MaterialTable)
        }}
        style={{ cursor: 'pointer' }}
      >
        <HomeOutlinedIcon data-testid="home-icon" />
      </Link>
      {currentView !== MaterialManagerComponent.MaterialTable && (
        <Typography color="textPrimary" variant="body1">
          {convertPascalCaseToTitleCase(currentView)}
        </Typography>
      )}
    </Breadcrumbs>
  )
}

export default MaterialManagerBreadcrumb
