import {
  ConvertKgToLb,
  roundUpToDecimal,
} from '../../../Common/Helpers/GeneralHelpers'
import Reading from './Reading'

class Injection {
  injectionId

  remoteUnitId

  startTime

  endTime

  readings

  ticketId

  injectedCO2Kg

  /**
   *
   * @param {Number} injectionId
   * @param {Number} remoteUnitId
   * @param {String} startTimestamp
   * @param {String} endTimestamp
   * @param {{injectionReadingId: Number
   *        injectionId: Number,
   *        inletLiquidPressureKPa: Number,
   *        inletGasPressureKPa: Number,
   *        upstreamPressureKPa: Number,
   *        downstreamPressure0KPa: Number,
   *        downstreamPressure1KPa: Number,
   *        upstreamTemperatureC: Number,
   *        flowRateKgPerSec: Number,
   *        injectedCO2Kg: Number,
   *        timestamp: String}[]} readings
   * @param {{
   *      alarmId:Number,
   *      code: Number,
   *      remoteUnitId: Number,
   *      injectionId: Number,
   *      timestamp: String,
   *      resolvedTimestamp: String,
   *      resolved: Boolean
   *      } [] } alarms
   * @param {Number} ticketId
   */
  constructor(
    injectionId,
    remoteUnitId,
    startTimestamp,
    endTimestamp,
    alarms,
    readings,
    ticketId,
    injectedCO2Kg
  ) {
    this.injectionId = injectionId
    this.remoteUnitId = remoteUnitId
    this.startTime = new Date(startTimestamp)
    this.endTime = new Date(endTimestamp)
    this.readings = readings?.map(reading => Reading.fromKelownaObject(reading))
    this.alarms = alarms
    this.ticketId = ticketId
    this.injectedCO2Kg = injectedCO2Kg
  }

  get injectedCO2Lb() {
    return ConvertKgToLb(this.injectedCO2Kg)
  }

  get injectionTimeInSeconds() {
    return roundUpToDecimal((this.endTime - this.startTime) / 1000, 1)
  }

  static fromKelownaObject(kelownaInjection) {
    return new Injection(
      kelownaInjection.injectionId,
      kelownaInjection.remoteUnitId,
      kelownaInjection.startTimestamp,
      kelownaInjection.endTimestamp,
      kelownaInjection.readings,
      kelownaInjection.alarms,
      kelownaInjection.ticketId,
      kelownaInjection.injectedCO2Kg,
      kelownaInjection.injectedCO2Lb
    )
  }
}

export default Injection
