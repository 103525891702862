import dataHelpers from '../../Common/Helpers/DataHelpers'

/** Returns corporations for the Echo interface */
export async function getCorporationsSalesOverview() {
  const endpoint = '/Customers/Management/Corporations/SalesOverview'
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Returns upcoming projects for the Echo interface */
export async function getUpcomingProjects() {
  const endpoint = '/ConstructionProjects'
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Returns CarbonCure projects for the Echo interface */
export async function getCarbonCureProjects() {
  const endpoint = '/ConstructionProjects/Historical'
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Returns cement plants for the Echo interface */
export async function getCementPlants(parameters = {}) {
  const endpoint = `/Customers/Cements/Map`
  return dataHelpers.fetchDataHelper(endpoint, parameters)
}

/** Returns geocode results for a given search term */
export async function getMapboxGeocode(searchTerm, params = {}) {
  let arrayString = ''
  const url = new URL(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json/`
  )
  const urlSearchParams = new URLSearchParams(params)
  url.search = urlSearchParams + arrayString
  return fetch(url).then(
    res => {
      return res
    },
    // according to the docs you don't want to catch errors, just handle them with a callback
    error => {
      console.error(error)
      return error
    }
  )
}

/** Returns CO2 depots for the Echo interface */
export async function getCO2Depots() {
  const endpoint = '/Customers/Management/CO2Supplier/MapLayer'
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Returns CO2 vendors for the Echo interface */
export async function getCO2Vendors() {
  const endpoint = '/Customers/Management/CO2Supplier/Table'
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Returns sales opportunities for the Echo interface */
export async function getOpportunities() {
  const endpoint = '/Sales/Opportunities'
  return dataHelpers.fetchDataHelper(endpoint)
}
