import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  getGraphWatermark,
  GetSlopeAndIntercept,
  preserveWatermarkAspectRatio,
} from '../../Logic/TSSLogic'
import { max } from 'mathjs'
import { getColorByCondition } from '../../Logic/ViewDesignLogic'
import {
  axisLabelsWithImperialUnit,
  axisLabelsWithMetricUnit,
  dataPointsSymbols,
} from '../../Constants/AnalysisConstants'
import StrengthDevelopmentPresentational from './StrengthDevelopmentPresentational'

/**
 * Calculated the standard deviation for the dataset.
 * @param {Number} maxXAxis the calculated maximim x axis, used to set the horizontal range.
 * @param {Number} slope the calculated slope for the dataset.
 * @param {Number} intercept the calculated intercept for the dataset.
 * @returns {Number} the calculated standard deviation.
 */
const calculateStrengthDev = (maxXAxis, slope, intercept) => {
  const strengthDev = []
  for (let x = 0; x <= maxXAxis + 1; x++) {
    let fx = slope + intercept * Math.log10(x)
    strengthDev.push([x, fx])
  }
  return strengthDev
}

/**
 * Prepares data for graph, sets config for Highcharts, collects list of data error messages.
 * Exported here for reuse in Storybook.
 * @param {Array} selectedMixVariations array of mix variations to appear in the graph.
 * @param {Function} addToBatchDataTable collects selected graph points and adds them to a table component.
 * @param {Boolean} isMetric whether or not the measurement system is metric
 * @returns {[Object, Array]} A graph options object including data, and an array of messages.
 */
export const getStrengthDevGraphOptionsAndErrors = (
  selectedMixVariations,
  addToBatchDataTable,
  isMetric
) => {
  let noSampleData = []
  let initialData = []
  let finalData = []
  let interval = new Set()
  let mixdesignColor
  let maxXAxis = 28

  selectedMixVariations.forEach((data, index) => {
    initialData = []

    // select the appropriate color by condition
    mixdesignColor = getColorByCondition(data.condition, index)

    /* Process the scatter data points to display on graph */
    data.freshProperties.forEach(freshProperty => {
      initialData.push({
        x: parseInt(freshProperty.batchInterval),
        y: Number(freshProperty.batchStrength),
        batchId: freshProperty.batchId,
      })
      interval.add(parseInt(freshProperty.batchInterval))
    })
    if (!initialData.length) return

    /* Get the value for highest interval from the selected designs */
    initialData.forEach(d => {
      interval.add(d.x)
    })
    maxXAxis = max(maxXAxis, max(Array.from(interval)))

    /* Add the scatter data to the graph */
    finalData.push({
      name: data.variationIdLabel,
      type: 'scatter',
      data: initialData,
      accessibility: {
        exposeAsGroupOnly: true,
      },
      marker: {
        radius: 4.5,
        symbol: dataPointsSymbols[index % dataPointsSymbols.length],
        fillColor: mixdesignColor,
      },
    })

    /* Processing for developing strength Development mean curve */
    const { slope, intercept } = GetSlopeAndIntercept(initialData)

    /* If data has more than one interval, then only the strength dev curves can be displayed */
    if (slope && intercept) {
      /* Create strength development mean curve from equation y=f(x)= slope + intercept * log(x) */
      const strengthDev = calculateStrengthDev(maxXAxis, slope, intercept)

      finalData.push({
        name: data.variationIdLabel + ' - Strength Development',
        type: 'spline',
        data: strengthDev,
        color: mixdesignColor,
        accessibility: {
          exposeAsGroupOnly: true,
        },
        dashStyle: 'solid',
        series: {
          marker: 'disable',
          symbol: null,
        },
      })
    } else {
      /* Display a message that the strength development is not possible for the mixCode */
      noSampleData.push(
        data.variationIdLabel +
          ' has data for only one interval. Strength Development Curve could not be generated.'
      )
    }
  })

  const graphOptions = {
    exporting: {
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      fallbackToExportServer: false,
    },

    chart: {
      zoomType: 'xy',
      plotBackgroundImage: getGraphWatermark(selectedMixVariations),
    },
    title: {
      text: null,
    },

    xAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit['batchInterval']
          : axisLabelsWithImperialUnit['batchInterval'],
      },
      alignTicks: false,
      min: 0,
      max: maxXAxis + 3,
    },

    yAxis: {
      title: {
        text: isMetric
          ? axisLabelsWithMetricUnit['batchStrength']
          : axisLabelsWithImperialUnit['batchStrength'],
      },
      plotLines: [],
      min: 0,
    },

    plotOptions: {
      scatter: {
        point: {
          events: {
            click: function() {
              addToBatchDataTable(this.batchId, this.y)
            },
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: isMetric
            ? `<b>{series.name} <br/> 
            ${axisLabelsWithMetricUnit['batchInterval']}
            : </b>{point.x} <br/><b>
            ${axisLabelsWithMetricUnit['batchStrength']}
            : </b>{point.y:.2f}`
            : `<b>{series.name} <br/> 
            ${axisLabelsWithImperialUnit['batchInterval']}
            : </b>{point.x} <br/><b>
            ${axisLabelsWithImperialUnit['batchStrength']}
            : </b>{point.y:.0f}`,
        },
      },
      spline: {
        marker: {
          enabled: false,
          symbol: null,
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{series.name} ',
        },
      },
    },
    legend: {
      verticalAlign: 'bottom',
      align: 'left',
    },

    series: finalData,
  }

  return [graphOptions, noSampleData]
}

export default function StrengthDevelopmentLogical(props) {
  StrengthDevelopmentLogical.propTypes = {
    /** The selected variations of mix group table */
    selectedMixVariations: PropTypes.array.isRequired,
    /** Function to add point to batch data table*/
    addToBatchDataTable: PropTypes.func.isRequired,
    isMetric: PropTypes.bool.isRequired,
  }
  const {
    selectedMixVariations,
    addToBatchDataTable,
    isFilterPanelOpen,
    isMetric,
  } = props
  const [node, setRef] = useState(null)

  const [graphOptions, dataErrors] = getStrengthDevGraphOptionsAndErrors(
    selectedMixVariations,
    addToBatchDataTable,
    isMetric
  )
  useEffect(() => {
    const watermarkedImage = document.querySelector(
      '.highcharts-container image'
    )
    preserveWatermarkAspectRatio(node, watermarkedImage)
  }, [node])

  useEffect(() => {
    const graph = node?.chart
    setTimeout(() => {
      if (graph) graph.reflow(false)
    }, 350)
  }, [node, isFilterPanelOpen])

  return (
    <StrengthDevelopmentPresentational
      graphOptions={graphOptions}
      dataErrors={dataErrors}
      setRef={setRef}
      isMetric
    />
  )
}
