import { atom } from 'recoil'

/*
 * The atoms.js will contain all atoms for shared state.
 * As a general rule, only define an atom if a state needs to be shared between views.
 * Try and have states that are dependent on an endpoint/return value of a function state it in the jsdocs for that atom.
 * Below are two examples of how an atom should be documented.
 */

const atomPlantTemporal = atom({
  key: 'atomPlantTemporal',
  default: null,
})

export { atomPlantTemporal }
