import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import { isProjectSearchActive } from '../../Logic/FilterLogicHelpers'

SalesPersonSelectPresentational.propTypes = {
  /** Function called when a sales person is selected */
  handleChange: PropTypes.func.isRequired,
  /** All sales people on file with the Echo data */
  salesPeople: PropTypes.array.isRequired,
}

function SalesPersonSelectPresentational(props) {
  const { handleChange, salesPeople } = props
  const echoSettings = useRecoilValue(atomEchoSettings)

  return (
    <Autocomplete
      value={
        echoSettings.salesPeople.length > 0 ? echoSettings.salesPeople[0] : null
      }
      disabled={isProjectSearchActive(echoSettings)}
      id="salesPersonSelect"
      onChange={handleChange}
      options={salesPeople}
      getOptionLabel={option => option}
      getOptionSelected={(option, value) => option === value}
      renderInput={params => (
        <TextField
          {...params}
          label="Sales person"
          variant="outlined"
          size="small"
          placeholder="Select a sales person..."
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default SalesPersonSelectPresentational
