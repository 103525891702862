export const baseColors = {
  primary: {
    main: '#E37F1C',
    dark: '#B56516',
    light: '#E89849',
    contrast: '#ffffff',
    twentySixMainOpacity: 'rgba(227, 127, 28, 0.26)',
    hover: 'rgba(227, 128, 28, 0.04)',
    eightOpacity: 'rgba(227, 127, 28, 0.08)',
  },
  noCO2Tag: {
    main: '#495358',
    twentySixMainOpacity: 'rgba(73, 83, 88, 0.26)',
  },
  variations: {
    baseline: '#254C73',
    optimized: '#59BB9B',
  },
  secondary: {
    main: '#1A4373',
    dark: '#15273C',
    light: '#739CCE',
    contrast: '#ffffff',
  },
  text: {
    primary: '#000000',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.35)',
    contrast: '#ffffff',
  },
  error: {
    main: '#D74F39',
    dark: '#963727',
    light: '#DF7260',
    contrast: '#ffffff',
    background: 'rgba(215, 79, 57, 0.5)',
    hover: 'rgba(215, 79, 57, 0.04)',
    headerText: '#562017',
  },
  warning: {
    main: '#FFC144',
    dark: '#976418',
    light: '#FFCD69',
    contrast: '#000000',
    background: '#FFE0A1',
    hover: '#664D1B',
  },
  info: {
    main: '#5CA1D8',
    dark: '#1E73A6',
    light: '#91D2FF',
    contrast: '#ffffff',
    headerText: '#2b4054',
    fiftyMainOpacity: 'rgba(92, 161, 216, 0.5)',
  },
  success: {
    main: '#3B8D72',
    dark: '#29624F',
    light: '#62A38E',
    contrast: '#FFFFFF',
    background: '#9CC6B9',
    hover: '#18382E',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
    required: 'rgba(32, 59, 91, 0.5)',
    checkboxActiveRequired: '#8F9EAC',
    checkboxNotSelectedDisabled: '#A6A6A6',
    checkboxNotSelectedEnabled: '#666666',
  },
  table: {
    outlineBorder: 'rgba(0, 0, 0, 0.23)',
    background: 'rgba(112, 112, 112, 0.04)',
    selected: 'rgba(227, 127, 28, 0.08)',
    shadedBackground: 'rgba(112, 112, 112, 0.08)',
  },
}

export const echoColors = {
  customers: { rgb: [231, 138, 61], hex: '#E78A3D' },
  privatelyOwned: { rgb: [160, 126, 204], hex: '#A07ECC' },
  verticallyIntegrated: { rgb: [102, 167, 214], hex: '#66A7D6' },
  opportunities: { rgb: [139, 195, 74], hex: '#8BC34A' },
  co2Deport: { rgb: [0, 0, 0], hex: '#000000' },
}

export const cementPlantsColors = {
  lowReactivity: { rgb: [70, 83, 88], hex: '#465358' },
  mediumReactivity: { rgb: [66, 122, 152], hex: '#145A8B' },
  highReactivity: { rgb: [227, 127, 28], hex: '#E37F1C' },
  notTested: { rgb: [129, 135, 138], hex: '#81878A' },
  positive: { rgb: [59, 141, 114], hex: '#3B8D72' },
  negative: { rgb: [215, 79, 57], hex: '#D74F39' },
}
