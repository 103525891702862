const typography = {
  fontFamily: {
    heading: '"Inter", sans-serif',
    body: '"Urbanist", sans-serif',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  fontSize: {
    XS: '12px',
    S: '14px',
    regular: '16px',
    M: '18px',
    L: '22px',
    XL: '26px',
    XXL: '32px',
  },
  highcharts: {
    fontFamily:
      '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
  },
}

export default typography
