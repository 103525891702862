import React from 'react'
import { Grid, makeStyles, Container, Typography } from '@material-ui/core'
import MapViewLogical from '../MapView/MapViewLogical'
import PropTypes from 'prop-types'
import TableViewLogical from '../TableView/TableViewLogical'
import FilterPanelLogical from '../../../Common/Components/FilterPanel/FilterPanelLogical'
import FilterButton from '../../../Common/Components/FilterPanel/FilterButton'
import FilterPanelChips from '../../../Common/Components/FilterPanel/FilterPanelChips'
import {
  echoFilterByPanel,
  echoSalesPanel,
  echoMapLayersPanel,
  echoProducersPanel,
  echoProjectSearchPanel,
  rmProducerRangeOptions,
  cementTypeOptions,
  labResultOptions,
} from '../../../TSS/Constants/EchoConstants'
import theme from '../../../theme/muiTheme'
import { EMapLayers } from '../../Enums/EchoEnums'
import { FilterComponentTypes } from '../../../Common/Logic/Types'
import { getCementSupplierOptions } from '../../Logic/FilterLogicHelpers'

const useStyles = makeStyles({
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '64px',
    [theme.breakpoints.down(600)]: {
      top: '100px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    top: '64px',
  },
})

function EchoHomePresentational(props) {
  EchoHomePresentational.propTypes = {
    /** Unfiltered Echo corporation data */
    corpData: PropTypes.array.isRequired,
    /** The data that has been returned after being filtered */
    filteredCorpData: PropTypes.array.isRequired,
    /** Boolean to display or not display an error for the zip code search filter */
    zipCodeError: PropTypes.bool.isRequired,
    /** State setter to display or not display an error for the zip code search filter */
    setZipCodeError: PropTypes.func.isRequired,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** The table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter to display or not display loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Flat array of companies, used for the map markers */
    companyMarkers: PropTypes.array.isRequired,
    /** Data table page number */
    page: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** State setter to update ascending or descending table sort order */
    setOrder: PropTypes.func.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** State setter to update table column to sort by */
    setOrderBy: PropTypes.func.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update number of table rows to display */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Filtered array of cement plants */
    filteredCementPlants: PropTypes.array,
    /** Coordinates of zip code that was selected in "FilterBy" */
    zipCodeCoordinates: PropTypes.array,
    /** Filtered array of CO2 depots */
    filteredCO2Depots: PropTypes.array,
    /** Array of CO2 depots */
    co2Depots: PropTypes.array,
    /** Filtered array of CO2 vendors */
    filteredCO2Vendors: PropTypes.array,
    /** Filtered array of sales opportunities */
    filteredSalesOpportunities: PropTypes.array,
    /** Function to clear all filters */
    clearAllFilters: PropTypes.func.isRequired,
    /** Function to submit project search based on inputs */
    submitProjectSearch: PropTypes.func.isRequired,
    /** Atmo information from Echo */
    echoSettings: PropTypes.array,
    /** Array that contains the selected Map Layer Filter Panel Chips */
    selectedOptions: PropTypes.array.isRequired,
    /** Function to update Map Layers Filter Panel Chips */
    setSelectedOptions: PropTypes.func.isRequired,
    /** Array of data to display on the ready mix table. */
    readyMixTableData: PropTypes.array.isRequired,
    /** Array of data to display on the precast table. */
    precastTableData: PropTypes.array.isRequired,
    /** Function to get filter value */
    getFilterValue: PropTypes.func.isRequired,
    /** Function to handle cement filter change */
    cementFilterChangeHandler: PropTypes.func.isRequired,
  }
  const {
    corpData,
    filteredCorpData,
    zipCodeError,
    setZipCodeError,
    isLoading,
    selectedRows,
    setSelectedRows,
    setIsLoading,
    companyMarkers,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    filteredCementPlants,
    zipCodeCoordinates,
    filteredCO2Depots,
    co2Depots,
    filteredCO2Vendors,
    filteredSalesOpportunities,
    clearAllFilters,
    open,
    setOpen,
    filterCount,
    expandFilters,
    setExpandFilters,
    filterGroup,
    filterSelect,
    submitProjectSearch,
    activeFilters,
    filterChipsHandler,
    echoSettings,
    selectedOptions,
    setSelectedOptions,
    readyMixTableData,
    precastTableData,
    getFilterValue,
    cementFilterChangeHandler,
  } = props

  const classes = useStyles()

  const echoHelperVariables = {
    corpData: corpData,
    filteredCorpData: filteredCorpData,
    zipCodeError: zipCodeError,
    setZipCodeError: setZipCodeError,
    clearAllFilters: clearAllFilters,
    filterGroup: filterGroup,
    filterSelect: filterSelect,
    submitProjectSearch: submitProjectSearch,
    echoSettings: echoSettings,
    filterChipsHandler: filterChipsHandler,
    selectedOptions: selectedOptions,
    setSelectedOptions: setSelectedOptions,
  }

  const filterSection1 = { ...echoMapLayersPanel }
  const filterSection2 = { ...echoFilterByPanel }
  const filterSection3 = { ...echoSalesPanel }
  const filterSection4 = { ...echoProducersPanel }
  const filterSection5 = { ...echoProjectSearchPanel }

  const cementSupplierOptions = getCementSupplierOptions(filteredCementPlants)

  const echoCementPlantsPanel = {
    name: 'CEMENT PLANTS',
    category: 'cementPlants',
    filters: [
      {
        property: 'cementTypes',
        name: 'Cement Type',
        componentType: FilterComponentTypes.AutocompleteMulti,
        options: cementTypeOptions,
        onChangeHandler: cementFilterChangeHandler,
      },
      {
        property: 'labResults',
        name: 'Lab Result(s)',
        componentType: FilterComponentTypes.AutocompleteSingle,
        options: labResultOptions,
        onChangeHandler: cementFilterChangeHandler,
      },
      {
        property: 'cementSuppliers',
        name: 'Supplier',
        componentType: FilterComponentTypes.AutocompleteSingle,
        options: cementSupplierOptions,
        onChangeHandler: cementFilterChangeHandler,
      },
      {
        property: 'rmProducerRange',
        name: 'RM Producer Range',
        componentType: FilterComponentTypes.AutocompleteSingle,
        options: rmProducerRangeOptions,
        onChangeHandler: cementFilterChangeHandler,
      },
    ],
  }

  const filterPanel = [
    filterSection1,
    filterSection2,
    filterSection3,
    echoSettings.layers.includes(EMapLayers.cementPlants) &&
      echoCementPlantsPanel,
    filterSection4,
    filterSection5,
  ].filter(Boolean) // filter out empty sections

  const onClickHandler = () => {
    setOpen(prev => !prev)
  }

  return (
    <Container
      maxWidth="xl"
      style={{ padding: '0em 0.5em' }}
      className={classes.mainContainer}
    >
      <FilterPanelLogical
        open={open}
        setOpen={setOpen}
        expandFilters={expandFilters}
        setExpandFilters={setExpandFilters}
        filterPanel={filterPanel}
        parentClasses={classes}
        helperVariables={echoHelperVariables}
        getValue={getFilterValue}
      />
      <div className={open ? classes.wrapperShifted : classes.wrapperUnshifted}>
        <Grid
          container
          direction="column"
          spacing={2}
          justify="space-around"
          className={classes.echoContainer}
        >
          <Grid item xs>
            <Typography variant="h2">Echo</Typography>
          </Grid>
          <FilterButton
            open={open}
            parentClasses={classes}
            filterCount={filterCount}
            onClickHandler={onClickHandler}
          />
          <Grid container item xs={12} style={{ padding: 0 }}>
            <FilterPanelChips
              activeFilters={activeFilters}
              chipClickHandler={filterChipsHandler}
              buttonClickHandler={clearAllFilters}
            />
          </Grid>
          <Grid
            container
            item
            spacing={2}
            direction="column"
            xs={12}
            className={classes.echoContainer}
          >
            <Grid item xs style={{ paddingTop: '2em' }}>
              <MapViewLogical
                selectedRows={selectedRows}
                filteredCorpData={filteredCorpData}
                companyMarkers={companyMarkers}
                filteredUpcomingProjects={filteredUpcomingProjects}
                filteredCementPlants={filteredCementPlants}
                zipCodeCoordinates={zipCodeCoordinates}
                isLoading={isLoading}
                filteredCO2Depots={filteredCO2Depots}
                co2Depots={co2Depots}
                filteredSalesOpportunities={filteredSalesOpportunities}
                readyMixTableData={readyMixTableData}
              />
            </Grid>
            <Grid item xs>
              <TableViewLogical
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                filteredCorpData={filteredCorpData}
                setIsLoading={setIsLoading}
                page={page}
                setPage={setPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                filteredUpcomingProjects={filteredUpcomingProjects}
                isLoading={isLoading}
                filteredCO2Vendors={filteredCO2Vendors}
                readyMixTableData={readyMixTableData}
                precastTableData={precastTableData}
                zipCodeCoordinates={zipCodeCoordinates}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default EchoHomePresentational
