import React, { useEffect, useState } from 'react'
import AddMaterialPresentational from './AddMaterialPresentational'
import { atomCustomerOptions } from '../../../Common/tssAtomsB'
import { useRecoilState } from 'recoil'
import { getDivisionNames } from '../../../Common/Helpers/DataHelpers'
import {
  CustomerDivision,
  IMaterial,
  IMetaData,
  MaterialManagerComponent,
} from '../../Logic/Types'
import { isDefaultCO2, isDefaultCement } from '../../Logic/TSSLogic'

interface IAddMaterialLogical {
  /** The metadata returned from Kelowna */
  metaData: IMetaData | undefined
  /** Changes the view between Add/Edit/View Materials */
  setCurrentView: (view: MaterialManagerComponent) => void
  /** The selected Material and the relevant details needed for editing/viewing */
  selectedMaterial?: IMaterial | null | undefined
  /** Determines if fields can be edited or just viewed */
  isEditMode: boolean
  /** Manages Unsaved Changes Modal */
  setHasUserInput: (arg0: boolean) => void
  /** Set the material to be viewed in Add/Edit view */
  setSelectedMaterial: (arg0: IMaterial) => void
  /** Values from the material manager filters */
  customerFilterSelection?: CustomerDivision | null
  /** Determines if the selected material is unclassified */
  isUnclassifiedMaterial?: boolean
}

function AddMaterialLogical(props: IAddMaterialLogical) {
  const {
    metaData,
    setCurrentView,
    selectedMaterial,
    isEditMode,
    setHasUserInput,
    setSelectedMaterial,
    customerFilterSelection,
    isUnclassifiedMaterial,
  } = props

  /** The available customers that can be selected */
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)

  /** Initialize customer options dropdown */
  useEffect(() => {
    if (customerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(res => {
          if (res.ok)
            res
              .json()
              .then(data => {
                setCustomerOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  let isDefaultMaterial
  if (selectedMaterial) {
    isDefaultMaterial =
      isDefaultCement(selectedMaterial) || isDefaultCO2(selectedMaterial)
  }

  return (
    <AddMaterialPresentational
      isLoading={isLoading}
      customerOptions={customerOptions}
      metaData={metaData}
      setCurrentView={setCurrentView}
      selectedMaterial={selectedMaterial}
      isEditMode={isEditMode}
      setHasUserInput={setHasUserInput}
      isDefaultMaterial={isDefaultMaterial}
      isUnclassifiedMaterial={isUnclassifiedMaterial}
      setSelectedMaterial={setSelectedMaterial}
      customerFilterSelection={customerFilterSelection}
    />
  )
}

export default AddMaterialLogical
