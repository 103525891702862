import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SystemDataPresentational from './SystemDataPresentational'
import { useRecoilValue } from 'recoil'
import { atomCurrentRemote, atomDateRange } from '../../../Common/engAtoms'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import Injection from '../Logic/Injection'
import Status from '../Logic/Status'
import Reading from '../Logic/Reading'

function SystemDataLogical(props) {
  SystemDataLogical.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** State setter to open and close the service case modal */
    setOpen: PropTypes.func.isRequired,
  }

  const {
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setOpen,
    systemDataType,
  } = props

  /** Data that can be downloaded */
  const [downloadableData, setDownloadableData] = useState([])
  /** Data from the mixer manifold data endpoint */
  const [mixerManifoldData, setMixerManifoldData] = useState([])
  /** Data from the injection data endpoint */
  const [injectionData, setInjectionData] = useState([])
  /** Data from the inlet data endpoint */
  const [inletData, setInletData] = useState([])

  const [systemDataCount, setSystemDataCount] = useState(0)

  const remote = useRecoilValue(atomCurrentRemote)
  const dateRange = useRecoilValue(atomDateRange)

  /** Get data from Kelowna */
  useEffect(() => {
    const fetchAndSetInjectionData = async () => {
      const injectionResponse = await AlarmsManagementDataHelpers.getInjections(
        remote?.remoteId,
        {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          startTime: dateRange?.startTime,
          endTime: dateRange?.endTime,
        }
      )
      if (injectionResponse.ok) {
        const {
          results: returnedInjectionData,
          count,
        } = await injectionResponse.json()
        setInjectionData(
          returnedInjectionData.map(injection =>
            Injection.fromKelownaObject(injection)
          )
        )
        setSystemDataCount(count)
      }
    }

    const fetchAndSetManifoldData = async () => {
      const injReadingsResponse = await AlarmsManagementDataHelpers.getInjectionReadings(
        remote?.remoteId,
        {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          startTime: dateRange?.startTime,
          endTime: dateRange?.endTime,
        }
      )
      if (injReadingsResponse.ok) {
        const {
          results: returnedInjReadingsData,
          count,
        } = await injReadingsResponse.json()
        const injReadingsClassObjects = returnedInjReadingsData.map(reading =>
          Reading.fromKelownaObject(reading)
        )
        setMixerManifoldData(injReadingsClassObjects)
        setSystemDataCount(count)
      }
    }

    const fetchAndSetInletData = async () => {
      const statusResponse = await AlarmsManagementDataHelpers.getStatuses(
        remote?.remoteId,
        {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          startTime: dateRange?.startTime,
          endTime: dateRange?.endTime,
        }
      )
      if (statusResponse.ok) {
        const {
          results: returnedStatusData,
          count,
        } = await statusResponse.json()
        const statusClassObjects = returnedStatusData.map(status =>
          Status.fromKelownaObject(status)
        )
        setInletData(statusClassObjects)
        setSystemDataCount(count)
      }
    }

    if (remote.remoteId && dateRange.startTime && dateRange.endTime) {
      if (systemDataType === 'injection') {
        fetchAndSetInjectionData()
      } else if (systemDataType === 'mixerManifold') {
        fetchAndSetManifoldData()
      } else if (systemDataType === 'inlet') {
        fetchAndSetInletData()
      } else {
        setMixerManifoldData([])
        setInjectionData([])
        setInletData([])
      }
    } else {
      setMixerManifoldData([])
      setInjectionData([])
      setInletData([])
    }
  }, [remote, page, rowsPerPage, order, orderBy, systemDataType, dateRange])

  useEffect(() => {
    /** Update the downloadable data according to which type of data is being displayed */
    setDownloadableData(data => {
      switch (systemDataType) {
        case 'mixerManifold':
          return mixerManifoldData
        case 'injection':
          return injectionData
        case 'inlet':
          return inletData
        default:
          return 'mixerManifold'
      }
    })
  }, [injectionData, inletData, mixerManifoldData, systemDataType])

  /* Reset the page and rows per page to default */
  useEffect(() => {
    setPage(0)
    setRowsPerPage(10)
  }, [systemDataType, setPage, setRowsPerPage])

  /** Open the service case modal */
  const handleOpen = () => setOpen(true)

  return (
    <SystemDataPresentational
      handleOpen={handleOpen}
      systemDataType={systemDataType}
      downloadableData={downloadableData}
      handleTableSort={handleTableSort}
      order={order}
      orderBy={orderBy}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      count={systemDataCount}
      mixerManifoldData={mixerManifoldData}
      injectionData={injectionData}
      inletData={inletData}
    />
  )
}

export default SystemDataLogical
