import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import {
  getTestResultsPropertyPercentage,
  getTestResultsPropertyValue,
} from '../../Helpers/CommissionReportHelpers'
import {
  ICommissionReportVariation,
  ITestResultsPropertyOption,
} from '../../Logic/Types'

const useStyles = makeStyles(() => ({
  tableCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  verticalTableCell: {
    paddingTop: 16,
    paddingBottom: 16,
  },
}))

export interface IPdfCommissionReportTestResultsTable {
  testResultsTableRows: ITestResultsPropertyOption[]
  isDifferentCO2DosageUnit: boolean
  baselineVariation?: ICommissionReportVariation
  carbonCureVariation?: ICommissionReportVariation
  isMetric: boolean
  showDiff: boolean
  horizontalView: boolean
}

const PdfCommissionReportTestResultsTable = (
  props: IPdfCommissionReportTestResultsTable
) => {
  const {
    testResultsTableRows,
    isDifferentCO2DosageUnit,
    baselineVariation,
    carbonCureVariation,
    isMetric,
    showDiff,
    horizontalView,
  } = props
  const classes = useStyles()
  if (baselineVariation === undefined) return <></>
  if (carbonCureVariation === undefined) return <></>

  const getHorizontalTable = () => {
    return (
      <Table data-testid="horizontal table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Description</TableCell>
            {testResultsTableRows.map(
              (propertyObj: ITestResultsPropertyOption) => (
                <TableCell key={propertyObj.id} className={classes.tableCell}>
                  {isDifferentCO2DosageUnit && propertyObj.id === 'cO2Dosage'
                    ? propertyObj.label
                    : propertyObj.labelWithUnit}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>Baseline</TableCell>
            {testResultsTableRows.map(
              (propertyObj: ITestResultsPropertyOption) => (
                <TableCell
                  key={`Baseline-${propertyObj.id}`}
                  className={classes.tableCell}
                >
                  {getTestResultsPropertyValue(
                    baselineVariation,
                    propertyObj,
                    isMetric,
                    isDifferentCO2DosageUnit
                  )}
                </TableCell>
              )
            )}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>CarbonCure</TableCell>
            {testResultsTableRows.map(
              (propertyObj: ITestResultsPropertyOption) => (
                <TableCell
                  key={`CarbonCure-${propertyObj.id}`}
                  className={classes.tableCell}
                >
                  {getTestResultsPropertyValue(
                    carbonCureVariation,
                    propertyObj,
                    isMetric,
                    isDifferentCO2DosageUnit
                  )}
                </TableCell>
              )
            )}
          </TableRow>
          {showDiff && (
            <TableRow>
              <TableCell className={classes.tableCell}>Comparison</TableCell>
              {testResultsTableRows.map(
                (propertyObj: ITestResultsPropertyOption) => (
                  <TableCell
                    key={`Baseline-${propertyObj.id}`}
                    className={classes.tableCell}
                  >
                    {getTestResultsPropertyPercentage(
                      baselineVariation,
                      carbonCureVariation,
                      propertyObj,
                      isDifferentCO2DosageUnit
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  const getVerticalTable = () => {
    return (
      <Table data-testid="vertical table">
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.verticalTableCell}
              style={{ width: '150px' }}
            >
              Description
            </TableCell>
            <TableCell className={classes.verticalTableCell}>
              Baseline
            </TableCell>
            <TableCell className={classes.verticalTableCell}>
              CarbonCure
            </TableCell>
            {showDiff && (
              <TableCell className={classes.verticalTableCell}>
                Comparison
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {testResultsTableRows.map(
            (propertyObj: ITestResultsPropertyOption) => (
              <TableRow key={propertyObj.id}>
                <TableCell className={classes.verticalTableCell}>
                  {isDifferentCO2DosageUnit && propertyObj.id === 'cO2Dosage'
                    ? propertyObj.label
                    : propertyObj.labelWithUnit}
                </TableCell>
                <TableCell className={classes.verticalTableCell}>
                  {getTestResultsPropertyValue(
                    baselineVariation,
                    propertyObj,
                    isMetric,
                    isDifferentCO2DosageUnit
                  )}
                </TableCell>
                <TableCell className={classes.verticalTableCell}>
                  {getTestResultsPropertyValue(
                    carbonCureVariation,
                    propertyObj,
                    isMetric,
                    isDifferentCO2DosageUnit
                  )}
                </TableCell>
                {showDiff && (
                  <TableCell className={classes.verticalTableCell}>
                    {getTestResultsPropertyPercentage(
                      baselineVariation,
                      carbonCureVariation,
                      propertyObj,
                      isDifferentCO2DosageUnit
                    )}
                  </TableCell>
                )}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    )
  }

  return horizontalView ? getHorizontalTable() : getVerticalTable()
}

export default PdfCommissionReportTestResultsTable
