import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Box,
} from '@material-ui/core'
import EchoTableHead from '../EchoTableHead/EchoTableHead'
import {
  getComparator,
  stableSort,
} from '../../../Common/Helpers/GeneralHelpers'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { getConcreteTableColor } from '../../Logic/GeneralEchoLogicHelpers'
import { atomFilterBySelected } from '../../../Common/echoAtoms'
import { useRecoilValue } from 'recoil'
import SimpleTablePagination from '../../../Common/Components/SimpleTable/SimpleTablePagination'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '15px',
  },
  wideCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    overflow: 'hidden',
  },
}))

export const PrecastTablePresentational = props => {
  PrecastTablePresentational.propTypes = {
    /** The table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** Data table page number */
    page: PropTypes.number.isRequired,
    /** Ascending or descending sort order of table column */
    order: PropTypes.string.isRequired,
    /** Name of table column to sort by */
    orderBy: PropTypes.string.isRequired,
    /** Number of table rows to display */
    rowsPerPage: PropTypes.number.isRequired,
    /** Function to fire when a user clicks on of the table headers  */
    handleRequestSort: PropTypes.func.isRequired,
    /** Function to fire when a user clicks a row */
    handleClick: PropTypes.func.isRequired,
    /** Function to fire when a user changes page */
    handleChangePage: PropTypes.func.isRequired,
    /** Function to fire when a user changes the number of rows per page */
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    /** Array of data to display on the precast table. */
    formattedPrecastTableData: PropTypes.array.isRequired,
  }

  const {
    selectedRows,
    page,
    order,
    orderBy,
    rowsPerPage,
    handleRequestSort,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    formattedPrecastTableData,
  } = props
  const filterBySelected = useRecoilValue(atomFilterBySelected)

  const classes = useStyles()
  /** Keep track of which table rows are selected */
  const isSelected = name => selectedRows.indexOf(name) !== -1

  /** Table column headers */
  const headCells = [
    {
      id: 'corporationName',
      numeric: false,
      label: 'Group',
    },
    {
      id: 'divisionList',
      numeric: false,
      label: 'Company',
    },
    {
      id: 'plantCount',
      numeric: true,
      label: '# Plants',
    },
    {
      id: 'stateList',
      numeric: false,
      label: 'States',
    },
  ]

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="precast corporations"
            size={'small'}
          >
            <EchoTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              includeCheckbox={true}
            />
            <TableBody>
              {/* Map through the filtered data and display it as sortable rows */}
              {stableSort(
                formattedPrecastTableData,
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  /** Keep track of selected rows */
                  const isItemSelected = isSelected(row)
                  const labelId = `enhanced-table-checkbox-${row.corporationId}`
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.corporationId}
                      selected={isItemSelected}
                    >
                      {/* Row checkbox */}
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          id={row.corporationName}
                        />
                      </TableCell>
                      {/* Group name of company (corporation name) */}
                      <TableCell id={labelId} className={classes.wideCell}>
                        <FiberManualRecordIcon
                          style={{
                            color: getConcreteTableColor(row, filterBySelected),
                            fontSize: '20px',
                            verticalAlign: 'middle',
                            marginRight: '0.5em',
                          }}
                        />
                        {row.corporationName}
                      </TableCell>
                      {/* Company name */}
                      <TableCell className={classes.wideCell}>
                        {row.divisionList}
                      </TableCell>
                      {/* Number of plants the company has */}
                      <TableCell>{row.plantCount}</TableCell>
                      {/* States the corporation operates in */}
                      <TableCell className={classes.wideCell}>
                        {row.stateList}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={formattedPrecastTableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
