import React from 'react'
import { Grid, Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import { useRecoilValue } from 'recoil'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  filterRow: {
    gap: '8px',
  },
  plantsFiltersSwitch: {
    marginRight: '0em',
  },
  label: {
    padding: '8px',
  },
})

MapLayerSelectPresentational.propTypes = {
  /** Available options for the MapLayerAutoComplete */
  options: PropTypes.array.isRequired,
  /** Function called when option is selected or removed */
  handleChange: PropTypes.func,
  /** Function to determine if a chip should be disabled */
  handleProjectSearchChipDisable: PropTypes.func,
  /** Filter Panel styling */
  filterPanelClasses: PropTypes.string,
}

function MapLayerSelectPresentational(props) {
  const classes = useStyles()
  const {
    options,
    handleChange,
    handleProjectSearchChipDisable,
    filterPanelClasses,
  } = props

  const echoSettings = useRecoilValue(atomEchoSettings)

  return (
    <>
      <Grid container spacing={1} wrap="wrap">
        {options.map(option => (
          <Grid item key={option + 'key'}>
            <Chip
              label={option}
              name={option}
              className={
                echoSettings.layers.includes(option)
                  ? filterPanelClasses.activeChip
                  : filterPanelClasses.inactiveChip
              }
              disabled={handleProjectSearchChipDisable(option)}
              onClick={() => handleChange(option)}
              classes={{
                label: classes.label,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default MapLayerSelectPresentational
