import { quicksightDashboardList } from '../../Quicksight/Constants/UrlMapping'
import {
  convertCamelCaseToPascalCase,
  getFeatureFlags,
} from '../Helpers/GeneralHelpers'
import { EditablePlantsFeature } from './FeatureFlags'

const concreteProjects = {
  title: 'Concrete',
  path: '/Concrete',
  subPaths: [
    {
      title: 'Add Data',
      path: '/Concrete/AddData',
    },
    {
      title: 'Baleen',
      path: '/Concrete/Baleen',
    },
    {
      title: 'Material Manager',
      path: '/Concrete/MaterialManager',
    },
    {
      title: 'Analysis',
      path: '/Concrete/Designs',
    },
  ],
}

const hardwareProjects = {
  title: 'Hardware',
  path: '/Hardware',
  subPaths: [
    {
      title: 'Alarms Management',
      path: '/Hardware/AlarmsManagement',
    },
    {
      title: 'Sonar',
      path: '/Hardware/Sonar',
    },
  ],
}

const producerProjects = {
  title: 'Producers',
  path: '/Producers',
  subPaths: [],
}

if (getFeatureFlags().showCommissionReport) {
  producerProjects.subPaths.push({
    title: 'Report Library',
    path: '/Producers/ReportLibrary',
  })
}

if (EditablePlantsFeature) {
  producerProjects.subPaths.push({
    title: 'Plant Data',
    path: '/Producers/Plants',
  })
}

producerProjects.subPaths.push(
  {
    title: 'Echo',
    path: '/Producers/Echo',
  },
  {
    title: 'Customers',
    path: '/Producers/Customers',
  }
)

const cementProfilerProject = {
  title: 'Cement Profiler',
  path: '/CementProfiler',
  subPaths: [
    {
      title: 'Cement Database',
      path: '/CementProfiler',
    },
    {
      title: 'Cement Profiler',
      path: '/CementProfiler/CheckProfile',
    },
  ],
}

const quicksightProject = {
  title: 'QuickSight Dashboards',
  path: '/QuickSight',
  subPaths: [],
}

for (const projectName in quicksightDashboardList) {
  quicksightProject.subPaths.push({
    title: quicksightDashboardList[projectName].title,
    path: `/QuickSight/${convertCamelCaseToPascalCase(projectName)}`,
  })
}

export const ProducersDepartment = {
  department: 'Producers',
  projects: [producerProjects],
  featuresOfInterest: [],
}

export const ConcreteDepartment = {
  department: 'Concrete',
  projects: [concreteProjects],
  featuresOfInterest: [cementProfilerProject],
}

export const HardwareDepartment = {
  department: 'Hardware',
  projects: [hardwareProjects],
  featuresOfInterest: [],
}

export const QuicksightDepartment = {
  department: 'QuickSight',
  projects: [quicksightProject],
  featuresOfInterest: [],
}

export const AllProjects = [
  cementProfilerProject,
  concreteProjects,
  producerProjects,
  hardwareProjects,
  quicksightProject,
]

const AllDepartmentObjects = [
  ConcreteDepartment,
  HardwareDepartment,
  ProducersDepartment,
  QuicksightDepartment,
]

export default AllDepartmentObjects
