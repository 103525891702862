import React from 'react'
import PropTypes from 'prop-types'
import MixerManifoldTableLogical from './MixerManifoldTableLogical'
import InjectionTableLogical from './InjectionTableLogical'
import InletTableLogical from './InletTableLogical'

function RenderSystemDataTable(props) {
  RenderSystemDataTable.propTypes = {
    /** The service case displayed when the modal opens: existing or new */
    systemDataType: PropTypes.oneOf(['inlet', 'injection', 'mixerManifold']),
    /** Data from the mixer manifold data endpoint */
    mixerManifoldData: PropTypes.array.isRequired,
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Number of rows for associated table */
    count: PropTypes.number.isRequired,
    /** Data from the injection data endpoint */
    injectionData: PropTypes.array.isRequired,
    /** Data from the inlet data endpoint */
    inletData: PropTypes.array.isRequired,
  }
  const {
    systemDataType,
    mixerManifoldData,
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    count,
    setRowsPerPage,
    injectionData,
    inletData,
  } = props

  if (systemDataType === 'injection') {
    return (
      <InjectionTableLogical
        injectionData={injectionData}
        handleTableSort={handleTableSort}
        order={order}
        orderBy={orderBy}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    )
  } else if (systemDataType === 'inlet') {
    return (
      <InletTableLogical
        inletData={inletData}
        handleTableSort={handleTableSort}
        order={order}
        orderBy={orderBy}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    )
  } else {
    return (
      <MixerManifoldTableLogical
        mixerManifoldData={mixerManifoldData}
        handleTableSort={handleTableSort}
        order={order}
        orderBy={orderBy}
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    )
  }
}

export default RenderSystemDataTable
