import React, { useEffect, useState } from 'react'
import {
  getBarGraphOptions,
  getCommissionReportBarGraphOptions,
  preserveWatermarkAspectRatio,
} from '../../Logic/TSSLogic'
import {
  ICommissionReportVariation,
  IMixSelection,
  MixGroupVariation,
  Units,
} from '../../Logic/Types'
import CommissioningBarGraphPresentational from './CommissioningBarGraphPresentational'
import { BarGraphPage } from '../../../Common/Logic/Types'

interface CommissioningBarGraphLogicalProps {
  mixVariationsData: MixGroupVariation[] | IMixSelection[]
  isMetric: boolean
  showDesignStrength: boolean
  selectedInterval: number[]
  pageName: BarGraphPage
  isFilterPanelOpen?: boolean
  tabValue?: number
}

function CommissioningBarGraphLogical(
  props: CommissioningBarGraphLogicalProps
) {
  const [node, setRef] = useState(null)
  const units = props.isMetric ? Units.Metric : Units.Imperial
  const hasGraphData = props.mixVariationsData.length > 0
  const isCommission = props.pageName === BarGraphPage.COMMISSION

  const chartOptions =
    hasGraphData && isCommission
      ? getCommissionReportBarGraphOptions(
          props.mixVariationsData as IMixSelection[],
          props.mixVariationsData[props.tabValue]
            ?.digestedVariations as ICommissionReportVariation[],
          props.showDesignStrength,
          props.selectedInterval,
          units
        )
      : getBarGraphOptions(
          props.mixVariationsData as MixGroupVariation[],
          units
        )

  useEffect(() => {
    const watermarkedImage = document.querySelector(
      '.highcharts-container image'
    )
    preserveWatermarkAspectRatio(node, watermarkedImage)
  }, [node])

  // reflow chart to fit width when panel opens/closes
  useEffect(() => {
    if (node) {
      const graph = node?.chart
      let timeout = setTimeout(() => {
        if (graph) graph.reflow(false)
      }, 350)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [node, props.isFilterPanelOpen])

  return (
    <CommissioningBarGraphPresentational
      chartOptions={chartOptions}
      setRef={setRef}
    />
  )
}

export default CommissioningBarGraphLogical
