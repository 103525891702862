import { ScatterplotLayer } from '@deck.gl/layers'

export function getZipCodeLayer(zipCode) {
  return [
    new ScatterplotLayer({
      radiusUnits: 'meters',
      id: 'zipCode',
      data: zipCode,
      pickable: false,
      stroked: true,
      filled: true,
      lineWidthUnits: 'pixels',
      getRadius: 200000, // 200km radius (200,000 meters)
      getPosition: zipcode => zipcode.longLat,
      getFillColor: [66, 122, 152, 50],
      getLineColor: [66, 122, 152, 255],
      getLineWidth: 5,
    }),
  ]
}
