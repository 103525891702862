import {
  convertKgM3ToLbYd3,
  ConvertLbPerYd3ToKgPerM3,
  ConvertM3ToYd3,
  ConvertYd3ToM3,
  removeDateTimezone,
} from '../../../Common/Helpers/GeneralHelpers'

class PlantsTableRow {
  isMetric
  plantId
  divisionName
  name
  displayName
  state
  cO2DosePercent
  cO2DosePercentTimestamp
  averageCementCut
  averageCementCutTimestamp
  averageCementLoading
  averageCementLoadingTimestamp
  yardsPerTruck
  tankRentalFee
  residentialPercent
  commercialPercent
  dotPercent
  installDate
  tse
  tssNote
  plantTemporals

  constructor(updateObject) {
    this.original = {}
    this.edited = {}
    for (const key in updateObject) {
      this.original[key] = updateObject[key]
      this.edited[key] = updateObject[key]
    }
  }

  convertToImperial(version) {
    version.isMetric = false

    const avgLoading = PlantsTableRow.extractNumber(
      version.averageCementLoading
    )
    version.averageCementLoading =
      avgLoading === null ? '' : convertKgM3ToLbYd3(avgLoading)

    const yardsPerTruck = PlantsTableRow.extractNumber(version.yardsPerTruck)
    version.yardsPerTruck =
      yardsPerTruck === null ? '' : ConvertM3ToYd3(yardsPerTruck)
  }

  convertToMetric(version) {
    version.isMetric = true

    const avgLoading = PlantsTableRow.extractNumber(
      version.averageCementLoading
    )
    version.averageCementLoading =
      avgLoading === null ? '' : ConvertLbPerYd3ToKgPerM3(avgLoading)

    const yardsPerTruck = PlantsTableRow.extractNumber(version.yardsPerTruck)
    version.yardsPerTruck =
      yardsPerTruck === null ? '' : ConvertYd3ToM3(yardsPerTruck)
  }

  setEdited(newEdited) {
    this.edited = newEdited
  }

  static fromKelownaObject(kelownaPlant) {
    const properties = {}
    properties.isMetric = true
    properties.plantId = kelownaPlant.plantId
    properties.divisionName = kelownaPlant.divisionName
    properties.name = kelownaPlant.name
    properties.displayName = kelownaPlant.displayName
    properties.state = kelownaPlant.state ?? ''
    properties.cO2DosePercent = kelownaPlant.cO2DosePercent ?? ''
    properties.cO2DosePercentTimestamp = removeDateTimezone(
      kelownaPlant.cO2DosePercentTimestamp
    )
    properties.averageCementCut = kelownaPlant.averageCementCut ?? ''
    properties.averageCementCutTimestamp = removeDateTimezone(
      kelownaPlant.averageCementCutTimestamp
    )
    properties.averageCementLoading =
      kelownaPlant.averageCementLoadingKgPerM3 ?? ''
    properties.averageCementLoadingKgPerM3 =
      kelownaPlant.averageCementLoadingKgPerM3 ?? ''
    properties.averageCementLoadingTimestamp = removeDateTimezone(
      kelownaPlant.averageCementLoadingKgPerM3Timestamp
    )
    properties.yardsPerTruck = kelownaPlant.averageLoadSizeM3 ?? ''
    properties.tankRentalFee = kelownaPlant.tankRentalFee ?? ''
    properties.residentialPercent = kelownaPlant.residentialPercent ?? ''
    properties.commercialPercent = kelownaPlant.commercialPercent ?? ''
    properties.dotPercent = kelownaPlant.dotPercent ?? ''
    properties.installDate = removeDateTimezone(kelownaPlant.installDate)
    properties.tse = kelownaPlant.tse
    properties.tssNote = kelownaPlant.tssNote ?? ''
    properties.plantTemporals = kelownaPlant.plantTemporals

    const updateTimestampIfEmpty = (property, temporalProperty) => {
      if (properties[property] === '') {
        const matchingTemporal = PlantsTableRow.findEffectiveDateByProperty(
          kelownaPlant.plantTemporals,
          properties[temporalProperty],
          temporalProperty
        )
        properties[property] = removeDateTimezone(
          matchingTemporal?.timestamp ?? null
        )
      }
    }

    updateTimestampIfEmpty('cO2DosePercentTimestamp', 'cO2DosePercent')
    updateTimestampIfEmpty('averageCementCutTimestamp', 'averageCementCut')
    updateTimestampIfEmpty(
      'averageCementLoadingTimestamp',
      'averageCementLoadingKgPerM3'
    )

    return new PlantsTableRow(properties)
  }

  toKelownaObject(version) {
    if (!version.isMetric) {
      this.convertToMetric(version)
    }
    return {
      plantId: version.plantId,
      divisionName: version.divisionName,
      name: version.name,
      displayName: version.displayName,
      state: version.state || null,
      cO2DosePercent: PlantsTableRow.extractNumber(version.cO2DosePercent),
      cO2DosePercentTimestamp: version.cO2DosePercentTimestamp || null,
      averageCementCut: PlantsTableRow.extractNumber(version.averageCementCut),
      averageCementCutTimestamp: version.averageCementCutTimestamp || null,
      averageCementLoadingKgPerM3: PlantsTableRow.extractNumber(
        version.averageCementLoading
      ),
      averageCementLoadingKgPerM3Timestamp:
        version.averageCementLoadingTimestamp || null,
      averageLoadSizeM3: PlantsTableRow.extractNumber(version.yardsPerTruck),
      tankRentalFee: PlantsTableRow.extractNumber(version.tankRentalFee),
      residentialPercent: PlantsTableRow.extractNumber(
        version.residentialPercent
      ),
      commercialPercent: PlantsTableRow.extractNumber(
        version.commercialPercent
      ),
      dotPercent: PlantsTableRow.extractNumber(version.dotPercent),
      installDate: version.installDate || null,
      tse: version.tse || null,
      tssNote: version.tssNote || null,
      plantEditNote: version.plantEditNote,
    }
  }

  static extractNumber(value) {
    if (value === '' || value == null) {
      return null
    } else return Number(value)
  }

  /**
   * Finds the effectivity date of a property in the data array.
   * @param {Array} temporalData - The temporal data array.
   * @param {String} targetValue - The value to search for.
   * @param {String} targetProperty - The property to search for.
   * @returns {Object} - Object containing the effectivity date for the property.
   */
  static findEffectiveDateByProperty(
    temporalData,
    targetValue,
    targetProperty
  ) {
    let lastMatchedItem = null

    const isMatch = item => {
      return item[targetProperty] === targetValue
    }

    for (let i = 0; i < temporalData.length; i++) {
      const item = temporalData[i]

      if (isMatch(item)) {
        const nextItem = temporalData[i + 1]
        if (!nextItem || !isMatch(nextItem)) {
          return item
        }
      }

      lastMatchedItem = isMatch(item) ? item : lastMatchedItem
    }

    return lastMatchedItem
  }
}

export default PlantsTableRow
