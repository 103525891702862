import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import MixDesignTablePresentational from './MixDesignTablePresentational'

export default function MixDesignTableLogical(props) {
  MixDesignTableLogical.propTypes = {
    /** Mix design data processed from digestMixDesigns in GeneralHelpers */
    rows: PropTypes.array.isRequired,
    /** The selected rows of mix design table */
    selectedMixDesigns: PropTypes.array.isRequired,
    /** The selected variation rows of mix design table */
    selectedMixVariations: PropTypes.array.isRequired,
    /** Producer list acquired from digesting the mixVariations */
    producerList: PropTypes.array.isRequired,
    /** Supplier list acquired from digesting the mixdesigns */
    supplierList: PropTypes.array.isRequired,
    /** Number of rows to be shown for a page */
    rowsPerPage: PropTypes.number.isRequired,
    /** function to set row size per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Number of current page to calculate offset */
    page: PropTypes.number.isRequired,
    /** function to set the current page */
    setPage: PropTypes.func.isRequired,
    /** column selected for sorting */
    orderBy: PropTypes.string.isRequired,
    /** set column for sorting */
    setOrderBy: PropTypes.func.isRequired,
    /** ascending or descending order style */
    order: PropTypes.string.isRequired,
    /** function for setting the order style for the column */
    setOrder: PropTypes.func.isRequired,
    /** List of selected producer for filter */
    selectedProducer: PropTypes.array.isRequired,
    /** function to set the list of selected producer */
    setSelectedProducer: PropTypes.func.isRequired,
    /** List of selected supplier for filter  */
    selectedSupplier: PropTypes.array.isRequired,
    /** function to set the list of selected supplier */
    setSelectedSupplier: PropTypes.func.isRequired,
    /** The number of rows returned by the endpoint */
    rowCount: PropTypes.number.isRequired,
    /** function to select/unselect table rows */
    handleClick: PropTypes.func.isRequired,
    /** Function to sort table rows */
    handleRequestSort: PropTypes.func.isRequired,
    /** The type of table */
    tableType: PropTypes.string.isRequired,
    /** Classes defined in the parent */
    parentClasses: PropTypes.object.isRequired,
    /** Mix group table data */
    tableData: PropTypes.array.isRequired,
    /** Function to set altered mix group table data globally */
    setTableData: PropTypes.func.isRequired,
    /** Settings for mix group table */
    tableSettings: PropTypes.object.isRequired,
    /** Function to set altered mix group table settings */
    setTableSettings: PropTypes.func.isRequired,
    /** Filter settings for mix table results */
    analysisFilterSettings: PropTypes.object.isRequired,
    /** Function to set filter settings for mix table results */
    setAnalysisFilterSettings: PropTypes.func.isRequired,
    /** Boolean that indicates whether the filter panel is open or not */
    isFilterPanelOpen: PropTypes.bool.isRequired,
    /** Function that opens and closes the filter panel */
    onFilterBtnClickHandler: PropTypes.func.isRequired,
    /** Boolean indicating whether or not the measurement system is metric */
    isMetric: PropTypes.bool.isRequired,
  }
  const {
    rows,
    selectedMixDesigns,
    selectedMixVariations,
    producerList,
    supplierList,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    selectedProducer,
    setSelectedProducer,
    selectedSupplier,
    setSelectedSupplier,
    rowCount,
    handleClick,
    handleRequestSort,
    onCollapseToggle,
    expanded,
    tableType,
    parentClasses,
    tableData,
    setTableData,
    tableSettings,
    setTableSettings,
    analysisFilterSettings,
    setAnalysisFilterSettings,
    isFilterPanelOpen,
    onFilterBtnClickHandler,
    filterCount,
    isMetric,
  } = props
  const [commissioningFilter, setCommissioningFilter] = useState(null)
  const [activeFilters, setActiveFilters] = useState([])
  useEffect(() => {
    setSelectedSupplier(analysisFilterSettings.supplier)
    setCommissioningFilter(analysisFilterSettings.commissioning[0])
  }, [analysisFilterSettings, supplierList, setSelectedSupplier])

  const handleChipAddition = useCallback(
    (filter, chipArray) => {
      switch (filter) {
        case 'producer':
          const selectedProducerId = analysisFilterSettings['producer'][0]
          const producer = producerList.find(
            option => option.divisionId === selectedProducerId
          )
          if (producer)
            chipArray.push({ property: 'producer', label: producer.name })
          break
        case 'supplier':
          for (const supplierId of analysisFilterSettings['supplier']) {
            const supplier = supplierList.find(
              option => option.cementPlantId === supplierId
            )
            if (supplier)
              chipArray.push({
                property: 'supplier',
                label: supplier.name,
                id: supplierId,
              })
          }
          break
        case 'mixCode':
          if (analysisFilterSettings.mixCode.length)
            chipArray.push({
              property: 'mixCode',
              label: `Mix Code: ${analysisFilterSettings['mixCode'][0]}`,
            })
          break
        case 'commissioning':
          for (const value of analysisFilterSettings['commissioning']) {
            chipArray.push({ property: 'commissioning', label: value })
          }
          break
        case 'showArchived':
          if (analysisFilterSettings.showArchived[0] === 'true')
            chipArray.push({ property: 'showArchived', label: 'Show Archived' })
          break
        default:
          break
      }
    },
    [analysisFilterSettings, producerList, supplierList]
  )

  useEffect(() => {
    const chipValues = []
    for (const filter in analysisFilterSettings) {
      handleChipAddition(filter, chipValues)
    }
    setActiveFilters(chipValues)
  }, [analysisFilterSettings, producerList, supplierList, handleChipAddition])

  const deleteChipHandler = filterToDelete => {
    if (filterToDelete.property === 'showArchived') {
      setAnalysisFilterSettings(prevSettings => {
        return {
          ...prevSettings,
          showArchived: ['false'],
        }
      })
    } else if (filterToDelete.property === 'supplier') {
      setAnalysisFilterSettings(prevSettings => {
        const newSettings = analysisFilterSettings[
          filterToDelete.property
        ].filter(option => option !== filterToDelete.id)
        return {
          ...prevSettings,
          [filterToDelete.property]: newSettings,
        }
      })
    } else {
      setAnalysisFilterSettings(prevSettings => {
        return {
          ...prevSettings,
          [filterToDelete.property]: [],
        }
      })
    }
  }

  const deleteAllChipsHandler = () => {
    setAnalysisFilterSettings(prevSettings => {
      return {
        producer: [],
        supplier: [],
        commissioning: [],
        mixCode: [],
        showArchived: ['false'],
      }
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  /* Function to set the producer and supplier based on current chips selected */
  const setParameters = (producerArray, supplierArray) => {
    setSelectedProducer(producerArray)
    setSelectedSupplier(supplierArray)
  }

  return (
    <MixDesignTablePresentational
      setParameters={setParameters}
      handleRequestSort={handleRequestSort}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rows={rows}
      producerList={producerList}
      supplierList={supplierList}
      selectedProducer={selectedProducer}
      selectedSupplier={selectedSupplier}
      rowCount={rowCount}
      page={page}
      rowsPerPage={rowsPerPage}
      selectedMixDesigns={selectedMixDesigns}
      selectedMixVariations={selectedMixVariations}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      expanded={expanded}
      onCollapseToggle={onCollapseToggle}
      tableType={tableType}
      parentClasses={parentClasses}
      tableData={tableData}
      setTableData={setTableData}
      tableSettings={tableSettings}
      setTableSettings={setTableSettings}
      analysisFilterSettings={analysisFilterSettings}
      setAnalysisFilterSettings={setAnalysisFilterSettings}
      commissioningFilter={commissioningFilter}
      setCommissioningFilter={setCommissioningFilter}
      isFilterPanelOpen={isFilterPanelOpen}
      onFilterBtnClickHandler={onFilterBtnClickHandler}
      filterCount={filterCount}
      activeFilters={activeFilters}
      deleteChipHandler={deleteChipHandler}
      deleteAllChipsHandler={deleteAllChipsHandler}
      isMetric={isMetric}
    />
  )
}
