import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'

interface HistoricalTableRowProps {
  rowData: Array<string>
}

function HistoricalTableRow(props: HistoricalTableRowProps) {
  const { rowData } = props

  return (
    <TableRow hover>
      {rowData.map((cellData: string | null | undefined, idx) => (
        <TableCell key={idx}>{cellData}</TableCell>
      ))}
    </TableRow>
  )
}

export default HistoricalTableRow
