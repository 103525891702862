import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import DownloadDataPresentational from './DownloadDataPresentational'
import { useRecoilValue } from 'recoil'
import {
  atomCodeMapper,
  atomCurrentRemote,
  atomDateRange,
  atomRegion,
} from '../../../Common/engAtoms'
import AlarmsManagementDataHelpers from '../Data/AlarmsManagementDataHelpers'
import AlarmForTable from '../Logic/AlarmForTable'
import { getDate } from '../../../Common/Helpers/GeneralHelpers'

DownloadDataLogical.propTypes = {
  /** data to be downloaded */
  data: PropTypes.array.isRequired,
  /** Data type: alarms or service cases */
  dataType: PropTypes.string.isRequired,
}

function DownloadDataLogical(props) {
  const { dataType, orderBy, order, showInactiveAlarms } = props
  const [downloadedData, setDownloadedData] = React.useState(null)
  const alarmCodeMapper = useRecoilValue(atomCodeMapper)
  const dateRange = useRecoilValue(atomDateRange)
  const currentRemote = useRecoilValue(atomCurrentRemote)
  const regionDisplay = useRecoilValue(atomRegion)
  const csvInstance = useRef()

  const getDownloadedData = async () => {
    const response = await AlarmsManagementDataHelpers.getAlarmsTable({
      Offset: 0,
      SortColumn: orderBy || '',
      SortOrder: order === 'asc' ? 'Ascending' : 'Descending',
      remoteUnitId: currentRemote?.remoteId || '',
      endTime: dateRange.endTime || '',
      startTime: dateRange.startTime || '',
      displayCanadaUs: regionDisplay.canadaUS,
      displayInternational: regionDisplay.international,
      onlyUnresolved: !showInactiveAlarms,
    })
    if (response.ok) {
      const { results } = await response.json()
      // setCount(count)
      const alarmClassObjects = results.map(alarm =>
        AlarmForTable.fromKelownaObject({ ...alarm, alarmCodeMapper })
      )
      /** Format the table data for the CSV file to be downloaded */
      let formattedData = []
      if (dataType === 'service cases') {
        /** Service case data */
        formattedData = alarmClassObjects.map(row => {
          return {
            caseID: row.overview.case,
            location: row.overview.location,
            system: row.overview.system,
            caseOpened: row.overview.caseOpened,
            caseClosed: row.overview.caseClosed,
            daysToResolve: row.overview.daysToResolve,
            hmiID: row.overview.hmiID,
            systemDowntime: row.overview.systemDowntime,
            solvedRemotely: row.overview.solvedRemotely,
            uniqueIssue: row.overview.uniqueIssue,
            replacementPartsNeededDate: row.overview.replacementPartsNeededDate,
            partsShippedDate: row.overview.partsShippedDate,
            partsReceivedDate: row.overview.partsReceivedDate,
            remoteTechnician: row.remoteTroubleshooting.removeTechnician,
            initialIndication: row.remoteTroubleshooting.initialIndication,
            responsibleForIssue: row.remoteTroubleshooting.responsibleForIssue,
            errorMessage: row.remoteTroubleshooting.errorMessage,
            faultySystemComponent:
              row.remoteTroubleshooting.faultySystemComponent,
            plantPOC: row.remoteTroubleshooting.plantPOC,
            problemDescriptionByCustomer:
              row.remoteTroubleshooting.problemDescriptionByCustomer,
            actionsTakenRemotely:
              row.remoteTroubleshooting.actionsTakenRemotely,
            graphOrData: row.remoteTroubleshooting.graphOrData,
            remoteUpdate: row.remoteTroubleshooting.remoteUpdate,
            followUpRequired: row.remoteTroubleshooting.followUpRequired,
            followUpSummary: row.remoteTroubleshooting.followUpSummary,
            status: row.remoteTroubleshooting.status,
            marketDevRepEmail: row.remoteTroubleshooting.marketDevRepEmail,
            salesOwnerEmail: row.remoteTroubleshooting.salesOwnerEmail,
            tssOwnerEmail: row.remoteTroubleshooting.tssOwnerEmail,
            onsiteServiceRequired: row.onsiteService.onsiteServiceRequired,
            criticalRepairPlan: row.onsiteService.criticalRepairPlan,
            repairPlanApproved: row.onsiteService.repairPlanApproved,
            actionsTakenOnsite: row.onsiteService.actionsTakenOnsite,
            replacedComponents: row.onsiteService.replacedComponents,
            onsiteTechnician: row.onsiteService.onsiteTechnician,
            anticipatedRepairDate: row.onsiteService.anticipatedRepairDate,
            arrivedOnsite: row.onsiteService.arrivedOnsite,
            solvedOnsite: row.onsiteService.solvedOnsite,
            notes: row.notes,
          }
        })
      } else formattedData = alarmClassObjects
      setDownloadedData(formattedData)
    }
  }

  useEffect(() => {
    if (downloadedData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setDownloadedData(null)
      })
    }
  }, [downloadedData])

  /** Time stamp of the current date in YYYY-MM-DD format */
  const currentTimestamp = getDate(Date.now())
  return (
    <DownloadDataPresentational
      formattedData={downloadedData}
      dataType={dataType}
      currentTimestamp={currentTimestamp}
      getDownloadedData={getDownloadedData}
      downloadedData={downloadedData}
      csvInstance={csvInstance}
    />
  )
}

export default DownloadDataLogical
