import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFusionAuth } from '@carboncure/fusion-auth-component'
import { Redirect } from 'react-router-dom'

AuthenticateAndRedirect.propTypes = {
  /** The path to redirect to after being authenticated (for example "/" for root) */
  redirectTo: PropTypes.string.isRequired,
}

function AuthenticateAndRedirect({ redirectTo }) {
  const [initialAuth] = useFusionAuth()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    const code = query.get('code')

    initialAuth(code).then(
      () => {
        setSuccess(true)
      },
      () => {
        setSuccess(false)
      }
    )
  }, [initialAuth])

  if (success) {
    return <Redirect to={redirectTo} />
  } else {
    return 'Loading...'
  }
}

export default AuthenticateAndRedirect
