import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import {
  Backdrop,
  CircularProgress,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Helmet } from 'react-helmet'
import {
  CommissionReportStatus,
  ICommissionReportSettings,
  IInsufficientMaturityAgeSamples,
  IInsufficientVariationSamples,
  IMixGroupOptionWithConditions,
  IMixSelection,
  IReviewedReportModalMixInfo,
} from '../Logic/Types'
import {
  getCommissionReportSettingsByReportId,
  getReportMixGroupsByDivision,
} from '../Data/TSSDataHelpers'
import {
  digestReportSettings,
  getInsufficientSampleBannerData,
  getPDFDate,
} from '../Helpers/CommissionReportHelpers'
import CreateCommissionReport from '../Components/CommissionReportGenerator/CreateCommissionReport'
import ViewCommissionReport from './ViewCommissionReport'
import { useRecoilState, useRecoilValue } from 'recoil'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import {
  useCompanyLogo,
  useGenerateCommissionReportPDF,
  useMixSelectionData,
} from '../Logic/Hooks'
import {
  atomCustomerOptions,
  atomSelectedMixGroup,
} from '../../Common/tssAtomsB'
import {
  getCustomerPlants,
  getDivisionNames,
} from '../../Common/Helpers/DataHelpers'
import cloneDeep from 'lodash.clonedeep'
import { baseColors } from '../../theme/colors'
import './styles.css'
import CarbonCureLogo from '../../Common/assets/CarbonCure-Logo-Primary-Inline-White.png'

export interface IMixSelectionRow {
  id: number
  mixDesignId: number | null
}

export interface ICommissionReportCustomer {
  divisionName: string
  corporationName: string
  plantName: string
  isMetric: boolean
}

const defaultEmptySettings: ICommissionReportSettings = {
  producerId: 0,
  plantId: 0,
  isMetric: false,
  reportStatus: CommissionReportStatus.Draft,
  testingStage: 'NeedsMoreTesting',
  lastUpdated: '',
  createdBy: '',
  createdByUserEmail: '',
  createDate: '',
  lastUpdatedBy: '',
  plantType: 'ReadyMix',
  testingSummary: '',
  conclusionParagraph: '',
  mixDesignSettings: [],
}

const useStyles = makeStyles(() => ({
  pdfViewDiv: {
    marginLeft: '2px',
  },
}))

const initializeDefaultSettings = (
  isValidReportId: boolean,
  mixGroup: number[],
  producerId?: number,
  plantId?: number
): ICommissionReportSettings => {
  if (isValidReportId) {
    return defaultEmptySettings
  } else {
    return {
      ...defaultEmptySettings,
      producerId: producerId ? producerId : 0,
      plantId: plantId ? plantId : 0,
      mixDesignSettings: [
        {
          mixDesignId: mixGroup[0],
          mixDesignPropertiesIncludedOnCustomerReport: {
            designStrength: true,
            waterCementRatio: true,
            airContentRange: true,
            slumpRange: true,
            scmPercent: true,
            cementTypes: true,
            cementContent: true,
          },
          testResults: {
            selectedProperties: [],
            selectedStrengthHours: [],
            selectedStrengthStDevHours: [],
            showDifference: true,
          },
          frequencyGraph: {
            isSelected: true,
            selectedInterval: 0,
            showFailureZone: true,
            showHistogram: true,
            showBellCurve: true,
          },
          avgStrengthGraph: {
            isSelected: true,
            selectedInterval: [],
            showDesignStrength: true,
          },
          outlierSettings: {
            show: true,
            showReason: true,
          },
        },
      ],
    }
  }
}

function CommissionReportGeneratorView() {
  const classes = useStyles()
  const producer = useRecoilValue(atomCurrentCustomer)
  const selectedProducerId = producer?.division?.divisionId
  const { reportId } = useParams()
  const firstMixGroup = useRecoilValue(atomSelectedMixGroup)?.mixGroup
  const JWT = useRecoilValue(atomJWT)
  const isValidReportId = !isNaN(Number(reportId)) //reportId must be a number
  const isValidLink = isValidReportId || selectedProducerId
  const [redirect, setRedirect] = useState(!isValidLink)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMixData, setIsLoadingMixData] = useState(false)
  const [reportSettings, setReportSettings] = useState<
    ICommissionReportSettings
  >(
    initializeDefaultSettings(
      isValidReportId,
      firstMixGroup,
      producer?.division?.divisionId,
      producer?.plant?.plantId
    )
  )
  const [mixSelections, setMixSelections] = useState<Array<IMixSelection>>([])
  const [callMixEndpointFlag, setCallMixEndpointFlag] = useState<boolean>(false)
  const [mixGroupOptions, setMixGroupOptions] = useState<
    IMixGroupOptionWithConditions[]
  >([])
  const [mixSelectionRows, setMixSelectionRows] = useState<IMixSelectionRow[]>(
    []
  )
  const [isCommissionReportViewMode, setIsCommissionReportViewMode] = useState(
    isValidReportId
  )
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>('')
  const [customerOptions, setCustomerOptions] = useRecoilState(
    atomCustomerOptions
  )
  const [plantOptions, setPlantOptions] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState<
    ICommissionReportCustomer
  >({
    divisionName: '',
    corporationName: '',
    plantName: '',
    isMetric: false,
  })
  const [
    variationsWithInsufficientSamplesList,
    setVariationsWithInsufficientSamplesList,
  ] = useState<IInsufficientVariationSamples[]>([])
  const [
    variationsWithInsufficientIntervalSamples,
    setVariationsWithInsufficientIntervalSamples,
  ] = useState<IInsufficientMaturityAgeSamples>([])
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0)

  const [isPrinting, setIsPrinting] = useState(false)
  const [isReviewErrorModalOpen, setIsReviewErrorModalOpen] = useState<boolean>(
    false
  )
  const pdfExportComponent = useRef(null)
  const history = useHistory()
  const reviewedReportModalMixInfo = useMemo<IReviewedReportModalMixInfo[]>(
    () =>
      mixSelections.map(mix => ({
        mixCode: mix.mixCode,
        mixDesignId: mix.mixDesignId,
      })),
    [mixSelections]
  )

  const currentUser = `${JWT.firstName} ${JWT.lastName}`
  const currentUserEmail = JWT.email
  const author = isValidReportId ? reportSettings.createdBy : currentUser
  const authorEmail = isValidReportId
    ? reportSettings.createdByUserEmail
    : currentUserEmail

  const pdfTemplate = useCallback(() => {
    return ReactDOMServer.renderToString(
      <div>
        {/* header */}
        <div
          style={{
            position: 'absolute',
            top: '0.5cm',
            left: '-0.4cm',
            width: '97%',
            paddingLeft: '2cm',
            height: '3.5cm',
          }}
        >
          <p>{getPDFDate()}</p>
          <h3>{`CarbonCure Commissioning Report - ${selectedCustomer.divisionName}`}</h3>
          <img
            src={companyLogoUrl}
            alt={`${selectedCustomer.divisionName} logo`}
            style={{
              position: 'absolute',
              top: '1cm',
              right: '2.5cm',
              height: '40px',
            }}
          />
        </div>
        {/* footer */}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: '-0.2cm',
            backgroundColor: '#E37F1C',
            width: '97%',
            height: '5.7cm',
            paddingLeft: '2cm',
          }}
        >
          <div>
            <p
              className={'footer-creator-info'}
              style={{ color: baseColors.text.contrast }}
            >
              Report Created by {author}, TSS. {authorEmail}
              <br />
              Report Reviewed by {currentUser}, TSS. {currentUserEmail}
              <br />
            </p>
            <p
              className="footer-paragraph"
              style={{ color: baseColors.text.contrast, width: '95%' }}
            >
              CARBONCURE{'\u2122'} is a trademark of CarbonCure Technologies
              Inc. {'\u00A9'} Copyright 2024. All rights reserved. Capitalized
              terms in this report have the meanings ascribed to them in
              CarbonCure's General Terms (as available from CarbonCure or at
              (pw:CO2)). This report and the content herein is provided as
              Decision Support pursuant and subject to the terms and conditions
              of your agreement with CarbonCure and the General Terms, including
              the terms and conditions pertaining to permitted use of this
              report and limitations of liability. You are solely responsible
              for all decisions pertaining to your concrete production
              (including but not limited to Treated mix designs, cement content
              and production processes). Decision Support is provided in
              furtherance of supporting potential opportunities for action for
              your consideration.
            </p>
          </div>
          <img
            src={CarbonCureLogo}
            alt="carboncure logo"
            style={{
              position: 'absolute',
              top: '0.5cm',
              right: '2cm',
              maxHeight: '50px',
            }}
          />
        </div>
      </div>
    )
  }, [
    author,
    companyLogoUrl,
    currentUser,
    currentUserEmail,
    selectedCustomer.divisionName,
    authorEmail,
  ])

  /** Get Division Names */
  useEffect(() => {
    if (customerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(result => {
          if (result.ok)
            result
              .json()
              .then(data => {
                setCustomerOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }
  }, [customerOptions, setCustomerOptions])

  /** Get Plant Names */
  useEffect(() => {
    if (reportSettings.producerId && plantOptions.length === 0) {
      setIsLoading(true)
      getCustomerPlants(reportSettings.producerId)
        .then(result => {
          if (result.ok)
            result
              .json()
              .then(data => {
                setPlantOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }
  }, [plantOptions, reportSettings.producerId, reportSettings.plantId])

  /** Get Mix Group options for autocomplete */
  useEffect(() => {
    if (!reportSettings?.producerId || !reportSettings?.plantId) return
    setIsLoading(true)
    const getMixGroupOptions = async () => {
      try {
        const responseJson = await getReportMixGroupsByDivision(
          reportSettings?.producerId,
          {
            plantId: reportSettings?.plantId,
            onlyIncludeReportMixGroups: false,
          }
        )
        const response = await responseJson.json()
        const convertedOptions = response.map((option: any) => {
          return {
            id: option.mixDesignId,
            name: option.mixCode,
            mixCode: option.mixCode,
            baseLineCementReduction: option.baselineCementReductionPercent,
            baseLineCO2: option.baselineCO2Dosage,
          }
        })
        setMixGroupOptions(convertedOptions)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    }
    getMixGroupOptions()
  }, [reportSettings?.producerId, reportSettings?.plantId])

  useEffect(() => {
    if (!isValidReportId || mixSelections.length !== 0) return
    setIsLoading(true)
    getCommissionReportSettingsByReportId(reportId)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            const reportSettings = digestReportSettings(data)
            setReportSettings(reportSettings)
            setIsLoading(false)
          })
        } else {
          setRedirect(true)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [reportId, isValidReportId, mixSelections])

  useEffect(() => {
    if (
      reportSettings.producerId === 0 ||
      customerOptions.length === 0 ||
      plantOptions.length === 0
    )
      return
    const matchedCustomer = customerOptions.find(
      option => option.divisionId === reportSettings.producerId
    )
    const matchedPlant = plantOptions.find(
      option => option.plantId === reportSettings.plantId
    )
    if (!matchedCustomer || !matchedPlant) return
    const customer = {
      divisionName: matchedCustomer.name,
      corporationName: matchedCustomer.corporationName,
      plantName: matchedPlant.name,
      isMetric: !matchedCustomer.isImperial,
    }
    setSelectedCustomer(customer)
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      newSettings.isMetric = customer.isMetric
      return newSettings
    })
    setCallMixEndpointFlag(true)
  }, [
    reportSettings.producerId,
    reportSettings.plantId,
    customerOptions,
    plantOptions,
  ])

  /** Custom hook to fetch and digest mix selections */
  useMixSelectionData({
    reportSettings,
    setMixSelections,
    setIsLoading: setIsLoadingMixData,
    sendRequest: callMixEndpointFlag,
    setSendRequest: setCallMixEndpointFlag,
    setReportSettings,
    setMixSelectionRows,
    mixSelections,
    redirect,
  })

  useEffect(() => {
    if (
      reportSettings.mixDesignSettings.length === 0 ||
      mixSelections.length === 0 ||
      reportSettings.mixDesignSettings.length !== mixSelections.length
    )
      return

    const { errorData, maturityAgeErrorData } = getInsufficientSampleBannerData(
      mixSelections,
      reportSettings
    )
    setVariationsWithInsufficientSamplesList(errorData)
    setVariationsWithInsufficientIntervalSamples(maturityAgeErrorData)
  }, [mixSelections, reportSettings])

  /** Custom hook to fetch company logo */
  useCompanyLogo(reportSettings, setCompanyLogoUrl, setIsLoading)

  useGenerateCommissionReportPDF({
    history,
    isPrinting,
    setIsPrinting,
    pdfExportComponent,
    setReportSettings,
    pdfTemplate,
    setIsCommissionReportViewMode,
    reportId,
    isCommissionReportViewMode,
    divisionName: selectedCustomer.divisionName,
    reviewedReportModalMixInfo: reviewedReportModalMixInfo,
    setIsReviewErrorModalOpen,
  })

  if (redirect) {
    return <Redirect to="/Producers/ReportLibrary" />
  }
  return (
    <Container maxWidth="xl" style={{ padding: '0em 0.5em' }}>
      <Helmet>
        <title>{`Report Library - ${selectedCustomer.divisionName}`}</title>
      </Helmet>
      <Backdrop
        open={isPrinting}
        style={{ zIndex: '50', backgroundColor: 'white' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="primary" style={{ marginBottom: '24px' }} />
          <Typography>
            Reviewed report is now being saved and generated.
          </Typography>
          <Typography>
            Please do not close this page until it's complete
          </Typography>
        </div>
      </Backdrop>
      <Backdrop open={isLoading || isLoadingMixData} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      {isCommissionReportViewMode ? (
        <div ref={pdfExportComponent}>
          <div className={isPrinting ? classes.pdfViewDiv : undefined}>
            <ViewCommissionReport
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              mixSelections={mixSelections}
              setIsCommissionReportViewMode={setIsCommissionReportViewMode}
              isCommissionReportViewMode={isCommissionReportViewMode}
              companyLogoUrl={companyLogoUrl}
              isValidReportId={isValidReportId}
              variationsWithInsufficientSamplesList={
                variationsWithInsufficientSamplesList
              }
              variationsWithInsufficientIntervalSamples={
                variationsWithInsufficientIntervalSamples
              }
              isLoadingMixData={isLoadingMixData}
              setIsLoadingMixData={setIsLoadingMixData}
              selectedCustomer={selectedCustomer}
              isPrinting={isPrinting}
            />
          </div>
        </div>
      ) : (
        mixSelections.length > 0 && (
          <CreateCommissionReport
            reportSettings={reportSettings}
            setReportSettings={setReportSettings}
            mixSelections={mixSelections}
            mixSelectionRows={mixSelectionRows}
            setMixSelectionRows={setMixSelectionRows}
            mixGroupOptions={mixGroupOptions}
            setCallMixEndpointFlag={setCallMixEndpointFlag}
            setMixSelections={setMixSelections}
            isValidReportId={isValidReportId}
            setIsCommissionReportViewMode={setIsCommissionReportViewMode}
            isCommissionReportViewMode={isCommissionReportViewMode}
            companyLogoUrl={companyLogoUrl}
            isLoadingMixData={isLoadingMixData}
            setIsLoadingMixData={setIsLoadingMixData}
            selectedCustomer={selectedCustomer}
            selectedTabValue={selectedTabValue}
            setSelectedTabValue={setSelectedTabValue}
            variationsWithInsufficientSamplesList={
              variationsWithInsufficientSamplesList
            }
            variationsWithInsufficientIntervalSamples={
              variationsWithInsufficientIntervalSamples
            }
            reportId={reportId}
            setIsLoading={setIsLoading}
            setIsPrinting={setIsPrinting}
            isReviewErrorModalOpen={isReviewErrorModalOpen}
            setIsReviewErrorModalOpen={setIsReviewErrorModalOpen}
          />
        )
      )}
    </Container>
  )
}

export default CommissionReportGeneratorView
