import React from 'react'
import PropTypes from 'prop-types'
import RoomIcon from '@material-ui/icons/Room'
import { projectColors } from '../../../Constants/Constants'
import BaseLegend from '../BaseLegend'

/** Function to return UpcomingProject icons for the map legend */
function UpcomingProjectsLegend(props) {
  UpcomingProjectsLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props

  if (!echoSettings.projects.includes('Upcoming Projects')) return null

  const items = [
    {
      label: 'CCT specified',
      color: projectColors.specified.hex,
      Icon: RoomIcon,
    },
    {
      label: 'CCT not specified',
      color: projectColors.unspecified.hex,
      Icon: RoomIcon,
    },
  ]

  return <BaseLegend title="Upcoming Projects:" items={items} />
}

export default UpcomingProjectsLegend
