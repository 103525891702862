import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'
import { useRecoilState } from 'recoil'
import {
  atomPlantsFiltersOptions,
  atomPlantsFiltersSettings,
} from '../../Common/tssAtoms'
import PlantTableViewLogical from './PlantTableViewLogical'
import HistoricalTablePresentational from '../Components/HistoricalTable/HistoricalTablePresentational'
import PlantsConfirmationLogical from '../Components/PlantsConfirmation/PlantsConfirmationLogical'
import {
  getFilterOptions,
  prepFilterOptions,
  getUrlFromFilters,
  getNewSettings,
} from '../Data/TSSDataHelpers'
import { useLocation } from 'react-router-dom'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import { cloneDeep } from 'lodash'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import theme from '../../theme/muiTheme'
import { FilterComponentTypes } from '../../Common/Logic/Types'

const useStyles = makeStyles(() => ({
  floatingMetricSwitch: {
    position: 'absolute',
    right: '-0.5em',
  },
  //Filter Panel Styling
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '108px',
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    top: '108px',
  },
}))

const useFiltersStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: '40px',
  },
  checkboxFormControlLabel: {
    marginLeft: 0,
  },
}))

function PlantManagementPageView() {
  const [isClient, setIsClient] = useState(true)
  const [isFirstRender, setIsFirstRender] = useState(true)
  /** Boolean to determine when to show loading spinner */
  const [isLoading, setIsLoading] = useState(false)
  const [plantsFiltersSettings, setPlantsFiltersSettings] = useRecoilState(
    atomPlantsFiltersSettings
  )
  const [plantsFiltersOptions, setPlantsFiltersOptions] = useRecoilState(
    atomPlantsFiltersOptions
  )
  const classes = useStyles()
  const filtersClasses = useFiltersStyles()
  const [open, setOpen] = useState(false)
  const [filterCount, setFilterCount] = useState(2)
  const [activeFilters, setActiveFilters] = useState([])
  const { search } = useLocation()

  const measurementOptions = [
    {
      name: 'Metric',
      id: true,
    },
    {
      name: 'Imperial',
      id: false,
    },
  ]

  const autoCompleteMultiChangeHandler = (filter, value) => {
    const newSettings = cloneDeep(plantsFiltersSettings)
    newSettings[filter.property] = value.map(x => x.id)
    setPlantsFiltersSettings(newSettings)
  }

  const withoutInstallsChangeHandler = () => {
    const newSettings = cloneDeep(plantsFiltersSettings)
    newSettings['plantsWithoutInstalls'] = !newSettings['plantsWithoutInstalls']
    setPlantsFiltersSettings(newSettings)
  }

  const chipClickHandler = (filter, option) => {
    const isSelected = plantsFiltersSettings.missingInformation.includes(
      option.id
    )
    const newSettings = cloneDeep(plantsFiltersSettings)
    if (!isSelected) newSettings.missingInformation.push(option.id)
    else
      newSettings.missingInformation = plantsFiltersSettings.missingInformation.filter(
        el => el !== option.id
      )
    setPlantsFiltersSettings(newSettings)
  }

  const radioSinglSelectChangeHandler = (filter, value) => {
    const newVal = value !== 'false'
    setPlantsFiltersSettings(prev => {
      return {
        ...prev,
        isMetric: newVal,
      }
    })
  }
  const filterPanel = [
    {
      name: 'CUSTOMER',
      category: 'customer',
      filters: [
        {
          property: 'customer',
          name: 'Producer',
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: plantsFiltersOptions.customer,
          onChangeHandler: autoCompleteMultiChangeHandler,
        },
        {
          property: 'state',
          name: 'State',
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: plantsFiltersOptions.state,
          onChangeHandler: autoCompleteMultiChangeHandler,
        },
        {
          property: 'tse',
          name: 'TSS Specialist',
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: plantsFiltersOptions.tse,
          onChangeHandler: autoCompleteMultiChangeHandler,
        },
        {
          property: 'plantsWithoutInstalls',
          name: 'Plants Without Installs',
          componentType: FilterComponentTypes.Checkbox,
          label: 'Plants Without Installs',
          options: [true, false],
          onChangeHandler: withoutInstallsChangeHandler,
          checkboxIconType: 'visibility',
        },
      ],
    },
    {
      name: 'MISSING INFO',
      category: 'missingInformation',
      filters: [
        {
          property: 'missingInformation',
          name: 'Missing Info',
          componentType: FilterComponentTypes.ChipsMulti,
          options: plantsFiltersOptions.missingInformation,
          onClickHandler: chipClickHandler,
        },
      ],
    },
    {
      name: 'CEMENT TYPE',
      category: 'cementType',
      filters: [
        {
          property: 'cementType',
          name: 'Cement Type',
          componentType: FilterComponentTypes.AutocompleteMulti,
          options: plantsFiltersOptions.cementType,
          onChangeHandler: autoCompleteMultiChangeHandler,
        },
      ],
    },
    {
      name: 'MEASUREMENT',
      category: 'isMetric',
      filters: [
        {
          property: 'isMetric',
          name: 'Measurements',
          componentType: FilterComponentTypes.RadioSingleSelect,
          options: measurementOptions,
          onChangeHandler: radioSinglSelectChangeHandler,
          isHorizontal: true,
          color: 'secondary',
        },
      ],
    },
  ]

  const isChipActive = option => {
    return plantsFiltersSettings.missingInformation.includes(option.id)
  }

  const helperVariables = {
    isChipActive: isChipActive,
  }

  const deleteChipHandler = filter => {
    if (
      filter.property === 'plantsWithoutInstalls' ||
      filter.property === 'isMetric'
    ) {
      setPlantsFiltersSettings(prevSettings => {
        return {
          ...prevSettings,
          [filter.property]: !prevSettings[filter.property],
        }
      })
    } else {
      setPlantsFiltersSettings(prevSettings => {
        const newSet = plantsFiltersSettings[filter.property].filter(
          option => option !== filter.id
        )

        return {
          ...prevSettings,
          [filter.property]: newSet,
        }
      })
    }
  }

  const deleteAllChipsHandler = () => {
    setPlantsFiltersSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      for (const setting in newSettings) {
        if (setting === 'plantsWithoutInstalls') {
          newSettings[setting] = false
        } else if (setting === 'isMetric') {
          newSettings[setting] = false
        } else {
          newSettings[setting] = []
        }
      }
      return newSettings
    })
  }

  useEffect(() => {
    if (!isFirstRender) {
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(plantsFiltersSettings, 'Plants')
      )
    } else {
      // FIRST RENDER
      const params = new URLSearchParams(search)
      const newSettings = getNewSettings(plantsFiltersSettings, params)
      setPlantsFiltersSettings(newSettings)
      setIsFirstRender(false)
    }
  }, [plantsFiltersSettings, isFirstRender, search, setPlantsFiltersSettings])

  const addChips = useCallback(
    (chipValues, setting) => {
      switch (setting) {
        case 'customer':
          plantsFiltersSettings[setting].forEach(id => {
            const customer = plantsFiltersOptions[setting].find(
              option => id === option.id
            )
            if (customer)
              chipValues.push({
                property: setting,
                label: customer.name,
                id: customer.id,
              })
          })
          break
        case 'cementType':
          plantsFiltersSettings[setting].forEach(id => {
            const type = plantsFiltersOptions[setting].find(
              option => id === option.id
            )
            if (type)
              chipValues.push({
                property: 'cementType',
                label: type.name,
                id: type.id,
              })
          })
          break
        case 'state':
          plantsFiltersSettings[setting].forEach(state => {
            chipValues.push({ property: setting, label: state, id: state })
          })
          break
        case 'tse':
          plantsFiltersSettings[setting].forEach(id => {
            const type = plantsFiltersOptions[setting].find(
              option => id === option.id
            )
            if (type)
              chipValues.push({
                property: 'tse',
                label: type.name,
                id: type.id,
              })
          })
          break
        case 'missingInformation':
          plantsFiltersSettings[setting].forEach(id => {
            const type = plantsFiltersOptions[setting].find(
              option => id === option.id
            )
            if (type)
              chipValues.push({
                property: 'missingInformation',
                label: `Missing Info: ${type.name}`,
                id: type.id,
              })
          })
          break
        case 'plantsWithoutInstalls':
          if (plantsFiltersSettings[setting] === true)
            chipValues.push({
              property: 'plantsWithoutInstalls',
              label: 'Plants Without Installs',
            })
          break
        default:
          break
      }
    },
    [plantsFiltersOptions, plantsFiltersSettings]
  )

  useEffect(() => {
    const chipValues = []
    for (const setting in plantsFiltersSettings) {
      addChips(chipValues, setting)
    }
    setActiveFilters(chipValues)
    setFilterCount(chipValues.length)
  }, [isLoading, plantsFiltersSettings, plantsFiltersOptions, addChips])

  useEffect(() => {
    async function initFilterOptions() {
      setIsLoading(true)
      const response = await getFilterOptions()
      if (response.ok) {
        const results = await response.json()
        setPlantsFiltersOptions(
          prepFilterOptions(results, plantsFiltersOptions)
        )
        setIsLoading(false)
      }
    }
    if (isFirstRender) {
      initFilterOptions()
    }
  }, [plantsFiltersOptions, setPlantsFiltersOptions, isFirstRender])

  const [expandFilters, setExpandFilters] = useState({
    customer: false,
    missingInformation: false,
    cementType: false,
    plantsWithoutInstalls: false,
    isMetric: false,
  })

  const onClickHandler = () => {
    setOpen(prev => !prev)
  }

  function getValue(filter) {
    if (filter.property === 'isMetric') return plantsFiltersSettings.isMetric
    else if (filter.property === 'plantsWithoutInstalls')
      return plantsFiltersSettings.plantsWithoutInstalls
    else {
      const result = filter.options.filter(x => {
        return plantsFiltersSettings[filter.property].includes(x.id)
      })
      if (result.length > 0) {
        return result
      }
      return []
    }
  }

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em' }}
        className={classes.mainContainer}
        id="plant-data-container"
      >
        <FilterPanelLogical
          open={open}
          setOpen={setOpen}
          expandFilters={expandFilters}
          setExpandFilters={setExpandFilters}
          filterPanel={filterPanel}
          parentClasses={classes}
          getValue={getValue}
          helperVariables={helperVariables}
          filtersClasses={filtersClasses}
        />
        <div
          className={open ? classes.wrapperShifted : classes.wrapperUnshifted}
        >
          <Grid container direction="column" spacing={2} justify="space-around">
            <Grid item xs>
              <Typography variant="h2">Plant Data</Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                Click on the row inputs to edit customer data values.
              </Typography>
            </Grid>
            <FilterButton
              open={open}
              parentClasses={classes}
              filterCount={filterCount}
              onClickHandler={onClickHandler}
            />
            <Grid
              container
              direction="row"
              spacing={4}
              justify="space-around"
              id="plant-data-table-container"
            >
              <Grid item xs style={{ paddingLeft: '24px' }}>
                <FilterPanelChips
                  activeFilters={activeFilters}
                  chipClickHandler={deleteChipHandler}
                  buttonClickHandler={deleteAllChipsHandler}
                />
              </Grid>
              <Grid item xs>
                <PlantTableViewLogical
                  isClient={isClient}
                  setIsClient={setIsClient}
                  setIsLoading={setIsLoading}
                />
              </Grid>
              <Grid item xs>
                <HistoricalTablePresentational />
                <PlantsConfirmationLogical />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}

export default PlantManagementPageView
