import React from 'react'
import { makeStyles, Typography, Paper, Grid, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import DownloadDataLogical from '../Components/DownloadDataLogical'
import RenderSystemDataTable from '../Components/RenderSystemDataTable'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  innerTabPanel: {
    padding: '3em',
    marginBottom: '2em',
  },
  radioGroup: {
    marginTop: '1em',
  },
  radioButton: {
    marginRight: '2em',
  },
}))

function SystemDataPresentational(props) {
  SystemDataPresentational.propTypes = {
    /** Function to sort the data table rows */
    handleTableSort: PropTypes.func.isRequired,
    /** Function to open the service case modal */
    handleOpen: PropTypes.func.isRequired,
    /** Order of the sorted table column: ascending or descending */
    order: PropTypes.string.isRequired,
    /** Name of the table column to sort by */
    orderBy: PropTypes.string,
    /** Current page number of the table */
    page: PropTypes.number.isRequired,
    /** Number of table rows to display per page */
    rowsPerPage: PropTypes.number.isRequired,
    /** State setter to update table page */
    setPage: PropTypes.func.isRequired,
    /** State setter to update number of table rows per page */
    setRowsPerPage: PropTypes.func.isRequired,
    /** Data from the mixer manifold data endpoint */
    mixerManifoldData: PropTypes.array.isRequired,
    /** Data from the injection data endpoint */
    injectionData: PropTypes.array.isRequired,
    /** Data from the inlet data endpoint */
    inletData: PropTypes.array.isRequired,
    /** Count of the number of rows for the associated data */
    count: PropTypes.number.isRequired,
    /** The type of data being displayed */
    systemDataType: PropTypes.string.isRequired,
    /** The data that can be downloaded */
    downloadableData: PropTypes.array.isRequired,
  }

  const {
    handleTableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    mixerManifoldData,
    injectionData,
    inletData,
    count,
    handleOpen,
    systemDataType,
    downloadableData,
  } = props

  const classes = useStyles()

  return (
    <Paper variant="outlined" className={classes.innerTabPanel}>
      <Grid container justify="space-between">
        <Typography variant="h3">System Data</Typography>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Create service case
        </Button>
      </Grid>
      <Grid style={{ marginTop: '24px' }}>
        <RenderSystemDataTable
          systemDataType={systemDataType}
          mixerManifoldData={mixerManifoldData}
          handleTableSort={handleTableSort}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          count={count}
          setRowsPerPage={setRowsPerPage}
          injectionData={injectionData}
          inletData={inletData}
        />
      </Grid>
      <Grid item container justify="flex-end">
        <DownloadDataLogical
          data={downloadableData}
          dataType={systemDataType}
        />
      </Grid>
    </Paper>
  )
}

export default SystemDataPresentational
