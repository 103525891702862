import React, { Dispatch, SetStateAction } from 'react'
import {
  ICommissionReportSettings,
  ICommissionReportVariation,
  IMixSelection,
} from '../../Logic/Types'
import { getGraphData } from '../../Data/TSSDataHelpers'
import HistogramPresentational from '../Histogram/HistogramPresentational'
import { getIntervalHeader } from '../../Helpers/BaleenHelpers'

export interface ITestResultsHistogramProps {
  baselineVariation: ICommissionReportVariation
  carbonCureVariation: ICommissionReportVariation
  reportSettings: ICommissionReportSettings | undefined
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  mixSelections: IMixSelection[]
  tabValue: number
}

function TestResultsHistogram(props: Readonly<ITestResultsHistogramProps>) {
  const {
    baselineVariation,
    carbonCureVariation,
    reportSettings,
    mixSelections,
    tabValue,
  } = props

  if (!baselineVariation || !carbonCureVariation || !reportSettings) return null

  const helperVariables = {
    designStrength: mixSelections[tabValue]?.designStrength,
    strengthIntervalHours: mixSelections[tabValue]?.strengthIntervalHours,
    graphSettings: reportSettings.mixDesignSettings?.[tabValue]?.frequencyGraph,
  }

  const filteredData = [baselineVariation, carbonCureVariation]
  const selectedAge = helperVariables.graphSettings?.selectedInterval
  const graphData = getGraphData(
    filteredData,
    selectedAge,
    helperVariables
  )
  const title = getIntervalHeader(selectedAge)

  return (
    <HistogramPresentational
      graphData={graphData}
      isMetric={reportSettings?.isMetric}
      title={title}
      isCommissionReport={true}
    />
  )
}

export default TestResultsHistogram
