import React, { useState, useEffect } from 'react'
import { getMixVariationSamples } from '../../Data/TSSDataHelpers'
import {
  digestBatchTestSamples,
  mixDesignsToExistingMixDesignsRows,
  findMixDesignById,
  filterMixDesignsById,
  filterVariationsById,
  findVariationById,
  getVariationProperties,
  getCommonVariationIds,
  filterBatchActualsById,
  getBatchInformationForVariation,
  mixDesignsToMixDesignsRows,
} from '../../Logic/TSSLogic'
import SimpleTablePresentational from '../../../Common/Components/SimpleTable/SimpleTablePresentational'
import {
  ISimpleTableSettings,
  ITargetEvent,
  SimpleTableSelection,
  SimpleTableTypes,
} from '../../../Common/Logic/Types'
import xor from 'lodash.xor'
import {
  headCellsAddDataView,
  headCellsMixDesigns,
} from '../../Constants/AddDataConstants'
import {
  DigestedBatchActual,
  DigestedMixDesign,
  MixGroupVariation,
  MixVariationBatch,
  MixVariationBatchList,
} from '../../Logic/Types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import cloneDeep from 'lodash.clonedeep'
import { comparedSort } from '../../../Common/Helpers/GeneralHelpers'
export interface IMixGroupVariationTableProps {
  setTableData: (prev: {}) => {}
  tableData: IMixGroupVariationTableData
  isAssociationView: boolean
  tableSettings: ISimpleTableSettings
  setTableSettings: (prev: {}) => {}
  handleAssociationView: () => void
  getButtonUrl: (id: number | string) => string
  getBaleenUrl: (id: number | string) => string
  getAssociatedMixes?: () => void
  parentClasses: ClassNameMap<string>
  roles: string
  mixCodeToFilter?: string
  conditionToFilter?: string
  rowCount: number
  tableType: SimpleTableTypes
  rows: Array<DigestedMixDesign>
  isMetric: boolean
}

export interface ICompareAndAnalyzeMixDesigns {
  [key: string]: DigestedMixDesign
}

export interface IMixGroupVariationTableData {
  digestedMixDesigns: Array<DigestedMixDesign>
  selectedMixDesigns: Array<DigestedMixDesign>
  selectedMixVariations: Array<MixGroupVariation>
  batchActuals: Array<DigestedBatchActual>
  selectedBatchActuals: Array<DigestedBatchActual>
  dataToExport: []
  compareAndAnalyzeMixDesigns: ICompareAndAnalyzeMixDesigns
}

export interface IGroupHandlerVariables {
  newVariations: Array<MixGroupVariation>
  undigestedBatchActuals: Array<MixVariationBatch>
  newBatchActuals: Array<Array<DigestedBatchActual>>
}

export interface IVariationHandlerVariables {
  newBatchActuals: Array<Array<DigestedBatchActual>>
  newResults: Array<MixVariationBatchList>
}

function MixGroupVariationTable(props: IMixGroupVariationTableProps) {
  const {
    isAssociationView,
    tableData,
    setTableData,
    tableSettings,
    setTableSettings,
    getButtonUrl,
    getBaleenUrl,
    getAssociatedMixes,
    handleAssociationView,
    parentClasses,
    roles,
    mixCodeToFilter,
    rowCount,
    rows,
    tableType,
    isMetric = false,
  } = props

  const {
    digestedMixDesigns,
    selectedMixDesigns,
    selectedMixVariations,
  } = tableData

  const { orderBy, order, page, rowsPerPage } = tableSettings

  const [collapsedMixDesigns, setCollapsedMixDesigns] = useState<
    Array<DigestedMixDesign>
  >(() => [])
  const [filteredMixDesigns, setFilteredMixDesigns] = useState<
    Array<DigestedMixDesign>
  >(() => [])

  useEffect(() => {
    if (mixCodeToFilter) {
      const filteredByMixCode = digestedMixDesigns.filter(design =>
        design.mixCode.toLowerCase().includes(mixCodeToFilter.toLowerCase())
      )
      setFilteredMixDesigns(filteredByMixCode)
    }
  }, [mixCodeToFilter, digestedMixDesigns])

  const groupDataHandler = (
    data: Array<MixVariationBatch>,
    variationsToRequest: Array<MixGroupVariation>,
    variables: IGroupHandlerVariables,
    row: DigestedMixDesign,
    index: number
  ) => {
    if (tableType === SimpleTableTypes.AddDataMixGroup) {
      const {
        undigestedBatchActuals,
        newVariations,
        newBatchActuals,
      } = variables
      // data is the array of batch samples for a mix variation
      const variation = { ...variationsToRequest[index] }
      undigestedBatchActuals.push(...data)
      newVariations.push(variation)

      const digestedBatchActuals: Array<DigestedBatchActual> = digestBatchTestSamples(
        data,
        row,
        variation
      )
      newBatchActuals.push(digestedBatchActuals)
      if (newVariations.length === variationsToRequest.length) {
        setTableData((prev: IMixGroupVariationTableData) => {
          return {
            ...prev,
            batchActuals: [...prev.batchActuals, ...newBatchActuals.flat()],
            selectedMixDesigns: [...prev.selectedMixDesigns, row],
            selectedMixVariations: [
              ...prev.selectedMixVariations,
              ...newVariations,
            ],
            dataToExport: undigestedBatchActuals,
          }
        })
      }
    }
    if (tableType === SimpleTableTypes.ViewDesignMixGroup) {
      const { newVariations } = variables
      const variation = { ...variationsToRequest[index] }
      const {
        freshProperties,
        uniqueProductionDates,
      } = getBatchInformationForVariation(data, variation)
      variation.freshProperties = freshProperties
      variation.productionDates = Array.from(uniqueProductionDates).sort(
        comparedSort
      )
      newVariations.push(variation)
      if (newVariations.length === variationsToRequest.length) {
        setTableData((prevTableData: IMixGroupVariationTableData) => {
          if (!tableData.compareAndAnalyzeMixDesigns[row.mixDesignId]) {
            return {
              ...prevTableData,
              selectedMixDesigns: [...prevTableData.selectedMixDesigns, row],
              selectedMixVariations: [
                ...prevTableData.selectedMixVariations,
                ...newVariations,
              ],
              compareAndAnalyzeMixDesigns: {
                ...prevTableData.compareAndAnalyzeMixDesigns,
                [row.mixDesignId]: row,
              },
            }
          } else {
            return {
              ...prevTableData,
              selectedMixDesigns: [...prevTableData.selectedMixDesigns, row],
              selectedMixVariations: [
                ...prevTableData.selectedMixVariations,
                ...newVariations,
              ],
            }
          }
        })
      }
    }
  }

  const variationDataHandler = (
    data: MixVariationBatchList,
    mixDesign: DigestedMixDesign,
    row: MixGroupVariation,
    commonIds: Array<number | string | undefined>,
    variables: IVariationHandlerVariables
  ) => {
    if (tableType === SimpleTableTypes.AddDataMixGroup) {
      const { newBatchActuals, newResults } = variables
      const variation = { ...row }
      const digestedBatchActuals = digestBatchTestSamples(
        data,
        mixDesign,
        variation
      )
      newBatchActuals.push(digestedBatchActuals)
      newResults.push(data)
      // if all of the mix group's variations will be selected, select the mix group.
      if (commonIds.length === mixDesign.variations.length - 1) {
        setTableData((prev: IMixGroupVariationTableData) => {
          return {
            ...prev,
            selectedMixDesigns: [...prev.selectedMixDesigns, mixDesign],
            selectedMixVariations: [...prev.selectedMixVariations, variation],
          }
        })
      }
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixVariations: [...prev.selectedMixVariations, variation],
          batchActuals: [...prev.batchActuals, ...newBatchActuals.flat()],
          dataToExport: newResults,
        }
      })
    }
    if (tableType === SimpleTableTypes.ViewDesignMixGroup) {
      const {
        freshProperties,
        uniqueProductionDates,
      } = getBatchInformationForVariation(data, row)
      row.freshProperties = freshProperties
      row.productionDates = Array.from(uniqueProductionDates).sort(comparedSort)
      // if all of the mix group's variations will be selected, select the mix group.
      setTableData((prevTableData: IMixGroupVariationTableData) => {
        const objToReturn: {
          selectedMixDesigns: Array<DigestedMixDesign>
          compareAndAnalyzeMixDesigns: ICompareAndAnalyzeMixDesigns
        } = {
          selectedMixDesigns: [...prevTableData.selectedMixDesigns],
          compareAndAnalyzeMixDesigns: {
            ...prevTableData.compareAndAnalyzeMixDesigns,
          },
        }
        if (commonIds.length === mixDesign.variations.length - 1) {
          objToReturn.selectedMixDesigns.push(mixDesign)
        }
        if (!prevTableData.compareAndAnalyzeMixDesigns[mixDesign.mixDesignId]) {
          objToReturn.compareAndAnalyzeMixDesigns[
            mixDesign.mixDesignId
          ] = mixDesign
        }
        return {
          ...prevTableData,
          ...objToReturn,
          selectedMixVariations: [
            ...prevTableData.selectedMixVariations,
            cloneDeep(row),
          ],
        }
      })
    }
  }

  const selectVariation = (
    mixDesign: DigestedMixDesign,
    row: MixGroupVariation,
    commonIds: Array<number | string | undefined>
  ) => {
    const variables = {
      newBatchActuals: [],
      newResults: [],
    }
    // if the clicked mix variation was not already selected
    const variationProperties = getVariationProperties(row)
    getMixVariationSamples({
      mixDesignId: mixDesign.mixDesignId,
      ...variationProperties,
      includeAll: false,
    }).then(result => {
      if (result.ok) {
        result.json().then(data => {
          variationDataHandler(data, mixDesign, row, commonIds, variables)
        })
      } else result.json().then(data => console.error(data.error))
    })
  }

  const deselectVariation = (
    mixDesign: DigestedMixDesign,
    id: number | string,
    commonIds: Array<number | string | undefined>
  ) => {
    // order of ViewDesignMixGroup else ifs matters because the mix design could only have 1 mix variation. so both the if and else if would be met.

    if (
      tableType === SimpleTableTypes.AddDataMixGroup &&
      commonIds.length === mixDesign.variations.length
    ) {
      // if the clicked mix variation row was already selected, deselect it.
      // if not all of the mix groups variations will be selected, deselect the mix group row automatically.
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixDesigns: filterMixDesignsById(
            prev.selectedMixDesigns,
            mixDesign.mixDesignId
          ),
          selectedMixVariations: filterVariationsById(
            prev.selectedMixVariations,
            id
          ),
          batchActuals: filterVariationsById(prev.batchActuals, id),
        }
      })
    } else if (
      tableType === SimpleTableTypes.AddDataMixGroup &&
      commonIds.length !== mixDesign.variations.length
    ) {
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixVariations: filterVariationsById(
            prev.selectedMixVariations,
            id
          ),
          batchActuals: filterVariationsById(prev.batchActuals, id),
        }
      })
    } else if (
      tableType === SimpleTableTypes.ViewDesignMixGroup &&
      commonIds.length === 1
    ) {
      /* if only 1 variation is selected (before update), deselect variation and 
      remove mix design key/value pair from object that contains mix groups with at least 1 variation selected */
      const compareAndAnalyzeMixDesignsCopy = {
        ...tableData.compareAndAnalyzeMixDesigns,
      }
      delete compareAndAnalyzeMixDesignsCopy[mixDesign.mixDesignId]
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixDesigns: filterMixDesignsById(
            prev.selectedMixDesigns,
            mixDesign.mixDesignId
          ),
          selectedMixVariations: filterVariationsById(
            prev.selectedMixVariations,
            id
          ),
          compareAndAnalyzeMixDesigns: compareAndAnalyzeMixDesignsCopy,
        }
      })
    } else if (
      tableType === SimpleTableTypes.ViewDesignMixGroup &&
      commonIds.length === mixDesign.variations.length
    ) {
      // if all mix variations are currently selected (before update). deselect mix group and mix variation
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixDesigns: filterMixDesignsById(
            prev.selectedMixDesigns,
            mixDesign.mixDesignId
          ),
          selectedMixVariations: filterVariationsById(
            prev.selectedMixVariations,
            id
          ),
        }
      })
    } else {
      /* if tableType === SimpleTableTypes.ViewDesignMixGroup and
      if more than 1, but less than all, of a mix group's variations are selected (before update). deselect variation */
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixVariations: filterVariationsById(
            prev.selectedMixVariations,
            id
          ),
        }
      })
    }
  }

  const selectGroup = (
    variations: Array<MixGroupVariation>,
    row: DigestedMixDesign
  ) => {
    // if the mix group was not already selected, determine which variations need batch data and get the data from the API endpoint
    const variationsToRequest: Array<MixGroupVariation> = []
    variations.forEach((variation: MixGroupVariation) => {
      if (!findVariationById(selectedMixVariations, variation.variationId)) {
        variationsToRequest.push(variation)
      }
    })
    if (variationsToRequest.length === 0) {
      setTableData((prev: IMixGroupVariationTableData) => {
        return {
          ...prev,
          selectedMixDesigns: [...prev.selectedMixDesigns, row],
        }
      })
    }
    Promise.all(
      variationsToRequest.map(variation => {
        const variationProperties = getVariationProperties(variation)
        return getMixVariationSamples({
          mixDesignId: row.mixDesignId,
          ...variationProperties,
          includeAll: false,
        })
      })
    ).then(results => {
      const variables = {
        newVariations: [],
        undigestedBatchActuals: [],
        newBatchActuals: [],
      }
      results.forEach((result, index) => {
        // looping through API responses
        if (result.ok) {
          result.json().then(data => {
            groupDataHandler(data, variationsToRequest, variables, row, index)
          })
        } else result.json().then(data => console.error(data.error))
      })
    })
  }

  const deselectGroup = (
    variations: Array<MixGroupVariation>,
    id: number | string
  ) => {
    // if the mix group was already selected, determine which variations need to be deselected and then remove them
    const variationIdsToRemove: Array<number | string | undefined> = []
    variations.forEach((variation: MixGroupVariation) => {
      if (findVariationById(selectedMixVariations, variation.variationId)) {
        variationIdsToRemove.push(variation.variationId)
      }
    })
    setTableData((prev: IMixGroupVariationTableData) => {
      if (tableType === SimpleTableTypes.AddDataMixGroup) {
        return {
          ...prev,
          selectedMixDesigns: filterMixDesignsById(prev.selectedMixDesigns, id),
          selectedMixVariations: prev.selectedMixVariations.filter(
            (variation: MixGroupVariation) =>
              !variationIdsToRemove.includes(variation.variationId)
          ),
          batchActuals: filterBatchActualsById(prev.batchActuals, id),
        }
      }
      if (tableType === SimpleTableTypes.ViewDesignMixGroup) {
        const newCompareAndAnalyzeMixDesigns = {
          ...prev.compareAndAnalyzeMixDesigns,
        }
        delete newCompareAndAnalyzeMixDesigns[id]
        return {
          ...prev,
          selectedMixDesigns: filterMixDesignsById(prev.selectedMixDesigns, id),
          selectedMixVariations: prev.selectedMixVariations.filter(
            (variation: MixGroupVariation) =>
              !variationIdsToRemove.includes(variation.variationId)
          ),
          batchActuals: filterBatchActualsById(prev.batchActuals, id),
          compareAndAnalyzeMixDesigns: newCompareAndAnalyzeMixDesigns,
        }
      }
    })
  }

  const handleVariationClick = (id: number | string) => {
    let row = findVariationById(selectedMixVariations, id)
    let isVariationAlreadySelected = !!row
    let mixDesign
    for (const design of digestedMixDesigns) {
      row = findVariationById(design.variations, id)
      if (row) {
        mixDesign = design
        break
      }
    }
    if (row && mixDesign) {
      const commonIds = getCommonVariationIds(
        mixDesign.variations,
        selectedMixVariations
      )
      isVariationAlreadySelected
        ? deselectVariation(mixDesign, id, commonIds)
        : selectVariation(mixDesign, row, commonIds)
    }
  }

  const handleGroupClick = (id: number | string) => {
    // if a mix group row was clicked
    let isMixGroupSelected = true
    let row = findMixDesignById(selectedMixDesigns, id)
    if (!row) {
      isMixGroupSelected = false
      row = findMixDesignById(digestedMixDesigns, id)
    }
    if (row) {
      const variations = row.variations
      isMixGroupSelected
        ? deselectGroup(variations, id)
        : selectGroup(variations, row)
    }
  }

  /* Function to select/unselect table rows */
  const handleToggleClick = (id: number | string) => {
    typeof id === 'string' ? handleVariationClick(id) : handleGroupClick(id)
  }

  const handleChangeRowsPerPage = (event: ITargetEvent) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const handleChangePage = (event: ITargetEvent, newPage: number) => {
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: newPage,
      }
    })
  }
  const handleRequestSort = (event: ITargetEvent, property: string) => {
    const { orderBy, order } = tableSettings
    const isAsc = orderBy === property && order === 'asc'
    setTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      }
    })
  }

  let simpleRows
  let headCells
  if (tableType === SimpleTableTypes.AddDataMixGroup) {
    simpleRows = mixDesignsToExistingMixDesignsRows(
      mixCodeToFilter ? filteredMixDesigns : digestedMixDesigns
    )
    headCells = headCellsAddDataView
  } else {
    simpleRows = mixDesignsToMixDesignsRows(rows, isMetric)
    headCells = headCellsMixDesigns
  }

  return (
    <SimpleTablePresentational
      headCells={headCells}
      tableType={tableType}
      selectionType={SimpleTableSelection.Multiple}
      rows={simpleRows}
      isExpandable={true}
      expanded={collapsedMixDesigns}
      onCollapseToggle={(mixDesignId: number | string) => {
        setCollapsedMixDesigns(xor(collapsedMixDesigns, [mixDesignId]))
      }}
      onToggle={event => {
        handleToggleClick(event)
      }}
      selected={[
        ...selectedMixDesigns.map(e => {
          return e.mixDesignId
        }),
        ...selectedMixVariations.map(e => {
          return e.variationId
        }),
      ]}
      rowCount={rowCount}
      rowsPerPage={rowsPerPage}
      page={page}
      order={order}
      orderBy={orderBy}
      onSort={handleRequestSort}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      getButtonUrl={getButtonUrl}
      getBaleenUrl={getBaleenUrl}
      getAssociatedMixes={getAssociatedMixes}
      showAssociationIcon={!isAssociationView}
      handleAssociationView={handleAssociationView}
      parentClasses={parentClasses}
      roles={roles}
    />
  )
}

export default MixGroupVariationTable
