import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

// TabPanel taken from the example on material-ui
function TabPanel(props) {
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    tabValue: PropTypes.any.isRequired,
  }
  const { children, tabValue, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={tabValue !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {index === tabValue && <Box>{children}</Box>}
    </Box>
  )
}

export default TabPanel
