import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'

ConfirmOpenSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConnect: PropTypes.func.isRequired,
}

function ConfirmOpenSessionModal(props) {
  const { isOpen, handleClose, handleConnect } = props

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Typography>Open Session?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You are about to open a live session
          <br />
          Do you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConnect} color="primary" variant="text">
          <Typography>Continue</Typography>
        </Button>
        <Button onClick={handleClose} color="primary" variant="text">
          <Typography>Cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmOpenSessionModal
