import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

StateSelectPresentational.propTypes = {
  /** Function called when state dropdown value changes */
  handleChange: PropTypes.func.isRequired,
  /** Options (states) for the autocomplete select input */
  options: PropTypes.array.isRequired,
}

function StateSelectPresentational(props) {
  const { handleChange, options } = props

  return (
    <Autocomplete
      id="stateSelect"
      multiple
      onChange={handleChange}
      options={options}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.label === value.label}
      renderInput={params => (
        <TextField
          {...params}
          label="States"
          variant="outlined"
          size="small"
          placeholder="Select a state..."
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default StateSelectPresentational
