import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { putMasterUnit } from '../Data/PCBDataHelpers'
import useFeedback from '../Hooks/useFeedback'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

AssignMasterUnitModal.propTypes = {
  /**
   * Controls whether the modal is open or not
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function that will fire when the modal is closed
   */
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  /**
   * List of corporations that have plants without
   * a master unit
   */
  corporations: PropTypes.array.isRequired,
  /** List of plants to assign a master unit to,
   *  matched from the corporation */
  plants: PropTypes.object.isRequired,
  /** The list of available masterUnits to assign */
  masterUnits: PropTypes.array.isRequired,
}

function AssignMasterUnitModal(props) {
  const {
    isOpen,
    handleClose,
    refresh,
    corporations,
    plants,
    masterUnits,
  } = props
  const [selectedPlantId, setSelectedPlantId] = useState('')
  const [selectedCompany, setSelectedCompany] = useState()
  const [plantSelectValues, setPlantSelectvalues] = useState([])
  const [feedbackIcon, setFeedbackIcon] = useFeedback('nothing')
  const masterUnitSchema = yup.object().shape({
    corporationId: yup.number().required('You must select a corporation'),
    plantId: yup.number().required('You must select a plant'),
    masterUnitId: yup.number().required('You must select a Master Unit'),
  })
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(masterUnitSchema),
  })

  console.log(selectedPlantId)

  useEffect(() => {
    const getPlantSelectValues = plants => {
      if (!plants) {
        return []
      }
      return plants.map(plant => {
        return { value: plant.plantId, label: plant.plantName }
      })
    }

    if (selectedCompany) {
      const plantSelectValues = getPlantSelectValues(plants[selectedCompany])
      setPlantSelectvalues(plantSelectValues)
    }
  }, [selectedCompany, plants])

  useEffect(() => {
    register({ name: 'plantId' })
    register({ name: 'corporationId' })
    register({ name: 'masterUnitId' })
  })

  const handleCloseInternal = () => {
    handleClose()
    setFeedbackIcon('nothing')
  }

  const handleChangeCompany = event => {
    setValue('corporationId', event.target.value)
    let returnedVal = parseInt(event.target.value)
    if (!isNaN(returnedVal)) {
      setSelectedPlantId('')
      setSelectedCompany(returnedVal)
    }
  }

  const handleSave = async data => {
    setFeedbackIcon('loading')
    let dataToSend = {
      plantId: data.plantId,
      authorized: true,
      deploymentPhase: 'ReadyToShip',
    }

    // const response  = await postDataHelper("/Hardware/Mixer/MasterUnits",dataToSend);
    const response = await putMasterUnit(data.masterUnitId, dataToSend).catch(
      () => {
        setFeedbackIcon('error')
      }
    )
    if (response && response.ok) {
      refresh()
      setFeedbackIcon('success')
    } else {
      setFeedbackIcon('error')
      try {
        console.log(response.status)
      } catch (e) {
        setFeedbackIcon('error')
        console.error(e)
      }
    }
  }

  const getCorporationSelectValues = () => {
    return corporations.map(corporation => {
      return {
        value: corporation.corporationId,
        label: corporation.corporationName,
      }
    })
  }

  //this should be moved to the parent component, and the data grab should be moved to the view that calls it
  //Equivalent to ComponentWillMount
  //warning from useEffect having a missing dependency "setFeedbackIcon" is not necessary/accurate
  //setFeedbackIcon is a state-setter that is guaranteed by react to be stable.

  return (
    <Dialog open={isOpen} onClose={handleCloseInternal} closeAfterTransition>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle disableTypography>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item>
              <Typography>Assign Master Unit</Typography>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="text"
                onClick={handleCloseInternal}
              >
                X
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                label="Company Name"
                select
                fullWidth
                size="small"
                name="corporationId"
                placeholder="Select A Company"
                error={errors.corporationId ? true : false}
                helperText={errors.corporationId?.message}
                onChange={event => handleChangeCompany(event)}
              >
                {getCorporationSelectValues().map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Plant Name"
                select
                fullWidth
                size="small"
                name="plantId"
                error={errors.plantId ? true : false}
                helperText={errors.plantId?.message}
                onChange={event => setValue('plantId', event.target.value)}
              >
                {plantSelectValues.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Typography>{option.label}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Master Unit"
                select
                fullWidth
                size="small"
                name="masterUnitId"
                error={errors.masterUnitId ? true : false}
                helperText={errors.masterUnitId?.message}
                onChange={event => setValue('masterUnitId', event.target.value)}
              >
                {masterUnits.map(masterUnit => (
                  <MenuItem
                    key={masterUnit.masterUnitId}
                    value={masterUnit.masterUnitId}
                  >
                    {masterUnit.hardwareId}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {feedbackIcon}
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            id="assign_submit"
          >
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AssignMasterUnitModal
