import React, { useEffect } from 'react'
import MapLayerSelectPresentational from './MapLayerSelectPresentational'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomFilterGroup,
  atomAddressOptions,
  atomAddressSearch,
  atomAddressCoordinates,
} from '../../../Common/echoAtoms'
import {
  turnOffCO2Depots,
  activateProjectSearch,
} from '../../Logic/FilterLogicHelpers'
import PropTypes from 'prop-types'

function MapLayerSelectLogical(props) {
  MapLayerSelectLogical.propTypes = {
    /** Array that contains the selected Map Layer Filter Panel Chips */
    selectedOptions: PropTypes.array.isRequired,
    /** Function to update Map Layers Filter Panel Chips */
    setSelectedOptions: PropTypes.func.isRequired,
    /** Filter Panel styling */
    filterPanelClasses: PropTypes.string,
  }

  const { selectedOptions, setSelectedOptions, filterPanelClasses } = props

  const [echoSettings, setEchoSettings] = useRecoilState(atomEchoSettings)
  /** Choose to filter by country, state, city, group, company, zip code, or address */
  const setFilterGroup = useSetRecoilState(atomFilterGroup)
  /** Update options for the address search dropdown */
  const setAddressOptions = useSetRecoilState(atomAddressOptions)
  /** Update address search query */
  const setAddressSearch = useSetRecoilState(atomAddressSearch)
  /** Update coordinates of address that has been searched in address filter */
  const setAddressCoordinates = useSetRecoilState(atomAddressCoordinates)

  /** Map layer options for the map layer multi-select input */
  const options = [
    'RM Plants',
    'Precast Plants',
    'Cement Plants',
    'CO₂ Depots',
    'Opportunities',
    'Project Search',
  ]

  if (
    process.env.REACT_APP_ENV !== 'prod' &&
    process.env.REACT_APP_ENV !== 'qa'
  ) {
    options.push('RM Companies', 'Precast Companies')
  }

  /** Update the echoSettings atom according to which map layers are selected */
  useEffect(() => {
    setEchoSettings(currentSettings => {
      const newSettings = { ...currentSettings }
      newSettings.layers = selectedOptions
      /** If the project search option is un-selected, clear the project layers. */
      if (!selectedOptions.includes('Project Search')) {
        newSettings.projects = []
        newSettings.startDate = ''
        newSettings.endDate = ''
        newSettings.keywords = ''
      }
      return newSettings
    })
  }, [selectedOptions, setEchoSettings])

  /** Handler for when Map Layer Panel Chips are clicked */
  const handleChange = (event, value) => {
    let newSelectedOptions = [...selectedOptions]
    // Toggle Chip
    if (selectedOptions.includes(event)) {
      newSelectedOptions = selectedOptions.filter(item => item !== event)
    } else {
      newSelectedOptions.push(event)
    }
    /** If the CO2 Depots layer is turned off, turn off the "connect CO2 to customers" layer. Also reset the address search and "Filter by" section */
    if (!newSelectedOptions.includes('CO₂ Depots')) {
      turnOffCO2Depots(
        echoSettings,
        setEchoSettings,
        setFilterGroup,
        setAddressOptions,
        setAddressSearch,
        setAddressCoordinates
      )
    }
    /** If the project search option is selected, only display customers. Also, disable the salesperson and greenfield features, and reset the Filter By section. */
    /** Also turn off map layers for CO₂ Depots, Opportunities, and Cement Plants. */
    if (newSelectedOptions.includes('Project Search')) {
      activateProjectSearch(
        echoSettings,
        setEchoSettings,
        setFilterGroup,
        setSelectedOptions
      )
      // Remove specific chip selections when Project Search is selected
      newSelectedOptions = newSelectedOptions.filter(
        item =>
          item !== 'CO₂ Depots' &&
          item !== 'Opportunities' &&
          item !== 'Cement Plants'
      )
    }
    setSelectedOptions(newSelectedOptions)
  }

  /** Check if specific chips should be disabled. For Project Search */
  const handleProjectSearchChipDisable = option => {
    if (!selectedOptions.includes('Project Search')) {
      return false
    } else if (
      ['CO₂ Depots', 'Cement Plants', 'Opportunities'].includes(option)
    ) {
      return true
    }
    return false
  }

  return (
    <MapLayerSelectPresentational
      options={options}
      handleChange={handleChange}
      handleProjectSearchChipDisable={handleProjectSearchChipDisable}
      filterPanelClasses={filterPanelClasses}
    />
  )
}

export default MapLayerSelectLogical
