import PropTypes from 'prop-types'
import { useState } from 'react'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AddDataBatchTableRowPresentational from './AddDataBatchTableRowPresentational'

AddDataBatchTableRowLogical.propTypes = {
  /** The batch data displayed in the row */
  batchDetails: PropTypes.object.isRequired,

  /** The onClickHandler for this row */
  onClickHandler: PropTypes.func.isRequired,

  /** Has this row been selected */
  selected: PropTypes.bool.isRequired,

  /** Whether this row should be single selectable radio button or checkbox */
  radioVariant: PropTypes.bool,
}

AddDataBatchTableRowLogical.defaultProps = {
  radioVariant: false,
}
/**
 * Wrapper for a \<TableRow\>, has its own internal state
 * to track whether it has been clicked or not
 */
export default function AddDataBatchTableRowLogical(props) {
  const { batchDetails, onClickHandler, selected, radioVariant } = props

  const [notesOpen, setNotesOpen] = useState(false)
  const handleCollapse = () => {
    setNotesOpen(prevValue => !prevValue)
  }
  const renderCollapseArrow = rowOpen => {
    if (rowOpen) {
      return <KeyboardArrowRightIcon onClick={handleCollapse} />
    } else {
      return <KeyboardArrowDownIcon onClick={handleCollapse} />
    }
  }

  return (
    <AddDataBatchTableRowPresentational
      batchDetails={batchDetails}
      onClickHandler={onClickHandler}
      selected={selected}
      radioVariant={radioVariant}
      renderCollapseArrow={renderCollapseArrow}
      notesOpen={notesOpen}
    />
  )
}
