import { IconLayer } from '@deck.gl/layers'
import { getCO2LocationSize } from '../MapComponentHelpers'
import cloudIconSVG from '../../../Assets/cloud-outlined.svg'

export function getCO2DepotsLayer(filteredCO2Depots, selectedRows) {
  const ICON_MAPPING = {
    marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
  }

  return [
    new IconLayer({
      id: 'co2Depots',
      data: filteredCO2Depots.map(depot => ({
        ...depot,
        layerType: 'co2Depot',
      })),
      pickable: true,
      iconMapping: ICON_MAPPING,
      getIcon: () => ({ url: cloudIconSVG, width: 128, height: 128 }),
      getPosition: depot => [depot.longitude, depot.latitude],
      getSize: depot => getCO2LocationSize(depot, selectedRows),
      getColor: '#0C1921',
    }),
  ]
}
