import { Grid, MenuItem, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { cementTypes, exposureClasses } from '../Constants/AddDataConstants'
import { useRecoilValue } from 'recoil'
import { atomCurrentCustomer } from '../../Common/atoms'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { MixGroupConditions } from '../Logic/Types'

AddMixDetailsForms.propTypes = {
  /** List all mix details, state object on AddMixDesignView. It is populated by form inputs. */
  mixDetails: PropTypes.object.isRequired,
  /** Setter for mix details, updates state on AddMixDesignView. */
  setMixDetails: PropTypes.func.isRequired,
  /** Sets all units based selected measurement system. */
  setUnits: PropTypes.func.isRequired,
  /** Register to collect form inputs, used for validation in AddMixDesignView. */
  register: PropTypes.func.isRequired,
  /** List of error messages to display in for fields, schema defined in AddMixDesignView. */
  errors: PropTypes.object.isRequired,
  /** List of all customer mix codes, fetched via API in AddMixDesignView. */
  mixDesigns: PropTypes.array.isRequired,
  /** Mix code of the design currently being edited. Prevents error flag on duplicate mix code entry. */
  editMixCode: PropTypes.string,
  /** Checks whether or not the mix that's currently being edited is Active  */
  isActiveMix: PropTypes.bool,
  /** Boolean flag that indicates whether the component is in edit mode. Optional. */
  isEditMode: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  infoIcon: {
    fontSize: '1.2rem',
    verticalAlign: 'middle',
    marginRight: '4px',
    color: '#1E73A6',
  },
}))

/**
 * Component holds all forms regarding mix details, used in AddMixDesignView.
 * @param {Object} mixDetails - list all mix details, state object on AddMixDesignView. It is populated by form inputs.
 * @param {Function} setMixDetails - setter for mix details, updates state on AddMixDesignView.
 * @param {Function} setUnits - sets all units based selected measurement system.
 * @param {Function} register - register to collect form inputs, used for validation.
 * @param {Object} errors - list of error messages to display in for fields.
 * @param {Array} mixDesigns - list of all customer mix codes.
 * @param {String} editMixCode - mix code of the design currently being edited. Prevents error flag on duplicate mix code entry.
 * @param {Boolean} isViewOnly - boolean to determine if input fields should be disabled
 * @param {Function} setHasUserInput - setState. Detect if the input has had a changed
 * @param {Array} roles - Array of strings. the role of the logged in user.
 * @param {boolean} isActiveMix - Boolean. The status of the mix being edited.
 *
 */
function AddMixDetailsForms(props) {
  const {
    mixDetails,
    setMixDetails,
    setUnits,
    register,
    errors,
    setValue,
    mixDesigns,
    editMixCode,
    setHasUserInput,
    isActiveMix,
    isViewOnly,
  } = props
  const classes = useStyles()
  const currentCustomer = useRecoilValue(atomCurrentCustomer)
  const [mixCodeError, setMixCodeError] = useState()

  useEffect(() => {
    const existingDesign = mixDesigns.find(
      mix =>
        mix.mixCode === mixDetails.mixCode &&
        mix.isCO2Design === (mixDetails.condition === MixGroupConditions.CO2)
    )
    if (existingDesign && existingDesign.mixCode !== editMixCode) {
      setMixCodeError(
        `${mixDetails.condition} Design with Mix Code ${mixDetails.mixCode} already exists.`
      )
    } else setMixCodeError(null)
  }, [mixDetails.mixCode, mixDetails.condition, mixDesigns, editMixCode])

  /* Constants for dropdown selections. */
  const systemsOM = [
    { name: 'Imperial', slumpUnit: 'In', strengthUnit: 'PSI' },
    { name: 'Metric', slumpUnit: 'mm', strengthUnit: 'MPa' },
  ]

  const conditions = [MixGroupConditions.CO2, MixGroupConditions.NOCO2]

  /* Set mix details based on selection. */
  const handleChange = stateKey => event => {
    setMixDetails(prevItems => ({
      ...prevItems,
      [stateKey]: event.target.value,
    }))
    setHasUserInput(true)
  }

  /* A helper function that facilitates the use of select inputs
    NOTE: When using a Material UI v4 select input with React Hook Form, 
    form validation stops working for that partiular input field. 
    This function allows us to invoke validation manually. */
  const getSelectValue = stateKey => {
    if (!mixDetails[stateKey]) {
      // If no value exists, don't validate
      setValue(stateKey, mixDetails[stateKey], { shouldValidate: false })
    } else {
      // Validate if a value exists
      setValue(stateKey, mixDetails[stateKey], { shouldValidate: true })
    }
    // Make sure a value is returned regardless of validation
    return mixDetails[stateKey]
  }

  return (
    <>
      <Grid item xs={12} lg={12} md={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} lg={6}>
            <TextField
              variant="outlined"
              label="Mix Code *"
              size="small"
              inputRef={register}
              value={mixDetails.mixCode}
              name="mixCode"
              data-testid="mixCode"
              placeholder="Enter the New Mix Code"
              onChange={handleChange('mixCode')}
              error={!!mixCodeError || !!errors.mixCode}
              helperText={
                !!mixCodeError ? mixCodeError : errors.mixCode?.message
              }
              disabled={isViewOnly}
            />
          </Grid>

          <Grid item xs={6} sm={6} lg={6}>
            <TextField
              variant="outlined"
              label="Condition *"
              size="small"
              select
              inputRef={register('condition', {
                required: true,
              })}
              value={getSelectValue('condition')}
              name="condition"
              placeholder="Select A Condition"
              onChange={handleChange('condition')}
              error={!!errors.condition}
              helperText={errors.condition?.message}
              disabled={isViewOnly || isActiveMix}
            >
              {conditions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={6}>
        <Autocomplete
          options={Object.values(cementTypes)}
          value={mixDetails.cementType}
          disabled={isViewOnly}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Cement Type"
              size="small"
              inputRef={register}
              name="cementType"
              placeholder="Select the Type of Cement"
            />
          )}
          onChange={(_, newValue) => {
            setMixDetails(prevItems => ({
              ...prevItems,
              cementType: newValue,
            }))
          }}
        />
      </Grid>
      <Grid item xs={6} sm={12} md={6}>
        <TextField
          variant="outlined"
          label="Measurement System"
          size="small"
          select
          value={mixDetails.unitsSystem}
          name="measurementSystem"
          placeholder="Select the System of Measurement"
          onChange={e => setUnits(e.target.value)}
          disabled={isViewOnly}
        >
          {systemsOM.map(option => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} lg={12} md={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={8} md={9} lg={6} style={{ paddingTop: 0 }}>
            <TextField
              variant="outlined"
              label="Baseline Mix CO2 Dosage"
              size="small"
              inputRef={register}
              name="baseLineCO2"
              value={mixDetails.baseLineCO2}
              disabled={
                isViewOnly || mixDetails.condition === MixGroupConditions.NOCO2
              }
              onChange={handleChange('baseLineCO2')}
              error={!!errors.baseLineCO2}
              helperText={errors.baseLineCO2?.message}
            />
          </Grid>
          <Grid item xs={12} lg={6} style={{ paddingTop: 0 }}>
            <TextField
              variant="outlined"
              label="Baseline Mix Cement Reduction"
              size="small"
              inputRef={register}
              name="baseLineCementReduction"
              value={mixDetails.baseLineCementReduction}
              disabled={
                isViewOnly || mixDetails.condition === MixGroupConditions.NOCO2
              }
              onChange={handleChange('baseLineCementReduction')}
              error={!!errors.baseLineCementReduction}
              helperText={errors.baseLineCementReduction?.message}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={11}>
        <InfoOutlinedIcon className={classes.infoIcon} />
        <Typography style={{ color: '#1E73A6' }} variant="caption">
          {`The Baseline Variation sets specified conditions for the mix group.
          ${
            mixDetails.condition === MixGroupConditions.NOCO2
              ? 'These inputs are disabled to ensure NO CO2 mixes remain consistent in our database.'
              : ''
          }
          `}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Description"
          size="small"
          inputRef={register}
          value={mixDetails.description}
          name="description"
          placeholder="Enter a Description"
          onChange={handleChange('description')}
          disabled={isViewOnly}
        />
      </Grid>

      <Grid item xs={12} lg={12} md={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={6}>
            <TextField
              variant="outlined"
              label="Interval (Days) *"
              size="small"
              inputRef={register}
              InputProps={{ inputProps: { min: 0 } }}
              value={mixDetails.interval}
              type="number"
              name="interval"
              placeholder="Set the Interval in Days"
              onChange={handleChange('interval')}
              error={!!errors.interval}
              helperText={errors.interval?.message}
              disabled={isViewOnly}
            />
          </Grid>

          <Grid item xs={8} md={9} lg={3}>
            <TextField
              variant="outlined"
              label="Design Str *"
              size="small"
              inputRef={register}
              value={mixDetails.designStrength}
              name="designStrength"
              placeholder="Specify the Design Strength"
              onChange={handleChange('designStrength')}
              error={!!errors.designStrength}
              helperText={errors.designStrength?.message}
              disabled={isViewOnly}
            />
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <TextField
              variant="outlined"
              label="Unit"
              size="small"
              select
              value={mixDetails.strengthUnit}
              name="strengthUnit"
              placeholder="Set the Strength Units"
              onChange={handleChange('strengthUnit')}
              disabled={isViewOnly}
            >
              {systemsOM.map(option => (
                <MenuItem key={option.strengthUnit} value={option.strengthUnit}>
                  {option.strengthUnit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {currentCustomer.division?.isPrecast && (
        <Grid item lg={12} container alignItems="center" spacing={1}>
          <Grid item xs={8} md={6} lg={4}>
            <TextField
              variant="outlined"
              label="Stripping Strength *"
              size="small"
              inputRef={register}
              value={mixDetails.strippingStrength}
              name="strippingStrength"
              placeholder="Specify the Stripping Strengh"
              onChange={handleChange('strippingStrength')}
              error={!!errors.strippingStrength}
              helperText={errors.strippingStrength?.message}
              disabled={isViewOnly}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <TextField
              variant="outlined"
              label="Unit"
              size="small"
              select
              value={mixDetails.strengthUnit}
              name="strengthUnit"
              placeholder="Set the Strength Units"
              onChange={handleChange('strengthUnit')}
              disabled={isViewOnly}
            >
              {systemsOM.map(option => (
                <MenuItem key={option.strengthUnit} value={option.strengthUnit}>
                  {option.strengthUnit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8} md={6} lg={4}>
            <TextField
              variant="outlined"
              label="Interval (Hours)"
              size="small"
              inputRef={register}
              InputProps={{ inputProps: { min: 0 } }}
              value={mixDetails.strippingInterval}
              name="strippingInterval"
              type="number"
              placeholder="Set the Stripping Interval in Hours"
              onChange={handleChange('strippingInterval')}
              error={!!errors.strippingInterval}
              helperText={errors.strippingInterval?.message}
              disabled={isViewOnly}
            />
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="h4">Other properties (if available)</Typography>
      </Grid>

      <Grid item container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TextField
            variant="outlined"
            label="Air Content Range (%)"
            size="small"
            inputRef={register}
            value={mixDetails.airContentRange}
            name="airContentRange"
            placeholder="Specify Air Content Range"
            onChange={handleChange('airContentRange')}
            error={!!errors.airContentRange}
            helperText={errors.airContentRange?.message}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={8} md={9} lg={3}>
          <TextField
            variant="outlined"
            label="Slump Range"
            size="small"
            inputRef={register}
            value={mixDetails.slumpRange}
            name="slumpRange"
            placeholder="Specify Slump Range"
            onChange={handleChange('slumpRange')}
            error={!!errors.slumpRange}
            helperText={errors.slumpRange?.message}
            disabled={isViewOnly}
          />
        </Grid>

        <Grid item xs={4} md={3} lg={3}>
          <TextField
            variant="outlined"
            label="Unit"
            size="small"
            select
            value={mixDetails.slumpUnit}
            name="slumpUnit"
            placeholder="Set the Slump Units"
            onChange={handleChange('slumpUnit')}
            disabled={isViewOnly}
          >
            {systemsOM.map(option => (
              <MenuItem key={option.slumpUnit} value={option.slumpUnit}>
                {option.slumpUnit}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Autocomplete
          options={exposureClasses}
          value={mixDetails.exposureClass}
          disabled={isViewOnly}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Exposure Class"
              size="small"
              inputRef={register}
              name="exposureClass"
              placeholder="Select the Exposure Class"
            />
          )}
          onChange={(_, newValue) => {
            setMixDetails(prevItems => ({
              ...prevItems,
              exposureClass: newValue,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12} sm={12}>
        <TextField
          variant="outlined"
          label="W/C Ratio"
          size="small"
          inputRef={register}
          value={mixDetails.wcRatio}
          name="wcRatio"
          placeholder="Set the Water/Cementitious Ratio"
          onChange={handleChange('wcRatio')}
          error={!!errors.wcRatio}
          helperText={errors.wcRatio?.message}
          disabled={isViewOnly}
        />
      </Grid>
    </>
  )
}

export default AddMixDetailsForms
