import React, { useState, useEffect } from 'react'
import {
  BaleenOutlierReasons,
  BaleenSample,
  DialogModalSize,
  DialogModalType,
} from '../Logic/Types'
import DialogModal from '../../Common/Components/DialogModal/DialogModal'
import { ClassNameMap, makeStyles } from '@material-ui/styles'
import { Card, CardContent, Typography } from '@material-ui/core'
import { baseColors } from '../../theme/colors'
import typography from '../../theme/typography'

interface IBaleenDialogModalLogical {
  outliers: BaleenSample[]
  originalOutliers: { [key: number]: BaleenSample }
  isSaveModalOpen: boolean
  setIsSaveModalOpen: (arg1: boolean) => void
  modalClasses: ClassNameMap
  customerAndMixGroupLabel: string
  handleSubmit: (arg1: BaleenSample[], arg2: BaleenSample[]) => void
}

const useStyles = makeStyles({
  category: {
    fontWeight: typography.fontWeight.semiBold,
    fontSize: typography.fontSize.S,
    lineHeight: '19.6px',
    letterSpacing: '1px',
    marginBottom: '8px',
  },
  cardContent: {
    padding: '16px !important',
  },
})
const BaleenDialogModalLogical = (props: IBaleenDialogModalLogical) => {
  const {
    outliers,
    originalOutliers,
    isSaveModalOpen,
    setIsSaveModalOpen,
    modalClasses,
    customerAndMixGroupLabel,
    handleSubmit,
  } = props
  const classes = useStyles()
  const [outliersAdded, setOutliersAdded] = useState<BaleenSample[]>([])
  const [outliersRemoved, setOutliersRemoved] = useState<BaleenSample[]>([])

  const getOutlierCards = () => {
    return (
      <>
        {outliersAdded.length > 0 && (
          <Typography className={classes.category}>
            Outlier(s) Added:
          </Typography>
        )}
        {outliersAdded.map(outlier => {
          const otherReason =
            outlier.outlierReasonTextField !== null
              ? outlier.outlierReasonTextField
              : ''
          return (
            <div key={outlier.batchId} style={{ padding: '4px 0px' }}>
              <Card
                style={{ borderLeft: `4px solid ${baseColors.success.main}` }}
              >
                <CardContent className={classes.cardContent}>
                  <Typography variant="body2">
                    Variation ID: {outlier.variationIdLabel}{' '}
                  </Typography>
                  <Typography variant="body2">
                    Ticket ID: {outlier.ticketId}{' '}
                  </Typography>
                  <Typography variant="body2">
                    Reason:{' '}
                    {outlier.outlierReasonDropdown ===
                    BaleenOutlierReasons.Other
                      ? `Other - ${otherReason}`
                      : BaleenOutlierReasons[outlier.outlierReasonDropdown]}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )
        })}
        {outliersRemoved.length > 0 && (
          <Typography className={classes.category}>
            Outlier(s) Removed:
          </Typography>
        )}
        {outliersRemoved.map(outlier => {
          const otherReason =
            outlier.outlierReasonTextField !== null
              ? outlier.outlierReasonTextField
              : ''
          return (
            <div key={outlier.batchId} style={{ padding: '4px 0px' }}>
              <Card
                style={{ borderLeft: `4px solid ${baseColors.error.main}` }}
              >
                <CardContent className={classes.cardContent}>
                  <Typography variant="body2">
                    Variation ID: {outlier.variationIdLabel}{' '}
                  </Typography>
                  <Typography variant="body2">
                    Ticket ID: {outlier.ticketId}{' '}
                  </Typography>
                  <Typography variant="body2">
                    Reason:{' '}
                    {outlier.outlierReasonDropdown ===
                    BaleenOutlierReasons.Other
                      ? `Other - ${otherReason}`
                      : BaleenOutlierReasons[outlier.outlierReasonDropdown]}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )
        })}
      </>
    )
  }

  useEffect(() => {
    let outliersToRemove = []
    let outliersToAdd = []
    for (const outlier of outliers) {
      const currentOutlierReasonDropdown = outlier.outlierReasonDropdown
      const currentOutlierReasonTextField = outlier.outlierReasonTextField

      if (currentOutlierReasonDropdown === null) continue
      else if (
        !originalOutliers[outlier.batchId] &&
        (currentOutlierReasonDropdown !== BaleenOutlierReasons.Other ||
          (currentOutlierReasonDropdown === BaleenOutlierReasons.Other &&
            currentOutlierReasonTextField))
      ) {
        outliersToAdd.push(outlier)
      } else if (
        originalOutliers[outlier.batchId] &&
        outlier.outlierStatus === 'PotentialOutlierUnchecked'
      ) {
        outliersToRemove.push(outlier)
      } else if (
        originalOutliers[outlier.batchId] &&
        (originalOutliers[outlier.batchId].outlierReasonDropdown !==
          currentOutlierReasonDropdown ||
          (originalOutliers[outlier.batchId].outlierReasonDropdown ===
            BaleenOutlierReasons.Other &&
            currentOutlierReasonDropdown === BaleenOutlierReasons.Other &&
            originalOutliers[outlier.batchId].outlierReasonTextField !==
              currentOutlierReasonTextField))
      ) {
        outliersToAdd.push(outlier)
      }
    }
    setOutliersAdded(outliersToAdd)
    setOutliersRemoved(outliersToRemove)
  }, [outliers, originalOutliers])

  return (
    <DialogModal
      modalOpen={
        isSaveModalOpen &&
        (outliersAdded.length > 0 || outliersRemoved.length > 0)
      }
      modalSize={DialogModalSize.XSmall}
      modalType={DialogModalType.warning}
      headerText={'Confirm Changes to Outlier Selection'}
      contentText={
        <Typography style={{ marginBottom: '10px' }} variant="body2">
          Below are all the changes made to the outliers of{' '}
          <b>{customerAndMixGroupLabel}</b>:
        </Typography>
      }
      parentClasses={modalClasses}
      handleCancel={() => setIsSaveModalOpen(false)}
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      hasAction={true}
      hasCard={true}
      cardContent={getOutlierCards()}
      handleConfirm={() => handleSubmit(outliersAdded, outliersRemoved)}
    />
  )
}

export default BaleenDialogModalLogical
