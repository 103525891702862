import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Paper,
  Box,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import GeneralizedEnhancedTableHead from '../../../Common/Components/GeneralizedEnhancedTableHead'
import MaterialHistoricalTableRow from './MaterialHistoricalTableRow'
import { useRecoilState } from 'recoil'
import { atomShowCementCompHistory } from '../../../Common/tssAtoms'
import {
  CementComposition,
  CementTableData,
  PlantComposition,
} from '../../Logic/Types'
import { generateMaterialHistoricalTableHeadCells } from '../../Helpers/MaterialManagerHelpers'
import { baseColors } from '../../../theme/colors'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    color: baseColors.error.main,
  },
}))

function MaterialHistoricalTablePresentational() {
  const [showCementCompHistory, setShowCementCompHistory] = useRecoilState(
    atomShowCementCompHistory
  )

  const cementPlantData =
    ((showCementCompHistory.plantData as unknown) as PlantComposition) || null
  const cementPlantHeaders = generateMaterialHistoricalTableHeadCells(
    cementPlantData
  )

  const classes = useStyles()

  if (cementPlantData) {
    return (
      <Dialog
        id="historicalTable"
        open={showCementCompHistory.show}
        onClose={() => {
          setShowCementCompHistory(prevState => ({
            ...prevState,
            show: false,
          }))
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justify="space-between">
            <Typography variant="h2">
              Historical Data: {cementPlantData.plantName}
            </Typography>
            <IconButton
              className={classes.closeIcon}
              onClick={() => {
                setShowCementCompHistory(prevState => ({
                  ...prevState,
                  show: false,
                }))
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box mt={2} mb={3}>
            <Paper variant="outlined">
              <TableContainer>
                <Table size="small">
                  <GeneralizedEnhancedTableHead
                    headCells={cementPlantHeaders}
                    onRequestSort={() => ({})}
                    order={'asc'}
                    orderBy={''}
                  />

                  <TableBody>
                    {cementPlantData?.cementCompositions.map(
                      (composition: CementComposition) => {
                        if (composition.cementComponents.length > 0) {
                          // Set up first column with effectiveDate data
                          const rowData: CementTableData[] = [
                            {
                              id: composition.effectiveDate,
                              cementData: composition.effectiveDate,
                            },
                          ]
                          // Map through headerCells excluding the one with an id of 'date', since 'date' is already set up
                          cementPlantHeaders.forEach(headerCell => {
                            if (headerCell.id !== 'date') {
                              const cellId = headerCell.id
                              const matchingComponent = composition.cementComponents.find(
                                comp =>
                                  `cementCompositionPlant-${comp.materialId}` ===
                                  cellId
                              )

                              rowData.push({
                                id: `${composition.effectiveDate}-${headerCell.id}`,
                                cementData: matchingComponent
                                  ? matchingComponent.cementPercentage
                                  : 0,
                              })
                            }
                          })

                          return (
                            <MaterialHistoricalTableRow
                              key={composition.effectiveDate}
                              rowData={rowData}
                            />
                          )
                        }
                        return null
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
  return null
}

export default MaterialHistoricalTablePresentational
