import React from 'react'
import {
  AddDataTableVariation,
  AlarmsManagementFilterProperty,
  AnalysisFilterProperty,
  BaleenFilterProperty,
  MaterialObject,
  PlantDataFilterProperty,
  ReportLibraryProperty,
  viewDesignsClickHandler,
} from '../../TSS/Logic/Types'

export enum CellBehavior {
  Chips,
  Link,
  ButtonLink,
  Checkbox,
  DropdownWithOther,
  Close,
  EditOutlined,
  Undo,
  StyledText,
  TruncatedArrayWithTooltip,
}

export enum SimpleTableSelection {
  None,
  Single,
  Multiple,
}

export interface SimpleTableComplexCell {
  behavior?: CellBehavior
  content: Array<any>
}

export interface SimpleTableCollapsibleRow {
  id: string | number
  cells: Array<string | number | SimpleTableComplexCell | null | undefined>
}

export interface SimpleTableRow {
  id: string | number
  cells: Array<
    string | number | SimpleTableComplexCell | null | undefined | Array<string>
  >
  expansion?: React.FC<any>
  variations?: Array<AddDataTableVariation>
  collapsibleRows?: SimpleTableCollapsibleRow[]
  isMetric?: boolean
}

export enum SimpleTableTypes {
  AddDataMixGroup = 'AddDataMixGroup',
  ViewDesignMixGroup = 'ViewDesignsMixGroup',
  MaterialManager = 'MaterialManager',
  BaleenSamplesTable = 'BaleenSamplesTable',
  BaleenOutliersTable = 'BaleenOutliersTable',
  ReportLibraryTable = 'ReportLibraryTable',
  EchoCementTable = 'EchoCementTable',
}

export interface ITargetEvent {
  event: { target: { value: number | string } }
}

export interface IActiveFilter {
  property: string
  label: string
  id?: any
}

/**
 * This interface is commonly used for autocomplete or select components
 * where the is a label display and corresponding value
 */
export interface ILabelValue {
  label: string
  value: string | number
}

export enum SimpleTableOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface ISimpleTableSettings {
  page: number
  rowsPerPage: number
  order: SimpleTableOrder | undefined
  orderBy: string
}

export interface FilterOption {
  name: string
  id: any
}

export enum FilterComponentTypes {
  AutocompleteMulti = 'AutocompleteMulti',
  ChipsMulti = 'ChipsMulti',
  AutocompleteSingle = 'AutocompleteSingle',
  RadioSingleSelect = 'RadioSingleSelect',
  CommissioningFilter = 'CommissioningFilter',
  SearchMixCodeField = 'SearchMixCodeField',
  TextFieldSelect = 'TextFieldSelect',
  Checkbox = 'Checkbox',
  CheckboxMulti = 'CheckboxMulti',
  CheckboxMultiWithTooltip = 'CheckboxMultiWithTooltip',
  DateRange = 'DateRange',
  Button = 'Button',
  CustomerAndLocationSelect = 'CustomerAndLocationSelect',
  SearchMixCodeFieldForParams = 'SearchMixCodeFieldForParams',
  SearchConditionFieldForParams = 'SearchConditionFieldForParams',
  RangeSlider = 'RangeSlider',
  GraphFilters = 'GraphFilters',
  EchoSales = 'EchoSales',
  EchoMapLayer = 'EchoMapLayer',
  EchoProducer = 'EchoProducer',
  EchoProjectFilters = 'EchoProjectFilters',
  EchoFilterBy = 'EchoFilterBy',
  Accordion = 'Accordion',
  DateRangeSelect = 'DateRangeSelect',
}
export interface Filter {
  property:
    | PlantDataFilterProperty
    | AlarmsManagementFilterProperty
    | AnalysisFilterProperty
    | BaleenFilterProperty
    | ReportLibraryProperty
  name: string
  label?: string
  componentType: FilterComponentTypes
  options: Array<FilterOption>
  onClickHandler?:
    | ((arg1: Filter, arg2: FilterOption | boolean | string) => {})
    | viewDesignsClickHandler
  onChangeHandler?: (
    arg1: Filter,
    arg2:
      | Array<FilterOption>
      | FilterOption
      | MaterialObject
      | null
      | string
      | boolean
  ) => void
  disabled?: () => boolean
  placeholder?: string
  checkboxIconType?: ''
  getTooltipTitle?: (arg1: boolean) => string
  isHorizontal: boolean
  color: 'default' | 'primary' | 'secondary'
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
  getOptionDisabled?: (arg1: FilterOption) => boolean
  getOptionLabel?: (arg1: FilterOption) => string
  heading?: HTMLElement | null
  renderTags?: (arg1: FilterOption[]) => HTMLElement
  renderOption?: (arg1: FilterOption) => HTMLElement
}

export enum VariabilityGraphPage {
  ANALYSIS = 'Analsysis',
  BALEEN = 'Baleen',
  COMMISSION = 'Commissioning Report',
}

export enum BarGraphPage {
  ANALYSIS = 'Analysis',
  COMMISSION = 'Commissioning Report',
}

export interface IFeatureFlags {
  showCommissionReport: boolean
}

export interface ISonarFeatureFlags {
  showSonarOverview: boolean
  showSonarAlarms: boolean
  showSonarDownSystems: boolean
  showSonarSnoozed: boolean
}

export type NullableValue = null | undefined | number | string

export interface ICurrentCustomerAtom {
  division: {
    divisionId: number
  } | null
  plant: number | null
}
export interface NavBarProject {
  title: string
  path: string
  subPaths: NavBarSubpath
}

export interface NavBarDepartment {
  department: string
  projects: NavBarProject[]
  featuresOfInterest?: NavBarProject
}

export interface NavBarSubpath {
  title: string
  path: string
}

export type TSortOrder = 'asc' | 'desc' | undefined

export type TSonarTechnologyType = 'Plc' | 'Pcb' | 'ReclaimedWater'

export interface ISonarFilter {
  technology: string[]
  divisionId?: ICustomerFilterOption[] | null
  plantId?: ICustomerFilterOption[] | null
  systemId?: ICustomerFilterOption[] | null
  region?: string[]
  country?: string[]
  state?: string[]
  city?: string[]
  alarmStartDate?: Date
  alarmEndDate?: Date
  lastSeenDateRange?: string[] | null
  lastSeenStartDate?: string[] | null
  lastSeenEndDate?: string[] | null
  severity?: string
  alarmType?: string
  shouldShowSnoozed?: string[] | null
  showResolvedAlarm?: boolean
  timeDown?: string[] | null
  [key: string]: any //To allow retreiving value by key without typescript error
}

export interface ISonarFrontendFilter {
  isUTC?: string[]
}

export interface IKelownaSonarDownSystem {
  daysDown: number
  divisionId: number
  divisionName: string
  lastSeenTimestamp: string
  location: string
  reclaimedWaterUnitId: number | null
  remoteUnitId: number | null
  snoozeId: number | null
  systemId: string
  technologyType: TSonarTechnologyType
  snoozeReason: string | null
  snoozeReasonDetails: string | null
  snoozeStartTimestamp: string | null
  snoozeEndTimestamp: string | null
  snoozeCreationTimestamp: string | null
  snoozeCreatedByUserId: number | null
  snoozeCreatedByUserFirstAndLastName: string | null
  snoozeLastUpdatedTimestamp: string | null
  snoozeLastUpdatedByUserId: number | null
  snoozeLastUpdatedByUserFirstAndLastName: string | null
}

export interface ISonarDownSystem {
  daysDown: number
  divisionId: number
  divisionName: string
  lastSeenTimestamp: string
  location: string
  reclaimedWaterUnitId: number | null
  remoteUnitId: number | null
  snoozeId: number | null
  systemId: string
  technologyType: TSonarTechnologyType
  snoozed: string
  snoozeReason: string | null
  snoozeReasonDetails: string | null
  snoozeBy: string | null
  snoozeDate: string | null
  snoozeEnd: string | null
}

/**
 * @description Defines the alignment of the table cell content.
 */
export type TableCellAlignment =
  | 'left'
  | 'right'
  | 'inherit'
  | 'center'
  | 'justify'
  | undefined

export interface ICustomerFilterProducer {
  divisionId: number
  name: string
}

export interface ICustomerFilterPlant {
  plantId: number
  name: string
}

export interface ICustomerFilterSystem {
  remoteUnitId: number
  systemId: string
  reclaimedWaterUnitId: string | number
}

export interface ICustomerFilterOption {
  id: string | number
  name: string
}

export interface ICustomerFilterOptions {
  divisionId: ICustomerFilterOption[]
  plantId: ICustomerFilterOption[]
  systemId: ICustomerFilterOption[]
  region: ICustomerFilterOption[]
  country: ICustomerFilterOption[]
  state: ICustomerFilterOption[]
  city: ICustomerFilterOption[]
  [key: string]: ICustomerFilterOption[] // To allow retreiving value by key without typescript error
}

export interface ICustomerFilterData {
  producers: ICustomerFilterProducer[]
  plants: ICustomerFilterPlant[]
  systems: ICustomerFilterSystem[]
  regions: string[]
  countries: string[]
  states: string[]
  cities: string[]
}
