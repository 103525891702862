import {
  ConvertCtoF,
  ConvertKPaToPSI,
} from '../../../Common/Helpers/GeneralHelpers'

class Status {
  statusId

  remoteUnitId

  inletLiquidPressureKPa

  inletGasPressureKPa

  upstreamPressureKPa

  downstreamPressure0KPa

  downstreamPressure1KPa

  upstreamTemperatureC

  timestamp

  /**
   *
   * @param {Number} statusId
   * @param {Number} remoteUnitId
   * @param {Number} inletLiquidPressureKPa
   * @param {Number} inletGasPressureKPa
   * @param {Number} upstreamPressureKPa
   * @param {Number} downstreamPressure0KPa
   * @param {Number} downstreamPressure1KPa
   * @param {Number} upstreamTemperatureC
   * @param {String} timestamp
   */
  constructor(
    statusId,
    remoteUnitId,
    inletLiquidPressureKPa,
    inletGasPressureKPa,
    upstreamPressureKPa,
    downstreamPressure0KPa,
    downstreamPressure1KPa,
    upstreamTemperatureC,
    timestamp
  ) {
    this.statusId = statusId
    this.remoteUnitId = remoteUnitId
    this.inletLiquidPressureKPa = inletLiquidPressureKPa
    this.inletGasPressureKPa = inletGasPressureKPa
    this.upstreamPressureKPa = upstreamPressureKPa
    this.downstreamPressure0KPa = downstreamPressure0KPa
    this.downstreamPressure1KPa = downstreamPressure1KPa
    this.upstreamTemperatureC = upstreamTemperatureC
    this.timestamp = new Date(timestamp)
  }

  get inletLiquidPressurePSI() {
    return ConvertKPaToPSI(this.inletLiquidPressureKPa)
  }

  get inletGasPressurePSI() {
    return ConvertKPaToPSI(this.inletGasPressureKPa)
  }

  get upstreamPressurePSI() {
    return ConvertKPaToPSI(this.upstreamPressureKPa)
  }

  get downstreamPressure0PSI() {
    return ConvertKPaToPSI(this.downstreamPressure0KPa)
  }

  get downstreamPressure1PSI() {
    return ConvertKPaToPSI(this.downstreamPressure1KPa)
  }

  get upstreamTemperatureF() {
    return ConvertCtoF(this.upstreamTemperatureC)
  }

  get formattedTimestamp() {
    return this.timestamp.toLocaleString()
  }

  static fromKelownaObject(kelownaStatus) {
    return new this(
      kelownaStatus.statusId,
      kelownaStatus.remoteUnitId,
      kelownaStatus.inletLiquidPressureKPa,
      kelownaStatus.inletGasPressureKPa,
      kelownaStatus.upstreamPressureKPa,
      kelownaStatus.downstreamPressure0KPa,
      kelownaStatus.downstreamPressure1KPa,
      kelownaStatus.upstreamTemperatureC,
      kelownaStatus.timestamp
    )
  }
}

export default Status
